import { Skeleton } from '@mui/material';
import React from 'react'
import Label from '../../../../elements/label/Label';
import ViewMore from '../../../../elements/view-more/ViewMore';
import GlobalSearchCss from "../../GlobalSearch.module.css";
import ArtistAndDjCardLoader from '../../../../elements/skeletonLoaders/ArtistAndDjCardLoader';
import ArtistList from '../../../../elements/Flatlist/ArtistList';

export default function Artists({
    artists,
    isLoading,
    length,
    showViewAll = false,
    setValue,
    moreArtistIsLoading,
    artistsPageNumber,
    artistsLastPage,
    updateArtistsPageNumber = undefined,
  }) {
    return (
      <div>
        <div className={GlobalSearchCss.mainDiv}>
          {isLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={"40%"}
              height={50}
            />
          ) : (
            <Label label={`Artists (${length} results)`} size="md" />
          )}
        </div>
        {isLoading ? (
          <div className={GlobalSearchCss.mgb}>
            <div className={GlobalSearchCss.albumsDiv}>
              {Array.from({ length: 10 }, (_, index) => {
                return (
                  <div key={index} className={GlobalSearchCss.albumsSubDiv}>
                    <ArtistAndDjCardLoader />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <>
            <ArtistList artists={artists} />
            {moreArtistIsLoading && (
              <div className={GlobalSearchCss.flexDrR} style={{ marginTop: 10 }}>
                {Array.from({ length: 8 }, (_, index) => (
                  <div key={index} className={GlobalSearchCss.albumsSubDiv}>
                    <ArtistAndDjCardLoader />
                  </div>
                ))}
              </div>
            )}
            {showViewAll && <ViewMore onClick={() => setValue(7)} />}
            {artistsLastPage != null &&
              artistsPageNumber < artistsLastPage &&
              !moreArtistIsLoading &&
              artists?.length !== 0 &&
              !showViewAll &&
              !isLoading && (
                <ViewMore loadMore={true} onClick={updateArtistsPageNumber} />
              )}
          </>
        )}
      </div>
    );
  }
