// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmV3X-jwjI7lPh0Z_PVohUyk9ZBnbmBgE",
  // authDomain: "trinijunglejuice-308c0.firebaseapp.com",
  authDomain: "auth.trinijunglejuice.com",
  projectId: "trinijunglejuice-308c0",
  storageBucket: "trinijunglejuice-308c0.appspot.com",
  messagingSenderId: "887938108789",
  appId: "1:887938108789:web:b136d2474718d23d92d73c",
  measurementId: "G-1Z145W8WS0"
};

// Initialize Firebase
const FirebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(FirebaseApp);

const provider = new GoogleAuthProvider();
provider.addScope('email');

const facebookProvider = new FacebookAuthProvider();
facebookProvider.addScope('email');

export { auth, provider,facebookProvider , signInWithPopup };