import React from "react";
import Slider from "react-slick";
import CarouselCss from "./Carousel.module.css";
import { useNavigate } from "react-router-dom";

export default function HomeCarousel({ data }) {
  const navigate = useNavigate();

  const settings = {
    customPaging: function (i) {
      return (
        <a key={i}>
          <img
            className={CarouselCss.dots}
            src={data[i]?.thumbnail_image_url}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: CarouselCss.slickDots,
    infinite: data?.length > 1 ? true : false,
    speed: 1000,
    slidesToShow: 1,
    autoplay: data?.length > 1 ? true : false,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    arrows: false,
  };

  const handleClick = (item, isNewWindow) => {
    if (isNewWindow) {
      const aTag = document.createElement("a");
      aTag.setAttribute("href", item);
      aTag.setAttribute("target", "_blank");
      aTag.click();
    } else {
      const url = item?.split(".com")
      console.log(item,url)
      navigate(url[1]);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className={CarouselCss.container}>
      <Slider {...settings}>
        {data?.map((item, index) => {
          return (
            <div key={index}>
              <div className={CarouselCss.carouselImageDiv}>
                <img
                  className={CarouselCss.carouselImage}
                  onClick={() =>
                    handleClick(item?.target_url, item?.is_new_window)
                  }
                  src={item?.cover_image_url}
                />
                <div className={CarouselCss.carouselTitle}>{item?.title}</div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
