import React, { useContext, useEffect, useState } from "react";
import Label from "../../elements/label/Label";
import { Grid, Skeleton } from "@mui/material";
import LightBoxGallery from "../../components/lightBox-gallery/LightBoxGallery";
import NavContainer from "../../elements/NavContainer/NavContainer";
import RatingComp from "../event/elements/RatingComp";
import GalleryCss from "./GalleryHome.module.css";
import CommentList from "../../components/comment/CommentList";
import { FaLocationArrow } from "react-icons/fa";
import RatingModal from "../../components/rating-modal/RatingModal";
import { LocationOn } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import InfoIcon from "@mui/icons-material/Info";
import GalleryList from "../../elements/Flatlist/GalleryList";
import NewsList from "../../elements/Flatlist/NewsList";
import { useLocation, useParams } from "react-router-dom";
import RatingBox from "../../components/rating-box/RatingBox";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip";
import { BiLike, BiSolidLike } from "react-icons/bi";
import ViewMore from "../../elements/view-more/ViewMore";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context";
import MetaTags from "../../MetaTags";
import GalleryImagesLoader from "../../elements/skeletonLoaders/GalleryImagesLoader";
import CommentLoader from "../../elements/skeletonLoaders/CommentLoader";
import GalleryCardLoader from "../../elements/skeletonLoaders/GalleryCardLoader";
import NewsCardLoader from "../../elements/skeletonLoaders/NewsCardLoader";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { LocationExtractor } from "../../elements/location-extractor/LocationExtractor.js";
import { CarnivalType, EventType } from "./LinkableTypes.js";
import { EventDateExtractor } from "../../elements/date-extractor/ExtractDate.js";
import NoDataScreen from "../no-data/NoDataScreen.js";
import { useLoggedInState } from "../../elements/logged-in-state/useLoggedInState.js";
import { convertNumUnit } from "../../elements/convert-num-unit/ConvertNumUnit.js";
import ReactGA from "react-ga4";

const EventAspects = ["Vibe", "Venue", "Crowd", "Food", "Service"];
const CarnivalAspects = [
  "Affordability",
  "Costumes",
  "Events",
  "Food",
  "Music",
  "Safety",
  "Culture",
];

function ViewGallery({ isLoggedIn, user }) {
  const { image_id, slug } = useParams();
  const [selectedPhotoId, setSelectedPhotoId] = useState(image_id);
  const [liked, setLiked] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [imageData, setImageData] = useState([]);
  const { setPageTitle } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [galleries, setGalleries] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [blogsIsLoading, setBlogsIsLoading] = useState(true);
  const [commentsData, setCommentsData] = useState([]);
  const [photoCommentsData, setPhotoCommentsData] = useState([]);
  const [isGalleryLoading, setIsGalleryLoading] = useState(true);
  const [isMoreGalleryLoading, setIsMoreGalleryLoading] = useState(false);
  const [isCommentsLoading, setIsCommentsLoading] = useState(true);
  const [isPhotosCommentsLoading, setIsPhotosCommentsLoading] = useState(true);
  const [isMoreCommentsLoading, setIsMoreCommentsLoading] = useState(false);
  const [isMorePhotosCommentsLoading, setIsMorePhotosCommentsLoading] =
    useState(false);
  const [ratings, setRatings] = useState(null);
  const [photosPageNumber, setPhotosPageNumber] = useState(1);
  const [commentsPageNumber, setCommentsPageNumber] = useState(1);
  const [photoCommentsPageNumber, setPhotoCommentsPageNumber] = useState(1);
  const [photosLastPage, setPhotosLastPage] = useState(null);
  const [commentsLastPage, setCommentsLastPage] = useState(null);
  const [photoCommentsLastPage, setPhotoCommentsLastPage] = useState(null);
  const [slugUrl, setSlugUrl] = useState("");
  const [url, setUrl] = useState("");
  const [galleryDetails, setgalleryDetails] = useState(null);
  const [getRatingUrl, setGetRatingsUrl] = useState("");
  const [submitRatingUrl, setSubmitRatingsUrl] = useState("");
  const [linkableType, setLinkableType] = useState("");
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [photoId, setPhotoId] = useState(null);
  const [photoCommentsId, setPhotoCommentsId] = useState(null); //This photoCommentsId will be used to load more comments of the same photo.
  const [totalPhotoComments, setTotalPhotoComments] = useState(0);
  const [status, setStatus] = useState(null);
  const [isDeletingComment, setIsDeletingComment] = useState(false);
  const [isDeletingPhotoComment, setIsDeletingPhotoComment] = useState(false);
  const [likeCount, setLikeCount] = useState(null);
  const [totalPhotos, setTotalPhotos] = useState(0);
  const isLoggedInUser = useLoggedInState();
  const { pathname } = useLocation();

  // This useEffect is being used for google analytics.
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/gallery/view/${slug}`,
      title: "Gallery Detail",
    });
  }, []);

  useEffect(() => {
    if (galleryDetails) {
      // Update the url to get the associated gallery details
      setUrl(`${SERVER_URL.GET_GALLERY_RELATED_DATA}/${galleryDetails?.id}`);
      setPageTitle(galleryDetails?.name);

      //Update the like state
      setLiked(galleryDetails?.is_liked);

      // Checks for which kind of ratings is their(Event/Carnival) on the basis of linkable type
      if (galleryDetails?.linkable_type === EventType) {
        setGetRatingsUrl(
          `${SERVER_URL.GET_EVENTS_DETAILS_BY_ID}${galleryDetails?.linkable?.id}/ratings`
        );
        setSubmitRatingsUrl(
          `${SERVER_URL.GET_EVENTS_DETAILS_BY_ID}${galleryDetails?.linkable?.id}/rating`
        );
        setLinkableType("Event");
      }
      if (galleryDetails?.linkable_type === CarnivalType) {
        setGetRatingsUrl(
          `${SERVER_URL.GET_CARNIVAL_DETAILS}/${galleryDetails?.linkable?.id}/ratings`
        );
        setSubmitRatingsUrl(
          `${SERVER_URL.GET_CARNIVAL_DETAILS}/${galleryDetails?.linkable?.id}/submit-rating`
        );
        setLinkableType("Carnival");
      }
    } else {
      setPageTitle("Gallery Details");
    }
  }, [galleryDetails]);

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (getRatingUrl !== "") {
      handleGetRatings();
    }
  }, [getRatingUrl]);

  // This function updates the current url with the currently opened galleries photo index which will be helpful to open the same gallery photo on other's desktop.
  function updatePageURL(index) {
    const u = pathname?.split("/")[3];
    if (index > -1)
      window.history.replaceState(
        null,
        "Gallery",
        `/gallery/view/${u}/${index}`
      );
    else window.history.replaceState(null, "Gallery", `/gallery/view/${u}`);
  }

  function updatePhotoPageNumber() {
    if (photosLastPage != null && photosPageNumber <= photosLastPage) {
      setIsMoreGalleryLoading(true);
      setPhotosPageNumber(photosPageNumber + 1);
    }
  }

  // Get the gallery details on the basis of slug.
  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[3];
      setSlugUrl(`${SERVER_URL.GET_GALLERY_DETAIL}${u}/view`);
    }
  }, [pathname]);

  function updateCommentPageNumber() {
    if (commentsLastPage != null && commentsPageNumber <= commentsLastPage) {
      setIsMoreCommentsLoading(true);
      setCommentsPageNumber(commentsPageNumber + 1);
    }
  }

  function updatePhotoCommentPageNumber() {
    if (
      photoCommentsLastPage != null &&
      photoCommentsPageNumber <= photoCommentsLastPage
    ) {
      setIsMorePhotosCommentsLoading(true);
      setPhotoCommentsPageNumber(photoCommentsPageNumber + 1);
    }
  }

  const GetRelatedGalleries = async () => {
    let param = {
      page: 1,
      items: PER_PAGE_COUNT.NEW_VIDEOS_HOME,
    };
    try {
      const res = await axios.get(
        `${SERVER_URL.GET_ALL_GALLERIES}/${galleryDetails?.id}/related`,
        {
          headers: {
            Accept: "application/json",
          },
          params: param,
        }
      );
      if (res.status === 200) {
        if (
          res?.data &&
          res?.data?.related_galleries &&
          res?.data?.related_galleries?.data
        ) {
          setGalleries(res?.data?.related_galleries?.data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getGalleryData = async () => {
    try {
      const res = await axios.get(slugUrl, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
        params: {
          page: photosPageNumber,
          items: PER_PAGE_COUNT.PHOTOS_ITEMS_COUNT,
          orderBy:"sequence_number"
        },
      });
      setStatus(res?.status);
      if (res?.status === 200) {
        if (res?.data && res?.data?.gallery_photos) {
          const data = res?.data?.gallery_photos;
          const imgData = data?.data?.sort(
            (a, b) => a.sequence_number - b.sequence_number
          );
          setTotalPhotos(data?.total);
          if (imageData.length === 0) {
            setImageData(imgData);
            setgalleryDetails(res?.data?.gallery);
            setLikeCount(parseInt(res?.data?.gallery?.like_count));
          } else {
            setImageData((prevData) => {
              const newData = [...prevData, ...imgData];
              if (newData.length > PER_PAGE_COUNT.MAX_PHOTOS_DISPLAY_COUNT) {
                return newData.slice(PER_PAGE_COUNT.PHOTOS_ITEMS_COUNT);
              }
              return newData;
            });
          }

          setPhotosLastPage(data.last_page);
        }
        setIsGalleryLoading(false);
      }
    } catch (err) {
      setIsGalleryLoading(false);
      setTotalPhotos(0);
      setIsMoreGalleryLoading(false);
      setStatus(err.response.status);
    }
  };

  const getGalleryReviews = async () => {
    try {
      const res = await axios.get(
        `${SERVER_URL.GET_ALL_GALLERIES}/${galleryDetails?.id}/related-reviews`,
        {
          headers: {
            Accept: "application/json",
          },
          params: {
            page: 1,
            items: PER_PAGE_COUNT.GET_GALLERY_REVIEWS,
          },
        }
      );
      if (res.status === 200) {
        if (res?.data && res?.data?.related_gallery_reviews) {
          setBlogs(res?.data?.related_gallery_reviews?.data);
        }
        setBlogsIsLoading(false);
      }
    } catch (err) {
      setBlogsIsLoading(false);
    }
  };

  // Navigate to the all gallery page with the photographer pre selected
  const handleNavigate = (param) => {
    const params = `photographer=${param.photographer}`;

    const route = "/gallery/all?" + params;

    navigate(route);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  // Collects the comments data for the gallery
  const getCommentsData = async () => {
    try {
      const res = await axios.get(`${url}/comments`, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
        params: {
          page: commentsPageNumber,
          items: PER_PAGE_COUNT.COMMENTS_ITEMS_COUNT,
        },
      });

      const data = res.data.gallery_comments;
      const commentsDataArray = data.data;

      if (commentsData.length === 0) setCommentsData(commentsDataArray);
      else {
        setCommentsData((prevData) => {
          const newData = [...prevData, ...commentsDataArray];
          if (newData.length > PER_PAGE_COUNT.MAX_COMMENTS_DISPLAY_COUNT) {
            return newData.slice(PER_PAGE_COUNT.COMMENTS_ITEMS_COUNT);
          }
          return newData;
        });
      }

      setCommentsLastPage(data.last_page);
      setIsCommentsLoading(false);
      if (isMoreCommentsLoading) {
        setIsMoreCommentsLoading(false);
      }
      if (isDeletingComment) {
        setIsDeletingComment(false);
      }
    } catch (err) {
      setIsCommentsLoading(false);
      if (isMoreCommentsLoading) {
        setIsMoreCommentsLoading(false);
      }
    }
  };

  // Collects the comments data for the gallery photo
  const getPhotoCommentsData = async (id) => {
    const galleryPhotoId = id ? id : photoCommentsId;
    if (photoCommentsPageNumber === 1) {
      setPhotoCommentsId(id);
    }
    try {
      const res = await axios.get(
        `${SERVER_URL.COMMENT_GALLERY_PHOTO}${galleryPhotoId}/comments`,
        {
          headers: {
            Accept: "application/json",
            ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
          },
          params: {
            page: photoCommentsPageNumber,
            items: PER_PAGE_COUNT.COMMENTS_ITEMS_COUNT,
          },
        }
      );
      if (res.status === 200) {
        const data = res.data.gallery_photo_comments;
        const commentsDataArray = data.data;

        if (photoCommentsPageNumber === 1)
          setPhotoCommentsData(commentsDataArray);
        else {
          setPhotoCommentsData((prevData) => {
            const newData = [...prevData, ...commentsDataArray];
            if (newData.length > PER_PAGE_COUNT.MAX_COMMENTS_DISPLAY_COUNT) {
              return newData.slice(PER_PAGE_COUNT.COMMENTS_ITEMS_COUNT);
            }
            return newData;
          });
        }
        setTotalPhotoComments(data?.total);
        setPhotoCommentsLastPage(data?.last_page);
        setIsPhotosCommentsLoading(false);
        if (isMorePhotosCommentsLoading) {
          setIsMorePhotosCommentsLoading(false);
        }
        if (isDeletingPhotoComment) {
          setIsDeletingPhotoComment(false);
        }
      }
    } catch (err) {
      setIsPhotosCommentsLoading(false);
      if (isMorePhotosCommentsLoading) {
        setIsMorePhotosCommentsLoading(false);
      }
    }
  };

  // This function will like the comment for the gallery
  const handleLikeComment = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.LIKE_GALLERY_COMMENT}${id}/like`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (res?.status === 200) {
        ReactGA.event({
          category: "Comments",
          action: `Liked/Unliked a comment`,
          label: `Liked/Unliked a comment on ${galleryDetails?.name}`,
        });
        ReactGA.event({
          category: "Comments",
          action: `Liked/Unliked a comment on ${galleryDetails?.name}`,
          label: `Liked/Unliked a comment on ${galleryDetails?.name}`,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to like comment",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
    }
  };

  // This function will post the comment for the gallery
  const handlePostComment = (value) => {
    const body = {
      comment: value,
      ...(id && { parent_comment_id: id }),
    };
    axios
      .post(url + `/comment`, body, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (id) {
          ReactGA.event({
            category: "Comments",
            action: `Replied on a comment`,
            label: `Replied on a comment on ${galleryDetails?.name}`,
          });
          ReactGA.event({
            category: "Comments",
            action: `Replied on a comment on ${galleryDetails?.name}`,
            label: `Replied on a comment on ${galleryDetails?.name}`,
          });
        } else {
          ReactGA.event({
            category: "Comments",
            action: `New comment posted`,
            label: `Posted a comment on ${galleryDetails?.name}`,
          });
          ReactGA.event({
            category: "Comments",
            action: `New comment posted on ${galleryDetails?.name}`,
            label: `Posted a comment on ${galleryDetails?.name}`,
          });
        }
        getCommentsData();
      });
  };

  // This function will be used to store the comment id on which you are replying for the gallery
  const onReply = (id) => {
    setId(id);
  };

  // This function will post the comment for the gallery's photo
  const handlePostPhotoComment = (value, id) => {
    const body = {
      comment: value,
      ...(photoId && { parent_comment_id: photoId }),
    };
    try {
      axios
        .post(SERVER_URL.COMMENT_GALLERY_PHOTO + `${id}/comment`, body, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((res) => {
          if (res?.status === 200) {
            if (photoId) {
              ReactGA.event({
                category: "Comments",
                action: `Replied on a comment`,
                label: `Replied on a comment on photo of ${galleryDetails?.name}`,
              });
              ReactGA.event({
                category: "Comments",
                action: `Replied on a comment on photo of ${galleryDetails?.name}`,
                label: `Replied on a comment on photo of ${galleryDetails?.name}`,
              });
            } else {
              ReactGA.event({
                category: "Comments",
                action: `New comment posted`,
                label: `Posted a comment on photo of ${galleryDetails?.name}`,
              });
              ReactGA.event({
                category: "Comments",
                action: `New comment posted on photo of ${galleryDetails?.name}`,
                label: `Posted a comment on photo of ${galleryDetails?.name}`,
              });
            }
            if (photoCommentsId) {
              getPhotoCommentsData();
            }
            if (photoId) {
              setPhotoId(null);
              getPhotoCommentsData();
            }
          }
        });
    } catch (err) {
      Swal.fire({
        title: "Unable to post comment",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
    }
  };

  // This function will be used to store the comment id on which you are replying for the gallery's photo
  const onReplyPhoto = (id) => {
    setPhotoId(id);
  };

  function getAllCommentsLength() {
    var lengthOfChildren = 0;
    for (var i = 0; i < commentsData.length; i++) {
      var replies = commentsData[i].children;
      lengthOfChildren += replies.length;
    }

    return lengthOfChildren + commentsData.length;
  }

  const handleGetRatings = async () => {
    try {
      const res = await axios.get(getRatingUrl, {
        headers: {
          Accept: "application/json",
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          if (linkableType === "Event") {
            setRatings(convertData(res?.data, EventAspects));
          } else {
            setRatings(convertData(res?.data, CarnivalAspects));
          }
        }
      }
    } catch (err) {}
  };

  const handleSubmitRatings = async (value) => {
    let body = {};
    if (linkableType === "Event")
      body = {
        crowd_score: value?.Crowd,
        vibe_score: value?.Vibe,
        venue_score: value?.Venue,
        food_score: value?.Food,
        service_score: value?.Service,
      };
    else {
      body = {
        affordability_score: value.Affordability,
        costumes_score: value.Costumes,
        events_score: value.Events,
        food_score: value.Food,
        music_score: value.Music,
        safety_score: value.Safety,
        culture_score: value.Culture,
      };
    }
    try {
      const res = await axios.post(submitRatingUrl, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (res.status === 200) {
        ReactGA.event({
          category: "Rating's Submitted",
          action: `Submit your ratings button clicked`,
          label: galleryDetails?.name,
        });
        ReactGA.event({
          category: "Rating's Submitted",
          action: `Submit your ratings button clicked for ${galleryDetails?.name}`,
          label: galleryDetails?.name,
        });
        handleGetRatings();
        Swal.fire({
          title: "Ratings submitted successfully",
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to submit your rating",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    if (slugUrl !== "") {
      if (isLoggedInUser) {
        if (user?.token) {
          getGalleryData();
        }
      } else {
        getGalleryData();
      }
    }
  }, [photosPageNumber, slugUrl, isLoggedInUser, user]);

  useEffect(() => {
    if (photoId) {
      getPhotoCommentsData();
    }
  }, [photoCommentsPageNumber]);

  useEffect(() => {
    if (url !== "") {
      getCommentsData();
      GetRelatedGalleries();
      getGalleryReviews();
    }
  }, [commentsPageNumber, url]);

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  // This function is used to like the gallery
  const handleLike = async (item) => {
    if (isLoggedIn) {
      setIsLiking(true);
      try {
        const res = await axios.get(url + "/like", {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        });
        if (res.status === 200) {
          Swal.fire({
            title: res?.data?.message,
            icon: "success",
            width: 600,
            padding: "3em",
            color: "#fff",
            background: "#000",
            timer: 2000,
            showConfirmButton: false,
          });
        }
        setIsLiking(false);
        setLiked(!liked);
        if (item === "like") {
          setLikeCount((prev) => prev + 1);
          ReactGA.event({
            category: "Gallery",
            action: `Liked a gallery`,
            label: `Liked a gallery: ${galleryDetails?.name}`,
          });
          ReactGA.event({
            category: "Gallery",
            action: `Liked a gallery: ${galleryDetails?.name}`,
            label: `Liked a gallery: ${galleryDetails?.name}`,
          });
        } else {
          setLikeCount((prev) => prev - 1);
          ReactGA.event({
            category: "Gallery",
            action: `Unliked a gallery`,
            label: `Unliked a gallery: ${galleryDetails?.name}`,
          });
          ReactGA.event({
            category: "Gallery",
            action: `Unliked a gallery: ${galleryDetails?.name}`,
            label: `Unliked a gallery: ${galleryDetails?.name}`,
          });
        }
      } catch (err) {
        setIsLiking(false);
        Swal.fire({
          title: "Unable to like the gallery",
          icon: "error",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } else {
      handleLogin();
    }
  };

  // This function is used to like the gallery's photo
  const onLikePhoto = async (id) => {
    if (isLoggedIn) {
      try {
        const res = await axios.get(
          SERVER_URL.LIKE_GALLERY_PHOTO + `${id}/like`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        if (res.status === 200) {
          ReactGA.event({
            category: "Photo",
            action: `Liked/Unliked a photo`,
            label: `Liked/Unliked a photo in ${galleryDetails?.name}`,
          });
          ReactGA.event({
            category: "Photo",
            action: `Liked/Unliked a photo on ${galleryDetails?.name}`,
            label: `Liked/Unliked a photo in ${galleryDetails?.name}`,
          });
          Swal.fire({
            title: res?.data?.message,
            icon: "success",
            width: 600,
            padding: "3em",
            color: "#fff",
            background: "#000",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      } catch (err) {
        Swal.fire({
          title: "Unable to like the photo",
          icon: "error",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } else {
      handleLogin();
    }
  };

  // This function is used to delete the gallery's comment
  const handleDeleteComment = (value) => {
    setIsDeletingComment(true);
    axios
      .get(`${SERVER_URL.DELETE_COMMENT}${value}/delete`, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        ReactGA.event({
          category: "Comments",
          action: `Deleted a comment`,
          label: `Deleted a comment of ${galleryDetails?.name}`,
        });
        ReactGA.event({
          category: "Comments",
          action: `Deleted a comment of ${galleryDetails?.name}`,
          label: `Deleted a comment of ${galleryDetails?.name}`,
        });
        if (commentsPageNumber === 1) {
          getCommentsData();
        } else {
          setCommentsPageNumber(1);
        }
      });
  };

  // This function is used to delete the gallery's photo's comment
  const handleDeletePhotoComment = (value, id) => {
    setIsDeletingPhotoComment(true);
    try {
      axios
        .get(`${SERVER_URL.DELETE_COMMENT}${value}/delete`, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((res) => {
          ReactGA.event({
            category: "Comments",
            action: `Deleted a comment`,
            label: `Deleted a comment of photo of ${galleryDetails?.name}`,
          });
          ReactGA.event({
            category: "Comments",
            action: `Deleted a comment of photo of ${galleryDetails?.name}`,
            label: `Deleted a comment of photo of ${galleryDetails?.name}`,
          });
          if (photoCommentsPageNumber === 1) {
            getPhotoCommentsData(id);
          } else {
            setPhotoCommentsPageNumber(1);
          }
        });
    } catch (err) {
      setIsDeletingPhotoComment(false);
    }
  };

  function handleShareGallery(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Share Button Clicked",
      label: `${label} Shared on ${item} from gallery details page`,
    });
    ReactGA.event({
      category: "Share",
      action: `${galleryDetails?.name} Shared on ${item}`,
      label: `${label} Shared on ${item} from gallery details page`,
    });
  }

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Lighbox Gallery Share Button Clicked",
      label: `${label} Shared on ${item} from photos on ${galleryDetails?.name}`,
    });
    ReactGA.event({
      category: "Share",
      action: `${label} Shared on ${item} from ${galleryDetails?.name}`,
      label: `${label} Shared on ${item} from photos on ${galleryDetails?.name}`,
    });
  }

  function handleDownload() {
    ReactGA.event({
      category: "Download",
      action: "Lighbox Gallery download Button Clicked",
      label: `A image is downloaded from photos on ${galleryDetails?.name}`,
    });
  }

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={galleryDetails?.name}
        url={window.location.href}
        image={imageData[0]?.src}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          {isGalleryLoading ? (
            <GalleryImagesLoader />
          ) : (
            <>
              <div className={GalleryCss.gridHeading}>
                <Label
                  size="lg"
                  label={`${galleryDetails?.name} (${imageData.length}/${totalPhotos})`}
                />
                <div className={GalleryCss.actionButtons}>
                  <div className={GalleryCss.actionButtonsDiv}>
                    <SharingTooltip
                      title={`checkout this amazing gallery of ${galleryDetails?.name}`}
                      link={window.location.href}
                      transparent
                      fontSize={22}
                      onShare={handleShareGallery}
                    />
                    {galleryDetails?.is_liked && liked ? (
                      <BiSolidLike
                        className={GalleryCss.likeButton}
                        onClick={() => !isLiking && handleLike("unlike")}
                      />
                    ) : liked ? (
                      <BiSolidLike
                        className={GalleryCss.likeButton}
                        onClick={() => !isLiking && handleLike("unlike")}
                      />
                    ) : (
                      <BiLike
                        className={GalleryCss.likeButton}
                        onClick={() => !isLiking && handleLike("like")}
                      />
                    )}
                    <div className={GalleryCss.likeCount}>
                      {likeCount && convertNumUnit(likeCount)}
                    </div>
                  </div>
                </div>
              </div>
              <LightBoxGallery
                imageArray={imageData}
                imageIndex={selectedPhotoId}
                onChangeImageIndex={(val) => {
                  updatePageURL(val.index);
                }}
                onClose={() => {
                  setSelectedPhotoId(undefined);
                  updatePageURL(-1);
                }}
                setMoreIsLoading={(i) => setIsMoreGalleryLoading(i)}
                onLikePhoto={onLikePhoto}
                isLoggedIn={isLoggedIn}
                onLike={handleLikeComment}
                onShare={handleShare}
                onDownload={handleDownload}
                onButtonClick={handlePostPhotoComment}
                onReply={onReplyPhoto}
                getComments={(id) => {
                  getPhotoCommentsData(id);
                  setIsPhotosCommentsLoading(true);
                }}
                moreCommentsLoading={isMorePhotosCommentsLoading}
                isCommentsLoading={isPhotosCommentsLoading}
                updatePhotoCommentPageNumber={updatePhotoCommentPageNumber}
                showLoadMore={
                  photoCommentsPageNumber < photoCommentsLastPage &&
                  photoCommentsLastPage &&
                  photoCommentsData?.length !== 0 &&
                  !isMorePhotosCommentsLoading &&
                  !isPhotosCommentsLoading
                }
                comments={photoCommentsData}
                totalComments={totalPhotoComments}
                handleDelete={handleDeletePhotoComment}
                isDeleting={isDeletingPhotoComment}
              />
              {isMoreGalleryLoading ? (
                <GalleryImagesLoader />
              ) : photosPageNumber < photosLastPage ? (
                <ViewMore loadMore onClick={updatePhotoPageNumber} />
              ) : (
                <div className={GalleryCss.mgb} />
              )}
            </>
          )}
        </Grid>
        <Grid className={GalleryCss.gridContainer2} item xs={12} sm={12} md={4}>
          {ratings && (
            <>
              <div className={GalleryCss.ratingSubDiv}>
                <Label size="md" label={"Score"} />
                <RatingModal
                  args={
                    linkableType === "Event" ? EventAspects : CarnivalAspects
                  }
                  onClick={(val) => handleSubmitRatings(val)}
                />
              </div>
              <div className={GalleryCss.ratingBoxDiv}>
                <RatingBox
                  tjjRatingPercentage={
                    ((galleryDetails?.linkable?.tjj_rating ?? 0) / 5) * 100
                  }
                  userRatingPercentage={
                    ((galleryDetails?.linkable?.user_rating ?? 0) / 5) * 100
                  }
                  multiline
                />
              </div>
              <div className={GalleryCss.mgb}>
                <RatingComp ratings={ratings} />
              </div>
            </>
          )}
          <Label size="md" label={`Comments (${getAllCommentsLength()})`} />
          {isCommentsLoading ? (
            <CommentLoader />
          ) : (
            <>
              <CommentList
                data={commentsData}
                onLike={handleLikeComment}
                onReply={onReply}
                onButtonClick={(value) => handlePostComment(value)}
                handleDelete={handleDeleteComment}
                isDeleting={isDeletingComment}
              />
              {isMoreCommentsLoading && <CommentLoader />}
              {commentsPageNumber < commentsLastPage &&
                commentsLastPage &&
                commentsData?.length !== 0 &&
                !isMoreCommentsLoading &&
                !isCommentsLoading && (
                  <ViewMore loadMore onClick={updateCommentPageNumber} />
                )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid container className={GalleryCss.contentDiv}>
        <Grid item xs={12} md={4} className={GalleryCss.gridGeneral}>
          <div className={GalleryCss.flexDR}>
            <div className={GalleryCss.headingStyle}>
              <DescriptionIcon className={GalleryCss.locationIcon} />
              Description
            </div>
            <div className={GalleryCss.contentStyle}>
              {!isGalleryLoading && galleryDetails?.description ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: galleryDetails?.description,
                  }}
                />
              ) : (
                <div className={GalleryCss.noHighlightedText}>
                  No description found
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} className={GalleryCss.spacingLR}>
          <div className={GalleryCss.flexDR}>
            <div className={GalleryCss.headingStyle}>
              <LocationOn className={GalleryCss.locationIcon} />
              Location
            </div>
            {galleryDetails?.location && (
              <iframe
                src={galleryDetails?.location?.google_map_embed_url}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                className={GalleryCss.imgIcon}
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            )}
            <div className={GalleryCss.contentStyle}>
              {!isGalleryLoading && galleryDetails?.location ? (
                <>
                  {" "}
                  <div>{LocationExtractor(galleryDetails?.location)}</div>
                  <div
                    className={GalleryCss.highlightedText}
                    onClick={() => {
                      ReactGA.event({
                        category: "Location",
                        action: "Opened in google maps",
                        label: galleryDetails?.name,
                      });
                      ReactGA.event({
                        category: "Location",
                        action: `${galleryDetails?.name} opened in google maps`,
                        label: galleryDetails?.name,
                      });
                      window.open(galleryDetails?.location?.google_map_url);
                    }}
                  >
                    <FaLocationArrow className={GalleryCss.redirectIcon} />
                    Open in Google Maps
                  </div>
                </>
              ) : (
                <div className={GalleryCss.noHighlightedText}>
                  No Location Found
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} className={GalleryCss.gridGeneralL}>
          <div className={GalleryCss.flexDR}>
            <div className={GalleryCss.headingStyle}>
              <InfoIcon className={GalleryCss.locationIcon} />
              More Info
            </div>
            <div className={GalleryCss.flexDR}>
              {linkableType !== "" && galleryDetails?.linkable && (
                <>
                  <div className={GalleryCss.moreText}>
                    <div className={GalleryCss.moreTitle}>
                      {linkableType === "Event" ? "Event" : "Carnival"}:
                    </div>
                    <div
                      onClick={() => {
                        linkableType === "Event"
                          ? handleClick(
                              `/events/${galleryDetails?.linkable?.slug}`
                            )
                          : handleClick(
                              `/carnival/${galleryDetails?.linkable?.slug}`
                            );
                      }}
                      className={GalleryCss.moreDescription}
                    >
                      {galleryDetails?.linkable?.title}
                    </div>
                  </div>
                  <div className={GalleryCss.moreText}>
                    <div className={GalleryCss.moreTitle}>
                      {linkableType === "Event" ? "Event" : "Carnival"} Date:
                    </div>
                    <div>
                      {EventDateExtractor(
                        galleryDetails?.linkable?.start_datetime
                      )}
                    </div>
                  </div>
                </>
              )}
              {linkableType === "Event" &&
                galleryDetails?.linkable?.promoters &&
                galleryDetails?.linkable?.promoters?.length !== 0 && (
                  <div className={GalleryCss.moreText}>
                    <div className={GalleryCss.moreTitle}>{"Promoter(s)"}:</div>
                    <div>
                      {galleryDetails?.linkable?.promoters?.map(
                        (item, index) => {
                          return (
                            <span>
                              {item?.display_name}
                              {index <
                              galleryDetails?.linkable?.promoters?.length - 1
                                ? ", "
                                : ""}
                            </span>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              {galleryDetails &&
                galleryDetails?.photographers?.length !== 0 && (
                  <div className={GalleryCss.moreText}>
                    <div className={GalleryCss.moreTitle}>
                      {"Photographer(s)"}:
                    </div>
                    <div>
                      {galleryDetails?.photographers?.map((item, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => {
                              handleNavigate({
                                photographer: item?.slug,
                              });
                            }}
                            className={GalleryCss.moreDescription}
                          >
                            {item?.first_name + " " + item?.last_name}
                            {index < galleryDetails?.photographers?.length - 1
                              ? ", "
                              : ""}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              {!isGalleryLoading &&
                linkableType === "" &&
                galleryDetails?.photographers?.length === 0 && (
                  <div className={GalleryCss.noHighlightedText}>
                    No more information
                  </div>
                )}
              <div className={GalleryCss.mgt10} />
            </div>
          </div>
        </Grid>
      </Grid>
      {galleries && galleries?.length !== 0 && (
        <Label size="md" label={"Related Gallery"} />
      )}
      <div className={GalleryCss.mgb}>
        {isLoading ? (
          <>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="text"
              width={"30%"}
              height={40}
            />
            <div className={GalleryCss.flexDrR}>
              {Array.from({ length: 8 }, (_, index) => {
                return <GalleryCardLoader key={index} />;
              })}
            </div>
          </>
        ) : (
          galleries &&
          galleries?.length !== 0 && (
            <>
              <GalleryList gallery={galleries} refresh={true} />
            </>
          )
        )}
      </div>
      <div className={GalleryCss.mgb40} />
      {blogs && blogs?.length !== 0 && (
        <Label size="md" label={"Gallery Reviews"} />
      )}
      {blogsIsLoading ? (
        <>
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={"30%"}
            height={40}
          />
          <div className={GalleryCss.flexDrR}>
            {Array.from({ length: 4 }, (_, index) => {
              return <NewsCardLoader key={index} />;
            })}
          </div>
        </>
      ) : (
        blogs &&
        blogs?.length !== 0 && (
          <>
            <NewsList news={blogs} share={true} />
          </>
        )
      )}
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
});

export default connect(mapStateToProps)(ViewGallery);

function removeScoreFromKeys(ratingObj) {
  const newRatingObj = {};
  for (const key in ratingObj) {
    if (Object.prototype.hasOwnProperty.call(ratingObj, key)) {
      const newKey = key.slice(0, -6);
      const capitalizedNewKey =
        newKey.charAt(0).toUpperCase() + newKey.slice(1);
      newRatingObj[capitalizedNewKey] = ratingObj[key];
    }
  }
  return newRatingObj;
}

function convertData(data, aspects) {
  const convertedData = aspects.map((aspect, index) => ({
    name: aspect,
    tjjRating:
      data?.tjj_rating &&
      Object.keys(data?.tjj_rating).length > 0 &&
      data?.tjj_rating[`${aspect.toLowerCase()}_score`]
        ? data?.tjj_rating[`${aspect.toLowerCase()}_score`]
        : 0,
    userRating:
      data?.user_rating &&
      Object.keys(data?.user_rating).length > 0 &&
      data?.user_rating[`${aspect.toLowerCase()}_score`]
        ? data?.user_rating[`${aspect.toLowerCase()}_score`]
        : 0,
  }));
  return convertedData;
}
