import React, { useEffect, useState } from "react";
import ClassifiedCardCss from "./ClassifiedCard.module.css";
import { GrExpand } from "react-icons/gr";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Fullscreen } from "yet-another-react-lightbox/plugins";
import SharingTooltip from "../sharing-tooltip/SharingTooltip";
import { AiFillMessage } from "react-icons/ai";
import Chip from "../chip/Chip";
import { connect } from "react-redux";
import Button from "../button/Button";
import { Delete } from "@mui/icons-material";
import IconButton from "../icon-button/IconButton";
import Swal from "sweetalert2";
import ReactGA from "react-ga4";

function ClassifiedCard({
  user = {},
  data = {},
  onMessageClick = undefined,
  onChipClick = undefined,
  shareLink = window.location.href.split("?")[0],
  onClick = undefined,
  disabled = false,
  showDelete = false,
  handleDelete = undefined,
}) {
  const [index, setIndex] = useState(-1);
  const [imageArr, setImageArr] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleDeleteConfirmation(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this classified?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff0000",
      confirmButtonText: "Delete",
      color: "#fff",
      background: "#000",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      }
    });
  }

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Event's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `${data?.title} Shared on ${item}`,
      label: label,
    });
  }

  const loadImage = async (imgArr) => {
    const img = new Image();
    img.src = imgArr;
    img.onload = () => {
      const a = [
        {
          src: imgArr,
          height: img.height,
          width: img.width,
        },
      ];
      setImageArr(a);
    };
    img.onerror = () => {};
  };

  useEffect(() => {
    if (data?.image_url) {
      loadImage(data?.image_url);
    }
  }, [data]);

  return (
    <div className={ClassifiedCardCss.classifiedCardMainDiv}>
      <div
        onClick={onClick}
        className={
          disabled
            ? ClassifiedCardCss.unClickableDiv
            : ClassifiedCardCss.clickableDiv
        }
      >
        <div className={ClassifiedCardCss.flexDiv}>
          <div className={ClassifiedCardCss.flex}>
            {data?.user?.profile_photo_url ? (
              <img
                className={ClassifiedCardCss.classifiedCardAvatar}
                src={data?.user?.profile_photo_url}
                alt="user"
              />
            ) : (
              <div className={ClassifiedCardCss.classifiedChatCardAvatar}>
                <div className={ClassifiedCardCss.classifiedChatCardUsername}>
                  {data?.user?.first_name[0]}
                </div>
              </div>
            )}
            <div className={ClassifiedCardCss.classifiedCardDiv2}>
              <div className={ClassifiedCardCss.classifiedCardUsername}>
                {data?.user?.first_name} {data?.user?.last_name}
              </div>
              <div className={ClassifiedCardCss.flexCenter}>
                <div className={ClassifiedCardCss.classifiedCardText}>
                  {convertTime(data?.created_at)} ago
                </div>
                {data?.type && (
                  <>
                    <div className={ClassifiedCardCss.dot} />
                    <div className={ClassifiedCardCss.classifiedCardType}>
                      {data?.type}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {data?.title && (
          <div className={ClassifiedCardCss.classifiedCardTitle}>
            {data?.title}
          </div>
        )}
        {data?.content && (
          <div
            onClick={() => {
              showDelete && onClick();
            }}
            className={ClassifiedCardCss.classifiedCardBody}
            dangerouslySetInnerHTML={{
              __html: data?.content,
            }}
          />
        )}
      </div>
      {data?.image_url && (
        <>
          <div className={ClassifiedCardCss.imageDiv}>
            <img
              className={ClassifiedCardCss.image}
              src={data?.image_url}
              alt="Image"
            />
            {!disabled && (
              <GrExpand
                className={ClassifiedCardCss.expand}
                onClick={() => setIndex(0)}
              />
            )}
          </div>
          <Lightbox
            index={index}
            slides={imageArr}
            open={index >= 0}
            close={() => {
              setIndex(-1);
            }}
            plugins={[Fullscreen]}
            render={{
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
            carousel={{ preload: 0 }}
          />
        </>
      )}
      {data?.tags && data?.tags?.length !== 0 && (
        <div className={ClassifiedCardCss.flexCenter}>
          {data?.tags.map((item, index) => {
            return disabled ? (
              <Chip key={index} label={item} />
            ) : (
              <Chip
                key={index}
                label={item?.slug}
                handleClick={() => {
                  onChipClick && onChipClick(item?.slug);
                }}
              />
            );
          })}
        </div>
      )}
      <div className={ClassifiedCardCss.flexCenter}>
        {data?.user?.id !== user?.id && (
          <div
            className={
              disabled
                ? ClassifiedCardCss.unClickableFlexCenter5
                : ClassifiedCardCss.flexCenter5
            }
            onClick={onMessageClick}
          >
            <AiFillMessage className={ClassifiedCardCss.message} />
            <div className={ClassifiedCardCss.classifiedCardText}>Message</div>
          </div>
        )}
        {showDelete &&
          user?.id === data?.user?.id &&
          (width < 500 ? (
            <IconButton
              backgroundColor={"transparent"}
              icon={<Delete className={ClassifiedCardCss.closeIcon} />}
              onClick={() => {
                handleDelete && handleDeleteConfirmation(data?.id);
              }}
            />
          ) : (
            <Button
              iconLeft
              icon={<Delete className={ClassifiedCardCss.closeIcon} />}
              label="Delete"
              onClick={() => {
                handleDelete && handleDelete(data?.id);
              }}
              backgroundColor={"transparent"}
            />
          ))}
        <SharingTooltip
          onShare={handleShare}
          withText
          backgroundColor={"transparent"}
          title={data?.content}
          link={shareLink + `/${data?.slug}`}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

const convertTime = (createdAt) => {
  const createdAtDate = new Date(createdAt);
  const now = new Date();

  const diffMs = now - createdAtDate;
  const diffSeconds = Math.floor(diffMs / 1000);
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  if (diffSeconds < 60) {
    const sec = diffSeconds + (diffSeconds === 1 ? " second" : " seconds");
    return `${sec}`;
  } else if (diffMinutes < 60) {
    const min = diffMinutes + (diffMinutes === 1 ? " minute" : " minutes");
    return `${min}`;
  } else if (diffHours < 24) {
    const hour = diffHours + (diffHours === 1 ? " hour" : " hours");
    return `${hour}`;
  } else if (diffDays < 30) {
    const day = diffDays + (diffDays === 1 ? " day" : " days");
    return `${day}`;
  } else if (diffMonths < 12) {
    const month = diffMonths + (diffMonths === 1 ? " month" : " months");
    return `${month}`;
  } else {
    const year = diffYears + (diffYears === 1 ? " year" : " years");
    return `${year}`;
  }
};

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps)(ClassifiedCard);
