import React from "react";
import { Skeleton } from "@mui/material";
export default function SingleAuthorLoader() {
  function getRandomPercentage() {
    const randomNumber = Math.floor(Math.random() * (100 - 60 + 1)) + 60;
    return randomNumber.toString() + "%";
  }
  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="circular"
        width={"50px"}
        height={"50px"}
      />
      <div style={{ width: "60%" }}>
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          animation="wave"
          variant="text"
          width={getRandomPercentage()}
        />
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          animation="wave"
          variant="text"
          width={getRandomPercentage()}
        />
      </div>
    </div>
  );
}
