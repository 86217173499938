import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClassifiedCard from "../../components/classified-card/ClassifiedCard";
import { Context } from "../../Context";
import Label from "../../elements/label/Label";
import ClassifieldsLoader from "../../elements/skeletonLoaders/ClassifiedsLoader";
import { Skeleton } from "@mui/material";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { SERVER_URL } from "../../Constants.ts";
import NoDataScreen from "../no-data/NoDataScreen.js";
import axios from "axios";
import ReactGA from "react-ga4";

const CarnivalClassifiedDetail = ({ auth }) => {
  const { slug } = useParams()
  const { pathname } = useLocation();
  const [data, setData] = useState({});
  const { setPageTitle } = useContext(Context);
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const navigate = useNavigate();
  const [slugUrl, setSlugUrl] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(()=>{
    ReactGA.send({
      hitType: "pageview",
      page: `/carnival/classifieds/${slug}`,
      title: "Carnival Classifieds Details",
    });
  },[])

  let user;

  useEffect(() => {
    if (data?.title) {
      setPageTitle(data?.title);
    } else {
      setPageTitle("Classified Details");
    }
  }, [data]);

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[3];
      setSlugUrl(`${SERVER_URL.GET_CLASSIFIED_DETAILS}${u}`);
    }
  }, [pathname]);

  useEffect(() => {
    if (slugUrl) {
      handleGetDetails();
    }
  }, [slugUrl]);

  const handleGetDetails = async () => {
    try {
      const res = await axios.get(slugUrl, {
        headers: {
          Accept: "application/json",
        },
      });
      setStatus(res.status);
      if (res.status === 200) {
        if (res?.data && res?.data?.classified) {
          setData(res?.data?.classified);
          user = {
            ...res?.data?.classified?.user,
            rating: res?.data?.classified?.rating,
          };
        }
        setDataIsLoading(false);
      }
    } catch (err) {
      setStatus(err.response.status);
      setDataIsLoading(false);
    }
  };

  const handleNavigate = (param) => {
    const params = `tag=${param.tag}`;
    const route = "/carnival/classifieds?" + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  const handleLogin = () => {
    Swal.fire({
      title: "You are not logged in!",
      text: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  const handleChat = (user) => {
    const userId = auth?.user?.slug;
    if (userId) {
      navigate(`/carnival/classifieds/${userId}/inbox/chatbox`, {
        state: { user: user },
      });
      window.scrollTo(0, 0);
    } else {
      handleLogin();
    }
  };

  if (status && status !== 200) {
    return <NoDataScreen />;
  }

  return (
    <NavContainer>
      {dataIsLoading ? (
        <>
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 1.5 }}
            animation="wave"
            variant="text"
            width={150}
            height={40}
          />
          <ClassifieldsLoader />
        </>
      ) : (
        data && (
          <>
            <Label label={data?.title} size="lg" />
            <ClassifiedCard
              data={data}
              onMessageClick={() => handleChat(user)}
              shareLink={window.location.href?.split("?")[0]}
              onChipClick={(i) => handleNavigate({ tag: i })}
            />
          </>
        )
      )}
    </NavContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CarnivalClassifiedDetail);
