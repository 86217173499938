import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import { Context } from "../../Context";
import InboxCard from "../../components/inbox-card/InboxCard";
import InboxCss from "./CarnivalClassifieds.module.css";
import InboxLoader from "../../elements/skeletonLoaders/InboxLoader";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import ViewMore from "../../elements/view-more/ViewMore.js";
import ReactGA from "react-ga4";

const CarnivalInbox = ({ auth }) => {
  const { id } = useParams();
  const { setPageTitle } = useContext(Context);
  const [inbox, setInbox] = useState([]);
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [moreDataIsLoading, setMoreDataIsLoading] = useState(true);
  const [inboxPageNumber, setInboxPageNumber] = useState(1);
  const [inboxLastPage, setInboxLastPage] = useState(null);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/carnival/classifieds/${id}/inbox`,
      title: "Carnival Inbox",
    });
  }, []);

  useEffect(() => {
    setPageTitle("Inbox");
  }, []);

  useEffect(() => {
    if (auth?.user?.token) {
      handleGetData();
    }
  }, [auth, inboxPageNumber]);

  function updateInboxPageNumber() {
    if (inboxLastPage != null && inboxPageNumber <= inboxLastPage) {
      setMoreDataIsLoading(true);
      setInboxPageNumber(inboxPageNumber + 1);
    }
  }

  const handleGetData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_INBOX_DATA, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${auth?.user?.token}`,
        },
        params: {
          page: inboxPageNumber,
          items: PER_PAGE_COUNT.GET_INBOX,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.inboxes && res?.data?.inboxes?.data) {
          setTotal(res?.data?.inboxes?.total);
          setInboxLastPage(res?.data?.inboxes?.last_page);
          if (inboxPageNumber === 1) {
            setInbox(res?.data?.inboxes?.data);
          } else {
            setInbox((prev) => [...prev, ...res?.data?.inboxes?.data]);
          }
        }
        setDataIsLoading(false);
        if (moreDataIsLoading) {
          setMoreDataIsLoading(false);
        }
      }
    } catch (err) {
      setDataIsLoading(false);
      if (moreDataIsLoading) {
        setMoreDataIsLoading(false);
      }
    }
  };

  const handleLogin = () => {
    Swal.fire({
      title: "You are not logged in!",
      text: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  const handleChat = (user) => {
    const userId = auth?.user?.slug;
    if (userId) {
      navigate(`/carnival/classifieds/${userId}/inbox/chatbox`, {
        state: { user: user },
      });
      window.scrollTo(0, 0);
    } else {
      handleLogin();
    }
  };

  return (
    <NavContainer>
      <Label label={`Inbox (${total})`} size="lg" center />
      <div className={InboxCss.inboxDiv}>
        <div className={InboxCss.inboxSubDiv}>
          {dataIsLoading ? (
            <InboxLoader />
          ) : (
            <>
              {inbox?.map((item, index) => {
                const user =
                  item?.from_user_id === auth?.user?.id
                    ? item?.to_user
                    : item?.from_user;
                const user1 = { ...user, rating: item?.rating };
                return (
                  <InboxCard
                    data={item}
                    user={user1}
                    onClick={() => handleChat(user1)}
                    key={index}
                  />
                );
              })}
              {moreDataIsLoading && <InboxLoader />}
              {inbox &&
              inbox?.length !== 0 &&
              inboxLastPage !== null &&
              inboxPageNumber < inboxLastPage &&
              !dataIsLoading &&
              !moreDataIsLoading ? (
                <ViewMore loadMore onClick={updateInboxPageNumber} />
              ) : (
                <div className={InboxCss.mgb} />
              )}
            </>
          )}
        </div>
      </div>
    </NavContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CarnivalInbox);
