import * as React from "react";

import { ComponentProps, makeUseContext } from "yet-another-react-lightbox";
import { CommentsRef } from "../index.ts";
import { resolveCommentsProps } from "./props.ts";

export const CommentsContext = React.createContext<CommentsRef | null>(null);

export const useComments = makeUseContext("useComments", "CommentsContext", CommentsContext);

export function CommentsContextProvider({ comments, children }: ComponentProps) {
  const { ref } = resolveCommentsProps(comments);

  const [visible, setVisible] = React.useState(false);

  const context = React.useMemo(
    () => ({
      visible,
      show: () => setVisible(true),
      hide: () => setVisible(false),
    }),
    [visible],
  );

  React.useImperativeHandle(ref, () => context, [context]);

  return <CommentsContext.Provider value={context}>{children}</CommentsContext.Provider>;
}
