import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import { Grid, Skeleton } from "@mui/material";
import Label from "../../elements/label/Label";
import BlogCss from "./NewsArticles.module.css";
import { popularNewsArticlesCard } from "./Common";
import { Context } from "../../Context";
import CommentList from "../../components/comment/CommentList";
import axios from "axios";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip";
import { useNavigate, useLocation } from "react-router";
import Chip from "../../components/chip/Chip";
import SlugConverter from "../../elements/slug-converter/SlugConverter";
import MetaTags from "../../MetaTags";
import ChipsLoader from "../../elements/skeletonLoaders/ChipsLoader";
import MiniNewsCard from "../../elements/skeletonLoaders/MiniNewsCard";
import CommentLoader from "../../elements/skeletonLoaders/CommentLoader";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import { connect } from "react-redux";
import ViewMore from "../../elements/view-more/ViewMore.js";
import NoDataScreen from "../no-data/NoDataScreen.js";
import Swal from "sweetalert2";
import ReactGA from "react-ga4";
import DynamicContentInjector from "../../elements/DynamicContentInjector/DynamicContentInjector.js";
import { insertInterstitialAds } from "../../elements/InsertInterstitialAds/InsertInterstitialAds.js";

function NewsArticleDetails({ isLoggedIn, user }) {
  const [isLoading, setIsLoading] = useState(true);
  const { setPageTitle } = useContext(Context);
  const [width, setWidth] = useState(window.innerWidth);
  const [relatedNewsArticles, setRelatedNewsArticles] = useState(null);
  const [recentNewsArticles, setRecentNewsArticles] = useState(null);
  const [commentsData, setCommentsData] = useState([]);
  const [slugUrl, setSlugUrl] = useState(null);
  const navigate = useNavigate();
  const [commentsPage, setCommentsPage] = useState(1);
  const [commentsIsLoading, setCommentsIsLoading] = useState(true);
  const [commentsLastPage, setCommentsLastPage] = useState(null);
  const [moreCommentsIsLoading, setMoreCommentsIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [url, setUrl] = useState(null);
  const [details, setDetails] = useState(null);
  const [relatedNewsArticlesIsLoading, setRelatedNewsArticlesIsLoading] =
    useState(true);
  const [recentNewsArticlesIsLoading, setRecentNewsArticlesIsLoading] =
    useState(true);
  const { pathname } = useLocation();
  const [status, setStatus] = useState(null);
  const [isDeletingComment, setIsDeletingComment] = useState(false);
  const [bannerAd, setBannerAd] = useState(null);
  const [bannerAd2, setBannerAd2] = useState(null);
  const [interstitialAd, setInterstitialAd] = useState([]);
  const [interstitialAdIsLoading, setInterstitialAdIsLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [contentIsLoading, setContentIsLoading] = useState(null);

  const handleGetSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "sidebar ad square",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd(res.data);
        }
      }
    } catch (err) {
      setBannerAd(null);
    }
  };

  const handleGetInterstitialBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "interstitial ad",
          is_array: true,
        },
      });
      if (res.status === 200) {
        setInterstitialAdIsLoading(false);
        if (res.data) {
          setInterstitialAd(res.data);
        }
      }
    } catch (err) {
      setInterstitialAd([]);
      setInterstitialAdIsLoading(false);
    }
  };

  const handleGetSecondSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "skyscraper ad",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd2(res.data);
        }
      }
    } catch (err) {
      setBannerAd2(null);
    }
  };

  useEffect(() => {
    handleGetSidebarBanner();
    handleGetSecondSidebarBanner();
    handleGetInterstitialBanner();
  }, []);

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      setSlugUrl(`${SERVER_URL.GET_NEWSARTICLE_DETAIL}${u}`);
      ReactGA.send({
        hitType: "pageview",
        page: `/news-articles/${u}`,
        title: "NewsArticles Detail",
      });
    }
  }, [pathname]);

  useEffect(() => {
    if (slugUrl) {
      GetNewsArticleDetail();
    }
  }, [slugUrl]);

  useEffect(() => {
    if (details) {
      setUrl(`${SERVER_URL.GET_ALL_NEWSARTICLES}/${details?.id}`);
      handleGetRelated();
    }
  }, [details]);

  const GetNewsArticleDetail = async () => {
    try {
      const res = await axios.get(slugUrl, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
      });
      setStatus(res.status);
      if (res.status === 200) {
        if (res?.data && res?.data?.news_article) {
          setDetails(res?.data?.news_article);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setStatus(err.response.status);
      setIsLoading(false);
    }
  };

  const handleGetComments = async () => {
    try {
      const res = await axios.get(url + "/comments", {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
        params: {
          page: commentsPage,
          items: PER_PAGE_COUNT.COMMENTS_PER_PAGE,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.comments) {
          if (moreCommentsIsLoading) {
            setMoreCommentsIsLoading(false);
          }
          if (res?.data?.comments?.data) {
            if (commentsPage === 1) {
              setCommentsData(res?.data?.comments?.data);
            } else {
              setCommentsData([...commentsData, ...res?.data?.comments?.data]);
            }
          }
          setCommentsLastPage(res?.data?.comments?.last_page);
        }
        setCommentsIsLoading(false);
        if (isDeletingComment) {
          setIsDeletingComment(false);
        }
      }
    } catch (err) {
      setCommentsIsLoading(false);
      if (moreCommentsIsLoading) {
        setMoreCommentsIsLoading(false);
      }
    }
  };

  const handleLikeComment = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.LIKE_NEWSLETTER_COMMENT}${id}/like`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Comments",
          action: `Liked a comment`,
          label: details?.title,
        });
        ReactGA.event({
          category: "Comments",
          action: `Liked a comment on ${details?.title}`,
          label: details?.title,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to like comment",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
    }
  };

  const handleGetRecent = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_NEWSARTICLES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.NEWSARTICLES_ITEM_COUNT,
          orderBy: "created_at",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          setRecentNewsArticles(res?.data?.news_articles?.data);
        }
        setRecentNewsArticlesIsLoading(false);
      }
    } catch (err) {
      setRecentNewsArticlesIsLoading(false);
    }
  };

  const handleGetRelated = async () => {
    let params = {
      page: 1,
      items: PER_PAGE_COUNT.NEWSARTICLES_ITEM_COUNT,
      orderDirection: "desc",
    };
    if (details?.blogger)
      params = {
        ...params,
        authors: [details?.blogger?.slug],
      };
    if (details?.categories?.length !== 0)
      params = {
        ...params,
        categories: [details?.categories[0]?.slug],
      };
    if (details?.tags?.length !== 0)
      params = {
        ...params,
        tags: [details?.tags[0]?.slug],
      };
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_NEWSARTICLES, {
        headers: {
          Accept: "application/json",
        },
        params: params,
      });
      if (res.status === 200) {
        if (res?.data) {
          setRelatedNewsArticles(res?.data?.news_articles?.data);
        }
        setRelatedNewsArticlesIsLoading(false);
      }
    } catch (err) {
      setRelatedNewsArticlesIsLoading(false);
    }
  };

  function updateCommentPageNumber() {
    if (commentsLastPage != null && commentsPage <= commentsLastPage) {
      setMoreCommentsIsLoading(true);
      setCommentsPage(commentsPage + 1);
    }
  }

  useEffect(() => {
    if (url) {
      handleGetComments();
    }
  }, [commentsPage, url]);

  const handlePostComment = (value) => {
    const body = {
      comment: value,
      ...(id && { parent_comment_id: id }),
    };
    axios
      .post(url + `/comment`, body, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (id) {
          ReactGA.event({
            category: "Comments",
            action: `Reply on a comment`,
            label: details?.title,
          });
          ReactGA.event({
            category: "Comments",
            action: `Reply on a comment on ${details?.title}`,
            label: details?.title,
          });
        } else {
          ReactGA.event({
            category: "Comments",
            action: `New Comment Posted`,
            label: details?.title,
          });
          ReactGA.event({
            category: "Comments",
            action: `New Comment Posted on ${details?.title}`,
            label: details?.title,
          });
        }
        setId(null);
        if (commentsPage === 1) {
          handleGetComments();
        } else {
          setCommentsPage(1);
        }
      });
  };

  const onReply = (id) => {
    setId(id);
  };

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (details) {
      setPageTitle(details?.title);
    } else {
      setPageTitle("NewsArticle Details Page");
    }
  }, [details]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleGetRecent();
  }, []);

  useEffect(() => {
    if (details?.content && !interstitialAdIsLoading) {
      const { updatedContent, isLoading } = insertInterstitialAds(
        details?.content,
        interstitialAd
      );
      if (!isLoading) {
        setContentIsLoading(false);
        setContent(updatedContent);
      }
    }
  }, [details?.content, interstitialAdIsLoading]);

  const handleDeleteComment = (value) => {
    setIsDeletingComment(true);
    axios
      .get(`${SERVER_URL.DELETE_COMMENT}${value}/delete`, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then(() => {
        ReactGA.event({
          category: "Comments",
          action: `Comment Deleted`,
          label: details?.title,
        });
        ReactGA.event({
          category: "Comments",
          action: `Comment Deleted on ${details?.title}`,
          label: details?.title,
        });
        if (commentsPage === 1) {
          handleGetComments();
        } else {
          setCommentsPage(1);
        }
      });
  };

  function getAllCommentsLength() {
    var lengthOfChildren = 0;
    for (var i = 0; i < commentsData?.length; i++) {
      if (
        commentsData[i]?.children?.length !== 0 &&
        commentsData[i]?.parent_comment_id !== null
      ) {
        var replies = commentsData[i].children;
        lengthOfChildren += replies.length;
      }
    }
    return lengthOfChildren + commentsData?.length;
  }

  const handleNavigate = (param) => {
    const params = param?.tag
      ? `tag=${SlugConverter(param.tag)}`
      : param?.author
        ? `author=${SlugConverter(param.author)}`
        : `category=${SlugConverter(param.category)}`;
    const route = "/news-articles?" + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "News Articles's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `News Article ${label} Shared on ${item}`,
      label: label,
    });
  }

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <NavContainer>
      {details && (
        <MetaTags
          author={"Trini Jungle Juice"}
          title={details?.title}
          url={window.location.href}
          type={"article"}
          image={details?.featured_image_url}
          description={details?.excerpt}
        />
      )}
      <div className={BlogCss.mgb}>
        <Grid container rowGap={2}>
          <Grid item className={BlogCss.gridGeneral} xs={12} md={9}>
            <div className={BlogCss.flexDrR}>
              {isLoading ? (
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"60%"}
                  height={40}
                />
              ) : (
                <Label size="lg" label={details?.title} />
              )}
              <SharingTooltip
                onShare={handleShare}
                title={details?.title}
                link={window.location.href}
              />
            </div>
            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginTop: 2 }}
                animation="wave"
                variant="text"
                width={"40%"}
              />
            ) : (
              details?.blogger && (
                <div
                  className={BlogCss.authorName}
                  onClick={() =>
                    handleNavigate({ author: details?.blogger?.slug })
                  }
                >
                  By: {details?.blogger?.first_name}{" "}
                  {details?.blogger?.last_name}
                </div>
              )
            )}
            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={"60%"}
              />
            ) : (
              details?.categories?.length !== 0 && (
                <div className={BlogCss.categoryName}>
                  Category:{" "}
                  {details?.categories?.map((item, index) => {
                    return (
                      <span
                        key={index}
                        className={BlogCss.category}
                        onClick={() => handleNavigate({ category: item?.slug })}
                      >
                        {item?.title}
                        {index < details?.categories?.length - 1 ? ", " : ""}
                      </span>
                    );
                  })}
                </div>
              )
            )}
            <div className={BlogCss.body}>
              {(isLoading || details?.tags?.length !== 0) && (
                <div style={{ marginBottom: 20 }}>
                  <div className={BlogCss.chipsArray} style={{ marginTop: 10 }}>
                    {isLoading ? (
                      <ChipsLoader numberOfChips={4} />
                    ) : (
                      details?.tags?.length !== 0 && (
                        <div className={BlogCss.chipsArray}>
                          {details?.tags?.map((item, index) => {
                            return (
                              <Chip
                                handleClick={() =>
                                  handleNavigate({ tag: item?.slug })
                                }
                                label={item.slug}
                                key={index}
                              />
                            );
                          })}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              {isLoading || contentIsLoading ? (
                <>
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={20}
                      key={index}
                    />
                  ))}
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton
                      sx={{
                        bgcolor: "grey.900",
                      }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={20}
                      key={index}
                    />
                  ))}
                  <Skeleton
                    sx={{ bgcolor: "grey.900", marginTop: 2, marginBottom: 2 }}
                    animation="wave"
                    variant="rectangular"
                    width={"100%"}
                    height={600}
                  />
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={20}
                      key={index}
                    />
                  ))}
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={20}
                      key={index}
                    />
                  ))}
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={20}
                      key={index}
                    />
                  ))}
                  <Skeleton
                    sx={{ bgcolor: "grey.900", marginTop: 2, marginBottom: 2 }}
                    animation="wave"
                    variant="rectangular"
                    width={"100%"}
                    height={600}
                  />
                </>
              ) : (
                <div className={`${BlogCss.bodyDiv} ck-content`}>{content}</div>
              )}
            </div>
            {commentsIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={width < 600 ? "100%" : 150}
                height={40}
              />
            ) : (
              <Label
                size="md"
                label={`Comments (${getAllCommentsLength()})`}
                style={{ marginBottom: 3 }}
              />
            )}
            {commentsIsLoading ? (
              <CommentLoader />
            ) : (
              <>
                <CommentList
                  onLike={handleLikeComment}
                  onReply={onReply}
                  onButtonClick={(value) => handlePostComment(value)}
                  data={commentsData}
                  handleDelete={handleDeleteComment}
                  isDeleting={isDeletingComment}
                />
                {moreCommentsIsLoading && <CommentLoader />}
                {commentsPage < commentsLastPage && !moreCommentsIsLoading && (
                  <ViewMore
                    loadMore={true}
                    onClick={() => {
                      updateCommentPageNumber();
                    }}
                  />
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {bannerAd &&
              (bannerAd?.banner_image_url ? (
                <img
                  className={BlogCss.BannerAd}
                  src={bannerAd?.banner_image_url}
                />
              ) : (
                bannerAd?.content && (
                  <DynamicContentInjector
                    className={BlogCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                )
              ))}
            {relatedNewsArticlesIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 2 }}
                animation="wave"
                variant="text"
                width={width > 431 ? "30%" : "40%"}
                height={50}
              />
            ) : (
              relatedNewsArticles &&
              relatedNewsArticles?.length !== 0 && (
                <Label size="lg" label={"Related News Articles"} />
              )
            )}
            <div className={BlogCss.rightDiv}>
              {relatedNewsArticlesIsLoading ? (
                <>
                  {Array.from({ length: 3 }, (_, index) => (
                    <MiniNewsCard key={index} />
                  ))}
                </>
              ) : (
                relatedNewsArticles &&
                relatedNewsArticles?.length !== 0 && (
                  <div className={BlogCss.mgb}>
                    {relatedNewsArticles?.map((item, index) => {
                      return popularNewsArticlesCard(item, index, handleClick);
                    })}
                  </div>
                )
              )}
            </div>
            {bannerAd2 &&
              (bannerAd2?.banner_image_url ? (
                <img
                  className={BlogCss.BannerAd}
                  src={bannerAd2?.banner_image_url}
                />
              ) : (
                bannerAd2?.content && (
                  <DynamicContentInjector
                    className={BlogCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                )
              ))}
            {recentNewsArticlesIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 2 }}
                animation="wave"
                variant="text"
                width={width > 431 ? "30%" : "40%"}
                height={50}
              />
            ) : (
              recentNewsArticles &&
              recentNewsArticles?.length !== 0 && (
                <Label
                  size="lg"
                  label={"Recent News Articles"}
                  style={{ marginTop: 2 }}
                />
              )
            )}
            <div className={BlogCss.rightDiv}>
              {recentNewsArticlesIsLoading ? (
                <>
                  {Array.from({ length: 3 }, (_, index) => (
                    <MiniNewsCard key={index} />
                  ))}
                </>
              ) : (
                recentNewsArticles &&
                recentNewsArticles?.length !== 0 &&
                recentNewsArticles?.map((item, index) => {
                  return popularNewsArticlesCard(item, index, handleClick);
                })
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state?.auth?.user,
});

export default connect(mapStateToProps)(NewsArticleDetails);
