const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Fullmonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthMap = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};
const fullDaysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function getDayNumberSuffix(day) {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function ExtractDate(dateS) {
  const [datePart] = dateS?.split("T");
  const [year, month, day] = datePart.split("-");
  const tempDate = new Date(`${year}-${month}-${day}`);
  const dayOfWeek = fullDaysOfWeek[tempDate.getUTCDay()];
  const monthName = Fullmonths[parseInt(month) - 1];
  const retrunDate = `${dayOfWeek}, ${day} ${monthName} ${year}`;
  return retrunDate;
}

export function ExtractYear(dateS) {
  const [datePart] = dateS?.split("T");
  const [year, month, day] = datePart.split("-");
  return year;
}

export function ExtractShortDate(dateS) {
  const [datePart] = dateS?.split("T");
  const [year, month, day] = datePart.split("-");
  const monthName = Months[parseInt(month) - 1];
  const retrunDate = `${day} ${monthName} ${year}`;
  return retrunDate;
}

export function ExtractShortMonthDate(dateS) {
  const [datePart] = dateS?.split("T");
  const [year, month, day] = datePart.split("-");
  const monthName = Months[parseInt(month) - 1];
  const suffix = getDayNumberSuffix(day);

  return `${monthName} ${day}${suffix}, ${year}`;
}

export function ExtractCarnivalDate(dateS) {
  const [datePart] = dateS?.split("T");
  const [year, month, day] = datePart.split("-");
  const monthName = Months[parseInt(month) - 1];
  const suffix = getDayNumberSuffix(day);

  return `${day}${suffix} ${monthName}, ${year}`;
}

export function CalendarDateExtractor(dateS) {
  const [datePart] = dateS?.split("T");
  const [year, month, day] = datePart.split("-");

  return `${year}-${month}-${day}`;
}

export function EventDateExtractor(date) {
  if (typeof date === "string") {
    if (date.includes("T")) {
      const [datePart] = date.split("T");
      return datePart;
    } else {
      const parts = date.split(" ");
      const day = parts[1];
      const month = parts[2];
      const year = parts[3];
      return `${year}-${monthMap[month]}-${day}`;
    }
  } else {
    const dateS = new Date(date);
    const day = dateS.getDate() <= 9 ? `0${dateS.getDate()}` : dateS.getDate();
    const month =
      dateS.getMonth() < 9 ? `0${dateS.getMonth() + 1}` : dateS.getMonth() + 1;
    const year = dateS.getFullYear();

    return `${year}-${month}-${day}`;
  }
}

export function UTCDateExtractor(date) {
  if (typeof date === "string") {
    if (date.includes("T")) {
      const [datePart] = date.split("T");
      return datePart;
    } else {
      const parts = date.split(" ");
      const day = parts[1];
      const month = parts[2];
      const year = parts[3];
      return `${year}-${monthMap[month]}-${day}`;
    }
  } else {
    const dateS = new Date(date);
    const day = dateS.getDate() <= 9 ? `0${dateS.getDate()}` : dateS.getDate();
    const month =
      dateS.getMonth() < 9 ? `0${dateS.getMonth() + 1}` : dateS.getMonth() + 1;
    const year = dateS.getFullYear();

    return `${year}-${month}-${day}`;
  }
}

export function GetTomorrowDate() {
  const today = new Date();
  const date = new Date(today);
  date.setDate(today.getUTCDate() + 1);
  const day =
    date.getUTCDate() <= 9 ? `0${date.getUTCDate()}` : date.getUTCDate();
  const month =
    date.getUTCMonth() < 9
      ? `0${date.getUTCMonth() + 1}`
      : date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  return `${year}-${month}-${day}`;
}

export const parseDateString = (dateString) => {
  try {
    // Extracting the components of the date
    const parts = dateString.split(", ");
    if (parts.length !== 3) throw new Error("Invalid date format");

    const [dayOfWeek, monthDay, year] = parts;
    const [month, day] = monthDay.trim().split(" ");

    const cleanedDay = day.replace(",", "").trim();

    const date = new Date(`${month} ${cleanedDay}, ${year}`);

    if (isNaN(date)) {
      throw new Error("Invalid Date");
    }

    return date;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const formatDate = (date) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};