import React, { useEffect, useState } from "react";
import FlatlistCss from "./Flatlist.module.css";
import Card from "../../components/card/Card";
import { useNavigate } from "react-router";

export default function PlaylistList({ playlist }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };
  return (
    <div className={FlatlistCss.albumsDiv}>
      {playlist?.map((item, index) => {
        return (
          <div key={index} className={FlatlistCss.albumsSubDiv}>
            <Card
              title={item.title}
              imageSrc={item.cover_image_url}
              content={
                <div className={FlatlistCss.artist}>
                  {item?.artists?.map((i, ind) =>
                    ind < item?.artists?.length - 1
                      ? `${i?.first_name} ${i?.last_name}, `
                      : `${i?.first_name} ${i?.last_name}`
                  )}
                </div>
              }
              textAlign={"left"}
              size={"sm"}
              imageProps={width < 431 ? { height: "179.53px" } : null}
              onClick={() => handleClick(`/music/playlist/${item?.slug}`)}
            />
          </div>
        );
      })}
    </div>
  );
}
