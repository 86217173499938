import { Skeleton } from '@mui/material';
import React from 'react'
import Label from '../../../../elements/label/Label';
import ViewMore from '../../../../elements/view-more/ViewMore';
import GlobalSearchCss from "../../GlobalSearch.module.css";
import SongsList from "../../../../components/songs-list/SongsList";

export default function Song({
    song,
    isLoading,
    length,
    showViewAll = false,
    setValue,
    moreSongsIsLoading,
    songsPageNumber,
    songsLastPage,
    loadMoreSongs = undefined,
    handleAddToQueue,
    handlePlay,
    currentSong,
    handleDownload
  }) {
    return (
      <div>
        <div className={GlobalSearchCss.mainDiv}>
          {isLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={"40%"}
              height={50}
            />
          ) : (
            <Label label={`Songs (${length} results)`} size="md" />
          )}
        </div>
        {isLoading ? (
          <div className={GlobalSearchCss.flexDrR}>
            {Array.from({ length: 4 }, (_, index) => (
              <Skeleton
                key={index}
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width={"49%"}
                height={80}
              />
            ))}
          </div>
        ) : (
          <>
            <SongsList
              showGrid={true}
              onClick={handlePlay}
              handleToAdd={handleAddToQueue}
              songArray={song}
              currentSongItem={currentSong}
              handleDownload={handleDownload}
            />
            {moreSongsIsLoading && (
              <div className={GlobalSearchCss.flexDrR} style={{ marginTop: 20 }}>
                {Array.from({ length: 4 }, (_, index) => (
                  <Skeleton
                    key={index}
                    sx={{ bgcolor: "grey.900" }}
                    animation="wave"
                    variant="rectangular"
                    width={"49%"}
                    height={80}
                  />
                ))}
              </div>
            )}
          </>
        )}
        {showViewAll && <ViewMore onClick={() => setValue(6)} />}
        {songsLastPage != null &&
          songsPageNumber < songsLastPage &&
          !isLoading &&
          !moreSongsIsLoading &&
          song?.length !== 0 &&
          !showViewAll && <ViewMore loadMore={true} onClick={loadMoreSongs} />}
      </div>
    );
  }
