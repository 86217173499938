import React, { useEffect, useState } from "react";
import { Rating as MUIRating } from "@mui/material";

export default function Rating({
  disabled = false,
  onChange = undefined,
  value = 0,
  precision = 1,
  readOnly = false,
  size = "medium",
}) {
  const [val, setVal] = useState(value);
  useEffect(() => {
    if (onChange) {
      onChange(val);
    }
  }, [val]);
  return (
    <>
      {readOnly || disabled ? (
        <MUIRating
          value={value}
          precision={precision}
          readOnly={readOnly}
          disabled={disabled}
          size={size}
          sx={{
            ".MuiRating-icon": {
              color: "#faaf00",
            },
          }}
        />
      ) : (
        <MUIRating
          value={val}
          precision={precision}
          readOnly={readOnly}
          disabled={disabled}
          size={size}
          sx={{
            ".MuiRating-icon": {
              color: "#faaf00",
            },
          }}
          onChange={(event, newValue) => {
            setVal(newValue);
          }}
        />
      )}
    </>
  );
}
