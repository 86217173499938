import { Skeleton } from "@mui/material";
import React from "react";
import loaderCss from "./loader.module.css";
import { useState, useEffect } from "react";
export default function ReviewsCardSingleTextLoader() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="rectangular"
        width={width > 431 ? 240 : 273}
        height={300}
      />
      <div className={loaderCss.centreContent}>
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          animation="wave"
          variant="text"
          width={150}
        />
      </div>
    </div>
  );
}
