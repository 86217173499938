import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer.js";
import { Grid, Skeleton } from "@mui/material";
import Label from "../../elements/label/Label.js";
import PageCss from "./Page.module.css";
import { Context } from "../../Context.js";
import axios from "axios";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip.js";
import { useLocation } from "react-router";
import MetaTags from "../../MetaTags.js";
import { SERVER_URL } from "../../Constants.ts";
import NoDataScreen from "../no-data/NoDataScreen.js";
import ReactGA from "react-ga4";

export default function Page() {
  const [isLoading, setIsLoading] = useState(true);
  const { setPageTitle } = useContext(Context);
  const [slugUrl, setSlugUrl] = useState(null);
  const [details, setDetails] = useState(null);
  const { pathname } = useLocation();
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      setSlugUrl(`${SERVER_URL.GET_PAGES}/slug/${u}`);
      ReactGA.send({
        hitType: "pageview",
        page: `/pages/${u}`,
        title: `Page(${u})`,
      });
    }
  }, [pathname]);

  useEffect(() => {
    if (slugUrl) {
      GetPageDetail();
    }
  }, [slugUrl]);

  const GetPageDetail = async () => {
    try {
      const res = await axios.get(slugUrl, {
        headers: {
          Accept: "application/json",
        },
      });
      setStatus(res.status);
      if (res.status === 200) {
        if (res?.data) {
          setDetails(res?.data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setStatus(err.response.status);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (details) {
      setPageTitle(details?.title);
    } else {
      setPageTitle("Page");
    }
  }, [details]);

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={details?.meta_title}
        url={window.location.href}
        description={details?.meta_description}
      />
      <div className={PageCss.mgb}>
        <Grid container rowGap={2}>
          <Grid item className={PageCss.gridGeneral} xs={12} md={12}>
            <div className={PageCss.flexDrR}>
              {isLoading ? (
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"60%"}
                  height={40}
                />
              ) : (
                <Label size="lg" label={details?.title} />
              )}
              <SharingTooltip link={window.location.href} />
            </div>
            <div className={PageCss.body}>
              {isLoading ? (
                <>
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={20}
                      key={index}
                    />
                  ))}
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton
                      sx={{
                        bgcolor: "grey.900",
                      }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={20}
                      key={index}
                    />
                  ))}
                  <Skeleton
                    sx={{ bgcolor: "grey.900", marginTop: 2, marginBottom: 2 }}
                    animation="wave"
                    variant="rectangular"
                    width={"100%"}
                    height={600}
                  />
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={20}
                      key={index}
                    />
                  ))}
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={20}
                      key={index}
                    />
                  ))}
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={20}
                      key={index}
                    />
                  ))}
                  <Skeleton
                    sx={{ bgcolor: "grey.900", marginTop: 2, marginBottom: 2 }}
                    animation="wave"
                    variant="rectangular"
                    width={"100%"}
                    height={600}
                  />
                </>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: details?.content }} />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </NavContainer>
  );
}
