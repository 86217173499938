export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GET_QUEUE = 'GET_QUEUE';
export const SET_QUEUE = 'SET_QUEUE';
export const ADD_TO_QUEUE = 'ADD_TO_QUEUE';
export const DELETE_FROM_QUEUE = 'DELETE_FROM_QUEUE';
export const ADD_ITEM_AT_ZERO_INDEX = 'ADD_ITEM_AT_ZERO_INDEX';
export const MOVE_ITEM_TO_TOP = 'MOVE_ITEM_TO_TOP';
export const MOVE_ITEM_TO_BOTTOM = 'MOVE_ITEM_TO_BOTTOM';
export const CLEAR_QUEUE = 'CLEAR_QUEUE';
