import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CountryCss from "./CountryFooter.module.css";
import { SERVER_URL, PER_PAGE_COUNT } from "../../Constants.ts";
import SlugConverter from "../slug-converter/SlugConverter";
import axios from "axios";
import CategoriesLoader from "../skeletonLoaders/CategoriesLoader";
import ExtractStateCountry from "./ExtractStateCountry.js";

export default function CountryFooter({ by, handleClick }) {
  const [countryList, setCountryList] = useState(null);
  const [countryListIsLoading, setCountryListIsLoading] = useState(true);

  const handleGetCountry = async () => {
    let URL;
    if (by === "Events") {
      URL = SERVER_URL.GET_EVENT_REGIONS;
    } else if (by === "Galleries") {
      URL = SERVER_URL.GET_GALLERY_REGIONS;
    } else if (by === "Carnivals") {
      URL = SERVER_URL.GET_CARNIVAL_REGIONS;
    } else {
      URL = SERVER_URL.GET_COUNTRY;
    }

    const PARAMS =
      by === "Events" || by === "Carnivals" || by === "Galleries"
        ? {
            page: 1,
            items: PER_PAGE_COUNT.ALL_DATA,
          }
        : {
            page: 1,
            items: PER_PAGE_COUNT.MINI_COUNTRY,
            type: "mini",
          };
    try {
      const res = await axios.get(URL, {
        headers: {
          Accept: "application/json",
        },
        params: PARAMS,
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setCountryList(res?.data?.data);
        }
        setCountryListIsLoading(false);
      }
    } catch (err) {
      setCountryListIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetCountry();
  }, []);

  return (
    <>
      {countryListIsLoading ||
        (countryList && countryList?.length !== 0 && (
          <div className={CountryCss.heading}>{by} By Location</div>
        ))}
      {/* <Grid container rowSpacing={4} columnGap={3}> */}
      <Grid container columnGap={3}>
        {countryListIsLoading ? (
          <CategoriesLoader />
        ) : (
          countryList &&
          countryList?.map((item) => {
            if (item?.state || item?.country) {
              const value=ExtractStateCountry(item);
              return (
                <Grid key={item?.country} item xs={12} md={2.2}>
                  {/* <Grid key={item.name} item xs={12} md={item.id === 1 ? 4.6 : 2.2}> */}
                  {/* <div className={CountryCss.subHeading}>{item.continent}</div> */}
                  <div className={CountryCss.contentBody}>
                    {/* {item.countries.map((d) => {
                    return ( */}
                    <div
                      // key={item.name}
                      onClick={() =>
                        handleClick(
                          by === "Events" ||
                            by === "Carnivals" ||
                            by === "Galleries"
                            ? value
                            : SlugConverter(item?.country)
                        )
                      }
                      className={CountryCss.country}
                    >
                      <img
                        alt={item?.country}
                        // alt={item.name}
                        src={item.flag}
                        className={CountryCss.countryFlag}
                      />
                      {/* {item.name} */}
                      {by === "Events" ||
                      by === "Carnivals" ||
                      by === "Galleries" ? (
                        value
                      ) : (
                        item?.country
                      )}
                    </div>
                    {/* ); */}
                    {/* })} */}
                  </div>
                </Grid>
              );
            }
          })
        )}
      </Grid>
    </>
  );
}