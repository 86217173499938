import { FontSizes } from "../config";

export const datePickerStyle = {
  color: "#fff",
  fontSize: FontSizes.smallText,
  fontFamily: "Inter-Regular",
  fontWeight: "400",
  minWidth: "100%",
  "& .MuiDateCalendar-root": {
    color: "#fff",
    borderRadius: 2,
    backgroundColor: "#3A3A3A",
    fontSize: 14,
    fontFamily: "Inter-Regular",
  },
  "& .MuiButtonBase-root": {
    color: "#fff",
    fontSize: FontSizes.smallText,
    fontFamily: "Inter-Regular",
    fontWeight: "400",
  },
  "& .MuiButtonBase-root svg": {
    color: "#fff",
    fontSize: FontSizes.heading,
  },
  "& .MuiDayCalendar-header span": {
    color: "#fff",
    fontSize: FontSizes.smallText,
    fontFamily: "Inter-Regular",
    fontWeight: "400",
  },
  "& .MuiInputBase-root input": {
    color: "#fff",
    paddingRight: "50px",
    fontSize: 14,
    fontFamily: "Inter-Regular",
    padding: 1,
  },
  "& .MuiFormLabel-root": {
    color: "#fff",
    fontSize: 14,
    fontFamily: "Inter-Regular",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#fff",
    fontSize: 14,
    fontFamily: "Inter-Regular",
  },
  "& .MuiInputBase-root": {
    color: "#fff",
  },

  "& .MuiOutlinedInput-root fieldset": {
    borderColor: "#fff !important",
  },

  "& .MuiOutlinedInput-root fieldset legend span": {
    fontSize: "11px",
  },
};

export const pickerStyle = {
  ".MuiDateCalendar-root": {
    color: "#fff",
    backgroundColor: "#3A3A3A",
    fontSize: 14,
    fontFamily: "Inter-Regular",
  },
  ".MuiButtonBase-root": {
    color: "#fff",
    fontSize: FontSizes.smallText,
    fontFamily: "Inter-Regular",
    fontWeight: "400",
  },
  ".MuiButtonBase-root svg": {
    color: "#fff",
    fontSize: FontSizes.heading,
  },
  ".MuiDayCalendar-header span": {
    color: "#fff",
    fontSize: FontSizes.smallText,
    fontFamily: "Inter-Regular",
    fontWeight: "400",
  },
  ".MuiInputBase-root input": {
    color: "#fff",
    paddingRight: "50px",
    fontSize: 14,
    fontFamily: "Inter-Regular",
  },
  ".MuiFormLabel-root": {
    color: "#fff",
    fontSize: 14,
    fontFamily: "Inter-Regular",
  },
  ".MuiFormLabel-root.Mui-focused": {
    color: "#fff",
    fontSize: 14,
    fontFamily: "Inter-Regular",
  },
  "& .MuiInputBase-root": {
    color: "#fff",
  },

  "& .MuiOutlinedInput-root fieldset": {
    borderColor: "#fff !important",
  },

  "& .MuiOutlinedInput-root fieldset legend span": {
    fontSize: "11px",
  },
};

export const calendarSxStyle = {
  color: "#fff",
  fontSize: FontSizes.smallText,
  fontFamily: "Inter-Regular",
  fontWeight: "400",
  "&.MuiDateCalendar-root": {
    maxWidth: "100%",
    width: "100%",
  },
  "& .MuiYearCalendar-root": {
    maxWidth: "100%",
    width: "100%",
  },
  "& .MuiMonthCalendar-root": {
    maxWidth: "100%",
    width: "100%",
  },
  "& .MuiPickersCalendarHeader-root": {
    paddingLeft: 0,
  },
  "& .MuiPickersCalendarHeader-label": {
    color: "#fff",
    fontSize: FontSizes.text,
    fontFamily: "Inter-Regular",
    fontWeight: "400",
  },
  "& .MuiButtonBase-root": {
    color: "#fff",
    fontSize: FontSizes.body,
    fontFamily: "Inter-Regular",
    fontWeight: "400",
  },
  "& .MuiButtonBase-root svg": {
    color: "#fff",
    fontSize: FontSizes.heading,
  },
  "& .MuiDayCalendar-header span": {
    color: "#fff",
    fontSize: FontSizes.smallText,
    fontFamily: "Inter-Regular",
    fontWeight: "400",
  },
  "& .MuiPickersDay-root.Mui-selected": {
    backgroundColor: "red !important",
  },
  "& .MuiPickersDay-today": {
    backgroundColor: "transparent",
    border: "1px solid white !important",
  },
};