import React from "react";
import loaderCss from "./loader.module.css";
import { Skeleton } from "@mui/material";
export default function ClassifieldsLoader() {
  return (
    <div>
      <div className={loaderCss.col3}>
        <Skeleton
          sx={{ bgcolor: "grey.900", paddingRight: 5 }}
          animation="wave"
          width={45}
          height={45}
          variant="circular"
        />
        <div className={loaderCss.row2}>
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
            animation="wave"
            variant="text"
            width={"30%"}
            height={25}
          />
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 0.2 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={14}
          />
        </div>
      </div>
      <Skeleton
        sx={{ bgcolor: "grey.900", marginBottom: 1 }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={14}
      />
      {Array.from({ length: 8 }, (_,index) => (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 0.2 }}
          animation="wave"
          variant="text"
          width={"100%"}
          height={14}
          key={index}
        />
      ))}
      <Skeleton
        sx={{ bgcolor: "grey.900", marginTop: 0.8, marginBottom: 1 }}
        animation="wave"
        variant="rectangular"
        width={"100%"}
        height={300}
      />
      <div className={loaderCss.chips}>
        {Array.from({ length: 3 }, (_,index) => (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 1 }}
            animation="wave"
            variant="rounded"
            width={80}
            height={40}
            key={index}
          />
        ))}
      </div>
      <div className={loaderCss.chips}>
        {Array.from({ length: 2 }, (_,index) => (
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="rectangular"
            width={150}
            height={20}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}
