import React from "react";
import { useState, useEffect } from "react";
import { Skeleton } from "@mui/material";
export default function PhotoCardLoader({ height, width, rounded }) {
  // const [width, setWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  return (
    <div>
      <Skeleton
        sx={{
          bgcolor: "grey.900",
          ...(rounded && { borderRadius: "10px" }),
        }}
        animation="wave"
        variant="rectangular"
        width={width ? width : 370}
        height={height ? height : 300}
      />
      <Skeleton
        sx={{ bgcolor: "grey.900", marginTop: "3px" }}
        animation="wave"
        variant="text"
        width={200}
        height={20}
      />
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="text"
        width={300}
        height={15}
      />
    </div>
  );
}
