import React, { useEffect, useState } from "react";
import { FontSizes } from "../../../config";
import EventCss from "../Album.module.css";
import AutoComplete from "../../../components/autocomplete/AutoComplete";
import Button from "../../../components/button/Button";
import SlugConverter from "../../../elements/slug-converter/SlugConverter";

export default function AlbumFilter({
  onSearch,
  countryArray,
  artistArray,
  yearsArray,
  genreArray,
  onReset,
  searchedLocation,
  searchedArtist,
  searchedGenre,
  searchedYear,
}) {
  const [location, setLocation] = useState([]);
  const [artist, setArtist] = useState([]);
  const [genre, setGenre] = useState([]);
  const [year, setYear] = useState([]);
  const [countryOptions, setCountryOption] = useState([]);
  const [artistsOptions, setArtistsOption] = useState([]);
  const [yearOptions, setYearOption] = useState([]);
  const [genreOptions, setGenreOption] = useState([]);

  useEffect(() => {
    const filtered = searchedLocation?.filter(
      (item) => !location.includes(item)
    );
    if (filtered && filtered?.length !== 0) {
      setLocation(filtered);
    }
  }, [searchedLocation]);

  useEffect(() => {
    const filtered = searchedArtist?.filter((item) => !artist.includes(item));
    if (filtered && filtered?.length !== 0) {
      setArtist(filtered);
    }
  }, [searchedArtist]);

  useEffect(() => {
    const filtered = searchedGenre?.filter((item) => !genre.includes(item));
    if (filtered && filtered?.length !== 0) {
      setGenre(filtered);
    }
  }, [searchedGenre]);

  useEffect(() => {
    const filtered = searchedYear?.filter(
      (item) => !year.includes(parseInt(item))
    );
    if (filtered && filtered.length !== 0) {
      setYear(filtered.map((item) => parseInt(item)));
    }
  }, [searchedYear]);

  useEffect(() => {
    const op = countryArray?.map((item, index) => ({
      id: index,
      name: item?.country,
      slug: SlugConverter(item?.country),
    }));
    if (op) {
      setCountryOption(op);
    }
  }, [countryArray]);

  useEffect(() => {
    const op = yearsArray?.map((item, index) => ({
      id: index,
      name: item?.year,
      slug: item?.year,
    }));
    if (op) {
      setYearOption(op);
    }
  }, [yearsArray]);

  useEffect(() => {
    const op = artistArray?.map((item) => ({
      id: item?.id,
      name: `${item?.first_name} ${item?.last_name}`,
      slug: item?.slug,
    }));
    if (op) {
      setArtistsOption(op);
    }
  }, [artistArray]);

  useEffect(() => {
    const op = genreArray?.map((item) => ({
      id: item?.id,
      name: item?.title,
      slug: item?.slug,
    }));
    if (op) {
      setGenreOption(op);
    }
  }, [genreArray]);

  function handleReset() {
    setYear([]);
    setGenre([]);
    setArtist([]);
    setLocation([]);
    onReset();
  }

  function handleFilter() {
    let params = {};
    onReset();
    if (location.length !== 0) {
      params = {
        ...params,
        countries: location,
      };
    }

    if (artist.length !== 0) {
      params = {
        ...params,
        artist: artist,
      };
    }

    if (genre.length !== 0) {
      params = {
        ...params,
        genre: genre,
      };
    }

    if (year.length !== 0) {
      params = {
        ...params,
        year: year,
      };
    }

    onSearch(params);
  }

  return (
    <div className={EventCss.filter}>
      <div className={EventCss.calendarHead}>Filters</div>
      {countryOptions?.length !== 0 && (
        <AutoComplete
          label="Country"
          defaultValue={location}
          handleOnChange={(val) => setLocation(val)}
          options={countryOptions}
          isSlug={true}
        />
      )}
      {artistsOptions?.length !== 0 && (
        <AutoComplete
          label="Artist"
          defaultValue={artist}
          handleOnChange={(val) => setArtist(val)}
          options={artistsOptions}
          isSlug={true}
        />
      )}
      {genreOptions?.length !== 0 && (
        <AutoComplete
          defaultValue={genre}
          handleOnChange={(val) => setGenre(val)}
          options={genreOptions}
          label="Genre"
          isSlug={true}
        />
      )}
      {yearOptions?.length !== 0 && (
        <AutoComplete
          defaultValue={year}
          handleOnChange={(val) => setYear(val)}
          options={yearOptions}
          label="Year"
          isSlug={true}
        />
      )}
      <div className={EventCss.buttonsDiv}>
        <Button
          size={"small"}
          label="Reset"
          backgroundColor={"transparent"}
          disabled={
            location?.length === 0 &&
            genre?.length === 0 &&
            artist?.length === 0 &&
            year?.length === 0
          }
          color={"#ff0000"}
          styles={{ fontWeight: "600", fontSize: FontSizes.text }}
          onClick={() => handleReset()}
        />
        <Button
          size={"small"}
          label="Search"
          disabled={
            location?.length === 0 &&
            genre?.length === 0 &&
            artist?.length === 0 &&
            year?.length === 0
          }
          onClick={() => {
            handleFilter();
          }}
        />
      </div>
    </div>
  );
}
