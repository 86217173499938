import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../components/button/Button";
import IconButton from "../../components/icon-button/IconButton";
import { BiPlus } from "react-icons/bi";
import TextField from "../../components/textfield/TextField";
import ItineraryCss from "./Itinerary.module.css";
import ItineraryCard from "./itinerary-card/ItineraryCard";
import { ExtractDate } from "../date-extractor/ExtractDate";

export default function Itinerary({ itinerary, onClick, onCreate }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box className={ItineraryCss.mainBox}>
      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          className={ItineraryCss.modalDiv}
        >
          <CreateNew
            width={width}
            onClick={onCreate}
            setOpen={(bool) => setOpen(bool)}
          />
        </Modal>
      )}
      <div className={ItineraryCss.itineraryDiv}>
        <div className={ItineraryCss.itineraryHead}>Add to Itinerary</div>
        {width < 431 ? (
          <IconButton
            onClick={() => setOpen(true)}
            backgroundColor={"transparent"}
            icon={<BiPlus className={ItineraryCss.plusIcon} />}
          />
        ) : (
          <Button
            onClick={() => setOpen(true)}
            size={"medium"}
            label="Create New"
          />
        )}
      </div>
      {itinerary?.length === 0 ? (
        <div className={ItineraryCss.noItinerary}>
          Ahh Ah! No itinerary created yet
        </div>
      ) : (
        <div className={ItineraryCss.itineraryGroupDiv}>
          {itinerary?.map((item, index) => {
            return (
              <ItineraryCard
                key={index}
                title={item.title}
                onClick={() => onClick(item?.id)}
                subTitle={"Created on " + ExtractDate(item?.created_at)}
              />
            );
          })}
        </div>
      )}
    </Box>
  );
}

function CreateNew({ onClick, setOpen }) {
  const [itineraryName, setItineraryName] = useState("");
  const [itineraryDescription, setItineraryDescription] = useState("");
  const [itineraryNameError, setItineraryNameError] = useState(false);

  const handleClick = () => {
    let valid = true;
    if (itineraryName === "") {
      setItineraryNameError(true);
      valid = false;
    }
    if (valid) {
      const body = {
        title: itineraryName,
        description: itineraryDescription,
      };
      onClick(body);
      setOpen(false);
    }
  };
  return (
    <Box className={ItineraryCss.subBox}>
      <div className={ItineraryCss.itineraryHead}>Create Itinerary</div>
      <div className={ItineraryCss.mgTop}>
        <TextField
          label="Itinerary Name*"
          onChange={(event) => setItineraryName(event)}
          defaultValue={itineraryName}
          size={"medium"}
          error={itineraryNameError}
        />
      </div>
      <div className={ItineraryCss.mgTop15}>
        <TextField
          label="Description"
          onChange={(event) => setItineraryDescription(event)}
          defaultValue={itineraryDescription}
          size={"medium"}
          rows={3}
          multiline
        />
      </div>
      <div className={ItineraryCss.buttonsDiv}>
        <Button
          onClick={() => setOpen(false)}
          size={"medium"}
          label="Cancel"
          backgroundColor={"transparent"}
        />
        <Button onClick={() => handleClick()} size={"medium"} label="Create" />
      </div>
    </Box>
  );
}
