import React, { useEffect, useState } from "react";
import Rating from "../../../components/rating/Rating";
import EventCss from "../Events.module.css";

export default function RatingComp({ ratings }) {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={EventCss.ratingDiv}>
      {width < 431 ? (
        <>
          <div className={EventCss.ratingHead}>TJJ Rating</div>
          {ratings?.map((item, index) => {
            return (
              <div key={index} className={EventCss.ratingStyle}>
                {item?.name}
                <Rating
                  value={item?.tjjRating}
                  size={"large"}
                  readOnly={true}
                />
              </div>
            );
          })}
          <div className={EventCss.ratingHead}>My Rating</div>
          {ratings?.map((item, index) => {
            return (
              <div key={index} className={EventCss.ratingStyle}>
                {item?.name}

                <Rating
                  value={item?.userRating}
                  size={"large"}
                  readOnly={true}
                />
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div className={EventCss.ratingHead}>
            <div className={EventCss.ratingParam} />
            <span className={EventCss.rating}>TJJ Rating</span>
            <span className={EventCss.rating}>My Rating</span>
          </div>
          {ratings?.map((item, index) => (
            <div key={index} className={EventCss.ratingStyle}>
              <span className={EventCss.ratingParam}> {item?.name}</span>
              <span className={EventCss.rating}>
                <Rating
                  value={item?.tjjRating}
                  size={"large"}
                  readOnly={true}
                />
              </span>
              <span className={EventCss.rating}>
                <Rating
                  value={item?.userRating}
                  size={"large"}
                  readOnly={true}
                />
              </span>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
