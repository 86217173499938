import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer.js";
import Label from "../../elements/label/Label.js";
import PlaylistCss from "./Playlist.module.css";
import { FontSizes } from "../../config.js";
import { Grid, Skeleton } from "@mui/material";
import { Context } from "../../Context.js";
import { connect } from "react-redux";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import MetaTags from "../../MetaTags.js";
import NoDataScreen from "../no-data/NoDataScreen.js";
import VideoCardLoader from "../../elements/skeletonLoaders/VideoCardLoader.js";
import VideoCard from "../../components/video-card/VideoCard.js";
import { ExtractDate } from "../../elements/date-extractor/ExtractDate.js";
import ReactGA from "react-ga4";

function VideoPlaylistView({ isLoggedIn, user }) {
  const { slug } = useParams();
  const [playlist, setPlaylist] = useState(null);
  const { setPageTitle } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [moreFromisLoading, setMoreFromIsLoading] = useState(true);
  const [moreFromPlaylist, setMoreFromPlaylist] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/videos/playlist/${slug}`,
      title: "Video's Playlist Detail",
    });
  }, []);

  useEffect(() => {
    if (playlist) {
      setPageTitle(playlist?.title);
    } else {
      setPageTitle("Video Playlist Details");
    }
  }, [playlist]);

  useEffect(() => {
    handleMorePlaylist();
  }, []);

  const handleGetData = async (url) => {
    try {
      const res = await axios.get(url, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
      });
      setStatus(res?.status);
      if (res.status === 200) {
        if (res?.data && res?.data?.playlist_details) {
          setPlaylist(res?.data?.playlist_details);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setStatus(err.response.status);
    }
  };

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const handleMorePlaylist = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_VIDEOS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.FEATURED_VIDEOS_HOME,
          type: "featured",
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setMoreFromPlaylist(res?.data?.data);
        }
        setMoreFromIsLoading(false);
      }
    } catch (err) {
      setMoreFromPlaylist([]);
      setMoreFromIsLoading(false);
    }
  };

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[3];
      handleGetData(
        `${SERVER_URL.GET_USER_VIDEO_PLAYLISTS_DETAIL}${u}/details`
      );
    }
  }, [pathname]);

  function VideoList({ videos, size }) {
    return (
      <div className={PlaylistCss.flexDr}>
        {videos?.map((item, index) => {
          const date = ExtractDate(item?.created_at);
          return (
            <VideoCard
              key={index}
              title={item?.title}
              viewCount={item?.watch_count}
              likeCount={item?.like_count}
              subTitle={date}
              thumbnailLink={item?.cover_image_url}
              onClick={() => handleClick(`/videos/${item?.slug}`)}
              orientation={"portrait"}
              size={size}
            />
          );
        })}
      </div>
    );
  }

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={playlist?.title}
        url={window.location.href}
        type={"website"}
        image={playlist?.cover_image_url}
        description={playlist?.description}
      />
      <div className={PlaylistCss.mainDiv}>
        <Grid container rowGap={3} columnGap={2}>
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={300}
              />
            ) : (
              <img
                className={PlaylistCss.artistImage}
                src={playlist?.cover_image_url}
                alt="Artist"
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={8}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 6,
              }}
            >
              <div
                style={{
                  fontSize: FontSizes.h1,
                  fontWeight: "700",
                  color: "white",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                }}
              >
                {isLoading ? (
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    animation="wave"
                    variant="text"
                    width={"30%"}
                    height={30}
                  />
                ) : (
                  playlist?.title
                )}
              </div>
              <div className={PlaylistCss.description}>
                {isLoading
                  ? Array.from({ length: 5 }, () => (
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="text"
                        width={"100%"}
                      />
                    ))
                  : playlist?.description}
              </div>
            </div>
          </Grid>
        </Grid>

        <div className={PlaylistCss.divMain}>
          {isLoading ? (
            <div>
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={"30%"}
              />
              <div className={PlaylistCss.flexDrR}>
                {Array.from({ length: 4 }, (_, index) => (
                  <VideoCardLoader key={index} />
                ))}
              </div>
            </div>
          ) : (
            <>
              {playlist?.videos?.map((item, index) => {
                return (
                  <div key={index} className={PlaylistCss.mgb10}>
                    <div className={PlaylistCss.eventTag}>
                      {item?.timestamp}
                    </div>
                    <VideoList videos={item?.videos} size={"md"} />
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div>
          {moreFromisLoading ? (
            <>
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 5 }}
                animation="wave"
                variant="rectangular"
                width={"30%"}
                height={30}
              />
              <div className={PlaylistCss.playlistsDiv}>
                {Array.from({ length: 4 }, () => {
                  return (
                    <div className={PlaylistCss.playlistsSubDiv}>
                      <MusicCardLoader music={true} />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            moreFromPlaylist?.length !== 0 && (
              <>
                <Label size="lg" label={"Recommended Videos"} />
                <VideoList videos={moreFromPlaylist?.slice(0, 4)} size={"sm"} />
              </>
            )
          )}
        </div>
      </div>
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
});

export default connect(mapStateToProps)(VideoPlaylistView);
