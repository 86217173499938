import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../ActionTypes';

const initialState = {
  isLoggedIn: false,
  user: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      const newState = {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
      localStorage.setItem('authState', JSON.stringify(newState)); // Save auth state to localStorage
      return newState;
    case LOGOUT_SUCCESS:
      localStorage.removeItem('authState'); // Remove auth state from localStorage
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case 'SET_AUTH_STATE':
      return action.payload;
    default:
      return state;
  }
};

export default authReducer;