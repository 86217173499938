import React, { useEffect, useRef, useState } from "react";
import "./styles/app.scss";
import MusicStyles from "./MusicPlayer.module.css";
import Player from "./components/PlayerInterface/Player";
import { Grid, Modal } from "@mui/material";
import SongsList from "../songs-list/SongsList";
import Card from "../card/Card";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import { FaChevronDown } from "react-icons/fa6";
import CloseIcon from "@mui/icons-material/Close";
import Playlist from "./components/Playlist/Playlist";
import { Close } from "@mui/icons-material";
import { connect } from "react-redux";
import Swal from "sweetalert2";

function MusicPlayer({
  isLoggedIn,
  songArgs,
  playerRef,
  onClose,
  onSongChange,
  playlist = [],
  songQueue,
  handleQueueUpdate,
  onPlaylistClick,
  onCreatePlaylist,
  moreFromArtist,
  handleDeleteFromQueue,
  handleMoveToBottom,
  handleMoveToTop,
  onCross,
  currentSongIndex,
  setCurrentSongIndex,
  onLike,
  onPlaylistOpen,
}) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [showQueue, setShowQueue] = useState(true);
  const [showPlayer, setShowPlayer] = useState(true);
  const grid1 = useRef(null);
  const grid2 = useRef(null);
  const [openPlaylist, setOpenPlaylist] = useState(false);
  const audioRef = playerRef?.getAudioRef();

  const handleSongChange = (item, index) => {
    setCurrentSongIndex(index);
  };

  const handleSong = (item) => {
    setSongItem(item);
    onSongChange(item);
  };

  useEffect(() => {
    if (audioRef?.current?.ended) {
      if (songQueue?.length !== 0) {
        if (currentSongIndex === songQueue?.length - 1) {
          playerRef?.pauseAudio();
        } else {
          setCurrentSongIndex((prev) => prev + 1);
        }
      } else {
        playerRef?.setSongState({ ...playerRef?.songState, isPlaying: false });
        playerRef?.pauseAudio();
      }
    }
  }, [audioRef?.current?.ended]);

  useEffect(() => {
    if (currentSongIndex !== -1) {
      handleSong(songQueue[currentSongIndex]);
    }
  }, [currentSongIndex]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width < 431) {
      setShowQueue(false);
    } else {
      setShowQueue(true);
      setShowPlayer(true);
    }
  }, [width]);

  const [songItem, setSongItem] = useState({
    id: "",
    title: "",
    subTitle: "",
    hlsSource: "",
    thumbnail: "",
    duration: "",
    isLiked: false,
  });

  useEffect(() => {
    setSongItem(songArgs);
  }, [songArgs]);

  const handleQueueClick = () => {
    setShowQueue(true);
    setTimeout(() => {
      grid2.current?.scrollIntoView({ behavior: "smooth" });
      setShowPlayer(false);
    }, 1000);
  };

  const handleShowPlayer = () => {
    setShowPlayer(true);
    setTimeout(() => {
      grid1.current?.scrollIntoView({ behavior: "smooth" });
      setShowQueue(false);
    }, 1000);
  };

  const handlePlaylist = (item) => {
    if (onPlaylistClick) {
      onPlaylistClick(item);
    }
    setOpenPlaylist(false);
  };

  const handleOpen = (bool) => {
    setOpenPlaylist(bool);
    onPlaylistOpen(songItem);
  };

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
      allowOutsideClick: false,
    }).then(() => {
      window.location.href = "/login";
      window.scrollTo(0, 0);
    });
  };

  const handleClick = (item) => {
    window.location.href = item;
    window.scrollTo(0, 0);
  };

  const handleDownload = async (song) => {
    if (!song?.media_url) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const response = await fetch(song.media_url);
      if (!response.ok) {
        Swal.fire({
          title: "Unable to download the song",
          icon: "error",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const fileExtension = song?.media_url?.split(".").pop(); // Assuming the extension is present in the URL
      const fileName =
        song?.title?.replace(/\s+/g, "-").toLowerCase() + "." + fileExtension;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div className={MusicStyles.playerGradientBg}>
      <div className={MusicStyles.cdBg}>
        <div className={MusicStyles.fdr}>
          {showPlayer && (
            <div className={MusicStyles.crossArrowDiv}>
              <Close onClick={onCross} className={MusicStyles.downArrow} />
            </div>
          )}
          {showPlayer && (
            <div className={MusicStyles.downArrowDiv}>
              <FaChevronDown
                onClick={onClose}
                className={MusicStyles.downArrow}
              />
            </div>
          )}
        </div>
        <Grid container>
          <Grid ref={grid1} item md={6} xs={12} lg={7}>
            {showPlayer && (
              <div style={{ height: width < 431 ? height - 66 : "auto" }}>
                <div className={MusicStyles.title}>
                  <div className={MusicStyles.textColor}>Now Playing</div>
                  {width < 431 && (
                    <QueueMusicIcon
                      onClick={() => handleQueueClick()}
                      className={MusicStyles.queueIcon}
                    />
                  )}
                </div>
                <div className={MusicStyles.mainDiv}>
                  <img
                    src={songItem.thumbnail}
                    alt="thumbnail"
                    className={MusicStyles.thumbnail}
                  />
                  <div className={MusicStyles.musicTitle}>{songItem.title}</div>
                  <div className={MusicStyles.musicSubTitle}>
                    {songItem.subTitle}
                  </div>
                  <div className={MusicStyles.player}>
                    {openPlaylist && (
                      <Modal
                        open={openPlaylist}
                        onClose={() => setOpenPlaylist(false)}
                        style={{ zIndex: 99999999999 }}
                      >
                        <Playlist
                          playlist={playlist}
                          onClick={onCreatePlaylist}
                          onPlaylistClick={handlePlaylist}
                        />
                      </Modal>
                    )}
                    <Player
                      audioRef={playerRef.getAudioRef()}
                      songState={playerRef.songState}
                      setSongState={playerRef.setSongState}
                      setOpenPlaylist={(bool) => {
                        isLoggedIn ? handleOpen(bool) : handleLogin();
                      }}
                      isLiked={songItem?.isLiked}
                      openPlaylist={openPlaylist}
                      songQueue={songQueue}
                      currentSongIndex={currentSongIndex}
                      setCurrentSongIndex={setCurrentSongIndex}
                      handleSong={handleSong}
                      isLoggedIn={isLoggedIn}
                      onLike={() => {
                        isLoggedIn ? onLike(songItem) : handleLogin();
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </Grid>
          <Grid ref={grid2} item md={6} xs={12} lg={5}>
            {showQueue && (
              <div
                style={{
                  height: width < 431 ? height : "auto",
                }}
                className={MusicStyles.queueDiv}
              >
                <div className={MusicStyles.queueTitleDiv}>
                  <div className={MusicStyles.queueText}>Queue</div>
                  {width < 431 && (
                    <CloseIcon
                      onClick={() => handleShowPlayer()}
                      className={MusicStyles.queueIcon}
                    />
                  )}
                </div>
                {songQueue?.length === 0 ? (
                  <div className={MusicStyles.noData}>
                    No songs found in the queue. Please add songs and try again.
                  </div>
                ) : (
                  <div className={MusicStyles.songListDiv}>
                    <SongsList
                      subTitleColor={"#4B3636"}
                      footerTextColor={"#4B3636"}
                      songArray={songQueue}
                      showQueue={true}
                      currentSongItem={songItem}
                      onClick={(item, index) => {
                        handleSongChange(item, index);
                      }}
                      currentQueue={handleQueueUpdate}
                      handleDeleteFromQueue={handleDeleteFromQueue}
                      handleMoveToBottom={handleMoveToBottom}
                      handleMoveToTop={handleMoveToTop}
                      handleDownload={handleDownload}
                    />
                  </div>
                )}
                {moreFromArtist?.length !== 0 && (
                  <>
                    <div className={MusicStyles.moreFromText}>
                      More from {songItem.subTitle}
                    </div>
                    <div className={MusicStyles.flexDr}>
                      {moreFromArtist?.map((item, index) => {
                        return (
                          <Card
                            key={index}
                            imageProps={{ width: "12.6vh", height: "12.6vh" }}
                            imageSrc={item?.cover_image_url}
                            onClick={() =>
                              handleClick(`/music/albums/${item?.slug}`)
                            }
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(MusicPlayer);
