import React, { useContext } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Container } from "@mui/material";
import NavbarCss from "./NavContainer.module.css";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../breadcrumbs/BreadCrumbs";
import { Context } from "../../Context";
import ScrollToTop from "../scroll-to-top/scrollToTop"; // Change the file name to 'ScrollToTop' with correct casing
export default function NavContainer({
  children,
  useContainer = true,
  useFooter = true,
  useScrollToTop = true,
  stickyHead=false
}) {
  const pathname = useLocation();
  const { pageTitle } = useContext(Context);

  return (
    <div className={NavbarCss.container}>
      <Navbar stickyHead={stickyHead}/>
      {useScrollToTop && <ScrollToTop />}
      <div className={NavbarCss.breadCrumbs}>
        <Container>
          <BreadCrumbs title={pageTitle} path={pathname.pathname} />
        </Container>
      </div>
      <div
        className={NavbarCss.navBar}
        style={{ paddingBottom: useFooter ? 30 : 0 }}
      >
        {useContainer ? (
          <Container>{children}</Container>
        ) : (
          <div>{children}</div>
        )}
      </div>
      {useFooter && <Footer />}
    </div>
  );
}
