import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer.js";
import Label from "../../elements/label/Label.js";
import PlaylistCss from "./Playlist.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { FontSizes } from "../../config.js";
import PlaylistList from "../../elements/Flatlist/PlaylistList.js";
import Button from "../../components/button/Button.js";
import { Grid, Skeleton } from "@mui/material";
import { Context } from "../../Context.js";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectMusic, setMusic } from "../../slices/MusicReducer.js";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader.js";
import GetTableRow from "./elements/GetTableRow.js";
import { addToQueue, playNextInQueue } from "../../actions.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import MetaTags from "../../MetaTags.js";
import Swal from "sweetalert2";
import NoDataScreen from "../no-data/NoDataScreen.js";
import ReactGA from "react-ga4";

function PlaylistView({ isLoggedIn, user }) {
  const { slug } = useParams();
  const [playlist, setPlaylist] = useState(null);
  const { setPageTitle } = useContext(Context);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [moreFromisLoading, setMoreFromIsLoading] = useState(true);
  const [moreFromPlaylist, setMoreFromPlaylist] = useState([]);
  const [song, setSong] = useState({ id: "", isPlaying: false });
  const music = useSelector(selectMusic)[0];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/music/playlist/${slug}`,
      title: "Playlist Home",
    });
  }, []);

  function handleAddToQueue(data) {
    ReactGA.event({
      category: "Queue",
      action: `Add to queue button clicked`,
      label: `Songs added to the queue`,
    });
    if (Array.isArray(data)) {
      data.map((item) => {
        const genre = item?.album?.genres?.map(
          (i, index) =>
            `${i?.title}${
              index !== item?.album?.genres?.length - 1 ? ", " : ""
            }`
        );
        const d = {
          ...item,
          subTitle: item?.album?.title,
          thumbnail: item?.album?.cover_image_url,
          footerText: genre[0],
          hlsSource: item?.media_url,
        };
        dispatch(addToQueue(d));
      });
    } else {
      const genre = data?.album?.genres?.map(
        (item, index) =>
          `${item?.title}${
            index !== data?.album?.genres?.length - 1 ? ", " : ""
          }`
      );
      const d = {
        ...data,
        subTitle: data?.album?.title,
        thumbnail: data?.album?.cover_image_url,
        footerText: genre[0],
        hlsSource: data?.media_url,
      };
      dispatch(addToQueue(d));
    }
  }

  useEffect(() => {
    if (playlist) {
      setPageTitle(playlist?.title);
    } else {
      setPageTitle("Playlist Details");
    }
  }, [playlist]);

  useEffect(() => {
    handleMorePlaylist();
  }, []);

  const handleGetData = async (url) => {
    try {
      const res = await axios.get(url, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
      });
      setStatus(res?.status);
      if (res.status === 200) {
        if (res?.data) {
          setPlaylist(res?.data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setStatus(err.response.status);
    }
  };

  const handleMorePlaylist = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_FEATURED_PLAYLIST, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.MORE_ARTISTS,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data) {
          res?.data?.data?.map((item) => {
            if (item?.id !== playlist?.id) {
              setMoreFromPlaylist((prev) => [...prev, item]);
            }
          });
        }
        setMoreFromIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setMoreFromPlaylist([]);
      setMoreFromIsLoading(false);
    }
  };

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[3];
      handleGetData(`${SERVER_URL.GET_PLAYLIST_DETAILS_BY_SLUG}${u}`);
    }
  }, [pathname]);

  useEffect(() => {
    if (!music?.isPlaying) {
      setSong({ id: "" });
    } else {
      setSong({ id: music?.musicArgs?.id });
    }
  }, [music]);

  const StreamMusic = async (id) => {
    try {
      const res = await axios.get(`${SERVER_URL.STREAM_MUSIC}${id}`, {
        headers: {
          Accept: "application/json",
        },
      });
    } catch (err) {}
  };

  const handlePlayTrack = (item) => {
    ReactGA.event({
      category: "Play",
      action: `Play all button clicked`,
      label: `${playlist?.title} track is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play all from ${playlist?.title}`,
      label: `${playlist?.title} track is being played`,
    });
    const genre = item?.songs[0]?.album?.genres?.map(
      (i, index) =>
        `${i?.title}${index !== item?.songs[0]?.album?.length - 1 ? ", " : ""}`
    );
    const data = {
      isPlaying: true,
      musicArgs: {
        id: item.songs[0]?.id,
        title: item?.songs[0]?.title,
        subTitle: item?.songs[0]?.album?.title,
        hlsSource: item?.songs[0]?.media_url,
        thumbnail: item?.songs[0]?.album?.cover_image_url,
        footerText: genre[0],
        duration: item?.songs[0]?.duration,
        artist_id: item?.songs[0]?.album?.artists[0]?.id,
        isLiked: item?.songs[0]?.is_liked,
      },
    };
    StreamMusic(item.songs[0]?.id);
    handleAddToQueue(item?.songs?.slice(1, item?.songs?.length));
    dispatch(setMusic(data));
  };

  const handlePlay = (data) => {
    ReactGA.event({
      category: "Play",
      action: `Play Song Clicked`,
      label: `${data?.title} is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play Song: ${data?.title}`,
      label: `${data?.title} is being played`,
    });
    const genre = data?.album?.genres?.map(
      (i, index) =>
        `${i?.title}${index !== data?.album?.genres?.length - 1 ? ", " : ""}`
    );
    const d = {
      isPlaying: true,
      musicArgs: {
        id: data?.id,
        title: data?.title,
        subTitle: data?.album?.title,
        hlsSource: data?.media_url,
        thumbnail: data?.album?.cover_image_url,
        footerText: genre[0],
        duration: data?.duration,
        artist_id: data?.album?.artists[0]?.id,
        isLiked: data?.is_liked,
      },
    };
    StreamMusic(data?.id);
    dispatch(setMusic(d));
  };

  const handlePlayNext = (data) => {
    ReactGA.event({
      category: "Play",
      action: `Play Next Clicked`,
      label: `${data?.title} is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play Next Clicked: ${data?.title}`,
      label: `${data?.title} is being played`,
    });
    const genre = data?.album?.genres?.map(
      (item, index) =>
        `${item?.title}${index !== data?.album?.genres?.length - 1 ? ", " : ""}`
    );
    const d = {
      ...data,
      subTitle: data?.album?.title,
      thumbnail: data?.album?.cover_image_url,
      footerText: genre[0],
      hlsSource: data?.media_url,
    };
    dispatch(playNextInQueue(d));
  };

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  const handleLike = async (data) => {
    try {
      const res = await axios.get(`${SERVER_URL.LIKE_SONG}${data?.id}/like`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (res.status === 200) {
        ReactGA.event({
          category: "Song",
          action: `Liked/Unliked a song`,
          label: `Liked/Unliked a song: ${data?.title}`,
        });
        ReactGA.event({
          category: "Song",
          action: `Liked/Unliked a song: ${data?.title}`,
          label: `Liked/Unliked a song: ${data?.title}`,
        });
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to like the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDownload = async (song) => {
    if (!song?.media_url) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const response = await fetch(song.media_url);
      if (!response.ok) {
        Swal.fire({
          title: "Unable to download the song",
          icon: "error",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const fileExtension = song?.media_url?.split(".").pop(); // Assuming the extension is present in the URL
      const fileName =
        song?.title?.replace(/\s+/g, "-").toLowerCase() + "." + fileExtension;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      ReactGA.event({
        category: "Download",
        action: "Download button clicked",
        label: fileName,
      });
      ReactGA.event({
        category: "Download",
        action: `${fileName} is downloaded`,
        label: fileName,
      });
    } catch (error) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={playlist?.title}
        url={window.location.href}
        type={"website"}
        image={playlist?.cover_image_url}
        description={playlist?.description}
      />
      <div className={PlaylistCss.mainDiv}>
        <Grid container rowGap={3} columnGap={2}>
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={300}
              />
            ) : (
              <img
                className={PlaylistCss.artistImage}
                src={playlist?.cover_image_url}
                alt="Artist"
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={8}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 6,
              }}
            >
              <div
                style={{
                  fontSize: FontSizes.h1,
                  fontWeight: "700",
                  color: "white",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                }}
              >
                {isLoading ? (
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    animation="wave"
                    variant="text"
                    width={"30%"}
                    height={30}
                  />
                ) : (
                  playlist?.title
                )}
              </div>
            </div>
            <div className={PlaylistCss.description}>
              {isLoading
                ? Array.from({ length: 5 }, () => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                    />
                  ))
                : playlist?.description}
            </div>
            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width={100}
                height={40}
              />
            ) : (
              <Button
                label="Play"
                onClick={() => handlePlayTrack(playlist)}
                disabled={playlist?.songs?.length === 0}
                size={"medium"}
                styles={{ width: 100 }}
              />
            )}
          </Grid>
        </Grid>

        <TableContainer>
          <Table sx={{ minWidth: 300 }} aria-label="customized table">
            <TableBody>
              {isLoading
                ? Array.from({ length: 3 }, () => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={90}
                    />
                  ))
                : playlist?.songs?.map((row, index) => {
                    return (
                      <GetTableRow
                        key={index}
                        isSelected={row.id === song.id}
                        index={index}
                        row={row}
                        handlePlay={handlePlay}
                        handlePlayNext={handlePlayNext}
                        handleAddToQueue={handleAddToQueue}
                        handleLike={(item) => {
                          isLoggedIn ? handleLike(item) : handleLogin();
                        }}
                        handleDownload={handleDownload}
                      />
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          {moreFromisLoading ? (
            <>
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 5 }}
                animation="wave"
                variant="rectangular"
                width={"30%"}
                height={30}
              />
              <div className={PlaylistCss.playlistsDiv}>
                {Array.from({ length: 4 }, () => {
                  return (
                    <div className={PlaylistCss.playlistsSubDiv}>
                      <MusicCardLoader music={true} />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            moreFromPlaylist?.length !== 0 && (
              <>
                <Label size="lg" label={"More Playlists"} />
                <PlaylistList playlist={moreFromPlaylist?.slice(0, 4)} />
              </>
            )
          )}
        </div>
      </div>
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
});

export default connect(mapStateToProps)(PlaylistView);
