import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import AccountsCss from "./AccountsCss.module.css";
import { useNavigate, useLocation } from "react-router";
import Button from "../../components/button/Button";
import { Delete, Edit } from "@mui/icons-material";
import IconButton from "../../components/icon-button/IconButton";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip";
import MetaTags from "../../MetaTags";
import ViewMore from "../../elements/view-more/ViewMore";
import { Context } from "../../Context";
import { Modal, Skeleton } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import SongsList from "../../components/songs-list/SongsList";
import SongLoader from "../../elements/skeletonLoaders/SongLoader";
import { useLoggedInState } from "../../elements/logged-in-state/useLoggedInState";
import Swal from "sweetalert2";
import { SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { selectMusic, setMusic } from "../../slices/MusicReducer.js";
import { addToQueue } from "../../actions.js";
import EditItinerary from "./elements/EditItinerary.js";
import Unauthorized from "../no-data/Unauthorized.js";
import ReactGA from "react-ga4";

const MySongsPlaylist = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const isLoggedIn = useLoggedInState();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setPageTitle } = useContext(Context);
  const [tempId, setTempId] = useState(null);
  const [songsData, setSongsData] = useState(null);
  const dispatch = useDispatch();
  const [currentSong, setCurrentSong] = useState(null);
  const music = useSelector(selectMusic)[0];
  const [openId, setOpenId] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    if (tempId) {
      ReactGA.send({
        hitType: "pageview",
        page: `/my-songs-playlist/${tempId}`,
        title: "My Songs Playlist",
      });
    }
  }, [tempId]);

  useEffect(() => {
    if (!music?.isPlaying) {
      setCurrentSong(null);
    } else {
      setCurrentSong(music?.musicArgs);
    }
  }, [music]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      const id = u?.split("-")[2];
      setTempId(id);
    }
  }, [pathname]);

  useEffect(() => {
    if (isAuthorized) {
      handleGetSongsPlaylists();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (tempId && user?.id) {
      const authorize = parseInt(tempId) === parseInt(user?.id);
      setIsAuthorized(authorize);
    }
  }, [tempId, user]);

  const handleGetSongsPlaylists = async () => {
    try {
      const res = await axios.get(
        `${SERVER_URL.GET_USER_SONG_PLAYLISTS_BY_ID}${tempId}/playlists`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          const playlist = res?.data?.data?.map((data) => {
            return {
              ...data,
              songs: data?.songs?.map((item) => {
                const genre = item?.album?.genres?.map(
                  (i, index) =>
                    `${i?.title}${
                      index !== item?.album?.genres?.length - 1 ? ", " : ""
                    }`
                );
                return {
                  ...item,
                  subTitle: item?.album?.title,
                  thumbnail: item?.album?.cover_image_url,
                  footerText: genre,
                  hlsSource: item?.media_url,
                };
              }),
            };
          });
          if (playlist) {
            setSongsData(playlist);
          }
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleEditSongPlaylist = async (id, body) => {
    try {
      const res = await axios.put(
        `${SERVER_URL.EDIT_SONG_PLAYLISTS}${id}/playlists`,
        body,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Edit playlist button clicked",
          label: "A playlist has been edited on songs playlist page",
        });
        setIsLoading(true);
        handleGetSongsPlaylists();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to edit the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteSongPlaylist = async (id) => {
    try {
      const res = await axios.delete(
        `${SERVER_URL.DELETE_SONG_PLAYLISTS}${id}/playlists`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Delete playlist button clicked",
          label: "A playlist has been delete from songs playlist page",
        });
        setIsLoading(true);
        handleGetSongsPlaylists();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteFromSongPlaylist = async (id, songId) => {
    const body = {
      playlist_id: id,
      song: songId,
    };
    try {
      const res = await axios.post(
        `${SERVER_URL.DELETE_SONG_FROM_PLAYLISTS}`,
        body,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Delete song from playlist button clicked",
          label:
            "A song has been delete from a playlist on songs playlist page",
        });
        setIsLoading(true);
        handleGetSongsPlaylists();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete from the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Song Playlist's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `${label} playlist Shared on ${item}`,
      label: label,
    });
  }

  function handleAddToQueue(data) {
    ReactGA.event({
      category: "Queue",
      action: `Add to queue button clicked`,
      label: `Songs added to the queue`,
    });
    if (Array.isArray(data)) {
      data?.map((item) => {
        const genre = item?.album?.genres?.map(
          (i, index) =>
            `${i?.title}${
              index !== item?.album?.genres?.length - 1 ? ", " : ""
            }`
        );
        const d = {
          ...item,
          subTitle: item?.album?.title,
          thumbnail: item?.album?.cover_image_url,
          footerText: genre[0],
          hlsSource: item?.media_url,
        };
        dispatch(addToQueue(d));
      });
    } else {
      const genre = data?.album?.genres?.map(
        (i, index) =>
          `${i?.title}${index !== data?.album?.genres?.length - 1 ? ", " : ""}`
      );
      const d = {
        ...data,
        subTitle: data?.album?.title,
        thumbnail: data?.album?.cover_image_url,
        footerText: genre[0],
        hlsSource: data?.media_url,
      };
      dispatch(addToQueue(d));
    }
  }

  const StreamMusic = async (id) => {
    try {
      const res = await axios.get(`${SERVER_URL.STREAM_MUSIC}${id}`, {
        headers: {
          Accept: "application/json",
        },
      });
    } catch (err) {}
  };

  const handlePlay = (data) => {
    ReactGA.event({
      category: "Play",
      action: `Play Song Clicked`,
      label: `${data?.title} is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play Song: ${data?.title}`,
      label: `${data?.title} is being played`,
    });
    setCurrentSong(data);
    const genre = data?.album?.genres?.map(
      (i, index) =>
        `${i?.title}${index !== data?.album?.genres?.length - 1 ? ", " : ""}`
    );
    const d = {
      isPlaying: true,
      musicArgs: {
        id: data?.id,
        title: data?.title,
        subTitle: data?.album?.title,
        hlsSource: data?.media_url,
        thumbnail: data?.album?.cover_image_url,
        footerText: genre[0],
        duration: data?.duration,
        artist_id: data?.album?.artists[0]?.id,
        isLiked: data?.is_liked,
      },
    };
    StreamMusic(data?.id);
    dispatch(setMusic(d));
  };

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isAuthorized) {
      setPageTitle("My Songs Playlist");
    }
  }, [isAuthorized]);

  const handleDownload = async (song) => {
    if (!song?.media_url) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const response = await fetch(song.media_url);
      if (!response.ok) {
        Swal.fire({
          title: "Unable to download the song",
          icon: "error",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const fileExtension = song?.media_url?.split(".").pop(); // Assuming the extension is present in the URL
      const fileName =
        song?.title?.replace(/\s+/g, "-").toLowerCase() + "." + fileExtension;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      ReactGA.event({
        category: "Download",
        action: "Download button clicked",
        label: fileName,
      });
      ReactGA.event({
        category: "Download",
        action: `${fileName} is downloaded`,
        label: fileName,
      });
    } catch (error) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  if (isAuthorized === false) {
    return <Unauthorized />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"My Songs Playlist"}
        url={window.location.href}
        type={"website"}
      />
      <Label label={"My Songs Playlist"} size="lg" />
      {isLoading ? (
        Array.from({ length: 4 }, () => (
          <div className={AccountsCss.mgb}>
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={"30%"}
              height={30}
            />
            <SongLoader />
          </div>
        ))
      ) : songsData?.length !== 0 ? (
        songsData?.map((item, index) => (
          <div key={index}>
            <div className={AccountsCss.mainDiv}>
              <Label label={item.title} size="md" />
              {isLoggedIn &&
                parseInt(tempId) === user?.id &&
                (width < 500 ? (
                  <div className={AccountsCss.buttonDiv}>
                    <SharingTooltip
                      onShare={handleShare}
                      transparent
                      link={`https://${window.location.href?.split("/")[2]}/music/playlist/${item?.slug}`}
                    />
                    <IconButton
                      backgroundColor={"transparent"}
                      onClick={() => {
                        setOpen(true);
                        setOpenId(item?.id);
                      }}
                      icon={<Edit className={AccountsCss.closeIcon} />}
                    />
                    <IconButton
                      backgroundColor={"transparent"}
                      icon={<Delete className={AccountsCss.closeIcon} />}
                      onClick={() => handleDeleteSongPlaylist(item?.id)}
                    />
                  </div>
                ) : (
                  <div className={AccountsCss.buttonDiv}>
                    <SharingTooltip
                      onShare={handleShare}
                      withText
                      showAnimation={true}
                      backgroundColor={"#1C1C1C"}
                      hoverColor={"#ff0000"}
                      link={`https://${window.location.href?.split("/")[2]}/music/playlist/${item?.slug}`}
                    />
                    <Button
                      label="Edit"
                      showAnimation={true}
                      backgroundColor={"#1C1C1C"}
                      hoverColor={"#ff0000"}
                      icon={<Edit className={AccountsCss.closeIcon} />}
                      iconLeft={true}
                      onClick={() => {
                        setOpen(true);
                        setOpenId(item?.id);
                      }}
                    />
                    <Button
                      label="Delete"
                      showAnimation={true}
                      backgroundColor={"#1C1C1C"}
                      hoverColor={"#ff0000"}
                      icon={<Delete className={AccountsCss.closeIcon} />}
                      iconLeft={true}
                      onClick={() => handleDeleteSongPlaylist(item?.id)}
                    />
                  </div>
                ))}
              {open && openId === item?.id && (
                <Modal
                  open={open}
                  onClose={() => setOpen(false)}
                  className={AccountsCss.modalDiv}
                >
                  <EditItinerary
                    onClick={(i) => handleEditSongPlaylist(item?.id, i)}
                    setOpen={(bool) => setOpen(bool)}
                    name={item?.title}
                    itineraryDescription={item?.description}
                    isPlaylist={true}
                  />
                </Modal>
              )}
            </div>
            {item?.songs && item?.songs?.length === 0 && (
              <>
                <div className={AccountsCss.flexDrC}>
                  <div className={AccountsCss.noItems}>No items added yet</div>
                  {isLoggedIn && parseInt(tempId) === user?.id && (
                    <>
                      <div className={AccountsCss.noItemsSub}>To add items</div>
                      <Button
                        label="Click Here"
                        backgroundColor={"#ff0000"}
                        styles={{ alignSelf: "center" }}
                        onClick={() => handleClick("/music")}
                      />
                    </>
                  )}
                </div>
              </>
            )}
            {item?.songs && item?.songs?.length !== 0 && (
              <SongsList
                showGrid={true}
                onClick={handlePlay}
                handleToAdd={handleAddToQueue}
                songArray={item?.songs}
                currentSongItem={currentSong}
                showDelete={isLoggedIn && parseInt(tempId) === user?.id}
                handleDelete={(i) => handleDeleteFromSongPlaylist(item?.id, i)}
                handleDownload={handleDownload}
              />
            )}
            {item?.songs &&
            item?.songs?.length !== 0 &&
            item?.songs?.length >= 4 ? (
              <ViewMore onClick={() => handleClick(`${item?.slug}`)} />
            ) : (
              <div className={AccountsCss.mgb} />
            )}
          </div>
        ))
      ) : (
        <div className={AccountsCss.noItems}>
          No playlist added,{" "}
          <span
            onClick={() => handleClick("/music")}
            className={AccountsCss.startButton}
          >
            Start
          </span>{" "}
          adding to your Songs Playlist
        </div>
      )}
    </NavContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps)(MySongsPlaylist);
