import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Tab, Tabs } from "@mui/material";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Review from "./Review";
import { FontSizes } from "../../../config";
import CarnivalCss from "../CarnivalHome.module.css";

export default function CarnivalTabs({
  value,
  handleChange,
  isPastCarnival,
  tjjRating,
  userRating,
  setId,
  handleSubmitRatings,
  reviews,
  reviewsPage,
  reviewsLastPage,
  updateCommentPageNumber,
  updateReviewsPageNumber,
  ratings,
  handleLike,
  handlePostComment,
  comments,
  commentsLastPage,
  commentsPage,
  generalInformation,
  foodNDrink,
  tjjRecommendations,
  commentsIsLoading,
  reviewsIsLoading,
  moreReviewsIsLoading,
  moreCommentsIsLoading,
  handleDeleteComment,
  isDeleting,
}) {
  const [width, setWidth] = useState(window.innerWidth);
  const [opened, setOpened] = useState([0]);
  const [show, setShow] = useState(false);
  const handleOpen = (index) => {
    if (opened.includes(index)) {
      for (let i = 0; i < opened.length; i++) {
        if (opened[i] == index) {
          opened.splice(i, 1);
          setShow(!show);
        }
      }
    } else {
      setOpened((prev) => [...prev, index]);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const upcomingTabs = [
    {
      title: "General Information",
      func: GeneralInformation(generalInformation, width),
    },
    {
      title: "Food & Drinks",
      func: FoodNDrinks(foodNDrink),
    },
    {
      title: "TJJ Recommendation",
      func: TJJRecommendations(tjjRecommendations, width),
    },
  ];

  const pastTabs = [
    {
      title: "Reviews",
      func: (
        <Review
          userRating={userRating}
          tjjRating={tjjRating}
          setId={setId}
          handleSubmitRatings={handleSubmitRatings}
          reviews={reviews}
          reviewsPage={reviewsPage}
          reviewsLastPage={reviewsLastPage}
          updateCommentPageNumber={updateCommentPageNumber}
          updateReviewsPageNumber={updateReviewsPageNumber}
          ratings={ratings}
          handleLike={handleLike}
          handlePostComment={handlePostComment}
          comments={comments}
          commentsLastPage={commentsLastPage}
          commentsPage={commentsPage}
          commentsIsLoading={commentsIsLoading}
          reviewsIsLoading={reviewsIsLoading}
          moreReviewsIsLoading={moreReviewsIsLoading}
          moreCommentsIsLoading={moreCommentsIsLoading}
          handleDeleteComment={handleDeleteComment}
          isDeleting={isDeleting}
        />
      ),
    },
    {
      title: "General Information",
      func: GeneralInformation(generalInformation, width),
    },
    {
      title: "Food & Drinks",
      func: FoodNDrinks(foodNDrink),
    },
    {
      title: "TJJ Recommendation",
      func: TJJRecommendations(tjjRecommendations, width),
    },
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className={CarnivalCss.tabPanel}>{children}</div>
        )}
      </div>
    );
  }

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: "98%",
      width: "100%",
      backgroundColor: "#FF0000",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
      fontSize: 30,
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: "700",
      fontSize: FontSizes.subTitle,
      fontFamily: "Inter-Bold",
      marginRight: theme.spacing(1),
      color: "#fff",
      minWidth: isPastCarnival ? "24.5%" : "32.5%",
      "&.Mui-selected": {
        color: "#fff",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(255, 255, 255, 0.32)",
      },
    })
  );

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className={CarnivalCss.tabDiv}>
      {width < 900 ? (
        <>
          {isPastCarnival ? (
            <div className={CarnivalCss.tabs}>
              {pastTabs.map((item, index) => {
                return (
                  <div className={CarnivalCss.tabsMain} key={index}>
                    <div
                      className={CarnivalCss.tabsText}
                      onClick={() => handleOpen(index)}
                    >
                      {item.title}
                      {opened.includes(index) ? (
                        <MdKeyboardArrowUp className={CarnivalCss.tabsArrow} />
                      ) : (
                        <MdKeyboardArrowDown
                          className={CarnivalCss.tabsArrow}
                        />
                      )}
                    </div>
                    {opened.includes(index) && item.func}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={CarnivalCss.tabs}>
              {upcomingTabs.map((item, index) => {
                return (
                  <div className={CarnivalCss.tabsMain} key={index}>
                    <div
                      className={CarnivalCss.tabsText}
                      onClick={() => handleOpen(index)}
                    >
                      {item.title}
                      {opened.includes(index) ? (
                        <MdKeyboardArrowUp className={CarnivalCss.tabsArrow} />
                      ) : (
                        <MdKeyboardArrowDown
                          className={CarnivalCss.tabsArrow}
                        />
                      )}
                    </div>
                    {opened.includes(index) && item.func}
                  </div>
                );
              })}
            </div>
          )}
        </>
      ) : (
        <>
          <div className={CarnivalCss.leftShadow} />
          {isPastCarnival ? (
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={width < 431 ? false : true}
              allowScrollButtonsMobile={width < 431 ? false : true}
              aria-label={
                width < 431
                  ? "scrollable prevent tabs example"
                  : "scrollable force tabs example"
              }
            >
              <StyledTab label="Reviews" {...a11yProps(0)} />
              <StyledTab label="General Information" {...a11yProps(1)} />
              <StyledTab label="Food & Drinks" {...a11yProps(2)} />
              <StyledTab label="TJJ Recommendations" {...a11yProps(3)} />
            </StyledTabs>
          ) : (
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={width < 431 ? false : true}
              allowScrollButtonsMobile={width < 431 ? false : true}
              aria-label={
                width < 431
                  ? "scrollable prevent tabs example"
                  : "scrollable force tabs example"
              }
            >
              <StyledTab label="General Information" {...a11yProps(0)} />
              <StyledTab label="Food & Drinks" {...a11yProps(1)} />
              <StyledTab label="TJJ Recommendations" {...a11yProps(2)} />
            </StyledTabs>
          )}
          {isPastCarnival ? (
            <>
              <TabPanel value={value} index={0}>
                <Review
                  userRating={userRating}
                  tjjRating={tjjRating}
                  setId={setId}
                  handleSubmitRatings={handleSubmitRatings}
                  reviews={reviews}
                  reviewsPage={reviewsPage}
                  reviewsLastPage={reviewsLastPage}
                  updateCommentPageNumber={updateCommentPageNumber}
                  updateReviewsPageNumber={updateReviewsPageNumber}
                  ratings={ratings}
                  handleLike={handleLike}
                  handlePostComment={handlePostComment}
                  comments={comments}
                  commentsLastPage={commentsLastPage}
                  commentsPage={commentsPage}
                  commentsIsLoading={commentsIsLoading}
                  reviewsIsLoading={reviewsIsLoading}
                  moreReviewsIsLoading={moreReviewsIsLoading}
                  moreCommentsIsLoading={moreCommentsIsLoading}
                  handleDeleteComment={handleDeleteComment}
                  isDeleting={isDeleting}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                {GeneralInformation(generalInformation, width)}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {FoodNDrinks(foodNDrink)}
              </TabPanel>
              <TabPanel value={value} index={3}>
                {TJJRecommendations(tjjRecommendations, width)}
              </TabPanel>
            </>
          ) : (
            <>
              <TabPanel value={value} index={0}>
                {GeneralInformation(generalInformation, width)}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {FoodNDrinks(foodNDrink)}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {TJJRecommendations(tjjRecommendations, width)}
              </TabPanel>
            </>
          )}
          <div className={CarnivalCss.rightShadow} />
        </>
      )}
    </div>
  );
}

function section(item, index) {
  return (
    <div key={index} className={CarnivalCss.flexDR}>
      <div className={CarnivalCss.headingStyle}>
        <span dangerouslySetInnerHTML={{ __html: item?.icon }} />
        {item?.title}
      </div>
      <div
        className={`${CarnivalCss.contentStyle} ck-content`}
        dangerouslySetInnerHTML={{ __html: item?.description }}
      />
    </div>
  );
}

function GeneralInformation(item, width) {
  return (
    <div className={CarnivalCss.divMain}>
      {item && item?.length !== 0 ? (
        <Grid container>
          <Grid item xs={12} md={6} className={CarnivalCss.gridGeneral}>
            {item
              ?.slice(0, Math.ceil(item?.length / 2))
              ?.map((item, index) => section(item, index))}
          </Grid>
          <Grid item xs={12} md={6} className={CarnivalCss.gridLeftPadding}>
            {item
              ?.slice(Math.ceil(item?.length / 2), item?.length)
              ?.map((item, index) => section(item, index))}
          </Grid>
        </Grid>
      ) : (
        <div className={CarnivalCss.noData}>No Information Available</div>
      )}
    </div>
  );
}

function TJJRecommendations(item) {
  return (
    <div className={CarnivalCss.divMain}>
      {item && item?.length !== 0 ? (
        <Grid container>
          <Grid item xs={12} md={6} className={CarnivalCss.gridGeneral}>
            {item
              ?.slice(0, Math.ceil(item?.length / 2))
              ?.map((item, index) => section(item, index))}
          </Grid>
          <Grid item xs={12} md={6} className={CarnivalCss.gridLeftPadding}>
            {item
              ?.slice(Math.ceil(item?.length / 2), item?.length)
              ?.map((item, index) => section(item, index))}
          </Grid>
        </Grid>
      ) : (
        <div className={CarnivalCss.noData}>No Information Available</div>
      )}
    </div>
  );
}

function FoodNDrinks(item) {
  return (
    <div className={CarnivalCss.divMain}>
      {item && item?.length !== 0 ? (
        <Grid container>
          <Grid item xs={12} md={6} className={CarnivalCss.gridGeneral}>
            {item
              ?.slice(0, Math.ceil(item?.length / 2))
              ?.map((item, index) => section(item, index))}
          </Grid>

          <Grid item xs={12} md={6} className={CarnivalCss.gridLeftPadding}>
            {item
              ?.slice(Math.ceil(item?.length / 2), item?.length)
              ?.map((item, index) => section(item, index))}
          </Grid>
        </Grid>
      ) : (
        <div className={CarnivalCss.noData}>No Information Available</div>
      )}
    </div>
  );
}
