import { Skeleton } from '@mui/material';
import React from 'react'
import Label from '../../../../elements/label/Label';
import ViewMore from '../../../../elements/view-more/ViewMore';
import GlobalSearchCss from "../../GlobalSearch.module.css";
import VideoList from '../../../../elements/Flatlist/VideoList';
import VideoCardLoader from '../../../../elements/skeletonLoaders/VideoCardLoader';

export default function Videos({
    videos,
    isLoading,
    length,
    showViewAll = false,
    setValue,
    moreVideosIsLoading,
    videosPageNumber,
    videosLastPage,
    updateVideoPageNumber = undefined,
  }) {
    return (
      <div>
        <div className={GlobalSearchCss.mainDiv}>
          {isLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={"40%"}
              height={50}
            />
          ) : (
            <Label label={`Videos (${length} results)`} size="md" />
          )}
        </div>
        {isLoading ? (
          <div className={GlobalSearchCss.flexDrR} style={{ marginBottom: 30 }}>
            {Array.from({ length: 8 }, (_,index) => {
              return <VideoCardLoader key={index} size={"sm"} />;
            })}
          </div>
        ) : (
          <>
            <VideoList videos={videos} />
            {showViewAll && <ViewMore onClick={() => setValue(5)} />}
            {moreVideosIsLoading ? (
              <div className={GlobalSearchCss.flexDrR1}>
                {Array.from({ length: 8 }, (_,index) => {
                  return <VideoCardLoader key={index} size={"sm"} />;
                })}
              </div>
            ) : videosLastPage != null &&
              videosPageNumber < videosLastPage &&
              !showViewAll ? (
              <ViewMore loadMore onClick={updateVideoPageNumber} />
            ) : (
              !showViewAll && <div className={GlobalSearchCss.mgb} />
            )}
          </>
        )}
      </div>
    );
  }
