export const convertNumUnit = (input) => {
  let number = typeof input === "string" ? parseInt(input) : input;
  const num = number;

  if (!number || isNaN(number)) return "0";

  const suffixes = ["", "K", "M", "B"];
  let suffixIndex = 0;

  number = Math.abs(number);

  while (number >= 1000 && suffixIndex < suffixes.length - 1) {
    number /= 1000;
    suffixIndex++;
  }

  const formattedNumber =
    num < 1000
      ? number
      : `${number.toFixed(2)} ${suffixes[suffixIndex]}`;
  return formattedNumber;
};
