import { Skeleton } from '@mui/material';
import React from 'react'
import Label from '../../../../elements/label/Label';
import ViewMore from '../../../../elements/view-more/ViewMore';
import GlobalSearchCss from "../../GlobalSearch.module.css";
import AlbumsList from '../../../../elements/Flatlist/AlbumsList';
import MusicCardLoader from "../../../../elements/skeletonLoaders/MusicCardLoader";

export default function Albums({
    albums,
    isLoading,
    length,
    showViewAll = false,
    setValue,
    moreAlbumsIsLoading,
    albumsPageNumber,
    albumsLastPage,
    loadMoreAlbums,
  }) {
    return (
      <div>
        <div className={GlobalSearchCss.mainDiv}>
          {isLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={"40%"}
              height={50}
            />
          ) : (
            <Label label={`Albums (${length} results)`} size="md" />
          )}
        </div>
        {isLoading ? (
          <div className={GlobalSearchCss.albumsDiv}>
            {Array.from({ length: 8 }, (_, index) => (
              <div key={index} className={GlobalSearchCss.albumsSubDiv}>
                <MusicCardLoader />
              </div>
            ))}
          </div>
        ) : (
          <>
            <AlbumsList albums={albums} />
            {moreAlbumsIsLoading && <div style={{ marginTop: 20 }} />}
            {moreAlbumsIsLoading && (
              <div className={GlobalSearchCss.albumsDiv}>
                {Array.from({ length: 8 }, (_, index) => (
                  <div key={index} className={GlobalSearchCss.albumsSubDiv}>
                    <MusicCardLoader />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        {showViewAll && <ViewMore onClick={() => setValue(8)} />}
        {albums?.length !== 0 &&
          albumsLastPage != null &&
          albumsPageNumber < albumsLastPage &&
          !isLoading &&
          !moreAlbumsIsLoading &&
          !showViewAll && <ViewMore loadMore={true} onClick={loadMoreAlbums} />}
      </div>
    );
  }
