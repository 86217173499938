import React, { useContext, useEffect, useState } from "react";
import TagsCss from "./Tags.module.css";
import Label from "../../elements/label/Label";
import TextField from "../../components/textfield/TextField";
import { Close, Search } from "@mui/icons-material";
import NavContainer from "../../elements/NavContainer/NavContainer";
import { IconButton } from "@mui/material";
import SortedTagsList from "../../elements/sorted-list/SortedTagsList";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../Context";
import { Skeleton } from "@mui/material";
import MetaTags from "../../MetaTags";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import NoData from "../../elements/no-data/NoData.js";
import ReactGA from "react-ga4";

export default function Tags() {
  const [search, setSearch] = useState("");
  const { setPageTitle } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [tagsList, setTagsList] = useState([]);
  const location = useLocation();
  const [label, setLabel] = useState("All Tags");
  const [routingPath, setRoutingPath] = useState("");
  const navigate = useNavigate();
  const [type, setType] = useState(null);
  const [finalSearch, setFinalSearch] = useState("");
  const [filteredTagsList, setFilteredTagsList] = useState([]);
  const [status, setStatus] = useState(null);

  const getTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
          type: type,
        },
      });
      if (res.status === 200) {
        setStatus(res.status);
        if (res?.data) {
          setTagsList(res?.data?.data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (type) {
      getTags();
      ReactGA.send({
        hitType: "pageview",
        page: `/${type}/tags`,
        title: `All Tags(${type})`,
      });
    }
  }, [type]);

  useEffect(() => {
    const names = location.pathname.split("/");
    if (names[1] === "newsletters") {
      setPageTitle("All Tags - Newsletters");
      setLabel("All Tags - Newsletters");
      setRoutingPath(names[1]);
      setType("newsletter");
    }
    if (names[1] === "events") {
      setPageTitle("All Tags - Events");
      setLabel("All Tags - Events");
      setRoutingPath(names[1]);
      setType("event");
    }
    if (names[1] === "reviews") {
      setPageTitle("All Tags - Reviews");
      setLabel("All Tags - Reviews");
      setRoutingPath(names[1]);
      setType("review");
    }
    if (names[1] === "news-articles") {
      setPageTitle("All Tags - News Articles");
      setLabel("All Tags - News Articles");
      setRoutingPath(names[1]);
      setType("news_article");
    }
    if (names[1] === "videos") {
      setPageTitle("All Tags - Videos");
      setLabel("All Tags - Videos");
      setRoutingPath("videos/all");
      setType("video");
    }
    if (names.includes("classifieds")) {
      setPageTitle("All Tags - Classifieds");
      setLabel("All Tags - Classifieds");
      setRoutingPath("carnival/classifieds");
      setType("classified");
    }
  }, [location.pathname]);

  const handleClick = (item) => {
    const params = `tag=${item}`;
    const route = `/${routingPath}?` + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (finalSearch) {
      filterList();
    }
  }, [finalSearch]);

  const handleSearch = (item) => {
    if (item && item !== "") {
      setFinalSearch(item);
      ReactGA.event({
        category: "Search",
        action: `Searched ${item}`,
        label: `Searched ${item} on all tags page`,
      });
      ReactGA.event({
        category: "Search",
        action: `Searched ${item} on all tags page`,
        label: `Searched ${item} on all tags page`,
      });
    }
  };

  const handleClear = () => {
    setSearch("");
    setFinalSearch("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(search);
    }
  };

  function filterList() {
    const fl = tagsList?.filter((tag) =>
      tag?.name?.toLowerCase()?.includes(search.toLowerCase())
    );
    setFilteredTagsList(fl);
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"Tags"}
        url={window.location.href}
        type={"website"}
        image="../../assets/images/tjj-logo.jpg"
      />
      {isLoading ? (
        <>
          <div className={TagsCss.mgb}>
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 5 }}
              animation="wave"
              variant="rectangular"
              width={"20%"}
              height={30}
            />
            <div className={TagsCss.flexDrR}>
              {Array.from({ length: 15 }, (_, index) => (
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"20%"}
                  key={index}
                />
              ))}
            </div>
          </div>
          <div className={TagsCss.mgb}>
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 5 }}
              animation="wave"
              variant="rectangular"
              width={"20%"}
              height={30}
            />
            <div className={TagsCss.flexDrR}>
              {Array.from({ length: 15 }, (_, index) => (
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"20%"}
                  key={index}
                />
              ))}
            </div>
          </div>
          <div className={TagsCss.mgb}>
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 5 }}
              animation="wave"
              variant="rectangular"
              width={"20%"}
              height={30}
            />
            <div className={TagsCss.flexDrR}>
              {Array.from({ length: 15 }, (_, index) => (
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"20%"}
                  key={index}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={TagsCss.allTagsMainDiv}>
            <Label size="lg" label={label} />
            <div className={TagsCss.allTagsSubDiv}>
              <TextField
                label="Search"
                onChange={(value) => setSearch(value)}
                defaultValue={search}
                size="small"
                onKeyDown={handleKeyPress}
              />
              {finalSearch ? (
                <IconButton
                  onClick={handleClear}
                  className={TagsCss.allTagsIconButton}
                >
                  <Close className={TagsCss.allTagsSearchIcon} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => handleSearch(search)}
                  className={TagsCss.allTagsIconButton}
                >
                  <Search className={TagsCss.allTagsSearchIcon} />
                </IconButton>
              )}
            </div>
          </div>
          {tagsList?.length === 0 ||
          (finalSearch && filteredTagsList?.length === 0) ? (
            <NoData errorCode={status} />
          ) : (
            <SortedTagsList
              data={finalSearch ? filteredTagsList : tagsList}
              handleClick={handleClick}
            />
          )}
        </>
      )}
    </NavContainer>
  );
}
