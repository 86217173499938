export default function ExtractStateCountry(item){
    let value="";
    if(item?.state)
    {
      value = value + item?.state;
    }
    if(item?.state && item?.country)
    {
      value= value + ", "
    }
    if(item?.country)
    {
      value= value + item?.country
    }

    return value;
  }