import React, { useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { FontSizes } from "../../../config";
import EventCss from "../GlobalSearch.module.css";
import TextField from "../../../components/textfield/TextField";
import AutoComplete from "../../../components/autocomplete/AutoComplete";
import Button from "../../../components/button/Button";
import { Close } from "@mui/icons-material";
import { datePickerStyle, pickerStyle } from "../../../css/DatePickerStyles";
import { useSearchParams } from "react-router-dom";
import ExtractStateCountry from "../../../elements/country-footer/ExtractStateCountry";

const timeArray = [
  { name: "Morning", slug: "morning" },
  { name: "Afternoon", slug: "afternoon" },
  { name: "Evening", slug: "evening" },
  { name: "Night", slug: "night" },
];

const reviewType = [
  { label: "All", value: "all" },
  { label: "Event", value: "event" },
  { label: "Carnival", value: "carnival" },
];

export default function Filter({
  value,
  onClose,
  onSearch,
  onReset,
  tagOptions,
  categoryOptions,
  countryOptions,
  promoterOptions,
  photographerOptions,
  authorOptions,
  artistOptions,
  albumOptions,
  genreOptions,
  yearOptions,
  regionOptions,
}) {
  const [location, setLocation] = useState([]);
  const [channel, setChannel] = useState([]);
  const [author, setAuthor] = useState([]);
  const [carnival, setCarnival] = useState([]);
  const [event, setEvent] = useState([]);
  const [genre, setGenre] = useState([]);
  const [song, setSong] = useState([]);
  const [photographer, setPhotographer] = useState([]);
  const [year, setYear] = useState([]);
  const [time, setTime] = useState([]);
  const [promoter, setPromoter] = useState([]);
  const [category, setCategory] = useState([]);
  const [tag, setTag] = useState([]);
  const [artist, setArtist] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = React.useState();
  const [index, setIndex] = useState(0);
  const [album, setAlbum] = useState([]);
  const [type, setType] = useState("");
  const [country, setCountry] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const [tagsArray, setTagsArray] = useState([]);
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [countryArray, setCountryArray] = useState([]);
  const [promotersArray, setPromoterArray] = useState([]);
  const [photographersArray, setPhotographersArray] = useState([]);
  const [authorArray, setAuthorArray] = useState([]);
  const [artistArray, setArtistArray] = useState([]);
  const [yearsArray, setYearsArray] = useState([]);
  const [genreArray, setGenreArray] = useState([]);
  const [albumsArray, setAlbumsArray] = useState([]);
  const [region, setRegion] = useState("");
  const [regionArray, setRegionArray] = useState([]);

  function handleSearch() {
    onReset();
    let params = {};
    if (location.length > 0) {
      params = {
        ...params,
        location: location,
      };
    }
    if (region !== "") {
      params = {
        ...params,
        region: region,
      };
    }
    if (channel.length > 0) {
      params = {
        ...params,
        channel: channel,
      };
    }
    if (carnival.length > 0) {
      params = {
        ...params,
        carnival: carnival,
      };
    }
    if (event.length > 0) {
      params = {
        ...params,
        event: event,
      };
    }
    if (genre.length > 0) {
      params = {
        ...params,
        genre: genre,
      };
    }
    if (song.length > 0) {
      params = {
        ...params,
        song: song,
      };
    }
    if (author.length > 0) {
      params = {
        ...params,
        author: author,
      };
    }
    if (album.length > 0) {
      params = {
        ...params,
        album: album,
      };
    }
    if (tag?.length !== 0) {
      params = {
        ...params,
        tag: tag,
      };
    }
    if (category.length !== 0) {
      params = {
        ...params,
        category: category,
      };
    }
    if (promoter.length !== 0) {
      params = {
        ...params,
        promoter: promoter,
      };
    }
    if (type.length !== 0) {
      params = {
        ...params,
        review_type: type,
      };
    }
    if (time.length !== 0) {
      params = {
        ...params,
        time: time,
      };
    }
    if (photographer.length !== 0) {
      params = {
        ...params,
        photographer: photographer,
      };
    }
    if (artist.length !== 0) {
      params = {
        ...params,
        artist: artist,
      };
    }
    if (year.length !== 0) {
      params = {
        ...params,
        year: year,
      };
    }
    if (startDate) {
      params = {
        ...params,
        startDate: startDate,
      };
    }
    if (endDate) {
      params = {
        ...params,
        endDate: endDate,
      };
    }
    if (country && country !== "") {
      params = {
        ...params,
        country: country,
      };
    }
    onSearch(params);
  }

  useEffect(() => {
    const op = regionOptions?.map((item, index) => ({
      id: index,
      name: ExtractStateCountry(item),
      slug: ExtractStateCountry(item),
    }));
    if (op) {
      setRegionArray(op);
    }
  }, [regionOptions]);

  useEffect(() => {
    const op = countryOptions?.map((item, index) => ({
      id: index,
      name: item?.full_address,
      slug: item?.slug,
    }));
    if (op) {
      setCountryArray(op);
    } else {
      setCountryArray([]);
    }
  }, [countryOptions]);

  useEffect(() => {
    const op = promoterOptions?.map((item) => ({
      id: item?.id,
      name: item?.display_name,
      slug: item?.slug,
    }));
    if (op) {
      setPromoterArray(op);
    } else {
      setPromoterArray([]);
    }
  }, [promoterOptions]);

  useEffect(() => {
    const op = artistOptions?.map((item) => ({
      id: item.id,
      name: `${item?.first_name} ${item?.last_name}`,
      slug: item.slug,
    }));
    if (op) {
      setArtistArray(op);
    }
  }, [artistOptions]);

  useEffect(() => {
    const op = photographerOptions?.map((item) => ({
      id: item?.id,
      name: `${item?.first_name} ${item?.last_name}`,
      slug: item?.slug,
    }));
    if (op) {
      setPhotographersArray(op);
    } else {
      setPhotographersArray([]);
    }
  }, [photographerOptions]);

  useEffect(() => {
    const op = tagOptions?.map((item) => ({
      id: item?.id,
      name: item?.slug,
      slug: item?.slug,
    }));
    if (op) {
      setTagsArray(op);
    } else {
      setTagsArray([]);
    }
  }, [tagOptions]);

  useEffect(() => {
    const op = authorOptions?.map((item) => ({
      id: item.id,
      name: item.first_name + " " + item.last_name,
      slug: item.slug,
    }));
    if (op) {
      setAuthorArray(op);
    } else {
      setAuthorArray([]);
    }
  }, [authorOptions]);

  useEffect(() => {
    const op = albumOptions?.map((item) => ({
      id: item?.id,
      name: item?.title,
      slug: item?.slug,
    }));
    if (op) {
      setAlbumsArray(op);
    } else {
      setAlbumsArray([]);
    }
  }, [albumOptions]);

  useEffect(() => {
    const op = categoryOptions?.map((item) => ({
      id: item?.id,
      name: item?.title,
      slug: item?.slug,
    }));
    if (op) {
      setCategoriesArray(op);
    } else {
      setCategoriesArray([]);
    }
  }, [categoryOptions]);

  useEffect(() => {
    const op = yearOptions?.map((item, index) => ({
      id: index,
      name: item?.year,
      slug: item?.year,
    }));
    if (op) {
      setYearsArray(op);
    } else {
      setYearsArray([]);
    }
  }, [yearOptions]);

  useEffect(() => {
    const op = genreOptions?.map((item) => ({
      id: item?.id,
      name: item?.title,
      slug: item?.slug,
    }));
    if (op) {
      setGenreArray(op);
    }
  }, [genreOptions]);

  useEffect(() => {
    if (searchParams.size !== 0) {
      if (searchParams?.getAll("location")?.length !== 0) {
        setLocation(searchParams?.getAll("location"));
      }
      if (searchParams?.get("region")) {
        setRegion(searchParams?.get("region"));
      }
      if (searchParams?.getAll("photographer")?.length !== 0) {
        setPhotographer(searchParams?.getAll("photographer"));
      }

      if (searchParams?.getAll("genre")?.length !== 0) {
        setGenre(searchParams?.getAll("genre"));
      }
      if (searchParams?.get("review_type")) {
        setType(searchParams?.get("review_type"));
      }
      if (searchParams?.getAll("album")?.length !== 0) {
        setAlbum(searchParams?.getAll("album"));
      }
      if (searchParams?.getAll("artist_name")?.length !== 0) {
        setArtist(searchParams?.getAll("artist_name"));
      }
      if (searchParams?.getAll("category")?.length !== 0) {
        setCategory(searchParams?.getAll("category"));
      }
      if (searchParams?.getAll("year")?.length !== 0) {
        setYear(searchParams?.getAll("year"));
      }
      if (searchParams?.getAll("time")?.length !== 0) {
        setTime(searchParams?.getAll("time"));
      }
      if (searchParams?.getAll("promoter")?.length !== 0) {
        setPromoter(searchParams?.getAll("promoter"));
      }
      if (searchParams?.getAll("tag")?.length !== 0) {
        setTag(searchParams?.getAll("tag"));
      }
      if (searchParams?.get("startDate")) {
        setStartDate(searchParams?.get("startDate"));
      }
      if (searchParams?.get("endDate")) {
        setEndDate(searchParams?.get("endDate"));
      }
      if (searchParams?.get("country")) {
        setCountry(searchParams?.get("country"));
      }
    }
  }, [searchParams]);

  const handleReset = () => {
    setLocation([]);
    setArtist([]);
    setAuthor([]);
    setCarnival([]);
    setCategory([]);
    setChannel([]);
    setEndDate(null);
    setStartDate(null);
    setEvent([]);
    setGenre([]);
    setSong([]);
    setYear([]);
    setTime([]);
    setTag([]);
    setCategory([]);
    setPromoter([]);
    setType("");
    setRegion("");
    setAlbum([]);
    setCountry("");
    onReset();
  };

  useEffect(() => {
    setIndex(value);
  }, [value]);

  const handleStartDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setStartDate(d);
  };

  const handleEndDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setEndDate(d);
  };

  return (
    <div className={EventCss.filter}>
      <div className={EventCss.filterDiv}>
        <div className={EventCss.calendarHead}>Filters</div>
        <Close onClick={onClose} className={EventCss.closeIcon} />
      </div>

      {index === 8 && countryArray?.length !== 0 && (
        <AutoComplete
          defaultValue={location}
          handleOnChange={(val) => setLocation(val)}
          options={countryArray}
          label={index === 3 ? "Venue" : "Location"}
          isSlug={true}
        />
      )}
      {(index === 2 || index === 3 || index === 4) &&
        regionArray?.length !== 0 && (
          <AutoComplete
            defaultValue={region}
            handleOnChange={(val) => setRegion(val)}
            options={regionArray}
            label={"Locations"}
            singleSelect={true}
            isSlug={true}
          />
        )}
      {index === 10 && (
        <TextField
          defaultValue={type}
          onChange={(val) => setType(val)}
          data={reviewType}
          label="Review Type"
          type={"select"}
        />
      )}
      {index === 7 && (
        <TextField
          defaultValue={country}
          onChange={(val) => setCountry(val)}
          label="Country"
          size={"small"}
        />
      )}
      {(index === 6 || index === 9) && albumsArray?.length !== 0 && (
        <AutoComplete
          defaultValue={album}
          handleOnChange={(val) => setAlbum(val)}
          options={albumsArray}
          label="Album Name"
          isSlug={true}
        />
      )}

      {(index === 1 || index === 10) && authorArray?.length !== 0 && (
        <AutoComplete
          defaultValue={author}
          handleOnChange={(val) => setAuthor(val)}
          options={authorArray}
          label="Author"
          isSlug={true}
        />
      )}

      {(index === 6 || index === 8 || index === 5) &&
        artistArray?.length !== 0 && (
          <AutoComplete
            defaultValue={artist}
            handleOnChange={(val) => setArtist(val)}
            options={artistArray}
            label="Artist Name"
            isSlug={true}
          />
        )}
      {(index === 6 || index === 7 || index === 8) &&
        genreArray?.length !== 0 && (
          <AutoComplete
            defaultValue={genre}
            handleOnChange={(val) => setGenre(val)}
            options={genreArray}
            label="Genre"
            isSlug={true}
          />
        )}

      {(index === 1 ||
        index === 2 ||
        index === 3 ||
        index === 4 ||
        index === 5 ||
        index === 10) &&
        tagsArray?.length !== 0 && (
          <AutoComplete
            defaultValue={tag}
            handleOnChange={(val) => setTag(val)}
            options={tagsArray}
            label="Tags"
            isSlug={true}
          />
        )}
      {index === 4 && photographersArray?.length !== 0 && (
        <AutoComplete
          defaultValue={photographer}
          handleOnChange={(val) => setPhotographer(val)}
          options={photographersArray}
          label="Photographers"
          isSlug={true}
        />
      )}

      {(index === 1 || index === 3 || index === 5 || index === 10) &&
        categoriesArray?.length !== 0 && (
          <AutoComplete
            defaultValue={category}
            handleOnChange={(val) => setCategory(val)}
            options={categoriesArray}
            label="Category"
            isSlug={true}
          />
        )}

      {index === 3 && (
        <AutoComplete
          defaultValue={time}
          handleOnChange={(val) => setTime(val)}
          options={timeArray}
          label="Time"
          isSlug={true}
        />
      )}

      {index === 3 && promotersArray?.length !== 0 && (
        <AutoComplete
          defaultValue={promoter}
          handleOnChange={(val) => setPromoter(val)}
          options={promotersArray}
          label="Promoter"
          isSlug={true}
        />
      )}

      {(index === 6 || index === 8 || index === 10) &&
        yearsArray?.length !== 0 && (
          <AutoComplete
            defaultValue={year}
            handleOnChange={(val) => setYear(val)}
            options={yearsArray}
            label="Year"
            isSlug={true}
          />
        )}

      {(index === 0 ||
        index === 1 ||
        index === 2 ||
        index === 3 ||
        index === 5 ||
        index === 4) && (
        <>
          <div className={EventCss.date}>Date Range</div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                sx={datePickerStyle}
                slotProps={{
                  textField: { size: "small" },
                  layout: {
                    sx: pickerStyle,
                  },
                }}
                label="Start date"
                value={startDate ? dayjs(startDate) : null}
                onChange={handleStartDate}
              />
            </LocalizationProvider>
            <div className={EventCss.to}>to</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                sx={datePickerStyle}
                slotProps={{
                  textField: { size: "small" },
                  layout: {
                    sx: pickerStyle,
                  },
                }}
                label="End date"
                value={endDate ? dayjs(endDate) : null}
                onChange={handleEndDate}
              />
            </LocalizationProvider>
          </div>
        </>
      )}

      <div className={EventCss.buttonsDiv}>
        <Button
          size={"small"}
          label="Reset"
          backgroundColor={"transparent"}
          color={"#ff0000"}
          onClick={() => handleReset()}
          styles={{ fontWeight: "600", fontSize: FontSizes.text }}
        />
        <Button size={"small"} onClick={() => handleSearch()} label="Filter" />
      </div>
    </div>
  );
}
