import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import Card from "../../components/card/Card";
import ArtistList from "../../elements/Flatlist/ArtistList";
import GenreList from "../../elements/Flatlist/GenreList";
import MusicCss from "./MusicHome.module.css";
import { Context } from "../../Context";
import { useNavigate } from "react-router";
import AlbumsList from "../../elements/Flatlist/AlbumsList";
import ViewMore from "../../elements/view-more/ViewMore";
import TrendingList from "../../components/trending-list/TrendingList";
import { CardSizes } from "../../config";
import { Skeleton } from "@mui/material";
import ArtistAndDjCardLoader from "../../elements/skeletonLoaders/ArtistAndDjCardLoader";
import MusicByCountryCard from "../../elements/skeletonLoaders/MusicByCountryCard";
import GenreCardLoader from "../../elements/skeletonLoaders/GenreCardLoader";
import MetaTags from "../../MetaTags";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader";
import { selectMusic, setMusic } from "../../slices/MusicReducer";
import { connect, useDispatch, useSelector } from "react-redux";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import YearList from "../../elements/Flatlist/YearList.js";
import NoData from "../../elements/no-data/NoData.js";
import SlugConverter from "../../elements/slug-converter/SlugConverter.js";
import PlaylistList from "../../elements/Flatlist/PlaylistList.js";
import { useLoggedInState } from "../../elements/logged-in-state/useLoggedInState.js";
import ReactGA from "react-ga4";

const trendingArgs = {
  gradientColours: [],
  borderColour: "white",
  listName: "Top Songs All Time",
  artistColour: "#CCCCCC",
  songNameColour: "White",
  playIconColour: "#D60017",
  numberColour: "White",
};

const trendingArgs1 = {
  gradientColours: ["#EE7E8E", "#EEC2CA"],
  borderColour: "#000",
  listName: "Top Songs- Most Liked",
  artistColour: "#fff",
  songNameColour: "#FF0000",
  playIconColour: "#D60017",
  numberColour: "#fff",
};

function MusicHome({ user }) {
  const [isLoading, setIsLoading] = useState(true);
  const [trendingListIsLoading, setTrendingListIsLoading] = useState(true);
  const [trendingListAllIsLoading, setTrendingListAllIsLoading] =
    useState(true);
  const { setPageTitle } = useContext(Context);
  const [latestReleases, setLatestReleases] = useState(null);
  const [latestReleasesIsLoading, setLatestReleasesIsLoading] = useState(true);
  const [trendingList, setTrendingList] = useState(null);
  const [trendingListAllTime, setTrendingListAllTime] = useState(null);
  const [allAlbums, setAllAlbums] = useState(null);
  const [allAlbumsIsLoading, setAllAlbumsIsLoading] = useState(true);
  const [genres, setGenres] = useState(null);
  const [genresIsLoading, setGenresIsLoading] = useState(true);
  const [artistNameLists, setArtistNameLists] = useState(null);
  const [featuredPlaylists, setFeaturedPlaylists] = useState(null);
  const [featuredPlaylistsIsLoading, setFeaturedPlaylistsIsLoading] =
    useState(true);
  const [countryList, setCountryList] = useState([]);
  const [countryListIsLoading, setCountryListIsLoading] = useState(true);
  const [years, setYears] = useState([]);
  const [yearsPageNumber, setYearsPageNumber] = useState(1);
  const [yearsLastPage, setYearsLastPage] = useState(null);
  const [countryPageNumber, setCountryPageNumber] = useState(1);
  const [countryLastPage, setCountryLastPage] = useState(null);
  const [yearsIsLoading, setYearsIsLoading] = useState(true);
  const [moreYearsIsLoading, setMoreYearsIsLoading] = useState(false);
  const [moreCountryIsLoading, setMoreCountryIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const currentlyPlaying = useSelector(selectMusic)[0];
  const navigate = useNavigate();
  const isLoggedIn = useLoggedInState();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/music", title: "Music Home" });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setPageTitle("Music");
  }, []);

  useEffect(() => {
    handleGetArtists();
    handleGetGenres();
    handleFeaturedPlaylists();
    handleGetNewReleases();
    handleAllAlbums();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (user?.token) {
        handleGetTrending();
        handleGetTrendingAll();
      }
    } else {
      handleGetTrending();
      handleGetTrendingAll();
    }
  }, [user, isLoggedIn]);

  const handleGetTrending = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_TRENDING_THIS_WEEK, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user.token}` }),
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.length !== 0) {
          setTrendingList(res?.data);
        }
        setTrendingListIsLoading(false);
      }
    } catch (err) {
      setTrendingListIsLoading(false);
    }
  };

  const handleGetTrendingAll = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_TRENDING_ALL_TIME, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.length !== 0) {
          setTrendingListAllTime(res?.data);
        }
        setTrendingListAllIsLoading(false);
      }
    } catch (err) {
      setTrendingListAllIsLoading(false);
    }
  };

  const handleGetNewReleases = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_NEW_RELEASES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setLatestReleases(res?.data?.data);
        }
        setLatestReleasesIsLoading(false);
      }
    } catch (err) {
      setLatestReleasesIsLoading(false);
    }
  };

  const handleAllAlbums = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ALL_ALBUMS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setAllAlbums(res?.data?.data);
        }
        setAllAlbumsIsLoading(false);
      }
    } catch (err) {
      setAllAlbumsIsLoading(false);
    }
  };

  const handleFeaturedPlaylists = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_FEATURED_PLAYLIST, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setFeaturedPlaylists(res?.data?.data);
        }
        setFeaturedPlaylistsIsLoading(false);
      }
    } catch (err) {
      setFeaturedPlaylistsIsLoading(false);
    }
  };

  const handleGetYears = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_YEARS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: yearsPageNumber,
          per_page: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
        },
      });
      if (res.status === 200) {
        setYearsLastPage(res?.data?.last_page);
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          if (years?.length === 0) {
            setYears(res?.data?.data);
          } else {
            setYears((prev) => [...prev, ...res?.data?.data]);
          }
        }
        if (moreYearsIsLoading) {
          setMoreYearsIsLoading(false);
        }
        setYearsIsLoading(false);
      }
    } catch (err) {
      if (moreYearsIsLoading) {
        setMoreYearsIsLoading(false);
      }
      setYearsIsLoading(false);
    }
  };

  const handleGetCountry = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_COUNTRY, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: countryPageNumber,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
        },
      });
      if (res.status === 200) {
        setCountryLastPage(res?.data?.last_page);
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          if (countryList?.length === 0) {
            setCountryList(res?.data?.data);
          } else {
            setCountryList((prev) => [...prev, ...res?.data?.data]);
          }
        }
        if (moreCountryIsLoading) {
          setMoreCountryIsLoading(false);
        }
        setCountryListIsLoading(false);
      }
    } catch (err) {
      if (moreCountryIsLoading) {
        setMoreCountryIsLoading(false);
      }
      setCountryListIsLoading(false);
    }
  };

  const handleGetGenres = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_GENRES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setGenres(res?.data?.data);
        }
        setGenresIsLoading(false);
      }
    } catch (err) {
      setGenresIsLoading(false);
    }
  };

  const handleGetArtists = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ARTISTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.MUSIC_ARTISTS,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setArtistNameLists(res?.data?.data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  function updateYearsPageNumber() {
    if (yearsLastPage != null && yearsPageNumber <= yearsLastPage) {
      setMoreYearsIsLoading(true);
      setYearsPageNumber(yearsPageNumber + 1);
    }
  }

  function updateCountryPageNumber() {
    if (countryLastPage != null && countryPageNumber <= countryLastPage) {
      setMoreCountryIsLoading(true);
      setCountryPageNumber(countryPageNumber + 1);
    }
  }

  useEffect(() => {
    handleGetYears();
  }, [yearsPageNumber]);

  useEffect(() => {
    handleGetCountry();
  }, [countryPageNumber]);

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const StreamMusic = async (id) => {
    try {
      const res = await axios.get(`${SERVER_URL.STREAM_MUSIC}${id}`, {
        headers: {
          Accept: "application/json",
        },
      });
    } catch (err) {}
  };

  const handlePlay = (item) => {
    const genre = item?.album?.genres?.map(
      (i, index) =>
        `${i?.title}${index !== item?.album?.genres?.length - 1 ? ", " : ""}`
    );
    const d = {
      isPlaying: true,
      musicArgs: {
        id: item?.id,
        title: item?.title,
        subTitle: item?.album?.title,
        hlsSource: item?.media_url,
        thumbnail: item?.album?.cover_image_url,
        footerText: genre[0],
        duration: item?.duration,
        isLiked: item?.is_liked,
        artist_id: item?.album?.artists[0]?.id,
      },
    };
    StreamMusic(item?.id);
    dispatch(setMusic(d));
  };

  const handlePause = () => {
    const d = {
      isPlaying: false,
    };
    dispatch(setMusic(d));
  };

  return (
    <>
      <NavContainer>
        <MetaTags
          author={"Trini Jungle Juice"}
          title={"Music"}
          url={window.location.href}
          type={"website"}
          image="../../assets/images/tjj-logo.jpg "
        />

        {trendingListAllIsLoading && trendingListIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={width > 431 ? "30%" : "40%"}
            height={50}
          />
        ) : (
          trendingList &&
          trendingListAllTime && <Label label={"Trending"} size="md" />
        )}

        <div className={MusicCss.flexDr}>
          {trendingListIsLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", borderRadius: 10 }}
              animation="wave"
              variant="rectangular"
              width={width > 431 ? "49%" : "100%"}
              height={550}
            />
          ) : (
            trendingList && (
              <TrendingList
                tracksList={trendingList}
                {...trendingArgs1}
                width={CardSizes.lg}
                onPlayClick={handlePlay}
                currentlyPlaying={currentlyPlaying}
                handlePause={handlePause}
              />
            )
          )}
          {trendingListAllIsLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", borderRadius: 10 }}
              animation="wave"
              variant="rectangular"
              width={width > 431 ? "49%" : "100%"}
              height={550}
            />
          ) : (
            trendingListAllTime && (
              <TrendingList
                tracksList={trendingListAllTime}
                {...trendingArgs}
                width={CardSizes.lg}
                onPlayClick={handlePlay}
                currentlyPlaying={currentlyPlaying}
                handlePause={handlePause}
              />
            )
          )}
        </div>

        <div className={MusicCss.mgb} />

        {latestReleasesIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={width > 431 ? "30%" : "40%"}
            height={50}
          />
        ) : (
          latestReleases && <Label label={"New Releases"} size="md" />
        )}
        {latestReleasesIsLoading ? (
          <>
            <div className={MusicCss.albumsDiv}>
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className={MusicCss.albumsSubDiv}>
                  <MusicCardLoader music={true} />
                </div>
              ))}
            </div>
            <div className={MusicCss.mgb} />
          </>
        ) : (
          latestReleases && (
            <>
              <div className={MusicCss.albumsDiv}>
                <AlbumsList albums={latestReleases} />
              </div>
              <ViewMore onClick={() => handleClick("/music/albums/all")} />
            </>
          )
        )}

        {allAlbumsIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={width > 431 ? "30%" : "40%"}
            height={50}
          />
        ) : (
          allAlbums && <Label label={"All Albums/Singles"} size="md" />
        )}
        {allAlbumsIsLoading ? (
          <>
            <div className={MusicCss.albumsDiv}>
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className={MusicCss.albumsSubDiv}>
                  <MusicCardLoader />
                </div>
              ))}
            </div>
            <div className={MusicCss.mgb} />
          </>
        ) : (
          allAlbums && (
            <>
              <div className={MusicCss.albumsDiv}>
                <AlbumsList albums={allAlbums} />
              </div>
              <ViewMore onClick={() => handleClick("/music/albums/all")} />
            </>
          )
        )}

        {featuredPlaylistsIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={width > 431 ? "30%" : "40%"}
            height={50}
          />
        ) : (
          featuredPlaylists && <Label label={"Featured Playlists"} size="md" />
        )}
        {featuredPlaylistsIsLoading ? (
          <>
            <div className={MusicCss.albumsDiv}>
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className={MusicCss.albumsSubDiv}>
                  <MusicCardLoader music={true} />
                </div>
              ))}
            </div>
            <div className={MusicCss.mgb} />
          </>
        ) : (
          featuredPlaylists && (
            <>
              <div className={MusicCss.albumsDiv}>
                <PlaylistList playlist={featuredPlaylists} />
              </div>
              <ViewMore onClick={() => handleClick("/music/playlist")} />
            </>
          )
        )}

        {countryListIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={width > 431 ? "30%" : "40%"}
            height={50}
          />
        ) : (
          countryList?.length !== 0 && (
            <Label label={"Music by Country"} size="md" />
          )
        )}
        {countryListIsLoading ? (
          <>
            <div className={MusicCss.flexDr}>
              {Array.from({ length: 8 }, (_, index) => (
                <MusicByCountryCard key={index} />
              ))}
            </div>
            <div className={MusicCss.mgb40} />
          </>
        ) : (
          countryList?.length !== 0 && (
            <>
              <div className={MusicCss.flexDr}>
                {countryList.map((item, index) => (
                  <Card
                    onClick={() =>
                      handleClick(
                        `/music/albums/all?countries=${SlugConverter(item?.country)}`
                      )
                    }
                    key={index}
                    title={item?.country}
                    imageSrc={item.flag}
                    textAlign={"center"}
                    size={"sm"}
                    imageProps={{ height: width < 431 ? "300px" : "200px" }}
                  />
                ))}
              </div>
              {moreCountryIsLoading ? (
                <div className={MusicCss.flexDr}>
                  {Array.from({ length: 8 }, (_, index) => (
                    <MusicByCountryCard key={index} />
                  ))}
                </div>
              ) : countryLastPage != null &&
                countryPageNumber < countryLastPage ? (
                <ViewMore loadMore onClick={updateCountryPageNumber} />
              ) : (
                <div className={MusicCss.mgb40} />
              )}
            </>
          )
        )}

        {yearsIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={width > 431 ? "30%" : "40%"}
            height={50}
          />
        ) : (
          years?.length !== 0 && <Label label={"Songs by Year"} size="md" />
        )}

        {yearsIsLoading ? (
          <>
            <div className={MusicCss.flexDr}>
              {Array.from({ length: 4 }, (_, index) => {
                return <GenreCardLoader key={index} />;
              })}
            </div>
            <div className={MusicCss.mgb} />
          </>
        ) : (
          years?.length !== 0 && (
            <>
              <div className={MusicCss.songListDiv}>
                <YearList years={years} />
              </div>
              {moreYearsIsLoading ? (
                <div className={MusicCss.flexDr}>
                  {Array.from({ length: 8 }, (_, index) => (
                    <MusicByCountryCard key={index} />
                  ))}
                </div>
              ) : yearsLastPage != null && yearsPageNumber < yearsLastPage ? (
                <ViewMore loadMore onClick={updateYearsPageNumber} />
              ) : (
                <div className={MusicCss.mgb40} />
              )}
            </>
          )
        )}

        {genresIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={width > 431 ? "30%" : "40%"}
            height={50}
          />
        ) : (
          genres && <Label label={"Genres"} size="md" />
        )}
        {genresIsLoading ? (
          <>
            <div className={MusicCss.flexDr}>
              {Array.from({ length: 4 }, (_, index) => {
                return <GenreCardLoader key={index} />;
              })}
            </div>
            <div className={MusicCss.mgb} />
          </>
        ) : (
          genres && (
            <>
              <GenreList genres={genres} />
              <ViewMore onClick={() => handleClick("/music/genres")} />
            </>
          )
        )}

        {isLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={width > 431 ? "30%" : "40%"}
            height={50}
          />
        ) : (
          artistNameLists && <Label label={"Artists & DJs"} size="md" />
        )}
        {isLoading ? (
          <>
            <div className={MusicCss.albumsDiv}>
              {Array.from({ length: 5 }, (_, index) => {
                return (
                  <div key={index} className={MusicCss.albumsSubDiv}>
                    <ArtistAndDjCardLoader />
                  </div>
                );
              })}
            </div>
            <div className={MusicCss.mgb} />
          </>
        ) : (
          artistNameLists && (
            <>
              <div className={MusicCss.albumsDiv}>
                <ArtistList artists={artistNameLists} />
              </div>
              <ViewMore onClick={() => handleClick("/music/artists")} />
            </>
          )
        )}

        {!isLoading &&
          !trendingList &&
          !trendingListAllTime &&
          !artistNameLists &&
          !genres &&
          !featuredPlaylists &&
          !allAlbums &&
          !latestReleases &&
          countryList?.length === 0 &&
          years?.length === 0 && <NoData />}
      </NavContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MusicHome);
