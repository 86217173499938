import React, { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/captions.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Comments from "../../plugins/comments/index.d.ts";
import Likes from "../../plugins/likes/index.d.ts";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "./LightBoxGallery.css";
import Share from "../../plugins/share/index.d.ts";
import GalleryImagesLoader from "../../elements/skeletonLoaders/GalleryImagesLoader.js";

export default function LightBoxGallery({
  imageArray = [],
  imageIndex = -1,
  onChangeImageIndex = undefined,
  onClose = undefined,
  setMoreIsLoading = undefined,
  onLikePhoto = undefined,
  isLoggedIn = false,
  onButtonClick = undefined,
  onLike = undefined,
  onReply = undefined,
  getComments = undefined,
  comments = [],
  isCommentsLoading = false,
  moreCommentsLoading = false,
  showLoadMore = false,
  updatePhotoCommentPageNumber = undefined,
  totalComments = undefined,
  handleDelete = undefined,
  isDeleting = false,
  onShare = undefined,
  onDownload = undefined,
}) {
  const [index, setIndex] = React.useState(
    imageIndex === undefined ? -1 : imageIndex
  );
  const [imageArr, setImageArr] = useState([]);
  const [originalImageArr, setOriginalImageArr] = useState([]);
  const [load, setLoad] = useState(true);

  const loadImage = async (imgArr) => {
    setImageArr([]);
    const promises = imgArr.map((image) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image.photo_url;
        img.onload = () => {
          const a = {
            src: image.photo_url,
            height: img.height,
            width: img.width,
            title: image.title,
            description: image.description,
          };
          resolve(a);
        };
        img.onerror = (err) => {
          reject(err);
        };
      });
    });

    try {
      const loadedImages = await Promise.all(promises);
      setImageArr(loadedImages);
      if (imageIndex !== undefined) {
        setIndex(imageIndex);
      }
      if (load) {
        setLoad(false);
      }
      if (setMoreIsLoading) {
        setMoreIsLoading(false);
      }
    } catch (error) {
      if (load) {
        setLoad(false);
      }
    }
  };

  const loadOriginalImage = async (imageArray) => {
    setOriginalImageArr([]);
    const promises = imageArray.map((image) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image.photo_url;
        img.onload = () => {
          const a = {
            src: image.photo_url,
            height: img.height,
            width: img.width,
            title: image.title,
            description: image.description,
            id: image.id,
            likeCount: image?.like_count,
            commentCount: image?.comment_count,
            liked: image?.is_liked,
          };
          resolve(a);
        };
        img.onerror = (err) => {
          reject(err);
        };
      });
    });

    try {
      const loadedImages = await Promise.all(promises);
      setOriginalImageArr(loadedImages);
      // if (imageIndex !== undefined) {
      //   setIndex(imageIndex);
      // }
    } catch (error) {}
  };

  useEffect(() => {
    if (imageIndex !== undefined) {
      setIndex(imageIndex);
    }
  }, [imageIndex]);

  useEffect(() => {
    const loadImages = async () => {
      await loadImage(imageArray);
    };

    const loadOriginalImages = async () => {
      await loadOriginalImage(imageArray);
    };
    loadImages();
    loadOriginalImages();
  }, [imageArray]);

  return load ? (
    <GalleryImagesLoader />
  ) : (
    <>
      <PhotoAlbum
        layout="rows"
        photos={imageArr}
        targetRowHeight={200}
        onClick={({ index: current }) => {
          setIndex(current);
        }}
        spacing={2}
      />
      <Lightbox
        index={index}
        slides={originalImageArr}
        open={index >= 0}
        close={() => {
          setIndex(-1);
          // Call onClose when the lightbox is closed
        }}
        carousel={{ preload: 2 }}
        plugins={[Download, Thumbnails, Captions, Share, Comments, Likes]}
        on={{
          view: onChangeImageIndex,
          exited: onClose,
          download: onDownload,
        }} // Call onChangeImageIndex when the image is changed
        thumbnails={{
          width: "auto",
          height: 80,
          border: 0,
          gap: 1,
          position: "bottom",
        }}
        captions={{
          descriptionMaxLines: 2,
          descriptionTextAlign: "center",
        }}
        likes={{
          showToggle: true,
          isLoggedIn: isLoggedIn,
          onClick: (id) => onLikePhoto(id),
        }}
        share={{
          onShare: (label, item) => onShare(label, item),
        }}
        comments={{
          isLoggedIn: isLoggedIn,
          onButtonClick: onButtonClick,
          onLike: onLike,
          onReply: onReply,
          getComments: getComments,
          comments: comments,
          isCommentsLoading: isCommentsLoading,
          moreCommentsLoading: moreCommentsLoading,
          updatePhotoCommentPageNumber: updatePhotoCommentPageNumber,
          showLoadMore: showLoadMore,
          totalComments: totalComments,
          handleDelete: handleDelete,
          isDeleting: isDeleting,
        }}
      />
    </>
  );
}
