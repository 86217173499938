import React, { useEffect, useState } from "react";
import { FontSizes } from "../../../config";
import NewsletterCss from "../Newsletter.module.css";
import dayjs from "dayjs";
import AutoComplete from "../../../components/autocomplete/AutoComplete";
import Button from "../../../components/button/Button";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { datePickerStyle, pickerStyle } from "../../../css/DatePickerStyles";

export default function NewsletterFilter({
  onSearch,
  authors,
  categoryArray,
  tagArray,
  onReset,
  filter,
  triggerReset,
  setTriggerReset,
}) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = React.useState();
  const [author, setAuthor] = useState([]);
  const [category, setCategory] = useState([]);
  const [tags, setTags] = useState([]);
  const [categoryOptions, setCategoryOption] = useState([]);
  const [authorOptions, setAuthorOption] = useState([]);
  const [tagOptions, setTagOption] = useState([]);

  function handleReset() {
    setEndDate(null);
    setStartDate(null);
    setAuthor([]);
    setCategory([]);
    setTags([]);
    onReset();
    if (triggerReset) {
      setTriggerReset(false);
    }
  }

  useEffect(() => {
    if (triggerReset) {
      handleReset();
    }
  }, [triggerReset]);

  useEffect(() => {
    const op = categoryArray?.map((item) => ({
      id: item.id,
      name: item.title,
      slug: item.slug,
    }));
    if (op) {
      setCategoryOption(op);
    }
  }, [categoryArray]);

  useEffect(() => {
    const op = tagArray?.map((item) => ({
      id: item.id,
      name: item.slug,
      slug: item.slug,
    }));
    if (op) {
      setTagOption(op);
    }
  }, [tagArray]);

  useEffect(() => {
    const op = authors?.map((item) => ({
      id: item.id,
      name: item.first_name + " " + item.last_name,
      slug: item.slug,
    }));
    if (op) {
      setAuthorOption(op);
    }
  }, [authors]);

  useEffect(() => {
    if (filter) {
      if (filter?.tag?.length !== 0) {
        const filtered = filter?.tag?.filter((item) => !tags.includes(item));
        if (filtered && filtered?.length !== 0) {
          setTags(filtered);
        }
      }
      if (filter?.category?.length !== 0) {
        const filtered = filter?.category?.filter(
          (item) => !category.includes(item)
        );
        if (filtered && filtered?.length !== 0) {
          setCategory(filtered);
        }
      }
      if (filter?.author?.length !== 0) {
        const filtered = filter?.author?.filter(
          (item) => !author.includes(item)
        );
        if (filtered && filtered?.length !== 0) {
          setAuthor(filtered);
        }
      }
      if (filter?.startDate) {
        if (filter?.startDate !== startDate) {
          setStartDate(filter?.startDate);
        }
      }
      if (filter?.endDate) {
        if (filter?.endDate !== endDate) {
          setEndDate(filter?.endDate);
        }
      }
    }
  }, [filter]);

  function handleSearch() {
    onReset();
    let params = {};
    if (category.length !== 0) {
      params = {
        ...params,
        category: category,
      };
    }
    if (author.length !== 0) {
      params = {
        ...params,
        author: author,
      };
    }
    if (tags?.length !== 0) {
      params = {
        ...params,
        tag: tags,
      };
    }
    if (startDate) {
      params = {
        ...params,
        startDate: startDate,
      };
    }
    if (endDate) {
      params = {
        ...params,
        endDate: endDate,
      };
    }
    onSearch(params);
  }

  const handleStartDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setStartDate(d);
  };

  const handleEndDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setEndDate(d);
  };

  return (
    <div className={NewsletterCss.filter}>
      <div className={NewsletterCss.calendarHead}>Filters</div>
      {categoryOptions?.length > 0 && (
        <AutoComplete
          defaultValue={category}
          handleOnChange={(val) => setCategory(val)}
          options={categoryOptions}
          label="Category"
          isSlug={true}
        />
      )}
      {authorOptions?.length > 0 && (
        <AutoComplete
          defaultValue={author}
          handleOnChange={(val) => setAuthor(val)}
          options={authorOptions}
          label="Authors"
          isSlug={true}
        />
      )}
      {tagOptions?.length > 0 && (
        <AutoComplete
          defaultValue={tags}
          handleOnChange={(val) => setTags(val)}
          options={tagOptions}
          label="Tags"
          isSlug={true}
        />
      )}
      <div>
        <div className={NewsletterCss.date}>Date Range</div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              sx={datePickerStyle}
              slotProps={{
                textField: { size: "small" },
                layout: {
                  sx: pickerStyle,
                },
              }}
              label="Start date"
              value={startDate ? dayjs(startDate) : null}
              onChange={(newValue) => handleStartDate(newValue)}
            />
          </LocalizationProvider>
          <div className={NewsletterCss.to}>to</div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              sx={datePickerStyle}
              slotProps={{
                textField: { size: "small" },
                layout: {
                  sx: pickerStyle,
                },
              }}
              label="End date"
              value={endDate ? dayjs(endDate) : null}
              onChange={(newValue) => handleEndDate(newValue)}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className={NewsletterCss.buttonsDiv}>
        <Button
          size={"small"}
          label="Reset"
          backgroundColor={"transparent"}
          color={"#ff0000"}
          styles={{ fontWeight: "600", fontSize: FontSizes.text }}
          onClick={() => handleReset()}
        />
        <Button onClick={() => handleSearch()} size={"small"} label="Search" />
      </div>
    </div>
  );
}
