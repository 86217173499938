import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import { Grid, Skeleton } from "@mui/material";
import Label from "../../elements/label/Label";
import ReviewCss from "./Review.module.css";
import Chip from "../../components/chip/Chip";
import ReviewSlides from "./elements/ReviewSlides";
import { Context } from "../../Context";
import { useNavigate, useLocation } from "react-router";
import CommentList from "../../components/comment/CommentList";
import { RelatedNewsCard } from "../newsletters/Common";
import SlugConverter from "../../elements/slug-converter/SlugConverter";
import ChipsLoader from "../../elements/skeletonLoaders/ChipsLoader";
import MiniNewsCard from "../../elements/skeletonLoaders/MiniNewsCard";
import CommentLoader from "../../elements/skeletonLoaders/CommentLoader";
import ReviewsCardSingleTextLoader from "../../elements/skeletonLoaders/ReviewsCardSingleTextLoader";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { connect } from "react-redux";
import ViewMore from "../../elements/view-more/ViewMore.js";
import { ExtractShortDate } from "../../elements/date-extractor/ExtractDate.js";
import NoDataScreen from "../no-data/NoDataScreen.js";
import Swal from "sweetalert2";
import ReactGA from "react-ga4";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip.js";
import DynamicContentInjector from "../../elements/DynamicContentInjector/DynamicContentInjector.js";
import { insertInterstitialAds } from "../../elements/InsertInterstitialAds/InsertInterstitialAds.js";

function ReviewDetail({ isLoggedIn, user }) {
  const [width, setWidth] = useState(window.innerWidth);
  const { setPageTitle } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [popularReviews, setPopularReviews] = useState(null);
  const [relatedReviews, setRelatedReviews] = useState(null);
  const [recentReviews, setRecentReviews] = useState(null);
  const [commentsData, setCommentsData] = useState([]);
  const [slugUrl, setSlugUrl] = useState(null);
  const navigate = useNavigate();
  const [commentsPage, setCommentsPage] = useState(1);
  const [commentsIsLoading, setCommentsIsLoading] = useState(true);
  const [commentsLastPage, setCommentsLastPage] = useState(null);
  const [moreCommentsIsLoading, setMoreCommentsIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [url, setUrl] = useState(null);
  const [details, setDetails] = useState(null);
  const [popularReviewsIsLoading, setPopularReviewsIsLoading] = useState(true);
  const [relatedReviewsIsLoading, setRelatedReviewsIsLoading] = useState(true);
  const [recentReviewsIsLoading, setRecentReviewsIsLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [isDeletingComment, setIsDeletingComment] = useState(false);
  const { pathname } = useLocation();
  const [bannerAd, setBannerAd] = useState(null);
  const [bannerAd2, setBannerAd2] = useState(null);
  const [interstitialAd, setInterstitialAd] = useState([]);
  const [interstitialAdIsLoading, setInterstitialAdIsLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [contentIsLoading, setContentIsLoading] = useState(null);

  const handleGetSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "sidebar ad square",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd(res.data);
        }
      }
    } catch (err) {
      setBannerAd(null);
    }
  };

  const handleGetSecondSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "skyscraper ad",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd2(res.data);
        }
      }
    } catch (err) {
      setBannerAd2(null);
    }
  };

  const handleGetInterstitialBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "interstitial ad",
          is_array: true,
        },
      });
      if (res.status === 200) {
        setInterstitialAdIsLoading(false);
        if (res.data) {
          setInterstitialAd(res.data);
        }
      }
    } catch (err) {
      setInterstitialAd([]);
      setInterstitialAdIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetSidebarBanner();
    handleGetSecondSidebarBanner();
    handleGetInterstitialBanner();
  }, []);

  useEffect(() => {
    if (details?.content && !interstitialAdIsLoading) {
      const { updatedContent, isLoading } = insertInterstitialAds(
        details?.content,
        interstitialAd
      );
      if (!isLoading) {
        setContentIsLoading(false);
        setContent(updatedContent);
      }
    }
  }, [details?.content, interstitialAdIsLoading]);

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      setSlugUrl(`${SERVER_URL.GET_REVIEW_DETAIL}${u}`);
      ReactGA.send({
        hitType: "pageview",
        page: `/reviews/${u}`,
        title: "Reviews Home",
      });
    }
  }, [pathname]);

  useEffect(() => {
    if (slugUrl) {
      GetReviewDetail();
    }
  }, [slugUrl]);

  useEffect(() => {
    if (details) {
      setUrl(`${SERVER_URL.GET_ALL_REVIEWS}/${details?.id}`);
      handleGetRelated();
    }
  }, [details]);

  const GetReviewDetail = async () => {
    try {
      const res = await axios.get(slugUrl, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
      });
      setStatus(res?.status);
      if (res.status === 200) {
        if (res?.data && res?.data?.review) {
          setDetails(res?.data?.review);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setStatus(err.response.status);
    }
  };

  const handleGetComments = async () => {
    try {
      const res = await axios.get(url + "/comments", {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
        params: {
          page: commentsPage,
          items: PER_PAGE_COUNT.COMMENTS_PER_PAGE,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.comments) {
          if (res?.data?.comments?.data) {
            if (commentsPage === 1) {
              setCommentsData(res?.data?.comments?.data);
            } else {
              setCommentsData([...commentsData, ...res?.data?.comments?.data]);
            }
          }
          setCommentsLastPage(res?.data?.comments?.last_page);
        }
        if (moreCommentsIsLoading) {
          setMoreCommentsIsLoading(false);
        }
        if (isDeletingComment) {
          setIsDeletingComment(false);
        }
        setCommentsIsLoading(false);
      }
    } catch (err) {
      setCommentsIsLoading(false);
      if (moreCommentsIsLoading) {
        setMoreCommentsIsLoading(false);
      }
    }
  };

  const handleLikeComment = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.LIKE_REVIEW_COMMENT}${id}/like`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Comments",
          action: `Liked a comment`,
          label: details?.title,
        });
        ReactGA.event({
          category: "Comments",
          action: `Liked a comment on ${details?.title}`,
          label: details?.title,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to like comment",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
    }
  };

  const handleGetPopular = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_REVIEWS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.REVIEWS_ITEM_COUNT,
          orderBy: "view_count",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.reviews && res?.data?.reviews?.data) {
          setPopularReviews(res?.data?.reviews?.data);
        }
        setPopularReviewsIsLoading(false);
      }
    } catch (err) {
      setPopularReviewsIsLoading(false);
    }
  };

  const handleGetRecent = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_REVIEWS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.REVIEWS_ITEM_COUNT,
          orderBy: "created_at",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.reviews && res?.data?.reviews?.data) {
          setRecentReviews(res?.data?.reviews?.data);
        }
        setRecentReviewsIsLoading(false);
      }
    } catch (err) {
      setRecentReviewsIsLoading(false);
    }
  };

  const handleGetRelated = async () => {
    let params = {
      page: 1,
      items: PER_PAGE_COUNT.REVIEWS_ITEM_COUNT,
      orderDirection: "desc",
    };
    if (details?.author)
      params = {
        ...params,
        author: [details?.author?.slug],
      };
    if (details?.categories?.length !== 0)
      params = {
        ...params,
        categories: [details?.categories[0]?.slug],
      };
    if (details?.tags?.length !== 0)
      params = {
        ...params,
        tags: [details?.tags[0]?.slug],
      };
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_REVIEWS, {
        headers: {
          Accept: "application/json",
        },
        params: params,
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.reviews && res?.data?.reviews?.data) {
          setRelatedReviews(res?.data?.reviews?.data);
        }
        setRelatedReviewsIsLoading(false);
      }
    } catch (err) {
      setRelatedReviewsIsLoading(false);
    }
  };

  function updateCommentPageNumber() {
    if (commentsLastPage != null && commentsPage <= commentsLastPage) {
      setMoreCommentsIsLoading(true);
      setCommentsPage(commentsPage + 1);
    }
  }

  useEffect(() => {
    if (url) {
      handleGetComments();
    }
  }, [commentsPage, url]);

  const handlePostComment = (value) => {
    const body = {
      comment: value,
      ...(id && { parent_comment_id: id }),
    };
    axios
      .post(url + `/comment`, body, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (id) {
          ReactGA.event({
            category: "Comments",
            action: `Reply on a comment`,
            label: details?.title,
          });
          ReactGA.event({
            category: "Comments",
            action: `Reply on a comment on ${details?.title}`,
            label: details?.title,
          });
        } else {
          ReactGA.event({
            category: "Comments",
            action: `New Comment Posted`,
            label: details?.title,
          });
          ReactGA.event({
            category: "Comments",
            action: `New Comment Posted on ${details?.title}`,
            label: details?.title,
          });
        }
        setId(null);
        if (commentsPage === 1) {
          handleGetComments();
        } else {
          setCommentsPage(1);
        }
      });
  };

  const onReply = (id) => {
    setId(id);
  };

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    if (details) {
      setPageTitle(details?.title);
    } else {
      setPageTitle("Review Details Page");
    }
  }, [details]);

  const handleNavigate = (param) => {
    const params = param?.tag
      ? `tag=${SlugConverter(param.tag)}`
      : param?.author
        ? `author=${SlugConverter(param.author)}`
        : `category=${SlugConverter(param.category)}`;
    const route = "/reviews?" + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleGetPopular();
    handleGetRecent();
  }, []);

  const handleDeleteComment = (value) => {
    setIsDeletingComment(true);
    axios
      .get(`${SERVER_URL.DELETE_COMMENT}${value}/delete`, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then(() => {
        ReactGA.event({
          category: "Comments",
          action: `Comment Deleted`,
          label: details?.title,
        });
        ReactGA.event({
          category: "Comments",
          action: `Comment Deleted on ${details?.title}`,
          label: details?.title,
        });
        if (commentsPage === 1) {
          handleGetComments();
        } else {
          setCommentsPage(1);
        }
      });
  };

  function getAllCommentsLength() {
    var lengthOfChildren = 0;
    for (var i = 0; i < commentsData?.length; i++) {
      if (
        commentsData[i]?.children?.length !== 0 &&
        commentsData[i]?.parent_comment_id !== null
      ) {
        var replies = commentsData[i].children;
        lengthOfChildren += replies.length;
      }
    }
    return lengthOfChildren + commentsData?.length;
  }

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Review's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `Review ${label} Shared on ${item}`,
      label: label,
    });
  }

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <NavContainer>
      <Grid container rowGap={2}>
        <Grid item className={ReviewCss.gridGeneral} xs={12} md={9}>
          {isLoading ? (
            <>
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={"40%"}
                height={50}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={"10%"}
                height={30}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={"20%"}
                height={30}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={"20%"}
                height={20}
              />
              <ChipsLoader numberOfChips={6} />
            </>
          ) : (
            <>
              <div className={ReviewCss.flexDrT}>
                <Label size="lg" label={details?.title} />
                <SharingTooltip
                  onShare={handleShare}
                  title={details?.title}
                  link={window.location.href}
                />
              </div>
              {details?.author && (
                <div
                  className={ReviewCss.subTitle}
                  onClick={() =>
                    handleNavigate({ author: details?.author?.slug })
                  }
                >
                  By: {details?.author?.first_name} {details?.author?.last_name}
                </div>
              )}

              {details?.categories?.length !== 0 && (
                <div className={ReviewCss.categoryName}>
                  Category:{" "}
                  {details?.categories?.map((item, index) => {
                    return (
                      <span
                        key={index}
                        className={ReviewCss.category}
                        onClick={() => handleNavigate({ category: item?.slug })}
                      >
                        {item?.title}
                        {index < details?.categories?.length - 1 ? ", " : ""}
                      </span>
                    );
                  })}
                </div>
              )}

              <div className={ReviewCss.publication}>
                Published On: {ExtractShortDate(details?.created_at)}
              </div>
              {details?.tags?.length !== 0 && (
                <div className={ReviewCss.chipsArray}>
                  {details?.tags?.map((item, index) => {
                    return (
                      <Chip
                        handleClick={() => handleNavigate({ tag: item?.slug })}
                        label={item.slug}
                        key={index}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}

          <>
            {isLoading || contentIsLoading ? (
              <div className={ReviewCss.loaders}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="rectangular"
                  width={width > 431 ? 328 : 300}
                  height={400}
                />
                <div>
                  {Array.from({ length: 8 }, (_, index) => (
                    <Skeleton
                      key={index}
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={width > 431 ? 600 : 300}
                      height={30}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className={`${ReviewCss.body} ck-content`}>{content}</div>
            )}
          </>
          <div className={ReviewCss.mgb} />
          {commentsIsLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="text"
              width={width < 600 ? "100%" : 150}
              height={40}
            />
          ) : (
            <Label
              size="md"
              label={`Comments (${getAllCommentsLength()})`}
              style={{ marginBottom: 3 }}
            />
          )}
          {commentsIsLoading ? (
            <CommentLoader />
          ) : (
            <>
              <CommentList
                onLike={handleLikeComment}
                onReply={onReply}
                onButtonClick={(value) => handlePostComment(value)}
                data={commentsData}
                handleDelete={handleDeleteComment}
                isDeleting={isDeletingComment}
              />
              {moreCommentsIsLoading && <CommentLoader />}
              {commentsPage < commentsLastPage && !moreCommentsIsLoading && (
                <ViewMore
                  loadMore={true}
                  onClick={() => {
                    updateCommentPageNumber();
                  }}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          {bannerAd &&
            (bannerAd?.banner_image_url ? (
              <img
                className={ReviewCss.BannerAd}
                src={bannerAd?.banner_image_url}
              />
            ) : (
              bannerAd?.content && (
                <DynamicContentInjector
                  className={ReviewCss.BannerAd}
                  htmlContent={bannerAd?.content}
                />
              )
            ))}
          {recentReviewsIsLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 2 }}
              animation="wave"
              variant="text"
              width={width > 431 ? "30%" : "40%"}
              height={50}
            />
          ) : (
            recentReviews &&
            recentReviews?.length !== 0 && (
              <Label size="lg" label={"Recent Reviews"} />
            )
          )}
          <div className={ReviewCss.rightDiv}>
            {recentReviewsIsLoading ? (
              <>
                {Array.from({ length: 3 }, (_, index) => (
                  <MiniNewsCard key={index} />
                ))}
              </>
            ) : (
              <div className={`${ReviewCss.mgb} ${ReviewCss.rightDiv}`}>
                {recentReviews &&
                  recentReviews?.length !== 0 &&
                  recentReviews?.map((item, index) => {
                    return (
                      <RelatedNewsCard
                        key={index}
                        item={item}
                        onClick={() => handleClick(`/reviews/${item?.slug}`)}
                      />
                    );
                  })}
              </div>
            )}
          </div>

          {bannerAd2 &&
            (bannerAd2?.banner_image_url ? (
              <img
                className={ReviewCss.BannerAd}
                src={bannerAd2?.banner_image_url}
              />
            ) : (
              bannerAd2?.content && (
                <DynamicContentInjector
                  className={ReviewCss.BannerAd}
                  htmlContent={bannerAd2?.content}
                />
              )
            ))}

          {relatedReviewsIsLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 2 }}
              animation="wave"
              variant="text"
              width={width > 431 ? "30%" : "40%"}
              height={50}
            />
          ) : (
            relatedReviews &&
            relatedReviews?.length !== 0 && (
              <Label size="lg" label={"Related Reviews"} />
            )
          )}
          <div className={ReviewCss.rightDiv}>
            {relatedReviewsIsLoading ? (
              <>
                {Array.from({ length: 3 }, (_, index) => (
                  <MiniNewsCard key={index} />
                ))}
              </>
            ) : (
              relatedReviews &&
              relatedReviews?.length !== 0 &&
              relatedReviews?.map((item, index) => {
                return (
                  <RelatedNewsCard
                    key={index}
                    item={item}
                    onClick={() => handleClick(`/reviews/${item?.slug}`)}
                  />
                );
              })
            )}
          </div>
        </Grid>
      </Grid>
      <div className={ReviewCss.mgb} />

      {popularReviewsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={width > 431 ? "30%" : "40%"}
          height={50}
        />
      ) : (
        popularReviews &&
        popularReviews?.length !== 0 && (
          <Label size="md" label={"Popular Reviews"} />
        )
      )}
      {popularReviewsIsLoading ? (
        <div className={ReviewCss.flexDrR}>
          {Array.from({ length: 4 }, (_, index) => (
            <ReviewsCardSingleTextLoader key={index} />
          ))}
        </div>
      ) : (
        popularReviews &&
        popularReviews?.length !== 0 && (
          <ReviewSlides reviews={popularReviews} />
        )
      )}
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state?.auth?.user,
});

export default connect(mapStateToProps)(ReviewDetail);
