import { Skeleton } from "@mui/material";
import React from "react";

export default function MusicByCountryCardLoader({}) {
  return (
    <div>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="rectangular"
        width={270}
        height={273}
      />
      <Skeleton
        sx={{ bgcolor: "grey.900", marginTop: "5px" }}
        animation="wave"
        variant="text"
        width={190}
      />
    </div>
  );
}
