import React from "react";

function PlayerSeekBar({ songState, audioRef, setSongState }) {
  const playerSeekHandler = (e) => {
    const value = e.target.value;
    audioRef.current.currentTime = value;
    setSongState({
      ...songState,
      currentTime: value,
    });
  };

  return (
    <div className="player__seek-bar--wrapper">
      <div
        className="player__seek-bar--gradient"
        style={{
          backgroundColor: "#f5f5f5",
          width: `${(songState.currentTime / songState.totalDuration) * 100}%`,
        }}
      ></div>
      <input
        min={0}
        max={songState?.totalDuration ?? 0}
        value={songState?.currentTime ?? 0}
        onChange={playerSeekHandler}
        type="range"
        className="player__seek-bar"
      />
    </div>
  );
}

export default PlayerSeekBar;
