import { createSlice } from "@reduxjs/toolkit";

const initialState = {
carnivalLocation:"",
};

export const carnivalSlice = createSlice({
  name: "carnival",
  initialState,
  reducers: {
    setCarnivalLocation: (state, action) => {
      state.carnivalLocation = [action.payload];
    },
  },
});

export const {
  setCarnivalLocation,
} = carnivalSlice.actions;

export const selectCarnivalLocation = (state) => state.carnival.carnivalLocation;

export default carnivalSlice.reducer;
