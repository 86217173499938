import { Skeleton } from '@mui/material';
import React from 'react'
import Label from '../../../../elements/label/Label';
import ViewMore from '../../../../elements/view-more/ViewMore';
import GlobalSearchCss from "../../GlobalSearch.module.css";
import PhotoCardLoader from '../../../../elements/skeletonLoaders/PhotoCardLoader';
import CarnivalList from '../../../../elements/Flatlist/CarnivalList';

export default function Carnival({
    carnival,
    isLoading,
    length,
    showViewAll = false,
    setValue,
    moreCarnivalsIsLoading,
    carnivalsPageNumber,
    carnivalsLastPage,
    updateCarnivalsPageNumber = undefined,
  }) {
    return (
      <div>
        <div className={GlobalSearchCss.mainDiv}>
          {isLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={"40%"}
              height={50}
            />
          ) : (
            <Label label={`Carnival (${length} results)`} size="md" />
          )}
        </div>
  
        {isLoading ? (
          <div className={GlobalSearchCss.mgb}>
            <div className={GlobalSearchCss.flexDrR}>
              {Array.from({ length: 6 }).map((_, index) => (
                <PhotoCardLoader key={index} />
              ))}
            </div>
          </div>
        ) : (
          <>
            <CarnivalList gallery={carnival} />
            {showViewAll && <ViewMore onClick={() => setValue(2)} />}
            {moreCarnivalsIsLoading && (
              <div className={GlobalSearchCss.mgb} style={{ marginTop: 10 }}>
                <div className={GlobalSearchCss.flexDrR}>
                  {Array.from({ length: 6 }).map((_, index) => (
                    <PhotoCardLoader key={index} />
                  ))}
                </div>
              </div>
            )}
            {carnivalsLastPage != null &&
            carnivalsPageNumber < carnivalsLastPage &&
            !isLoading &&
            !moreCarnivalsIsLoading &&
            !showViewAll ? (
              <ViewMore loadMore onClick={updateCarnivalsPageNumber} />
            ) : (
              !showViewAll && <div className={GlobalSearchCss.mgb} />
            )}
          </>
        )}
      </div>
    );
  }
