import React from "react";
import ViewMoreStyles from "./ViewMore.module.css";

export default function ViewMore({
  onClick,
  loadMore,
  viewMoreOrFilter,
  ...props
}) {
  return (
    <div className={ViewMoreStyles.border}>
      <div onClick={onClick} className={ViewMoreStyles.text}>
        {loadMore
          ? "Load More"
          : viewMoreOrFilter
          ? "View More/Filter"
          : "View More"}{" "}
        {">>"}
      </div>
    </div>
  );
}
