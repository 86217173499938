import React from "react";
import { Skeleton } from "@mui/material";
export default function MiniNewsCard() {
  return (
    <div>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="rectangular"
        width={300}
        height={240}
      />
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        width={140}
        height={40}
        variant="text"
      />{" "}
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        width={190}
        variant="text"
      />
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        width={190}
        variant="text"
      />
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        width={190}
        variant="text"
      />
    </div>
  );
}
