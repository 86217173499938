import React from "react";
import trendingStyles from "./TrendingList.module.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useState } from "react";
import PauseIcon from "@mui/icons-material/Pause";

export default function TrendingList({
  tracksList = [],
  width = null,
  gradientColours = [],
  listName = "",
  songNameColour = "",
  artistColour = "",
  playIconColour = "",
  listNameColour = "",
  onPlayClick = undefined,
  numberColour = "",
  borderColour = "",
  styles = {},
  currentlyPlaying = {},
  handlePause = undefined,
}) {
  var number = "0";
  const [activeIndex, setActiveIndex] = useState(null);
  function handlePlayClick(item, data) {
    if (activeIndex == item) {
      setActiveIndex(null);
    } else {
      setActiveIndex(item);
    }
    onPlayClick(data);
  }

  const handlePauseClick = (item) => {
    if (activeIndex == item) {
      setActiveIndex(null);
    }
    if (handlePause) {
      handlePause();
    }
  };

  return (
    <div
      className={trendingStyles.cardParent}
      style={{
        ...styles,
        width: width < 431 ? "100%" : width ? width : "100%",
        background:
          gradientColours.length > 0
            ? `linear-gradient(180deg, ${gradientColours[0]}, ${gradientColours[1]})`
            : "transparent",
      }}
    >
      <div
        style={{
          color: listNameColour,
          paddingLeft: gradientColours.length == 0 ? "0px" : "35px",
          paddingTop: "35px",
        }}
        className={trendingStyles.listName}
      >
        {listName || "Trending List"}
      </div>

      <div
        style={{
          paddingLeft: gradientColours.length == 0 ? "0px" : "35px",
          paddingTop: "15px",
          paddingBottom: gradientColours.length == 0 ? "0px" : "35px",
          paddingRight: gradientColours.length == 0 ? "0px" : "35px",
        }}
        className={trendingStyles.trackList}
      >
        {tracksList.map((item, index) => (
          <SongListItem
            number={index < 9 ? number + (index + 1) : index + 1}
            key={item.title}
            songName={item.title}
            artist={
              item?.album?.artists?.length !== 0 &&
              item?.album?.artists[0]?.first_name +
                " " +
                item?.album?.artists[0]?.last_name
            }
            isActive={
              activeIndex === index &&
              currentlyPlaying?.musicArgs?.id === item?.id
            }
            onClick={() => handlePlayClick(index, item)}
            playIconColour={playIconColour}
            songNameColour={songNameColour}
            artistColour={artistColour}
            numberColour={numberColour}
            borderColour={borderColour}
            onPauseClick={() => handlePauseClick(index)}
          />
        ))}
      </div>
    </div>
  );
}

function SongListItem({
  songName,
  artist,
  number,
  isActive,
  onClick,
  playIconColour,
  songNameColour,
  artistColour,
  numberColour,
  borderColour,
  onPauseClick,
}) {
  return (
    <div
      className={trendingStyles.songListItemParent}
      style={{ borderColor: borderColour }}
    >
      <div
        className={trendingStyles.songListItemNumber}
        style={{ color: numberColour }}
      >
        {number || 1}
      </div>
      <div className={trendingStyles.songListItemContainer}>
        <div
          style={{ color: songNameColour }}
          className={trendingStyles.songListItemText}
        >
          {songName || "Song Name"}
        </div>
        <div
          style={{ color: artistColour }}
          className={trendingStyles.songListItemSubText}
        >
          {artist}
        </div>
      </div>

      {isActive ? (
        <PauseIcon
          onClick={() => {
            onPauseClick();
          }}
          className={trendingStyles.playIcon}
          style={{ color: playIconColour }}
        />
      ) : (
        <PlayArrowIcon
          onClick={() => {
            onClick();
          }}
          className={trendingStyles.playIcon}
          style={{ color: playIconColour }}
        />
      )}
    </div>
  );
}
