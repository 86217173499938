import { Breadcrumbs, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import BreadCrumbsCss from "./BreadCrumbs.module.css";
import { connect } from "react-redux";

const BreadCrumbs = ({ path, title = null, auth }) => {
  const [paths, setPaths] = useState([
    <Link
      underline="hover"
      key={"Home"}
      color={"#7C7979"}
      href={"/"}
      className={BreadCrumbsCss.links}
    >
      Home
    </Link>,
  ]);

  useEffect(() => {
    setPaths([
      <Link
        underline="hover"
        key={"Home"}
        color={"#7C7979"}
        href={"/"}
        className={BreadCrumbsCss.links}
      >
        Home
      </Link>,
    ]);
    const names = path.split("/");
    const toMinus = names[names.length - 1] === auth?.user?.slug ? 2 : 1;
    names.map((item, index) => {
      if (
        item !== "" &&
        index < names.length - toMinus &&
        item !== auth?.user?.slug &&
        item !== "view" &&
        item !== "pages"
      ) {
        setPaths((prev) => [
          ...prev,
          <Link
            underline="hover"
            key={getPath(item, names[index - 1]).name}
            color="#7C7979"
            href={getPath(item, names[index - 1]).link}
            className={BreadCrumbsCss.links}
          >
            {getPath(item).name}
          </Link>,
        ]);
      }
    });
    if (title === "No Data") {
      setPaths([
        <Link
          underline="hover"
          key={"Home"}
          color={"#7C7979"}
          href={"/"}
          className={BreadCrumbsCss.links}
        >
          Home
        </Link>,
      ]);
    }
    if (title && title !== "No Data") {
      setPaths((prev) => [
        ...prev,
        <Typography key={title} color="#fff" className={BreadCrumbsCss.links}>
          {title}
        </Typography>,
      ]);
    }
  }, [path, title]);

  function getPath(item, prev) {
    var route;
    switch (item) {
      case "all":
        if (prev === "albums") {
          route = {
            name: "All Albums",
            link: "/music/albums/all",
          };
        } else {
          route = {
            name: item,
            link: "/videos/all",
          };
        }
        break;
      case "my-itinerary":
        route = {
          name: "My Itinerary",
          link: `/my-itinerary/${auth?.user?.slug}`,
        };
        break;
      case "my-songs-playlist":
        route = {
          name: "My Songs Playlist",
          link: `/my-songs-playlist/${auth?.user?.slug}`,
        };
        break;
      case "my-video-playlist":
        route = {
          name: "My Videos Playlist",
          link: `/my-video-playlist/${auth?.user?.slug}`,
        };
        break;
      case "classifieds":
        route = {
          name: "Carnival Classifieds",
          link: "/carnival/classifieds",
        };
        break;
      case "inbox":
        route = {
          name: item,
          link: `/carnival/classifieds/${auth?.user?.slug}/inbox`,
        };
        break;
      case "albums":
        route = {
          name: item,
          link: "/music/albums",
        };
        break;
      case "playlist":
        route = {
          name: item,
          link: "/music/playlist",
        };
        break;
      case "genre":
        route = {
          name: item,
          link: "/music/genre",
        };
        break;
      case "artists":
        route = {
          name: item,
          link: "/music/artists",
        };
        break;
      default:
        route = {
          name: item.replaceAll("-", " "),
          link: `/${item}`,
        };
        break;
    }

    return route;
  }

  return (
    <Breadcrumbs
      separator={
        <MdKeyboardDoubleArrowRight className={BreadCrumbsCss.arrowRight} />
      }
      aria-label="breadcrumb"
    >
      {paths.length > 1 && paths}
    </Breadcrumbs>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(BreadCrumbs);
