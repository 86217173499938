import { Grid } from "@mui/material";
import React from "react";
import EventCard from "../../../components/event-card/EventCard";
import { useNavigate } from "react-router";
import { IoMdClose } from "react-icons/io";
import AccountCss from "../AccountsCss.module.css";
import { LocationExtractor } from "../../../elements/location-extractor/LocationExtractor";
import { EventTimeExtractor } from "../../../elements/time-extractor/TimeExtractor";
import { EventDateExtractor } from "../../../elements/date-extractor/ExtractDate";
import Swal from "sweetalert2";
import ReactGA from "react-ga4";

export default function EventList({ events, isLoggedIn, handleOnRemove, showButton }) {
  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  const handleNavigate = (param) => {
    const params = `tag=${param.tag}`;
    const route = "/events?" + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Event's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `${label} Shared on ${item}`,
      label: label,
    });
  }

  function handleCalendarClick(label) {
    ReactGA.event({
      category: "Calendar",
      action: "Event added to calendar",
      label: label,
    });
    ReactGA.event({
      category: "Calendar",
      action: `${label} added to calendar`,
      label: label,
    });
  }

  const buttonArgs = {
    backgroundColor: "#ff0000",
    borderColor: null,
    color: null,
    hoverColor: null,
    size: "medium",
    label: "Remove",
    iconLeft: true,
    iconRight: false,
    variant: "contained",
    showAnimation: false,
    styles: {},
    icon: <IoMdClose className={AccountCss.closeIcon} />,
    disabled: false,
    borderRadius: 0,
  };

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={2}>
        {events?.map((item, index) => {
          const location = LocationExtractor(item?.location);
          const startTime = EventTimeExtractor(item?.start_datetime);
          const endTime = EventTimeExtractor(item?.end_datetime);
          const startDate = EventDateExtractor(item?.start_datetime);
          const endDate = EventDateExtractor(item?.end_datetime);
          return (
            <Grid key={index} item xs={12} sm={12} md={6}>
              <EventCard
                eventName={item?.title}
                imageSrc={item?.poster_url}
                carnivalName={item?.carnival?.title}
                onShare={handleShare}
                onCalendarClick={handleCalendarClick}
                shortAddress={location}
                startTime={startTime}
                endTime={endTime}
                startDate={startDate}
                endDate={endDate}
                startDateTime={item?.start_datetime}
                endDateTime={item?.end_datetime}
                timezone={item?.location?.timezone}
                description={item?.description}
                tag={item?.tags}
                size={"sm"}
                attendanceLimits={
                  item?.is_registration_required
                    ? "Attendance Limit: " +
                      (item?.capacity !== 0
                        ? item?.capacity
                        : "No Attendance Limit")
                    : 0
                }
                registrationsCount={item?.registered_users_count ?? 0}
                link={window.location.href.split("?")[0] + `/${item?.slug}`}
                handleCarnivalClick={() =>
                  handleClick(`/carnival/${item?.carnival?.slug}`)
                }
                handleLocation={() =>
                  window.open(item?.location?.google_map_url, "_blank")
                }
                buttonCallback={() =>
                  isLoggedIn ? handleOnRemove(item?.id) : handleLogin()
                }
                onClick={() => handleClick(`/events/${item?.slug}`)}
                chipCallback={(item) => handleNavigate({ tag: item })}
                isWholeDayEvent={item?.is_whole_day_event}
                buttonArgs={buttonArgs}
                showButton={showButton}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
