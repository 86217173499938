import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Chip from "../chip/Chip";
import GroupsIcon from "@mui/icons-material/Groups";
import Button from "../button/Button";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { CardSizes } from "../../config";
import { LocationOn } from "@mui/icons-material";
import SharingTooltip from "../sharing-tooltip/SharingTooltip";
import EventCss from "./eventCard.module.css";
import CalendarTooltip from "../calendar-tooltip/CalendarTooltip";
import { TimeExtractor } from "../../elements/time-extractor/TimeExtractor";
import { ExtractDate } from "../../elements/date-extractor/ExtractDate";

export default function EventCard({
  eventName = "",
  shortAddress = null,
  startTime = "",
  endTime = "",
  startDate = "",
  endDate = "",
  attendanceLimits = "No Attendance Limits",
  registrationsCount = 0,
  imageSrc = "https://4f14f3f2b2e5fd1c43c0-19fdc616b209d38d8f477bc6e666e66f.ssl.cf1.rackcdn.com/events/tribe-ignite-2024-272724-VArJSPE65x.jpg",
  tags = [],
  showButton = true,
  buttonArgs = null,
  buttonCallback = null,
  tag = [],
  carnivalName = null,
  link = "",
  size = "sm",
  onClick = undefined,
  chipCallback = undefined,
  description = "",
  handleCarnivalClick = undefined,
  handleLocation = undefined,
  isWholeDayEvent = false,
  startDateTime = "",
  endDateTime = "",
  timezone = "America/Port_of_Spain",
  onShare = undefined,
  onCalendarClick = undefined,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const defaultButtonArgs = {
    backgroundColor: "blue",
    borderColor: null,
    color: null,
    hoverColor: null,
    size: "medium",
    onClick: buttonCallback,
    label: "Add to itinerary",
    iconLeft: false,
    iconRight: false,
    variant: "contained",
    showAnimation: false,
    styles: {},
    icon: null,
    disabled: false,
    borderRadius: 0,
  };

  return (
    <div
      style={{
        width:
          width < 600 || size === "free"
            ? "100%"
            : size === "md"
              ? CardSizes.xxl
              : CardSizes.lg,
        height: "auto",
      }}
      className={EventCss.mainDiv}
    >
      <Grid
        container
        columnSpacing={width < 600 ? 0 : 2}
        rowSpacing={width < 600 ? 2 : 0}
      >
        <Grid
          item
          xs={12}
          sm={4}
          md={size === "md" ? 3 : size === "free" ? 5 : 4}
          className={EventCss.imgMainDiv}
        >
          <div
            onClick={onClick}
            className={EventCss.eventImageDiv}
            style={{
              height: "auto",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${imageSrc})`,
              }}
              className={EventCss.eventImageD}
            />
            {/* <img
              className={EventCss.eventImage}
              src={imageSrc}
              alt="eventFlyer"
            /> */}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={size === "md" ? 9 : size === "free" ? 7 : 8}
        >
          <div className={EventCss.eventContent}>
            <div
              onClick={onClick}
              style={{
                marginBottom: carnivalName ? 5 : 30,
              }}
              className={EventCss.eventName}
            >
              {eventName}
            </div>
            {carnivalName && (
              <div
                onClick={handleCarnivalClick}
                className={EventCss.eventCarnivalName}
              >
                {carnivalName}
              </div>
            )}
            {shortAddress && shortAddress !== "TBA" && (
              <div
                onClick={handleLocation}
                className={EventCss.eventShortAddress}
              >
                <LocationOn style={{ color: "#FF0000", fontSize: 18 }} />
                <span>{shortAddress}</span>
              </div>
            )}
            <div className={EventCss.eventShortAddressA}>
              <AccessTimeFilledIcon
                style={{ color: "#FF0000", fontSize: 16, marginLeft: 1 }}
              />
              {isWholeDayEvent ? (
                <span>
                  {ExtractDate(startDateTime)} {"(whole day)"}
                </span>
              ) : (
                <span>
                  {ExtractDate(startDateTime)}, {TimeExtractor(startDateTime)}{" "}
                  to {ExtractDate(endDateTime)}, {TimeExtractor(endDateTime)}
                </span>
              )}
            </div>
            <div className={EventCss.eventChips}>
              {tags?.length > 0
                ? tags.map((item, index) => (
                    <Chip
                      size="md"
                      variant="filled"
                      label={item}
                      key={index}
                      handleClick={chipCallback}
                    />
                  ))
                : tag?.map((item, index) => (
                    <Chip
                      size="md"
                      variant="filled"
                      label={item?.slug}
                      key={index}
                      handleClick={() => chipCallback(item.slug)}
                    />
                  ))}
            </div>
            <div className={EventCss.eventChip}>
              <CalendarTooltip
                title={eventName}
                location={shortAddress}
                description={description}
                startDate={startDate}
                startTime={startTime}
                endDate={endDate}
                endTime={endTime}
                timezone={timezone}
                onCalendarClick={onCalendarClick}
              />
              <SharingTooltip
                title={"Checkout this amazing event: " + eventName}
                link={link}
                onShare={onShare}
              />
              {showButton &&
                (buttonArgs ? (
                  <Button onClick={buttonCallback} {...buttonArgs} />
                ) : (
                  <Button {...defaultButtonArgs} />
                ))}
            </div>
            <div
              style={{
                marginTop: carnivalName ? 17 : 30,
              }}
              className={EventCss.eventNumber}
            >
              <GroupsIcon style={{ marginRight: 10, fontSize: 20 }} />
              <span>
                {attendanceLimits === 0 && registrationsCount == 0
                  ? "No Registration Required"
                  : `${attendanceLimits} | Currently Registered : ${registrationsCount}`}
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
