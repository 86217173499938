import React, { useEffect, useState } from "react";
import CardCss from "./Card.module.css";
import { CardSizes, FontSizes } from "../../config";

export default function Card({
  title = "",
  content = null,
  imageSrc = "",
  size = "md",
  showAnimation = false,
  showLinearFillShadow = false,
  onClick = undefined,
  textColor = null,
  textProps = {},
  fontSize = null,
  textAlign = "center",
  imageProps = {},
}) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const styles = {
    container: {
      width:
        width < 800
          ? "100%"
          : imageProps?.width
            ? imageProps?.width
            : size === "xs"
              ? CardSizes.xs
              : size === "sm"
                ? CardSizes.sm
                : size === "md"
                  ? CardSizes.md
                  : CardSizes.lg,
      cursor: onClick !== undefined ? "pointer" : "auto",
    },
    imageDiv: {
      width: "100%",
      height:
        width < 800
          ? "auto"
          : size === "xs"
            ? 240
            : size === "sm"
              ? 270
              : size === "md"
                ? 300
                : 350,
      minHeight: 230,
      ...imageProps,
    },
    title: {
      fontSize: fontSize
        ? fontSize
        : size === "xs"
          ? FontSizes.text
          : size === "sm"
            ? FontSizes.body
            : size === "md"
              ? FontSizes.heading
              : FontSizes.heading,
      color: textColor ?? "#ffffff",
      textAlign: textAlign || "center",
      ...textProps,
    },
  };

  return (
    <div
      style={styles.container}
      onClick={onClick}
      className={CardCss.cardContainer}
    >
      <div
        style={styles.imageDiv}
        className={showAnimation ? CardCss.image : CardCss.imageDiv}
      >
        <img className={CardCss.cardImg} src={imageSrc} alt="video" />
        {showLinearFillShadow && <div className={CardCss.linear} />}
      </div>
      <div className={CardCss.title} style={styles.title}>
        {title}
      </div>
      <div>{content}</div>
    </div>
  );
}
