import * as React from "react";

import { ComponentProps, makeUseContext } from "yet-another-react-lightbox";
import { LikesRef } from "../index.ts";
import { resolveLikesProps } from "./props.ts";

export const LikesContext = React.createContext<LikesRef | null>(null);

export const useLikes = makeUseContext("useLikes", "LikesContext", LikesContext);

export function LikesContextProvider({ likes, children }: ComponentProps) {
  const { ref } = resolveLikesProps(likes);

  const [visible, setVisible] = React.useState(true);

  const context = React.useMemo(
    () => ({
      visible,
      show: () => setVisible(true),
      hide: () => setVisible(false),
    }),
    [visible],
  );

  React.useImperativeHandle(ref, () => context, [context]);

  return <LikesContext.Provider value={context}>{children}</LikesContext.Provider>;
}
