import { Skeleton } from "@mui/material";
import React from "react";
import loaderCss from "./loader.module.css";
export default function EventCardLoader() {
  return (
    <div>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="rectangular"
        width={270}
        height={350}
      />
      <div className={loaderCss.centreContent}>
        <Skeleton
          sx={{ bgcolor: "grey.900", marginTop: "5px" }}
          animation="wave"
          variant="text"
          width={150}
        />

        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          animation="wave"
          variant="text"
          width={190}
        />
      </div>
    </div>
  );
}
