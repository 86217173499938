import * as React from "react";

import { addToolbarButton, PluginProps } from "yet-another-react-lightbox";
import { resolveShareProps } from "./props.ts";
import { ShareButton } from "./ShareButton.tsx";

export function Share({ augment }: PluginProps) {
  augment(({ toolbar, share: shareProps, ...rest }) => {
    const share = resolveShareProps(shareProps);
    return {
      toolbar: addToolbarButton(toolbar, "share", <ShareButton onShare={share.onShare}/>),
      share,
      ...rest,
    }
  });
}
