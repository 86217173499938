import React, { useEffect, useState } from "react";
import { FontSizes } from "../../../config";
import { BsThreeDotsVertical } from "react-icons/bs";
import PlaylistCss from "../Playlist.module.css";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MenuItem, MenuList, Popover } from "@mui/material";

export default function GetTableRow({
  isSelected,
  handlePlay,
  row,
  index,
  handlePlayNext,
  handleAddToQueue,
  handleLike,
  handleDownload,
}) {
  const [like, setLike] = useState(false);
  const [anchorPos, setAnchorPos] = useState({
    top: 0,
    left: 0,
  });

  const open = anchorPos.top !== 0 && anchorPos.left !== 0;

  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorPos({
      top: event?.clientY,
      left: event?.clientX,
    });
  };

  
  useEffect(() => {
    setLike(row?.is_liked);
  }, [row?.is_liked]);

  const handleClose = () => {
    setAnchorPos({
      top: 0,
      left: 0,
    });
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorPos({
        top: 0,
        left: 0,
      });
    } else if (event.key === "Escape") {
      setAnchorPos({
        top: 0,
        left: 0,
      });
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#515151",
      color: "#fff",
      fontSize: FontSizes.heading,
      fontWeight: "700",
      fontFamily: "Inter-Bold",
      border: "0",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: FontSizes.body,
      fontWeight: "500",
      fontFamily: "Inter-Medium",
      color: "#fff",
      border: "0",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#181818",
      border: "0",
      cursor: "pointer",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#000000",
      border: "0",
      cursor: "pointer",
    },
    // hide last border
  }));
  const StyledTableRowP = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255,25,25,0.7)",
      border: "0",
      cursor: "pointer",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(255,25,25,0.7)",
      border: "0",
      cursor: "pointer",
    },
    // hide last border
  }));

  const GetTableRow = isSelected ? StyledTableRowP : StyledTableRow;

  function handleRoute(item) {
    if (item === "add") {
      handleAddToQueue(row);
    } else if (item === "next") {
      handlePlayNext(row);
    } else if (item === "now") {
      handlePlay(row);
    } else if (item === "like") {
      setLike(!like);
      handleLike(row);
    } else if (item === "download") {
      handleDownload(row);
    }
    handleClose();
  }
  return (
    <GetTableRow>
      <StyledTableCell
        onClick={() => handleRoute("now")}
        padding="none"
        style={{ paddingLeft: 10, width: 50 }}
      >
        {index + 1}.
      </StyledTableCell>
      <StyledTableCell
        onClick={() => handleRoute("now")}
        padding="none"
        align="left"
      >
        {row.title}
      </StyledTableCell>
      <StyledTableCell onClick={() => handleRoute("now")} align="right">
        {convertDurationToTime(row.duration)}
      </StyledTableCell>
      <StyledTableCell
        padding="none"
        style={{
          paddingRight: 10,
          width: 50,
        }}
        align="right"
        onClick={(e) => handleClick(e)}
      >
        <BsThreeDotsVertical className={PlaylistCss.threedots} />
      </StyledTableCell>
      <Popover
        id={id}
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={anchorPos}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuList
          autoFocusItem={open}
          id="composition-menu"
          aria-labelledby="composition-button"
          onKeyDown={handleListKeyDown}
          style={{
            backgroundColor: "#363635",
            color: "#fff",
            fontSize: FontSizes.smallText,
            fontFamily: "Inter-Regular",
          }}
        >
          <MenuItem onClick={() => handleRoute("now")}>Play</MenuItem>
          <MenuItem onClick={() => handleRoute("next")}>Play Next</MenuItem>
          <MenuItem onClick={() => handleRoute("add")}>Add to queue</MenuItem>
          <MenuItem onClick={() => handleRoute("like")}>
            {row?.is_liked && like ? "Unlike" : like ? "Unlike" : "Like"}
          </MenuItem>
          {row?.is_downloadable && (
            <MenuItem onClick={() => handleRoute("download")}>
              Download
            </MenuItem>
          )}
        </MenuList>
      </Popover>
    </GetTableRow>
  );
}

const convertDurationToTime = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  // Construct the time string based on the presence of hours
  let formattedTime = "";
  if (hours > 0) {
    formattedTime += `${formatNumber(hours)}:${formatNumber(
      minutes
    )}:${formatNumber(seconds)}`;
  } else {
    formattedTime += `${formatNumber(minutes)}:${formatNumber(seconds)}`;
  }
  return formattedTime;
};

const formatNumber = (number) => {
  return number < 10 ? `0${number}` : number;
};
