import React, { useContext, useEffect, useState } from "react";
import AuthorsCss from "./Author.module.css";
import Label from "../../elements/label/Label";
import TextField from "../../components/textfield/TextField";
import { Close, Search } from "@mui/icons-material";
import NavContainer from "../../elements/NavContainer/NavContainer";
import { IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../Context";
import { Skeleton } from "@mui/material";
import SortedAuthorList from "../../elements/sorted-list/SortedAuthorList";
import SlugConverter from "../../elements/slug-converter/SlugConverter";
import SingleAuthorLoader from "../../elements/skeletonLoaders/SingleAuthorLoader";
import NoData from "../../elements/no-data/NoData";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import ReactGA from "react-ga4";

export default function Authors() {
  const [search, setSearch] = useState("");
  const { setPageTitle } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [authorsList, setAuthorsList] = useState([]);
  const location = useLocation();
  const [label, setLabel] = useState("All Authors");
  const [routingPath, setRoutingPath] = useState("");
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const [finalSearch, setFinalSearch] = useState("");
  const [filteredAuthorList, setFilteredAuthorList] = useState([]);
  const [status, setStatus] = useState(null);

  const getAllAuthor = async () => {
    try {
      const res = await axios.get(url, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        setStatus(res.status);
        if (res?.data?.authors?.data?.length !== 0) {
          setAuthorsList(res?.data?.authors?.data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (url) {
      getAllAuthor();
    }
  }, [url]);

  useEffect(() => {
    const names = location.pathname.split("/");
    setRoutingPath(names[1]);
    if (names[1] === "newsletters") {
      setPageTitle("All Authors - Newsletters");
      setLabel("All Authors - Newsletters");
      setUrl(SERVER_URL.GET_AUTHORS_NEWSLETTERS);
    }
    if (names[1] === "reviews") {
      setPageTitle("All Authors - Reviews");
      setLabel("All Authors - Reviews");
      setUrl(SERVER_URL.GET_AUTHORS);
    }
    if (names[1] === "news-articles") {
      setPageTitle("All Authors - News Articles");
      setLabel("All Authors - News Articles");
      setUrl(SERVER_URL.GET_AUTHORS_NEWSARTICLES);
    }
    ReactGA.send({
      hitType: "pageview",
      page: `/${names[1]}/authors`,
      title: `All Authors(${names[1]})`,
    });
  }, [location.pathname]);

  const handleClick = (item) => {
    const params = `author=${SlugConverter(item)}`;
    const route = `/${routingPath}?` + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (finalSearch) {
      filterList();
    }
  }, [finalSearch]);

  const handleSearch = (item) => {
    if (item && item !== "") {
      setFinalSearch(item);
      ReactGA.event({
        category: "Search",
        action: `Searched ${item}`,
        label: `Searched ${item} on all author page`,
      });
      ReactGA.event({
        category: "Search",
        action: `Searched ${item} on all author page`,
        label: `Searched ${item} on all author page`,
      });
    }
  };

  const handleClear = () => {
    setSearch("");
    setFinalSearch("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(search);
    }
  };

  function filterList() {
    const fl = authorsList?.filter(
      (author) =>
        author?.first_name?.toLowerCase()?.includes(search.toLowerCase()) ||
        author?.last_name?.toLowerCase()?.includes(search.toLowerCase())
    );
    setFilteredAuthorList(fl);
  }

  return (
    <NavContainer>
      {isLoading ? (
        Array.from({ length: 7 }, (_, index) => (
          <div key={index}>
            <div className={AuthorsCss.mgb} />
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="text"
              width={200}
              height={40}
              key={index}
            />
            <div className={AuthorsCss.mgb} />
            <div className={AuthorsCss.flexDrR}>
              {Array.from({ length: 7 }, (_, index) => (
                <div style={{ width: 200 }} key={index}>
                  <SingleAuthorLoader />
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <>
          <div className={AuthorsCss.allAuthorsMainDiv}>
            <Label size="lg" label={label} />
            <div className={AuthorsCss.allAuthorsSubDiv}>
              <TextField
                label="Search"
                onChange={(value) => setSearch(value)}
                defaultValue={search}
                size="small"
                onKeyDown={handleKeyPress}
              />
              {finalSearch ? (
                <IconButton
                  onClick={handleClear}
                  className={AuthorsCss.allAuthorsIconButton}
                >
                  <Close className={AuthorsCss.allAuthorsSearchIcon} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => handleSearch(search)}
                  className={AuthorsCss.allAuthorsIconButton}
                >
                  <Search className={AuthorsCss.allAuthorsSearchIcon} />
                </IconButton>
              )}
            </div>
          </div>
          {authorsList?.length === 0 ||
          (finalSearch && filteredAuthorList?.length === 0) ? (
            <NoData errorCode={status} />
          ) : (
            <SortedAuthorList
              data={finalSearch ? filteredAuthorList : authorsList}
              handleClick={handleClick}
            />
          )}
        </>
      )}
    </NavContainer>
  );
}
