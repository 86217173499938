import FlatlistCss from "./Flatlist.module.css";
import React from "react";
import VideoCard from "../../components/video-card/VideoCard";
import { useNavigate } from "react-router";
import { ExtractDate } from "../date-extractor/ExtractDate";

export default function VideoList({ videos }) {
  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };
  return (
    <div className={FlatlistCss.songListDiv}>
      {videos.map((item, index) => {
        const date = ExtractDate(item.created_at);
        return (
          <VideoCard
            key={index}
            title={item.title}
            viewCount={item.watch_count}
            likeCount={item.like_count}
            subTitle={date}
            thumbnailLink={item.cover_image_url}
            onClick={() => handleClick(`/videos/${item.slug}`)}
            orientation={"portrait"}
            size={"md"}
          />
        );
      })}
    </div>
  );
}
