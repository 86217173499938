import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import AlbumCss from "./Album.module.css";
import AlbumFilter from "./elements/AlbumFilter";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Context } from "../../Context";
import TextField from "../../components/textfield/TextField";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader";
import { useSearchParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import AlbumsList from "../../elements/Flatlist/AlbumsList";
import NoData from "../../elements/no-data/NoData";
import ViewMore from "../../elements/view-more/ViewMore";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import ReactGA from "react-ga4";
import DynamicContentInjector from "../../elements/DynamicContentInjector/DynamicContentInjector.js";

const options = [
  {
    label: "Date",
    value: "updated_at",
  },
  {
    label: "Plays",
    value: "play_count",
  },
  {
    label: "Likes",
    value: "like_count",
  },
  {
    label: "Title",
    value: "title",
  },
];

export default function AlbumHome() {
  const [width, setWidth] = useState(window.innerWidth);
  const [showDate, setShowDate] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { setPageTitle } = useContext(Context);
  const [orderDirection, setOrderDirection] = useState(null);
  const [albumsPageNumber, setAlbumsPageNumber] = useState(1);
  const [filters, setFilters] = useState({});
  const [albums, setAlbums] = useState([]);
  const [moreAlbumsIsLoading, setMoreAlbumsIsLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({});
  const [albumsLastPage, setAlbumsLastPage] = useState(null);
  const [artistNameLists, setArtistNameLists] = useState(null);
  const [years, setYears] = useState(null);
  const [countryList, setCountryList] = useState(null);
  const [genres, setGenres] = useState(null);
  const [countryListIsLoading, setCountryListIsLoading] = useState(true);
  const [yearsIsLoading, setYearsIsLoading] = useState(true);
  const [genresIsLoading, setGenresIsLoading] = useState(true);
  const [artistsIsLoading, setArtistsIsLoading] = useState(true);
  const [searchedLocation, setSearchedLocation] = useState([]);
  const [searchedGenre, setSearchedGenre] = useState([]);
  const [searchedYear, setSearchedYear] = useState([]);
  const [searchedArtists, setSearchedArtists] = useState([]);
  const [bannerAd, setBannerAd] = useState(null);
  const [bannerAd2, setBannerAd2] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/music/albums/all",
      title: "All Albums",
    });
  }, []);

  const handleGetSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "sidebar ad square",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd(res.data);
        }
      }
    } catch (err) {
      setBannerAd(null);
    }
  };

  const handleGetSecondSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "skyscraper ad",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd2(res.data);
        }
      }
    } catch (err) {
      setBannerAd2(null);
    }
  };

  useEffect(() => {
    handleGetSidebarBanner();
    handleGetSecondSidebarBanner();
    handleGetArtists();
    handleGetCountry();
    handleGetGenres();
    handleGetYears();
  }, []);

  const handleGetData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ALL_ALBUMS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: albumsPageNumber,
          items: PER_PAGE_COUNT.ALL_ALBUMS,
          orderBy: orderBy,
          orderDirection: orderDirection,
          ...(filters?.countries?.length != 0 && {
            countries: filters?.countries,
          }),
          ...(filters?.artist?.length != 0 && { artists: filters?.artist }),
          ...(filters?.genre?.length != 0 && { genres: filters?.genre }),
          ...(filters?.year?.length != 0 && { year: filters?.year }),
        },
      });
      if (res.status === 200) {
        setAlbumsLastPage(res?.data?.last_page);
        if (albums?.length === 0) {
          setAlbums(res?.data?.data);
        } else {
          setAlbums((prev) => [...prev, ...res?.data?.data]);
        }
        setIsLoading(false);
        if (moreAlbumsIsLoading) {
          setMoreAlbumsIsLoading(false);
        }
      }
    } catch (err) {
      if (moreAlbumsIsLoading) {
        setMoreAlbumsIsLoading(false);
      }
      setAlbums([]);
      setIsLoading(false);
    }
  };

  const handleGetYears = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_YEARS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          per_page: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setYears(res?.data?.data);
        }
        setYearsIsLoading(false);
      }
    } catch (err) {
      setYearsIsLoading(false);
    }
  };

  const handleGetCountry = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_COUNTRY, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
          type: "all",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setCountryList(res?.data?.data);
        }
        setCountryListIsLoading(false);
      }
    } catch (err) {
      setCountryListIsLoading(false);
    }
  };

  const handleGetGenres = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_GENRES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setGenres(res?.data?.data);
        }
        setGenresIsLoading(false);
      }
    } catch (err) {
      setGenresIsLoading(false);
    }
  };

  const handleGetArtists = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ARTISTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setArtistNameLists(res?.data?.data);
        }
        setArtistsIsLoading(false);
      }
    } catch (err) {
      setArtistsIsLoading(false);
    }
  };

  useEffect(() => {
    setAlbumsPageNumber(1);
    setIsLoading(true);
    setAlbums([]);
    if (orderBy && orderDirection) {
      if (searchParams.size === 1 && searchParams.has("sortby")) {
        handleGetData();
      } else if (searchParams.size === 1 && searchParams.has("orderby")) {
        handleGetData();
      } else if (
        searchParams.size === 2 &&
        searchParams.has("orderby") &&
        searchParams.has("sortby")
      ) {
        handleGetData();
      } else if (searchParams.size !== 0) {
        if (Object.keys(filters)?.length !== 0) {
          handleGetData();
        }
      } else {
        handleGetData();
      }
    }
  }, [orderBy, orderDirection, filters]);

  function sortBy() {
    return (
      <div className={AlbumCss.sortDiv}>
        <div className={AlbumCss.sortBy}>
          <TextField
            defaultValue={orderBy}
            type={"select"}
            label={"Sort by"}
            data={options}
            onChange={(item) => handleSortby(item)}
          />
        </div>
        <FaSortAmountUp
          onClick={() => handleOrder("asc")}
          className={
            orderDirection === "asc"
              ? AlbumCss.ascDescIcon1
              : AlbumCss.ascDescIcon
          }
        />
        <>
          <FaSortAmountDownAlt
            onClick={() => handleOrder("desc")}
            className={
              orderDirection === "desc"
                ? AlbumCss.ascDescIcon1
                : AlbumCss.ascDescIcon
            }
          />
        </>
      </div>
    );
  }

  const handleOrder = (item) => {
    handleSearchParam("orderby", item);
    const order = item === "asc" ? "ascending" : "descending";
    ReactGA.event({
      category: "Order",
      action: `Order by ${order}`,
      label: `Order by ${order} on all albums page`,
    });
    ReactGA.event({
      category: "Order",
      action: `Order by ${order} on all albums page`,
      label: `Ordered the results by ${order} on all albums page`,
    });
    if (item === "asc") {
      setOrderDirection("asc");
    } else {
      setOrderDirection("desc");
    }
  };

  useEffect(() => {
    setPageTitle("All Albums");
  }, []);

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  useEffect(() => {
    if (searchParams.size !== 0) {
      let params = {};
      if (searchParams?.getAll("countries").length !== 0) {
        setSearchedLocation(searchParams?.getAll("countries"));
        params = {
          ...params,
          countries: searchParams.getAll("countries"),
        };
      }

      if (searchParams?.getAll("artist").length !== 0) {
        setSearchedArtists(searchParams?.getAll("artist"));
        params = {
          ...params,
          artist: searchParams.getAll("artist"),
        };
      }

      if (searchParams?.getAll("genre").length !== 0) {
        setSearchedGenre(searchParams?.getAll("genre"));
        params = {
          ...params,
          genre: searchParams.getAll("genre"),
        };
      }
      if (searchParams?.getAll("year").length !== 0) {
        setSearchedYear(searchParams?.getAll("year"));
        params = {
          ...params,
          year: searchParams.getAll("year"),
        };
      }
      if (
        params &&
        Object.keys(params).length > 0 &&
        Object.keys(filters)?.length === 0
      ) {
        handleSearch(params);
      }
    }
    if (searchParams.size !== 0 && searchParams.get("sortby")) {
      handleSortby(searchParams.get("sortby"));
    } else {
      setOrderBy("updated_at");
    }
    if (searchParams.size !== 0 && searchParams.get("orderby")) {
      handleOrder(searchParams.get("orderby"));
    } else {
      setOrderDirection("desc");
    }
  }, [searchParams]);

  const handleSortby = (item) => {
    handleSearchParam("sortby", item);
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results`,
      label: `Sort by ${item} on all albums page`,
    });
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results on all albums page`,
      label: `Sorted the results by ${item} on all albums page`,
    });
    setOrderBy(item);
  };

  const handleShowDd = (item) => {
    if (item === "filter") {
      if (showDate) {
        setShowDate(false);
      }
      setShowFilter(!showFilter);
    } else {
      if (showFilter) {
        setShowFilter(false);
      }
      setShowDate(!showDate);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width < 431) {
      setShowDate(false);
      setShowFilter(false);
    }
  }, [width]);

  const loadMoreAlbums = () => {
    if (albumsLastPage != null && albumsPageNumber <= albumsLastPage) {
      setMoreAlbumsIsLoading(true);
      setAlbumsPageNumber(albumsPageNumber + 1);
    }
  };

  useEffect(() => {
    if (albumsPageNumber !== 1) {
      handleGetData();
    }
  }, [albumsPageNumber]);

  const handleSearchParam = (key, value) => {
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  const handleResetAll = () => {
    setFilters({});
    const filter = ["countries", "artist", "genre", "year"];
    filter.map((item) => {
      if (searchParams.has(item)) {
        searchParams.delete(item);
        setSearchParams(searchParams);
        delete params[item];
      }
    });
    setSearchedLocation([]);
    setSearchedArtists([]);
    setSearchedGenre([]);
    setSearchedYear([]);
  };

  const handleSearch = (item) => {
    ReactGA.event({
      category: "Filter",
      action: "Results filtered",
      label: "Results filtered on all albums page",
    });
    ReactGA.event({
      category: "Filter",
      action: "Results filtered on all albums page",
      label: "Results filtered on all albums page",
    });
    setFilters(item);
    Object.entries(item).map((i) => {
      handleSearchParam(i[0], i[1]);
    });
  };

  return (
    <NavContainer>
      <div className={AlbumCss.mainDiv}>
        <div className={AlbumCss.fullWidthContainerFd}>
          <div className={AlbumCss.firstContainer}>
            <div className={AlbumCss.flexDr}>
              <div className={AlbumCss.head}>
                <Label size="lg" label={"All Album"} />
                {width > 899 && sortBy()}
              </div>
              <div className={AlbumCss.iconsSm}>
                <div className={AlbumCss.iconsSmDiv}>
                  <img
                    src={require("../../assets/images/filter.png")}
                    className={AlbumCss.filterSm}
                    onClick={() => handleShowDd("filter")}
                    alt="filter"
                  />
                  {showFilter ? (
                    <FaCaretUp className={AlbumCss.upDown} />
                  ) : (
                    <FaCaretDown className={AlbumCss.upDown} />
                  )}
                </div>
              </div>
            </div>
            {showFilter &&
              (artistsIsLoading ||
              genresIsLoading ||
              yearsIsLoading ||
              countryListIsLoading ? (
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="rounded"
                  width={"100%"}
                  height={334}
                />
              ) : (
                <AlbumFilter
                  onSearch={(val) => handleSearch(val)}
                  countryArray={countryList}
                  genreArray={genres}
                  yearsArray={years}
                  artistArray={artistNameLists}
                  onReset={() => handleResetAll()}
                  searchedArtist={searchedArtists}
                  searchedGenre={searchedGenre}
                  searchedYear={searchedYear}
                  searchedLocation={searchedLocation}
                />
              ))}
            {width < 900 && sortBy()}
            {bannerAd &&
              (bannerAd?.banner_image_url ? (
                <div className={AlbumCss.smBanner}>
                  <img
                    className={AlbumCss.BannerAds}
                    src={bannerAd?.banner_image_url}
                  />
                </div>
              ) : (
                bannerAd?.content && (
                  <div className={AlbumCss.smBanner}>
                    <DynamicContentInjector
                      className={AlbumCss.BannerAd}
                      htmlContent={bannerAd?.content}
                    />
                  </div>
                )
              ))}
            {bannerAd2 &&
              (bannerAd2?.banner_image_url ? (
                <div className={AlbumCss.smBanner}>
                  <img
                    className={AlbumCss.BannerAds}
                    src={bannerAd2?.banner_image_url}
                  />
                </div>
              ) : (
                bannerAd2?.content && (
                  <div className={AlbumCss.smBanner}>
                    <DynamicContentInjector
                      className={AlbumCss.BannerAd}
                      htmlContent={bannerAd2?.content}
                    />
                  </div>
                )
              ))}
            {width < 899 && <div className={AlbumCss.mgb} />}
            {isLoading ? (
              <div className={AlbumCss.albumsDiv}>
                {Array.from({ length: 9 }, (_, index) => (
                  <div key={index} className={AlbumCss.albumsSubDiv}>
                    <MusicCardLoader />
                  </div>
                ))}
              </div>
            ) : albums?.length !== 0 ? (
              <>
                <AlbumsList albums={albums} />
                {moreAlbumsIsLoading && <div style={{ marginTop: 20 }} />}
                {moreAlbumsIsLoading && (
                  <div className={AlbumCss.albumsDiv}>
                    {Array.from({ length: 9 }, (_, index) => (
                      <div key={index} className={AlbumCss.albumsSubDiv}>
                        <MusicCardLoader />
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <NoData />
            )}
            {albums?.length !== 0 &&
              albumsLastPage != null &&
              albumsPageNumber < albumsLastPage &&
              !isLoading &&
              !moreAlbumsIsLoading && (
                <ViewMore loadMore={true} onClick={loadMoreAlbums} />
              )}{" "}
          </div>

          <div className={AlbumCss.secondContainer}>
            {artistsIsLoading ||
            genresIsLoading ||
            yearsIsLoading ||
            countryListIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={334}
              />
            ) : (
              <AlbumFilter
                onSearch={(val) => handleSearch(val)}
                countryArray={countryList}
                genreArray={genres}
                yearsArray={years}
                artistArray={artistNameLists}
                onReset={() => handleResetAll()}
                searchedArtist={searchedArtists}
                searchedGenre={searchedGenre}
                searchedYear={searchedYear}
                searchedLocation={searchedLocation}
              />
            )}
            {bannerAd &&
              (bannerAd?.banner_image_url ? (
                <img
                  className={AlbumCss.BannerAds}
                  src={bannerAd?.banner_image_url}
                />
              ) : (
                bannerAd?.content && (
                  <DynamicContentInjector
                    className={AlbumCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                )
              ))}
            {bannerAd2 &&
              (bannerAd2?.banner_image_url ? (
                <img
                  className={AlbumCss.BannerAds}
                  src={bannerAd2?.banner_image_url}
                />
              ) : (
                bannerAd2?.content && (
                  <DynamicContentInjector
                    className={AlbumCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                )
              ))}
          </div>
        </div>
      </div>
    </NavContainer>
  );
}
