import React from "react";
import { Grid, Skeleton } from "@mui/material";

export default function CategoriesLoader() {
  function getRandomPercentage() {
    const randomNumber = Math.floor(Math.random() * (100 - 60 + 1)) + 60;
    return randomNumber.toString() + "%";
  }

  return (
    <>
      {Array.from({ length: 50 }).map((_, index) => (
        <Grid key={index} item xs={5.3} sm={3.3} md={2.2}>
          <div style={{ display: "flex", gap: "5px" }}>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="circular"
              width={"10%"}
            />
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="text"
              width={getRandomPercentage()}
            />
          </div>
        </Grid>
      ))}
    </>
  );
}
