import React from "react";
import { useState, useEffect } from "react";
import { Skeleton } from "@mui/material";
export default function VideoCardLoader({ size }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="rectangular"
        width={size === "sm" ? 273 : width > 431 ? 370 : 300}
        height={size === "sm" ? 183 : width > 431 ? 210 : 170}
      />

      <Skeleton
        sx={{ bgcolor: "grey.900", marginTop: "5px" }}
        animation="wave"
        variant="text"
        width={190}
      />
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="text"
        width={150}
      />
    </div>
  );
}
