import { Skeleton } from "@mui/material";
import React from "react";
import loaderCss from "./loader.module.css";
import { useState, useEffect } from "react";
export default function NewsCardLoader() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {" "}
      {width > 431 ? (
        <>
          <div className={loaderCss.col5}>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="rectangular"
              width={300}
              height={250}
            />
            <div className={loaderCss.row}>
              {" "}
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={width > 600 ? 320 : 120}
                height={50}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={width > 600 ? 320 : 190}
                height={20}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={width > 600 ? 320 : 190}
                height={20}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={width > 600 ? 320 : 190}
                height={20}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={width > 600 ? 320 : 190}
                height={20}
              />{" "}
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={width > 600 ? 320 : 190}
                height={20}
              />
              <div className={loaderCss.chips}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={50}
                  height={40}
                />
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={50}
                  height={40}
                />
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={50}
                  height={40}
                />
              </div>
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="circular"
                height={40}
                width={40}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={200}
                height={20}
              />{" "}
            </div>
          </div>
        </>
      ) : (
        <>
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="rectangular"
            width={525}
            height={350}
          />
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={220}
            height={30}
          />
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={220}
            height={15}
          />
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={220}
            height={15}
          />{" "}
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={220}
            height={15}
          />
          <div className={loaderCss.chips}>
            {Array.from({ length: 5 }, (item, index) => (
              <Skeleton
                key={index}
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={50}
                height={40}
              />
            ))}
          </div>
          <div>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="circular"
              height={40}
              width={40}
            />
            <Skeleton
              sx={{ bgcolor: "grey.900", marginTop: "7px", marginBottom: 7 }}
              animation="wave"
              variant="text"
              width={width > 600 ? 400 : 200}
              height={15}
            />
          </div>
        </>
      )}
    </>
  );
}
