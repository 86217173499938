import React, { useEffect, useState } from "react";
import MusicControls from "../../../music-controls/MusicControls";
import { RiPlayListAddFill } from "react-icons/ri";
import { BiLike, BiSolidLike } from "react-icons/bi";

function PlayerControl({
  songState,
  setSongState,
  audioRef,
  setPlaylistOpen,
  playlistOpen,
  songQueue,
  currentSongIndex,
  setCurrentSongIndex,
  handleSong,
  onLike,
  isLiked,
  isLoggedIn,
}) {
  const [like, setLike] = useState(false);
  const [openPlaylist, setOpenPlaylist] = useState(false);

  useEffect(() => {
    setLike(isLiked);
  }, [isLiked]);

  const handleOpen = () => {
    setOpenPlaylist(!openPlaylist);
    setPlaylistOpen(!openPlaylist);
  };

  useEffect(() => {
    setOpenPlaylist(playlistOpen);
  }, [playlistOpen]);

  const handleLike = () => {
    if (isLoggedIn) {
      setLike(!like);
    }
    onLike();
  };

  return (
    <div className="player__control">
      <RiPlayListAddFill
        onClick={() => handleOpen()}
        style={{ color: "#fff", fontSize: 26, cursor: "pointer" }}
      />
      <MusicControls
        size={"lg"}
        audioRef={audioRef}
        disabled={false}
        showBackward={true}
        showForward={true}
        setSongState={setSongState}
        songState={songState}
        songQueue={songQueue}
        currentSongIndex={currentSongIndex}
        setCurrentSongIndex={setCurrentSongIndex}
        onSongChange={handleSong}
      />
      {like ? (
        <BiSolidLike
          style={{ color: "#fff", fontSize: 26, cursor: "pointer" }}
          onClick={() => handleLike()}
        />
      ) : (
        <BiLike
          style={{ color: "#fff", fontSize: 26, cursor: "pointer" }}
          onClick={() => handleLike()}
        />
      )}
    </div>
  );
}

export default PlayerControl;
