import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../../button/Button";
import PlaylistCard from "../../../playlist-card/PlaylistCard";
import IconButton from "../../../icon-button/IconButton";
import { BiPlus } from "react-icons/bi";
import TextField from "../../../textfield/TextField";
import PlaylistCss from "./Playlist.module.css";

export default function Playlist({ playlist, onClick, onPlaylistClick }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box className={PlaylistCss.mainBox}>
      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          className={PlaylistCss.modalDiv}
        >
          <CreateNew
            width={width}
            onClick={onClick}
            setOpen={(bool) => setOpen(bool)}
          />
        </Modal>
      )}
      <div className={PlaylistCss.playListDiv}>
        <div className={PlaylistCss.playlistHead}>Add to Playlist</div>
        {width < 431 ? (
          <IconButton
            onClick={() => setOpen(true)}
            backgroundColor={"transparent"}
            icon={<BiPlus className={PlaylistCss.plusIcon} />}
          />
        ) : (
          <Button
            onClick={() => setOpen(true)}
            size={"medium"}
            label="Create New"
          />
        )}
      </div>
      {playlist?.length === 0 ? (
        <div className={PlaylistCss.noPlaylist}>
          Ahh Ah! No playlist created yet
        </div>
      ) : (
        <div className={PlaylistCss.playlistGroupDiv}>
          {playlist?.map((item, index) => {
            return (
              <PlaylistCard
                key={index}
                title={item?.title}
                onClick={() => onPlaylistClick(item)}
                subTitle={item?.description}
                thumbnail={item?.cover_image_url}
              />
            );
          })}
        </div>
      )}
    </Box>
  );
}

function CreateNew({ onClick, setOpen }) {
  const [playlistName, setPlaylistName] = useState("");
  const [playlistNameError, setPlaylistNameError] = useState(false);
  const [description, setDescription] = useState("");

  const handleClick = () => {
    let valid = true;
    if (playlistName === "") {
      setPlaylistNameError(true);
      valid = false;
    }
    if (valid) {
      const body = {
        title: playlistName,
        ...(description && { description: description }),
      };
      onClick(body);
      setOpen(false)
    }
  };
  return (
    <Box className={PlaylistCss.subBox}>
      <div className={PlaylistCss.playlistHead}>Create Playlist</div>
      <div className={PlaylistCss.mgTop}>
        <TextField
          label="Playlist Name"
          onChange={(event) => setPlaylistName(event)}
          defaultValue={playlistName}
          size={"small"}
          error={playlistNameError}
        />
      </div>
      <div className={PlaylistCss.mgTop}>
        <TextField
          label="Description"
          onChange={(event) => setDescription(event)}
          defaultValue={description}
          size={"small"}
          rows={3}
          multiline
        />
      </div>
      <div className={PlaylistCss.buttonsDiv}>
        <Button
          onClick={() => setOpen(false)}
          size={"medium"}
          label="Cancel"
          backgroundColor={"transparent"}
        />
        <Button onClick={() => handleClick()} size={"medium"} label="Create" />
      </div>
    </Box>
  );
}
