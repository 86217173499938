import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import SortedList from "../../elements/sorted-list/SortedCarnivalList";
import Label from "../../elements/label/Label";
import { IconButton, Skeleton } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import TextField from "../../components/textfield/TextField";
import CarnivalCss from "./CarnivalHome.module.css";
import { Context } from "../../Context";
import CountryFooter from "../../elements/country-footer/CountryFooter";
import { useSearchParams } from "react-router-dom";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import MetaTags from "../../MetaTags";
import CarnivalList from "../../elements/Flatlist/CarnivalList";
import PhotoCardLoader from "../../elements/skeletonLoaders/PhotoCardLoader";
import ViewMore from "../../elements/view-more/ViewMore";
import NoData from "../../elements/no-data/NoData";
import axios from "axios";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import ReactGA from "react-ga4";

const options = [
  {
    label: "Date",
    value: "created_at",
  },
  {
    label: "Rating",
    value: "tjj_rating",
  },
  {
    label: "Title",
    value: "title",
  },
];

export default function AllCarnivals() {
  const [search, setSearch] = useState("");
  const { setPageTitle } = useContext(Context);
  let [searchParams, setSearchParams] = useSearchParams();
  const [location, setLocation] = useState("");
  const [orderDirection, setOrderDirection] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [carnivalsPageNumber, setCarnivalsPageNumber] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [carnivalsLastPage, setCarnivalsLastPage] = useState(null);
  const [params, setParams] = useState({});
  const [carnivals, setCarnivals] = useState([]);
  const [carnivalsIsLoading, setCarnivalsIsLoading] = useState(true);
  const [moreCarnivalsIsLoading, setMoreCarnivalsIsLoading] = useState(false);
  const [finalSearch, setFinalSearch] = useState("");
  const [status, setStatus] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/carnivals",
      title: "All Carnivals",
    });
  }, []);

  function updateCarnivalsPageNumber() {
    if (carnivalsLastPage != null && carnivalsPageNumber <= carnivalsLastPage) {
      setMoreCarnivalsIsLoading(true);
      setCarnivalsPageNumber(carnivalsPageNumber + 1);
    }
  }
  useEffect(() => {
    if (carnivalsPageNumber !== 1) {
      handleGetCarnivals();
    }
  }, [carnivalsPageNumber]);

  const handleGetCarnivals = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: carnivalsPageNumber,
          items: PER_PAGE_COUNT.ALL_CARNIVALS,
          orderBy: orderBy,
          orderDirection: orderDirection,
          ...(finalSearch && {
            search: finalSearch,
          }),
          ...(location && { region: location }),
        },
      });
      if (res.status === 200) {
        setCarnivalsLastPage(res?.data?.last_page);
        setStatus(res.status);
        if (carnivals?.length === 0) {
          setCarnivals(res?.data?.data);
        } else {
          setCarnivals((prev) => [...prev, ...res?.data?.data]);
        }
        if (moreCarnivalsIsLoading) {
          setMoreCarnivalsIsLoading(false);
        }
        setCarnivalsIsLoading(false);
      }
    } catch (err) {
      if (moreCarnivalsIsLoading) {
        setMoreCarnivalsIsLoading(false);
      }
      setCarnivalsIsLoading(false);
    }
  };

  useEffect(() => {
    setCarnivalsPageNumber(1);
    setCarnivalsIsLoading(true);
    setCarnivals([]);
    if (searchParams.size === 1 && searchParams.has("sortby")) {
      if (orderBy && orderDirection) {
        handleGetCarnivals();
      }
    } else if (searchParams.size === 1 && searchParams.has("orderby")) {
      if (orderBy && orderDirection) {
        handleGetCarnivals();
      }
    } else if (
      searchParams.size === 2 &&
      searchParams.has("orderby") &&
      searchParams.has("sortby")
    ) {
      if (orderBy && orderDirection) {
        handleGetCarnivals();
      }
    } else if (searchParams.size !== 0) {
      if ((location || finalSearch) && orderBy && orderDirection) {
        handleGetCarnivals();
      }
    } else {
      if (orderBy && orderDirection) {
        handleGetCarnivals();
      }
    }
  }, [orderBy, orderDirection, location, finalSearch]);

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function sortBy() {
    return (
      <div className={CarnivalCss.sortDiv}>
        <div className={CarnivalCss.sortBy}>
          <TextField
            defaultValue={orderBy}
            type={"select"}
            label={"Sort by"}
            data={options}
            onChange={(item) => handleSortby(item)}
          />
        </div>
        <FaSortAmountUp
          onClick={() => handleOrder("asc")}
          className={
            orderDirection === "asc"
              ? CarnivalCss.ascDescIcon1
              : CarnivalCss.ascDescIcon
          }
        />
        <>
          <FaSortAmountDownAlt
            onClick={() => handleOrder("desc")}
            className={
              orderDirection === "desc"
                ? CarnivalCss.ascDescIcon1
                : CarnivalCss.ascDescIcon
            }
          />
        </>
      </div>
    );
  }

  const handleSearchParam = (key, value) => {
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSortby = (item) => {
    handleSearchParam("sortby", item);
    setOrderBy(item);
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results`,
      label: `Sort by ${item} on all carnivals page`,
    });
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results on all carnivals page`,
      label: `Sort by ${item} on all carnivals page`,
    });
  };

  const handleOrder = (item) => {
    if (item === "asc") {
      setOrderDirection("asc");
    } else {
      setOrderDirection("desc");
    }
    const order = item === "asc" ? "ascending" : "descending";
    ReactGA.event({
      category: "Order",
      action: `Order by ${order}`,
      label: `Order by ${order} on all carnivals page`,
    });
    ReactGA.event({
      category: "Order",
      action: `Order by ${order} on all carnivals page`,
      label: `Ordered the results by ${order} on all carnivals page`,
    });
    handleSearchParam("orderby", item);
  };

  useEffect(() => {
    setPageTitle("All Carnivals");
  }, []);

  const handleCountry = (item) => {
    handleSearchParam("region", item);
    ReactGA.event({
      category: "Region",
      action: `Filtered by region`,
      label: `Filtered by region ${item} on all carnivals page`,
    });
    ReactGA.event({
      category: "Region",
      action: `Filtered by region ${item} on all carnivals page`,
      label: `Filtered by region ${item} on all carnivals page`,
    });
    setLocation(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (searchParams.size !== 0) {
      if (searchParams?.get("region")) {
        handleCountry(searchParams.get("region"));
      }
    }
    if (searchParams.size !== 0 && searchParams?.get("search")) {
      if (searchParams.get("search")) {
        setSearch(searchParams.get("search"));
        handleSearch(searchParams.get("search"));
      }
    }
    if (searchParams.size !== 0 && searchParams.get("sortby")) {
      handleSortby(searchParams.get("sortby"));
    } else {
      setOrderBy("created_at");
    }
    if (searchParams.size !== 0 && searchParams.get("orderby")) {
      handleOrder(searchParams.get("orderby"));
    } else {
      setOrderDirection("asc");
    }
  }, [searchParams]);

  const handleSearch = (item) => {
    if (item && item !== "") {
      setFinalSearch(item);
      ReactGA.event({
        category: "Search",
        action: `Searched ${item}`,
        label: `Searched ${item} on all carnivals page`,
      });
      ReactGA.event({
        category: "Search",
        action: `Searched ${item} on all carnivals page`,
        label: `Searched ${item} on all carnivals page`,
      });
      handleSearchParam("search", item);
    }
  };

  const handleClear = () => {
    if (searchParams.has("search")) {
      searchParams.delete("search");
      setSearchParams(searchParams);
      delete params["search"];
    }
    setSearch("");
    setFinalSearch("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(search);
    }
  };

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"All Carnivals"}
        url={window.location.href}
        type={"website"}
        image="../../assets/images/tjj-logo.jpg "
      />

      <div className={CarnivalCss.allCarnivalMainDiv}>
        <div className={CarnivalCss.flexDr1}>
          <Label size="lg" label={"All Carnivals"} />
          {width > 431 && sortBy()}
        </div>
        <div className={CarnivalCss.allCarnivalSubDiv}>
          <TextField
            label="Search"
            onChange={(value) => setSearch(value)}
            defaultValue={search}
            size="small"
            onKeyDown={handleKeyPress}
          />
          {finalSearch ? (
            <IconButton
              onClick={handleClear}
              className={CarnivalCss.allCarnivalIconButton}
            >
              <Close className={CarnivalCss.allCarnivalSearchIcon} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => handleSearch(search)}
              className={CarnivalCss.allCarnivalIconButton}
            >
              <Search className={CarnivalCss.allCarnivalSearchIcon} />
            </IconButton>
          )}
        </div>
      </div>
      {width < 431 && sortBy()}

      {carnivalsIsLoading ? (
        <div className={CarnivalCss.mgb}>
          <Skeleton
            sx={{ bgcolor: "grey.900", margin: "20px" }}
            variant="text"
            width={80}
          />
          <div className={CarnivalCss.flexDrR}>
            {Array.from({ length: 3 }).map((_, index) => (
              <PhotoCardLoader key={index} />
            ))}
          </div>
          <Skeleton
            sx={{ bgcolor: "grey.900", margin: "20px" }}
            variant="text"
            width={80}
          />
          <div className={CarnivalCss.flexDrR}>
            {Array.from({ length: 3 }).map((_, index) => (
              <PhotoCardLoader key={index} />
            ))}
          </div>
          <Skeleton
            sx={{ bgcolor: "grey.900", margin: "20px" }}
            variant="text"
            width={80}
          />
          <div className={CarnivalCss.flexDrR}>
            {Array.from({ length: 3 }).map((_, index) => (
              <PhotoCardLoader key={index} />
            ))}
          </div>
        </div>
      ) : (
        <>
          {orderBy === "title" ? (
            carnivals ? (
              <SortedList data={carnivals} order={orderDirection} />
            ) : (
              <NoData errorCode={status} />
            )
          ) : carnivals?.length > 0 ? (
            <CarnivalList gallery={carnivals} />
          ) : (
            <NoData errorCode={status} />
          )}
          {carnivalsLastPage != null &&
          carnivalsPageNumber < carnivalsLastPage ? (
            <ViewMore loadMore onClick={updateCarnivalsPageNumber} />
          ) : (
            <div className={CarnivalCss.mgb} />
          )}
        </>
      )}

      <CountryFooter by={"Carnivals"} handleClick={handleCountry} />
    </NavContainer>
  );
}
