import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Chip from "../chip/Chip";
import blogcss from "./BlogCard.module.css";
import { CardSizes } from "../../config";
import SharingTooltip from "../sharing-tooltip/SharingTooltip";

export default function BlogCard({
  title = "",
  subTitle = "",
  excerpt = "",
  imageSrc = "",
  footerText = "",
  size = "md",
  tagsArray = [],
  tagArray = [],
  share = false,
  link = "",
  onClick = undefined,
  handleChipCallback = undefined,
  onShare
}) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        width:
          width < 600
            ? "100%"
            : size === "sm"
              ? CardSizes.lg
              : size === "md"
                ? CardSizes.xl
                : CardSizes.xxl,
        height:
          width < 600
            ? "auto"
            : size === "sm"
              ? 220
              : size === "md"
                ? 296
                : 251,
      }}
      className={blogcss.mainDiv}
    >
      <Grid container>
        <Grid className={blogcss.blogGrid} item xs={12} sm={4} md={4}>
          <div
            onClick={onClick}
            className={blogcss.blogImgDiv}
            style={{
              // backgroundImage: `url(${imageSrc})`,
              height:
                width < 600
                  ? 350
                  : size === "sm"
                    ? 220
                    : size === "md"
                      ? 296
                      : 251,
            }}
          />
          <img
            onClick={onClick}
            style={{
              width: "auto",
              height:"100%",
              position: "absolute",
            }}
            src={imageSrc}
            alt="video"
          />
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <div className={blogcss.blogDiv}>
            <div onClick={onClick} className={blogcss.title}>
              {title}
            </div>
            {subTitle !== "" && (
              <div className={blogcss.subTitle}>{subTitle}</div>
            )}
            {excerpt !== "" && <div className={blogcss.excerpt}>{excerpt}</div>}
            {tagsArray && tagsArray?.length !== 0 && (
              <div className={blogcss.chips}>
                {tagsArray?.map((item, index) => {
                  return (
                    <Chip
                      label={item}
                      handleClick={handleChipCallback}
                      key={index}
                    />
                  );
                })}
              </div>
            )}
            {tagArray && tagArray?.length !== 0 && (
              <div className={blogcss.chipsA}>
                {tagArray?.map((item, index) => {
                  return (
                    <Chip
                      label={item?.slug}
                      handleClick={() => handleChipCallback(item?.slug)}
                      key={index}
                    />
                  );
                })}
              </div>
            )}
            {share && (
              <SharingTooltip
                title={"Checkout this amazing blog post: " + title}
                link={link}
                onShare={onShare}
              />
            )}
            {footerText !== "" && (
              <div
                style={{
                  fontWeight: subTitle !== "" ? "500" : "400",
                  fontFamily:
                    subTitle !== "" ? "Inter-Medium" : "Inter-Regular",
                }}
                className={blogcss.footerText}
              >
                {footerText}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
