import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../../components/button/Button";
import Rating from "../../../components/rating/Rating";
import PropTypes from "prop-types";
import RatingCss from "./RatingModal.module.css";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function RatingModal({ isLoggedIn, onClick }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState({
    rating: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width < 431 ? "70%" : "300px",
    bgcolor: "#343232",
    borderRadius: 6,
    boxShadow: 24,
    maxHeight: 350,
    p: 4,
    overflow: "hidden",
  };

  const handleClick = () => {
    if (onClick) {
      onClick(result);
    }
    setOpen(false);
    setResult([]);
  };

  const handleOnChange = (value) => {
    setResult({
      rating: parseInt(value),
    });
  };

  return (
    <div>
      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          style={{ zIndex: 99999999999 }}
        >
          <Box sx={style}>
            <div className={RatingCss.title}>Submit Your Rating</div>
            <div className={RatingCss.content}>
              <div className={RatingCss.subTitleDiv}>
                <Rating
                  value={result?.rating}
                  onChange={(i) => handleOnChange(i)}
                  size={"large"}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Button
                onClick={() => setOpen(false)}
                size={"medium"}
                label="Cancel"
                backgroundColor={"transparent"}
              />
              <Button
                onClick={() => handleClick()}
                size={"medium"}
                label="Rate"
              />
            </div>
          </Box>
        </Modal>
      )}
      <Button
        onClick={() => (isLoggedIn ? setOpen(true) : handleLogin())}
        size={"medium"}
        label="Add Review"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn, // Assuming `isLoggedIn` is stored in your Redux state
});

export default connect(mapStateToProps)(RatingModal);

RatingModal.propTypes = {
  onClick: PropTypes.func,
  args: PropTypes.array,
};

RatingModal.defaultProps = {
  onClick: undefined,
  args: [],
};
