import { LightboxProps, useLightboxProps } from "yet-another-react-lightbox";

export const defaultlikesProps = {
  showToggle: true,
};

export const resolveLikesProps = (likes: LightboxProps["likes"]) => ({
  ...defaultlikesProps,
  ...likes,
});

export function useLikesProps() {
  const { likes } = useLightboxProps();
  return resolveLikesProps(likes);
}
