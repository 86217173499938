import { Skeleton } from '@mui/material';
import React from 'react'
import Label from '../../../../elements/label/Label';
import NewsLongCardLoader from '../../../../elements/skeletonLoaders/NewsLongCardLoader';
import NewsList from '../../../../elements/Flatlist/NewsList';
import ViewMore from '../../../../elements/view-more/ViewMore';
import GlobalSearchCss from "../../GlobalSearch.module.css";

export default function News({
    news,
    isLoading,
    length,
    showViewAll = false,
    setValue,
    moreNewsArticlesIsLoading,
    newsArticlesPageNumber,
    newsArticlesLastPage,
    updateNewsArticlesPageNumber = undefined,
  }) {
    return (
      <div>
        <div className={GlobalSearchCss.mainDiv}>
          {isLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={"40%"}
              height={50}
            />
          ) : (
            <Label label={`News Articles (${length} results)`} size="md" />
          )}
        </div>
        {isLoading ? (
          <>
            {Array.from({ length: 6 }, (_,index) => {
              return <NewsLongCardLoader key={index} />;
            })}
            <div className={GlobalSearchCss.mgb} />
          </>
        ) : (
          <>
            <NewsList news={news} isNewsArticle={true}/>
            {moreNewsArticlesIsLoading && (
              <>
                {Array.from({ length: 6 }, (_,index) => {
                  return <NewsLongCardLoader key={index} />;
                })}
                <div className={GlobalSearchCss.mgb} />
              </>
            )}
            {showViewAll && <ViewMore onClick={() => setValue(1)} />}
            {news &&
              news?.length !== 0 &&
              newsArticlesLastPage !== null &&
              newsArticlesPageNumber < newsArticlesLastPage &&
              !isLoading &&
              !moreNewsArticlesIsLoading &&
              !showViewAll && (
                <ViewMore loadMore onClick={updateNewsArticlesPageNumber} />
              )}
          </>
        )}
      </div>
    );
  }
