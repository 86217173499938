import Card from "../../components/card/Card";
import NewsCss from "./NewsArticles.module.css";

export function popularNewsArticlesCard(item, index, handleClick) {
  return (
    <Card
      imageProps={{ width: "100%", height: "auto" }}
      key={index}
      imageSrc={item?.featured_image_url}
      title={item?.title}
      textAlign={"left"}
      textColor={"#ff0000"}
      textProps={{ fontWeight: "400", fontFamily: "Inter" }}
      onClick={() => handleClick(`/news-articles/${item?.slug}`)}
      content={
        item?.excerpt ? (
          <div className={NewsCss.content}>{item?.excerpt}</div>
        ) : (
          <div className={NewsCss.content}>{item?.content}</div>
        )
      }
    />
  );
}
