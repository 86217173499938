import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import AlbumCss from "./Album.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { FontSizes } from "../../config";
import AlbumsList from "../../elements/Flatlist/AlbumsList";
import Button from "../../components/button/Button";
import { Grid, Modal, Skeleton } from "@mui/material";
import { Context } from "../../Context";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectMusic, setMusic } from "../../slices/MusicReducer";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader";
import GetTableRow from "./elements/GetTableRow";
import { addToQueue, playNextInQueue } from "../../actions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import MetaTags from "../../MetaTags.js";
import Swal from "sweetalert2";
import Playlist from "../../elements/Playlist/Playlist.js";
import NoDataScreen from "../no-data/NoDataScreen.js";
import ReactGA from "react-ga4";

function AlbumView({ isLoggedIn, user }) {
  const { slug } = useParams();
  const [album, setAlbum] = useState(null);
  const { setPageTitle } = useContext(Context);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [moreFromisLoading, setMoreFromIsLoading] = useState(true);
  const [moreFromArtists, setMoreFromArtists] = useState([]);
  const [artists, setArtists] = useState(null);
  const [song, setSong] = useState({ id: "", isPlaying: false });
  const music = useSelector(selectMusic)[0];
  const { pathname } = useLocation();
  const [openPlaylist, setOpenPlaylist] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const [playlistIsLoading, setPlaylistIsLoading] = useState(true);
  const [songId, setSongId] = useState(null);
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/music/albums/${slug}`,
      title: "Albums Details",
    });
  }, []);

  function handleAddToQueue(data, album) {
    ReactGA.event({
      category: "Queue",
      action: `Add to queue button clicked`,
      label: `Songs added to the queue`,
    });
    if (Array.isArray(data)) {
      data.map((item) => {
        const genre = album?.genres?.map(
          (i, index) =>
            `${i?.title}${index !== album?.genres?.length - 1 ? ", " : ""}`
        );
        const d = {
          ...item,
          subTitle: album?.title,
          thumbnail: album?.cover_image_url,
          footerText: genre[0],
          hlsSource: item?.media_url,
        };
        dispatch(addToQueue(d));
      });
    } else {
      const genre = album?.genres?.map(
        (item, index) =>
          `${item?.title}${index !== album?.genres?.length - 1 ? ", " : ""}`
      );
      const d = {
        ...data,
        subTitle: album?.title,
        thumbnail: album?.cover_image_url,
        footerText: genre[0],
        hlsSource: data?.media_url,
      };
      dispatch(addToQueue(d));
    }
  }

  useEffect(() => {
    if (album) {
      setPageTitle(album?.title);
    } else {
      setPageTitle("Album Details");
    }
  }, [album]);

  const handleGetData = async (url) => {
    try {
      const res = await axios.get(url, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
      });
      setStatus(res?.status);
      if (res.status === 200) {
        if (res?.data) {
          setAlbum(res?.data);
          setIsLoading(false);
          if (res?.data?.artist?.length !== 0) {
            setArtists(res?.data?.artists[0]);
          } else {
            setArtists(null);
          }
        }
      }
    } catch (err) {
      setIsLoading(false);
      setStatus(err.response.status);
    }
  };

  const handleGetArtistsData = async () => {
    try {
      const res = await axios.get(
        `${SERVER_URL.GET_ARTIST_ALBUMS}${artists?.id}/albums`,
        {
          headers: {
            Accept: "application/json",
          },
          params: {
            page: 1,
            items: PER_PAGE_COUNT.MORE_ARTISTS,
          },
        }
      );
      if (res.status === 200) {
        if (res?.data?.data) {
          res?.data?.data?.map((item) => {
            if (item?.id !== album?.id) {
              setMoreFromArtists((prev) => [...prev, item]);
            }
          });
        }
        setMoreFromIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setMoreFromArtists([]);
      setMoreFromIsLoading(false);
    }
  };

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[3];
      handleGetData(`${SERVER_URL.GET_ALBUM_BY_SLUG}${u}`);
    }
  }, [pathname]);

  useEffect(() => {
    if (artists && album) {
      setMoreFromIsLoading(true);
      handleGetArtistsData();
    }
    if (!artists && album) {
      setMoreFromIsLoading(false);
    }
  }, [artists, album]);

  useEffect(() => {
    if (!music?.isPlaying) {
      setSong({ id: "" });
    } else {
      setSong({ id: music?.musicArgs?.id });
    }
  }, [music]);

  // This function is used to stream songs.
  const StreamMusic = async (id) => {
    try {
      const res = await axios.get(`${SERVER_URL.STREAM_MUSIC}${id}`, {
        headers: {
          Accept: "application/json",
        },
      });
    } catch (err) {}
  };

  const handlePlayTrack = (item) => {
    ReactGA.event({
      category: "Play",
      action: `Play all button clicked`,
      label: `${album?.title} track is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play all from ${album?.title}`,
      label: `${album?.title} track is being played`,
    });
    const genre = item?.genres?.map(
      (i, index) =>
        `${i?.title}${index !== item?.genres?.length - 1 ? ", " : ""}`
    );
    const data = {
      isPlaying: true,
      musicArgs: {
        id: item.songs[0]?.id,
        title: item?.songs[0]?.title,
        subTitle: item?.title,
        hlsSource: item?.songs[0]?.media_url,
        thumbnail: item?.cover_image_url,
        footerText: genre[0],
        duration: item?.songs[0]?.duration,
        isLiked: item?.songs[0]?.is_liked,
        artist_id: item?.songs[0]?.album?.artists[0]?.id,
      },
    };
    StreamMusic(item.songs[0]?.id);
    handleAddToQueue(item?.songs?.slice(1, item?.songs?.length), item);
    dispatch(setMusic(data));
  };

  const handlePlay = (data, item) => {
    ReactGA.event({
      category: "Play",
      action: `Play Song Clicked`,
      label: `${data?.title} is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play Song: ${data?.title}`,
      label: `${data?.title} is being played`,
    });
    const genre = item?.genres?.map(
      (i, index) =>
        `${i?.title}${index !== item?.genres?.length - 1 ? ", " : ""}`
    );
    const d = {
      isPlaying: true,
      musicArgs: {
        id: data?.id,
        title: data?.title,
        subTitle: item?.title,
        hlsSource: data?.media_url,
        thumbnail: item?.cover_image_url,
        footerText: genre[0],
        duration: data?.duration,
        artist_id: item?.artists[0]?.id,
        isLiked: data?.is_liked,
      },
    };
    StreamMusic(data?.id);
    dispatch(setMusic(d));
  };

  const handlePlayNext = (data, album) => {
    const genre = album?.genres?.map(
      (item, index) =>
        `${item?.title}${index !== album?.genres?.length - 1 ? ", " : ""}`
    );
    ReactGA.event({
      category: "Play",
      action: `Play Next Clicked`,
      label: `${data?.title} is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play Next Clicked: ${data?.title}`,
      label: `${data?.title} is being played`,
    });
    const d = {
      ...data,
      subTitle: album?.title,
      thumbnail: album?.cover_image_url,
      footerText: genre[0],
      hlsSource: data?.media_url,
    };
    dispatch(playNextInQueue(d));
  };

  const handleGetPlaylist = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_USER_PLAYLIST, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setPlaylist(res?.data?.data);
        }
        setPlaylistIsLoading(false);
      }
    } catch (err) {
      setPlaylist([]);
      setPlaylistIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      handleGetPlaylist();
    }
  }, [user]);

  const handleLike = async (data) => {
    try {
      const res = await axios.get(`${SERVER_URL.LIKE_SONG}${data?.id}/like`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (res.status === 200) {
        ReactGA.event({
          category: "Song",
          action: `Liked/Unliked a song`,
          label: `Liked/Unliked a song: ${data?.title}`,
        });
        ReactGA.event({
          category: "Song",
          action: `Liked/Unliked a song: ${data?.title}`,
          label: `Liked/Unliked a song: ${data?.title}`,
        });
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to like the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const onCreatePlaylist = async (item) => {
    try {
      const res = await axios.post(SERVER_URL.GET_PLAYLIST, item, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        setPlaylistIsLoading(true);
        handleGetPlaylist();
        ReactGA.event({
          category: "Playlist",
          action: "New playlist created",
        });
        setOpenPlaylist(false);
        Swal.fire({
          title: "Playlist Created Successfully",
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        }).then(() => {
          setOpenPlaylist(true);
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to create the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleOpenPlaylist = (item) => {
    setSongId(item?.id);
    setOpenPlaylist(true);
    ReactGA.event({
      category: "Playlist",
      action: "Add to playlist button clicked",
      label: item?.title,
    });
    ReactGA.event({
      category: "Playlist",
      action: `${item?.title} added to playlist`,
      label: item?.title,
    });
  };

  const handlePlaylist = async (item) => {
    setOpenPlaylist(false);
    const body = {
      playlist_id: item?.id,
      song: songId,
    };
    try {
      const res = await axios.post(SERVER_URL.ADD_SONGS_TO_PLAYLIST, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to add song to the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  const handleDownload = async (song) => {
    if (!song?.media_url) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const response = await fetch(song.media_url);
      if (!response.ok) {
        Swal.fire({
          title: "Unable to download the song",
          icon: "error",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const fileExtension = song?.media_url?.split(".").pop(); // Assuming the extension is present in the URL
      const fileName =
        song?.title?.replace(/\s+/g, "-").toLowerCase() + "." + fileExtension;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      ReactGA.event({
        category: "Download",
        action: "Download button clicked",
        label: fileName,
      });
      ReactGA.event({
        category: "Download",
        action: `${fileName} is downloaded`,
        label: fileName,
      });
    } catch (error) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={album?.title}
        url={window.location.href}
        type={"website"}
        image={album?.cover_image_url}
        description={album?.description}
      />
      <div className={AlbumCss.mainDiv}>
        <Grid container rowGap={3} columnGap={2}>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={400}
              />
            ) : (
              <img
                className={AlbumCss.artistImage}
                src={album?.cover_image_url}
                alt="Artist"
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={7}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className={AlbumCss.sideDes}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 6,
                }}
              >
                <div
                  style={{
                    fontSize: FontSizes.h1,
                    fontWeight: "700",
                    color: "white",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                  }}
                >
                  {isLoading ? (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"30%"}
                      height={30}
                    />
                  ) : (
                    album?.title
                  )}
                </div>
                <div className={AlbumCss.artistTitle}>
                  {isLoading ? (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"30%"}
                      height={20}
                    />
                  ) : (
                    album?.artists?.map((artist, index) => (
                      <span
                        key={index}
                        onClick={() =>
                          navigate(`/music/artists/${artist.slug}`)
                        }
                      >
                        {artist.first_name} {artist.last_name}
                        {index !== album?.artists?.length - 1 && ", "}
                      </span>
                    ))
                  )}
                </div>
                <div
                  style={{
                    fontSize: FontSizes.subTitle,
                    fontWeight: "400",
                    color: "#BBB",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                  }}
                >
                  {isLoading ? (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"30%"}
                    />
                  ) : (
                    <span>
                      {album?.genres?.map(
                        (i, index) =>
                          `${i?.title}${
                            index !== album?.genres?.length - 1 ? ", " : ""
                          }`
                      )}
                    </span>
                  )}
                </div>
              </div>
              <div className={AlbumCss.description}>
                {isLoading
                  ? Array.from({ length: 10 }, (_, index) => (
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="text"
                        width={"100%"}
                        key={index}
                      />
                    ))
                  : album?.description}
              </div>
            </div>
            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width={100}
                height={40}
              />
            ) : (
              album?.songs?.length !== 0 && (
                <Button
                  label="Play"
                  onClick={() => handlePlayTrack(album)}
                  size={"medium"}
                  styles={{ width: 100 }}
                />
              )
            )}
          </Grid>
        </Grid>

        <TableContainer>
          <Table sx={{ minWidth: 300 }} aria-label="customized table">
            <TableBody>
              {isLoading
                ? Array.from({ length: 3 }, (_, index) => (
                    <Skeleton
                      key={index}
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                      height={90}
                    />
                  ))
                : album?.songs?.map((row, index) => {
                    return (
                      <GetTableRow
                        key={index}
                        isSelected={row.id === song.id}
                        index={index}
                        row={row}
                        album={album}
                        handlePlay={handlePlay}
                        handlePlayNext={handlePlayNext}
                        handleAddToQueue={handleAddToQueue}
                        handleLike={(item) => {
                          isLoggedIn ? handleLike(item) : handleLogin();
                        }}
                        handleAddToPlaylist={(item) => {
                          isLoggedIn ? handleOpenPlaylist(item) : handleLogin();
                        }}
                        handleDownload={handleDownload}
                      />
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>

        {playlistIsLoading && openPlaylist ? (
          <Modal open={openPlaylist} onClose={() => setOpenPlaylist(false)}>
            <Skeleton
              sx={{ bgcolor: "grey.900", borderRadius: 10 }}
              animation="wave"
              variant="rounded"
              width={"40%"}
              height={300}
            />
          </Modal>
        ) : (
          openPlaylist &&
          !playlistIsLoading && (
            <Modal open={openPlaylist} onClose={() => setOpenPlaylist(false)}>
              <Playlist
                playlist={playlist}
                onClick={onCreatePlaylist}
                onPlaylistClick={handlePlaylist}
              />
            </Modal>
          )
        )}
        <div>
          {moreFromisLoading ? (
            <>
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 5 }}
                animation="wave"
                variant="rectangular"
                width={"30%"}
                height={30}
              />
              <div className={AlbumCss.albumsDiv}>
                {Array.from({ length: 4 }, (_, index) => {
                  return (
                    <div key={index} className={AlbumCss.albumsSubDiv}>
                      <MusicCardLoader music={true} />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            moreFromArtists?.length !== 0 && (
              <>
                <Label
                  size="lg"
                  label={
                    "More from " +
                    album?.artists[0]?.first_name +
                    " " +
                    album?.artists[0]?.last_name
                  }
                />
                <AlbumsList albums={moreFromArtists?.slice(0, 4)} />
              </>
            )
          )}
        </div>
      </div>
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
});

export default connect(mapStateToProps)(AlbumView);
