import React from "react";
import Dropzone from "react-dropzone";
import DragAndDropCss from "./DragAndDropZone.module.css";

export default function DragAndDropZone({
  handleDrop = undefined,
  handleRejected = undefined,
  accept = {
    "image/jpeg": [],
    "image/png": [],
    "image/webp": [],
    "image/heic": [],
    "image/jfif": [],
  },
  multiple = false,
  text = "Drag and drop an image here, or click to select an image",
  textColor = "#fff",
  borderColor = null,
  style = {},
}) {
  return (
    <Dropzone
      onDropAccepted={handleDrop}
      onDropRejected={handleRejected}
      accept={accept}
      multiple={multiple}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={DragAndDropCss.dash}
          style={borderColor && { borderColor: borderColor, ...style }}
        >
          <input {...getInputProps()} />
          <p
            className={DragAndDropCss.imageText}
            style={{ color: textColor ?? "#fff" }}
          >
            {text}
          </p>
        </div>
      )}
    </Dropzone>
  );
}
