import React, { useEffect, useState } from "react";
import { FontSizes } from "../../../config";
import CarnivalClassifiedsCss from "../CarnivalClassifieds.module.css";
import dayjs from "dayjs";
import AutoComplete from "../../../components/autocomplete/AutoComplete";
import Button from "../../../components/button/Button";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { datePickerStyle, pickerStyle } from "../../../css/DatePickerStyles";

export default function CarnivalClassifiedsFilter({
  onSearch,
  tag,
  postTypeData,
  onReset,
  filter,
}) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = React.useState();
  const [tags, setTags] = useState([]);
  const [postType, setPostType] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);

  function handleReset() {
    setEndDate(null);
    setStartDate(null);
    setTags([]);
    setPostType([]);
    onReset();
  }

  useEffect(() => {
    const op = tag?.map((item) => ({
      id: item?.id,
      name: item?.slug,
      slug: item?.slug,
    }));
    if (op) {
      setTagsArray(op);
    }
  }, [tag]);

  function handleSearch() {
    onReset();
    let params = {};
    if (tags?.length !== 0) {
      params = {
        ...params,
        tag: tags,
      };
    }
    if (postType?.length !== 0) {
      params = {
        ...params,
        postType: postType,
      };
    }
    if (startDate) {
      params = {
        ...params,
        startDate: startDate,
      };
    }
    if (endDate) {
      params = {
        ...params,
        endDate: endDate,
      };
    }
    onSearch(params);
  }

  useEffect(() => {
    if (filter) {
      if (filter?.tag?.length !== 0) {
        const filtered = filter?.tag?.filter((item) => !tags.includes(item));
        if (filtered && filtered.length !== 0) {
          setTags(filtered);
        }
      }
      if (filter?.postType?.length !== 0) {
        const filtered = filter?.postType?.filter(
          (item) => !postType.includes(item)
        );
        if (filtered && filtered.length !== 0) {
          setPostType(filtered);
        }
      }
      if (filter?.startDate) {
        if (filter?.startDate !== startDate) {
          setStartDate(filter.startDate);
        }
      }
      if (filter?.endDate) {
        if (filter?.endDate !== endDate) {
          setEndDate(filter.endDate);
        }
      }
    }
  }, [filter]);

  const handleStartDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setStartDate(d);
  };

  const handleEndDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setEndDate(d);
  };

  return (
    <div className={CarnivalClassifiedsCss.filter}>
      <div className={CarnivalClassifiedsCss.calendarHead}>Filters</div>
      {postTypeData && (
        <AutoComplete
          defaultValue={postType}
          handleOnChange={(val) => setPostType(val)}
          options={postTypeData}
          label="Post Type"
          isSlug={true}
        />
      )}
      {tagsArray && tagsArray?.length !== 0 && (
        <AutoComplete
          defaultValue={tags}
          handleOnChange={(val) => setTags(val)}
          options={tagsArray}
          label="Tags"
          isSlug={true}
        />
      )}
      <div>
        <div className={CarnivalClassifiedsCss.date}>Date Range</div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              sx={datePickerStyle}
              slotProps={{
                textField: { size: "small" },
                layout: {
                  sx: pickerStyle,
                },
              }}
              label="Start date"
              value={startDate ? dayjs(startDate) : null}
              onChange={handleStartDate}
            />
          </LocalizationProvider>
          <div className={CarnivalClassifiedsCss.to}>to</div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              sx={datePickerStyle}
              slotProps={{
                textField: { size: "small" },
                layout: {
                  sx: pickerStyle,
                },
              }}
              label="End date"
              value={endDate ? dayjs(endDate) : null}
              onChange={handleEndDate}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className={CarnivalClassifiedsCss.buttonsDiv}>
        <Button
          size={"small"}
          label="Reset"
          backgroundColor={"transparent"}
          color={"#ff0000"}
          styles={{ fontWeight: "600", fontSize: FontSizes.text }}
          onClick={() => handleReset()}
        />
        <Button onClick={() => handleSearch()} size={"small"} label="Search" />
      </div>
    </div>
  );
}
