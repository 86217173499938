export const playlist = [
  {
    imageSrc:
      "https://i.scdn.co/image/ab67616d0000b2734a8e46a57aa722809cd05d8b",
    title: "No Place Like Home",
    subTitle: "Vacations",
  },
  {
    imageSrc:
      "https://i.scdn.co/image/ab67616d00001e02073aebff28f79959d2543596",
    title: "No Place Like Home",
    subTitle: "Vacations",
  },
  {
    imageSrc:
      "https://i.scdn.co/image/ab67616d0000b2733e87501009d6a9d7e78889e5",
    title: "No Place Like Home",
    subTitle: "Vacations",
  },
  {
    imageSrc:
      "https://i.scdn.co/image/ab67616d0000b2734a8e46a57aa722809cd05d8b",
    title: "Nuthin' But A 'G' Thang",
    subTitle: "Dr. Dre | The Chronic | 1992",
  },
];

export const events = {
  total: 50,
  per_page: 15,
  current_page: 1,
  last_page: 4,
  first_page_url: "https://api.trinijunglejuice.com?page=1",
  last_page_url: "https://api.trinijunglejuice.com?page=4",
  next_page_url: "https://api.trinijunglejuice.com?page=2",
  path: "https://api.trinijunglejuice.com",
  from: 1,
  to: 15,
  events: [
    {
      timestamp: "Wednesday, March 27, 2024",
      events: [
        {
          id: 3,
          title: "Winter Wonderland",
          slug: "winter-wonderland",
          description:
            "Experience the magic of winter with ice skating, snowmen, and hot cocoa!",
          poster_url: "https://example.com/winter_wonderland_poster.jpg",
          start_datetime: "2024-12-15T10:00:00",
          end_datetime: "2024-12-15T20:00:00",
          is_whole_day_event: false,
          is_registration_required: true,
          registration_url: "https://example.com/register/winter-wonderland",
          cost_per_person: 15.99,
          capacity: 300,
          currently_registered_count: 10,
          is_recurring: false,
          tags: [
            { id: 1, name: "newsletter", slug: "newsletter" },
            { id: 2, name: "June 2024", slug: "june_2024" },
            { id: 3, name: "updates", slug: "updates" },
            { id: 4, name: "events", slug: "events" },
            { id: 5, name: "articles", slug: "articles" },
          ],
          carnival: {
            id: 7,
            name: "Frosty Fest",
          },
          is_featured: true,
          featured_until_date: "2024-12-10",
          is_published: true,
          is_rating_allowed: true,
          tjj_rating: 4.7,
          user_rating: 0,
          created_at: "2024-03-27T11:00:00",
          updated_at: "2024-03-27T11:00:00",
          location: {
            id: 3,
            latitude: 51.5074,
            longitude: -0.1278,
            address: "789 Elm Street",
            slug: "elm-street",
            city: "London",
            country: "UK",
            region: "Europe",
            google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
            created_at: "2024-03-27T11:00:00",
            updated_at: "2024-03-27T11:00:00",
          },
          itineraries: {
            id: 3,
            title: "Winter Activities",
            slug: "winter-activities",
            description: "List of winter-themed activities",
            user_id: 789,
            is_featured: false,
            created_at: "2024-03-28T12:00:00",
            updated_at: "2024-03-28T12:00:00",
          },
        },
        {
          id: 3,
          title: "Winter Wonderland",
          slug: "winter-wonderland",
          description:
            "Experience the magic of winter with ice skating, snowmen, and hot cocoa!",
          poster_url: "https://example.com/winter_wonderland_poster.jpg",
          start_datetime: "2024-12-15T10:00:00",
          end_datetime: "2024-12-15T20:00:00",
          is_whole_day_event: false,
          is_registration_required: true,
          registration_url: "https://example.com/register/winter-wonderland",
          cost_per_person: 15.99,
          capacity: 300,
          currently_registered_count: 10,
          is_recurring: false,
          tags: [
            { id: 1, name: "newsletter", slug: "newsletter" },
            { id: 2, name: "June 2024", slug: "june_2024" },
            { id: 3, name: "updates", slug: "updates" },
            { id: 4, name: "events", slug: "events" },
            { id: 5, name: "articles", slug: "articles" },
          ],
          carnival: {
            id: 7,
            name: "Frosty Fest",
          },
          is_featured: true,
          featured_until_date: "2024-12-10",
          is_published: true,
          is_rating_allowed: true,
          tjj_rating: 4.7,
          user_rating: 0,
          created_at: "2024-03-27T11:00:00",
          updated_at: "2024-03-27T11:00:00",
          location: {
            id: 3,
            latitude: 51.5074,
            longitude: -0.1278,
            address: "789 Elm Street",
            slug: "elm-street",
            city: "London",
            country: "UK",
            region: "Europe",
            google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
            created_at: "2024-03-27T11:00:00",
            updated_at: "2024-03-27T11:00:00",
          },
          itineraries: {
            id: 3,
            title: "Winter Activities",
            slug: "winter-activities",
            description: "List of winter-themed activities",
            user_id: 789,
            is_featured: false,
            created_at: "2024-03-28T12:00:00",
            updated_at: "2024-03-28T12:00:00",
          },
        },
      ],
    },
    {
      timestamp: "Wednesday, March 26, 2024",
      events: [
        {
          id: 2,
          title: "Summer Carnival",
          slug: "summer-carnival",
          description:
            "Celebrate the warmth of summer with games, rides, and delicious treats!",
          poster_url: "https://example.com/summer_carnival_poster.jpg",
          start_datetime: "2024-07-20T12:00:00",
          end_datetime: "2024-07-20T22:00:00",
          is_whole_day_event: false,
          is_registration_required: false,
          cost_per_person: 0,
          capacity: 1000,
          currently_registered_count: 10,
          is_recurring: false,
          tags: [
            { id: 1, name: "newsletter", slug: "newsletter" },
            { id: 2, name: "June 2024", slug: "june_2024" },
            { id: 3, name: "updates", slug: "updates" },
            { id: 4, name: "events", slug: "events" },
            { id: 5, name: "articles", slug: "articles" },
          ],
          carnival: {
            id: 5,
            name: "Summer Splash",
          },
          is_featured: false,
          is_published: true,
          is_rating_allowed: true,
          tjj_rating: 4.2,
          user_rating: 0,
          created_at: "2024-03-26T09:00:00",
          updated_at: "2024-03-26T09:00:00",
          location: {
            id: 2,
            latitude: 34.0522,
            longitude: -118.2437,
            address: "456 Oak Avenue",
            slug: "oak-avenue",
            city: "Los Angeles",
            state: "CA",
            country: "USA",
            region: "North America",
            google_map_url: "https://maps.google.com/maps?q=34.0522,-118.2437",
            created_at: "2024-03-26T09:00:00",
            updated_at: "2024-03-26T09:00:00",
          },
          itineraries: {
            id: 2,
            title: "Event Activities",
            slug: "event-activities",
            description: "List of activities at the event",
            user_id: 456,
            is_featured: true,
            featured_until_date: "2024-07-15",
            created_at: "2024-03-28T11:00:00",
            updated_at: "2024-03-28T11:00:00",
          },
        },
      ],
    },
    {
      timestamp: "Saturday, March 23, 2024",
      events: [
        {
          id: 1,
          title: "Spring Festival",
          slug: "spring-festival",
          description:
            "Join us for a day filled with fun activities, food, and music!",
          poster_url: "https://example.com/spring_festival_poster.jpg",
          start_datetime: "2024-04-15T10:00:00",
          end_datetime: "2024-04-15T18:00:00",
          is_whole_day_event: false,
          is_registration_required: true,
          registration_url: "https://example.com/register/spring-festival",
          cost_per_person: 10.99,
          capacity: 500,
          currently_registered_count: 10,
          is_recurring: false,
          tags: [
            { id: 1, name: "newsletter", slug: "newsletter" },
            { id: 2, name: "June 2024", slug: "june_2024" },
            { id: 3, name: "updates", slug: "updates" },
            { id: 4, name: "events", slug: "events" },
            { id: 5, name: "articles", slug: "articles" },
          ],
          carnival: {
            id: 3,
            name: "Spring Carnival",
          },
          is_featured: true,
          featured_until_date: "2024-04-10",
          is_published: true,
          is_rating_allowed: true,
          tjj_rating: 4.5,
          user_rating: 0,
          created_at: "2024-03-25T15:30:00",
          updated_at: "2024-03-25T15:30:00",
          location: {
            id: 1,
            latitude: 40.7128,
            longitude: -74.006,
            address: "123 Main Street",
            slug: "main-street",
            city: "New York",
            state: "NY",
            country: "USA",
            region: "North America",
            google_map_url: "https://maps.google.com/maps?q=40.7128,-74.006",
            created_at: "2024-03-25T15:30:00",
            updated_at: "2024-03-25T15:30:00",
          },
          itineraries: {
            id: 1,
            title: "Day Schedule",
            slug: "day-schedule",
            description: "Detailed itinerary for the day",
            user_id: 123,
            is_featured: false,
            created_at: "2024-03-28T10:00:00",
            updated_at: "2024-03-28T10:00:00",
          },
        },
      ],
    },
  ],
};

export const details = {
  id: 3,
  title: "Winter Wonderland",
  slug: "winter-wonderland",
  event_categories: ["Music", "Food & Drink", "Festival"],
  event_description:
    '<div className="event-description"><h1>CARNIVAL SATURDAY 7PM IN KINGSTON JAMAICA 🇯🇲!</h1><p>Absolutely FUN COOLER FETE with some of the most powerful West Indian DJs on Planet Earth!</p><img src="https://via.placeholder.com/400" alt="DJ booth at carnival" /><p>Our Fully Loaded Premium Bar will Accept Cash and Credit Cards.</p><p>Commemorative T-Shirts, Snap-Backs and discounted pre-sold bottles are also available upon checkout.</p><img src="https://via.placeholder.com/400" alt="Carnival merchandise" /><p><em>Don\'t push your limits. Drink Responsibly</em></p><p>Join us as we set the stage unlike you have ever seen before powered by planet earth\'s most powerful West Indian DJs.</p><img src="https://via.placeholder.com/400" alt="Crowd at carnival" /><p>The Revolution will be televised!</p><h2>Electronica Costume</h2><p>The Electronica costume is a vibrant and electrifying ensemble inspired by the world\'s greatest electronic music festivals and their daring fashion trends.</p><img src="https://via.placeholder.com/400" alt="Electronica costume" /><p>This high-energy costume is a bold and dynamic expression of the music, lights, and pulsating energy of these festivals and ravers worldwide.</p><p>It incorporates bright colors like neon yellow, hot pink, and royal blue to make a powerful visual statement.</p><img src="https://via.placeholder.com/400" alt="Colorful costume" /><p>Did we mention we are a private section this year meaning WE control the quality of your costume and your experience.</p><p>We ensure you get the most luxurious well designed and durable costumes.</p><p>We have concierge packages we will be sharing soon!</p><img src="https://via.placeholder.com/400" alt="Luxurious costume" /><p>Let’s do carnival the Five Star Way!</p></div>',
  description:
    "CARNIVAL SATURDAY 7PM IN KINGSTON JAMAICA 🇯🇲! Absolutely FUN COOLER FETE with some of the most powerful West Indian DJs on Planet Earth! Our Fully Loaded Premium Bar will Accept Cash and Credit Cards. Commemorative T-Shirts, Snap-Backs and discounted pre-sold bottles are also available upon checkout. *Don't push your limits. Drink Responsibly* Join us as we set the stage unlike you have ever seen before powered by planet earth's most powerful West Indian DJs. The Revolution will be televised! The Electronica costume is a vibrant and electrifying ensemble inspired by the world's greatest electronic music festivals and their daring fashion trends. This high-energy costume is a bold and dynamic expression of the music, lights, and pulsating energy of these festivals and ravers worldwide. It incorporates bright colors like neon yellow, hot pink, and royal blue to make a powerful visual statement. Did we mention we are a private section this year meaning WE control the quality of your costume and your experience. We ensure you get the most luxurious well designed and durable costumes. We have concierge packages we will be sharing soon! Let’s do carnival the Five Star Way!",
  poster_url:
    "https://www.trinijunglejuice.com/photos/2024/trinidad/doubles-and-bubbles-breakfast-cruise-2024/images/doubles-and-bubbles-breakfast-cruise-2024-126.jpg",
  start_datetime: "2024-12-15T10:00:00",
  end_datetime: "2024-12-19T20:00:00",
  is_whole_day_event: false,
  is_registration_required: true,
  registration_url: "https://example.com/register/winter-wonderland",
  cost_per_person: 15.99,
  capacity: 300,
  currently_registered_count: 10,
  is_recurring: false,
  tags: [
    { id: 1, name: "newsletter", slug: "newsletter" },
    { id: 2, name: "June 2024", slug: "june_2024" },
    { id: 3, name: "updates", slug: "updates" },
    { id: 4, name: "events", slug: "events" },
    { id: 5, name: "articles", slug: "articles" },
  ],
  carnival: {
    id: 7,
    name: "Frosty Fest",
  },
  is_featured: true,
  featured_until_date: "2024-12-10",
  is_published: true,
  is_rating_allowed: true,
  tjj_rating: 97,
  user_rating: 90,
  created_at: "2024-03-27T11:00:00",
  updated_at: "2024-03-27T11:00:00",
  subscribed: true,
  location: {
    id: 3,
    latitude: 51.5074,
    longitude: -0.1278,
    address: "789 Elm Street",
    slug: "elm-street",
    city: "Kolkata",
    country: "India",
    state: "West Bengal",
    region: "Asia",
    google_map_embed_url:
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2483.317002859837!2d-0.13037492337992762!3d51.50739997181331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTHCsDMwJzI2LjYiTiAwwrAwNyc0MC4xIlc!5e0!3m2!1sen!2sin!4v1713511630159!5m2!1sen!2sin",
    google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
    created_at: "2024-03-27T11:00:00",
    updated_at: "2024-03-27T11:00:00",
  },
  itineraries: [
    {
      id: 3,
      title: "Winter Activities",
      slug: "winter-activities",
      description: "List of winter-themed activities",
      user_id: 789,
      is_featured: false,
      created_at: "2024-03-28T12:00:00",
      updated_at: "2024-03-28T12:00:00",
    },
    {
      id: 2,
      title: "Summer Activities",
      slug: "summer-activities",
      description: "List of summer-themed activities",
      user_id: 789,
      is_featured: false,
      created_at: "2024-03-28T12:00:00",
      updated_at: "2024-03-28T12:00:00",
    },
  ],
  contact_information: {
    first_name: "Ayushi",
    last_name: "Kamthan",
    slug: "ayushi_kamthan",
    email: "kamthanayushi102@gmail.com",
    phone: "+91 1234567890",
  },
  ratings: {
    user: {
      crowd_score: 1,
      vibe_score: 1,
      venue_score: 1,
      food_score: 4,
      overall_score: 4.75,
    },
    tjj: {
      crowd_score: 2,
      vibe_score: 3,
      venue_score: 3,
      food_score: 5,
      overall_score: 4,
    },
  },
};
export const pastDetails = {
  id: 3,
  title: "Winter Wonderland",
  slug: "winter-wonderland",
  event_categories: ["Music", "Food & Drink", "Festival"],
  event_description:
    '<div className="event-description"><h1>CARNIVAL SATURDAY 7PM IN KINGSTON JAMAICA 🇯🇲!</h1><p>Absolutely FUN COOLER FETE with some of the most powerful West Indian DJs on Planet Earth!</p><img src="https://via.placeholder.com/400" alt="DJ booth at carnival" /><p>Our Fully Loaded Premium Bar will Accept Cash and Credit Cards.</p><p>Commemorative T-Shirts, Snap-Backs and discounted pre-sold bottles are also available upon checkout.</p><img src="https://via.placeholder.com/400" alt="Carnival merchandise" /><p><em>Don\'t push your limits. Drink Responsibly</em></p><p>Join us as we set the stage unlike you have ever seen before powered by planet earth\'s most powerful West Indian DJs.</p><img src="https://via.placeholder.com/400" alt="Crowd at carnival" /><p>The Revolution will be televised!</p><h2>Electronica Costume</h2><p>The Electronica costume is a vibrant and electrifying ensemble inspired by the world\'s greatest electronic music festivals and their daring fashion trends.</p><img src="https://via.placeholder.com/400" alt="Electronica costume" /><p>This high-energy costume is a bold and dynamic expression of the music, lights, and pulsating energy of these festivals and ravers worldwide.</p><p>It incorporates bright colors like neon yellow, hot pink, and royal blue to make a powerful visual statement.</p><img src="https://via.placeholder.com/400" alt="Colorful costume" /><p>Did we mention we are a private section this year meaning WE control the quality of your costume and your experience.</p><p>We ensure you get the most luxurious well designed and durable costumes.</p><p>We have concierge packages we will be sharing soon!</p><img src="https://via.placeholder.com/400" alt="Luxurious costume" /><p>Let’s do carnival the Five Star Way!</p></div>',
  description:
    "CARNIVAL SATURDAY 7PM IN KINGSTON JAMAICA 🇯🇲! Absolutely FUN COOLER FETE with some of the most powerful West Indian DJs on Planet Earth! Our Fully Loaded Premium Bar will Accept Cash and Credit Cards. Commemorative T-Shirts, Snap-Backs and discounted pre-sold bottles are also available upon checkout. *Don't push your limits. Drink Responsibly* Join us as we set the stage unlike you have ever seen before powered by planet earth's most powerful West Indian DJs. The Revolution will be televised! The Electronica costume is a vibrant and electrifying ensemble inspired by the world's greatest electronic music festivals and their daring fashion trends. This high-energy costume is a bold and dynamic expression of the music, lights, and pulsating energy of these festivals and ravers worldwide. It incorporates bright colors like neon yellow, hot pink, and royal blue to make a powerful visual statement. Did we mention we are a private section this year meaning WE control the quality of your costume and your experience. We ensure you get the most luxurious well designed and durable costumes. We have concierge packages we will be sharing soon! Let’s do carnival the Five Star Way!",
  poster_url:
    "https://www.trinijunglejuice.com/photos/2024/trinidad/doubles-and-bubbles-breakfast-cruise-2024/images/doubles-and-bubbles-breakfast-cruise-2024-126.jpg",
  start_datetime: "2023-12-15T10:00:00",
  end_datetime: "2023-12-19T20:00:00",
  is_whole_day_event: false,
  is_registration_required: true,
  registration_url: "https://example.com/register/winter-wonderland",
  cost_per_person: 15.99,
  capacity: 300,
  currently_registered_count: 10,
  is_recurring: false,
  tags: [
    { id: 1, name: "newsletter", slug: "newsletter" },
    { id: 2, name: "June 2024", slug: "june_2024" },
    { id: 3, name: "updates", slug: "updates" },
    { id: 4, name: "events", slug: "events" },
    { id: 5, name: "articles", slug: "articles" },
  ],
  carnival: {
    id: 7,
    name: "Frosty Fest",
  },
  is_featured: true,
  featured_until_date: "2024-12-10",
  is_published: true,
  is_rating_allowed: true,
  tjj_rating: 97,
  user_rating: 90,
  created_at: "2024-03-27T11:00:00",
  updated_at: "2024-03-27T11:00:00",
  subscribed: true,
  location: {
    id: 3,
    latitude: 51.5074,
    longitude: -0.1278,
    address: "789 Elm Street",
    slug: "elm-street",
    city: "Kolkata",
    country: "India",
    state: "West Bengal",
    region: "Asia",
    google_map_embed_url:
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2483.317002859837!2d-0.13037492337992762!3d51.50739997181331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTHCsDMwJzI2LjYiTiAwwrAwNyc0MC4xIlc!5e0!3m2!1sen!2sin!4v1713511630159!5m2!1sen!2sin",
    google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
    created_at: "2024-03-27T11:00:00",
    updated_at: "2024-03-27T11:00:00",
  },
  itineraries: [
    {
      id: 3,
      title: "Winter Activities",
      slug: "winter-activities",
      description: "List of winter-themed activities",
      user_id: 789,
      is_featured: false,
      created_at: "2024-03-28T12:00:00",
      updated_at: "2024-03-28T12:00:00",
    },
    {
      id: 2,
      title: "Summer Activities",
      slug: "summer-activities",
      description: "List of summer-themed activities",
      user_id: 789,
      is_featured: false,
      created_at: "2024-03-28T12:00:00",
      updated_at: "2024-03-28T12:00:00",
    },
  ],
  contact_information: {
    first_name: "Ayushi",
    last_name: "Kamthan",
    slug: "ayushi_kamthan",
    email: "kamthanayushi102@gmail.com",
    phone: "+91 1234567890",
  },
  ratings: {
    user: {
      crowd_score: 1,
      vibe_score: 1,
      venue_score: 1,
      food_score: 4,
      overall_score: 4.75,
    },
    tjj: {
      crowd_score: 2,
      vibe_score: 3,
      venue_score: 3,
      food_score: 5,
      overall_score: 4,
    },
  },
};

export const featuredEvents = {
  total: 50,
  per_page: 15,
  current_page: 1,
  last_page: 4,
  first_page_url: "https://api.trinijunglejuice.com?page=1",
  last_page_url: "https://api.trinijunglejuice.com?page=4",
  next_page_url: "https://api.trinijunglejuice.com?page=2",
  path: "https://api.trinijunglejuice.com",
  from: 1,
  to: 15,
  events: [
    {
      id: 1,
      title: "Spring Festival",
      slug: "spring-festival",
      description:
        "Join us for a day filled with fun activities, food, and music!",
      poster_url:
        "https://4f14f3f2b2e5fd1c43c0-19fdc616b209d38d8f477bc6e666e66f.ssl.cf1.rackcdn.com/events/xperience-272369-b5pVQY9eJf.jpg",
      start_datetime: "2024-04-15T10:00:00",
      end_datetime: "2024-04-19T18:00:00",
      is_whole_day_event: false,
      is_registration_required: true,
      registration_url: "https://example.com/register/spring-festival",
      cost_per_person: 10.99,
      capacity: 500,
      is_recurring: false,
      tags: [
        { id: 1, name: "newsletter", slug: "newsletter" },
        { id: 2, name: "June 2024", slug: "june_2024" },
        { id: 3, name: "updates", slug: "updates" },
        { id: 4, name: "events", slug: "events" },
        { id: 5, name: "articles", slug: "articles" },
      ],
      carnival: {
        id: 3,
        name: "Spring Carnival",
      },
      is_featured: true,
      featured_until_date: "2024-04-10",
      is_published: true,
      is_rating_allowed: true,
      tjj_rating: 4.5,
      user_rating: 0,
      created_at: "2024-03-25T15:30:00",
      updated_at: "2024-03-25T15:30:00",
      location: {
        id: 1,
        latitude: 40.7128,
        longitude: -74.006,
        address: "123 Main Street",
        slug: "main-street",
        city: "New York",
        state: "NY",
        country: "USA",
        region: "North America",
        google_map_url: "https://maps.google.com/maps?q=40.7128,-74.006",
        created_at: "2024-03-25T15:30:00",
        updated_at: "2024-03-25T15:30:00",
      },
      itineraries: {
        id: 1,
        title: "Day Schedule",
        slug: "day-schedule",
        description: "Detailed itinerary for the day",
        user_id: 123,
        is_featured: false,
        created_at: "2024-03-28T10:00:00",
        updated_at: "2024-03-28T10:00:00",
      },
    },
    {
      id: 1,
      title: "Spring Festival",
      slug: "spring-festival",
      description:
        "Join us for a day filled with fun activities, food, and music!",
      poster_url:
        "https://4f14f3f2b2e5fd1c43c0-19fdc616b209d38d8f477bc6e666e66f.ssl.cf1.rackcdn.com/events/xperience-272369-b5pVQY9eJf.jpg",
      start_datetime: "2024-04-15T10:00:00",
      end_datetime: "2024-04-15T18:00:00",
      is_whole_day_event: false,
      is_registration_required: true,
      registration_url: "https://example.com/register/spring-festival",
      cost_per_person: 10.99,
      capacity: 500,
      is_recurring: false,
      tags: [
        { id: 1, name: "newsletter", slug: "newsletter" },
        { id: 2, name: "June 2024", slug: "june_2024" },
        { id: 3, name: "updates", slug: "updates" },
        { id: 4, name: "events", slug: "events" },
        { id: 5, name: "articles", slug: "articles" },
      ],
      carnival: {
        id: 3,
        name: "Spring Carnival",
      },
      is_featured: true,
      featured_until_date: "2024-04-10",
      is_published: true,
      is_rating_allowed: true,
      tjj_rating: 4.5,
      user_rating: 0,
      created_at: "2024-03-25T15:30:00",
      updated_at: "2024-03-25T15:30:00",
      location: {
        id: 1,
        latitude: 40.7128,
        longitude: -74.006,
        address: "123 Main Street",
        slug: "main-street",
        city: "New York",
        state: "NY",
        country: "USA",
        region: "North America",
        google_map_url: "https://maps.google.com/maps?q=40.7128,-74.006",
        created_at: "2024-03-25T15:30:00",
        updated_at: "2024-03-25T15:30:00",
      },
      itineraries: {
        id: 1,
        title: "Day Schedule",
        slug: "day-schedule",
        description: "Detailed itinerary for the day",
        user_id: 123,
        is_featured: false,
        created_at: "2024-03-28T10:00:00",
        updated_at: "2024-03-28T10:00:00",
      },
    },
    {
      id: 1,
      title: "Spring Festival",
      slug: "spring-festival",
      description:
        "Join us for a day filled with fun activities, food, and music!",
      poster_url:
        "https://4f14f3f2b2e5fd1c43c0-19fdc616b209d38d8f477bc6e666e66f.ssl.cf1.rackcdn.com/events/xperience-272369-b5pVQY9eJf.jpg",
      start_datetime: "2024-04-15T10:00:00",
      end_datetime: "2024-04-15T18:00:00",
      is_whole_day_event: false,
      is_registration_required: true,
      registration_url: "https://example.com/register/spring-festival",
      cost_per_person: 10.99,
      capacity: 500,
      is_recurring: false,
      tags: [
        { id: 1, name: "newsletter", slug: "newsletter" },
        { id: 2, name: "June 2024", slug: "june_2024" },
        { id: 3, name: "updates", slug: "updates" },
        { id: 4, name: "events", slug: "events" },
        { id: 5, name: "articles", slug: "articles" },
      ],
      carnival: {
        id: 3,
        name: "Spring Carnival",
      },
      is_featured: true,
      featured_until_date: "2024-04-10",
      is_published: true,
      is_rating_allowed: true,
      tjj_rating: 4.5,
      user_rating: 0,
      created_at: "2024-03-25T15:30:00",
      updated_at: "2024-03-25T15:30:00",
      location: {
        id: 1,
        latitude: 40.7128,
        longitude: -74.006,
        address: "123 Main Street",
        slug: "main-street",
        city: "New York",
        state: "NY",
        country: "USA",
        region: "North America",
        google_map_url: "https://maps.google.com/maps?q=40.7128,-74.006",
        created_at: "2024-03-25T15:30:00",
        updated_at: "2024-03-25T15:30:00",
      },
      itineraries: {
        id: 1,
        title: "Day Schedule",
        slug: "day-schedule",
        description: "Detailed itinerary for the day",
        user_id: 123,
        is_featured: false,
        created_at: "2024-03-28T10:00:00",
        updated_at: "2024-03-28T10:00:00",
      },
    },

    // {
    //   eventName: "Doubles & Bubbles Cooler Cruise",
    //   imageSrc:
    //     "https://4f14f3f2b2e5fd1c43c0-19fdc616b209d38d8f477bc6e666e66f.ssl.cf1.rackcdn.com/events/xperience-272369-b5pVQY9eJf.jpg",
    //   location: "Cocorite, Trinidad and Tobago",
    // },
    // {
    //   eventName: "Doubles & Bubbles Cooler Cruise",
    //   imageSrc:
    //     "http://www.trinijunglejuice.com/events/2024/pcd-post-carnival-depression-nyc-2024.jpg",
    //   location: "Cocorite, Trinidad and Tobago",
    // },
    // {
    //   eventName: "Doubles & Bubbles Cooler Cruise",
    //   imageSrc:
    //     "http://www.trinijunglejuice.com/events/2024/frazzled-back-in-times-mar23-2024.jpg",
    //   location: "Cocorite, Trinidad and Tobago",
    // },
  ],
};

export const top = [
  "DJ Party",
  "Hip-Hop",
  "Pop",
  "Reggae/Dancehall",
  "RnB",
  "Soca/Calypso",
  "Afrobeat",
  "All Inclusive (Food & Drink)",
  "Art/Fashion",
  "Band/Artiste",
  "Performances",
  "Band/Section",
  "Launch	Beach Party",
  "Breakfast Party",
  "Brunch",
  "Bus Ride",
  "BYOB (Bring Your Own Bottle)",
  "Carnival",
  "Charity/Fundraiser",
  "Chutney",
  "Comedy",
  "Concert",
  "DJ Party",
  "Hip-Hop",
  "Pop",
  "Reggae/Dancehall",
  "RnB",
  "Soca/Calypso",
  "Afrobeat",
  "All Inclusive (Food & Drink)",
  "Art/Fashion",
  "Band/Artiste",
  "Performances",
  "Band/Section",
  "Launch	Beach Party",
  "Breakfast Party",
  "Brunch",
  "Bus Ride",
  "BYOB (Bring Your Own Bottle)",
  "Carnival",
  "Charity/Fundraiser",
  "Chutney",
  "Comedy",
  "Concert",
  "Cooler Fete",
];

export const topPromoters = [
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Antigua and Barbuda",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Barbados",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Cayman Islands",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Guadeloupe ",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Grenada ",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Jamaica",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Martinique",
  },
  {
    icon: null,
    name: "St. Lucia ",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "St. Vincent",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Trinidad and Toabgo ",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Turks and Caicos Islands (TCI)",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Antigua and Barbuda",
  },
  {
    icon: null,
    name: "Barbados",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Cayman Islands",
  },
  {
    icon: null,
    name: "Guadeloupe ",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Grenada ",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Jamaica",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Martinique",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "St. Lucia ",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "St. Vincent",
  },
  {
    icon: null,
    name: "Trinidad and Toabgo ",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Turks and Caicos Islands (TCI)",
  },
  {
    icon: null,
    name: "Antigua and Barbuda",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Barbados",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Cayman Islands",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Guadeloupe ",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Grenada ",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Jamaica",
  },
  {
    icon: null,
    name: "Martinique",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "St. Lucia ",
  },
  {
    icon: null,
    name: "St. Vincent",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Trinidad and Toabgo ",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Turks and Caicos Islands (TCI)",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Antigua and Barbuda",
  },
  {
    icon: null,
    name: "Barbados",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Cayman Islands",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Guadeloupe ",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Grenada ",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Jamaica",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Martinique",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "St. Lucia ",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "St. Vincent",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Trinidad and Toabgo ",
  },
  {
    icon: null,
    name: "Turks and Caicos Islands (TCI)",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Antigua and Barbuda",
  },
  {
    icon: null,
    name: "Barbados",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Cayman Islands",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Guadeloupe ",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Grenada ",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Jamaica",
  },
  {
    icon: null,
    name: "Martinique",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "St. Lucia ",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "St. Vincent",
  },
  {
    icon: "https://i.pinimg.com/originals/5c/b1/8e/5cb18e070f8001f997e9570821a32394.jpg",
    name: "Trinidad and Toabgo ",
  },
  {
    icon: "https://wallpapercave.com/wp/wp6939436.jpg",
    name: "Turks and Caicos Islands (TCI)",
  },
];

export const gallery = {
  galleries: [
    {
      id: 1,
      name: "Nature Hike",
      slug: "nature-hike",
      description: "Explore the wonders of nature on this guided hike.",
      cover_photo_url:
        "http://www.trinijunglejuice.com/photos/2022/jamaica/duck-work-jamaica-2022/images/duck-work-jamaica-2022-095.jpg",
      view_count: 150,
      created_at: "2024-03-27T09:00:00",
      updated_at: "2024-03-27T09:00:00",
      location: {
        id: 3,
        latitude: 51.5074,
        longitude: -0.1278,
        address: "789 Elm Street",
        slug: "elm-street",
        city: "London",
        country: "UK",
        region: "Europe",
        google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
        created_at: "2024-03-27T11:00:00",
        updated_at: "2024-03-27T11:00:00",
      },
    },
    {
      id: 1,
      name: "Nature Hike",
      slug: "nature-hike",
      description: "Explore the wonders of nature on this guided hike.",
      cover_photo_url:
        "http://www.trinijunglejuice.com/photos/2023/trinidad/tribe-las-jam-2023-pt1/images/tribe-las-jam-2023-pt1-013.jpg",
      view_count: 150,
      created_at: "2024-03-27T09:00:00",
      updated_at: "2024-03-27T09:00:00",
      location: {
        id: 3,
        latitude: 51.5074,
        longitude: -0.1278,
        address: "789 Elm Street",
        slug: "elm-street",
        city: "London",
        country: "UK",
        region: "Europe",
        google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
        created_at: "2024-03-27T11:00:00",
        updated_at: "2024-03-27T11:00:00",
      },
    },
    {
      id: 1,
      name: "Nature Hike",
      slug: "nature-hike",
      description: "Explore the wonders of nature on this guided hike.",
      cover_photo_url:
        "http://www.trinijunglejuice.com/photos/2023/trinidad/tribe-las-jam-2023-pt2/images/tribe-las-jam-2023-pt2-017.jpg",
      view_count: 150,
      created_at: "2024-03-27T09:00:00",
      updated_at: "2024-03-27T09:00:00",
      location: {
        id: 3,
        latitude: 51.5074,
        longitude: -0.1278,
        address: "789 Elm Street",
        slug: "elm-street",
        city: "London",
        country: "UK",
        region: "Europe",
        google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
        created_at: "2024-03-27T11:00:00",
        updated_at: "2024-03-27T11:00:00",
      },
    },
    {
      id: 1,
      name: "Nature Hike",
      slug: "nature-hike",
      description: "Explore the wonders of nature on this guided hike.",
      cover_photo_url:
        "http://www.trinijunglejuice.com/photos/2022/jamaica/duck-work-jamaica-2022/images/duck-work-jamaica-2022-017.jpg",
      view_count: 150,
      created_at: "2024-03-27T09:00:00",
      updated_at: "2024-03-27T09:00:00",
      location: {
        id: 3,
        latitude: 51.5074,
        longitude: -0.1278,
        address: "789 Elm Street",
        slug: "elm-street",
        city: "London",
        country: "UK",
        region: "Europe",
        google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
        created_at: "2024-03-27T11:00:00",
        updated_at: "2024-03-27T11:00:00",
      },
    },
    {
      id: 1,
      name: "Nature Hike",
      slug: "nature-hike",
      description: "Explore the wonders of nature on this guided hike.",
      cover_photo_url:
        "http://www.trinijunglejuice.com/photos/2022/jamaica/duck-work-jamaica-2022/images/duck-work-jamaica-2022-021.jpg",
      view_count: 150,
      created_at: "2024-03-27T09:00:00",
      updated_at: "2024-03-27T09:00:00",
      location: {
        id: 3,
        latitude: 51.5074,
        longitude: -0.1278,
        address: "789 Elm Street",
        slug: "elm-street",
        city: "London",
        country: "UK",
        region: "Europe",
        google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
        created_at: "2024-03-27T11:00:00",
        updated_at: "2024-03-27T11:00:00",
      },
    },
    {
      id: 1,
      name: "Nature Hike",
      slug: "nature-hike",
      description: "Explore the wonders of nature on this guided hike.",
      cover_photo_url:
        "http://www.trinijunglejuice.com/photos/2022/jamaica/duck-work-jamaica-2022/images/duck-work-jamaica-2022-037.jpg",
      view_count: 150,
      created_at: "2024-03-27T09:00:00",
      updated_at: "2024-03-27T09:00:00",
      location: {
        id: 3,
        latitude: 51.5074,
        longitude: -0.1278,
        address: "789 Elm Street",
        slug: "elm-street",
        city: "London",
        country: "UK",
        region: "Europe",
        google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
        created_at: "2024-03-27T11:00:00",
        updated_at: "2024-03-27T11:00:00",
      },
    },
    {
      id: 1,
      name: "Nature Hike",
      slug: "nature-hike",
      description: "Explore the wonders of nature on this guided hike.",
      cover_photo_url:
        "http://www.trinijunglejuice.com/photos/2022/jamaica/duck-work-jamaica-2022/images/duck-work-jamaica-2022-055.jpg",
      view_count: 150,
      created_at: "2024-03-27T09:00:00",
      updated_at: "2024-03-27T09:00:00",
      location: {
        id: 3,
        latitude: 51.5074,
        longitude: -0.1278,
        address: "789 Elm Street",
        slug: "elm-street",
        city: "London",
        country: "UK",
        region: "Europe",
        google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
        created_at: "2024-03-27T11:00:00",
        updated_at: "2024-03-27T11:00:00",
      },
    },
    {
      id: 1,
      name: "Nature Hike",
      slug: "nature-hike",
      description: "Explore the wonders of nature on this guided hike.",
      cover_photo_url:
        "http://www.trinijunglejuice.com/photos/2022/jamaica/duck-work-jamaica-2022/images/duck-work-jamaica-2022-016.jpg",
      view_count: 150,
      created_at: "2024-03-27T09:00:00",
      updated_at: "2024-03-27T09:00:00",
      location: {
        id: 3,
        latitude: 51.5074,
        longitude: -0.1278,
        address: "789 Elm Street",
        slug: "elm-street",
        city: "London",
        country: "UK",
        region: "Europe",
        google_map_url: "https://maps.google.com/maps?q=51.5074,-0.1278",
        created_at: "2024-03-27T11:00:00",
        updated_at: "2024-03-27T11:00:00",
      },
    },
  ],
};

export const ratings = [
  {
    name: "Vibe",
    tjjRating: 5,
    userRating: 4,
  },
  {
    name: "Venue",
    tjjRating: 5,
    userRating: 4,
  },
  {
    name: "Crowd",
    tjjRating: 5,
    userRating: 4,
  },
  {
    name: "Food",
    tjjRating: 5,
    userRating: 4,
  },
];
export const videos = {
  event_videos: [
    {
      id: 1,
      title: "Exploring the Wilderness",
      slug: "exploring-the-wilderness",
      description: "Join us on an adventure through untamed landscapes.",
      media_type: "video/mp4",
      media_url: "https://www.youtube.com/watch?v=3jAWmzcuKAw",
      cover_image_url: require("../../../assets/images/temp/video-1.jpg"),
      watch_count: 1000,
      likes_count: 1000,
      last_watched_at: "2024-03-26T15:30:00",
      is_featured: true,
      featured_until_date: "2024-04-10",
      is_published: true,
      is_comments_allowed: true,
      created_at: "2024-03-26T12:00:00",
      updated_at: "2024-03-26T12:00:00",
    },
    {
      id: 2,
      title: "Cooking Masterclass: Italian Cuisine",
      slug: "cooking-masterclass-italian-cuisine",
      description:
        "Learn the secrets behind crafting authentic Italian dishes.",
      media_type: "video/mp4",
      media_url: "https://example.com/videos/cooking.mp4",
      cover_image_url: require("../../../assets/images/temp/video-2.jpg"),
      watch_count: 500,
      likes_count: 1000,
      last_watched_at: "2024-03-25T18:45:00",
      is_featured: false,
      is_published: true,
      is_comments_allowed: true,
      created_at: "2024-03-25T10:00:00",
      updated_at: "2024-03-25T10:00:00",
    },
    {
      id: 3,
      title: "Yoga for Beginners",
      slug: "yoga-for-beginners",
      description:
        "Start your journey to inner peace with these beginner-friendly yoga exercises.",
      media_type: "video/mp4",
      media_url: "https://example.com/videos/yoga.mp4",
      cover_image_url: require("../../../assets/images/temp/video-3.jpg"),
      watch_count: 1500,
      likes_count: 1000,
      last_watched_at: "2024-03-24T20:00:00",
      is_featured: true,
      featured_until_date: "2024-04-05",
      is_published: true,
      is_comments_allowed: true,
      created_at: "2024-03-24T14:00:00",
      updated_at: "2024-03-24T14:00:00",
    },
    {
      id: 4,
      title: "Travel Vlog: Paris Adventure",
      slug: "travel-vlog-paris-adventure",
      description:
        "Experience the charm of Paris through the eyes of a traveler.",
      media_type: "video/mp4",
      media_url: "https://example.com/videos/paris.mp4",
      cover_image_url: require("../../../assets/images/temp/video-4.jpg"),
      watch_count: 2000,
      likes_count: 1000,
      last_watched_at: "2024-03-23T11:30:00",
      is_featured: false,
      is_published: true,
      is_comments_allowed: true,
      created_at: "2024-03-23T08:00:00",
      updated_at: "2024-03-23T08:00:00",
    },
    {
      id: 5,
      title: "Gardening Tips for Beginners",
      slug: "gardening-tips-for-beginners",
      description:
        "Discover essential gardening techniques to cultivate a thriving garden.",
      media_type: "video/mp4",
      media_url: "https://example.com/videos/gardening.mp4",
      cover_image_url: require("../../../assets/images/temp/video-5.jpg"),
      watch_count: 800,
      likes_count: 1000,
      last_watched_at: "2024-03-22T14:20:00",
      is_featured: true,
      featured_until_date: "2024-04-08",
      is_published: true,
      is_comments_allowed: true,
      created_at: "2024-03-22T10:30:00",
      updated_at: "2024-03-22T10:30:00",
    },
    {
      id: 6,
      title: "Gardening Tips for Beginners",
      slug: "gardening-tips-for-beginners",
      description:
        "Discover essential gardening techniques to cultivate a thriving garden.",
      media_type: "video/mp4",
      media_url: "https://example.com/videos/gardening.mp4",
      cover_image_url: require("../../../assets/images/temp/gallery-4.jpg"),
      watch_count: 800,
      likes_count: 1000,
      last_watched_at: "2024-03-22T14:20:00",
      is_featured: true,
      featured_until_date: "2024-04-08",
      is_published: true,
      is_comments_allowed: true,
      created_at: "2024-03-22T10:30:00",
      updated_at: "2024-03-22T10:30:00",
    },
    {
      id: 7,
      title: "Welcome To New York",
      slug: "welcome-to-new-york",
      description:
        "Discover essential gardening techniques to cultivate a thriving garden.",
      media_type: "video/mp4",
      media_url: "https://example.com/videos/gardening.mp4",
      cover_image_url: require("../../../assets/images/temp/gallery-11.jpg"),
      watch_count: 800,
      likes_count: 1000,
      last_watched_at: "2024-03-22T14:20:00",
      is_featured: true,
      featured_until_date: "2024-04-08",
      is_published: true,
      is_comments_allowed: true,
      created_at: "2024-03-22T10:30:00",
      updated_at: "2024-03-22T10:30:00",
    },
    {
      id: 8,
      title: "Atlanta Carnival Full Coverage",
      slug: "atlanta-carnival-full-coverage",
      description:
        "Discover essential gardening techniques to cultivate a thriving garden.",
      media_type: "video/mp4",
      media_url: "https://example.com/videos/gardening.mp4",
      cover_image_url: require("../../../assets/images/temp/gallery-8.jpg"),
      watch_count: 800,
      likes_count: 1000,
      last_watched_at: "2024-03-22T14:20:00",
      is_featured: true,
      featured_until_date: "2024-04-08",
      is_published: true,
      is_comments_allowed: true,
      created_at: "2024-03-22T10:30:00",
      updated_at: "2024-03-22T10:30:00",
    },
  ],
};

export const itinerary = [
  {
    title: "For You",
    subTitle: "Created 16 Jan 2024",
  },
  {
    title: "Trending Events",
    subTitle: "Created 16 Jan 2024",
  },
  {
    title: "Upcoming Events",
    subTitle: "Created 16 Jan 2024",
  },
  {
    title: "Favourites",
    subTitle: "Created 16 Jan 2024",
  },
  {
    title: "Trending Events",
    subTitle: "Created 16 Jan 2024",
  },
  {
    title: "Upcoming Events",
    subTitle: "Created 16 Jan 2024",
  },
  {
    title: "Favourites",
    subTitle: "Created 16 Jan 2024",
  },
];
