import React from "react";
import { Chip as MUIChip } from "@mui/material";
import "./Chip.css";
import { FontSizes } from "../../config";

export default function Chip({
  label = "",
  textColor = "",
  backgroundColor = "",
  size = "md",
  handleClick = undefined,
  handleDelete = undefined,
  hoverBackgroundColor = "",
  fontSize = null,
}) {
  return (
    <MUIChip
      sx={{
        color: textColor !== "" ? textColor : "#fff",
        backgroundColor: backgroundColor !== "" ? backgroundColor : "#2B2B2B",
        fontSize: fontSize ?? FontSizes.text,
        fontFamily: "Inter-Regular",
        maxWidth: 200,
        cursor: handleClick !== null ? "pointer" : "auto",
        transition:
          "color 0.25s ease-in-out, background-color 0.35s ease-in-out",
        "&:hover": {
          backgroundColor:
            handleClick !== null
              ? hoverBackgroundColor !== ""
                ? hoverBackgroundColor
                : "red"
              : "",
        },
      }}
      onClick={handleClick}
      onDelete={handleDelete}
      size={size === "sm" ? "small" : "medium"}
      label={label}
    />
  );
}
