import DynamicContentInjector from "../DynamicContentInjector/DynamicContentInjector";
import AdsCss from "./Ads.module.css"

export const insertInterstitialAds = (htmlString, interstitialAd,classes) => {
  // Parse the HTML into DOM elements
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Grab all child nodes
  const bodyChildren = Array.from(doc.body.childNodes);

  // Create an array of JSX elements
  const updatedContent = [];

  let paragraphCount = 0;

  bodyChildren.forEach((node, index) => {
    // Handle different node types
    if (node.nodeName === "P") {
      // Preserve paragraph with existing styles and classes
      updatedContent.push(
        <p
          dangerouslySetInnerHTML={{ __html: node.innerHTML }}
          key={`p-${index}`}
          className={node.getAttribute("class")}
          style={node.getAttribute("style")}
        />
      );
      paragraphCount++;

      // Insert an ad after every 4th paragraph
      if (
        paragraphCount % 4 === 0 &&
        paragraphCount / 4 <= interstitialAd?.length
      ) {
        const at = paragraphCount / 4 - 1;

        const ad = interstitialAd[at]?.banner_image_url ? (
          <img
            className={AdsCss.interstitialAd}
            src={interstitialAd[at]?.banner_image_url}
            alt="Ad"
            key={`ad-img-${at}`}
          />
        ) : (
          <DynamicContentInjector
            key={`ad-content-${at}`}
            className={AdsCss.interstitialAd}
            htmlContent={interstitialAd[at]?.content}
          />
        );

        // Push the ad (either img or content injector) into the array
        updatedContent.push(ad);
      }
    } else if (node.nodeName === "FIGURE") {
      updatedContent.push(
        <figure
          dangerouslySetInnerHTML={{ __html: node.innerHTML }}
          key={`figure-${index}`}
          className={node.getAttribute("class")}
        />
      );
    } else if (node.nodeName === "A") {
      updatedContent.push(
        <a
          href={node.getAttribute("href")}
          target="_blank"
          className={node.getAttribute("class")}
          dangerouslySetInnerHTML={{ __html: node.innerHTML }}
          key={`a-${index}`}
        />
      );
    } else if (node.nodeName === "IMG") {
      updatedContent.push(
        <img
          src={node.getAttribute("src")}
          alt={node.getAttribute("alt")}
          className={node.getAttribute("class")}
          style={node.getAttribute("style")}
          key={`img-${index}`}
        />
      );
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      // For any other element nodes, handle generically
      updatedContent.push(
        <div
          dangerouslySetInnerHTML={{ __html: node.outerHTML }}
          key={`element-${index}`}
          className={node.getAttribute("class")}
          style={node.getAttribute("style")}
        />
      );
    }
  });

  // Wrap everything in a parent div to scope the styles and avoid collisions
  return { updatedContent, isLoading: false };
};
