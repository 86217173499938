import * as React from "react";

import { addToolbarButton, createModule } from "yet-another-react-lightbox";
import { LikesButton } from "./LikesButton.tsx";
import { LikesContextProvider, useLikes } from "./LikesContext.tsx";
import { resolveLikesProps } from "./props.ts";

const PLUGIN_LIKES = "likes";

/** Likes plugin */
export function Likes({ augment, addModule }) {
  // const { visible } = useLikes();
  // const [likeCount, setLikeCount] = React.useState(115);
  const [likedState, setLikedState] = React.useState({})
  augment(
    ({
      likes: likesProps,
      render: { slideFooter: renderFooter, ...restRender },
      toolbar,
      ...restProps
    }) => {
      const likes = resolveLikesProps(likesProps);
      return {
        render: {
          slideFooter: ({ slide }) => <>{renderFooter?.({ slide })}</>,
          ...restRender,
        },
        toolbar: addToolbarButton(
          toolbar,
          PLUGIN_LIKES,
          likes.showToggle === true ? (
            <LikesButton
              onClickHandler={likes.onClick}
              isLoggedIn={likes.isLoggedIn}
              likedState={likedState}
              setLikedState={setLikedState}
            />
          ) : null
        ),
        likes,
        ...restProps,
      };
    }
  );

  addModule(createModule(PLUGIN_LIKES, LikesContextProvider));
}
