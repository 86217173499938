import React, { useContext, useState, useEffect } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import GenreList from "../../elements/Flatlist/GenreList";
import { Context } from "../../Context";
import MusicCss from "./MusicHome.module.css";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import MetaTags from "../../MetaTags";
import GenreCardLoader from "../../elements/skeletonLoaders/GenreCardLoader";
import ViewMore from "../../elements/view-more/ViewMore";
import TextField from "../../components/textfield/TextField";
import { useSearchParams } from "react-router-dom";
import NoData from "../../elements/no-data/NoData";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import ReactGA from "react-ga4";

export default function Genres() {
  const { setPageTitle } = useContext(Context);
  const [width, setWidth] = useState(window.innerWidth);
  const [genresList, setGenresList] = useState([]);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");
  const [genresIsLoading, setGenresIsLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const [genresPageNumber, setGenresPageNumber] = useState(1);
  const [params, setParams] = useState({});
  const [genresLastPage, setGenresLastPage] = useState(null);
  const [moreGenresIsLoading, setMoreGenresIsLoading] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/music/genres`,
      title: "Genres Home",
    });
  }, []);

  const handleSortby = (item) => {
    handleSearchParam("sortby", item);
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results`,
      label: `Sort by ${item} on all genres page`,
    });
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results on all genres page`,
      label: `Sorted the results by ${item} on all genres page`,
    });
    setOrderBy(item);
  };
  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  const handleGetData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_GENRES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: genresPageNumber,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
          orderBy: orderBy,
          orderDirection: orderDirection,
        },
      });
      if (res.status === 200) {
        setStatus(res.status)
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setGenresLastPage(res?.data?.last_page);
          if (genresList?.length === 0) {
            setGenresList(res?.data?.data);
          } else {
            setGenresList((prev) => [...prev, ...res?.data?.data]);
          }
        }
        setGenresIsLoading(false);
        if (moreGenresIsLoading) {
          setMoreGenresIsLoading(false);
        }
      }
    } catch (err) {
      if (moreGenresIsLoading) {
        setMoreGenresIsLoading(false);
      }
      setGenresList([]);
      setGenresIsLoading(false);
    }
  };

  useEffect(() => {
    setGenresIsLoading(true);
    setGenresList([]);
    if (genresPageNumber === 1) {
      handleGetData();
    } else {
      setGenresPageNumber(1);
    }
  }, [orderBy, orderDirection]);

  useEffect(() => {
    handleGetData();
  }, [genresPageNumber]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearchParam = (key, value) => {
    setParams((prevParams) => ({
      ...prevParams,
      [key]: value,
    }));
  };

  const handleOrder = (item) => {
    handleSearchParam("orderby", item);
    const order = item === "asc" ? "ascending" : "descending";
    ReactGA.event({
      category: "Order",
      action: `Order by ${order}`,
      label: `Order by ${order} on all genres page`,
    });
    ReactGA.event({
      category: "Order",
      action: `Order by ${order} on all genres page`,
      label: `Ordered the results by ${order} on all genres page`,
    });
    if (item === "asc") {
      setOrderDirection("asc");
    } else {
      setOrderDirection("desc");
    }
  };

  const options = [
    {
      label: "Name",
      value: "title",
    },
    {
      label: "Album Count",
      value: "album_count",
    },
  ];

  useEffect(() => {
    handleGetData();
  }, []);

  useEffect(() => {
    setPageTitle("Genres");
  }, []);

  const loadMoreGenres = () => {
    if (genresLastPage != null && genresPageNumber <= genresLastPage) {
      setMoreGenresIsLoading(true);
      setGenresPageNumber(genresPageNumber + 1);
    }
  };

  function sortBy() {
    return (
      <div className={MusicCss.sortDiv}>
        <div className={MusicCss.sortBy}>
          <TextField
            defaultValue={orderBy}
            type={"select"}
            label={"Sort by"}
            data={options}
            onChange={(item) => handleSortby(item)}
          />
        </div>
        <FaSortAmountUp
          onClick={() => handleOrder("asc")}
          className={
            orderDirection === "asc"
              ? MusicCss.ascDescIcon1
              : MusicCss.ascDescIcon
          }
        />
        <>
          <FaSortAmountDownAlt
            onClick={() => handleOrder("desc")}
            className={
              orderDirection === "desc"
                ? MusicCss.ascDescIcon1
                : MusicCss.ascDescIcon
            }
          />
        </>
      </div>
    );
  }
  useEffect(() => {
    let params = {};

    if (searchParams.get("sortby")) {
      setOrderBy(searchParams.get("sortby"));
      params = {
        ...params,
        orderBy: searchParams.get("sortby"),
      };
    }
  }, [searchParams]);
  return (
    <>
      <NavContainer>
        <MetaTags
          author={"Trini Jungle Juice"}
          title={"Genres"}
          url={window.location.href}
          type={"website"}
          image="../../assets/images/tjj-logo.jpg "
        />
        <div className={MusicCss.flexDrR}>
          <Label label={"Genres"} size="md" />
          {width > 431 && sortBy()}
        </div>
        {width < 431 && sortBy()}
        {width < 431 && <div className={MusicCss.mgb10} />}

        <div>
          {genresIsLoading ? (
            <div className={MusicCss.albumsDiv}>
              {Array.from({ length: 8 }, (_, index) => (
                <div key={index} className={MusicCss.albumsSubDiv}>
                  <GenreCardLoader />
                </div>
              ))}
            </div>
          ) : genresList?.length !== 0 ? (
            <>
              <GenreList genres={genresList} />
              {moreGenresIsLoading && (
                <div className={MusicCss.flexDr} style={{ marginTop: 10 }}>
                  {Array.from({ length: 8 }, (_, index) => (
                    <GenreCardLoader key={index} />
                  ))}
                </div>
              )}
            </>
          ) : (
            <NoData errorCode={status} />
          )}
        </div>
        {genresLastPage != null &&
          genresPageNumber < genresLastPage &&
          !genresIsLoading &&
          !moreGenresIsLoading &&
          genresList?.length !== 0 && (
            <ViewMore loadMore={true} onClick={loadMoreGenres} />
          )}
      </NavContainer>
    </>
  );
}
