import Card from "../../components/card/Card";
import NewsCss from "./Newsletter.module.css";

export function RelatedNewsCard({ item, onClick }) {
  return (
    <Card
      imageProps={{ width: "100%" }}
      imageSrc={item?.featured_image_url || item?.poster_url}
      title={item?.title}
      key={item?.title}
      textAlign={"left"}
      onClick={onClick}
      textColor={"#ff0000"}
      textProps={{ fontWeight: "400", fontFamily: "Inter" }}
      content={<div className={NewsCss.content}>{item.excerpt}</div>}
    />
  );
}
