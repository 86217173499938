import React from "react";
import { Skeleton } from "@mui/material";
import LoaderCss from "./loader.module.css";
import { useState, useEffect } from "react";
export default function MusicCard() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={LoaderCss.mainDiv}>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="rectangular"
        width={width < 431 ? "100%" : 273}
        height={width < 431 ? 180 : 270}
      />
      <Skeleton
        sx={{ bgcolor: "grey.900", marginTop: "5px" }}
        animation="wave"
        width={width < 431 ? 80 : 140}
        variant="text"
      />{" "}
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        width={width < 431 ? 80 : 190}
        variant="text"
      />
    </div>
  );
}
