import React, { useState, useEffect, useContext } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import { Grid, Skeleton } from "@mui/material";
import GalleryCss from "./GalleryHome.module.css";
import GalleryFilter from "./elements/GalleryFilter";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Context } from "../../Context";
import { LocationOn } from "@mui/icons-material";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import StarIcon from "@mui/icons-material/Star";
import Card from "../../components/card/Card";
import { useLocation } from "react-router";
import { LocationExtractor } from "../../elements/location-extractor/LocationExtractor";
import { ExtractShortDate } from "../../elements/date-extractor/ExtractDate";
import { useNavigate, useSearchParams } from "react-router-dom";
import NoData from "../../elements/no-data/NoData";
import TextField from "../../components/textfield/TextField";
import ViewMore from "../../elements/view-more/ViewMore";
import GalleryCardLoader from "../../elements/skeletonLoaders/GalleryCardLoader";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import ReactGA from "react-ga4";
import DynamicContentInjector from "../../elements/DynamicContentInjector/DynamicContentInjector.js";

const options = [
  {
    label: "Date",
    value: "created_at",
  },
  {
    label: "Views",
    value: "view_count",
  },
  {
    label: "Rating",
    value: "user_rating",
  },
  {
    label: "Title",
    value: "name",
  },
];

export default function AllGalleries() {
  const [width, setWidth] = useState(window.innerWidth);
  const [showFilter, setShowFilter] = useState(false);
  const { setPageTitle } = useContext(Context);
  const [galleriesIsLoading, setGalleriesIsLoading] = useState(true);
  const [moreGalleriesIsLoading, setMoreGalleriesIsLoading] = useState(false);
  const [galleries, setGalleries] = useState(null);
  const [orderDirection, setOrderDirection] = useState(null);
  const [galleriesPageNumber, setGalleriesPageNumber] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({});
  const [galleriesLastPage, setGalleriesLastPage] = useState(null);
  const [filters, setFilters] = useState({});
  const [type, setType] = useState("");
  const [title, setTitle] = useState(null);
  const [photographers, setPhotographers] = useState([]);
  const [photographerIsLoading, setPhotographerIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [locationIsLoading, setLocationIsLoading] = useState(true);
  const [bannerAd, setBannerAd] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagsIsLoading, setTagsIsLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [bannerAd2, setBannerAd2] = useState(null);

  let timeoutId;
  //Get the ad for sidebar.
  const handleGetSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "sidebar ad square",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd(res.data);
        }
      }
    } catch (err) {
      setBannerAd(null);
    }
  };

  const handleGetSecondSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "skyscraper ad",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd2(res.data);
        }
      }
    } catch (err) {
      setBannerAd2(null);
    }
  };

  useEffect(() => {
    handleGetSidebarBanner();
    handleGetSecondSidebarBanner();
  }, []);

  const { pathname } = useLocation();

  // This useEffect is being used to get which type of galleries we need to show and what title need to be there like Most Recent, Highly Rated, etc.
  useEffect(() => {
    const u = pathname?.split("/")[2];
    const t = pathname?.split("/")[2]?.replaceAll("-", " ") + " Galleries";
    setTitle(t);
    setPageTitle(t);
    ReactGA.send({
      hitType: "pageview",
      page: `/gallery/${u}`,
      title: t,
    });

    if (u === "highly-rated") {
      setType("highly_rated");
      setOrderBy("user_rating");
    }
    if (u === "this-time-last-year") {
      setType("this_time_last_year");
      setOrderBy("created_at");
    }
    if (u === "top-viewed") {
      setType("top_viewed");
      setOrderBy("view_count");
    }
    if (u === "most-liked") {
      setType("most_liked");
      setOrderBy("created_at");
    }
    if (u === "all") {
      setType("most_recent");
      setOrderBy("created_at");
    }
  }, [pathname]);

  // Appends the query in the current url.
  const handleSearchParam = (key, value) => {
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  function sortBy() {
    return (
      <div className={GalleryCss.sortDiv}>
        <div className={GalleryCss.sortBy}>
          <TextField
            defaultValue={orderBy}
            type={"select"}
            label={"Sort by"}
            data={options}
            onChange={(item) => handleSortby(item)}
          />
        </div>
        <FaSortAmountUp
          onClick={() => handleOrder("asc")}
          className={
            orderDirection === "asc"
              ? GalleryCss.ascDescIcon1
              : GalleryCss.ascDescIcon
          }
        />
        <>
          <FaSortAmountDownAlt
            onClick={() => handleOrder("desc")}
            className={
              orderDirection === "desc"
                ? GalleryCss.ascDescIcon1
                : GalleryCss.ascDescIcon
            }
          />
        </>
      </div>
    );
  }

  const getAllTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_GALLERY_TAGS, {
        headers: {
          Accept: "application/json",
        },
      });
      if (res.status === 200) {
        setTags(res?.data?.data);
        setTagsIsLoading(false);
      }
    } catch (err) {
      setTagsIsLoading(false);
    }
  };

  const getCountry = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_GALLERY_REGIONS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setLocations(res?.data?.data);
        }
        setLocationIsLoading(false);
      }
    } catch (err) {
      setLocationIsLoading(false);
    }
  };

  const getPhotographers = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_PHOTOGRAPHERS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (
          res?.data?.photographers &&
          res?.data?.photographers?.length !== 0
        ) {
          setPhotographers(res?.data?.photographers);
        }
        setPhotographerIsLoading(false);
      }
    } catch (err) {
      setPhotographerIsLoading(false);
    }
  };

  // Automatically set the galleriesIsLoading to false after 30 seconds to show timeout.
  useEffect(() => {
    timeoutId = setTimeout(() => {
      if (galleriesIsLoading) {
        setGalleriesIsLoading(false);
      }
    }, 30000);

    return () => clearTimeout(timeoutId);
  }, [galleriesIsLoading]);

  const handleGetData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_GALLERIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: galleriesPageNumber,
          items: PER_PAGE_COUNT.ALL_GALLERIES,
          type: type,
          orderBy: orderBy,
          orderDirection: orderDirection,
          ...(filters?.region && {
            region: filters?.region,
          }),
          ...(filters?.location && {
            locations: filters?.location,
          }),
          ...(filters?.tag && {
            tags: filters?.tag,
          }),
          ...(filters?.startDate && {
            startDate: filters?.startDate,
          }),
          ...(filters?.endDate && {
            endDate: filters?.endDate,
          }),
          ...(filters?.search && {
            search: filters?.search,
          }),
          ...(filters?.photographer && {
            photographer: filters?.photographer,
          }),
        },
      });

      if (res.status === 200) {
        setStatus(res.status);
        if (
          res?.data &&
          res?.data?.length !== 0 &&
          res?.data?.galleries?.data?.length !== 0
        ) {
          setGalleriesLastPage(res?.data?.galleries?.last_page);
          if (galleries?.length === 0) {
            setGalleries(res?.data?.galleries?.data);
          } else {
            setGalleries((prev) => [...prev, ...res?.data?.galleries?.data]);
          }
        } else {
          setGalleries([]);
          setGalleriesLastPage(null);
        }
        setGalleriesIsLoading(false);
        if (moreGalleriesIsLoading) {
          setMoreGalleriesIsLoading(false);
        }
      } else {
        setGalleries([]);
        setGalleriesLastPage(null);
        setGalleriesIsLoading(false);
        if (moreGalleriesIsLoading) {
          setMoreGalleriesIsLoading(false);
        }
      }
      clearTimeout(timeoutId);
    } catch (err) {
      clearTimeout(timeoutId);
      setGalleries([]);
      setGalleriesLastPage(null);
      setGalleriesIsLoading(false);
      if (moreGalleriesIsLoading) {
        setMoreGalleriesIsLoading(false);
      }
    }
  };

  function updateGalleryPageNumber() {
    if (galleriesLastPage != null && galleriesPageNumber <= galleriesLastPage) {
      setMoreGalleriesIsLoading(true);
      setGalleriesPageNumber(galleriesPageNumber + 1);
    }
  }

  useEffect(() => {
    getPhotographers();
    getCountry();
    getAllTags();
  }, []);

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  useEffect(() => {
    if (galleriesPageNumber !== 1) {
      handleGetData();
    }
  }, [galleriesPageNumber]);

  useEffect(() => {
    setGalleriesPageNumber(1);
    setGalleriesIsLoading(true);
    setGalleries([]);
    if (type !== "" && orderBy && orderDirection) {
      if (searchParams.size === 1 && searchParams.has("sortby")) {
        handleGetData();
      } else if (searchParams.size === 1 && searchParams.has("orderby")) {
        handleGetData();
      } else if (
        searchParams.size === 2 &&
        searchParams.has("orderby") &&
        searchParams.has("sortby")
      ) {
        handleGetData();
      } else if (searchParams.size !== 0) {
        if (Object.keys(filters)?.length !== 0) {
          handleGetData();
        }
      } else {
        handleGetData();
      }
    }
  }, [orderBy, orderDirection, filters]);

  useEffect(() => {
    if (searchParams.size !== 0 && searchParams.get("sortby")) {
      handleSortby(searchParams.get("sortby"));
    }

    if (searchParams.size !== 0 && searchParams.get("orderby")) {
      handleOrder(searchParams.get("orderby"));
    } else {
      setOrderDirection("desc");
    }
  }, [searchParams]);

  const handleSortby = (item) => {
    if (item === "user_rating") {
      setType("highly_rated");
    }
    if (item === "view_count") {
      setType("top_viewed");
    }
    if (item === "created_at") {
      setType("most_recent");
    }
    if (item === "name") {
      setType(null);
    }
    handleSearchParam("sortby", item);
    setOrderBy(item);
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results`,
      label: `Sort by ${item} on all galleries page`,
    });
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results on all galleries page`,
      label: `Sorted the results by ${item} on all galleries page`,
    });
  };

  const handleOrder = (item) => {
    handleSearchParam("orderby", item);
    const order = item === "asc" ? "ascending" : "descending";
    ReactGA.event({
      category: "Order",
      action: `Order by ${order}`,
      label: `Order by ${order} on all galleries page`,
    });
    ReactGA.event({
      category: "Order",
      action: `Order by ${order} on all galleries page`,
      label: `Ordered the results by ${order} on all galleries page`,
    });
    if (item === "asc") {
      setOrderDirection("asc");
    } else {
      setOrderDirection("desc");
    }
  };

  const handleSearch = (item) => {
    ReactGA.event({
      category: "Filter",
      action: "Results filtered",
      label: "Results filtered on all galleries page",
    });
    ReactGA.event({
      category: "Filter",
      action: "Results filtered on all galleries page",
      label: "Results filtered on all galleries page",
    });
    setFilters(item);
    Object.entries(item).map((i) => {
      handleSearchParam(i[0], i[1]);
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShowDd = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    if (width < 900) {
      setShowFilter(false);
    }
  }, [width]);

  const handleResetAll = () => {
    setFilters({});
    const filter = [
      "location",
      "region",
      "tag",
      "photographer",
      "search",
      "startDate",
      "endDate",
    ];
    filter.map((item) => {
      if (searchParams.has(item)) {
        searchParams.delete(item);
        setSearchParams(searchParams);
        delete params[item];
      }
    });
  };

  useEffect(() => {
    if (searchParams.size !== 0) {
      let params = {};
      if (searchParams?.getAll("location")?.length !== 0) {
        params = {
          ...params,
          location: searchParams.getAll("location"),
        };
      }
      if (searchParams?.get("region")) {
        params = {
          ...params,
          region: searchParams.get("region"),
        };
      }
      if (searchParams?.getAll("tag")?.length !== 0) {
        params = {
          ...params,
          tag: searchParams.getAll("tag"),
        };
      }
      if (searchParams?.getAll("photographer")?.length !== 0) {
        params = {
          ...params,
          photographer: searchParams.getAll("photographer"),
        };
      }
      if (searchParams?.get("startDate")) {
        params = {
          ...params,
          startDate: searchParams.get("startDate"),
        };
      }
      if (searchParams?.get("endDate")) {
        params = {
          ...params,
          endDate: searchParams.get("endDate"),
        };
      }
      if (searchParams?.get("search")) {
        params = {
          ...params,
          search: searchParams.get("search"),
        };
      }
      if (
        params &&
        Object.keys(params).length > 0 &&
        Object.keys(filters).length === 0
      ) {
        handleSearch(params);
      }
    }
  }, [searchParams]);

  return (
    <NavContainer>
      <Grid container>
        <Grid item xs={12} sm={12} md={9}>
          <div className={GalleryCss.flexDr}>
            <Label size="lg" label={title} />
            {width > 431 && sortBy()}
            <div className={GalleryCss.iconsSm}>
              <div className={GalleryCss.iconsSmDiv}>
                <img
                  src={require("../../assets/images/filter.png")}
                  className={GalleryCss.filterSm}
                  onClick={() => handleShowDd()}
                />
                {showFilter ? (
                  <FaCaretUp className={GalleryCss.upDown} />
                ) : (
                  <FaCaretDown className={GalleryCss.upDown} />
                )}
              </div>
            </div>
          </div>
          {showFilter &&
            (locationIsLoading || photographerIsLoading || tagsIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginTop: 2 }}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={440}
              />
            ) : (
              <div className={GalleryCss.mgb}>
                <GalleryFilter
                  onSearch={(val) => handleSearch(val)}
                  filter={filters}
                  onReset={handleResetAll}
                  locationArray={locations}
                  photographerArray={photographers}
                  tagsOptions={tags}
                />
              </div>
            ))}
          {bannerAd &&
            (bannerAd?.banner_image_url ? (
              <div className={GalleryCss.smBanner}>
                <img
                  className={GalleryCss.BannerAds}
                  src={bannerAd?.banner_image_url}
                />
              </div>
            ) : (
              bannerAd?.content && (
                <div className={GalleryCss.smBanner}>
                  <DynamicContentInjector
                    className={GalleryCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                </div>
              )
            ))}
          {bannerAd2 &&
            (bannerAd2?.banner_image_url ? (
              <div className={GalleryCss.smBanner}>
                <img
                  className={GalleryCss.BannerAds}
                  src={bannerAd2?.banner_image_url}
                />
              </div>
            ) : (
              bannerAd2?.content && (
                <div className={GalleryCss.smBanner}>
                  <DynamicContentInjector
                    className={GalleryCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                </div>
              )
            ))}
          {width < 431 && sortBy()}
          {width < 431 && <div className={GalleryCss.mgb} />}
          {galleriesIsLoading ? (
            <div className={GalleryCss.flexDrR}>
              {Array.from({ length: 8 }, (_, index) => {
                return (
                  <div key={index}>
                    <GalleryCardLoader />
                  </div>
                );
              })}
            </div>
          ) : galleries && galleries?.length !== 0 ? (
            <>
              <GalleryList gallery={galleries} />
              {moreGalleriesIsLoading && (
                <div className={GalleryCss.flexDrR} style={{ marginTop: 30 }}>
                  {Array.from({ length: 8 }, (_, index) => {
                    return (
                      <div key={index}>
                        <GalleryCardLoader />
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          ) : (
            <NoData errorCode={status} />
          )}
          {!galleriesIsLoading &&
          galleries &&
          !moreGalleriesIsLoading &&
          galleriesLastPage != null &&
          galleriesPageNumber < galleriesLastPage ? (
            <ViewMore loadMore onClick={() => updateGalleryPageNumber()} />
          ) : (
            galleries &&
            galleries?.length !== 0 && <div className={GalleryCss.mgb40} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <div className={GalleryCss.secondContainer}>
            {locationIsLoading || photographerIsLoading || tagsIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginTop: 2 }}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={440}
              />
            ) : (
              <GalleryFilter
                onSearch={(val) => handleSearch(val)}
                filter={filters}
                onReset={handleResetAll}
                locationArray={locations}
                photographerArray={photographers}
                tagsOptions={tags}
              />
            )}
            {bannerAd &&
              (bannerAd?.banner_image_url ? (
                <img
                  className={GalleryCss.BannerAds}
                  src={bannerAd?.banner_image_url}
                />
              ) : (
                bannerAd?.content && (
                  <DynamicContentInjector
                    className={GalleryCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                )
              ))}
            {bannerAd2 &&
              (bannerAd2?.banner_image_url ? (
                <img
                  className={GalleryCss.BannerAds}
                  src={bannerAd2?.banner_image_url}
                />
              ) : (
                bannerAd2?.content && (
                  <DynamicContentInjector
                    className={GalleryCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                )
              ))}
          </div>
        </Grid>
      </Grid>
    </NavContainer>
  );
}

function GalleryList({ gallery }) {
  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };
  return (
    <Grid container rowSpacing={4} columnSpacing={2}>
      {gallery ? (
        gallery.map((item, index) => {
          const location = LocationExtractor(item?.location);
          const date = ExtractShortDate(item.created_at);
          return (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card
                title={item.name}
                key={index}
                imageSrc={item?.cover_photo_url}
                content={
                  <div className={GalleryCss.galleryContent}>
                    <div className={GalleryCss.flexDrC}>
                      {item?.location && (
                        <div className={GalleryCss.subText}>
                          <LocationOn className={GalleryCss.locationOnIcon} />{" "}
                          <div className={GalleryCss.locationText}>
                            {location}
                          </div>
                        </div>
                      )}
                      <div className={GalleryCss.subText}>
                        <FaCalendarAlt className={GalleryCss.calendarIcon} />{" "}
                        <div className={GalleryCss.locationText}>{date}</div>
                      </div>
                    </div>
                    {item?.rating && (
                      <div className={GalleryCss.ratingCarnival}>
                        {item.rating} <StarIcon className={GalleryCss.star} />
                      </div>
                    )}
                  </div>
                }
                textAlign={"left"}
                showAnimation={true}
                size={"sm"}
                onClick={() => handleClick(`/gallery/view/${item?.slug}`)}
                imageProps={{
                  borderRadius: 20,
                }}
              />
            </Grid>
          );
        })
      ) : (
        <NoData />
      )}
    </Grid>
  );
}
