import React from "react";
import { Grid } from "@mui/material";
import CategoryListCss from "./CategoriesList.module.css";
import CategoryIcon from "@mui/icons-material/Category";

export default function CategoriesList({ item, onClick }) {
  return (
    <Grid onClick={onClick} item xs={5.3} sm={3.3} md={2.2}>
      <div className={CategoryListCss.div}>
        <div className={CategoryListCss.iconDiv}>
          {item?.icon !== null ? (
            !isStringIncludes(item?.icon) ? (
              <i class={item?.icon}></i>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: item?.icon }}></span>
            )
          ) : (
            <CategoryIcon />
          )}
        </div>
        <div className={CategoryListCss.top}>{item.title}</div>
      </div>
    </Grid>
  );
}

function isStringIncludes(item) {
  return item.includes("</i>");
}
