import React, { useEffect, useState } from "react";
import IconButton from "../icon-button/IconButton";
import PauseIcon from "@mui/icons-material/Pause";
import { PlayArrow } from "@mui/icons-material";

export default function MusicControls({
  audioRef = null,
  disabled = false,
  showBackward = true,
  showForward = true,
  size = "lg",
  songState = {},
  setSongState = undefined,
  songQueue = [],
  currentSongIndex = 0,
  setCurrentSongIndex = undefined,
  onSongChange = undefined,
}) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleSong = (item) => {
    onSongChange(item);
  };

  useEffect(() => {
    if (currentSongIndex !== -1) {
      handleSong(songQueue[currentSongIndex]);
    }
  }, [currentSongIndex]);

  const handleChange = (item) => {
    if (item === "back") {
      audioRef.current.currentTime = 0;
    } else if (item === "back-change") {
      if (songQueue?.length !== 0 && audioRef?.current?.currentTime === 0) {
        if (currentSongIndex !== 0 && currentSongIndex !== -1) {
          setCurrentSongIndex((prev) => prev - 1);
        } else {
          setCurrentSongIndex(songQueue?.length - 1);
        }
      } else {
        audioRef.current.currentTime = 0;
      }
    } else {
      if (songQueue?.length !== 0) {
        if (currentSongIndex !== songQueue?.length - 1) {
          setCurrentSongIndex((prev) => prev + 1);
        } else {
          setCurrentSongIndex(0);
        }
      } else {
        audioRef.current.currentTime += 10;
      }
    }
  };

  const handlePlay = () => {
    if (!isPlaying) {
      audioRef?.current?.play();
      setSongState({ ...songState, isPlaying: true });
    } else {
      audioRef?.current?.pause();
      setSongState({ ...songState, isPlaying: false });
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (songState.isPlaying) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [songState]);

  const styles = {
    playIcon: {
      width: size === "lg" ? 50 : size === "md" ? 35 : 20,
      height: size === "lg" ? 50 : size === "md" ? 35 : 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    backForIconButton: {
      width: size === "lg" ? 40 : size === "md" ? 28 : 15,
      height: size === "lg" ? 40 : size === "md" ? 28 : 15,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    backForIcon: {
      width: size === "lg" ? 21 : size === "md" ? 14 : 8,
      height: size === "lg" ? 18 : size === "md" ? 12 : 5,
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: size === "lg" ? 19 : size === "md" ? 14 : 8,
        alignItems: "center",
      }}
    >
      {showBackward && (
        <IconButton
          backgroundColor={"#FF2B2B"}
          styles={styles.backForIconButton}
          disabled={disabled}
          onDoubleClick={() => handleChange("back-change")}
          onClick={() => handleChange("back")}
          size={"medium"}
          icon={
            <img
              style={styles.backForIcon}
              src={require("../../assets/images/BackwardIcon.png")}
              alt="Play"
            />
          }
        />
      )}
      <IconButton
        backgroundColor={"#FF2B2B"}
        onClick={() => handlePlay()}
        styles={styles.playIcon}
        disabled={disabled}
        size={"large"}
        icon={
          isPlaying ? (
            <PauseIcon
              style={{
                color: "#D9D9D9",
                fontSize: size === "lg" ? 35 : size === "md" ? 25 : 15,
              }}
            />
          ) : (
            <PlayArrow
              style={{
                color: "#D9D9D9",
                fontSize: size === "lg" ? 35 : size === "md" ? 32 : 20,
              }}
            />
          )
        }
      />
      {showForward && (
        <IconButton
          backgroundColor={"#FF2B2B"}
          styles={styles.backForIconButton}
          disabled={disabled}
          onClick={() => handleChange("forward")}
          size={"medium"}
          icon={
            <img
              style={styles.backForIcon}
              src={require("../../assets/images/ForwardIcon.png")}
              alt="Play"
            />
          }
        />
      )}
    </div>
  );
}
