import { configureStore } from "@reduxjs/toolkit";
import carnivalReducer from "./slices/CarnivalReducer";
import eventReducer from "./slices/EventReducer";
import albumReducer from "./slices/AlbumReducer";
import galleryReducer from "./slices/GalleryReducer";
import reviewReducer from "./slices/ReviewReducer";
import searchReducer from "./slices/SearchReducer";
import musicReducer from "./slices/MusicReducer";
import authReducer from "./slices/AuthReducer";
import queueReducer from "./slices/QueueReducer";
import pagesReducer from "./slices/PagesReducer";

export default configureStore({
  reducer: {
    carnival: carnivalReducer,
    event: eventReducer,
    album: albumReducer,
    gallery: galleryReducer,
    review: reviewReducer,
    search: searchReducer,
    music: musicReducer,
    auth: authReducer,
    queue: queueReducer,
    pages: pagesReducer,
  },
});
