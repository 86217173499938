import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventLocation: "",
  eventType: "",
  eventDate: "",
  eventFilters: {
    location: "",
    category: [],
    time: [],
    promoter: [],
    startDate: "",
    endDate: "",
    search: "",
  },
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEventLocation: (state, action) => {
      state.eventLocation = [action.payload];
    },
    setEventType: (state, action) => {
      state.eventType = [action.payload];
    },
    setEventDate: (state, action) => {
      state.eventDate = [action.payload];
    },
    setEventFilters: (state, action) => {
      state.eventFilters = [action.payload];
    },
  },
});

export const { setEventLocation, setEventType, setEventDate, setEventFilters } =
  eventSlice.actions;

export const selectEventLocation = (state) => state.event.eventLocation;
export const selectEventType = (state) => state.event.eventType;
export const selectEventDate = (state) => state.event.eventDate;
export const selectEventFilters = (state) => state.event.eventFilters;

export default eventSlice.reducer;
