import React, { useEffect, useRef, useState } from "react";
import { Grid, MenuItem, MenuList, Popover } from "@mui/material";
import { FontSizes } from "../../config";
import albumStyles from "./AlbumSongs.module.css";
import { BsThreeDots } from "react-icons/bs";
import IconButton from "../icon-button/IconButton";
import PauseIcon from "@mui/icons-material/Pause";
import { PlayArrow } from "@mui/icons-material";

function SongListItem({
  songName,
  duration,
  handlePlay,
  handlePlayNext,
  handleAddToPlaylist,
  handleAddToQueue,
  handleLike,
  data,
  album,
  isLiked,
  handleDownload
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef();
  const [like, setLike] = useState(false);

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
    } else if (event.key === "Escape") {
      setAnchorEl(null);
    }
  }

  useEffect(() => {
    setLike(isLiked);
  }, [isLiked]);

  function handlePlaySong(item) {
    if (item === "play" && handlePlay) {
      handlePlay(data, album);
    }
    if (item === "playNext" && handlePlayNext) {
      handlePlayNext(data, album);
    }
    if (item === "addToQueue" && handleAddToQueue) {
      handleAddToQueue(data, album);
    }
    if (item === "addToPlaylist" && handleAddToPlaylist) {
      handleAddToPlaylist(data);
    }
    if (item === "like" && handleLike) {
      setLike(!like);
      handleLike(data);
    }
    if (item === "download" && handleDownload) {
      handleDownload(data);
    }
    handleClose();
  }
  return (
    <div className={albumStyles.songListItemParent}>
      <div style={{ flex: 6 }}>{songName || "Song Name"}</div>
      <div className={albumStyles.songListItemText}>{duration || "3:06"}</div>
      <div className={albumStyles.songListItemText}>
        <BsThreeDots
          className={albumStyles.threeDots}
          ref={ref}
          onClick={(e) => handleClick(e)}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}
            style={{
              backgroundColor: "#363635",
              color: "#fff",
              fontSize: FontSizes.smallText,
              fontFamily: "Inter-Regular",
            }}
          >
            <MenuItem onClick={() => handlePlaySong("play")}>Play</MenuItem>
            <MenuItem onClick={() => handlePlaySong("playNext")}>
              Play next
            </MenuItem>
            <MenuItem onClick={() => handlePlaySong("addToQueue")}>
              Add to queue
            </MenuItem>
            <MenuItem onClick={() => handlePlaySong("addToPlaylist")}>
              Add to playlist
            </MenuItem>
            <MenuItem onClick={() => handlePlaySong("like")}>
              {isLiked && like ? "Unlike" : like ? "Unlike" : "Like"}
            </MenuItem>
            {data?.is_downloadable && (
            <MenuItem onClick={() => handlePlaySong("download")}>
              Download
            </MenuItem>
          )}
          </MenuList>
        </Popover>
      </div>
    </div>
  );
}

export default function AlbumSongs({
  width = null,
  height = null,
  tracksList = [],
  albumName = null,
  albumArt = null,
  genres = [],
  handlePlay = undefined,
  handlePlayNext = undefined,
  handleAddToPlaylist = undefined,
  handleAddToQueue = undefined,
  handleLike = undefined,
  handlePlayTrack = undefined,
  gradientColors = ["#EE7E8E", "#EEC2CA"],
  album = null,
  handleDownload = undefined
}) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayTracks = () => {
    setIsPlaying(!isPlaying);
    if (handlePlayTrack) {
      handlePlayTrack();
    }
  };

  return (
    <div
      className={albumStyles.cardParent}
      style={{
        background: `linear-gradient(180deg, ${gradientColors[0]}, ${gradientColors[1]})`,
      }}
    >
      <div className={albumStyles.header}>
        <div className={albumStyles.albumName}>{albumName || "Prism"}</div>
        <div className={albumStyles.genreTitle}>
          {genres?.length === 0
            ? "Pop 2013"
            : genres.map((item, index) => {
                return (
                  <span key={index}>
                    {item?.title}
                    {index !== genres?.length - 1 && ", "}
                  </span>
                );
              })}
        </div>
      </div>
      <Grid
        container
        style={{
          width: width,
          height: height,
        }}
        className={albumStyles.gridContainers}
      >
        <Grid
          md={3}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={
              albumArt ||
              "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/ee/96/1f/ee961ffa-2551-5614-7cd6-995fbcf1c468/14DMGIM05704.rgb.jpg/220x220bb.webp"
            }
            alt="album art"
            width={120}
            height={120}
          />
        </Grid>
        <Grid md={2} xs={12} className={albumStyles.showXs}>
          <div className={albumStyles.header}>
            <div style={{ flex: 1, fontSize: FontSizes.h1 }}>
              {albumName || "Prism"}
            </div>
          </div>
          <IconButton
            backgroundColor={"#FF2B2B"}
            onClick={() => handlePlayTracks()}
            // styles={styles.playIcon}
            disabled={false}
            size={"large"}
            icon={
              isPlaying ? (
                <PauseIcon
                  style={{
                    color: "#D9D9D9",
                    fontSize: 28,
                  }}
                />
              ) : (
                <PlayArrow
                  style={{
                    color: "#D9D9D9",
                    fontSize: 28,
                  }}
                />
              )
            }
          />
        </Grid>
        <Grid
          md={7}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "auto",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              {tracksList.map((item, index) => {
                return (
                  <SongListItem
                    key={index}
                    handlePlay={handlePlay}
                    handleAddToPlaylist={handleAddToPlaylist}
                    handleAddToQueue={handleAddToQueue}
                    handleLike={handleLike}
                    handlePlayNext={handlePlayNext}
                    data={item}
                    songName={item.title}
                    album={album}
                    isLiked={item?.is_liked}
                    duration={convertDurationToTime(item.duration)}
                    handleDownload={handleDownload}
                  />
                );
              })}
            </div>
          </div>
        </Grid>
        <Grid md={2} xs={12} className={albumStyles.hideXs}>
          <IconButton
            backgroundColor={"#FF2B2B"}
            onClick={() => handlePlayTracks()}
            // styles={styles.playIcon}
            disabled={false}
            size={"large"}
            icon={
              isPlaying ? (
                <PauseIcon
                  style={{
                    color: "#D9D9D9",
                    fontSize: 35,
                  }}
                />
              ) : (
                <PlayArrow
                  style={{
                    color: "#D9D9D9",
                    fontSize: 35,
                  }}
                />
              )
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}

const convertDurationToTime = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  // Construct the time string based on the presence of hours
  let formattedTime = "";
  if (hours > 0) {
    formattedTime += `${formatNumber(hours)}:${formatNumber(
      minutes
    )}:${formatNumber(seconds)}`;
  } else {
    formattedTime += `${formatNumber(minutes)}:${formatNumber(seconds)}`;
  }
  return formattedTime;
};

const formatNumber = (number) => {
  return number < 10 ? `0${number}` : number;
};
