import React from "react";
import FlatListCss from "./Flatlist.module.css";

export default function TagsList({ tag, handleNavigate }) {
  return (
    <div className={FlatListCss.topBoxTag}>
      {tag.map((item, index) => {
        return (
          <div
            onClick={() => handleNavigate({ tag: item?.slug })}
            className={index % 3 === 0 ? FlatListCss.topBold : FlatListCss.top}
            key={index}
          >
            {item.slug}
          </div>
        );
      })}
    </div>
  );
}
