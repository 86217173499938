import React, { useEffect, useRef, useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import IconButton from "../icon-button/IconButton";
import { IconButton as MUIIconButton, Popover } from "@mui/material";
import {
  copy,
  emailLink,
  facebookLink,
  redditLink,
  twitterLink,
  whatsappLink,
} from "./Resource";
import Button from "../button/Button";

export default function SharingTooltip({
  link = "",
  title = "",
  transparent = false,
  withText = false,
  backgroundColor = null,
  hoverColor = null,
  showAnimation = false,
  disabled = false,
  fontSize = null,
  onShare = undefined,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [x, setX] = useState();

  const id = open ? "simple-popover" : undefined;

  const getPosition = () => {
    const x = ref?.current?.offsetLeft;
    setX(x);
  };

  useEffect(() => {
    getPosition();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getPosition);
  }, []);

  const img = {
    width: width < 431 ? 28 : 43,
    height: width < 431 ? 28 : 43,
    cursor: "pointer",
  };
  const imgTwitter = {
    width: width < 431 ? 28 : 43,
    height: width < 431 ? 28 : 43,
    cursor: "pointer",
    backgroundColor: "#fff",
    borderRadius: 50,
    boxShadow: "0px 0px 0px 3px #000 inset",
  };

  const PopoverArrowStyles = {
    position: "absolute",
    top: "-7px",
    left: x + 10,
    margin: "auto",
    display: "block",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderColor: "#413f3f",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderTop: "0px",
    borderBottom: "16px solid #413f3f",
    zIndex: 9999,
    content: '""',
  };

  function share(item) {
    let getLink = "";
    if (item === "facebook") {
      getLink = facebookLink(link, title);
    }
    if (item === "twitter") {
      getLink = twitterLink(link, title);
    }
    if (item === "whatsapp") {
      getLink = whatsappLink(link, title, " ");
    }
    if (item === "reddit") {
      getLink = redditLink(link, title);
    }
    if (item === "gmail") {
      getLink = emailLink(link, title, "Check out this amazing post", " ");
    }
    if (onShare) {
      onShare(title,item);
    }
    const aTag = document.createElement("a");
    aTag.setAttribute("href", getLink);
    aTag.setAttribute("target", "_blank");
    aTag.click();
  }

  return (
    <div style={{ position: "relative" }}>
      {withText ? (
        <Button
          iconLeft
          icon={<ShareIcon style={{ color: "#fff", fontSize: 20 }} />}
          label="Share"
          backgroundColor={backgroundColor}
          showAnimation={showAnimation}
          hoverColor={hoverColor}
          onClick={handleClick}
          disabled={disabled}
        />
      ) : (
        <MUIIconButton
          ref={ref}
          variant="contained"
          aria-describedby={id}
          onClick={handleClick}
          disabled={disabled}
          style={{
            backgroundColor: transparent ? "transparent" : "red",
            color: "#fff",
          }}
        >
          <ShareIcon style={{ color: "#fff", fontSize: fontSize ?? 24 }} />
        </MUIIconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#413f3f",
            borderRadius: "43px",
            padding: "9px",
            marginTop: "8px",
            overflow: "visible",
          },
          "& .MuiPaper-root:before": open ? PopoverArrowStyles : {},
        }}
      >
        {/* <span className="arrow" /> */}
        <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
          <img
            src={require("../../assets/images/facebook.png")}
            style={img}
            onClick={() => share("facebook")}
            alt="facebook"
          />
          <img
            src={require("../../assets/images/twitter.png")}
            style={{ ...imgTwitter }}
            onClick={() => share("twitter")}
            alt="twitter"
          />
          <img
            src={require("../../assets/images/whatsapp.png")}
            style={img}
            onClick={() => share("whatsapp")}
            alt="whatsapp"
          />
          <img
            src={require("../../assets/images/reddit.png")}
            style={img}
            onClick={() => share("reddit")}
            alt="reddit"
          />
          <img
            src={require("../../assets/images/gmail.png")}
            style={img}
            onClick={() => share("gmail")}
            alt="mail"
          />
          <IconButton
            onClick={() => copy(link)}
            icon={
              <img
                src={require("../../assets/images/link.png")}
                style={{
                  width: width < 431 ? 14 : 26,
                  height: width < 431 ? 14 : 26,
                  cursor: "pointer",
                }}
                alt="link"
              />
            }
          />
        </div>
      </Popover>
    </div>
  );
}
