import { Skeleton } from '@mui/material';
import React from 'react'
import Label from '../../../../elements/label/Label';
import NewsLongCardLoader from '../../../../elements/skeletonLoaders/NewsLongCardLoader';
import NewsList from '../../../../elements/Flatlist/NewsList';
import ViewMore from '../../../../elements/view-more/ViewMore';
import GlobalSearchCss from "../../GlobalSearch.module.css";

export default function Reviews({
    reviews,
    length,
    showViewAll = false,
    setValue,
    isLoading,
    moreReviewsIsLoading,
    ReviewsPageNumber,
    ReviewsLastPage,
    updateReviewsPageNumber = undefined,
  }) {
    return (
      <div>
        <div className={GlobalSearchCss.mainDiv}>
          {isLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={"40%"}
              height={50}
            />
          ) : (
            <Label label={`Reviews (${length} results)`} size="md" />
          )}
        </div>
        {isLoading ? (
          <>
            {Array.from({ length: 6 }, (_,index) => {
              return <NewsLongCardLoader key={index} />;
            })}
          </>
        ) : (
          <>
            <NewsList share={true} news={reviews} />
            {moreReviewsIsLoading && (
              <>
                {Array.from({ length: 6 }, (_,index) => {
                  return <NewsLongCardLoader key={index} />;
                })}
              </>
            )}
          </>
        )}
        {showViewAll && <ViewMore onClick={() => setValue(10)} />}
        {reviews &&
          reviews?.length !== 0 &&
          ReviewsLastPage !== null &&
          ReviewsPageNumber < ReviewsLastPage &&
          !isLoading &&
          !moreReviewsIsLoading &&
          !showViewAll && <ViewMore loadMore onClick={updateReviewsPageNumber} />}
      </div>
    );
  }
