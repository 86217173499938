import React from "react";
import loaderCss from "./loader.module.css";
import Skeleton from "@mui/material/Skeleton";
export default function DetailsDescriptionLoader() {
  return (
    <div>
      {Array.from({ length: 6 }, (_, index) => (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
          animation="wave"
          variant="text"
          width={"100%"}
          height={30}
          key={index}
        />
      ))}
      <Skeleton
        sx={{ bgcolor: "grey.900", marginTop: 4 }}
        animation="wave"
        variant="text"
        width={"60%"}
        height={30}
      />
      <div className={loaderCss.chips}>
        {Array.from({ length: 6 }, (_, index) => (
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={50}
            height={40}
            key={index}
          />
        ))}
      </div>
      <Skeleton
        sx={{ bgcolor: "grey.900", marginTop: 2, marginBottom: 2 }}
        animation="wave"
        variant="rectangular"
        width={"30%"}
        height={30}
      />
      <div className={loaderCss.col2}>
        {Array.from({ length: 5 }, (_, index) => (
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="circular"
            height={43}
            width={43}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}
