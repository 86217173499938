import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginSuccess } from "../../actions";
import { useNavigate } from "react-router-dom";
import NavContainer from "../../elements/NavContainer/NavContainer";
import AuthCss from "./Auth.module.css";
import { FaApple } from "react-icons/fa";
import TextField from "../../components/textfield/TextField";
import Button from "../../components/button/Button";
import { FontSizes } from "../../config";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, Modal } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Context } from "../../Context";
import axios from "axios";
import { EventDateExtractor } from "../../elements/date-extractor/ExtractDate";
import { SERVER_URL } from "../../Constants.ts";
import Swal from "sweetalert2";
import DragAndDropZone from "../../components/drag-and-drop-zone/DragAndDropZone.js";
import {
  auth,
  facebookProvider,
  provider,
  signInWithPopup,
} from "./FirebaseApp.js";
import ReactGA from "react-ga4";

const options = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Prefer not to say", value: "prefer-not-to-say" },
  { label: "Other", value: "other" },
];

const Register = ({ loginSuccess, isLoggedIn }) => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [dob, setDOB] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [gender, setGender] = useState("");
  const [isGenderInvalid, setIsGenderInvalid] = useState("");
  const [genderError, setGenderError] = useState("");
  const [isFirstNameValid, setIsFirstNameInvalid] = useState(false);
  const [isLastNameInvalid, setIsLastNameInvalid] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState(false);
  const [isConfirmPasswordInvalid, setIsConfirmPasswordInvalid] =
    useState(false);
  const [isDOBInvalid, setIsDOBInvaild] = useState(false);
  const { setPageTitle } = useContext(Context);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [DOBError, setDOBError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/register`,
      title: "Register",
    });
  }, []);

  function isValidPhoneNumber(phoneNumber) {
    // Regular expression to match a phone number with optional country code
    const phoneRegex = /^\+?(\d{1,3})?[-. ]?\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;

    // Test the phone number against the regex pattern
    return phoneRegex.test(phoneNumber);
  }

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const data = user?.providerData[0];
      const { first_name, last_name } = splitName(data?.displayName);
      let body = {
        social_id: data?.uid,
        social_type: "google",
        email: data?.email,
        gender: "other",
      };
      if (first_name) {
        body = {
          ...body,
          first_name: first_name,
        };
      }
      if (last_name) {
        body = {
          ...body,
          last_name: last_name,
        };
      }
      if (data?.phoneNumber) {
        body = {
          ...body,
          phone: data?.phoneNumber,
        };
      }
      if (data?.photoURL) {
        body = {
          ...body,
          profile_photo_url: data?.photoURL,
        };
      }
      register(body);
    } catch (error) {
      Swal.fire({
        title: "Sorry...",
        text: "We are unable to process your request!",
        icon: "warning",
        color: "#fff",
        background: "#000",
        timer: 3000,
        showConfirmButton: false,
      });
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      const user = result.user;
      const data = user?.providerData[0];
      const { first_name, last_name } = splitName(data?.displayName);
      let body = {
        social_id: data?.uid,
        social_type: "facebook",
        email: data?.email,
        gender: "other",
      };
      if (first_name) {
        body = {
          ...body,
          first_name: first_name,
        };
      }
      if (last_name) {
        body = {
          ...body,
          last_name: last_name,
        };
      }
      if (data?.phoneNumber) {
        body = {
          ...body,
          phone: data?.phoneNumber,
        };
      }
      if (data?.photoURL) {
        body = {
          ...body,
          profile_photo_url: data?.photoURL,
        };
      }
      register(body);
    } catch (error) {
      Swal.fire({
        title: "Sorry...",
        text: "We are unable to process your request!",
        icon: "warning",
        color: "#fff",
        background: "#000",
        timer: 3000,
        showConfirmButton: false,
      });
    }
  };

  const DOBPickerStyle = {
    color: "#fff",
    fontSize: FontSizes.smallText,
    fontFamily: "Inter-Regular",
    fontWeight: "400",
    minWidth: "100%",
    "& .MuiDateCalendar-root": {
      color: "#fff",
      borderRadius: 2,
      backgroundColor: "#3A3A3A",
      fontSize: 14,
      fontFamily: "Inter-Regular",
    },
    "& .MuiButtonBase-root": {
      color: "#fff",
      fontSize: FontSizes.smallText,
      fontFamily: "Inter-Regular",
      fontWeight: "400",
    },
    "& .MuiButtonBase-root svg": {
      color: "#fff",
      fontSize: FontSizes.heading,
    },
    "& .MuiDayCalendar-header span": {
      color: "#fff",
      fontSize: FontSizes.smallText,
      fontFamily: "Inter-Regular",
      fontWeight: "400",
    },
    "& .MuiInputBase-root input": {
      color: "#fff",
      paddingRight: "50px",
      fontSize: 14,
      fontFamily: "Inter-Regular",
      padding: 1,
    },
    "& .MuiFormLabel-root": {
      color: "#fff",
      fontSize: 14,
      fontFamily: "Inter-Regular",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#fff",
      fontSize: 14,
      fontFamily: "Inter-Regular",
    },
    "& .MuiInputBase-root": {
      color: "#fff",
    },

    "& .MuiOutlinedInput-root fieldset": {
      borderColor: "#fff !important",
    },

    "& .MuiOutlinedInput-root fieldset legend span": {
      fontSize: "11px",
    },
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width < 431 ? "90%" : "300px",
    bgcolor: "#343232",
    borderRadius: 6,
    boxShadow: 24,
    maxHeight: 350,
    p: 4,
    overflow: "hidden",
  };
  const phoneStyle = {
    background: "transparent",
    width: "100%",
    fontSize: "14px",
    color: "#fff",
    fontFamily: "Inter-Regular",
  };
  useEffect(() => {
    setPageTitle("Register");
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDrop = (acceptedFiles) => {
    const imageFile = acceptedFiles[0];
    setSelectedImage(URL.createObjectURL(imageFile));
    handleClose();
  };

  const handleRejected = () => {
    handleClose();
    Swal.fire({
      title: "File extension not supported",
      text: "Only images can be uploaded here",
      icon: "warning",
      color: "#fff",
      background: "#000",
      timer: 3000,
      showConfirmButton: false,
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = () => {
    navigate("/login");
    window.scrollTo(0, 0);
  };

  const register = async (data) => {
    setIsRegistering(true);
    const params = {
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      phone: phoneNumber,
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      date_of_birth: EventDateExtractor(dob),
      profile_photo_url: selectedImage,
      address: address,
    };

    const body = data ? data : params;

    try {
      const res = await axios.post(`${SERVER_URL.REGISTER}`, body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const receivedData = res.data;

      if (res.data.status === 200) {
        const u = receivedData.user;
        const user = {
          ...u,
          token: receivedData.token,
          slug: `${u.first_name.toLowerCase()}-${u.last_name.toLowerCase()}-${u.id}`,
        };
        loginSuccess(user);
        setIsRegistering(false);
        navigate(-1);
        Swal.fire({
          title: "Hurray...",
          text: "User profile created successfully!",
          icon: "success",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          // title: "Oops!",
          title: receivedData.code,
          // text: "Something went wrong please try again later.",
          text: receivedData.message,
          icon: "warning",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      setIsRegistering(false);

      if (!err.response) {
        // Network or other error
        Swal.fire({
          title: "Oops...",
          text: "Network error. Please try again later.",
          icon: "error",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }

      const status = err.response.status;
      const d = err.response.data;

      if (status === 422) {
        if (d?.errors?.email) {
          setEmailError(d?.errors?.email[0]);
          setIsEmailInvalid(true);
          if (d.errors?.email[0] === "The email has already been taken.") {
            Swal.fire({
              title: "User Already Exists",
              text: "A user with this email already exists. Please try logging in.",
              icon: "warning",
              color: "#fff",
              background: "#000",
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
        if (d?.errors?.password) {
          setPasswordError(d?.errors?.password);
          setIsPasswordInvalid(true);
        }
      } else if (status === 409 || status === 400 || status === 401) {
        // Conflict, e.g., user already exists

        Swal.fire({
          // title: "User Already Exists",
          // text: "A user with this email already exists. Please try logging in.",
          title: d.code,
          // text: "Something went wrong please try again later.",
          text: d.message,
          icon: "warning",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      } else if (status === 500) {
        // Internal Server Error
        Swal.fire({
          title: "Server Error",
          text: "Something went wrong on our end. Please try again later.",
          icon: "error",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Sorry...",
          text: "We are unable to process your request!",
          icon: "warning",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  const handleLogin = () => {
    setIsRegistering(true);
    let isValid = true;
    setEmailError("");
    setPasswordError("");

    if (isValidPhoneNumber(phoneNumber) === false) {
      setIsPhoneNumberInvalid(true);
      setPhoneNumberError("Invalid phone number");
      setIsRegistering(false);
    } else {
      setIsPhoneNumberInvalid(false);
      setPhoneNumberError("");
    }
    if (firstName.trim() === "") {
      setIsFirstNameInvalid(true);
      isValid = false;
      setFirstNameError("First name is required");
      setIsRegistering(false);
    } else {
      setIsFirstNameInvalid(false);
      setFirstNameError("");
    }
    if (lastName.trim() === "") {
      setIsLastNameInvalid(true);
      isValid = false;
      setLastNameError("Last name is required");
      setIsRegistering(false);
    } else {
      setIsLastNameInvalid(false);
      setLastNameError("");
    }
    if (!isValidEmail(email)) {
      setIsEmailInvalid(true);
      isValid = false;
      setEmailError("Invalid email");
      setIsRegistering(false);
    } else {
      setIsEmailInvalid(false);
      setEmailError("");
    }
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      setIsPasswordInvalid(true);
      setIsRegistering(false);
    } else {
      setPasswordError("");
      setIsPasswordInvalid(false);
    }
    if (
      password !== confirmPassword ||
      password === "" ||
      confirmPassword === ""
    ) {
      setIsPasswordInvalid("false");
      setIsConfirmPasswordInvalid("false");
      setConfirmPasswordError("Passwords do not match");
      setIsRegistering(false);
      isValid = false;
    } else {
      setIsPasswordInvalid(false);
      setIsConfirmPasswordInvalid(false);
      setConfirmPasswordError("");
    }
    if (dob === null) {
      setIsDOBInvaild(true);
      isValid = false;
      setDOBError("Please select a date of birth");
      setIsRegistering(false);
    }
    if (gender === "") {
      setIsGenderInvalid(true);
      isValid = false;
      setGenderError("Please select a gender");
      setIsRegistering(false);
    } else {
      setIsDOBInvaild(false);
      setDOBError("");
    }
    if (isValid) {
      register();
      // const user = {
      //   id: "gsvv-d8ns-nsb9",
      //   username: "User",
      //   slug: "user-name-1234",
      //   email: "example@example.com",
      // };
      // loginSuccess(user);
      // navigate(-1);
    }
  };

  return (
    <NavContainer useFooter={true}>
      <div className={AuthCss.mainContainer}>
        <div className={AuthCss.Login}>Register</div>
        <div className={AuthCss.box}>
          <div className={AuthCss.head}>Register using</div>
          <div className={AuthCss.socials}>
            <FaApple className={AuthCss.socialIcon} />
            <img
              src={require("../../assets/images/Google.png")}
              className={AuthCss.socialImage}
              onClick={handleGoogleSignIn}
            />
            <img
              src={require("../../assets/images/facebook.png")}
              className={AuthCss.socialImage}
              onClick={handleFacebookSignIn}
            />
          </div>

          <div className={AuthCss.orContainer}>
            <div className={AuthCss.hr} />
            <div className={AuthCss.orText}>OR</div>
            <div className={AuthCss.hr} />
          </div>
          <div className={AuthCss.imageContainer}>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <DragAndDropZone
                  handleDrop={handleDrop}
                  handleRejected={handleRejected}
                />
              </Box>
            </Modal>

            <img
              onClick={handleOpen}
              src={
                selectedImage === null
                  ? require("../../assets/images/upload-photo.png")
                  : selectedImage
              }
              alt="Selected"
              className={AuthCss.imagePicker}
            />
          </div>
          <div className={AuthCss.fields}>
            <div className={AuthCss.subFields}>
              <div className={AuthCss.heading}>
                First Name<span className={AuthCss.required}>*</span>
              </div>
              <div className={AuthCss.mgt10}>
                <TextField
                  onChange={(item) => setFirstName(item)}
                  error={isFirstNameValid}
                />
                {firstNameError && (
                  <div className={AuthCss.error}>{firstNameError}</div>
                )}
              </div>
            </div>
            <div className={AuthCss.subFields}>
              <div className={AuthCss.heading}>
                Last Name<span className={AuthCss.required}>*</span>
              </div>
              <div className={AuthCss.mgt10}>
                <TextField
                  onChange={(item) => setLastName(item)}
                  error={isLastNameInvalid}
                />
                {lastNameError && (
                  <div className={AuthCss.error}>{lastNameError}</div>
                )}
              </div>
            </div>
          </div>
          <div className={AuthCss.mgt20} />

          <div className={AuthCss.heading}>
            Mobile Number<span className={AuthCss.required}>*</span>
          </div>
          <div className={AuthCss.mgt10}>
            <PhoneInput
              inputStyle={{
                ...phoneStyle,
                border: isPhoneNumberInvalid
                  ? "1px solid red"
                  : "1px solid #fff",
              }}
              buttonStyle={{ background: "transparent" }}
              dropdownStyle={{ background: "grey" }}
              country={"us"}
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
            />{" "}
            {isPhoneNumberInvalid && (
              <div className={AuthCss.error}>{phoneNumberError}</div>
            )}
          </div>
          <div className={AuthCss.mgt20} />
          <div className={AuthCss.heading}>
            Email<span className={AuthCss.required}>*</span>
          </div>
          <div className={AuthCss.mgt10}>
            <TextField
              onChange={(item) => setEmail(item)}
              type={"email"}
              error={isEmailInvalid}
            />
            {isEmailInvalid && (
              <div className={AuthCss.error}>{emailError}</div>
            )}
          </div>
          <div className={AuthCss.mgt20} />

          <div className={AuthCss.heading}>Address</div>
          <div className={AuthCss.mgt10}>
            <TextField onChange={(item) => setAddress(item)} type={"text"} />
          </div>
          <div className={AuthCss.mgt20} />

          <div className={AuthCss.fields}>
            <div className={AuthCss.subFields}>
              <div className={AuthCss.heading}>
                Password<span className={AuthCss.required}>*</span>
              </div>
              <div className={AuthCss.mgt10}>
                <TextField
                  onChange={(item) => setPassword(item)}
                  type={"password"}
                  error={isPasswordInvalid}
                />
                {isPasswordInvalid && (
                  <div className={AuthCss.error}>{passwordError}</div>
                )}
              </div>
            </div>
            <div className={AuthCss.subFields}>
              <div className={AuthCss.heading}>
                Confirm Password<span className={AuthCss.required}>*</span>
              </div>
              <div className={AuthCss.mgt10}>
                <TextField
                  onChange={(item) => {
                    setConfirmPassword(item);
                  }}
                  type={"password"}
                  error={isConfirmPasswordInvalid}
                />
                {isConfirmPasswordInvalid && (
                  <div className={AuthCss.error}>{confirmPasswordError}</div>
                )}
              </div>
            </div>
          </div>
          <div className={AuthCss.mgt20} />

          <div className={AuthCss.fields3}>
            <div className={AuthCss.miniFields}>
              <div className={AuthCss.heading}>
                Date of birth<span className={AuthCss.required}>*</span>
              </div>
              <div className={AuthCss.mgt10}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    sx={{
                      ...DOBPickerStyle,
                      ...(isDOBInvalid && {
                        "& .MuiOutlinedInput-root fieldset": {
                          borderColor: "red !important",
                        },
                      }),
                    }}
                    slotProps={{
                      textField: { size: "small" },
                      layout: {
                        sx: DOBPickerStyle,
                      },
                    }}
                    disableFuture
                    value={dob}
                    onChange={(newValue) => setDOB(newValue)}
                  />
                  {isDOBInvalid && (
                    <div className={AuthCss.error}>{DOBError}</div>
                  )}
                </LocalizationProvider>
              </div>
            </div>
            <div className={AuthCss.miniFields}>
              <div className={AuthCss.heading}>
                Gender<span className={AuthCss.required}>*</span>
              </div>
              <div className={AuthCss.mgt10}>
                <TextField
                  type={"select"}
                  onChange={(item) => setGender(item)}
                  data={options}
                  error={isGenderInvalid}
                />
                {isGenderInvalid && (
                  <div className={AuthCss.error}>{genderError}</div>
                )}
              </div>
            </div>
          </div>
          <div className={AuthCss.mgt20} />

          <div className={AuthCss.flexDr3}>
            <Button
              onClick={() => handleLogin()}
              label="Register"
              loading={isRegistering}
            />
            <div>
              <span className={AuthCss.imageText}>
                Already have an account?
              </span>
              <Button
                size={"small"}
                label="Login"
                backgroundColor={"transparent"}
                color={"#ff0000"}
                styles={{ fontWeight: "600", fontSize: FontSizes.body }}
                onClick={() => handleNavigate()}
              />
            </div>
          </div>
        </div>
      </div>
    </NavContainer>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps, { loginSuccess })(Register);
function isValidEmail(email) {
  // Regular expression for validating an email address
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

function splitName(displayName) {
  const nameParts = displayName.trim().split(/\s+/); // Split by whitespace

  if (nameParts.length === 1) {
    // Only one word
    return {
      first_name: displayName,
      last_name: "",
    };
  } else {
    // More than one word
    const last_name = nameParts.pop(); // Remove the last word
    const first_name = nameParts.join(" "); // Join the rest of the words
    return {
      first_name: first_name,
      last_name: last_name,
    };
  }
}
