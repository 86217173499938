import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavContainer from "../../elements/NavContainer/NavContainer";
import AuthCss from "./Auth.module.css";
import TextField from "../../components/textfield/TextField";
import Button from "../../components/button/Button";
import { Context } from "../../Context";
import ReactGA from "react-ga4";

export default function ConfirmPassword() {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [totalHeight, setTotalHeight] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { setPageTitle } = useContext(Context);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isConfirmPasswordInvalid, setIsConfirmPasswordInvalid] =
    useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  useEffect(() => {
    setPageTitle("Confirm Password");
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/confirm-password",
      title: "Confirm Password",
    });
  }, []);

  const handleNavigate = () => {
    navigate("/register");
    window.scrollTo(0, 0);
  };
  const handleSubmit = () => {
    let isValid = true;
    if (newPassword.length < 8) {
      setIsPasswordInvalid(true);
      setPasswordError("Password must be at least 8 characters.");
      isValid = false;
      return;
    } else {
      setIsPasswordInvalid(false);
      setPasswordError("");
    }

    if (
      newPassword !== confirmPassword ||
      newPassword.trim() === "" ||
      confirmPassword.trim() === ""
    ) {
      setIsPasswordInvalid(true);

      setIsConfirmPasswordInvalid(true);

      isValid = false;
    } else {
      setPasswordError("Passwords do not match.");
      setConfirmPasswordError("Passwords do not match.");
    }

    if (isValid) {
      const user = {
        id: "gsvv-d8ns-nsb9",
        username: "User",
        slug: "user-name-1234",
        email: "example@example.com",
      };
      navigate("/login");
    }
  };

  useEffect(() => {
    // Convert 1vh to pixels
    const vhInPixels = height / 100;
    const total = 12 * vhInPixels + (width > 900 ? 30 : 50);
    setTotalHeight(total);
  }, [height, width]);

  return (
    <NavContainer useFooter={true}>
      <div className={AuthCss.mainContainer}>
        <div className={AuthCss.Login}>Set new password?</div>
        <div className={AuthCss.box}>
          <div className={AuthCss.heading}>
            New Password<span className={AuthCss.required}>*</span>
          </div>
          <div className={AuthCss.mgt10}>
            <TextField
              onChange={(item) => setConfirmPassword(item)}
              type={"password"}
              error={isConfirmPasswordInvalid}
            />
            {
              <div className={AuthCss.error}>
                {isPasswordInvalid && passwordError}
              </div>
            }
          </div>
          <div className={AuthCss.mgt10} />
          <div className={AuthCss.heading}>
            Confirm New Password<span className={AuthCss.required}>*</span>
          </div>
          <div className={AuthCss.mgt10}>
            <TextField
              onChange={(item) => setNewPassword(item)}
              type={"password"}
              error={isPasswordInvalid}
            />
            {
              <div className={AuthCss.error}>
                {isConfirmPasswordInvalid && confirmPasswordError}
              </div>
            }
          </div>
          <div className={AuthCss.mgt20} />
          <Button onClick={() => handleSubmit()} label="Submit" />
        </div>
      </div>
    </NavContainer>
  );
}
