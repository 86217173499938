import React from "react";
import loaderCss from "./loader.module.css";
import { Skeleton } from "@mui/material";
export default function CommentLoader({ showAll = true }) {
  return showAll ? (
    <div>
      <div className={loaderCss.col3}>
        <Skeleton
          sx={{ bgcolor: "grey.900", paddingRight: 5 }}
          animation="wave"
          width={40}
          height={40}
          variant="circular"
        />
        <div className={loaderCss.row2}>
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 1 }}
            animation="wave"
            variant="text"
            width={"30%"}
            height={20}
          />
          {Array.from({ length: 5 }, (_, index) => (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
              animation="wave"
              variant="text"
              width={"100%"}
              height={10}
              key={index}
            />
          ))}
        </div>
      </div>
      <div className={loaderCss.col4}>
        <Skeleton
          sx={{ bgcolor: "grey.900", paddingRight: 5 }}
          animation="wave"
          width={40}
          height={40}
          variant="circular"
        />
        <div className={loaderCss.row2}>
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 1 }}
            animation="wave"
            variant="text"
            width={"30%"}
            height={20}
          />
          {Array.from({ length: 5 }, (_, index) => (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
              animation="wave"
              variant="text"
              width={"100%"}
              height={10}
              key={index}
            />
          ))}
        </div>
      </div>
      <div className={loaderCss.wrapper}>
        <div className={loaderCss.col3}>
          <Skeleton
            sx={{ bgcolor: "grey.900", paddingRight: 5 }}
            animation="wave"
            width={40}
            height={40}
            variant="circular"
          />
          <div className={loaderCss.row2}>
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 1 }}
              animation="wave"
              variant="text"
              width={"30%"}
              height={20}
            />
            {Array.from({ length: 5 }, (_, index) => (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                animation="wave"
                variant="text"
                width={"100%"}
                height={10}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={loaderCss.col4}>
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          animation="wave"
          width={40}
          height={40}
          variant="circular"
        />
        <div className={loaderCss.row2}>
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 1 }}
            animation="wave"
            variant="text"
            width={"30%"}
            height={20}
          />
          {Array.from({ length: 5 }, (_, index) => (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
              animation="wave"
              variant="text"
              width={"90%"}
              height={10}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className={loaderCss.col3}>
        <Skeleton
          sx={{ bgcolor: "grey.900", paddingRight: 5 }}
          animation="wave"
          width={40}
          height={40}
          variant="circular"
        />
        <div className={loaderCss.row2}>
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 1 }}
            animation="wave"
            variant="text"
            width={"30%"}
            height={20}
          />
          {Array.from({ length: 5 }, (_, index) => (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
              animation="wave"
              variant="text"
              width={"100%"}
              height={10}
              key={index}
            />
          ))}
        </div>
      </div>
      <div className={loaderCss.col4}>
        <Skeleton
          sx={{ bgcolor: "grey.900", paddingRight: 5 }}
          animation="wave"
          width={40}
          height={40}
          variant="circular"
        />
        <div className={loaderCss.row2}>
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 1 }}
            animation="wave"
            variant="text"
            width={"30%"}
            height={20}
          />
          {Array.from({ length: 5 }, (_, index) => (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
              animation="wave"
              variant="text"
              width={"100%"}
              height={10}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
