import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavContainer from "../../elements/NavContainer/NavContainer";
import AuthCss from "./Auth.module.css";
import TextField from "../../components/textfield/TextField";
import Button from "../../components/button/Button";
import { Context } from "../../Context";
import ReactGA from "react-ga4";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");
  const [height, setHeight] = useState(window.innerHeight);
  const [totalHeight, setTotalHeight] = useState(null);
  const { setPageTitle } = useContext(Context);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/forgot-password",
      title: "Forgot Password",
    });
  }, []);

  useEffect(() => {
    setPageTitle("Forgot Password");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigate = () => {
    navigate("/register");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // Convert 1vh to pixels
    const vhInPixels = height / 100;
    const total = 12 * vhInPixels + (width > 900 ? 30 : 50);
    setTotalHeight(total);
  }, [height, width]);
  const handleSubmit = () => {
    if (isValidEmail(email)) navigate("/confirm-password");
    else {
      setIsEmailInvalid(true);
      setEmailError("Please enter a valid email address.");
    }
  };
  return (
    <NavContainer useFooter={true}>
      <div className={AuthCss.mainContainer}>
        <div className={AuthCss.Login}>Forgot Password?</div>
        <div className={AuthCss.box}>
          <div className={AuthCss.heading}>
            Email<span className={AuthCss.required}>*</span>
          </div>
          <div className={AuthCss.mgt10}>
            <TextField
              onChange={(item) => setEmail(item)}
              type={"email"}
              error={isEmailInvalid}
            />
            {
              <div className={AuthCss.error}>
                {isEmailInvalid && emailError}
              </div>
            }
          </div>
          <div className={AuthCss.mgt20} />
          <Button onClick={() => handleSubmit()} label="Submit" />
        </div>
      </div>
    </NavContainer>
  );
}
function isValidEmail(email) {
  // Regular expression for validating an email address
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}
