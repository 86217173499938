import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import CarnivalClassifiedsCss from "./CarnivalClassifieds.module.css";
import { Grid, Skeleton } from "@mui/material";
import { Context } from "../../Context";
import CarnivalClassifiedsFilter from "./elements/CarnivalClassifiedsFilter";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import ViewMore from "../../elements/view-more/ViewMore";
import { useNavigate, useSearchParams } from "react-router-dom";
import SlugConverter from "../../elements/slug-converter/SlugConverter";
import MetaTags from "../../MetaTags";
import NoData from "../../elements/no-data/NoData";
import TagsList from "../../elements/Flatlist/TagsList";
import ClassifiedCard from "../../components/classified-card/ClassifiedCard";
import { HiInboxArrowDown } from "react-icons/hi2";
import { IoAdd } from "react-icons/io5";
import Button from "../../components/button/Button";
import ClassifieldsLoader from "../../elements/skeletonLoaders/ClassifiedsLoader";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import Chip from "../../components/chip/Chip.js";
import { useLoggedInState } from "../../elements/logged-in-state/useLoggedInState.js";
import ReactGA from "react-ga4";
import DynamicContentInjector from "../../elements/DynamicContentInjector/DynamicContentInjector.js";

const postTypeData = [
  {
    id: 1,
    name: "For Sale",
    slug: "sale",
  },
  {
    id: 2,
    name: "For Swap",
    slug: "swap",
  },
  {
    id: 3,
    name: "For Rent",
    slug: "rent",
  },
  {
    id: 4,
    name: "Needed",
    slug: "needed",
  },
];

const tagsArray = [
  {
    name: "All",
    slug: "all",
  },
  {
    name: "My Classifieds",
    slug: "my_classifieds",
  },
];

const CarnivalClassifieds = ({ auth }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const { setPageTitle } = useContext(Context);
  const [carnivalClassifieds, setCarnivalClassifieds] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [tag, setTag] = useState(null);
  const [allTag, setAllTag] = useState(null);
  const [tagIsLoading, setTagIsLoading] = useState(true);
  const [allTagIsLoading, setAllTagIsLoading] = useState(true);
  const [params, setParams] = useState({});
  const [optionalTitle, setOptionalTitle] = useState("");
  const [selectedChip, setSelectedChip] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const [carnivalClassifiedsPageNumber, setCarnivalClassifiedsPageNumber] =
    useState(1);
  const [carnivalClassifiedsLastPage, setCarnivalClassifiedsLastPage] =
    useState(null);
  const [
    moreCarnivalClassifiedsIsLoading,
    setMoreCarnivalClassifiedsIsLoading,
  ] = useState(false);
  const [bannerAd, setBannerAd] = useState(null);
  const [status, setStatus] = useState(null);
  const [bannerAd2, setBannerAd2] = useState(null);
  const isLoggedInState = useLoggedInState();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/carnival/classifieds`,
      title: "Carnival Classifieds",
    });
  }, []);

  // This function is used to get banner ad which is placed below the filters.
  const handleGetSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "sidebar ad square",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd(res.data);
        }
      }
    } catch (err) {
      setBannerAd(null);
    }
  };

  const handleGetSecondSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "skyscraper ad",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd2(res.data);
        }
      }
    } catch (err) {
      setBannerAd2(null);
    }
  };

  useEffect(() => {
    handleGetSidebarBanner();
    handleGetSecondSidebarBanner();
  }, []);

  useEffect(() => {
    if (searchParams.size !== 0) {
      if (searchParams.size === 1) {
        if (searchParams?.get("tag") !== null) {
          const tag = allTag?.filter(
            (i) => i.slug === searchParams?.get("tag")
          )[0]?.name;
          if (tag) {
            setOptionalTitle(`By Tag: ${tag}`);
          }
        }
        if (searchParams?.get("postType") !== null) {
          const postType = postTypeData?.filter(
            (i) => i.slug === searchParams?.get("postType")
          )[0]?.name;
          setOptionalTitle(`By Post Type: ${postType}`);
        }
      } else {
        setOptionalTitle("Filtered Results");
      }
    } else {
      setOptionalTitle("");
    }
  }, [searchParams, allTag]);

  useEffect(() => {
    setPageTitle("Carnival Classifieds");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width < 431) {
      setShowFilter(false);
    }
  }, [width]);

  const handleGetData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CARNIVAL_CLASSIFIEDS, {
        headers: {
          Accept: "application/json",
          ...(auth?.user?.token &&
            selectedChip === "my_classifieds" && {
              Authorization: `Bearer ${auth?.user?.token}`,
            }),
        },
        params: {
          page: carnivalClassifiedsPageNumber,
          items: PER_PAGE_COUNT.CARNIVAL_CLASSIFIEDS,
          ...(filters?.tag && {
            tags: filters?.tag,
          }),
          ...(filters?.postType && {
            type: filters?.postType,
          }),
          ...(filters?.startDate && {
            startDate: filters?.startDate,
          }),
          ...(filters?.endDate && {
            endDate: filters?.endDate,
          }),
        },
      });
      if (res.status === 200) {
        if (
          res?.data &&
          res?.data?.classifieds &&
          res?.data?.classifieds?.data
        ) {
          setStatus(res.status);
          setCarnivalClassifiedsLastPage(res?.data?.classifieds?.last_page);
          if (carnivalClassifiedsPageNumber === 1) {
            setCarnivalClassifieds(res?.data?.classifieds?.data);
          } else {
            setCarnivalClassifieds((prev) => [
              ...prev,
              ...res?.data?.classifieds?.data,
            ]);
          }
        }
        setDataIsLoading(false);
        if (moreCarnivalClassifiedsIsLoading) {
          setMoreCarnivalClassifiedsIsLoading(false);
        }
      }
    } catch (err) {
      setDataIsLoading(false);
      if (moreCarnivalClassifiedsIsLoading) {
        setMoreCarnivalClassifiedsIsLoading(false);
      }
    }
  };

  function updateCarnivalClassifiedsPageNumber() {
    if (
      carnivalClassifiedsLastPage != null &&
      carnivalClassifiedsPageNumber <= carnivalClassifiedsLastPage
    ) {
      setMoreCarnivalClassifiedsIsLoading(true);
      setCarnivalClassifiedsPageNumber(carnivalClassifiedsPageNumber + 1);
    }
  }

  useEffect(() => {
    if (carnivalClassifiedsPageNumber !== 1) {
      handleGetData();
    }
  }, [carnivalClassifiedsPageNumber]);

  const getTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
          type: "classified",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setTag(res?.data?.data);
        }
        setTagIsLoading(false);
      }
    } catch (err) {
      setTagIsLoading(false);
    }
  };

  const getAllTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
          type: "classified",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setAllTag(res?.data?.data);
        }
        setAllTagIsLoading(false);
      }
    } catch (err) {
      setAllTagIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.size !== 0) {
      let params = {};
      if (searchParams?.getAll("tag")?.length !== 0) {
        params = {
          ...params,
          tag: searchParams.getAll("tag"),
        };
      }
      if (searchParams?.getAll("postType")?.length !== 0) {
        params = {
          ...params,
          postType: searchParams.getAll("postType"),
        };
      }
      if (searchParams?.get("startDate")) {
        params = {
          ...params,
          startDate: searchParams.get("startDate"),
        };
      }
      if (searchParams?.get("endDate")) {
        params = {
          ...params,
          endDate: searchParams.get("endDate"),
        };
      }

      if (
        params &&
        Object.keys(params).length > 0 &&
        Object.keys(filters)?.length === 0
      ) {
        handleSearch(params);
      }
    }
  }, [searchParams]);

  const handleResetAll = () => {
    setFilters({});
    const filter = ["tag", "postType", "startDate", "endDate"];
    filter.map((item) => {
      if (searchParams.has(item)) {
        searchParams.delete(item);
        setSearchParams(searchParams);
        delete params[item];
      }
    });
  };

  useEffect(() => {
    getTags();
    getAllTags();
  }, []);

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  const handleShowDd = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    setCarnivalClassifiedsPageNumber(1);
    setDataIsLoading(true);
    setCarnivalClassifieds([]);
    if (searchParams.size === 1 && searchParams.has("selectedChip")) {
      if (selectedChip) {
        handleGetData();
      }
    } else if (searchParams.size !== 0) {
      if (Object.keys(filters)?.length !== 0) {
        handleGetData();
      }
    } else {
      handleGetData();
    }
  }, [filters, selectedChip]);

  // Update the selected chip from params by checking whether the user has been logged in or not.
  useEffect(() => {
    if (searchParams?.get("selectedChip")) {
      if (isLoggedInState) {
        if (auth?.user?.token) {
          handleChange(searchParams?.get("selectedChip"));
        }
      } else {
        handleChange("all");
      }
    } else {
      setSelectedChip("all");
    }
  }, [searchParams, isLoggedInState, auth]);

  const handleSearchParam = (key, value) => {
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  const handleSearch = (item) => {
    setFilters(item);
    ReactGA.event({
      category: "Filter",
      action: "Results filtered",
      label: "Results filtered on carnival classified page",
    });
    ReactGA.event({
      category: "Filter",
      action: "Results filtered on carnival classified page",
      label: "Results filtered on carnival classified page",
    });
    Object.entries(item)?.map((i) => {
      handleSearchParam(i[0], i[1]);
    });
  };

  const handleLogin = () => {
    Swal.fire({
      title: "You are not logged in!",
      text: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  const handleChange = (item) => {
    ReactGA.event({
      category: "Chip",
      action: "Chip updated",
      label: `${item} chip updated on carnival classified page`,
    });
    ReactGA.event({
      category: "Chip",
      action: `${item} chip updated on carnival classified page`,
      label: `${item} chip updated on carnival classified page`,
    });
    if (item === "my_classifieds") {
      if (auth?.isLoggedIn) {
        setSelectedChip(item);
        handleSearchParam("selectedChip", item);
      } else {
        handleLogin();
      }
    } else {
      setSelectedChip(item);
      handleSearchParam("selectedChip", item);
    }
  };

  const handleClick = (item) => {
    const userId = auth?.user?.slug;
    if (item === "inbox") {
      if (userId) {
        navigate(`/carnival/classifieds/${userId}/inbox`);
      } else {
        handleLogin();
      }
    } else if (item === "create") {
      if (userId) {
        navigate(`/carnival/classifieds/${userId}/create`);
      } else {
        handleLogin();
      }
    } else {
      navigate(item);
    }
    window.scrollTo(0, 0);
  };

  const handleDetail = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.DELETE_CLASSIFIED}${id}/delete`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${auth?.user.token}`,
          },
        }
      );
      if (res.status === 200) {
        setDataIsLoading(true);
        handleGetData();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete the classified",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleChat = (user) => {
    const userId = auth?.user?.id;
    if (userId) {
      navigate(`/carnival/classifieds/${userId}/inbox/chatbox`, {
        state: { user: user },
      });
      window.scrollTo(0, 0);
    } else {
      handleLogin();
    }
  };

  const handleNavigate = (param) => {
    const params = `tag=${SlugConverter(param.tag)}`;
    const route = "?" + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"Carnival Classifieds"}
        url={window.location.href}
        type={"website"}
      />
      <div className={CarnivalClassifiedsCss.labelDiv}>
        <Label
          size="lg"
          label={
            optionalTitle !== ""
              ? "Carnival Classifieds " + optionalTitle
              : "Carnival Classifieds"
          }
        />
        <div className={CarnivalClassifiedsCss.iconsMd}>
          <Button
            label="New"
            iconLeft={true}
            icon={<IoAdd className={CarnivalClassifiedsCss.addMd} />}
            onClick={() => handleClick("create")}
          />
          <Button
            label="Inbox"
            iconLeft={true}
            icon={
              <HiInboxArrowDown className={CarnivalClassifiedsCss.inboxMd} />
            }
            onClick={() => handleClick("inbox")}
          />
        </div>
        <div className={CarnivalClassifiedsCss.iconsSm}>
          <div className={CarnivalClassifiedsCss.iconsSmDiv}>
            <IoAdd
              className={CarnivalClassifiedsCss.add}
              onClick={() => handleClick("create")}
            />
          </div>
          <div className={CarnivalClassifiedsCss.iconsSmDiv}>
            <img
              src={require("../../assets/images/filter.png")}
              className={CarnivalClassifiedsCss.filterSm}
              onClick={() => handleShowDd()}
            />
            {showFilter ? (
              <FaCaretUp className={CarnivalClassifiedsCss.upDown} />
            ) : (
              <FaCaretDown className={CarnivalClassifiedsCss.upDown} />
            )}
          </div>
          <div className={CarnivalClassifiedsCss.iconsSmDiv}>
            <HiInboxArrowDown
              className={CarnivalClassifiedsCss.inbox}
              onClick={() => handleClick("inbox")}
            />
          </div>
        </div>
      </div>
      <Grid container>
        <Grid
          item
          className={CarnivalClassifiedsCss.gridGeneral}
          xs={12}
          md={9}
        >
          {showFilter &&
            (allTagIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginTop: 2 }}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={440}
              />
            ) : (
              <CarnivalClassifiedsFilter
                onSearch={(item) => handleSearch(item)}
                tag={allTag}
                postTypeData={postTypeData}
                onReset={handleResetAll}
                filter={filters}
              />
            ))}
          {bannerAd &&
            (bannerAd?.banner_image_url ? (
              <div className={CarnivalClassifiedsCss.smBanner}>
                <img
                  className={CarnivalClassifiedsCss.BannerAds}
                  src={bannerAd?.banner_image_url}
                />
              </div>
            ) : (
              bannerAd?.content && (
                <div className={CarnivalClassifiedsCss.smBanner}>
                  <DynamicContentInjector
                    className={CarnivalClassifiedsCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                </div>
              )
            ))}
          {bannerAd2 &&
            (bannerAd2?.banner_image_url ? (
              <div className={CarnivalClassifiedsCss.smBanner}>
                <img
                  className={CarnivalClassifiedsCss.BannerAds}
                  src={bannerAd2?.banner_image_url}
                />
              </div>
            ) : (
              bannerAd2?.content && (
                <div className={CarnivalClassifiedsCss.smBanner}>
                  <DynamicContentInjector
                    className={CarnivalClassifiedsCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                </div>
              )
            ))}
          <div className={CarnivalClassifiedsCss.chipsArray}>
            {tagsArray?.map((item, index) => {
              return (
                <Chip
                  label={item?.name}
                  size={"md"}
                  backgroundColor={selectedChip === item?.slug ? "#ff0000" : ""}
                  handleClick={() => handleChange(item?.slug)}
                  key={index}
                />
              );
            })}
          </div>
          <div className={CarnivalClassifiedsCss.firstContainer}>
            {dataIsLoading ? (
              <div className={CarnivalClassifiedsCss.classifiedCardsList}>
                {Array.from({ length: 8 }, (_, index) => (
                  <ClassifieldsLoader key={index} />
                ))}
              </div>
            ) : (
              <>
                {carnivalClassifieds && carnivalClassifieds?.length !== 0 ? (
                  <>
                    <div className={CarnivalClassifiedsCss.classifiedCardsList}>
                      {carnivalClassifieds.map((item, index) => {
                        const user = { ...item?.user, rating: item?.rating };
                        return (
                          <ClassifiedCard
                            data={item}
                            key={index}
                            onMessageClick={() => handleChat(user)}
                            onClick={() =>
                              handleDetail(
                                `/carnival/classifieds/${item?.slug}`
                              )
                            }
                            handleDelete={handleDelete}
                            showDelete={selectedChip === "my_classifieds"}
                            shareLink={window.location.href.split("?")[0]}
                            onChipClick={(i) => handleNavigate({ tag: i })}
                          />
                        );
                      })}
                    </div>
                    {moreCarnivalClassifiedsIsLoading && (
                      <div
                        className={CarnivalClassifiedsCss.classifiedCardsList}
                      >
                        {Array.from({ length: 8 }, () => (
                          <ClassifieldsLoader />
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <NoData errorCode={status} />
                )}
              </>
            )}
          </div>
          {carnivalClassifieds &&
          carnivalClassifieds?.length !== 0 &&
          carnivalClassifiedsLastPage !== null &&
          carnivalClassifiedsPageNumber < carnivalClassifiedsLastPage &&
          !dataIsLoading &&
          !moreCarnivalClassifiedsIsLoading ? (
            <ViewMore loadMore onClick={updateCarnivalClassifiedsPageNumber} />
          ) : (
            <div className={CarnivalClassifiedsCss.mgb} />
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={CarnivalClassifiedsCss.secondContainer}>
            {allTagIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginTop: 2 }}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={440}
              />
            ) : (
              <CarnivalClassifiedsFilter
                onSearch={(item) => handleSearch(item)}
                tag={allTag}
                postTypeData={postTypeData}
                onReset={handleResetAll}
                filter={filters}
              />
            )}
            {bannerAd &&
              (bannerAd?.banner_image_url ? (
                <img
                  className={CarnivalClassifiedsCss.BannerAds}
                  src={bannerAd?.banner_image_url}
                />
              ) : (
                bannerAd?.content && (
                  <DynamicContentInjector
                    className={CarnivalClassifiedsCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                )
              ))}
            {bannerAd2 &&
              (bannerAd2?.banner_image_url ? (
                <img
                  className={CarnivalClassifiedsCss.BannerAds}
                  src={bannerAd2?.banner_image_url}
                />
              ) : (
                bannerAd2?.content && (
                  <DynamicContentInjector
                    className={CarnivalClassifiedsCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                )
              ))}
          </div>
        </Grid>
      </Grid>
      {tagIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={"30%"}
          height={30}
        />
      ) : (
        tag &&
        tag?.length !== 0 && (
          <div className={CarnivalClassifiedsCss.redHead}>Popular Tags</div>
        )
      )}
      {tagIsLoading ? (
        <Grid container columnGap={3}>
          {Array.from({ length: 50 }, (_, index) => {
            return (
              <Grid key={index} item xs={5.3} sm={3.3} md={2.2}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        tag &&
        tag?.length !== 0 && (
          <TagsList tag={tag} handleNavigate={handleNavigate} />
        )
      )}
      {tag && tag?.length !== 0 && (
        <ViewMore onClick={() => handleClick("/carnival/classifieds/tags")} />
      )}
    </NavContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CarnivalClassifieds);
