import React from "react";
import { Skeleton } from "@mui/material";
import LoaderCss from "./loader.module.css";
import { useState, useEffect } from "react";
export default function GalleryImagesLoader() {
  const [width, setWidth] = React.useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={LoaderCss.images}>
      {Array.from({ length: 8 }, (_,index) => {
        return (
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="rectangular"
            width={width > 431 ? "24.8%" : "49%"}
            height={273}
            key={index}
          />
        );
      })}
    </div>
  );
}
