import React, { useContext, useEffect, useState } from "react";
import ArtistStyles from "./ArtistPage.module.css";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import VideoCardLoader from "../../elements/skeletonLoaders/VideoCardLoader";
import EventList from "../../elements/Flatlist/EventList";
import EventLongCardLoader from "../../elements/skeletonLoaders/EventLongCardLoader";
import ViewMore from "../../elements/view-more/ViewMore";
import { Grid, Modal, Skeleton, Tab, Tabs, styled } from "@mui/material";
import ArtistList from "../../elements/Flatlist/ArtistList";
import { Box } from "@mui/system";
import AlbumSongs from "../../components/album-songs/AlbumSongs";
import AlbumsList from "../../elements/Flatlist/AlbumsList";
import { Context } from "../../Context";
import { useNavigate } from "react-router";
import Button from "../../components/button/Button";
import { BsPlus } from "react-icons/bs";
import VideoCard from "../../components/video-card/VideoCard";
import { ExtractDate } from "../../elements/date-extractor/ExtractDate";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader";
import ArtistAndDjCardLoader from "../../elements/skeletonLoaders/ArtistAndDjCardLoader";
import { connect, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { addToQueue, playNextInQueue } from "../../actions";
import { setMusic } from "../../slices/MusicReducer";
import { useLocation, useParams } from "react-router-dom";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { convertNumUnit } from "../../elements/convert-num-unit/ConvertNumUnit.js";
import NoData from "../../elements/no-data/NoData.js";
import MetaTags from "../../MetaTags.js";
import Playlist from "../../elements/Playlist/Playlist.js";
import NoDataScreen from "../no-data/NoDataScreen.js";
import ReactGA from "react-ga4";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";

const gradientColors = [
  ["#EE7E8E", "#EEC2CA"],
  ["#EC0D0B", "#FD9494"],
  ["#7199B5", "#ABC4D3"],
  ["#FF8676", "#F9D5C6"],
  ["#3C6382", "#7FB3D5"],
  ["#AF6F6F", "#DEB3B0"],
];

function ArtistPage({ isLoggedIn, user }) {
  const { slug } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const { setPageTitle } = useContext(Context);
  const [moreFromArtists, setMoreFromArtists] = useState([]);
  const [moreFromArtistIsLoading, setMoreFromArtistIsLoading] = useState(null);
  const [songsIsLoading, setSongsIsLoading] = useState(true);
  const [upcomingEventsIsLoading, setUpcomingEventsIsLoading] = useState(true);
  const [pastEventsIsLoading, setPastEventsIsLoading] = useState(true);
  const [pastEvents, setPastEvents] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState(null);
  const [albumList, setAlbumList] = useState([]);
  const [recentReleasedAlbums, setRecentReleasedAlbum] = useState(null);
  const [mostLikedAlbums, setMostLikedAlbum] = useState(null);
  const [mostPlayedAlbums, setMostPlayedAlbum] = useState(null);
  const [recentReleasedAlbumsIsLoading, setRecentReleasedAlbumIsLoading] =
    useState(true);
  const [mostLikedAlbumsIsLoading, setMostLikedAlbumIsLoading] = useState(true);
  const [mostPlayedAlbumsIsLoading, setMostPlayedAlbumIsLoading] =
    useState(true);
  const [recentReleasedVideos, setRecentReleasedVideos] = useState(null);
  const [mostLikedVideos, setMostLikedVideos] = useState(null);
  const [mostPlayedVideos, setMostPlayedVideos] = useState(null);
  const [recentReleasedVideosIsLoading, setRecentReleasedVideosIsLoading] =
    useState(true);
  const [mostLikedVideosIsLoading, setMostLikedVideosIsLoading] =
    useState(true);
  const [mostPlayedVideosIsLoading, setMostPlayedVideosIsLoading] =
    useState(true);
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const [slugUrl, setSlugUrl] = useState(null);
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const [artistData, setArtistData] = useState(null);
  const [artistsIsLoading, setArtistsIsLoading] = useState(true);
  const [songsPageNumber, setSongsPageNumber] = useState(1);
  const [songsLastPageNumber, setSongsLastPageNumber] = useState(null);
  const [moreSongsIsLoading, setMoreSongsIsLoading] = useState(false);
  const [openPlaylist, setOpenPlaylist] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const [playlistIsLoading, setPlaylistIsLoading] = useState(true);
  const [songId, setSongId] = useState(null);
  const [status, setStatus] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/music/artists/${slug}`,
      title: "Artists Detail",
    });
  }, []);

  const getArtistData = async () => {
    setArtistsIsLoading(true);
    try {
      const res = await axios.get(slugUrl, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user.token}` }),
        },
      });
      setStatus(null);
      if (res.status === 200) {
        if (res?.data) {
          setArtistData(res?.data);
          setIsFollowed(res?.data?.follower);
        }
        setArtistsIsLoading(false);
      }
    } catch (err) {
      setArtistsIsLoading(false);
      setStatus(err.response.status);
    }
  };

  const getRecentlyReleasedAlbums = async () => {
    try {
      const res = await axios.get(`${url}/albums`, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ARTIST_ALBUM_SECTION,
          orderBy: "release_date",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          setRecentReleasedAlbum(res?.data?.data);
        }
        setRecentReleasedAlbumIsLoading(false);
      }
    } catch (err) {
      setRecentReleasedAlbumIsLoading(false);
    }
  };

  const getMostLikedAlbums = async () => {
    try {
      const res = await axios.get(`${url}/albums`, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ARTIST_ALBUM_SECTION,
          orderBy: "like_count",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          setMostLikedAlbum(res?.data?.data);
        }
        setMostLikedAlbumIsLoading(false);
      }
    } catch (err) {
      setMostLikedAlbumIsLoading(false);
    }
  };

  const getMostPlayedAlbums = async () => {
    try {
      const res = await axios.get(`${url}/albums`, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ARTIST_ALBUM_SECTION,
          orderBy: "play_count",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          setMostPlayedAlbum(res?.data?.data);
        }
        setMostPlayedAlbumIsLoading(false);
      }
    } catch (err) {
      setMostPlayedAlbumIsLoading(false);
    }
  };

  const getRecentlyReleasedVideos = async () => {
    try {
      const res = await axios.get(`${url}/videos`, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ARTIST_ALBUM_SECTION,
          orderBy: "created_at",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          setRecentReleasedVideos(res?.data?.data);
        }
        setRecentReleasedVideosIsLoading(false);
      }
    } catch (err) {
      setRecentReleasedVideosIsLoading(false);
    }
  };

  const getMostLikedVideos = async () => {
    try {
      const res = await axios.get(`${url}/videos`, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ARTIST_ALBUM_SECTION,
          orderBy: "like_count",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          setMostLikedVideos(res?.data?.data);
        }
        setMostLikedVideosIsLoading(false);
      }
    } catch (err) {
      setMostLikedVideosIsLoading(false);
    }
  };

  const getMostPlayedVideos = async () => {
    try {
      const res = await axios.get(`${url}/videos`, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ARTIST_ALBUM_SECTION,
          orderBy: "play_count",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          setMostPlayedVideos(res?.data?.data);
        }
        setMostPlayedVideosIsLoading(false);
      }
    } catch (err) {
      setMostPlayedVideosIsLoading(false);
    }
  };

  const getUpcomingEvents = async () => {
    try {
      const res = await axios.get(`${url}/events`, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ARTIST_VIDEO_SECTION,
          type: "upcoming",
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          setUpcomingEvents(res?.data?.data);
        }
        setUpcomingEventsIsLoading(false);
      }
    } catch (err) {
      setUpcomingEventsIsLoading(false);
    }
  };

  const getPastEvents = async () => {
    try {
      const res = await axios.get(`${url}/events`, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ARTIST_VIDEO_SECTION,
          type: "past",
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          setPastEvents(res?.data?.data);
        }
        setPastEventsIsLoading(false);
      }
    } catch (err) {
      setPastEventsIsLoading(false);
    }
  };

  const getSongs = async () => {
    try {
      const res = await axios.get(`${url}/songs`, {
        headers: {
          Accept: "application/json",
          ...(user && { Authorization: `Bearer ${user?.token}` }),
        },
        params: {
          page: songsPageNumber,
          items: PER_PAGE_COUNT.ARTIST_ALBUM_SECTION,
        },
      });
      if (res.status === 200) {
        setSongsLastPageNumber(res?.data?.last_page);
        if (res?.data) {
          if (albumList?.length === 0) {
            setAlbumList(res?.data?.data);
          } else {
            setAlbumList((prev) => [...prev, ...res?.data?.data]);
          }
        }
        if (moreSongsIsLoading) {
          setMoreSongsIsLoading(false);
        }
        setSongsIsLoading(false);
      }
    } catch (err) {
      if (moreSongsIsLoading) {
        setMoreSongsIsLoading(false);
      }
      setSongsIsLoading(false);
    }
  };

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[3];
      setSlugUrl(`${SERVER_URL.GET_ARTIST_BY_SLUG}${u}`);
    }
  }, [pathname]);

  useEffect(() => {
    if (slugUrl) {
      getArtistData();
    }
  }, [slugUrl]);

  useEffect(() => {
    if (artistData?.id) {
      setUrl(`${SERVER_URL.GET_ARTIST_BY_ID}${artistData?.id}`);
    }
  }, [artistData?.id]);

  useEffect(() => {
    if (url) {
      getRecentlyReleasedAlbums();
      getMostLikedAlbums();
      getMostPlayedAlbums();
      getRecentlyReleasedVideos();
      getMostLikedVideos();
      getSongs();
      getMostPlayedVideos();
      getPastEvents();
      getUpcomingEvents();
    }
  }, [url]);

  const handlePlaylist = async (item) => {
    setOpenPlaylist(false);
    const body = {
      playlist_id: item?.id,
      song: songId,
    };
    try {
      const res = await axios.post(SERVER_URL.ADD_SONGS_TO_PLAYLIST, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to add song to the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  function handleAddToQueue(data, album) {
    ReactGA.event({
      category: "Queue",
      action: `Add to queue button clicked`,
      label: `Songs added to the queue`,
    });
    if (Array.isArray(data)) {
      data.map((item) => {
        const genre = album?.genres?.map(
          (i, index) =>
            `${i?.title}${index !== album?.genres?.length - 1 ? ", " : ""}`
        );
        const d = {
          ...item,
          subTitle: album?.title,
          thumbnail: album?.cover_image_url,
          footerText: genre[0],
          hlsSource: item?.media_url,
        };
        dispatch(addToQueue(d));
      });
    } else {
      const genre = album?.genres?.map(
        (item, index) =>
          `${item?.title}${index !== album?.genres?.length - 1 ? ", " : ""}`
      );
      const d = {
        ...data,
        subTitle: album?.title,
        thumbnail: album?.cover_image_url,
        footerText: genre[0],
        hlsSource: data?.media_url,
      };
      dispatch(addToQueue(d));
    }
  }

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const handleGetPlaylist = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_USER_PLAYLIST, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setPlaylist(res?.data?.data);
        }
        setPlaylistIsLoading(false);
      }
    } catch (err) {
      setPlaylist([]);
      setPlaylistIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      handleGetPlaylist();
    }
  }, [user]);

  useEffect(() => {
    if (artistData) {
      setPageTitle(`${artistData?.first_name} ${artistData?.last_name}`);
      handleGetMoreArtistsData();
    } else {
      setPageTitle("Artist Details");
    }
  }, [artistData]);

  useEffect(() => {
    if (url) {
      getSongs();
    }
  }, [songsPageNumber]);

  const handleAlbumsPagination = () => {
    if (songsLastPageNumber != null && songsPageNumber <= songsLastPageNumber) {
      setMoreSongsIsLoading(true);
      setSongsPageNumber(songsPageNumber + 1);
    }
  };

  const handleGetMoreArtistsData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ARTISTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ARTIST_ALBUM_SECTION,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data) {
          res?.data?.data?.map((item) => {
            if (item?.id !== artistData?.id) {
              setMoreFromArtists((prev) => [...prev, item]);
            }
          });
        }
        setMoreFromArtistIsLoading(false);
      }
    } catch (err) {
      setMoreFromArtistIsLoading(false);
    }
  };

  const StreamMusic = async (id) => {
    try {
      const res = await axios.get(`${SERVER_URL.STREAM_MUSIC}${id}`, {
        headers: {
          Accept: "application/json",
        },
      });
    } catch (err) {}
  };

  const handlePlayTrack = (item) => {
    ReactGA.event({
      category: "Play",
      action: `Play all button clicked`,
      label: `${item?.title} track is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play all from ${item?.title}`,
      label: `${item?.title} track is being played`,
    });
    const genre = item?.genres?.map(
      (i, index) =>
        `${i?.title}${index !== item?.genres?.length - 1 ? ", " : ""}`
    );
    const data = {
      isPlaying: true,
      musicArgs: {
        id: item.songs[0]?.id,
        title: item?.songs[0]?.title,
        subTitle: item?.title,
        hlsSource: item?.songs[0]?.media_url,
        thumbnail: item?.cover_image_url,
        footerText: genre[0],
        duration: item?.songs[0]?.duration,
        artist_id: item?.artists[0]?.id,
        isLiked: item?.songs[0]?.is_liked,
      },
    };
    StreamMusic(item.songs[0]?.id);
    handleAddToQueue(item?.songs?.slice(1, item?.songs?.length), item);
    dispatch(setMusic(data));
  };

  const handlePlay = (data, item) => {
    ReactGA.event({
      category: "Play",
      action: `Play Song Clicked`,
      label: `${data?.title} is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play Song: ${data?.title}`,
      label: `${data?.title} is being played`,
    });
    const genre = item?.genres?.map(
      (i, index) =>
        `${i?.title}${index !== item?.genres?.length - 1 ? ", " : ""}`
    );
    const d = {
      isPlaying: true,
      musicArgs: {
        id: data?.id,
        title: data?.title,
        subTitle: item?.title,
        hlsSource: data?.media_url,
        thumbnail: item?.cover_image_url,
        footerText: genre[0],
        duration: data?.duration,
        artist_id: item?.artists[0]?.id,
        isLiked: data?.is_liked,
      },
    };
    StreamMusic(data?.id);
    dispatch(setMusic(d));
  };

  const handlePlayNext = (data, album) => {
    ReactGA.event({
      category: "Play",
      action: `Play Next Clicked`,
      label: `${data?.title} is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play Next Clicked: ${data?.title}`,
      label: `${data?.title} is being played`,
    });
    const genre = album?.genres?.map(
      (item, index) =>
        `${item?.title}${index !== album?.genres?.length - 1 ? ", " : ""}`
    );
    const d = {
      ...data,
      subTitle: album?.title,
      thumbnail: album?.cover_image_url,
      footerText: genre[0],
      hlsSource: data?.media_url,
    };
    dispatch(playNextInQueue(d));
  };

  const handleTabUpdate = (event, tabNumber) => {
    setActiveTab(tabNumber);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  const AntTabs = styled(Tabs)({
    width: "100%",
    "& .MuiTabs-indicator": {
      display: "none",
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      minWidth: 0,
      [theme.breakpoints.up("sm")]: {
        minWidth: 0,
      },
      borderRadius: 11,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 16,
      color: "#ffffff",
      fontFamily: ['"Inter"'].join(","),
      "&:hover": {
        transition: " color 0.25s ease-in-out, color 0.35s ease-in-out",
        color: "#ff0000",
        opacity: 1,
      },
      "&.Mui-selected": {
        backgroundColor: "red",
        color: "#ffffff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#d1eaff",
      },
    })
  );

  function VideoList({ videos, size }) {
    return (
      <div className={ArtistStyles.flexDrR}>
        {videos.map((item, index) => {
          const date = ExtractDate(item.created_at);
          return (
            <VideoCard
              key={index}
              title={item.title}
              viewCount={item.watch_count}
              likeCount={item.like_count}
              subTitle={date}
              thumbnailLink={item.cover_image_url}
              onClick={() => handleClick(`/videos/${item.slug}`)}
              orientation={"portrait"}
              size={size}
            />
          );
        })}
      </div>
    );
  }

  function NoDataText() {
    return <div className={ArtistStyles.noDataText}>No Data Found</div>;
  }

  function albumsTab() {
    return (
      <>
        {recentReleasedAlbumsIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          recentReleasedAlbums &&
          recentReleasedAlbums?.length !== 0 && (
            <Label label={"Recent Releases"} size="md" />
          )
        )}
        {recentReleasedAlbumsIsLoading ? (
          <>
            <div className={ArtistStyles.albumsDiv}>
              {Array.from({ length: 6 }, (_, index) => (
                <div key={index} className={ArtistStyles.albumsSubDiv}>
                  <MusicCardLoader />
                </div>
              ))}
            </div>
            <div className={ArtistStyles.mgT} />
          </>
        ) : (
          recentReleasedAlbums &&
          recentReleasedAlbums?.length && (
            <>
              <AlbumsList albums={recentReleasedAlbums} />
              <ViewMore
                viewMoreOrFilter={true}
                onClick={() =>
                  handleClick(`/music/albums/all?artist=${artistData?.slug}`)
                }
              />
            </>
          )
        )}

        {mostLikedAlbumsIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          mostLikedAlbums &&
          mostLikedAlbums?.length !== 0 && (
            <Label label={"Most Liked"} size="md" />
          )
        )}
        {mostLikedAlbumsIsLoading ? (
          <div className={ArtistStyles.albumsDiv}>
            {Array.from({ length: 6 }, (_, index) => (
              <div key={index} className={ArtistStyles.albumsSubDiv}>
                <MusicCardLoader />
              </div>
            ))}
          </div>
        ) : (
          mostLikedAlbums &&
          mostLikedAlbums?.length !== 0 && (
            <AlbumsList albums={mostLikedAlbums} />
          )
        )}
        {(mostPlayedAlbumsIsLoading || mostPlayedAlbums) && (
          <div className={ArtistStyles.mgT} />
        )}

        {mostPlayedAlbumsIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          mostPlayedAlbums &&
          mostPlayedAlbums?.length !== 0 && (
            <Label label={"Most Played"} size="md" />
          )
        )}
        {mostLikedAlbumsIsLoading ? (
          <div className={ArtistStyles.albumsDiv}>
            {Array.from({ length: 6 }, (_, index) => (
              <div key={index} className={ArtistStyles.albumsSubDiv}>
                <MusicCardLoader />
              </div>
            ))}
          </div>
        ) : (
          mostPlayedAlbums &&
          mostPlayedAlbums?.length !== 0 && (
            <AlbumsList albums={mostPlayedAlbums} />
          )
        )}

        {!recentReleasedAlbumsIsLoading &&
          !mostLikedAlbumsIsLoading &&
          !mostPlayedAlbumsIsLoading &&
          (!recentReleasedAlbums || recentReleasedAlbums?.length === 0) &&
          (!mostLikedAlbums || mostLikedAlbums?.length === 0) &&
          (!mostPlayedAlbums || mostPlayedAlbums?.length === 0) && (
            <NoDataText />
          )}
      </>
    );
  }

  const handleLike = async (data) => {
    try {
      const res = await axios.get(`${SERVER_URL.LIKE_SONG}${data?.id}/like`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (res.status === 200) {
        ReactGA.event({
          category: "Song",
          action: `Liked/Unliked a song`,
          label: `Liked/Unliked a song: ${data?.title}`,
        });
        ReactGA.event({
          category: "Song",
          action: `Liked/Unliked a song: ${data?.title}`,
          label: `Liked/Unliked a song: ${data?.title}`,
        });
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to like the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const onCreatePlaylist = async (item) => {
    try {
      const res = await axios.post(SERVER_URL.GET_PLAYLIST, item, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "New playlist created",
        });
        setPlaylistIsLoading(true);
        handleGetPlaylist();
        setOpenPlaylist(false);
        Swal.fire({
          title: "Playlist Created Successfully",
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        }).then(() => {
          setOpenPlaylist(true);
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to create the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleOpenPlaylist = (item) => {
    setSongId(item?.id);
    setOpenPlaylist(true);
    ReactGA.event({
      category: "Playlist",
      action: "Add to playlist button clicked",
      label: item?.title,
    });
    ReactGA.event({
      category: "Playlist",
      action: `${item?.title} added to playlist`,
      label: item?.title,
    });
  };

  const handleDownload = async (song) => {
    if (!song?.media_url) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const response = await fetch(song.media_url);
      if (!response.ok) {
        Swal.fire({
          title: "Unable to download the song",
          icon: "error",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const fileExtension = song?.media_url?.split(".").pop(); // Assuming the extension is present in the URL
      const fileName =
        song?.title?.replace(/\s+/g, "-").toLowerCase() + "." + fileExtension;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      ReactGA.event({
        category: "Download",
        action: "Download button clicked",
        label: fileName,
      });
      ReactGA.event({
        category: "Download",
        action: `${fileName} is downloaded`,
        label: fileName,
      });
    } catch (error) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  function songsTab() {
    return (
      <>
        {songsIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          albumList?.length !== 0 && (
            <Label label={"Songs by Album"} size="md" />
          )
        )}
        {songsIsLoading ? (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            {Array.from({ length: 2 }, (_, index) => {
              return (
                <Skeleton
                  key={index}
                  sx={{ bgcolor: "grey.900", borderRadius: 10 }}
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={300}
                />
              );
            })}
          </div>
        ) : (
          albumList?.length !== 0 && (
            <>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                {albumList.map((item, index) => (
                  <AlbumSongs
                    key={index}
                    width={"100%"}
                    tracksList={item?.songs}
                    albumName={item?.title}
                    genres={item?.genres}
                    albumArt={item?.cover_image_url}
                    handleAddToQueue={handleAddToQueue}
                    gradientColors={gradientColors[index]}
                    handlePlayTrack={() => handlePlayTrack(item)}
                    handlePlayNext={handlePlayNext}
                    handlePlay={handlePlay}
                    handleLike={(item) => {
                      isLoggedIn ? handleLike(item) : handleLogin();
                    }}
                    handleAddToPlaylist={(item) => {
                      isLoggedIn ? handleOpenPlaylist(item) : handleLogin();
                    }}
                    album={item}
                    handleDownload={handleDownload}
                  />
                ))}
              </div>
              {moreSongsIsLoading && (
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  {Array.from({ length: 2 }, (_, index) => {
                    return (
                      <Skeleton
                        key={index}
                        sx={{ bgcolor: "grey.900", borderRadius: 10 }}
                        animation="wave"
                        variant="rectangular"
                        width={"100%"}
                        height={300}
                      />
                    );
                  })}
                </div>
              )}
              {songsLastPageNumber != null &&
                songsPageNumber < songsLastPageNumber &&
                !moreSongsIsLoading &&
                albumList?.length !== 0 && (
                  <ViewMore loadMore={true} onClick={handleAlbumsPagination} />
                )}
            </>
          )
        )}
        {playlistIsLoading && openPlaylist ? (
          <Modal open={openPlaylist} onClose={() => setOpenPlaylist(false)}>
            <Skeleton
              sx={{ bgcolor: "grey.900", borderRadius: 10 }}
              animation="wave"
              variant="rounded"
              width={"40%"}
              height={300}
            />
          </Modal>
        ) : (
          openPlaylist &&
          !playlistIsLoading && (
            <Modal open={openPlaylist} onClose={() => setOpenPlaylist(false)}>
              <Playlist
                playlist={playlist}
                onClick={onCreatePlaylist}
                onPlaylistClick={handlePlaylist}
              />
            </Modal>
          )
        )}
        {!songsIsLoading && albumList?.length === 0 && <NoDataText />}
      </>
    );
  }

  function musicVideosTab() {
    return (
      <>
        {recentReleasedVideosIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          recentReleasedVideos &&
          recentReleasedVideos?.length !== 0 && (
            <Label label={"Recent Releases"} size="md" />
          )
        )}
        {recentReleasedVideosIsLoading ? (
          <>
            <div className={ArtistStyles.videosTab}>
              {Array.from({ length: 6 }, (_, index) => {
                return <VideoCardLoader key={index} size="sm" />;
              })}
            </div>
            <div className={ArtistStyles.mgT} />
          </>
        ) : (
          recentReleasedVideos &&
          recentReleasedVideos?.length !== 0 && (
            <>
              <VideoList size={"sm"} videos={recentReleasedVideos} />
              <ViewMore
                onClick={() =>
                  handleClick(`/videos/all?artist=${artistData?.slug}`)
                }
              />
            </>
          )
        )}

        {mostLikedVideosIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          mostLikedVideos &&
          mostLikedVideos?.length !== 0 && (
            <Label label={"Most Liked"} size="md" />
          )
        )}
        <div className={ArtistStyles.videosTab}>
          {mostLikedVideosIsLoading
            ? Array.from({ length: 6 }, (_, index) => {
                return <VideoCardLoader key={index} size="sm" />;
              })
            : mostLikedVideos &&
              mostLikedVideos?.length !== 0 && (
                <>
                  <VideoList size={"sm"} videos={mostLikedVideos} />
                </>
              )}
        </div>
        {mostLikedVideos && mostLikedVideos?.length !== 0 && (
          <div className={ArtistStyles.mgT} />
        )}

        {mostPlayedVideosIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          mostPlayedVideos &&
          mostPlayedVideos?.length !== 0 && (
            <Label label={"Most Played"} size="md" />
          )
        )}
        <div className={ArtistStyles.videosTab}>
          {mostPlayedVideosIsLoading
            ? Array.from({ length: 6 }, (_, index) => {
                return <VideoCardLoader key={index} size="sm" />;
              })
            : mostPlayedVideos &&
              mostPlayedVideos?.length !== 0 && (
                <VideoList size={"sm"} videos={mostPlayedVideos} />
              )}
        </div>

        {!recentReleasedVideosIsLoading &&
          !mostLikedVideosIsLoading &&
          !mostPlayedVideosIsLoading &&
          (!recentReleasedVideos || recentReleasedVideos?.length === 0) &&
          (!mostLikedVideos || mostLikedVideos?.length === 0) &&
          (!mostPlayedVideos || mostPlayedVideos?.length === 0) && (
            <NoDataText />
          )}
      </>
    );
  }

  function eventsTab() {
    return (
      <>
        {upcomingEventsIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          upcomingEvents &&
          upcomingEvents?.length !== 0 && (
            <Label label={"Upcoming Events"} size="md" />
          )
        )}
        <div className={ArtistStyles.videosTab}>
          {upcomingEventsIsLoading
            ? Array.from({ length: 4 }, (_, index) => {
                return (
                  <div key={index} style={{ minWidth: "49%" }}>
                    <EventLongCardLoader />
                  </div>
                );
              })
            : upcomingEvents &&
              upcomingEvents?.length !== 0 && (
                <EventList events={upcomingEvents} size="free" />
              )}
        </div>
        {((upcomingEvents && upcomingEvents?.length !== 0) ||
          upcomingEventsIsLoading) && <div className={ArtistStyles.mgT} />}

        {pastEventsIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          pastEvents &&
          pastEvents?.length !== 0 && (
            <Label
              label={"Past Events"}
              size="md"
              style={{ marginBottom: 5 }}
            />
          )
        )}
        <div className={ArtistStyles.videosTab}>
          {pastEventsIsLoading
            ? Array.from({ length: 4 }, (_, index) => {
                return (
                  <div key={index} style={{ minWidth: "49%" }}>
                    <EventLongCardLoader />
                  </div>
                );
              })
            : pastEvents &&
              pastEvents?.length !== 0 && (
                <EventList events={pastEvents} size="free" />
              )}
        </div>

        {!upcomingEventsIsLoading &&
          !pastEventsIsLoading &&
          (!upcomingEvents || upcomingEvents?.length === 0) &&
          (!pastEvents || pastEvents?.length === 0) && <NoDataText />}
      </>
    );
  }

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  const handleFollow = async () => {
    setIsFollowing(true);
    try {
      const res = await axios.get(`${url}/follow`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (res.status === 200) {
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
        setIsFollowing(false);
        setIsFollowed(!isFollowed);
        if (isFollowed) {
          ReactGA.event({
            category: "Unfollow",
            action: `Unfollow an artist`,
            label: `${artistData?.first_name} ${artistData?.last_name}`,
          });
          ReactGA.event({
            category: "Unfollow",
            action: `Unfollow an artist ${artistData?.first_name} ${artistData?.last_name}`,
            label: `${artistData?.first_name} ${artistData?.last_name}`,
          });
        } else {
          ReactGA.event({
            category: "Follow",
            action: `Follow an artist`,
            label: `${artistData?.first_name} ${artistData?.last_name}`,
          });
          ReactGA.event({
            category: "Follow",
            action: `Follow an artist ${artistData?.first_name} ${artistData?.last_name}`,
            label: `${artistData?.first_name} ${artistData?.last_name}`,
          });
        }
      }
    } catch (err) {
      setIsFollowing(false);
      Swal.fire({
        title: "Unable to follow the artist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  function openLink(item, platform) {
    const getLink = `${item}`;
    const aTag = document.createElement("a");
    aTag.setAttribute("href", getLink);
    aTag.setAttribute("target", "_blank");
    aTag.click();
    ReactGA.event({
      category: "Open",
      action: `Artist's ${platform} opened`,
      label: `Artist - ${artistData?.first_name} ${artistData?.last_name} ${platform} opened`,
    });
  }

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <>
      <NavContainer>
        <MetaTags
          author={"Trini Jungle Juice"}
          title={`${artistData?.first_name} ${artistData?.last_name}`}
          url={window.location.href}
          type={"website"}
          image={artistData?.profile_photo_url}
          description={artistData?.biograpghy}
        />
        <Grid container>
          <Grid
            item
            xs={12}
            sm={!artistsIsLoading && !artistData ? 12 : 4}
            md={!artistsIsLoading && !artistData ? 12 : 3}
            className={ArtistStyles.gridContainer1}
          >
            {artistsIsLoading ? (
              <>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                  height={40}
                />
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginTop: 3, marginBottom: 2 }}
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={200}
                />
                {Array.from({ length: 4 }, (_, index) => {
                  return (
                    <Skeleton
                      key={index}
                      sx={{
                        bgcolor: "grey.900",
                      }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                    />
                  );
                })}
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginBottom: 2 }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                />
              </>
            ) : (
              artistData && (
                <>
                  <Label
                    label={`Artist - ${artistData?.first_name} ${artistData?.last_name}`}
                    size="md"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <img
                      className={ArtistStyles.artistImage}
                      src={
                        artistData?.profile_photo_url
                          ? artistData?.profile_photo_url
                          : `https://via.placeholder.com/640x480.png/0088aa?text=${artistData.first_name}%20${artistData.last_name}`
                      }
                      alt="Artist"
                    />
                    <div className={ArtistStyles.vtArtistFollow}>
                      <Button
                        label={
                          artistData?.follower
                            ? "Unfollow"
                            : isFollowed
                              ? "Unfollow"
                              : "Follow"
                        }
                        iconLeft={true}
                        icon={
                          artistData?.follower ? undefined : (
                            <BsPlus className={ArtistStyles.follow} />
                          )
                        }
                        onClick={() => {
                          isLoggedIn ? handleFollow() : handleLogin();
                        }}
                        loading={isFollowing}
                      />
                      {convertNumUnit(artistData?.followers_count)}{" "}
                      {artistData?.followers_count <= 1
                        ? "follower"
                        : "followers"}
                    </div>
                    <div
                      className={ArtistStyles.vtArtistDescription}
                      dangerouslySetInnerHTML={{
                        __html: artistData?.biography,
                      }}
                    />
                    <div className={ArtistStyles.iconsWrap}>
                      {artistData?.facebook_url && (
                        <FaFacebookF
                          onClick={() => {
                            const facebookUrl = artistData?.facebook_url;
                            openLink(facebookUrl, "facebook");
                          }}
                          className={ArtistStyles.icon}
                        />
                      )}
                      {artistData?.twitter_url && (
                        <FaXTwitter
                          onClick={() => {
                            const twitterUrl = artistData?.twitter_url;
                            openLink(twitterUrl, "twitter");
                          }}
                          className={ArtistStyles.icon}
                        />
                      )}
                      {artistData?.instagram_url && (
                        <RiInstagramFill
                          onClick={() => {
                            const instagramUrl = artistData?.instagram_url;
                            openLink(instagramUrl, "instagram");
                          }}
                          className={ArtistStyles.icon}
                        />
                      )}
                      {artistData?.tiktok_url && (
                        <FaTiktok
                          onClick={() => {
                            const tiktokUrl = artistData?.tiktok_url;
                            openLink(tiktokUrl, "tiktok");
                          }}
                          className={ArtistStyles.icon}
                        />
                      )}
                      {artistData?.website_url && (
                        <img
                          src={require("../../assets/images/website.png")}
                          className={ArtistStyles.imgSocial}
                          onClick={() => {
                            const websiteUrl = artistData?.website_url;
                            openLink(websiteUrl, "website");
                          }}
                        />
                      )}
                    </div>
                  </div>
                </>
              )
            )}
            {artistData && (
              <div className={ArtistStyles.tabsMainDiv}>
                <AntTabs
                  orientation="vertical"
                  variant="scrollable"
                  value={activeTab}
                  onChange={handleTabUpdate}
                >
                  <AntTab label="Albums" />
                  <AntTab label="Songs" />
                  <AntTab label="Music Videos" />
                  <AntTab label="Events" />
                </AntTabs>
              </div>
            )}
            {!artistsIsLoading && !artistData && <NoData />}
          </Grid>
          <Grid
            item
            xs={12}
            sm={!artistsIsLoading && !artistData ? 0 : 8}
            md={!artistsIsLoading && !artistData ? 0 : 9}
          >
            <TabPanel value={activeTab} index={0}>
              {albumsTab()}
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              {songsTab()}
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              {musicVideosTab()}
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              {eventsTab()}
            </TabPanel>
          </Grid>
          <div className={ArtistStyles.mgT}>
            {moreFromArtistIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 3 }}
                animation="wave"
                variant="text"
                width={"40%"}
                height={50}
              />
            ) : (
              moreFromArtists?.length !== 0 && (
                <Label label={"More Artists"} size="md" />
              )
            )}
          </div>
          <div className={ArtistStyles.albumsDiv}>
            {moreFromArtistIsLoading
              ? Array.from({ length: 5 }, (_, index) => {
                  return (
                    <div key={index} className={ArtistStyles.albumsSubDiv}>
                      <ArtistAndDjCardLoader />
                    </div>
                  );
                })
              : moreFromArtists?.length !== 0 && (
                  <ArtistList artists={moreFromArtists.slice(0, 5)} />
                )}
          </div>
          <div style={{ marginTop: 40 }} />
        </Grid>
      </NavContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
});

export default connect(mapStateToProps)(ArtistPage);
