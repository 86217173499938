import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import AccountsCss from "./AccountsCss.module.css";
import { useNavigate, useLocation } from "react-router";
import Button from "../../components/button/Button";
import { Delete, Edit } from "@mui/icons-material";
import IconButton from "../../components/icon-button/IconButton";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip";
import MetaTags from "../../MetaTags";
import ViewMore from "../../elements/view-more/ViewMore";
import { Context } from "../../Context";
import { Modal, Skeleton } from "@mui/material";
import { connect } from "react-redux";
import VideoCard from "../../components/video-card/VideoCard";
import { ExtractDate } from "../../elements/date-extractor/ExtractDate";
import { useLoggedInState } from "../../elements/logged-in-state/useLoggedInState";
import Swal from "sweetalert2";
import VideoCardLoader from "../../elements/skeletonLoaders/VideoCardLoader";
import { SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import EditItinerary from "./elements/EditItinerary.js";
import Unauthorized from "../no-data/Unauthorized.js";
import ReactGA from "react-ga4";

const MyVideoPlaylist = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const isLoggedIn = useLoggedInState();
  const navigate = useNavigate();
  const { setPageTitle } = useContext(Context);
  const { pathname } = useLocation();
  const [tempId, setTempId] = useState(null);
  const [videosData, setVideosData] = useState(null);
  const [openId, setOpenId] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    if (tempId) {
      ReactGA.send({
        hitType: "pageview",
        page: `/my-video-playlist/${tempId}`,
        title: "My Video Playlist",
      });
    }
  }, [tempId]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      const id = u?.split("-")[2];
      setTempId(id);
    }
  }, [pathname]);

  useEffect(() => {
    if (isAuthorized) {
      handleGetVideosPlaylists();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (tempId && user?.id) {
      const authorize = parseInt(tempId) === parseInt(user?.id);
      setIsAuthorized(authorize);
    }
  }, [tempId, user]);

  const handleGetVideosPlaylists = async () => {
    try {
      const res = await axios.get(
        `${SERVER_URL.GET_USER_VIDEO_PLAYLISTS_BY_ID}${tempId}/playlists`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setVideosData(res?.data?.data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isAuthorized) {
      setPageTitle("My Video Playlist");
    }
  }, [isAuthorized]);

  function VideoList({ videos, playlistId }) {
    return (
      <div className={AccountsCss.flexDrR}>
        {videos?.map((item, index) => {
          const date = ExtractDate(item.created_at);
          return (
            <div className={AccountsCss.videoDiv}>
              <VideoCard
                key={index}
                title={item?.title}
                viewCount={item?.watch_count}
                likeCount={item?.like_count}
                subTitle={date}
                thumbnailLink={item.cover_image_url}
                onClick={() => handleClick(`/videos/${item?.slug}`)}
                orientation={"portrait"}
                size={"sm"}
              />
              {isLoggedIn && parseInt(tempId) === user?.id && (
                <IconButton
                  backgroundColor={"#1C1C1C"}
                  icon={<Delete className={AccountsCss.dltIcon} />}
                  onClick={() =>
                    handleDeleteFromVideoPlaylist(playlistId, item?.id)
                  }
                  styles={{
                    position: "absolute",
                    top: -15,
                    right: -15,
                    width: 30,
                    height: 30,
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  const handleEditVideoPlaylist = async (id, body) => {
    try {
      const res = await axios.post(
        `${SERVER_URL.EDIT_VIDEO_PLAYLISTS}${id}/update-playlist`,
        body,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Edit playlist button clicked",
          label: "A playlist has been edited on video playlist page",
        });
        setIsLoading(true);
        handleGetVideosPlaylists();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to edit the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteVideoPlaylist = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.DELETE_VIDEO_PLAYLISTS}${id}/delete-playlist`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Delete playlist button clicked",
          label: "A playlist has been delete from video playlist page",
        });
        setIsLoading(true);
        handleGetVideosPlaylists();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteFromVideoPlaylist = async (id, videoId) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.DELETE_VIDEO_FROM_PLAYLISTS}${id}/${videoId}/delete`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Delete video from playlist button clicked",
          label:
            "A video has been delete from a playlist on video playlist page",
        });
        setIsLoading(true);
        handleGetVideosPlaylists();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete from the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Video Playlist's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `${label} playlist Shared on ${item}`,
      label: label,
    });
  }

  if (isAuthorized === false) {
    return <Unauthorized />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"My Video Playlist"}
        url={window.location.href}
        type={"website"}
      />
      <Label label={"My Video Playlist"} size="lg" />
      {isLoading ? (
        Array.from({ length: 4 }, (_, index) => (
          <div key={index} style={{ marginBottom: 30 }}>
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 2 }}
              animation="wave"
              variant="rectangular"
              width={"20%"}
              height={30}
            />
            <div className={AccountsCss.flexDrR}>
              {Array.from({ length: 4 }, (_, index) => (
                <VideoCardLoader key={index} size={"sm"} />
              ))}
            </div>
          </div>
        ))
      ) : videosData && videosData?.length !== 0 ? (
        videosData?.map((item, index) => (
          <div key={index}>
            <div className={AccountsCss.mainDiv}>
              <Label label={item?.title} size="md" />
              {isLoggedIn &&
                parseInt(tempId) === user?.id &&
                (width < 500 ? (
                  <div className={AccountsCss.buttonDiv}>
                    <SharingTooltip
                      onShare={handleShare}
                      transparent
                      link={`https://${window.location.href?.split("/")[2]}/videos/playlist/${item?.slug}`}
                    />
                    <IconButton
                      backgroundColor={"transparent"}
                      onClick={() => {
                        setOpen(true);
                        setOpenId(item?.id);
                      }}
                      icon={<Edit className={AccountsCss.closeIcon} />}
                    />
                    <IconButton
                      backgroundColor={"transparent"}
                      icon={<Delete className={AccountsCss.closeIcon} />}
                      onClick={() => handleDeleteVideoPlaylist(item?.id)}
                    />
                  </div>
                ) : (
                  <div className={AccountsCss.buttonDiv}>
                    <SharingTooltip
                      onShare={handleShare}
                      withText
                      showAnimation={true}
                      backgroundColor={"#1C1C1C"}
                      hoverColor={"#ff0000"}
                      link={`https://${window.location.href?.split("/")[2]}/videos/playlist/${item?.slug}`}
                    />
                    <Button
                      label="Edit"
                      showAnimation={true}
                      backgroundColor={"#1C1C1C"}
                      hoverColor={"#ff0000"}
                      icon={<Edit className={AccountsCss.closeIcon} />}
                      iconLeft={true}
                      onClick={() => {
                        setOpen(true);
                        setOpenId(item?.id);
                      }}
                    />
                    <Button
                      label="Delete"
                      showAnimation={true}
                      backgroundColor={"#1C1C1C"}
                      hoverColor={"#ff0000"}
                      icon={<Delete className={AccountsCss.closeIcon} />}
                      iconLeft={true}
                      onClick={() => handleDeleteVideoPlaylist(item?.id)}
                    />
                  </div>
                ))}

              {open && openId === item?.id && (
                <Modal
                  open={open}
                  onClose={() => setOpen(false)}
                  className={AccountsCss.modalDiv}
                >
                  <EditItinerary
                    onClick={(i) => handleEditVideoPlaylist(item?.id, i)}
                    setOpen={(bool) => setOpen(bool)}
                    name={item?.title}
                    itineraryDescription={item?.description}
                    isPlaylist={true}
                  />
                </Modal>
              )}
            </div>
            {item?.videos && item?.videos?.length === 0 && (
              <>
                <div className={AccountsCss.flexDrC}>
                  <div className={AccountsCss.noItems}>No items added yet</div>
                  {isLoggedIn && parseInt(tempId) === user?.id && (
                    <>
                      <div className={AccountsCss.noItemsSub}>To add items</div>
                      <Button
                        label="Click Here"
                        backgroundColor={"#ff0000"}
                        styles={{ alignSelf: "center" }}
                        onClick={() => handleClick("/videos")}
                      />
                    </>
                  )}
                </div>
              </>
            )}

            {item?.videos && item?.videos?.length !== 0 && (
              <VideoList
                playlistId={item?.id}
                videos={item?.videos?.slice(0, 4)}
              />
            )}
            {item?.videos &&
            item?.videos?.length !== 0 &&
            item?.videos?.length >= 4 ? (
              <ViewMore onClick={() => handleClick(`${item?.slug}`)} />
            ) : (
              <div className={AccountsCss.mgb} />
            )}
          </div>
        ))
      ) : (
        <div className={AccountsCss.noItems}>
          No playlist added,{" "}
          <span
            onClick={() => handleClick("/videos")}
            className={AccountsCss.startButton}
          >
            Start
          </span>{" "}
          adding to your Video Playlist
        </div>
      )}
    </NavContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps)(MyVideoPlaylist);
