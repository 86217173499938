import React, { useEffect, useMemo, useRef, useState } from "react";
import EventCss from "./EventSlides.module.css";
import Card from "../../components/card/Card";
import Slider from "react-slick";
import { useNavigate } from "react-router";
import { LocationExtractor } from "../location-extractor/LocationExtractor";

export default function EventSlides({ data }) {
  const [width, setWidth] = useState(window.innerWidth);
  let sliderRef = useRef();
  let divRef = useRef();
  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const isVisible = useIsInViewport(divRef);

  const play = () => {
    sliderRef.current.slickPlay();
  };
  const pause = () => {
    sliderRef.current.slickPause();
  };

  useEffect(() => {
    if (isVisible) {
      play();
    } else {
      pause();
    }
  }, [isVisible]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      if (window.innerWidth > 431) {
        sliderRef.current.slickGoTo(0);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getSlides() {
    return data?.map((item, index) => {
      const loc = LocationExtractor(item?.location);
      return (
        <div key={index}>
          <div className={EventCss.eventSlidesDiv}>
            <Card
              title={item?.title}
              imageSrc={item?.poster_url}
              content={
                loc !== "TBA" && <div className={EventCss.subText}>{loc}</div>
              }
              textAlign={"center"}
              onClick={() => handleClick(`/events/${item?.slug}`)}
              size={"sm"}
              imageProps={
                width < 431
                  ? { height: 300 }
                  : {
                      height: 350,
                    }
              }
            />
          </div>
        </div>
      );
    });
  }

  var settings = {
    dots: false,
    infinite: data?.length < 4 ? false : true,
    autoplay: data?.length < 4 ? false : true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: false,
    swipeToSlide: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1154,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 712,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div ref={divRef}>
      <Slider ref={sliderRef} {...settings}>
        {getSlides()}
      </Slider>
    </div>
  );
}

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        []
      ),
    []
  );

  useEffect(() => {
    observer?.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
