import React, { useState, useEffect, useContext } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import { Grid, Skeleton } from "@mui/material";
import VideoCss from "./Video.module.css";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Context } from "../../Context";
import { useNavigate } from "react-router";
import VideosFilter from "./elements/VideosFilter";
import { ExtractDate } from "../../elements/date-extractor/ExtractDate";
import VideoCard from "../../components/video-card/VideoCard";
import { useSearchParams, useLocation } from "react-router-dom";
import CategoriesList from "../../elements/categories/CategoriesList";
import TagsList from "../../elements/Flatlist/TagsList";
import ViewMore from "../../elements/view-more/ViewMore";
import { FaSortAmountDownAlt } from "react-icons/fa";
import { FaSortAmountUp } from "react-icons/fa";
import TextField from "../../components/textfield/TextField";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import NoData from "../../elements/no-data/NoData.js";
import VideoCardLoader from "../../elements/skeletonLoaders/VideoCardLoader.js";
import ReactGA from "react-ga4";
import DynamicContentInjector from "../../elements/DynamicContentInjector/DynamicContentInjector.js";

const options = [
  {
    label: "Date",
    value: "created_at",
  },
  {
    label: "Views",
    value: "watch_count",
  },
  {
    label: "Rating",
    value: "like_count",
  },
  {
    label: "Title",
    value: "title",
  },
];

export default function AllVideosWithFilter() {
  const [width, setWidth] = useState(window.innerWidth);
  const [showFilter, setShowFilter] = useState(false);
  const { setPageTitle } = useContext(Context);
  const [videos, setVideos] = useState([]);
  const [videosIsLoading, setVideosIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const navigate = useNavigate();
  const [optionalTitle, setOptionalTitle] = useState("");
  const [tag, setTag] = useState([]);
  const [allTag, setAllTag] = useState([]);
  const [orderDirection, setOrderDirection] = useState(null);
  const [title, setTitle] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [videosPageNumber, setVideosPageNumber] = useState(1);
  const [videosLastPage, setVideosLastPage] = useState(null);
  const [type, setType] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({});
  const [moreVideosIsLoading, setMoreVideosIsLoading] = useState(false);
  const [tagsIsLoading, setTagsIsLoading] = useState(true);
  const [categoriesIsLoading, setCategoriesIsLoading] = useState(true);
  const [allTagsIsLoading, setAllTagsIsLoading] = useState(true);
  const [allCategoriesIsLoading, setAllCategoriesIsLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [artists, setArtists] = useState([]);
  const [artistsIsLoading, setArtistsIsLoading] = useState(true);
  const [bannerAd, setBannerAd] = useState(null);
  const [triggerReset, setTriggerReset] = useState(false);
  const [status, setStatus] = useState(null);
  const [bannerAd2, setBannerAd2] = useState(null);

  const handleGetSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "sidebar ad square",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd(res.data);
        }
      }
    } catch (err) {
      setBannerAd(null);
    }
  };

  const handleGetSecondSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "skyscraper ad",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd2(res.data);
        }
      }
    } catch (err) {
      setBannerAd2(null);
    }
  };

  useEffect(() => {
    handleGetSidebarBanner();
    handleGetSecondSidebarBanner();
  }, []);

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  useEffect(() => {
    if (title) {
      if (searchParams.size !== 0 && searchParams.get("sortby")) {
        handleSortBy(searchParams.get("sortby"));
      } else {
        if (type === "popular") {
          setOrderBy("watch_count");
        } else if (type === "trending") {
          setOrderBy("like_count");
        } else {
          setOrderBy("created_at");
        }
      }

      if (searchParams.size !== 0 && searchParams.get("orderby")) {
        handleOrder(searchParams.get("orderby"));
      } else {
        setOrderDirection("desc");
      }
    }
  }, [searchParams, title, type]);

  const pathname = useLocation();

  useEffect(() => {
    const u = pathname?.pathname?.split("/")[2];
    const t = pathname?.pathname?.split("/")[2]?.replace("-", " ") + " Videos";
    setTitle(t);
    setPageTitle(t);
    if (u === "featured") {
      setType("featured");
    }
    if (u === "popular") {
      setType("popular");
    }
    if (u === "top-trending") {
      setType("trending");
    }
    if (u === "all") {
      setType("");
    }
    ReactGA.send({
      hitType: "pageview",
      page: `/videos/${u}`,
      title: "All Videos",
    });
  }, [pathname]);

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getCategories();
    getTags();
    getArtist();
    getAllTags();
    getAllCategories();
  }, []);

  const handleNavigate = (param) => {
    setTriggerReset(true);
    let params = {};
    if (param?.category) {
      params = {
        ...params,
        category: [param?.category],
      };
    }
    if (param?.tag) {
      params = {
        ...params,
        tag: [param?.tag],
      };
    }
    setTimeout(() => {
      handleSearch(params);
      window.scrollTo(0, 0);
    }, 1000);
  };

  useEffect(() => {
    if (searchParams.size !== 0) {
      let params = {};
      if (searchParams?.getAll("tag")?.length !== 0) {
        params = {
          ...params,
          tag: searchParams.getAll("tag"),
        };
      }
      if (searchParams?.get("search") !== null) {
        params = {
          ...params,
          search: searchParams.get("search"),
        };
      }
      if (searchParams?.getAll("category")?.length !== 0) {
        params = {
          ...params,
          category: searchParams.getAll("category"),
        };
      }
      if (searchParams?.getAll("artist")?.length !== 0) {
        params = {
          ...params,
          artist: searchParams.getAll("artist"),
        };
      }
      if (searchParams?.get("startDate")) {
        params = {
          ...params,
          startDate: searchParams.get("startDate"),
        };
      }
      if (searchParams?.get("endDate")) {
        params = {
          ...params,
          endDate: searchParams.get("endDate"),
        };
      }

      if (
        params &&
        Object.keys(params).length > 0 &&
        Object.keys(filter)?.length === 0
      ) {
        handleSearch(params);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.size !== 0) {
      if (searchParams.size === 1) {
        if (searchParams?.get("tag") !== null) {
          const c = tag?.filter(
            (i) => i.slug === searchParams?.get("category")
          )[0]?.name;
          const tagN =
            c === undefined ? searchParams?.get("tag").replace("-", " ") : c;
          setOptionalTitle(`By Tag: ${tagN}`);
        }
        if (searchParams?.get("category") !== null) {
          const c = categories?.filter(
            (i) => i.slug === searchParams?.get("category")
          )[0]?.title;
          const category =
            c === undefined
              ? searchParams?.get("category").replace("-", " ")
              : c;
          setOptionalTitle(`By Category: ${category}`);
        }
      } else {
        setOptionalTitle("Filtered Results");
      }
    } else {
      setOptionalTitle("");
    }
  }, [searchParams, categories, tag]);

  function updateVideoPageNumber() {
    if (videosLastPage != null && videosPageNumber <= videosLastPage) {
      setMoreVideosIsLoading(true);
      setVideosPageNumber(videosPageNumber + 1);
    }
  }

  const handleSortBy = (value) => {
    handleSearchParam("sortby", value);
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results`,
      label: `Sort by ${value} on all videos page`,
    });
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results on all videos page`,
      label: `Sort by ${value} on all videos page`,
    });
    setOrderBy(value);
  };

  const handleOrder = (item) => {
    handleSearchParam("orderby", item);
    if (item === "asc") {
      setOrderDirection("asc");
    } else {
      setOrderDirection("desc");
    }
  };

  const handleGetVideos = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_VIDEOS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: videosPageNumber,
          items: PER_PAGE_COUNT.ALL_VIDEOS,
          orderBy: orderBy,
          orderDirection: orderDirection,
          ...(type !== "" && { type: type }),
          ...(filter?.search && {
            search: filter?.search,
          }),
          ...(filter?.tag && {
            tags: filter?.tag,
          }),
          ...(filter?.category && {
            categories: filter?.category,
          }),
          ...(filter?.artist && {
            artists: filter?.artist,
          }),
          ...(filter?.startDate && {
            startDate: filter?.startDate,
          }),
          ...(filter?.endDate && {
            endDate: filter?.endDate,
          }),
        },
      });
      if (res.status === 200) {
        setStatus(res.status);
        setVideosLastPage(res?.data?.last_page);
        if (videos?.length === 0) {
          setVideos(res?.data?.data);
        } else {
          setVideos((prev) => [...prev, ...res?.data?.data]);
        }
        if (moreVideosIsLoading) {
          setMoreVideosIsLoading(false);
        }
        setVideosIsLoading(false);
      }
    } catch (err) {
      setVideosIsLoading(false);
    }
  };

  const getCategories = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_VIDEO_CATEGORIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
        },
      });
      if (res.status === 200) {
        if (res?.data?.categories?.data?.length !== 0) {
          setCategories(res?.data?.categories?.data);
        }
        setCategoriesIsLoading(false);
      }
    } catch (err) {
      setCategoriesIsLoading(false);
    }
  };

  const getAllCategories = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_VIDEO_CATEGORIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.categories?.data?.length !== 0) {
          setAllCategories(res?.data?.categories?.data);
        }
        setAllCategoriesIsLoading(false);
      }
    } catch (err) {
      setAllCategoriesIsLoading(false);
    }
  };

  const getArtist = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ARTISTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setArtists(res?.data?.data);
        }
        setArtistsIsLoading(false);
      }
    } catch (err) {
      setArtistsIsLoading(false);
    }
  };

  const getTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
          type: "video",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setTag(res?.data?.data);
        }
        setTagsIsLoading(false);
      }
    } catch (err) {
      setTagsIsLoading(false);
    }
  };

  const getAllTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
          type: "video",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setAllTag(res?.data?.data);
        }
        setAllTagsIsLoading(false);
      }
    } catch (err) {
      setAllTagsIsLoading(false);
    }
  };

  useEffect(() => {
    if (title !== "" && videosPageNumber !== 1) {
      setVideosIsLoading(true);
      handleGetVideos();
    }
  }, [videosPageNumber]);

  useEffect(() => {
    if (title !== "") {
      setVideosPageNumber(1);
      setVideosIsLoading(true);
      setVideos([]);
      if (orderBy && orderDirection) {
        if (searchParams.size === 1 && searchParams.has("sortby")) {
          handleGetVideos();
        } else if (searchParams.size === 1 && searchParams.has("orderby")) {
          handleGetVideos();
        } else if (
          searchParams.size === 2 &&
          searchParams.has("orderby") &&
          searchParams.has("sortby")
        ) {
          handleGetVideos();
        } else if (searchParams.size !== 0) {
          if (Object.keys(filter)?.length !== 0) {
            if (orderBy && orderDirection) {
              handleGetVideos();
            }
          }
        } else {
          handleGetVideos();
        }
      }
    }
  }, [orderBy, orderDirection, filter, title]);

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShowDd = () => {
    setShowFilter(!showFilter);
  };

  function getRandomPercentage() {
    const randomNumber = Math.floor(Math.random() * (100 - 60 + 1)) + 60;
    return randomNumber.toString() + "%";
  }
  useEffect(() => {
    if (width < 900) {
      setShowFilter(false);
    }
  }, [width]);

  const handleSearchParam = (key, value) => {
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSearch = (item) => {
    setFilter(item);
    ReactGA.event({
      category: "Filter",
      action: "Results filtered",
      label: "Results filtered on all videos page",
    });
    ReactGA.event({
      category: "Filter",
      action: "Results filtered on all videos page",
      label: "Results filtered on all videos page",
    });
    Object.entries(item)?.map((i) => {
      handleSearchParam(i[0], i[1]);
    });
  };

  const handleResetAll = () => {
    setFilter({});
    const filters = [
      "tag",
      "category",
      "artist",
      "search",
      "startDate",
      "endDate",
    ];
    filters.map((item) => {
      if (searchParams.has(item)) {
        searchParams.delete(item);
        setSearchParams(searchParams);
        delete params[item];
      }
    });
  };

  function VideoList({ videos }) {
    return (
      <div className={VideoCss.flexDrR1}>
        {videos.map((item, index) => {
          const date = ExtractDate(item.created_at);
          return (
            <div key={index} className={VideoCss.mgb20}>
              <VideoCard
                title={item.title}
                viewCount={item.watch_count}
                likeCount={item.like_count}
                subTitle={date}
                thumbnailLink={item.cover_image_url}
                onClick={() => handleClick(`/videos/${item.slug}`)}
                orientation={"portrait"}
                size={"sm"}
              />
            </div>
          );
        })}
      </div>
    );
  }

  function sortBy() {
    return (
      <div className={VideoCss.sortDiv}>
        <div className={VideoCss.sortBy}>
          <TextField
            defaultValue={orderBy}
            type={"select"}
            label={"Sort by"}
            data={options}
            onChange={(value) => handleSortBy(value)}
          />
        </div>
        <FaSortAmountUp
          onClick={() => handleOrder("asc")}
          className={
            orderDirection === "asc"
              ? VideoCss.ascDescIcon1
              : VideoCss.ascDescIcon
          }
        />
        <>
          <FaSortAmountDownAlt
            onClick={() => handleOrder("desc")}
            className={
              orderDirection === "desc"
                ? VideoCss.ascDescIcon1
                : VideoCss.ascDescIcon
            }
          />
        </>
      </div>
    );
  }

  return (
    <NavContainer>
      <Grid container>
        <Grid item xs={12} sm={12} md={9}>
          <div className={VideoCss.flexDr2}>
            <Label
              size="lg"
              label={optionalTitle !== "" ? title + " " + optionalTitle : title}
            />
            {width > 431 && sortBy()}
            <div className={VideoCss.iconsSm}>
              <div className={VideoCss.iconsSmDiv}>
                <img
                  src={require("../../assets/images/filter.png")}
                  className={VideoCss.filterSm}
                  onClick={() => handleShowDd()}
                />
                {showFilter ? (
                  <FaCaretUp className={VideoCss.upDown} />
                ) : (
                  <FaCaretDown className={VideoCss.upDown} />
                )}
              </div>
            </div>
          </div>
          {showFilter && (
            <div className={VideoCss.mgb}>
              {tagsIsLoading || categoriesIsLoading || artistsIsLoading ? (
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginTop: 2 }}
                  animation="wave"
                  variant="rounded"
                  width={"100%"}
                  height={440}
                />
              ) : (
                <VideosFilter
                  categoryArray={allCategories}
                  onSearch={(item) => handleSearch(item)}
                  filter={filter}
                  tag={allTag}
                  artists={artists}
                  onReset={handleResetAll}
                  triggerReset={triggerReset}
                  setTriggerReset={setTriggerReset}
                />
              )}
            </div>
          )}
          {bannerAd &&
            (bannerAd?.banner_image_url ? (
              <div className={VideoCss.smBanner}>
                <img
                  className={VideoCss.BannerAds}
                  src={bannerAd?.banner_image_url}
                />
              </div>
            ) : (
              bannerAd?.content && (
                <div className={VideoCss.smBanner}>
                  <DynamicContentInjector
                    className={VideoCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                </div>
              )
            ))}
          {bannerAd2 &&
            (bannerAd2?.banner_image_url ? (
              <div className={VideoCss.smBanner}>
                <img
                  className={VideoCss.BannerAds}
                  src={bannerAd2?.banner_image_url}
                />
              </div>
            ) : (
              bannerAd2?.content && (
                <div className={VideoCss.smBanner}>
                  <DynamicContentInjector
                    className={VideoCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                </div>
              )
            ))}
          {width < 431 && sortBy()}
          {width < 431 && <div className={VideoCss.mgb} />}
          {videosIsLoading ? (
            <div className={VideoCss.flexDrR1} style={{ marginBottom: 30 }}>
              {Array.from({ length: 8 }, (_, index) => {
                return <VideoCardLoader key={index} size={"sm"} />;
              })}
            </div>
          ) : (
            <>
              {videos?.length > 0 ? (
                <VideoList videos={videos} />
              ) : (
                <NoData errorCode={status} />
              )}

              {moreVideosIsLoading ? (
                <div className={VideoCss.flexDrR1}>
                  {Array.from({ length: 8 }, (_, index) => {
                    return <VideoCardLoader key={index} size={"sm"} />;
                  })}
                </div>
              ) : videosLastPage != null &&
                videosPageNumber < videosLastPage ? (
                <ViewMore loadMore onClick={updateVideoPageNumber} />
              ) : (
                <div className={VideoCss.mgb} />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <div className={VideoCss.secondContainer}>
            {allTagsIsLoading || allCategoriesIsLoading || artistsIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginTop: 2 }}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={440}
              />
            ) : (
              <VideosFilter
                categoryArray={allCategories}
                onSearch={(item) => handleSearch(item)}
                filter={filter}
                tag={allTag}
                artists={artists}
                onReset={handleResetAll}
                triggerReset={triggerReset}
                setTriggerReset={setTriggerReset}
              />
            )}
            {bannerAd &&
              (bannerAd?.banner_image_url ? (
                <img
                  className={VideoCss.BannerAds}
                  src={bannerAd?.banner_image_url}
                />
              ) : (
                bannerAd?.content && (
                  <DynamicContentInjector
                    className={VideoCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                )
              ))}
            {bannerAd2 &&
              (bannerAd2?.banner_image_url ? (
                <img
                  className={VideoCss.BannerAds}
                  src={bannerAd2?.banner_image_url}
                />
              ) : (
                bannerAd2?.content && (
                  <DynamicContentInjector
                    className={VideoCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                )
              ))}
          </div>
        </Grid>
      </Grid>
      {(categoriesIsLoading || categories?.length !== 0) && (
        <div className={VideoCss.redHead}>Categories</div>
      )}
      <Grid className={VideoCss.topBox} container columnGap={3}>
        {categoriesIsLoading
          ? Array.from({ length: 50 }, (_, index) => {
              return (
                <Grid key={index} item xs={5.3} sm={3.3} md={2.2}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="circular"
                      width={"10%"}
                    />
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={getRandomPercentage()}
                    />
                  </div>
                </Grid>
              );
            })
          : categories?.length !== 0 && (
              <>
                {categories.map((item, index) => {
                  return (
                    <CategoriesList
                      key={index}
                      item={item}
                      onClick={() => handleNavigate({ category: item.slug })}
                    />
                  );
                })}
              </>
            )}
      </Grid>
      {categories?.length !== 0 && (
        <ViewMore onClick={() => handleClick("/videos/categories")} />
      )}

      {(tagsIsLoading || tag?.length !== 0) && (
        <div className={VideoCss.redHead}>Popular Tags</div>
      )}
      {tagsIsLoading ? (
        <Grid container columnGap={3}>
          {Array.from({ length: 50 }, (_, index) => {
            return (
              <Grid key={index} item xs={5.3} sm={3.3} md={2.2}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        tag?.length !== 0 && (
          <>
            <TagsList tag={tag} handleNavigate={handleNavigate} />
            <ViewMore onClick={() => handleClick("/videos/tags")} />
          </>
        )
      )}
    </NavContainer>
  );
}
