import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginSuccess } from "../../actions";
import { useNavigate } from "react-router-dom";
import NavContainer from "../../elements/NavContainer/NavContainer";
import AuthCss from "./Auth.module.css";
import { FaApple } from "react-icons/fa";
import TextField from "../../components/textfield/TextField";
import Button from "../../components/button/Button";
import { FontSizes } from "../../config";
import { Context } from "../../Context";
import { SERVER_URL } from "../../Constants.ts";
import Swal from "sweetalert2";
import axios from "axios";
import {
  auth,
  facebookProvider,
  provider,
  signInWithPopup,
} from "./FirebaseApp.js";
import ReactGA from "react-ga4";

const LoginPage = ({ loginSuccess, isLoggedIn }) => {
  const { setPageTitle } = useContext(Context);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  useEffect(() => {
    setPageTitle("Login");
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/login`,
      title: "Login",
    });
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const data = user?.providerData[0];
      const body = {
        social_id: data?.uid,
        social_type: "google",
        email: data?.email,
      };
      socialLogin(body);
    } catch (error) {
      Swal.fire({
        title: "Sorry...",
        text: "We are unable to process your request!",
        icon: "warning",
        color: "#fff",
        background: "#000",
        timer: 3000,
        showConfirmButton: false,
      });
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      const user = result.user;
      const data = user?.providerData[0];
      let body = {
        social_id: data?.uid,
        social_type: "facebook",
        email: data?.email,
      };
      socialLogin(body);
    } catch (error) {
      Swal.fire({
        title: "Sorry...",
        text: "We are unable to process your request!",
        icon: "warning",
        color: "#fff",
        background: "#000",
        timer: 3000,
        showConfirmButton: false,
      });
    }
  };

  const socialLogin = async (body) => {
    try {
      const res = await axios.post(`${SERVER_URL.SOCIAL_LOGIN}`, body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        const u = res.data.user;
        const user = {
          ...u,
          token: res.data.token,
          slug: `${u.first_name}-${u.last_name}-${u.id}`,
        };
        loginSuccess(user);
        setIsLogging(false);
        navigate(-1);
        Swal.fire({
          title: "Hurray...",
          text: "You have logged in successfully!",
          icon: "success",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Oops!",
          text: "Something went wrong please try again later.",
          icon: "warning",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      const d = err.response.data;
      const status = err.response.status;
      setIsLogging(false);
      if (!err.response) {
        // Network or other error
        Swal.fire({
          title: "Oops...",
          text: "Network error. Please try again later.",
          icon: "error",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }
      if (status === 401) {
        if (
          d?.error === "User is not registered" ||
          d?.message === "User is not registered"
        ) {
          Swal.fire({
            title: "Not Registered Yet",
            text: d.error || d?.message,
            icon: "error",
            color: "#fff",
            background: "#000",
            timer: 3000,
            showConfirmButton: false,
          });
          handleNavigate();
        } else {
          Swal.fire({
            title: d.code,
            text: d.message,
            icon: "error",
            color: "#fff",
            background: "#000",
            timer: 5000,
            showConfirmButton: false,
          });
        }
      } else if (status === 403) {
        Swal.fire({
          title: "Account Locked",
          text: "Your account has been locked due to multiple unsuccessful login attempts. Please try again later or contact support.",
          icon: "warning",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      } else if (status === 500) {
        Swal.fire({
          title: "Server Error",
          text: "Something went wrong on our end. Please try again later.",
          icon: "error",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Sorry...",
          text: "We are unable to process your request!",
          icon: "warning",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    }
  };

  const login = async () => {
    const body = {
      email: email,
      password: password,
    };

    try {
      const res = await axios.post(`${SERVER_URL.LOGIN}`, body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (res.status === 200) {
        const u = res.data.user;
        const user = {
          ...u,
          token: res.data.token,
          slug: `${u.first_name}-${u.last_name}-${u.id}`,
        };
        loginSuccess(user);
        setIsLogging(false);
        navigate(-1);
        Swal.fire({
          title: "Hurray...",
          text: "You have logged in successfully!",
          icon: "success",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Oops!",
          text: "Something went wrong please try again later.",
          icon: "warning",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      setIsLogging(false);

      if (!err.response) {
        // Network or other error
        Swal.fire({
          title: "Oops...",
          text: "Network error. Please try again later.",
          icon: "error",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }

      const status = err.response.status;
      const d = err.response.data;
      if (!d.registered && status === 401) {
        Swal.fire({
          title: "Not Registered Yet",
          text: "User is not registered yet. Please register!",
          icon: "error",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
        handleNavigate();
      } else if (status === 401) {
        Swal.fire({
          title: "Incorrect email/password",
          text: d.error,
          icon: "error",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      } else if (status === 403) {
        Swal.fire({
          title: "Account Locked",
          text: "Your account has been locked due to multiple unsuccessful login attempts. Please try again later or contact support.",
          icon: "warning",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      } else if (status === 500) {
        Swal.fire({
          title: "Server Error",
          text: "Something went wrong on our end. Please try again later.",
          icon: "error",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Sorry...",
          text: "We are unable to process your request!",
          icon: "warning",
          color: "#fff",
          background: "#000",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  const handleLogin = () => {
    setIsLogging(true);
    let isValid = true;

    if (!isValidEmail(email)) {
      setIsEmailInvalid(true);
      isValid = false;
      setEmailError("Please enter a valid email address.");
      setIsLogging(false);
    } else {
      setIsEmailInvalid(false);
      setEmailError("");
    }
    if (password.trim() === "") {
      setIsPasswordInvalid(true);
      isValid = false;
      setPasswordError("Please enter a password.");
      setIsLogging(false);
    } else {
      setIsPasswordInvalid(false);
      setPasswordError("");
    }

    if (isValid) {
      login();
    }
  };

  const handleNavigate = () => {
    navigate("/register");
    window.scrollTo(0, 0);
  };

  return (
    <NavContainer useFooter={true}>
      <div className={AuthCss.mainContainer}>
        <div className={AuthCss.Login}>Login</div>
        <div className={AuthCss.box}>
          <div className={AuthCss.head}>Login using</div>
          <div className={AuthCss.socials}>
            <FaApple className={AuthCss.socialIcon} />
            <img
              src={require("../../assets/images/Google.png")}
              className={AuthCss.socialImage}
              onClick={handleGoogleSignIn}
            />
            <img
              src={require("../../assets/images/facebook.png")}
              className={AuthCss.socialImage}
              onClick={handleFacebookSignIn}
            />
          </div>
          <div className={AuthCss.orContainer}>
            <div className={AuthCss.hr} />
            <div className={AuthCss.orText}>OR</div>
            <div className={AuthCss.hr} />
          </div>
          <div className={AuthCss.heading}>
            Email<span className={AuthCss.required}>*</span>
          </div>
          <div className={AuthCss.mgt10}>
            <TextField
              error={isEmailInvalid}
              onChange={(item) => setEmail(item)}
              type={"email"}
            />
            {
              <div className={AuthCss.error}>
                {isEmailInvalid && emailError}
              </div>
            }
          </div>

          <div className={AuthCss.mgt20} />

          <div className={AuthCss.flexDr}>
            <div className={AuthCss.heading}>
              Password<span className={AuthCss.required}>*</span>
            </div>
          </div>
          <div className={AuthCss.mgt10}>
            <TextField
              error={isPasswordInvalid}
              onChange={(item) => setPassword(item)}
              type={"password"}
            />{" "}
            {
              <div className={AuthCss.error}>
                {isPasswordInvalid && passwordError}
              </div>
            }
          </div>
          <div className={AuthCss.mgt20} />

          <div className={AuthCss.flexDr3}>
            <Button
              onClick={() => handleLogin()}
              label="Login"
              loading={isLogging}
            />
            <div>
              {" "}
              <span className={AuthCss.imageText}>New user?</span>
              <Button
                size={"small"}
                label="Sign Up"
                backgroundColor={"transparent"}
                color={"#ff0000"}
                styles={{ fontWeight: "600", fontSize: FontSizes.body }}
                onClick={() => handleNavigate()}
              />
            </div>
            <div>
              <span className={AuthCss.imageText}>Forgot your Password?</span>
              <Button
                size={"small"}
                label="Click here"
                backgroundColor={"transparent"}
                color={"#ff0000"}
                onClick={() => navigate("/forgot-password")}
                styles={{ fontWeight: "600", fontSize: FontSizes.text }}
              />
            </div>
          </div>
        </div>
      </div>
    </NavContainer>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps, { loginSuccess })(LoginPage);
function isValidEmail(email) {
  // Regular expression for validating an email address
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}
