import * as React from "react";

import {
  createIcon,
  IconButton as YT,
  isImageSlide,
  useLightboxProps,
  useLightboxState,
} from "yet-another-react-lightbox";
import { resolveShareProps } from "./props.ts";
import { isShareSupported } from "./utils.ts";
import { useState } from "react";
import { useRef } from "react";
import { Popover } from "@mui/material";
import IconButton from "../../components/icon-button/IconButton.js";
import { useEffect } from "react";
import "./Share.css"
import { copy, emailLink, facebookLink, redditLink, twitterLink, whatsappLink } from "../../components/sharing-tooltip/Resource.js";

const ShareIcon = createIcon(
  "ShareIcon",
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24"
    height="24"
    viewBox="0 0 30 30"
  >
    <path d="M 23 3 A 4 4 0 0 0 19 7 A 4 4 0 0 0 19.09375 7.8359375 L 10.011719 12.376953 A 4 4 0 0 0 7 11 A 4 4 0 0 0 3 15 A 4 4 0 0 0 7 19 A 4 4 0 0 0 10.013672 17.625 L 19.089844 22.164062 A 4 4 0 0 0 19 23 A 4 4 0 0 0 23 27 A 4 4 0 0 0 27 23 A 4 4 0 0 0 23 19 A 4 4 0 0 0 19.986328 20.375 L 10.910156 15.835938 A 4 4 0 0 0 11 15 A 4 4 0 0 0 10.90625 14.166016 L 19.988281 9.625 A 4 4 0 0 0 23 11 A 4 4 0 0 0 27 7 A 4 4 0 0 0 23 3 z"></path>
  </svg>
  // </svg>
);

export function ShareButton({ onShare }) {
  const { render, on, share: shareProps } = useLightboxProps();
  const { share: customShare } = resolveShareProps(shareProps);
  const { currentSlide, currentIndex } = useLightboxState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [x, setX] = useState();
  const [y, setY] = useState();
  const ref = useRef();

  useEffect(() => {
    getPosition();
  }, [anchorEl]);

  useEffect(() => {
    window.addEventListener("resize", getPosition);
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const share =
  //   (currentSlide &&
  //     ((typeof currentSlide.share === "object" && currentSlide.share) ||
  //       (typeof currentSlide.share === "string" && {
  //         url: currentSlide.share,
  //       }) ||
  //       (isImageSlide(currentSlide) && { url: currentSlide.src }))) ||
  //   undefined;

  // slides must be explicitly marked as shareable when custom share function is provided
  // const canShare = customShare ? currentSlide?.share !== false : share; //&& navigator.canShare(share);

  const handleShare = (event) => {
    // if (currentSlide) {
    //   (customShare || defaultShare)({ slide: currentSlide });

    //   on.share?.({ index: currentIndex });
    // }
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);


  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? "simple-popover" : undefined;

  const getPosition = () => {
    const x = ref.current?.offsetLeft;
    setX(x);

    const y = ref.current?.offsetTop;
    setY(y);
  };

  const PopoverArrowStyles = {
    position: "absolute",
    top: width < 431 ? y + 80 : y + 40,
    right: x,
    margin: "auto",
    display: "block",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderColor: "#413f3f",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderTop: "0px",
    borderBottom: "16px solid #413f3f",
    zIndex: 9999,
    content: '""',
  };

  function share(item) {
    const link = window.location.href;
    const content = "Checkout this amazing gallery" + currentSlide?.title ? `: ${currentSlide?.title}` : ""

    let getLink = "";
    if (item === "facebook") {
      getLink = facebookLink(link, content);
    }
    if (item === "twitter") {
      getLink = twitterLink(link, content);
    }
    if (item === "whatsapp") {
      getLink = whatsappLink(link, content, "&");
    }
    if (item === "reddit") {
      getLink = redditLink(link, content);
    }
    if (item === "gmail") {
      getLink = emailLink(
        link,
        content,
        "Check out this amazing post",
        "&"
      );
    }
    if (getLink) {
      handleClose()
    }
    if (onShare) {
      onShare(currentSlide?.title, item)
    }
    const aTag = document.createElement("a");
    aTag.setAttribute("href", getLink);
    aTag.setAttribute("target", "_blank");
    aTag.click();
  }

  const img = {
    width: width < 431 ? 24 : 43,
    height: width < 431 ? 24 : 43,
    cursor: "pointer"
  }

  const imgTwitter = {
    width: width < 431 ? 28 : 43,
    height: width < 431 ? 28 : 43,
    cursor: "pointer",
    backgroundColor: "#fff",
    borderRadius: 50,
    boxShadow: "0px 0px 0px 3px #000 inset"
  };

  return (
    <>
      <YT
        label="Share"
        ref={ref}
        icon={ShareIcon}
        aria-describedby={id}
        // renderIcon={render.iconShare}
        // disabled={!canShare}
        onClick={handleShare}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "::before": open ? PopoverArrowStyles : {},
          ".MuiPaper-root": {
            backgroundColor: "#413f3f",
            borderRadius: "43px",
            padding: "9px",
            marginTop: "8px",
            overflow: "visible",
            position: "absolute"
          },
        }}
      >
        {/* <span className="arrow" /> */}
        <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
          <img
            src={require("../../assets/images/facebook.png")}
            style={img}
            onClick={() => share("facebook")}
          />
          <img src={require("../../assets/images/twitter.png")} style={{ ...imgTwitter }} onClick={() => share("twitter")} />
          <img src={require("../../assets/images/whatsapp.png")} style={img} onClick={() => share("whatsapp")} />
          <img src={require("../../assets/images/reddit.png")} style={img} onClick={() => share("reddit")} />
          <img src={require("../../assets/images/gmail.png")} style={img} onClick={() => share("gmail")} />
          <IconButton
            styles={img}
            onClick={() => { handleClose(); copy(window.location.href); }}
            icon={
              <img
                src={require("../../assets/images/link.png")}
                style={{ width: width < 431 ? 14 : 26, height: width < 431 ? 14 : 26, cursor: "pointer" }}
              />
            }
          />
        </div>
      </Popover>
    </>
  );
}
