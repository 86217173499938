import React, { useEffect, useState } from "react";
import { BiLike, BiSolidLike } from "react-icons/bi";
import CommentCss from "./CommentList.module.css";
import { Delete } from "@mui/icons-material";
import Swal from "sweetalert2";

export default function Comment({
  user = {},
  id = null,
  body_text = "",
  liked = false,
  like_count = 0,
  parent_comment_id = null,
  children = [],
  onReply = undefined,
  onLike = undefined,
  likes = null,
  setLikes = undefined,
  authUser = {},
  handleDelete = undefined,
  isDeleting = false,
}) {
  const [like, setLike] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deletedComment, setDeletedComments] = useState([]);

  const handleLike = (index, status) => {
    if (status === "like") {
      if (index === null) {
        const updatedComments = likes.map((comment) => {
          if (comment.id === id) {
            return { ...comment, likeCount: comment.likeCount + 1 };
          }
          return comment;
        });
        setLikes(updatedComments);
        setLike(true);
        return;
      }

      var likeStatus = children[index].liked;
      children[index].liked = !likeStatus;
      var likeCount = children[index].like_count;
      children[index].like_count = likeCount + 1;
      setRefresh(!refresh);
    } else {
      if (index === null) {
        const updatedComments = likes.map((comment) => {
          if (comment.id === id) {
            return {
              ...comment,
              likeCount: comment.likeCount - 1,
              liked: false,
            };
          }
          return comment;
        });
        setLikes(updatedComments);
        setLike(false);
        return;
      }

      var likeStatus1 = children[index].liked;
      children[index].liked = !likeStatus1;
      var likeCount1 = children[index].like_count;
      children[index].like_count = likeCount1 - 1;
      setRefresh(!refresh);
    }
  };

  function handleDeleteComment(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this comment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff0000",
      confirmButtonText: "Delete",
      color: "#fff",
      background: "#000",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
        setDeletedComments((prev) => [...prev, id]);
      }
    });
  }

  useEffect(() => {
    setLikes((prev) => [
      ...prev,
      { id: id, likeCount: like_count, liked: liked },
    ]);
  }, [like_count]);

  const commentCard = ({
    index,
    id,
    user,
    body_text,
    liked,
    like_count,
    parent_comment_id,
  }) => {
    const likeCount = likes.filter((i) => id === i.id)[0];
    return (
      <div className={CommentCss.flexDr}>
        <div className={CommentCss.commentsGrid}>
          <div className={CommentCss.commentsDiv}>
            {user?.profile_photo_url ? (
              <img
                className={CommentCss.commentsAvatar}
                src={user?.profile_photo_url}
                alt="user"
              />
            ) : (
              <div className={CommentCss.classifiedChatCardAvatar}>
                <div className={CommentCss.classifiedChatCardUsername}>
                  {user?.first_name && user?.first_name[0]}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={CommentCss.commentsGrid2}>
          <div className={CommentCss.commentsDiv2}>
            <div className={CommentCss.commentsUsername}>
              {user.first_name} {user.last_name}
            </div>
            <div className={CommentCss.commentsText}>{body_text}</div>
            <div className={CommentCss.commentsLikeDiv}>
              {liked && index !== null ? (
                <BiSolidLike
                  className={CommentCss.commentsLike}
                  onClick={() => {
                    handleLike(index, "unlike");
                    onLike(id);
                  }}
                />
              ) : (like && index === null) || likeCount?.liked ? (
                <BiSolidLike
                  className={CommentCss.commentsLike}
                  onClick={() => {
                    handleLike(index, "unlike");
                    onLike(id);
                  }}
                />
              ) : (
                <BiLike
                  className={CommentCss.commentsLike}
                  onClick={() => {
                    handleLike(index, "like");
                    onLike(id);
                  }}
                />
              )}
              {likeCount?.likeCount > 0 ? (
                index === null ? (
                  <span className={CommentCss.commentsLikeCount}>
                    {likeCount?.likeCount}
                  </span>
                ) : (
                  <span className={CommentCss.commentsLikeCount}>
                    {like_count}
                  </span>
                )
              ) : null}
              {authUser && authUser?.id === user?.id && (
                <>
                  {isDeleting && deletedComment.includes(id) ? (
                    <span className={CommentCss.commentsReply}>
                      Deleting...
                    </span>
                  ) : (
                    <Delete
                      className={CommentCss.commentsDelete}
                      onClick={() => handleDeleteComment(id)}
                    />
                  )}
                </>
              )}
              <span
                onClick={() =>
                  onReply(parent_comment_id !== null ? parent_comment_id : id)
                }
                className={CommentCss.commentsReply}
              >
                Reply
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={CommentCss.commentsMainDiv}>
      <div className={CommentCss.mgb15}>
        {commentCard({
          index: null,
          id,
          user,
          body_text,
          liked,
          like_count,
          parent_comment_id,
        })}
        {children?.length > 0 ? (
          <div className={CommentCss.commentsRepliesDiv}>
            <div className={CommentCss.mgb15}>
              {children?.map((element, index) => {
                return commentCard({
                  index,
                  id: element.id,
                  profile_photo_url: element.user.profile_photo_url,
                  user: element.user,
                  body_text: element.body_text,
                  liked: element.liked,
                  like_count: element.like_count,
                  parent_comment_id: element.parent_comment_id,
                });
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
