import React, { useEffect, useState } from "react";
import SortedCss from "./SortedList.module.css";
import CarnivalList from "../Flatlist/CarnivalList";

export default function SortedList({ data, order = "asc" }) {
  const [groupedData, setGroupedData] = useState();
  const alphabets = {
    asc: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
    desc: [
      "Z",
      "Y",
      "X",
      "W",
      "V",
      "U",
      "T",
      "S",
      "R",
      "Q",
      "P",
      "O",
      "N",
      "M",
      "L",
      "K",
      "J",
      "I",
      "H",
      "G",
      "F",
      "E",
      "D",
      "C",
      "B",
      "A",
    ],
  };

  useEffect(() => {
    let d = data.reduce((result, element) => {
      let group = element.title[0].toUpperCase();
      if (!result[group]) result[group] = { group, children: [element] };
      else result[group].children.push(element);

      return result;
    }, {});

    setGroupedData(d);
  }, [data]);

  const keysArray = groupedData && Object.keys(groupedData);
  const lastKey = keysArray && keysArray.slice(-1)[0];

  return (
    <div>
      {groupedData &&
        lastKey &&
        alphabets[order].map((item) => {
          if (groupedData[item]?.group === item) {
            return (
              <div
                key={item}
                className={lastKey === item ? undefined : SortedCss.mgb}
              >
                <div className={SortedCss.heading}>
                  {groupedData[item].group}
                </div>
                <CarnivalList gallery={groupedData[item].children} />
              </div>
            );
          }
        })}
    </div>
  );
}
