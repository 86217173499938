import { createSlice } from "@reduxjs/toolkit";

const initialState = {
galleryFilters:{
    location:"",
    category:[],
    startDate:"",
    endDate:""
}
};

export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    setGalleryFilters: (state, action) => {
      state.galleryFilters = [action.payload];
    },
  },
});

export const {
  setGalleryFilters
} = gallerySlice.actions;

export const selectGalleryFilters = (state) => state.gallery.galleryFilters;

export default gallerySlice.reducer;
