import React, { useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FontSizes } from "../../../config";
import VideoCss from "../Video.module.css";
import TextField from "../../../components/textfield/TextField";
import AutoComplete from "../../../components/autocomplete/AutoComplete";
import Button from "../../../components/button/Button";
import { datePickerStyle, pickerStyle } from "../../../css/DatePickerStyles";
import dayjs from "dayjs";

export default function VideosFilter({
  onSearch,
  categoryArray,
  filter,
  tag,
  artists,
  onReset,
  triggerReset,
  setTriggerReset,
}) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = React.useState();
  const [category, setCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [categoryOptions, setCategoryOption] = useState([]);
  const [tags, setTags] = useState([]);
  const [artist, setArtist] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);
  const [artistArray, setArtistArray] = useState([]);

  function handleReset() {
    setStartDate(null);
    setEndDate(null);
    setCategory([]);
    setTags([]);
    setSearch("");
    setArtist([]);
    onReset();
    if (triggerReset) {
      setTriggerReset(false);
    }
  }

  useEffect(() => {
    if (triggerReset) {
      handleReset();
    }
  }, [triggerReset]);

  useEffect(() => {
    const op = categoryArray?.map((item) => ({
      id: item.id,
      name: item.title,
      slug: item.slug,
    }));
    if (op) {
      setCategoryOption(op);
    }
  }, [categoryArray]);

  useEffect(() => {
    const op = tag?.map((item) => ({
      id: item.id,
      name: item.slug,
      slug: item.slug,
    }));
    if (op) {
      setTagsArray(op);
    }
  }, [tag]);

  useEffect(() => {
    const op = artists?.map((item) => ({
      id: item.id,
      name: `${item?.first_name} ${item?.last_name}`,
      slug: item.slug,
    }));
    if (op) {
      setArtistArray(op);
    }
  }, [artists]);

  function handleSearch() {
    onReset();
    let params = {};
    if (category.length !== 0) {
      params = {
        ...params,
        category: category,
      };
    }
    if (tags?.length !== 0) {
      params = {
        ...params,
        tag: tags,
      };
    }
    if (artist?.length !== 0) {
      params = {
        ...params,
        artist: artist,
      };
    }
    if (search) {
      params = {
        ...params,
        search: search,
      };
    }
    if (startDate) {
      params = {
        ...params,
        startDate: startDate,
      };
    }
    if (endDate) {
      params = {
        ...params,
        endDate: endDate,
      };
    }
    onSearch(params);
  }

  useEffect(() => {
    if (filter) {
      if (filter?.tag?.length !== 0) {
        const filtered = filter?.tag?.filter((item) => !tags.includes(item));
        if (filtered && filtered?.length !== 0) {
          setTags(filtered);
        }
      }
      if (filter?.category?.length !== 0) {
        const filtered = filter?.category?.filter(
          (item) => !category.includes(item)
        );
        if (filtered && filtered?.length !== 0) {
          setCategory(filtered);
        }
      }
      if (filter?.artist?.length !== 0) {
        const filtered = filter?.artist?.filter(
          (item) => !artist.includes(item)
        );
        if (filtered && filtered?.length !== 0) {
          setArtist(filtered);
        }
      }
      if (filter?.search) {
        if (filter?.search !== search) {
          setSearch(filter?.search);
        }
      }
      if (filter?.startDate) {
        if (filter?.startDate !== startDate) {
          setStartDate(filter.startDate);
        }
      }
      if (filter?.endDate) {
        if (filter?.endDate !== endDate) {
          setEndDate(filter?.endDate);
        }
      }
    }
  }, [filter]);

  const handleStartDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setStartDate(d);
  };

  const handleEndDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setEndDate(d);
  };

  return (
    <div className={VideoCss.filter}>
      <div className={VideoCss.calendarHead}>Filters</div>
      {categoryOptions?.length !== 0 && (
        <AutoComplete
          defaultValue={category}
          handleOnChange={(val) => setCategory(val)}
          options={categoryOptions}
          label="Category"
          isSlug={true}
        />
      )}
      {tagsArray?.length !== 0 && (
        <AutoComplete
          defaultValue={tags}
          handleOnChange={(val) => setTags(val)}
          options={tagsArray}
          label="Tags"
          isSlug={true}
        />
      )}
      {artistArray?.length !== 0 && (
        <AutoComplete
          defaultValue={artist}
          handleOnChange={(val) => setArtist(val)}
          options={artistArray}
          label="Artists"
          isSlug={true}
        />
      )}
      <div className={VideoCss.date}>Advanced search</div>
      <TextField
        label="Search"
        onChange={(value) => setSearch(value)}
        defaultValue={search}
        size="small"
      />
      <div className={VideoCss.date}>Date Range</div>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            sx={datePickerStyle}
            slotProps={{
              textField: { size: "small" },
              layout: {
                sx: pickerStyle,
              },
            }}
            label="Start date"
            value={startDate ? dayjs(startDate) : null}
            onChange={(newValue) => handleStartDate(newValue)}
          />
        </LocalizationProvider>
        <div className={VideoCss.to}>to</div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            sx={datePickerStyle}
            slotProps={{
              textField: { size: "small" },
              layout: {
                sx: pickerStyle,
              },
            }}
            label="End date"
            value={endDate ? dayjs(endDate) : null}
            onChange={(newValue) => handleEndDate(newValue)}
          />
        </LocalizationProvider>
      </div>
      <div className={VideoCss.buttonsDiv}>
        <Button
          size={"small"}
          label="Reset"
          backgroundColor={"transparent"}
          color={"#ff0000"}
          styles={{ fontWeight: "600", fontSize: FontSizes.text }}
          onClick={() => handleReset()}
        />
        <Button size={"small"} label="Search" onClick={() => handleSearch()} />
      </div>
    </div>
  );
}
