import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import MusicCss from "./MusicHome.module.css";
import { Context } from "../../Context";
import AlbumsList from "../../elements/Flatlist/AlbumsList";
import ViewMore from "../../elements/view-more/ViewMore";
import { useNavigate } from "react-router";
import MetaTags from "../../MetaTags";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { Skeleton } from "@mui/material";
import ReactGA from "react-ga4";

export default function Album() {
  const { setPageTitle } = useContext(Context);
  const navigate = useNavigate();
  const [newReleasesData, setNewReleasesData] = useState(null);
  const [popularData, setPopularData] = useState(null);
  const [trendingData, setTrendingData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [popularIsLoading, setPopularIsLoading] = useState(true);
  const [trendingIsLoading, setTrendingIsLoading] = useState(true);
  useEffect(() => {
    handleGetPopularData();
    handleGetTrendingData();
    handleGetRecentlyPlayedData();
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/music/albums",
      title: "Albums Home",
    });
  }, []);

  const handleGetPopularData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ALL_ALBUMS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
          orderBy: "like_count",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setPopularData(res?.data?.data);
        }
        setPopularIsLoading(false);
      }
    } catch (err) {
      setPopularIsLoading(false);
    }
  };

  const handleGetTrendingData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ALL_ALBUMS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
          orderBy: "play_count",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setTrendingData(res?.data?.data);
        }
        setTrendingIsLoading(false);
      }
    } catch (err) {
      setTrendingIsLoading(false);
    }
  };

  const handleGetRecentlyPlayedData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_RECENTLY_PLAYED_ALBUMS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setNewReleasesData(res?.data?.data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setPageTitle("Albums");
  }, []);

  return (
    <>
      <NavContainer>
        <MetaTags
          author={"Trini Jungle Juice"}
          title={"Albums"}
          url={window.location.href}
          type={"website"}
          image="../../assets/images/tjj-logo.jpg "
        />

        {popularIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          popularData && <Label label={"Popular Albums"} size="md" />
        )}
        {popularIsLoading ? (
          <div className={MusicCss.mgb}>
            <div className={MusicCss.albumsDiv}>
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className={MusicCss.albumsSubDiv}>
                  <MusicCardLoader music={true} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          popularData && (
            <>
              <AlbumsList albums={popularData} />
              <ViewMore
                viewMoreOrFilter={true}
                onClick={() => handleClick("/music/albums/all")}
              />
            </>
          )
        )}

        {trendingIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          trendingData && <Label label={"Trending Albums"} size="md" />
        )}
        {trendingIsLoading ? (
          <div className={MusicCss.mgb}>
            <div className={MusicCss.albumsDiv}>
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className={MusicCss.albumsSubDiv}>
                  <MusicCardLoader music={true} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          trendingData && (
            <>
              <AlbumsList albums={trendingData} />
              <ViewMore
                viewMoreOrFilter={true}
                onClick={() => handleClick("/music/albums/all")}
              />
            </>
          )
        )}

        {isLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          newReleasesData && <Label label={"Recently Played"} size="md" />
        )}
        {isLoading ? (
          <div className={MusicCss.mgb}>
            <div className={MusicCss.albumsDiv}>
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className={MusicCss.albumsSubDiv}>
                  <MusicCardLoader music={true} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          newReleasesData && (
            <>
              <AlbumsList albums={newReleasesData} />
              <ViewMore
                viewMoreOrFilter={true}
                onClick={() => handleClick("/music/albums/all")}
              />
            </>
          )
        )}
      </NavContainer>
    </>
  );
}
