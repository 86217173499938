import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { CardSizes } from "../../config";
import VideoCss from "./VideoCard.module.css";

export default function VideoCard({
  title = "",
  subTitle = "",
  likeCount = 0,
  viewCount = 0,
  thumbnailLink = "",
  onClick = undefined,
  orientation = "landscape",
  textAlign = null,
  size = "md",
}) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div onClick={onClick} className={VideoCss.mainDiv}>
      <Grid
        container
        columnSpacing={width < 431 ? 0 : orientation === "landscape" ? 2 : 0}
        rowSpacing={width < 431 ? 2 : 0}
        style={{
          width:
            width < 431
              ? "100%"
              : orientation === "landscape"
                ? CardSizes.lg
                : CardSizes[size],
          height:
            width < 431 ? "auto" : orientation === "landscape" ? 144 : "auto",
        }}
      >
        <Grid
          item
          xs={12}
          sm={orientation === "landscape" ? 4 : 12}
          md={orientation === "landscape" ? 6 : 12}
          className={VideoCss.imgMainDiv}
        >
          <div
            style={{
              height:
                width < 431
                  ? "auto"
                  : orientation === "landscape"
                    ? 144
                    : "auto",
              minHeight: orientation === "landscape" ? 144 : 120,
            }}
            className={VideoCss.imgDiv}
          >
            <img
              className={VideoCss.thumbnail}
              src={thumbnailLink}
              alt="video"
            />
            <div className={VideoCss.playIconDiv}>
              <img
                className={VideoCss.playIcon}
                src={require("../../assets/images/playIcon.png")}
                alt="Play"
              />
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={orientation === "landscape" ? 8 : 12}
          md={orientation === "landscape" ? 6 : 12}
        >
          <div className={VideoCss.content}>
            <div>
              <div
                style={{
                  marginTop: orientation === "landscape" ? 0 : 5,
                  textAlign: textAlign ?? "left",
                }}
                className={VideoCss.title}
              >
                {title}
              </div>

              {subTitle && orientation === "landscape" && (
                <div className={VideoCss.subTitle}>{subTitle}</div>
              )}
              {subTitle && orientation === "portrait" && size !== "md" && (
                <div className={VideoCss.subTitle}>{subTitle}</div>
              )}
            </div>

            <div className={VideoCss.footerDivP}>
              {subTitle && orientation === "portrait" && size === "md" && (
                <div className={VideoCss.subTitleP}>{subTitle}</div>
              )}

              {(likeCount !== 0 || viewCount !== 0) && (
                <div
                  className={
                    orientation === "portrait" &&
                    (size === "xs" || size === "sm")
                      ? VideoCss.footerDivS
                      : VideoCss.footerDiv
                  }
                >
                  <div className={VideoCss.flexDr}>
                    <img
                      style={{ width: 33, height: 23 }}
                      src={require("../../assets/images/Like.png")}
                      alt="Likes"
                    />
                    <div className={VideoCss.text}>{likeCount}</div>
                  </div>
                  <div className={VideoCss.flexDr}>
                    <img
                      style={{ width: 22, height: 13 }}
                      src={require("../../assets/images/eye.png")}
                      alt="Views"
                    />
                    <div className={VideoCss.text}>{viewCount}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
