import React, { useContext, useState, useEffect } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import { Context } from "../../Context";
import PlaylistCss from "./Playlist.module.css";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import MetaTags from "../../MetaTags";
import ViewMore from "../../elements/view-more/ViewMore";
import TextField from "../../components/textfield/TextField";
import { useSearchParams } from "react-router-dom";
import NoData from "../../elements/no-data/NoData";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader";
import PlaylistList from "../../elements/Flatlist/PlaylistList.js";
import ReactGA from "react-ga4";

export default function Playlist() {
  const { setPageTitle } = useContext(Context);
  const [width, setWidth] = useState(window.innerWidth);
  const [playlistData, setPlaylistData] = useState([]);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");
  const [playlistIsLoading, setPlaylistIsLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const [playlistPageNumber, setPlaylistPageNumber] = useState(1);
  const [params, setParams] = useState({});
  const [playlistLastPage, setPlaylistLastPage] = useState(null);
  const [morePlaylistIsLoading, setMorePlaylistIsLoading] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/music/playlist`,
      title: "Playlist Home",
    });
  }, []);

  const handleSortby = (item) => {
    handleSearchParam("sortby", item);
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results`,
      label: `Sort by ${item} on all playlist page`,
    });
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results on all playlist page`,
      label: `Sorted the results by ${item} on all playlist page`,
    });
    setOrderBy(item);
  };
  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  const handleGetData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_PLAYLIST, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: playlistPageNumber,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
          orderBy: orderBy,
          orderDirection: orderDirection,
        },
      });
      if (res.status === 200) {
        setStatus(res.status);
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setPlaylistLastPage(res?.data?.last_page);
          if (playlistData?.length === 0) {
            setPlaylistData(res?.data?.data);
          } else {
            setPlaylistData((prev) => [...prev, ...res?.data?.data]);
          }
        }
        setPlaylistIsLoading(false);
        if (morePlaylistIsLoading) {
          setMorePlaylistIsLoading(false);
        }
      }
    } catch (err) {
      if (morePlaylistIsLoading) {
        setMorePlaylistIsLoading(false);
      }
      setPlaylistData([]);
      setPlaylistIsLoading(false);
    }
  };

  useEffect(() => {
    setPlaylistIsLoading(true);
    setPlaylistData([]);
    if (playlistPageNumber === 1) {
      handleGetData();
    } else {
      setPlaylistPageNumber(1);
    }
  }, [orderBy, orderDirection]);

  useEffect(() => {
    if (playlistPageNumber !== 1) {
      handleGetData();
    }
  }, [playlistPageNumber]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearchParam = (key, value) => {
    setParams((prevParams) => ({
      ...prevParams,
      [key]: value,
    }));
  };

  const handleOrder = (item) => {
    handleSearchParam("orderby", item);
    const order = item === "asc" ? "ascending" : "descending";
    ReactGA.event({
      category: "Order",
      action: `Order by ${order}`,
      label: `Order by ${order} on all playlist page`,
    });
    ReactGA.event({
      category: "Order",
      action: `Order by ${order} on all playlist page`,
      label: `Ordered the results by ${order} on all playlist page`,
    });
    if (item === "asc") {
      setOrderDirection("asc");
    } else {
      setOrderDirection("desc");
    }
  };

  const options = [
    {
      label: "Name",
      value: "title",
    },
  ];

  useEffect(() => {
    setPageTitle("Playlist");
  }, []);

  const loadMorePlaylist = () => {
    if (playlistLastPage != null && playlistPageNumber <= playlistLastPage) {
      setMorePlaylistIsLoading(true);
      setPlaylistPageNumber(playlistPageNumber + 1);
    }
  };

  function sortBy() {
    return (
      <div className={PlaylistCss.sortDiv}>
        <div className={PlaylistCss.sortBy}>
          <TextField
            defaultValue={orderBy}
            type={"select"}
            label={"Sort by"}
            data={options}
            disabled={true}
            onChange={(item) => handleSortby(item)}
          />
        </div>
        <FaSortAmountUp
          onClick={() => handleOrder("asc")}
          className={
            orderDirection === "asc"
              ? PlaylistCss.ascDescIcon1
              : PlaylistCss.ascDescIcon
          }
        />
        <>
          <FaSortAmountDownAlt
            onClick={() => handleOrder("desc")}
            className={
              orderDirection === "desc"
                ? PlaylistCss.ascDescIcon1
                : PlaylistCss.ascDescIcon
            }
          />
        </>
      </div>
    );
  }
  useEffect(() => {
    let params = {};

    if (searchParams.get("sortby")) {
      setOrderBy(searchParams.get("sortby"));
      params = {
        ...params,
        orderBy: searchParams.get("sortby"),
      };
    }
  }, [searchParams]);
  return (
    <>
      <NavContainer>
        <MetaTags
          author={"Trini Jungle Juice"}
          title={"Playlist"}
          url={window.location.href}
          type={"website"}
          image="../../assets/images/tjj-logo.jpg "
        />
        <div className={PlaylistCss.flexDrR}>
          <Label label={"All Playlists"} size="md" />
          {width > 431 && sortBy()}
        </div>
        {width < 431 && sortBy()}
        {width < 431 && <div className={PlaylistCss.mgb10} />}

        <div>
          {playlistIsLoading ? (
            <>
              <div className={PlaylistCss.playlistsDiv}>
                {Array.from({ length: 4 }, () => (
                  <div className={PlaylistCss.playlistsSubDiv}>
                    <MusicCardLoader music={true} />
                  </div>
                ))}
              </div>
              <div className={PlaylistCss.mgb} />
            </>
          ) : playlistData?.length !== 0 ? (
            <>
              <div className={PlaylistCss.playlistsDiv}>
                <PlaylistList playlist={playlistData} />
              </div>
              {morePlaylistIsLoading && (
                <div className={PlaylistCss.playlistsDiv}>
                  {Array.from({ length: 4 }, () => (
                    <div className={PlaylistCss.playlistsSubDiv}>
                      <MusicCardLoader music={true} />
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <NoData errorCode={status} />
          )}
        </div>
        {playlistLastPage != null &&
          playlistPageNumber < playlistLastPage &&
          !playlistIsLoading &&
          !morePlaylistIsLoading &&
          playlistData?.length !== 0 && (
            <ViewMore loadMore={true} onClick={loadMorePlaylist} />
          )}
      </NavContainer>
    </>
  );
}
