import React from 'react'
import GlobalSearchCss from "../../GlobalSearch.module.css";
import News from './News';
import Carnival from './Carnival';
import Event from './Event';
import Gallery from './Gallery';
import Videos from './Videos';
import Song from './Song';
import Artists from './Artist';
import Albums from './Albums';
import Genres from './Genres';
import Reviews from './Reviews';

export default function All({
    setValue,
    events,
    eventsIsLoading,
    galleries,
    galleriesIsLoading,
    videos,
    videosIsLoading,
    carnivals,
    carnivalsIsLoading,
    newsArticles,
    newsArticlesLoading,
    reviews,
    reviewsIsLoading,
    totalReviews,
    totalCarnivals,
    totalGalleries,
    totalVideos,
    totalEvents,
    totalNewsArticles,
    artists,
    artistsIsLoading,
    totalArtists,
    albums,
    albumsIsLoading,
    totalAlbums,
    genres,
    genresIsLoading,
    totalGenres,
    songs,
    songsIsLoading,
    totalSongs,
    handleAddToQueue,
    handlePlay,
    currentSong,
    handleDownload
  }) {
    return (
      <div className={GlobalSearchCss.allDiv}>
        {((newsArticles && newsArticles?.length !== 0) ||
          newsArticlesLoading) && (
          <News
            isLoading={newsArticlesLoading}
            news={newsArticles?.slice(0, 4)}
            length={totalNewsArticles}
            showViewAll={true}
            setValue={(i) => setValue(i)}
          />
        )}
        {((carnivals && carnivals?.length !== 0) || carnivalsIsLoading) && (
          <Carnival
            isLoading={carnivalsIsLoading}
            carnival={carnivals?.slice(0, 6)}
            length={totalCarnivals}
            showViewAll={true}
            setValue={(i) => setValue(i)}
          />
        )}
        {((events && events?.length !== 0) || eventsIsLoading) && (
          <Event
            isLoading={eventsIsLoading}
            events={events?.slice(0, 4)}
            length={totalEvents}
            showViewAll={true}
            setValue={(i) => setValue(i)}
          />
        )}
        {((galleries && galleries?.length !== 0) || galleriesIsLoading) && (
          <Gallery
            isLoading={galleriesIsLoading}
            galleries={galleries.slice(0, 4)}
            length={totalGalleries}
            showViewAll={true}
            setValue={(i) => setValue(i)}
          />
        )}
        {((videos && videos?.length !== 0) || videosIsLoading) && (
          <Videos
            isLoading={videosIsLoading}
            videos={videos.slice(0, 6)}
            length={totalVideos}
            showViewAll={true}
            setValue={(i) => setValue(i)}
          />
        )}
        {((songs && songs?.length !== 0) || songsIsLoading) && (
          <Song
            isLoading={songsIsLoading}
            song={songs?.slice(0, 4)}
            length={totalSongs}
            showViewAll={true}
            setValue={(i) => setValue(i)}
            handleAddToQueue={handleAddToQueue}
            handlePlay={handlePlay}
            currentSong={currentSong}
            handleDownload={handleDownload}
          />
        )}
        {((artists && artists?.length !== 0) || artistsIsLoading) && (
          <Artists
            isLoading={artistsIsLoading}
            artists={artists?.slice(0, 5)}
            length={totalArtists}
            showViewAll={true}
            setValue={(i) => setValue(i)}
          />
        )}
  
        {((albums && albums?.length !== 0) || albumsIsLoading) && (
          <Albums
            isLoading={albumsIsLoading}
            albums={albums?.slice(0, 4)}
            length={totalAlbums}
            showViewAll={true}
            setValue={(i) => setValue(i)}
          />
        )}
  
        {((genres && genres?.length !== 0) || genresIsLoading) && (
          <Genres
            isLoading={genresIsLoading}
            genres={genres?.slice(0, 4)}
            length={totalGenres}
            showViewAll={true}
            setValue={(i) => setValue(i)}
          />
        )}
        {((reviews && reviews?.length !== 0) || reviewsIsLoading) && (
          <Reviews
            isLoading={reviewsIsLoading}
            reviews={reviews?.slice(0, 4)}
            length={totalReviews}
            showViewAll={true}
            setValue={(i) => setValue(i)}
          />
        )}
      </div>
    );
  }
