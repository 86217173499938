import * as React from "react";

import {
  createIcon,
  createIconDisabled,
  IconButton,
  useLightboxProps,
  useLightboxState,
} from "yet-another-react-lightbox";
import { useComments } from "./CommentsContext.tsx";

const commentsIcon = () => (
  <>
    <path d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4zM18 14H6v-2h12zm0-3H6V9h12zm0-3H6V6h12z"></path>
  </>
);

const CommentsVisible = createIcon("CommentsVisible", commentsIcon());

const CommentsHidden = createIconDisabled("CommentsVisible", commentsIcon());

export function CommentsButton({ commentsVisible, getComments, commentCount }) {
  const { visible, show, hide } = useComments();
  const { render } = useLightboxProps();
  const { currentSlide, currentIndex } = useLightboxState();


  React.useEffect(() => {
    if (getComments && visible) {
      getComments(currentSlide?.id)
    }
    commentsVisible(visible)
  }, [currentSlide, visible])

  if (render.buttonComments) {
    return <>{render.buttonComments({ visible, show, hide })}</>;
  }

  function isVisible() {
    if (visible) {
      commentsVisible(false);
      return hide();
    } else {
      commentsVisible(true);
      return show();
    }
  }

  return (
    <>
      <IconButton
        label={visible ? "Hide comments" : "Show comments"}
        icon={visible ? CommentsVisible : CommentsHidden}
        renderIcon={
          visible ? render.iconCommentsVisible : render.iconCommentsHidden
        }
        // onClick={visible ? hide : show}
        onClick={isVisible}
      />
      <div
        style={{
          color: "#fff",
          fontSize: 14,
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {commentCount && commentCount !== 0 ? commentCount : currentSlide?.commentCount}
      </div>
    </>
  );
}
