import React, { useState } from "react";
import "./button.css";
import { Button as MUIButton, CircularProgress } from "@mui/material";
import { FontSizes } from "../../config";

export default function Button({
  backgroundColor = null,
  size = "medium",
  label = "button",
  iconLeft = false,
  iconRight = false,
  variant = "contained",
  showAnimation = false,
  styles = {},
  icon = null,
  onClick = undefined,
  borderColor = null,
  color = null,
  disabled = false,
  borderRadius = 0,
  hoverColor = null,
  loading = false,
  loadingLabel = null,
}) {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    if (showAnimation) {
      setIsHover(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const ButtonStyles = {
    backgroundColor:
      variant === "contained"
        ? isHover
          ? hoverColor || "#1c1c1c"
          : backgroundColor || "red"
        : "",
    borderColor: variant === "outlined" ? borderColor || "red" : "",
    color: variant === "contained" ? color || "white" : color || "red",
    borderRadius: borderRadius || 0,
    transition: " color 0.25s ease-in-out, background-color 0.35s ease-in-out",
    boxShadow: "none",
    textTransform: "none",
    fontFamily: "Inter-Regular",
    fontSize:
      size === "large"
        ? FontSizes.heading
        : size === "medium"
          ? FontSizes.body
          : FontSizes.text,
    ...styles,
  };

  const renderButton = (startIcon = null, endIcon = null) => (
    <MUIButton
      variant={variant}
      size={size}
      startIcon={
        loading ? (
          <CircularProgress style={{ color: "#fff" }} size={20} />
        ) : (
          startIcon
        )
      }
      endIcon={!loading && endIcon}
      style={ButtonStyles}
      onClick={onClick}
      disabled={disabled || loading}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={!showAnimation && variant !== "contained" ? "noHover" : ""}
    >
      {loading ? (loadingLabel ? loadingLabel : label) : label}
    </MUIButton>
  );

  return (
    <>
      {iconLeft && icon && renderButton(icon)}
      {iconRight && icon && renderButton(null, icon)}
      {!iconLeft && !iconRight && icon && renderButton(icon, icon)}
      {!icon && renderButton()}
    </>
  );
}
