import React from "react";
import { Box } from "@mui/material";
import { FontSizes } from "../../config";
import LabelCss from "./Label.module.css";

export default function Label({
  label,
  size = "md",
  style = {},
  center = false,
}) {
  return (
    <Box
      sx={{
        fontSize: size === "lg" ? FontSizes.title : FontSizes.subTitle,
        ...style,
      }}
      className={
        center === true ? LabelCss.labelTextCenter : LabelCss.labelText
      }
    >
      {label}
      {center && <div className={LabelCss.center} />}
    </Box>
  );
}
