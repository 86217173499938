import React from "react";
import Slider from "react-slick";
import CarouselCss from "./Carousel.module.css";
import { useNavigate } from "react-router-dom";

export default function Carousel({ data }) {
  const navigate = useNavigate();

  const settings = {
    customPaging: function (i) {
      return (
        <a key={i}>
          <img className={CarouselCss.dots} src={data[i].poster_url} />
        </a>
      );
    },
    dots: true,
    dotsClass: CarouselCss.slickDots,
    infinite: data?.length > 1 ? true : false,
    speed: 1000,
    slidesToShow: 1,
    autoplay: data?.length > 1 ? true : false,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    arrows: false,
  };

  const handleClick = (item, id) => {
    navigate(`/carnival/${item}`, { state: { id: id } });
    window.scrollTo(0, 0);
  };

  return (
    <div className={CarouselCss.container}>
      <Slider {...settings}>
        {data?.map((item, index) => {
          return (
            <div key={index}>
              <div className={CarouselCss.carouselImageDiv}>
                <img
                  className={CarouselCss.carouselImage}
                  onClick={() => handleClick(item.slug, item.id)}
                  src={item.poster_url}
                />
                <div className={CarouselCss.carouselTitle}>
                {item?.title}
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
