import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  GET_QUEUE,
  SET_QUEUE,
  ADD_TO_QUEUE,
  DELETE_FROM_QUEUE,
  ADD_ITEM_AT_ZERO_INDEX,
  MOVE_ITEM_TO_TOP,
  MOVE_ITEM_TO_BOTTOM,
  CLEAR_QUEUE,
} from "./ActionTypes";

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const selectQueue = (state) => ({
  queue: state.queue.queue,
});

export const setQueue = (data) => ({
  type: SET_QUEUE,
  payload: data,
});

export const addToQueue = (item) => ({
  type: ADD_TO_QUEUE,
  payload: item,
});

export const playNextInQueue = (item) => {
  return {
    type: ADD_ITEM_AT_ZERO_INDEX,
    payload: item,
  };
};

export const deleteFromQueue = (item) => ({
  type: DELETE_FROM_QUEUE,
  payload: item,
});

export const moveItemToTop = (itemId) => {
  return {
    type: MOVE_ITEM_TO_TOP,
    payload: itemId,
  };
};

export const moveItemToBottom = (itemId) => {
  return {
    type: MOVE_ITEM_TO_BOTTOM,
    payload: itemId,
  };
};

export const clearQueue = () => ({
  type: CLEAR_QUEUE,
});
