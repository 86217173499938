import React from "react";
import PlayerControl from "../Main/PlayerControl";
import SeekControl from "../Main/SeekControl";
function Player({
  setOpenPlaylist,
  openPlaylist,
  songState,
  setSongState,
  audioRef,
  songQueue,
  currentSongIndex,
  setCurrentSongIndex,
  handleSong,
  onLike,
  isLiked,
  isLoggedIn
}) {
  return (
    <div className="player">
      <SeekControl
        songState={songState}
        setSongState={setSongState}
        audioRef={audioRef}
      />
      <PlayerControl
        songState={songState}
        setSongState={setSongState}
        audioRef={audioRef}
        setPlaylistOpen={setOpenPlaylist}
        playlistOpen={openPlaylist}
        songQueue={songQueue}
        currentSongIndex={currentSongIndex}
        setCurrentSongIndex={setCurrentSongIndex}
        handleSong={handleSong}
        onLike={onLike}
        isLiked={isLiked}
        isLoggedIn={isLoggedIn}
      />
    </div>
  );
}

export default Player;
