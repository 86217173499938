import React from "react";
import PropTypes from "prop-types";
import ClassifiedChatCardCss from "./ClassifiedChatCard.module.css";

export default function ClassifiedChatCard({
  messageText = "",
  user = {},
  role = "sender",
}) {
  return (
    <div className={ClassifiedChatCardCss.classifiedChatCardMainDiv}>
      {role === "sender" ? (
        <div className={ClassifiedChatCardCss.flex}>
          <div className={ClassifiedChatCardCss.classifiedChatCardDiv2}>
            {messageText && (
              <div className={ClassifiedChatCardCss.classifiedChatCardText}>
                {messageText}
              </div>
            )}
          </div>
          {user?.profile_photo_url ? (
            <img
              className={ClassifiedChatCardCss.classifiedCardAvatar}
              src={user?.profile_photo_url}
              alt="user"
            />
          ) : (
            <div className={ClassifiedChatCardCss.classifiedChatCardAvatar}>
              <div className={ClassifiedChatCardCss.classifiedChatCardUsername}>
                {user && user?.first_name && user?.first_name[0]}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={ClassifiedChatCardCss.flex}>
          {user?.profile_photo_url ? (
            <img
              className={ClassifiedChatCardCss.classifiedCardAvatar}
              src={user?.profile_photo_url}
              alt="user"
            />
          ) : (
            <div className={ClassifiedChatCardCss.classifiedChatCardAvatar}>
              <div className={ClassifiedChatCardCss.classifiedChatCardUsername}>
                {user && user?.first_name && user?.first_name[0]}
              </div>
            </div>
          )}
          <div className={ClassifiedChatCardCss.classifiedChatCardDiv2}>
            {messageText && (
              <div className={ClassifiedChatCardCss.classifiedChatCardText}>
                {messageText}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
