import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
export default function ChatLoader() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return Array.from({ length: 8 }, (_, index) => (
    <div
      key={index}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: index % 2 === 0 ? "flex-end" : "flex-start",
        flexDirection: "row",
        marginBottom: 20,
        gap: 10,
      }}
    >
      {index % 2 === 0 ? (
        <>
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="rounded"
            width={width < 431 ? "100%" : 350}
            height={60}
          />
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            width={40}
            height={40}
            variant="circular"
          />
        </>
      ) : (
        <>
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            width={40}
            height={40}
            variant="circular"
          />
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
            animation="wave"
            variant="rounded"
            width={width < 431 ? "100%" : 350}
            height={60}
          />
        </>
      )}
    </div>
  ));
}
