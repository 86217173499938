import * as React from "react";

import { Callback, RenderFunction } from "yet-another-react-lightbox";

import { Likes } from "./Likes.tsx";

// const PLUGIN_COMMENTS = "likes";

declare module "../types.js" {
  // noinspection JSUnusedGlobalSymbols
  interface GenericSlide {
    /** slide title */
    title?: React.ReactNode;
    /** slide description */
    description?: React.ReactNode;
  }

  // noinspection JSUnusedGlobalSymbols
  interface ToolbarButtonKeys {
    ["likes"]: null;
  }

  interface LightboxProps {
    /** Likes plugin settings */
    likes?: {
      /** Likes plugin ref */
      ref?: React.ForwardedRef<LikesRef>;
      /** if `true`, show Likes Toggle button in the toolbar */
      showToggle?: boolean;
      /** description text alignment */
      descriptionTextAlign?: "start" | "end" | "center";
      /** maximum number of lines to display in the description section */
      descriptionMaxLines?: number;
      isLoggedIn: boolean;
      onClick?: Callback;
    };
  }

  // noinspection JSUnusedGlobalSymbols
  interface SlotType {
    /** likes title customization slot */
    likesTitle: "likesTitle";
    /** likes title container customization slot */
    likesTitleContainer: "likesTitleContainer";
    /** likes description customization slot */
    likesDescription: "likesDescription";
    /** likes description container customization slot */
    likesDescriptionContainer: "likesDescriptionContainer";
  }

  // noinspection JSUnusedGlobalSymbols
  interface Render {
    /** render custom Likes Visible icon */
    iconLikesVisible?: RenderFunction;
    /** render custom Likes Hidden icon */
    iconLikesHidden?: RenderFunction;
    /** render custom Likes button */
    buttonLikes?: RenderFunction<LikesRef>;
  }

  /** Likes plugin ref */
  interface LikesRef {
    /** if `true`, likes are visible */
    visible: boolean;
    /** show likes */
    show: Callback;
    /** hide likes */
    hide: Callback;
  }
}

export default Likes;
