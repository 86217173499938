import React, { useState, useEffect } from "react";
import EventCard from "../../components/event-card/EventCard";
import FlatlistCss from "./Flatlist.module.css";
import { Grid, Modal, Skeleton } from "@mui/material";
import { useNavigate } from "react-router";
import Itinerary from "../itinerary/Itinerary";
import { LocationExtractor } from "../location-extractor/LocationExtractor";
import { EventTimeExtractor } from "../time-extractor/TimeExtractor";
import {
  CalendarDateExtractor,
  formatDate,
  parseDateString,
} from "../date-extractor/ExtractDate";
import SlugConverter from "../slug-converter/SlugConverter";
import Swal from "sweetalert2";
import axios from "axios";
import { SERVER_URL } from "../../Constants.ts";
import ReactGA from "react-ga4";

export default function FullWidthEventList({ event, isLoggedIn, user }) {
  const [openItinerary, setOpenItinerary] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [itinerariesIsLoading, setItinerariesIsLoading] = useState(true);
  const [itineraries, setItineraries] = useState([]);
  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };
  const handleNavigate = (param) => {
    const params = `tag=${param.tag}`;
    const route = "/events?" + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Event's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `${label} Shared on ${item}`,
      label: label,
    });
  }

  function handleCalendarClick(label) {
    ReactGA.event({
      category: "Calendar",
      action: "Event added to calendar",
      label: label,
    });
    ReactGA.event({
      category: "Calendar",
      action: `${label} added to calendar`,
      label: label,
    });
  }

  useEffect(() => {
    if (isLoggedIn) {
      handleGetItineraries();
    }
  }, [isLoggedIn]);

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  function handleOpen(id, label) {
    setOpenItinerary(true);
    ReactGA.event({
      category: "Itinerary",
      action: "Add to itinerary button clicked",
      label: label,
    });
    ReactGA.event({
      category: "Itinerary",
      action: `${label} added to itinerary`,
      label: label,
    });
    setEventId(id);
  }

  const handleGetItineraries = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ITINERARIES, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        setItineraries(res?.data?.itineraries?.data);
        setItinerariesIsLoading(false);
      }
    } catch (err) {
      setItinerariesIsLoading(false);
    }
  };

  const handleCreateItineraries = async (body) => {
    try {
      const res = await axios.post(SERVER_URL.CREATE_ITINERARIES, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        handleGetItineraries();
        ReactGA.event({
          category: "Itinerary",
          action: "New itinerary created",
        });
        Swal.fire({
          title: "Itinerary created successfully",
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to create the itinerary",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleAddToItinerary = async (id) => {
    const body = {
      itinerary_id: id,
      event_id: eventId,
    };
    try {
      const res = await axios.post(SERVER_URL.ADD_TO_ITINERARIES, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
        setOpenItinerary(false);
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to add to the itinerary",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <>
      <Grid container>
        {event?.map((d, index) => {
          const parsedDate = parseDateString(d?.timestamp);
          const formattedDate = formatDate(parsedDate);
          const showTimestamp =
            event[index - 1]?.timestamp === event[index]?.timestamp
              ? false
              : true;
          return (
            <div
              key={index}
              className={`${
                index === event?.length - 1 ? undefined : FlatlistCss.mgb10
              } ${FlatlistCss.widFull}`}
            >
              {showTimestamp && (
                <div
                  className={`${FlatlistCss.eventsHeading} ${FlatlistCss.widFull}`}
                >
                  {formattedDate}
                </div>
              )}
              {d?.events?.map((item, ind) => {
                const location = LocationExtractor(item?.location);
                const startTime = EventTimeExtractor(item?.start_datetime);
                const endTime = EventTimeExtractor(item?.end_datetime);
                const startDate = CalendarDateExtractor(item?.start_datetime);
                const endDate = CalendarDateExtractor(item?.end_datetime);
                return (
                  <Grid
                    key={ind}
                    item
                    className={
                      ind === d?.events?.length - 1 &&
                      index === event?.length - 1
                        ? undefined
                        : FlatlistCss.mgb20
                    }
                    style={{ marginRight: 20 }}
                    xs={12}
                    md={12}
                  >
                    <EventCard
                      eventName={item?.title}
                      imageSrc={item?.poster_url}
                      carnivalName={item?.carnival?.title}
                      shortAddress={location}
                      startTime={startTime}
                      endTime={endTime}
                      startDate={startDate}
                      endDate={endDate}
                      onShare={handleShare}
                      onCalendarClick={handleCalendarClick}
                      startDateTime={item?.start_datetime}
                      endDateTime={item?.end_datetime}
                      timezone={item?.location?.timezone}
                      description={item?.description}
                      tag={item?.tags}
                      attendanceLimits={
                        item?.is_registration_required
                          ? "Attendance Limit: " +
                            (item?.capacity !== 0
                              ? item?.capacity
                              : "No Attendance Limit")
                          : 0
                      }
                      registrationsCount={item?.registered_users_count ?? 0}
                      handleCarnivalClick={() =>
                        handleClick(
                          `/carnival/${SlugConverter(item?.carnival?.slug)}`
                        )
                      }
                      link={
                        window.location.href.split("?")[0] + `/${item?.slug}`
                      }
                      handleLocation={() =>
                        item?.location?.google_map_url &&
                        window.open(item?.location?.google_map_url, "_blank")
                      }
                      buttonCallback={() =>
                        isLoggedIn
                          ? handleOpen(item?.id, item?.title)
                          : handleLogin()
                      }
                      onClick={() => handleClick(`/events/${item?.slug}`)}
                      size={"md"}
                      chipCallback={(item) => handleNavigate({ tag: item })}
                      isWholeDayEvent={item?.is_whole_day_event}
                    />
                  </Grid>
                );
              })}
            </div>
          );
        })}
      </Grid>
      {openItinerary && (
        <Modal
          open={openItinerary}
          onClose={() => setOpenItinerary(false)}
          style={{ zIndex: 99999999999 }}
        >
          {itinerariesIsLoading ? (
            <Skeleton
              sx={{
                bgcolor: "grey.900",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              animation="wave"
              variant="rounded"
              width={400}
              height={300}
            />
          ) : (
            <Itinerary
              itinerary={itineraries}
              onClick={handleAddToItinerary}
              onCreate={handleCreateItineraries}
            />
          )}
        </Modal>
      )}
    </>
  );
}
