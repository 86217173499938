import React, { useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FontSizes } from "../../../config";
import EventCss from "../Events.module.css";
import TextField from "../../../components/textfield/TextField";
import AutoComplete from "../../../components/autocomplete/AutoComplete";
import Button from "../../../components/button/Button";
import { datePickerStyle, pickerStyle } from "../../../css/DatePickerStyles";
import dayjs from "dayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExtractStateCountry from "../../../elements/country-footer/ExtractStateCountry";

const timeArray = [
  { name: "Morning", slug: "morning" },
  { name: "Afternoon", slug: "afternoon" },
  { name: "Evening", slug: "evening" },
  { name: "Night", slug: "night" },
];

export default function EventFilter({
  onSearch,
  triggerReset,
  filter,
  countryArray,
  categoryOptions,
  promoterOptions,
  tagsOptions,
  onReset,
  setTriggerReset,
  carnivalOptions,
}) {
  const [region, setRegion] = useState("");
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = React.useState();
  const [time, setTime] = useState([]);
  const [promoter, setPromoter] = useState([]);
  const [carnivals, setCarnivals] = useState([]);
  const [category, setCategory] = useState([]);
  const [tags, setTags] = useState([]);
  const [regionArray, setRegionArray] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const [promotersArray, setPromoterArray] = useState([]);
  const [carnivalsArray, setCarnivalsArray] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const op = countryArray?.map((item, index) => ({
      id: index,
      name: ExtractStateCountry(item),
      slug: ExtractStateCountry(item),
    }));
    if (op) {
      setRegionArray(op);
    }
  }, [countryArray]);

  useEffect(() => {
    const op = categoryOptions?.map((item) => ({
      id: item?.id,
      name: item?.title,
      slug: item?.slug,
    }));
    if (op) {
      setCategoryArray(op);
    }
  }, [categoryOptions]);

  useEffect(() => {
    const op = carnivalOptions?.map((item) => ({
      id: item?.id,
      name: item?.title,
      slug: item?.slug,
    }));
    if (op) {
      setCarnivalsArray(op);
    }
  }, [carnivalOptions]);

  useEffect(() => {
    const op = promoterOptions?.map((item) => ({
      id: item?.id,
      name: item?.display_name,
      slug: item?.slug,
    }));
    if (op) {
      setPromoterArray(op);
    }
  }, [promoterOptions]);

  useEffect(() => {
    const op = tagsOptions?.map((item) => ({
      id: item?.id,
      name: item?.slug,
      slug: item?.slug,
    }));
    if (op) {
      setTagsArray(op);
    }
  }, [tagsOptions]);

  useEffect(() => {
    if (triggerReset) {
      handleReset();
    }
  }, [triggerReset]);

  function handleReset() {
    onReset();
    setCategory([]);
    setPromoter([]);
    setTime([]);
    setCarnivals([]);
    setTags([]);
    setEndDate(null);
    setStartDate(null);
    setSearch("");
    setRegion("");
    if (triggerReset) {
      setTriggerReset(false);
    }
  }

  function handleSearch() {
    let params = {};
    if (region !== "") {
      params = {
        ...params,
        region: region,
      };
    }
    if (search !== "") {
      params = {
        ...params,
        search: search,
      };
    }
    if (tags?.length !== 0) {
      params = {
        ...params,
        tag: tags,
      };
    }
    if (carnivals?.length !== 0) {
      params = {
        ...params,
        carnivals: carnivals,
      };
    }
    if (category.length !== 0) {
      params = {
        ...params,
        category: category,
      };
    }
    if (promoter.length !== 0) {
      params = {
        ...params,
        promoter: promoter,
      };
    }
    if (time.length !== 0) {
      params = {
        ...params,
        time: time,
      };
    }
    if (startDate) {
      params = {
        ...params,
        startDate: startDate,
      };
    }
    if (endDate) {
      params = {
        ...params,
        endDate: endDate,
      };
    }
    onReset();
    onSearch(params);
  }

  useEffect(() => {
    if (filter && Object.keys(filter)?.length !== 0) {
      if (filter?.region) {
        if (filter?.region !== region) {
          setRegion(filter?.region);
        }
      }
      if (filter?.time?.length !== 0) {
        const filtered = filter?.time?.filter((item) => !time.includes(item));
        if (filtered && filtered?.length !== 0) {
          setTime(filtered);
        }
      }
      if (filter?.tag?.length !== 0) {
        const filtered = filter?.tag?.filter((item) => !tags.includes(item));
        if (filtered && filtered?.length !== 0) {
          setTags(filtered);
        }
      }
      if (filter?.category?.length !== 0) {
        const filtered = filter?.category?.filter(
          (item) => !category.includes(item)
        );
        if (filtered && filtered?.length !== 0) {
          setCategory(filtered);
        }
      }
      if (filter?.carnivals?.length !== 0) {
        const filtered = filter?.carnivals?.filter(
          (item) => !carnivals.includes(item)
        );
        if (filtered && filtered?.length !== 0) {
          setCarnivals(filtered);
        }
      }
      if (filter?.promoter?.length !== 0) {
        const filtered = filter?.promoter?.filter(
          (item) => !promoter.includes(item)
        );
        if (filtered && filtered?.length !== 0) {
          setPromoter(filtered);
        }
      }
      if (filter?.search) {
        if (filter?.search !== search) {
          setSearch(filter?.search);
        }
      }
      if (filter?.startDate) {
        if (filter?.startDate !== startDate) {
          setStartDate(filter.startDate);
        }
      }
      if (filter?.endDate) {
        if (filter?.endDate !== endDate) {
          setEndDate(filter.endDate);
        }
      }
    }
  }, [filter]);

  const handleStartDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setStartDate(d);
  };

  const handleEndDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setEndDate(d);
  };

  function handleCollapse() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <div className={EventCss.filter}>
      <div className={EventCss.calendarHead}>Filters</div>
      {regionArray?.length !== 0 && (
        <AutoComplete
          defaultValue={region}
          handleOnChange={(val) => setRegion(val)}
          options={regionArray}
          label={"Locations"}
          singleSelect={true}
          isSlug={true}
        />
      )}
      <TextField
        label="Search"
        onChange={(value) => setSearch(value)}
        defaultValue={search}
        size="small"
      />
      {categoryArray?.length !== 0 && (
        <AutoComplete
          defaultValue={category}
          handleOnChange={(val) => setCategory(val)}
          options={categoryArray}
          label="Category"
          isSlug={true}
        />
      )}
      {carnivalsArray?.length !== 0 && (
        <AutoComplete
          defaultValue={carnivals}
          handleOnChange={(val) => setCarnivals(val)}
          options={carnivalsArray}
          label="Carnivals"
          isSlug={true}
        />
      )}
      <div onClick={handleCollapse} className={EventCss.advanceFlexDr}>
        <div className={EventCss.advance}>Advanced search</div>
        {isCollapsed ? (
          <KeyboardArrowDownIcon className={EventCss.UpDownIcon} />
        ) : (
          <KeyboardArrowUpIcon className={EventCss.UpDownIcon} />
        )}
      </div>
      {!isCollapsed && (
        <>
          <AutoComplete
            defaultValue={time}
            handleOnChange={(val) => setTime(val)}
            options={timeArray}
            label="Time"
            isSlug={true}
          />
          {promotersArray?.length !== 0 && (
            <AutoComplete
              defaultValue={promoter}
              handleOnChange={(val) => setPromoter(val)}
              options={promotersArray}
              label="Promoter"
              isSlug={true}
            />
          )}
          {tagsArray?.length !== 0 && (
            <AutoComplete
              defaultValue={tags}
              handleOnChange={(val) => setTags(val)}
              options={tagsArray}
              label="Tags"
              isSlug={true}
            />
          )}
          <div className={EventCss.date}>Date Range</div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                sx={datePickerStyle}
                slotProps={{
                  textField: { size: "small" },
                  layout: {
                    sx: pickerStyle,
                  },
                }}
                label="Start date"
                value={startDate ? dayjs(startDate) : null}
                onChange={handleStartDate}
              />
            </LocalizationProvider>
            <div className={EventCss.to}>to</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                sx={datePickerStyle}
                slotProps={{
                  textField: { size: "small" },
                  layout: {
                    sx: pickerStyle,
                  },
                }}
                label="End date"
                value={endDate ? dayjs(endDate) : null}
                onChange={handleEndDate}
              />
            </LocalizationProvider>
          </div>
        </>
      )}
      <div className={EventCss.buttonsDiv}>
        <Button
          size={"small"}
          label="Reset"
          backgroundColor={"transparent"}
          color={"#ff0000"}
          onClick={() => handleReset()}
          styles={{ fontWeight: "600", fontSize: FontSizes.text }}
        />
        <Button size={"small"} label="Search" onClick={() => handleSearch()} />
      </div>
    </div>
  );
}
