import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import { Context } from "../../Context";
import CarnivalClassifiedsCss from "./CarnivalClassifieds.module.css";
import TextField from "../../components/textfield/TextField";
import DragAndDropZone from "../../components/drag-and-drop-zone/DragAndDropZone";
import Swal from "sweetalert2";
import AutoComplete from "../../components/autocomplete/AutoComplete";
import Button from "../../components/button/Button";
import { Box, Modal } from "@mui/material";
import ClassifiedCard from "../../components/classified-card/ClassifiedCard";
import { connect } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./ckeditor-dark-theme.css";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";

const options = [
  { label: "For Sale", value: "sale" },
  { label: "For Swap", value: "swap" },
  { label: "For Rent", value: "rent" },
  { label: "Needed", value: "needed" },
];

const instructions = [
  {
    id: 1,
    text: "You can only have 1 active post at a time.",
  },
  {
    id: 2,
    text: "Your post will expire in 15 days.",
  },
];

const NewClassified = ({ auth }) => {
  const { id } = useParams();
  const { setPageTitle } = useContext(Context);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagsIsLoading, setTagsIsLoading] = useState(true);
  const [tag, setTag] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [postType, setPostType] = useState("");
  const [data, setData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/carnival/classifieds/${id}/create`,
      title: "New Carnival Classified",
    });
  }, []);

  const getAllTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
          type: "carnival",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          const op = res?.data?.data?.map((item) => ({
            id: item?.id,
            name: item?.slug,
            slug: item?.slug,
          }));
          if (op) {
            setTags(op);
          }
        }
        setTagsIsLoading(false);
      }
    } catch (err) {
      setTagsIsLoading(false);
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width < 431 ? "90%" : "700px",
    bgcolor: "#343232",
    borderRadius: 6,
    boxShadow: 24,
    maxHeight: 350,
    display: "flex",
    alignItems: "center",
    p: 4,
    overflow: "auto",
    flexDirection: "column",
  };

  useEffect(() => {
    setPageTitle("New Classified");
  }, []);

  const handleDrop = async (acceptedFiles) => {
    const imageFile = acceptedFiles[0];
    setSelectedImage(URL.createObjectURL(imageFile));
    setUploadImage(acceptedFiles[0]);
  };

  const handleRejected = () => {
    Swal.fire({
      title: "File extension not supported",
      text: "Only images can be uploaded here",
      icon: "warning",
      color: "#fff",
      background: "#000",
      timer: 3000,
      showConfirmButton: false,
    });
  };

  const handleClear = () => {
    setTag([]);
    setTitle("");
    setContent("");
    setSelectedImage(null);
    setPostType("");
    setData({});
    setUploadImage(null);
  };

  const handleSubmit = async () => {
    handleClose();
    const body = {
      title: title,
      content: content,
      type: postType,
      image: uploadImage,
      tags: tag,
    };
    try {
      const res = await axios.post(SERVER_URL.CREATE_CLASSIFIED, body, {
        headers: {
          "Content-type": "multipart/form-data",
          enctype: "multipart/form-data",
          Authorization: `Bearer ${auth?.user?.token}`,
        },
      });
      if (res.status === 200) {
        ReactGA.event({
          category: "Classified",
          action: "New classified created",
        });
        handleClear();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to create the classified",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handlePreview = () => {
    if (title !== "" && content !== "" && postType !== "") {
      const d = {
        id: 1,
        title: title,
        slug: null,
        content: content,
        type: options.filter((d) => d.label === postType)[0]?.label,
        image_url: selectedImage,
        created_at: new Date(),
        user: {
          id: auth?.user?.id,
          first_name: auth?.user?.first_name || "Smith",
          last_name: auth?.user?.last_name || "John",
          profile_photo_url: auth?.user?.profile_photo_url || null,
        },
        tags: tag,
      };
      setData(d);
      handleOpen();
    }
  };

  return (
    <NavContainer>
      <Label label={"Add new classified"} size="lg" center />
      <div className={CarnivalClassifiedsCss.inboxDiv}>
        <div className={CarnivalClassifiedsCss.newClassifiedSubDiv}>
          <div className={CarnivalClassifiedsCss.label}>
            Describe the item you'd like to sell, swap or that you need. Be sure
            to include the band, section, and measurements.
          </div>
          <TextField
            onChange={(val) => setTitle(val)}
            placeholder={"Add Title here."}
            defaultValue={title}
          />
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "heading",
                  "|",
                  "fontFamily",
                  "fontSize",
                  "fontColor",
                  "fontBackgroundColor",
                  "|",
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "subscript",
                  "superscript",
                  "|",
                  "link",
                  "blockQuote",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "todoList",
                  "outdent",
                  "indent",
                ],
                shouldNotGroupWhenFull: false,
              },
            }}
            data={content}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContent(data);
            }}
          />
          <div className={CarnivalClassifiedsCss.wrapper1}>
            <div className={CarnivalClassifiedsCss.subWrapperDiv}>
              <DragAndDropZone
                handleDrop={handleDrop}
                handleRejected={handleRejected}
                borderColor={"#fff"}
                textColor={"#7E7E7E"}
                style={{
                  height: 100,
                }}
              />
            </div>
            {selectedImage && (
              <img
                src={selectedImage}
                className={CarnivalClassifiedsCss.image}
              />
            )}
          </div>
          <div className={CarnivalClassifiedsCss.wrapper}>
            <div className={CarnivalClassifiedsCss.subWrapper}>
              <TextField
                onChange={(val) => setPostType(val)}
                type={"select"}
                label={"Post Type"}
                data={options}
                defaultValue={postType}
              />
            </div>
            <div className={CarnivalClassifiedsCss.subWrapper}>
              <AutoComplete
                defaultValue={tag}
                handleOnChange={(val) => setTag(val)}
                options={tags}
                label="Tags"
                isSlug={true}
                disabled={tagsIsLoading}
              />
            </div>
          </div>
          <Button
            disabled={title === "" || content === "" || postType === ""}
            label="Preview"
            onClick={() => handlePreview()}
            styles={{ width: 200, display: "flex", alignSelf: "center" }}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <ClassifiedCard data={data} disabled={true} />
              <Button
                label="Submit"
                onClick={() => handleSubmit()}
                styles={{ width: 200, marginTop: 30 }}
              />
            </Box>
          </Modal>
          <div className={CarnivalClassifiedsCss.list}>
            <ul className={CarnivalClassifiedsCss.listStyle}>
              {instructions.map((item, index) => {
                return (
                  <li key={index} className={CarnivalClassifiedsCss.listText}>
                    {item.text}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </NavContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(NewClassified);
