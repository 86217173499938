import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Label from "../../../elements/label/Label";
import RatingModal from "../../../components/rating-modal/RatingModal";
import CommentList from "../../../components/comment/CommentList";
import Rating from "../../../components/rating/Rating";
import CarnivalCss from "../CarnivalHome.module.css";
import BlogCard from "../../../components/blog-card/BlogCard";
import ViewMore from "../../../elements/view-more/ViewMore.js";
import NewsCardLoader from "../../../elements/skeletonLoaders/NewsCardLoader.js";
import CommentLoader from "../../../elements/skeletonLoaders/CommentLoader.js";
import {
  ExtractShortDate,
  ExtractShortMonthDate,
} from "../../../elements/date-extractor/ExtractDate.js";
import FullWidthBlogsList from "../../../elements/Flatlist/FullWidthReviewsList.js";
export default function Review({
  userRating,
  tjjRating,
  setId,
  handleSubmitRatings,
  reviews,
  reviewsPage,
  reviewsLastPage,
  updateCommentPageNumber,
  updateReviewsPageNumber,
  ratings,
  handleLike,
  handlePostComment,
  comments,
  commentsLastPage,
  commentsPage,
  commentsIsLoading,
  reviewsIsLoading,
  moreReviewsIsLoading,
  moreCommentsIsLoading,
  handleDeleteComment,
  isDeleting,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  const onReply = (id) => {
    setId(id);
  };

  const RatingModalArgs = {
    onClick: (value) => handleSubmitRatings(value),
    args: [
      "Affordability",
      "Costumes",
      "Events",
      "Food",
      "Music",
      "Safety",
      "Culture",
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={CarnivalCss.reviewDiv}>
      <Grid container>
        <Grid item xs={12} md={8} className={CarnivalCss.gridPadding}>
          <div className={CarnivalCss.overallRatingDiv}>
            <div className={CarnivalCss.overallHeading}>Score Card</div>
            <Grid container>
              <Grid item xs={12} md={6} className={CarnivalCss.gridBorderRight}>
                <div className={CarnivalCss.overallRatingSubDiv}>
                  <div className={CarnivalCss.overallRatingHead}>
                    TJJ Rating
                  </div>
                  <div className={CarnivalCss.overallRating}>{tjjRating}%</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={CarnivalCss.overallRatingSubDiv}>
                  <div className={CarnivalCss.overallRatingHead}>
                    My Rating
                  </div>
                  <div className={CarnivalCss.overallRating}>{userRating}%</div>
                </div>
              </Grid>
            </Grid>
          </div>
          {reviewsIsLoading ? (
            <div className={CarnivalCss.flexDrR} style={{ marginTop: 40 }}>
              {Array.from({ length: 4 }, (_, index) => {
                return <NewsCardLoader key={index} />;
              })}
            </div>
          ) : reviews.length !== 0 && reviews?.length > 1 ? (
            <div className={CarnivalCss.multiReviewDiv}>
              <FullWidthBlogsList reviews={reviews} />
              {moreReviewsIsLoading && (
                <div className={CarnivalCss.flexDrR}>
                  {Array.from({ length: 4 }, (_, index) => {
                    return <NewsCardLoader key={index} />;
                  })}
                </div>
              )}
              {reviewsPage < reviewsLastPage && !moreReviewsIsLoading && (
                <ViewMore
                  loadMore={true}
                  onClick={() => {
                    updateReviewsPageNumber();
                  }}
                />
              )}
            </div>
          ) : (
            reviews.length !== 0 && (
              <>
                <div className={CarnivalCss.reviewTitle}>
                  {reviews[0]?.title}
                </div>
                <div
                  className={`${CarnivalCss.review} ck-content`}
                  dangerouslySetInnerHTML={{ __html: reviews[0]?.content }}
                />
                <div className={CarnivalCss.reviewAuthor}>
                  By: {reviews[0]?.author?.first_name}{" "}
                  {reviews[0]?.author?.last_name}
                </div>
                <div className={CarnivalCss.reviewPublished}>
                  Published: {ExtractShortMonthDate(reviews[0]?.created_at)}
                </div>
              </>
            )
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={CarnivalCss.ratingMainDiv}>
            <div className={CarnivalCss.ratingSubDiv}>
              <Label size="md" label={"Score"} />
              <RatingModal {...RatingModalArgs} />
            </div>
            {RatingComp(ratings, width)}
          </div>
          <div>
            <Label size="md" label={"Comments"} />
            {commentsIsLoading ? (
              <CommentLoader />
            ) : (
              <>
                <CommentList
                  onLike={handleLike}
                  onReply={onReply}
                  onButtonClick={(value) => handlePostComment(value)}
                  data={comments}
                  handleDelete={handleDeleteComment}
                  isDeleting={isDeleting}
                />
                {moreCommentsIsLoading && <CommentLoader />}
                {commentsPage < commentsLastPage && !moreCommentsIsLoading && (
                  <ViewMore
                    loadMore={true}
                    onClick={() => {
                      updateCommentPageNumber();
                    }}
                  />
                )}
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function RatingComp(ratings, width) {
  return (
    <div className={CarnivalCss.ratingDiv}>
      {width < 431 ? (
        <>
          <div className={CarnivalCss.ratingHead}>TJJ Rating</div>
          {ratings.map((item, index) => {
            return (
              <div key={index} className={CarnivalCss.ratingStyle}>
                {item.name}
                <Rating value={item.tjjRating} size={"large"} readOnly={true} />
              </div>
            );
          })}
          <div className={CarnivalCss.ratingHead}>My Rating</div>
          {ratings.map((item, index) => {
            return (
              <div key={index} className={CarnivalCss.ratingStyle}>
                {item.name}
                <Rating
                  value={item.userRating}
                  size={"large"}
                  readOnly={true}
                />
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div className={CarnivalCss.ratingHead}>
            <div className={CarnivalCss.ratingParam} />
            <span className={CarnivalCss.rating}>TJJ Rating</span>
            <span className={CarnivalCss.rating}>My Rating</span>
          </div>
          {ratings.map((item, index) => {
            return (
              <div key={index} className={CarnivalCss.ratingStyle}>
                <span className={CarnivalCss.ratingParam}>{item.name}</span>
                <span className={CarnivalCss.rating}>
                  <Rating
                    value={item.tjjRating}
                    size={"large"}
                    readOnly={true}
                  />
                </span>
                <span className={CarnivalCss.rating}>
                  <Rating
                    value={item.userRating}
                    size={"large"}
                    readOnly={true}
                  />
                </span>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}
