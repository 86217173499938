import React, { useContext, useEffect } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import NoData from "../../elements/no-data/NoData";
import { Context } from "../../Context";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import NoDataCss from "./Nodata.module.css";

export default function NoPageFound() {
  const { setPageTitle } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle("No Data");
  }, []);

  return (
    <NavContainer>
      <div className={NoDataCss.noDataDiv}>
        <NoData error={true} />
        <div className={NoDataCss.noDataButton}>
        <Button
          onClick={() => navigate("/")}
          label="RETURN TO HOME PAGE"
          backgroundColor={"#000000"}
          variant="outlined"
          color={"#ffffff"}
          borderColor={"#ffffff"}
        />
        </div>
      </div>
    </NavContainer>
  );
}
