import React from "react";
import { Skeleton } from "@mui/material";
import loaderCss from "./loader.module.css";
import { useState, useEffect } from "react";
export default function EventLongCardLoader() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {width > 431 ? (
        <div className={loaderCss.col}>
          <Skeleton
            sx={{ bgcolor: "grey.900", minWidth: "150px" }}
            animation="wave"
            variant="rectangular"
            width={"40%"}
            height={250}
          />
          <div className={loaderCss.seventy}>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="text"
              width={"60%"}
              height={40}
            />
            <Skeleton
              sx={{ bgcolor: "grey.900", marginTop: "0.2rem" }}
              animation="wave"
              variant="text"
              width={"50%"}
              height={20}
            />
            <Skeleton
              sx={{ bgcolor: "grey.900", marginTop: "10px" }}
              animation="wave"
              variant="text"
              width={"100%"}
              height={15}
            />
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="text"
              width={"80%"}
              height={15}
            />
            <div className={loaderCss.chips}>
              {Array.from({ length: 3 }, (item, index) => (
                <Skeleton
                  key={index}
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={50}
                  height={40}
                />
              ))}
            </div>
            <div className={loaderCss.col2}>
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="circular"
                height={40}
                width={40}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="circular"
                height={40}
                width={40}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                height={40}
                width={100}
              />
            </div>
            <Skeleton
              sx={{ bgcolor: "grey.900", marginTop: "7px" }}
              animation="wave"
              variant="text"
              width={200}
              height={15}
            />
          </div>
        </div>
      ) : (
        <>
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="rectangular"
            width={330}
            height={237}
          />
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={220}
            height={30}
          />
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: "17px" }}
            animation="wave"
            variant="text"
            width={190}
            height={20}
          />
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={220}
            height={15}
          />
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={220}
            height={15}
          />
          <div className={loaderCss.chips}>
            {Array.from({ length: 3 }, (item, index) => (
              <Skeleton
                key={index}
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={50}
                height={40}
              />
            ))}
          </div>
          <div className={loaderCss.col2}>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="circular"
              height={40}
              width={40}
            />
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="circular"
              height={40}
              width={40}
            />
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="rectangle"
              height={40}
              width={100}
            />
          </div>
          <Skeleton
            sx={{ bgcolor: "grey.900", marginTop: "7px" }}
            animation="wave"
            variant="text"
            width={width > 600 ? 200 : 100}
            height={15}
          />
        </>
      )}
    </>
  );
}
