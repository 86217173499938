import React, { useState, useEffect } from "react";
import {
  Chip,
  Autocomplete as MuiAutocomplete,
  TextField,
} from "@mui/material";
import { FontSizes } from "../../config";

const textfieldStyle = {
  "& .MuiInputBase-root input": {
    color: "#fff",
    paddingRight: "50px",
    fontSize: 14,
    fontFamily: "Inter-Regular",
  },
  "& .MuiFormLabel-root": {
    color: "#fff",
    fontSize: 14,
    fontFamily: "Inter-Regular",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#fff",
    fontSize: 14,
    fontFamily: "Inter-Regular",
  },
  "& .MuiInputBase-root": {
    color: "#fff !important",
  },

  "& .MuiOutlinedInput-root fieldset": {
    borderColor: "#fff !important",
  },

  "& .MuiOutlinedInput-root fieldset legend span": {
    fontSize: "11px",
  },
};

export default function AutoComplete({
  options = undefined,
  label = "",
  size = "small",
  defaultValue = [],
  handleOnChange = [],
  isSlug = false,
  disabled = false,
  singleSelect = false,
}) {
  const [width, setWidth] = useState(window.innerWidth);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);
  const [slugItems, setSlugItems] = useState([]);

  useEffect(() => {
    if (isSlug && singleSelect) {
      const filtered = options.filter((x) => x?.slug === defaultValue)[0];
      setItem(filtered);
    } else if (isSlug) {
      const filtered = options.filter((x) => defaultValue.includes(x.slug));
      setSlugItems(filtered);
    } else if (singleSelect) {
      const filtered = options.filter((x) => x?.slug === defaultValue)[0];
      setItem(filtered);
    } else {
      setItems(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (item) => {
    setItems(item);
    if (handleOnChange) {
      handleOnChange(item);
    }
  };

  const handleSingleChange = (item) => {
    setItem(item);
    if (handleOnChange) {
      handleOnChange(item?.slug);
    }
  };

  const handleSlugChange = (item) => {
    const slugs = item.map((i) => i.slug);
    setSlugItems(item);
    if (handleOnChange) {
      handleOnChange(slugs);
    }
  };

  const handleRemove = (item) => {
    const slugs = item.map((i) => i.slug);
    setSlugItems(item);
    if (handleOnChange) {
      handleOnChange(slugs);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isSlug && singleSelect ? (
    <MuiAutocomplete
      clearIcon={false}
      options={options}
      freeSolo={true}
      openOnFocus
      getOptionLabel={(option) => option.name}
      disabled={disabled}
      ListboxProps={{
        style: {
          backgroundColor: "#3A3A3A",
          color: "#fff",
          fontSize: FontSizes.text,
          fontFamily: "Inter-Medium",
          fontWeight: "500",
        },
        sx: {
          "&.MuiAutocomplete-listbox .MuiAutocomplete-option": {
            minHeight: width < 431 ? 20 : "auto",
          },
        },
      }}
      onChange={(item, newvalue) => handleSingleChange(newvalue)}
      value={item}
      renderInput={(params) => (
        <TextField
          sx={textfieldStyle}
          label={label}
          {...params}
          fullWidth
          id="outlined-helperText"
          size={size}
        />
      )}
    />
  ) : isSlug ? (
    <MuiAutocomplete
      clearIcon={false}
      options={options}
      disabled={disabled}
      freeSolo
      getOptionLabel={(option) => option.name}
      multiple
      openOnFocus
      includeInputInList
      ListboxProps={{
        style: {
          backgroundColor: "#3A3A3A",
          color: "#fff",
          fontSize: FontSizes.text,
          fontFamily: "Inter-Medium",
          fontWeight: "500",
        },
        sx: {
          "&.MuiAutocomplete-listbox .MuiAutocomplete-option": {
            minHeight: width < 431 ? 20 : "auto",
          },
        },
      }}
      renderTags={(value, props) =>
        value.map((option, index) => (
          <div key={index} style={{ margin: 2 }}>
            <Chip
              key={props({ index })?.key}
              sx={{
                color: "#fff",
                backgroundColor: "#ff0000",
                fontSize: FontSizes.text,
                fontFamily: "Inter-Regular",
                height: "auto !important",
                paddingY: 1,
                textOverflow: "ellipsis",
                ".MuiChip-label": {
                  whiteSpace: "wrap",
                },
              }}
              label={option.name}
              {...props({ index })}
            />
          </div>
        ))
      }
      onChange={(item, newvalue, reason) => {
        reason === "selectOption"
          ? handleSlugChange(newvalue)
          : reason === "removeOption" && handleRemove(newvalue);
      }}
      value={slugItems}
      renderInput={(params) => (
        <TextField
          sx={textfieldStyle}
          label={label}
          {...params}
          fullWidth
          id="outlined-helperText"
          size={size}
        />
      )}
    />
  ) : singleSelect ? (
    <MuiAutocomplete
      clearIcon={false}
      options={options}
      freeSolo={true}
      openOnFocus
      disabled={disabled}
      ListboxProps={{
        style: {
          backgroundColor: "#3A3A3A",
          color: "#fff",
          fontSize: FontSizes.text,
          fontFamily: "Inter-Medium",
          fontWeight: "500",
        },
        sx: {
          "&.MuiAutocomplete-listbox .MuiAutocomplete-option": {
            minHeight: width < 431 ? 20 : "auto",
          },
        },
      }}
      onChange={(item, newvalue) => handleSingleChange(newvalue)}
      value={item}
      renderInput={(params) => (
        <TextField
          sx={textfieldStyle}
          label={label}
          {...params}
          fullWidth
          id="outlined-helperText"
          size={size}
        />
      )}
    />
  ) : (
    <MuiAutocomplete
      clearIcon={false}
      options={options}
      freeSolo
      multiple
      openOnFocus
      disabled={disabled}
      ListboxProps={{
        style: {
          backgroundColor: "#3A3A3A",
          color: "#fff",
          fontSize: FontSizes.text,
          fontFamily: "Inter-Medium",
          fontWeight: "500",
        },
        sx: {
          "&.MuiAutocomplete-listbox .MuiAutocomplete-option": {
            minHeight: width < 431 ? 20 : "auto",
          },
        },
      }}
      renderTags={(value, props) =>
        value.map((option, index) => (
          <div key={index} style={{ margin: 2 }}>
            <Chip
              key={props({ index })?.key}
              sx={{
                color: "#fff",
                backgroundColor: "#ff0000",
                fontSize: FontSizes.text,
                fontFamily: "Inter-Regular",
                whiteSpace: "wrap",
              }}
              label={option}
              {...props({ index })}
            />
          </div>
        ))
      }
      onChange={(item, newvalue) => handleChange(newvalue)}
      value={items}
      renderInput={(params) => (
        <TextField
          sx={textfieldStyle}
          label={label}
          {...params}
          fullWidth
          id="outlined-helperText"
          size={size}
        />
      )}
    />
  );
}
