import React from "react";
import PlayerDuration from "../../elements/Main/PlayerDuration";
import PlayerSeekBar from "../../elements/Main/PlayerSeekBar";

function SeekControl({ songState, setSongState, audioRef}) {
  return (
    <div className="player__seek-controls">
      <PlayerDuration value={songState.elapsed} />
      <PlayerSeekBar
        songState={songState}
        setSongState={setSongState}
        audioRef={audioRef}
      />
      <PlayerDuration
        value={songState.duration}
      />
    </div>
  );
}

export default SeekControl;
