import { Skeleton } from '@mui/material';
import React from 'react'
import Label from '../../../../elements/label/Label';
import ViewMore from '../../../../elements/view-more/ViewMore';
import GlobalSearchCss from "../../GlobalSearch.module.css";
import EventLongCardLoader from '../../../../elements/skeletonLoaders/EventLongCardLoader';
import EventList from '../../../../elements/Flatlist/EventList';

export default function Event({
    events,
    isLoading,
    length,
    showViewAll = false,
    setValue,
    eventsLastPage,
    eventsPageNumber,
    moreEventsIsLoading,
    loadMoreEvents = undefined,
  }) {
    return (
      <div>
        <div className={GlobalSearchCss.mainDiv}>
          {isLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={"40%"}
              height={50}
            />
          ) : (
            <Label label={`Events (${length} results)`} size="md" />
          )}
        </div>
        {isLoading ? (
          <div className={GlobalSearchCss.flexDrR}>
            {Array.from({ length: 5 }, (_,index) => (
              <div key={index} style={{ width: "48%" }}>
                <EventLongCardLoader />
                <div className={GlobalSearchCss.mgb} />
              </div>
            ))}
          </div>
        ) : (
          <>
            {" "}
            <EventList events={events} />
            {showViewAll && <ViewMore onClick={() => setValue(3)} />}
            {moreEventsIsLoading &&
              Array.from({ length: 5 }, (_,index) => (
                <div key={index} style={{ maxWidth: "60%" }}>
                  <EventLongCardLoader />
                  <div className={GlobalSearchCss.mgb} />
                </div>
              ))}
            {events?.length !== 0 &&
            eventsLastPage != null &&
            eventsPageNumber < eventsLastPage &&
            !isLoading &&
            !moreEventsIsLoading &&
            !showViewAll ? (
              <ViewMore loadMore={true} onClick={loadMoreEvents} />
            ) : (
              <div className={GlobalSearchCss.mgb} />
            )}
          </>
        )}
      </div>
    );
  }
