import { Skeleton } from "@mui/material";
import React from "react";
import Label from "../../../../elements/label/Label";
import ViewMore from "../../../../elements/view-more/ViewMore";
import GlobalSearchCss from "../../GlobalSearch.module.css";
import GalleryCardLoader from "../../../../elements/skeletonLoaders/GalleryCardLoader";
import GalleryList from "../../../../elements/Flatlist/GalleryList";

export default function Gallery({
  galleries,
  isLoading,
  length,
  showViewAll = false,
  setValue,
  galleriesLastPage,
  galleriesPageNumber,
  moreGalleriesIsLoading,
  updateGalleryPageNumber = undefined,
}) {
  return (
    <div>
      <div className={GlobalSearchCss.mainDiv}>
        {isLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={"40%"}
            height={50}
          />
        ) : (
          <Label label={`Gallery (${length} results)`} size="md" />
        )}
      </div>
      {isLoading ? (
        <div className={GlobalSearchCss.flexDrR}>
          {Array.from({ length: 8 }, (_,index) => {
            return (
              <div key={index}>
                <GalleryCardLoader />
              </div>
            );
          })}
        </div>
      ) : (
        <>
          <GalleryList gallery={galleries} />
          {moreGalleriesIsLoading && (
            <div className={GlobalSearchCss.flexDrR}>
              {Array.from({ length: 8 }, (_,index) => {
                return (
                  <div key={index}>
                    <GalleryCardLoader />
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
      {!isLoading &&
      galleries &&
      !moreGalleriesIsLoading &&
      galleriesLastPage != null &&
      galleriesPageNumber < galleriesLastPage &&
      !showViewAll ? (
        <ViewMore loadMore onClick={() => updateGalleryPageNumber()} />
      ) : (
        galleries &&
        galleries?.length !== 0 &&
        !showViewAll && <div className={GlobalSearchCss.mgb40} />
      )}
      {showViewAll && <ViewMore onClick={() => setValue(4)} />}
    </div>
  );
}
