import { Grid } from "@mui/material";
import React from "react";
import BlogCard from "../../components/blog-card/BlogCard";
import { useNavigate } from "react-router";
import { ExtractShortDate } from "../date-extractor/ExtractDate";
import { createSearchParams } from "react-router-dom";
import FlatlistCss from "./Flatlist.module.css";
import SlugConverter from "../slug-converter/SlugConverter";
import ReactGA from "react-ga4";

export const encodeSearchParams = (params) => createSearchParams(params);

export default function NewsList({ news, share, isNewsArticle }) {
  const navigate = useNavigate();
  const handleClick = (item) => {
    if (isNewsArticle) {
      navigate(`/news-articles/${SlugConverter(item?.slug)}`);
    } else if (share) {
      navigate(`/reviews/${SlugConverter(item?.slug)}`);
    } else {
      navigate(`/newsletters/${SlugConverter(item?.slug)}`);
    }
    window.scrollTo(0, 0);
  };

  function handleShare(label, item) {
    if (isNewsArticle) {
      ReactGA.event({
        category: "Share",
        action: "NewsArticle's Share Button Clicked",
        label: label,
      });
      ReactGA.event({
        category: "Share",
        action: `NewsArticle's Shared on ${item}`,
        label: label,
      });
    } else if (share) {
      ReactGA.event({
        category: "Share",
        action: "Review's Share Button Clicked",
        label: label,
      });
      ReactGA.event({
        category: "Share",
        action: `Review's Share Button Clicked(Shared on ${item}`,
        label: label,
      });
    } else {
      ReactGA.event({
        category: "Share",
        action: "Newsletter's Share Button Clicked",
        label: label,
      });
      ReactGA.event({
        category: "Share",
        action: `Newsletter's Share Button Clicked(Shared on ${item}`,
        label: label,
      });
    }
  }

  const handleNavigate = (param) => {
    let route = "/";
    const params = param?.tag
      ? `tag=${SlugConverter(param.tag)}`
      : `author=${SlugConverter(param.author)}`;
    if (isNewsArticle) {
      route = "/news-articles?" + params;
    } else if (share) {
      route = "/reviews?" + params;
    } else {
      route = "/newsletters?" + params;
    }
    navigate(route);
    window.scrollTo(0, 0);
  };
  return (
    <Grid container rowSpacing={4} columnSpacing={2}>
      {!share
        ? news?.map((item, index) => {
            const date = ExtractShortDate(item.created_at);
            return (
              <Grid key={index} item xs={12} sm={12} md={12} lg={6}>
                <BlogCard
                  onClick={() => handleClick(item)}
                  title={item.title}
                  imageSrc={item.poster_url || item.featured_image_url}
                  handleChipCallback={(item) => handleNavigate({ tag: item })}
                  onShare={handleShare}
                  footerText={
                    <span>
                      Published by{" "}
                      {item?.creator ? (
                        <span
                          className={FlatlistCss.name}
                          onClick={() =>
                            handleNavigate({
                              author: item?.creator?.slug
                                ? item?.creator?.slug
                                : `${item?.creator?.first_name} ${item?.creator?.last_name}`,
                            })
                          }
                        >
                          {item?.creator?.first_name} {item?.creator?.last_name}
                        </span>
                      ) : (
                        <span>Admin</span>
                      )}{" "}
                      on {date}
                    </span>
                  }
                  excerpt={item.excerpt || item.content}
                  tagArray={item.tags}
                  size={"sm"}
                  share={true}
                />
              </Grid>
            );
          })
        : news?.map((item, index) => {
            const date = ExtractShortDate(item.created_at);
            return (
              <Grid key={index} item xs={12} sm={12} md={6}>
                <BlogCard
                  onClick={() => handleClick(item)}
                  title={item.title}
                  imageSrc={item.poster_url || item.featured_image_url}
                  footerText={
                    <span>
                      Published by{" "}
                      {item?.blogger ? (
                        <span
                          className={FlatlistCss.name}
                          onClick={() =>
                            handleNavigate({
                              author: item?.blogger?.slug,
                            })
                          }
                        >
                          {item?.blogger?.first_name} {item?.blogger?.last_name}
                        </span>
                      ) : (
                        <span>Admin</span>
                      )}{" "}
                      on {date}
                    </span>
                  }
                  excerpt={item.excerpt || item.content}
                  tagArray={item.tags}
                  handleChipCallback={(item) => handleNavigate({ tag: item })}
                  size={"sm"}
                  share={share}
                />
              </Grid>
            );
          })}
    </Grid>
  );
}
