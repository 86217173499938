import React, { useEffect, useState } from 'react'

export const useLoggedInState=()=> {
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        const storedAuthState = localStorage.getItem("authState");
        if (storedAuthState) {
          const parsedAuthState = JSON.parse(storedAuthState);
          setIsLoggedIn(parsedAuthState?.isLoggedIn);
        }
        else{
          setIsLoggedIn(false)
        }
      }, []);

      return isLoggedIn
}
