import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  music: {
    isPlaying: false,
    musicArgs: {
      title: "",
      subTitle: "",
      hlsSource: "",
      thumbnail: "",
      footerText: "",
      duration: "",
    },
  },
};

export const musicSlice = createSlice({
  name: "music",
  initialState,
  reducers: {
    setMusic: (state, action) => {
      state.music = [action.payload];
    },
  },
});

export const { setMusic } = musicSlice.actions;

export const selectMusic = (state) => state.music.music;

export default musicSlice.reducer;
