import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import PropTypes from "prop-types";

export function MusicPlayerRoot({ hlsSource }) {
  const audio = new Audio();
  const hlsRef = useRef(null);
  const audioRef = useRef(audio);
  audioRef.current.ontimeupdate = onProgress;
  const [songState, setSongState] = useState({
    currentSong: [],
    isPlaying: false,
    elapsed: "00:00",
    duration: "00:00",
    currentTime: 0,
    totalDuration: 0,
    isPlaying: false,
  });

  useEffect(() => {
    if (hlsRef?.current) {
      hlsRef.current.destroy();
    }
    if (audioRef.current) {
      if (hlsSource?.endsWith(".mp3")) {
        audioRef.current.src = hlsSource;
        // audioRef.current.src = "https://tjj-uploads.s3.us-east-2.amazonaws.com/uploads/songs/KES%20The%20Band%20-%20Come%20Gyal.mp3";
        audioRef.current.load();
        audioRef.current.onloadedmetadata = () => {
          const dur = formatTime(audioRef.current.duration);
          playAudio();
          setSongState({
            ...songState,
            isPlaying: true,
            elapsed: "00:00",
            duration: dur,
            totalDuration: Math.floor(audioRef.current.duration),
          });
        };
      } else {
        hlsRef.current = new Hls();
        hlsRef?.current.attachMedia(audioRef.current);
        hlsRef?.current.on(Hls.Events.MEDIA_ATTACHED, () => {
          hlsRef?.current.loadSource(hlsSource);
        });

        hlsRef?.current.on(Hls.Events.MANIFEST_PARSED, () => {
          hlsRef?.current.on(Hls.Events.LEVEL_LOADED, (_, data) => {
            const duration = formatTime(data.details.totalduration);
            songState.currentSong[0] = audioRef.current.src;
            playAudio();
            setSongState({
              ...songState,
              isPlaying: true,
              elapsed: "00:00",
              duration: duration,
              currentTime: 0,
              totalDuration: Math.floor(data.details.totalduration),
            });
          });
        });
      }
    }
  }, [hlsSource]);

  const formatTime = (time) => {
    if (time && !isNaN(time)) {
      const minutes = Math.floor(time / 60);
      const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const seconds = Math.floor(time % 60);
      const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${formatMinutes}:${formatSeconds}`;
    }
    return "00:00";
  };

  function onProgress() {
    const time = formatTime(audioRef?.current?.currentTime);
    const current = Math.floor(audioRef?.current?.currentTime);
    setSongState({
      ...songState,
      elapsed: time,
      currentTime: current,
    });
  }

  function playAudio() {
    setSongState({
      ...songState,
      isPlaying: true,
    });
    return audioRef?.current?.play();
  }

  function pauseAudio() {
    setSongState({
      ...songState,
      isPlaying: false,
    });
    return audioRef?.current?.pause();
  }

  function getAudioRef() {
    return audioRef;
  }

  return {
    songState,
    setSongState,
    getAudioRef,
    playAudio,
    pauseAudio,
    onProgress,
  };
}

MusicPlayerRoot.propTypes = {
  hlsSource: PropTypes.string,
};

MusicPlayerRoot.defaultProps = {
  hlsSource: "",
};
