import React, { useState } from "react";
import "./iconButton.css";
import { IconButton as MUIButton } from "@mui/material";

export default function IconButton({
  backgroundColor = "red",
  size = "medium",
  variant = "contained",
  showAnimation = false,
  styles = {},
  icon = undefined,
  onClick = undefined,
  color = "#fff",
  disabled = false,
  borderRadius = 0,
  hoverColor = null,
  onDoubleClick = undefined,
}) {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    if (showAnimation) {
      setIsHover(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const ButtonStyles = {
    ...styles,
    backgroundColor:
      variant === "contained"
        ? isHover
          ? hoverColor || "#1c1c1c"
          : backgroundColor || "red"
        : "",
    color: variant === "contained" ? color || "white" : color || "white",
    borderRadius: borderRadius || size === "large" ? 50 : 20,
    transition: " color 0.25s ease-in-out, background-color 0.35s ease-in-out",
  };
  return (
    <>
      <MUIButton
        variant={variant}
        size={size}
        style={ButtonStyles}
        onClick={onClick}
        disabled={disabled}
        onMouseEnter={handleMouseEnter}
        onDoubleClick={onDoubleClick}
        onMouseLeave={handleMouseLeave}
        className={!showAnimation && variant !== "contained" ? "noHover" : ""}
      >
        {icon}
      </MUIButton>
    </>
  );
}
