import React from "react";
import InboxCardCss from "./InboxCard.module.css";

export default function InboxCard({
  data = {},
  onClick = undefined,
  user = {},
}) {
  return (
    <div onClick={onClick} className={InboxCardCss.inboxCardMainDiv}>
      <div className={InboxCardCss.flex}>
        {user?.profile_photo_url ? (
          <img
            className={InboxCardCss.inboxCardAvatar}
            src={user?.profile_photo_url}
            alt="user"
          />
        ) : (
          <div className={InboxCardCss.classifiedChatCardAvatar}>
            <div className={InboxCardCss.classifiedChatCardUsername}>
              {user && user?.first_name && user?.first_name[0]}
            </div>
          </div>
        )}
        <div className={InboxCardCss.inboxCardDiv2}>
          <div className={InboxCardCss.inboxCardUsername}>
            {user?.first_name} {user?.last_name}
          </div>
          {data?.message_text && (
            <div className={InboxCardCss.inboxCardText}>
              {data?.message_text}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
