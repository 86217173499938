import React, { useEffect, useState } from "react";
import FlatListCss from "./Flatlist.module.css";
import { CardSizes } from "../../config";
import { navigate, useNavigate } from "react-router-dom";
export default function YearList({ years, onClick }) {
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={FlatListCss.songListDiv}>
      {years?.map((item, index) => {
        return (
          <div
            onClick={() => {
              navigate("/music/albums/all?year=" + item?.year);
              window.scrollTo(0, 0);
            }}
            key={index}
            style={{
              background:
                item?.colors && item?.colors?.length !== 0
                  ? `linear-gradient(to bottom right, ${item?.colors[0]} 25%, ${item?.colors[1]} 34%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, ${item?.colors[0]} 25%, ${item?.colors[1]} 34%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, ${item?.colors[0]} 25%, ${item?.colors[1]} 34%) top left / 50% 50% no-repeat, linear-gradient(to top right, ${item?.colors[0]} 25%, ${item?.colors[1]} 34%) top right / 50% 50% no-repeat`
                  : `linear-gradient(to bottom right, rgba(240, 115, 24, 0.96) 25%, rgba(199, 61, 52, 0.83) 34%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(240, 115, 24, 0.96) 25%, rgba(199, 61, 52, 0.83) 34%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(240, 115, 24, 0.96) 25%, rgba(199, 61, 52, 0.83) 34%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(240, 115, 24, 0.96) 25%, rgba(199, 61, 52, 0.83) 34%) top right / 50% 50% no-repeat`,
              width: width < 431 ? "46.8%" : CardSizes.sm - 1,
            }}
            className={FlatListCss.yearCard}
          >
            <div className={FlatListCss.yearText}>{item.year}</div>
          </div>
        );
      })}
    </div>
  );
}
