import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import AccountsCss from "./AccountsCss.module.css";
import EventSlides from "./elements/event-slides/EventSlides";
import { useNavigate, useLocation } from "react-router";
import Button from "../../components/button/Button";
import { Delete, Edit } from "@mui/icons-material";
import IconButton from "../../components/icon-button/IconButton";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip";
import MetaTags from "../../MetaTags";
import ViewMore from "../../elements/view-more/ViewMore";
import { Context } from "../../Context";
import { Modal, Skeleton } from "@mui/material";
import { useLoggedInState } from "../../elements/logged-in-state/useLoggedInState";
import Swal from "sweetalert2";
import EventCardLoader from "../../elements/skeletonLoaders/EventCardLoader";
import { connect } from "react-redux";
import { SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import EditItinerary from "./elements/EditItinerary.js";
import AddIcon from "@mui/icons-material/Add";
import Unauthorized from "../no-data/Unauthorized.js";
import ReactGA from "react-ga4";

function MyItinerary({ user }) {
  const [isLoading, setIsLoading] = useState(true);
  const [itineraryData, setItineraryData] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const isLoggedIn = useLoggedInState();
  const navigate = useNavigate();
  const { setPageTitle } = useContext(Context);
  const { pathname } = useLocation();
  const [tempId, setTempId] = useState(null);
  const [openId, setOpenId] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    if (tempId) {
      ReactGA.send({
        hitType: "pageview",
        page: `/my-itinerary/${tempId}`,
        title: "My Itinerary",
      });
    }
  }, [tempId]);

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      const id = u?.split("-")[2];
      setTempId(id);
    }
  }, [pathname]);

  useEffect(() => {
    if (isAuthorized) {
      handleGetItineraries();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (tempId && user?.id) {
      const authorize = parseInt(tempId) === parseInt(user?.id);
      setIsAuthorized(authorize);
    }
  }, [tempId, user]);

  const handleGetItineraries = async () => {
    try {
      const res = await axios.get(
        `${SERVER_URL.GET_USER_ITINERARIES_BY_ID}${tempId}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (res.status === 200) {
        if (res?.data && res?.data?.itineraries) {
          setItineraryData(res?.data?.itineraries);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isAuthorized) {
      setPageTitle("My Itinerary");
    }
  }, [isAuthorized]);

  const handleEditItinerary = async (id, body) => {
    try {
      const res = await axios.post(
        `${SERVER_URL.EDIT_ITINERARIES}${id}/update`,
        body,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Itinerary",
          action: "Edit itinerary button clicked",
          label: "An itinerary is edited",
        });
        setIsLoading(true);
        handleGetItineraries();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to edit the itinerary",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteItinerary = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.EDIT_ITINERARIES}${id}/delete`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Itinerary",
          action: "Delete itinerary button clicked",
          label: "An itinerary is deleted",
        });
        setIsLoading(true);
        handleGetItineraries();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete the itinerary",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteFromItinerary = async (id, eventId) => {
    const body = {
      itinerary_id: id,
      event_id: eventId,
    };
    try {
      const res = await axios.post(SERVER_URL.ADD_TO_ITINERARIES, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        setIsLoading(true);
        handleGetItineraries();
        ReactGA.event({
          category: "Itinerary",
          action: "Delete event from itinerary button clicked",
          label: "An event has been deleted from an itinerary",
        });
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete from the itinerary",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Itinerary's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `${label} itinerary Shared on ${item}`,
      label: label,
    });
  }

  const handleAddMoreEvents = () => {
    navigate("/events");
    ReactGA.event({
      category: "Itinerary",
      action: "Add more events button clicked",
      label: "Add more events button clicked",
    });
    window.scrollTo(0, 0);
  };

  if (isAuthorized === false) {
    return <Unauthorized />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"Itinerary"}
        url={window.location.href}
        type={"website"}
      />
      <Label label={"My Itinerary"} size="lg" />
      {isLoading ? (
        Array.from({ length: 4 }, (_, index) => {
          return (
            <>
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 5 }}
                animation="wave"
                variant="rectangular"
                width={"20%"}
                height={30}
                key={index}
              />
              <div className={AccountsCss.flexDrR}>
                {Array.from({ length: 4 }, (_, index) => {
                  return <EventCardLoader key={index} />;
                })}
              </div>
            </>
          );
        })
      ) : itineraryData && itineraryData?.length !== 0 ? (
        itineraryData?.map((item, index) => (
          <div key={index}>
            <div className={AccountsCss.mainDiv}>
              <Label label={item.title} size="md" />
              {isLoggedIn &&
                parseInt(tempId) === user?.id &&
                (width < 500 ? (
                  <div className={AccountsCss.buttonDiv}>
                    <SharingTooltip
                      onShare={handleShare}
                      transparent
                      link={`https://${window.location.href?.split("/")[2]}/events/itinerary/${item?.slug}`}
                    />
                    <IconButton
                      backgroundColor={"transparent"}
                      onClick={() => {
                        setOpen(true);
                        setOpenId(item?.id);
                      }}
                      icon={<Edit className={AccountsCss.closeIcon} />}
                    />
                    <IconButton
                      backgroundColor={"transparent"}
                      icon={<Delete className={AccountsCss.closeIcon} />}
                      onClick={() => handleDeleteItinerary(item?.id)}
                    />
                    {isAuthorized && (
                      <IconButton
                        backgroundColor={"transparent"}
                        icon={<AddIcon className={AccountsCss.closeIcon} />}
                        onClick={() => handleAddMoreEvents()}
                      />
                    )}
                  </div>
                ) : (
                  <div className={AccountsCss.buttonDiv}>
                    <SharingTooltip
                      onShare={handleShare}
                      withText
                      showAnimation={true}
                      backgroundColor={"#1C1C1C"}
                      hoverColor={"#ff0000"}
                      link={`https://${window.location.href?.split("/")[2]}/events/itinerary/${item?.slug}`}
                    />
                    <Button
                      label="Edit"
                      showAnimation={true}
                      backgroundColor={"#1C1C1C"}
                      hoverColor={"#ff0000"}
                      icon={<Edit className={AccountsCss.closeIcon} />}
                      iconLeft={true}
                      onClick={() => {
                        setOpen(true);
                        setOpenId(item?.id);
                      }}
                    />
                    <Button
                      label="Delete"
                      showAnimation={true}
                      backgroundColor={"#1C1C1C"}
                      hoverColor={"#ff0000"}
                      icon={<Delete className={AccountsCss.closeIcon} />}
                      iconLeft={true}
                      onClick={() => handleDeleteItinerary(item?.id)}
                    />
                    {isAuthorized && (
                      <Button
                        label="Add more events"
                        showAnimation={true}
                        backgroundColor={"#1C1C1C"}
                        hoverColor={"#ff0000"}
                        icon={<AddIcon className={AccountsCss.closeIcon} />}
                        iconLeft={true}
                        onClick={() => handleAddMoreEvents()}
                      />
                    )}
                  </div>
                ))}

              {open && openId === item?.id && (
                <Modal
                  open={open}
                  onClose={() => setOpen(false)}
                  className={AccountsCss.modalDiv}
                >
                  <EditItinerary
                    onClick={(i) => handleEditItinerary(item?.id, i)}
                    setOpen={(bool) => setOpen(bool)}
                    name={item?.title}
                    itineraryDescription={item?.description}
                  />
                </Modal>
              )}
            </div>

            {item?.events && item?.events?.length === 0 && (
              <>
                <div className={AccountsCss.flexDrC}>
                  <div className={AccountsCss.noItems}>No items added yet</div>
                  {isLoggedIn && parseInt(tempId) === user?.id && (
                    <>
                      <div className={AccountsCss.noItemsSub}>To add items</div>
                      <Button
                        label="Click Here"
                        backgroundColor={"#ff0000"}
                        styles={{ alignSelf: "center" }}
                        onClick={() => handleClick("/events")}
                      />
                    </>
                  )}
                </div>
              </>
            )}

            {item?.events && item?.events?.length !== 0 && (
              <EventSlides
                data={item?.events}
                handleDelete={(i) => handleDeleteFromItinerary(item?.id, i)}
                showDelete={isLoggedIn && parseInt(tempId) === user?.id}
              />
            )}
            {item?.events &&
            item?.events?.length !== 0 &&
            item?.events?.length >= 4 ? (
              <ViewMore onClick={() => handleClick(`${item?.slug}`)} />
            ) : (
              <div className={AccountsCss.mgb} />
            )}
          </div>
        ))
      ) : (
        <div className={AccountsCss.noItems}>
          No itinerary added,{" "}
          <span
            onClick={() => handleClick("/events")}
            className={AccountsCss.startButton}
          >
            Start
          </span>{" "}
          adding to your itinerary
        </div>
      )}
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MyItinerary);
