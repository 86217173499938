import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import { Grid, Skeleton } from "@mui/material";
import Chip from "../../components/chip/Chip";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip";
import GalleryList from "../../elements/Flatlist/GalleryList";
import VideoList from "../../elements/Flatlist/VideoList";
import Card from "../../components/card/Card";
import CarnivalTabs from "./elements/CarnivalTabs";
import CarnivalCss from "./CarnivalHome.module.css";
import CountryFooter from "../../elements/country-footer/CountryFooter";
import Button from "../../components/button/Button";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../Context";
import { FaCalendarAlt } from "react-icons/fa";
import EventList from "../../elements/Flatlist/EventList";
import AlbumsList from "../../elements/Flatlist/AlbumsList";
import MetaTags from "../../MetaTags";
import GalleryCardLoader from "../../elements/skeletonLoaders/GalleryCardLoader";
import DetailsDescriptionLoader from "../../elements/skeletonLoaders/DetailsDescriptionLoader";
import EventLongCardLoader from "../../elements/skeletonLoaders/EventLongCardLoader";
import ReLiveCardLoader from "../../elements/skeletonLoaders/ReLiveCardLoader";
import VideoCardLoader from "../../elements/skeletonLoaders/VideoCardLoader";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader";
import UpcomingCarnivalCardLoader from "../../elements/skeletonLoaders/UpcomingCarnivalCardLoader";
import ViewMore from "../../elements/view-more/ViewMore";
import Swal from "sweetalert2";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { SERVER_URL, PER_PAGE_COUNT } from "../../Constants.ts";
import {
  ExtractDate,
  ExtractShortMonthDate,
} from "../../elements/date-extractor/ExtractDate.js";
import { TimeExtractor } from "../../elements/time-extractor/TimeExtractor.js";
import NoDataScreen from "../no-data/NoDataScreen.js";
import ReactGA from "react-ga4";

function CarnivalDetail({ isLoggedIn, user }) {
  const { slug } = useParams();
  const [carnivalDetails, setCarnivalDetails] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [eventsPageNumber, setEventsPageNumber] = useState(1);
  const [recentsLastPage, setRecentsLastPage] = useState(0);
  const [recentsPageNumber, setRecentsPageNumber] = useState(1);
  const [moreRecentsIsLoading, setMoreRecentsIsLoading] = useState(false);
  const [galleriesPage, setGalleriesPage] = useState(1);
  const [eventsLastPage, setEventsLastPage] = useState(0);
  const [galleriesLastPage, setGalleriesLastPage] = useState(0);
  const { setPageTitle } = useContext(Context);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [videosData, setVideosData] = useState([]);
  const [videosLastPage, setVideosLastPage] = useState(0);
  const [videosPage, setVideosPage] = useState(1);
  const [galleries, setGalleries] = useState([]);
  const [events, setEvents] = useState([]);
  const [recentCarnival, setRecentCarnival] = useState([]);
  const [upcomingCarnivals, setUpcomingCarnivals] = useState([]);
  const [upcomingIsLoading, setUpcomingIsLoading] = useState(true);
  const [detailsIsLoading, setDetailsIsLoading] = useState(true);
  const [eventIsLoading, setEventIsLoading] = useState(true);
  const [recentIsLoading, setRecentIsLoading] = useState(true);
  const [galleriesIsLoading, setGalleriesIsLoading] = useState(true);
  const [musicIsLoading, setMusicIsLoading] = useState(true);
  const [moreMusicIsLoading, setMoreMusicIsLoading] = useState(false);
  const [musicLastPage, setMusicLastPage] = useState(0);
  const [music, setMusic] = useState([]);
  const { pathname } = useLocation();
  const [musicPageNumber, setMusicPageNumber] = useState(1);
  const [url, setUrl] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [videosIsLoading, setVideosIsLoading] = useState(true);
  const [slugUrl, setSlugUrl] = useState(null);
  const [comments, setComments] = useState([]);
  const [commentsPage, setCommentsPage] = useState(1);
  const [commentsIsLoading, setCommentsIsLoading] = useState(true);
  const [commentsLastPage, setCommentsLastPage] = useState(null);
  const [ratings, setRatings] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [reviewsIsLoading, setReviewsIsLoading] = useState(true);
  const [moreReviewsIsLoading, setMoreReviewsIsLoading] = useState(false);
  const [moreCommentsIsLoading, setMoreCommentsIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [reviewsLastPage, setReviewsLastPage] = useState([]);
  const [reviewsPage, setReviewsPage] = useState(1);
  const [generalInformation, setGeneralInformation] = useState([]);
  const [foodNDrink, setFoodNDrink] = useState([]);
  const [tjjRecommendations, setTjjRecommendations] = useState([]);
  const [status, setStatus] = useState(null);
  const [isDeletingComment, setIsDeletingComment] = useState(false);
  const [moreEventsIsLoading, setMoreEventsIsLoading] = useState(false);
  const [moreVideosIsLoading, setMoreVideosIsLoading] = useState(false);
  const [moreGalleriesIsLoading, setMoreGalleriesIsLoading] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/carnival/${slug}`,
      title: "Carnival Detail",
    });
  }, []);

  // This useEffect is extracting the slug from the web-url and creating a link for api call.
  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      setSlugUrl(`${SERVER_URL.GET_CARNIVAL_DETAILS_BY_SLUG}${u}`);
    }
  }, [pathname]);

  useEffect(() => {
    handleGetRecentCarnivals();
  }, [recentsPageNumber]);

  const loadMoreRecentCarnivals = () => {
    if (recentsLastPage != null && recentsPageNumber <= recentsLastPage) {
      setMoreRecentsIsLoading(true);
      setRecentsPageNumber(recentsPageNumber + 1);
    }
  };

  // When slug is extracted and api is ready, this useEffect will get the data from the api.
  useEffect(() => {
    if (slugUrl) {
      handleGetCarnivalDetails();
    }
  }, [slugUrl]);

  // This useEffect will be called once carnival's details were fetched. This will help us to get the data from other apis which depends upon carnival's id.
  useEffect(() => {
    if (carnivalDetails) {
      setUrl(`${SERVER_URL.GET_CARNIVAL_DETAILS}/${carnivalDetails?.id}`);
    }
  }, [carnivalDetails]);

  // Calling all the functions which depends upon carnival's id.
  useEffect(() => {
    if (url) {
      handleGetUpcoming();
      handleGetRatings();
      handleGetSections();
    }
  }, [url]);

  const handleGetSections = async () => {
    try {
      const res = await axios.get(url + "/sections", {
        headers: {
          Accept: "application/json",
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          setGeneralInformation(res?.data["General Information"]);
          setFoodNDrink(res?.data["Food & Drinks"]);
          setTjjRecommendations(res?.data["TJJ Recommendations"]);
        }
      }
    } catch (err) {
      setGeneralInformation(null);
      setFoodNDrink(null);
      setTjjRecommendations(null);
    }
  };

  const handleGetUpcoming = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_UPCOMING_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.FEATURED_VIDEOS_HOME,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setUpcomingCarnivals(res?.data?.data);
        }
        setUpcomingIsLoading(false);
      }
    } catch (err) {
      setUpcomingIsLoading(false);
    }
  };

  const handleGetCarnivalDetails = async () => {
    try {
      const res = await axios.get(slugUrl + "/details", {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user.token}` }),
        },
      });
      setStatus(res?.status);
      if (res.status === 200) {
        if (res?.data) {
          setCarnivalDetails(res?.data);
          setIsSubscribed(res?.data?.subscribed);
        }
        setDetailsIsLoading(false);
      }
    } catch (err) {
      setDetailsIsLoading(false);
      setStatus(err.response.status);
    }
  };

  const handleGetRecentCarnivals = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_RECENT_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: recentsPageNumber,
          items: PER_PAGE_COUNT.RECENT_CARNIVALS,
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          if (res?.data?.data) {
            if (recentCarnival?.length === 0) {
              setRecentCarnival(res?.data?.data);
            } else {
              setRecentCarnival((prev) => [...prev, ...res?.data?.data]);
            }
          }
          setRecentsLastPage(res?.data?.last_page);
        }
        if (moreRecentsIsLoading) {
          setMoreRecentsIsLoading(false);
        }
        setRecentIsLoading(false);
      }
    } catch (err) {
      setRecentIsLoading(false);
      if (moreRecentsIsLoading) {
        setMoreRecentsIsLoading(false);
      }
    }
  };

  const handleCarnivalEvents = async () => {
    try {
      const res = await axios.get(url + "/events", {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: eventsPageNumber,
          items: PER_PAGE_COUNT.CARNIVAL_EVENTS,
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          if (res?.data?.data) {
            if (events?.length === 0) {
              setEvents(res?.data?.data);
            } else {
              setEvents((prev) => [...prev, ...res?.data?.data]);
            }
          }
          setEventsLastPage(res?.data?.last_page);
        }
        setEventIsLoading(false);
        if (moreEventsIsLoading) {
          setMoreEventsIsLoading(false);
        }
      }
    } catch (err) {
      setEventIsLoading(false);
    }
  };

  const handleCarnivalGalleries = async () => {
    try {
      const res = await axios.get(url + "/galleries", {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: galleriesPage,
          items: PER_PAGE_COUNT.CARNIVAL_GALLERIES,
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          if (res?.data?.data) {
            if (galleries?.length === 0) {
              setGalleries(res?.data?.data);
            } else {
              setGalleries((prev) => [...prev, ...res?.data?.data]);
            }
          }
          setGalleriesLastPage(res?.data?.last_page);
        }
        if (moreGalleriesIsLoading) {
          setMoreGalleriesIsLoading(false);
        }
        setGalleriesIsLoading(false);
      }
    } catch (err) {
      setGalleriesIsLoading(false);
    }
  };

  const handleCarnivalVideos = async () => {
    try {
      const res = await axios.get(url + "/videos", {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: videosPage,
          items: PER_PAGE_COUNT.CARNIVAL_VIDEOS,
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          if (res?.data?.data) {
            if (videosData?.length === 0) {
              setVideosData(res?.data?.data);
            } else {
              setVideosData((prev) => [...prev, ...res?.data?.data]);
            }
          }
          setVideosLastPage(res?.data?.last_page);
        }
        if (moreVideosIsLoading) {
          setMoreVideosIsLoading(false);
        }
        setVideosIsLoading(false);
      }
    } catch (err) {
      setVideosIsLoading(false);
    }
  };

  const handleGetCarnivalMusic = async () => {
    try {
      const res = await axios.get(url + "/music", {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: musicPageNumber,
          items: PER_PAGE_COUNT.CARNIVAL_MUSIC,
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          if (res?.data?.data) {
            if (music?.length === 0) {
              setMusic(res?.data?.data);
            } else {
              setMusic((prev) => [...prev, ...res?.data?.data]);
            }
          }
          setMusicLastPage(res?.data?.last_page);
        }
        if (moreMusicIsLoading) {
          setMoreMusicIsLoading(false);
        }
        setMusicIsLoading(false);
      }
    } catch (err) {
      setMusicIsLoading(false);
      if (moreMusicIsLoading) {
        setMoreMusicIsLoading(false);
      }
    }
  };

  const handleSubscribe = () => {
    setIsSubscribing(true);
    axios
      .get(url + `/subscribe`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (isSubscribed) {
            ReactGA.event({
              category: "Unsubscribe",
              action: `Unsubscribed a carnival`,
              label: carnivalDetails?.title,
            });
            ReactGA.event({
              category: "Unsubscribe",
              action: `Unsubscribed a carnival ${carnivalDetails?.title}`,
              label: carnivalDetails?.title,
            });
          } else {
            ReactGA.event({
              category: "Subscribe",
              action: `Subscribed a carnival`,
              label: carnivalDetails?.title,
            });
            ReactGA.event({
              category: "Subscribe",
              action: `Subscribed a carnival ${carnivalDetails?.title}`,
              label: carnivalDetails?.title,
            });
          }
          setIsSubscribed(!isSubscribed);
          setIsSubscribing(false);
        } else {
          setIsSubscribing(false);
        }
      });
  };

  function updateCommentPageNumber() {
    if (commentsLastPage != null && commentsPage <= commentsLastPage) {
      setMoreCommentsIsLoading(true);
      setCommentsPage(commentsPage + 1);
    }
  }

  useEffect(() => {
    if (url) {
      handleGetComments();
    }
  }, [commentsPage, url]);

  useEffect(() => {
    if (url) {
      handleGetCarnivalMusic();
    }
  }, [musicPageNumber, url]);

  useEffect(() => {
    if (url) {
      handleCarnivalEvents();
    }
  }, [eventsPageNumber, url]);

  useEffect(() => {
    if (url) {
      handleCarnivalGalleries();
    }
  }, [galleriesPage, url]);

  useEffect(() => {
    if (url) {
      handleCarnivalVideos();
    }
  }, [videosPage, url]);

  function updateMusicPageNumber() {
    if (musicLastPage != null && musicPageNumber <= musicLastPage) {
      setMoreMusicIsLoading(true);
      setMusicPageNumber(musicPageNumber + 1);
    }
  }

  function updateEventsPageNumber() {
    if (eventsLastPage != null && eventsPageNumber <= eventsLastPage) {
      setMoreEventsIsLoading(true);
      setEventsPageNumber(eventsPageNumber + 1);
    }
  }

  function updateGalleriesPageNumber() {
    if (galleriesLastPage != null && galleriesPage <= galleriesLastPage) {
      setMoreGalleriesIsLoading(true);
      setGalleriesPage(galleriesPage + 1);
      handleCarnivalGalleries();
    }
  }

  function updateVideosPageNumber() {
    if (videosLastPage != null && videosPage <= videosLastPage) {
      setMoreVideosIsLoading(true);
      setVideosPage(videosPage + 1);
      handleCarnivalVideos();
    }
  }

  function updateReviewsPageNumber() {
    if (reviewsLastPage != null && reviewsPage <= reviewsLastPage) {
      setMoreReviewsIsLoading(true);
      setReviewsPage(reviewsPage + 1);
    }
  }

  useEffect(() => {
    if (url) {
      handleGetReviews();
    }
  }, [reviewsPage, url]);

  const handleGetReviews = async () => {
    try {
      const res = await axios.get(url + `/reviews`, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: reviewsPage,
          items: PER_PAGE_COUNT.REVIEWS_PER_PAGE,
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          if (res?.data?.data) {
            if (reviewsPage === 1) {
              setReviews(res?.data?.data);
            } else {
              setReviews([...reviews, ...res?.data?.data]);
            }
          }
          setReviewsLastPage(res?.data?.last_page);
        }
        setReviewsIsLoading(false);
        if (moreReviewsIsLoading) {
          setMoreReviewsIsLoading(false);
        }
      }
    } catch (err) {
      if (moreReviewsIsLoading) {
        setMoreReviewsIsLoading(false);
      }
      setReviewsIsLoading(false);
    }
  };

  const handleLike = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.GET_CARNIVAL_DETAILS}/${id}/like`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Comments",
          action: `Liked a comment`,
          label: carnivalDetails?.title,
        });
        ReactGA.event({
          category: "Comments",
          action: `Liked a comment on ${carnivalDetails?.title}`,
          label: carnivalDetails?.title,
        });
      }
    } catch (err) {}
  };

  const handleGetRatings = async () => {
    try {
      const res = await axios.get(url + "/ratings", {
        headers: {
          Accept: "application/json",
        },
      });
      if (res.status === 200) {
        setRatings(convertData(res?.data));
      }
    } catch (err) {
      setCommentsIsLoading(false);
    }
  };

  const handleGetComments = async () => {
    try {
      const res = await axios.get(url + "/comments", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
        params: {
          page: commentsPage,
          items: PER_PAGE_COUNT.COMMENTS_PER_PAGE,
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          if (res?.data?.data) {
            if (commentsPage === 1) {
              setComments(res.data.data);
            } else {
              setComments([...comments, ...res.data.data]);
            }
          }
          setCommentsLastPage(res.data.last_page);
        }
        if (isDeletingComment) {
          setIsDeletingComment(false);
        }
        if (moreCommentsIsLoading) {
          setMoreCommentsIsLoading(false);
        }
        setCommentsIsLoading(false);
      }
    } catch (err) {
      setCommentsIsLoading(false);
      if (moreCommentsIsLoading) {
        setMoreCommentsIsLoading(false);
      }
    }
  };

  const handleSubmitRatings = (value) => {
    const body = {
      affordability_score: value.Affordability,
      costumes_score: value.Costumes,
      events_score: value.Events,
      food_score: value.Food,
      music_score: value.Music,
      safety_score: value.Safety,
      culture_score: value.Culture,
    };
    axios
      .post(url + `/submit-rating`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            title: "Hurray...",
            text: "Review Submitted Successfully!",
            icon: "success",
            color: "#fff",
            background: "#000",
            timer: 3000,
            showConfirmButton: false,
          });
          handleGetRatings();
          ReactGA.event({
            category: "Rating's Submitted",
            action: `Submit your ratings button clicked`,
            label: carnivalDetails?.title,
          });
          ReactGA.event({
            category: "Rating's Submitted",
            action: `Submit your ratings button clicked for ${carnivalDetails?.title}`,
            label: carnivalDetails?.title,
          });
        } else {
          Swal.fire({
            title: "Sorry...",
            text: "Something unforseen has happpened!",
            icon: "warning ",
            color: "#fff",
            background: "#000",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      });
  };

  const handlePostComment = (value) => {
    const body = {
      comment: value,
      ...(id && { parent_comment_id: id }),
    };
    axios
      .post(url + `/comment`, body, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (id) {
          ReactGA.event({
            category: "Comments",
            action: `Reply on a comment`,
            label: carnivalDetails?.title,
          });
          ReactGA.event({
            category: "Comments",
            action: `Reply on a comment on ${carnivalDetails?.title}`,
            label: carnivalDetails?.title,
          });
        } else {
          ReactGA.event({
            category: "Comments",
            action: `New Comment Posted`,
            label: carnivalDetails?.title,
          });
          ReactGA.event({
            category: "Comments",
            action: `New Comment Posted on ${carnivalDetails?.title}`,
            label: carnivalDetails?.title,
          });
        }
        setId(null);
        if (commentsPage === 1) {
          handleGetComments();
        } else {
          setCommentsPage(1);
        }
      });
  };

  const handleDeleteComment = (value) => {
    setIsDeletingComment(true);
    axios
      .get(`${SERVER_URL.DELETE_COMMENT}${value}/delete`, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        ReactGA.event({
          category: "Comments",
          action: `Comment Deleted`,
          label: carnivalDetails?.title,
        });
        ReactGA.event({
          category: "Comments",
          action: `Comment Deleted on ${carnivalDetails?.title}`,
          label: carnivalDetails?.title,
        });
        if (commentsPage === 1) {
          handleGetComments();
        } else {
          setCommentsPage(1);
        }
      });
  };

  function isDatePast(date1String) {
    const date1 = new Date(date1String);
    const date2 = new Date();

    if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
      return "Invalid date format";
    }

    if (date1 <= date2) {
      return true;
    } else {
      return 0;
    }
  }

  const isPastCarnival = isDatePast(carnivalDetails?.end_datetime);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCountry = (item) => {
    const route = `/carnivals?region=${item}`;
    navigate(route);
    window.scrollTo(0, 0);
  };

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  // The setPageTitle is being used to update the title in the breadcrumbs.
  useEffect(() => {
    if (carnivalDetails) {
      setPageTitle(carnivalDetails.title);
    } else {
      setPageTitle("Carnival Details");
    }
  }, [carnivalDetails]);

  function openLink(item, platform) {
    const getLink = `${item}`;
    const aTag = document.createElement("a");
    aTag.setAttribute("href", getLink);
    aTag.setAttribute("target", "_blank");
    aTag.click();
    ReactGA.event({
      category: "Open",
      action: `Carnival's ${platform} opened`,
      label: carnivalDetails?.title,
    });
  }

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Carnival Details's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `Carnival Details's Shared on ${item}`,
      label: label,
    });
  }

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={carnivalDetails?.title}
        url={window.location.href}
        type={"article"}
        image={carnivalDetails?.poster_url}
      />
      <div>
        {detailsIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 5 }}
            animation="wave"
            variant="rectangular"
            width={"30%"}
            height={30}
          />
        ) : (
          <Label size="lg" label={carnivalDetails?.title} />
        )}
        <Grid container rowGap={2} columnGap={3}>
          <Grid item xs={12} md={5}>
            {detailsIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 10 }}
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={400}
              />
            ) : (
              <img
                className={CarnivalCss.poster}
                src={carnivalDetails?.poster_url}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6.7}>
            {detailsIsLoading ? (
              <>
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginBottom: 2 }}
                  animation="wave"
                  variant="rectangular"
                  width={"30%"}
                  height={30}
                />
                <DetailsDescriptionLoader />
              </>
            ) : (
              <>
                <Label label={"About this carnival"} size="md" />
                <div
                  className={`${CarnivalCss.body} ck-content`}
                  dangerouslySetInnerHTML={{
                    __html: carnivalDetails?.description,
                  }}
                />

                <div className={CarnivalCss.showDate}>
                  <FaCalendarAlt className={CarnivalCss.calendarIcon} />
                  <div className={CarnivalCss.time}>
                    Start: {ExtractDate(carnivalDetails?.start_datetime)},{" "}
                    {TimeExtractor(carnivalDetails?.start_datetime)}
                  </div>
                  <div className={CarnivalCss.time}>|</div>
                  <div className={CarnivalCss.time}>
                    End: {ExtractDate(carnivalDetails?.end_datetime)},{" "}
                    {TimeExtractor(carnivalDetails?.end_datetime)}
                  </div>
                </div>
                <div className={CarnivalCss.chipsArray}>
                  {carnivalDetails?.tags?.map((item, index) => {
                    return <Chip label={item?.slug} key={index} />;
                  })}
                </div>
                <div className={CarnivalCss.mgt30}>
                  <Label label={"Contact This Carnival"} size="md" />
                </div>
                <div className={CarnivalCss.shareTooltip}>
                  <img
                    src={require("../../assets/images/facebook.png")}
                    className={CarnivalCss.imgSocial}
                    onClick={() => {
                      const facebookUrl = carnivalDetails?.facebook_url;
                      openLink(facebookUrl, "facebook");
                    }}
                  />
                  <img
                    src={require("../../assets/images/instagram.png")}
                    className={CarnivalCss.imgSocial}
                    onClick={() => {
                      const instagramUrl = carnivalDetails?.instagram_url;
                      openLink(instagramUrl, "instagram");
                    }}
                  />
                  <img
                    src={require("../../assets/images/twitterWhite.png")}
                    className={CarnivalCss.imgSocial}
                    onClick={() => {
                      const twitterUrl = carnivalDetails?.twitter_url;
                      openLink(twitterUrl, "twitter");
                    }}
                  />
                  <img
                    src={require("../../assets/images/website.png")}
                    className={CarnivalCss.imgSocial}
                    onClick={() => {
                      const websiteUrl = carnivalDetails?.website_url;
                      openLink(websiteUrl, "website");
                    }}
                  />
                  <SharingTooltip
                    title={carnivalDetails?.title}
                    link={window.location.href.split("?")[0]}
                    onShare={handleShare}
                  />
                  <Button
                    size={"medium"}
                    styles={{ width: width < 834 ? "100%" : "20%" }}
                    label={isSubscribed ? "Unsubscribe" : "Subscribe"}
                    onClick={() => {
                      isLoggedIn ? handleSubscribe() : handleLogin();
                    }}
                    loading={isSubscribing}
                    loadingLabel={"Subscribing"}
                  />
                </div>
              </>
            )}
          </Grid>
        </Grid>
        <CarnivalTabs
          value={value}
          handleChange={handleChange}
          isPastCarnival={isPastCarnival}
          tjjRating={((carnivalDetails?.tjj_rating ?? 0) / 5) * 100}
          userRating={((carnivalDetails?.user_rating ?? 0) / 5) * 100}
          setId={setId}
          handleSubmitRatings={handleSubmitRatings}
          reviews={reviews}
          reviewsPage={reviewsPage}
          reviewsLastPage={reviewsLastPage}
          updateCommentPageNumber={updateCommentPageNumber}
          updateReviewsPageNumber={updateReviewsPageNumber}
          ratings={ratings}
          handleLike={handleLike}
          handlePostComment={handlePostComment}
          comments={comments}
          commentsLastPage={commentsLastPage}
          commentsPage={commentsPage}
          foodNDrink={foodNDrink}
          generalInformation={generalInformation}
          tjjRecommendations={tjjRecommendations}
          commentsIsLoading={commentsIsLoading}
          reviewsIsLoading={reviewsIsLoading}
          moreReviewsIsLoading={moreReviewsIsLoading}
          moreCommentsIsLoading={moreCommentsIsLoading}
          handleDeleteComment={handleDeleteComment}
          isDeleting={isDeletingComment}
        />

        {!isPastCarnival && (
          <>
            {events?.length > 0 && (
              <Label label={"Upcoming Events"} size="md" />
            )}
            {eventIsLoading ? (
              <div className={CarnivalCss.mgb}>
                <div className={CarnivalCss.flexDrR}>
                  {Array.from({ length: 4 }, (_, index) => {
                    return (
                      <div key={index} style={{ minWidth: "49%" }}>
                        <EventLongCardLoader />;
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              events?.length > 0 && (
                <>
                  <EventList events={events} />
                  {moreEventsIsLoading && (
                    <div className={CarnivalCss.mgb}>
                      <div className={CarnivalCss.flexDrR}>
                        {Array.from({ length: 4 }, (_, index) => {
                          return (
                            <div key={index} style={{ minWidth: "49%" }}>
                              <EventLongCardLoader />;
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {eventsPageNumber < eventsLastPage &&
                  eventsLastPage &&
                  !eventIsLoading &&
                  !moreEventsIsLoading ? (
                    <ViewMore
                      loadMore
                      onClick={() => updateEventsPageNumber()}
                    />
                  ) : (
                    <div className={CarnivalCss.mgb} />
                  )}
                </>
              )
            )}
          </>
        )}

        {galleries?.length > 0 && (
          <Label
            label={isPastCarnival ? "Galleries" : "Related Carnival Galleries"}
            size="md"
          />
        )}
        {galleriesIsLoading ? (
          <div className={CarnivalCss.mgb}>
            <div className={CarnivalCss.flexDrR}>
              {Array.from({ length: 8 }, (_, index) => {
                return <GalleryCardLoader key={index} />;
              })}
            </div>
          </div>
        ) : (
          galleries?.length > 0 && (
            <>
              <GalleryList gallery={galleries} />
              {moreGalleriesIsLoading && (
                <div className={CarnivalCss.mgb}>
                  <div className={CarnivalCss.flexDrR}>
                    {Array.from({ length: 8 }, (_, index) => {
                      return <GalleryCardLoader key={index} />;
                    })}
                  </div>
                </div>
              )}
              {galleriesPage < galleriesLastPage &&
              galleriesLastPage &&
              !galleriesIsLoading &&
              !moreGalleriesIsLoading ? (
                <ViewMore
                  loadMore
                  onClick={() => updateGalleriesPageNumber()}
                />
              ) : (
                <div className={CarnivalCss.mgb} />
              )}
            </>
          )
        )}

        {videosData?.length > 0 && (
          <Label
            label={isPastCarnival ? "Videos" : "Related Carnival Videos"}
            size="md"
          />
        )}
        {videosIsLoading ? (
          <div className={CarnivalCss.mgb}>
            <div className={CarnivalCss.flexDrR}>
              {Array.from({ length: 6 }, (_, index) => {
                return <VideoCardLoader key={index} />;
              })}
            </div>
          </div>
        ) : (
          videosData?.length > 0 && (
            <>
              <VideoList videos={videosData} />
              {moreVideosIsLoading && (
                <div className={CarnivalCss.mgb}>
                  <div className={CarnivalCss.flexDrR}>
                    {Array.from({ length: 6 }, (_, index) => {
                      return <VideoCardLoader key={index} />;
                    })}
                  </div>
                </div>
              )}
              {videosPage < videosLastPage &&
              videosLastPage &&
              !videosIsLoading &&
              !moreVideosIsLoading ? (
                <ViewMore loadMore onClick={() => updateVideosPageNumber()} />
              ) : (
                <div className={CarnivalCss.mgb} />
              )}
            </>
          )
        )}

        {music?.length > 0 && (
          <Label
            label={isPastCarnival ? "Music" : "Related Carnival Music"}
            size="md"
          />
        )}

        {musicIsLoading ? (
          <div className={CarnivalCss.mgb}>
            <div className={CarnivalCss.albumsDiv}>
              {Array.from({ length: 8 }, (_, index) => {
                return (
                  <div key={index} className={CarnivalCss.albumsSubDiv}>
                    <MusicCardLoader />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          music?.length > 0 && (
            <>
              <AlbumsList albums={music} />
              {moreMusicIsLoading && (
                <div className={CarnivalCss.mgb}>
                  <div className={CarnivalCss.albumsDiv}>
                    {Array.from({ length: 8 }, (_, index) => {
                      return (
                        <div key={index} className={CarnivalCss.albumsSubDiv}>
                          <MusicCardLoader />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {musicPageNumber < musicLastPage &&
              musicLastPage &&
              !musicIsLoading &&
              !moreMusicIsLoading ? (
                <ViewMore
                  loadMore
                  onClick={() => {
                    updateMusicPageNumber();
                  }}
                />
              ) : (
                <div className={CarnivalCss.mgb} />
              )}
            </>
          )
        )}

        {!isPastCarnival && (
          <>
            {recentCarnival?.length > 0 && (
              <Label label={"Re-Live Recent Carnival Experiences"} size="md" />
            )}
            {recentIsLoading ? (
              <Grid
                className={CarnivalCss.mgb}
                container
                rowSpacing={4}
                columnSpacing={2}
              >
                {Array.from({ length: 6 }, (_, index) => {
                  return (
                    <Grid key={index} item xs={12} md={4}>
                      <ReLiveCardLoader />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              recentCarnival?.length > 0 && (
                <>
                  <Grid container rowSpacing={4} columnSpacing={2}>
                    {recentCarnival?.map((item, index) => {
                      return (
                        <Grid key={index} item xs={12} md={4}>
                          <Card
                            title={item?.title}
                            imageSrc={item?.poster_url}
                            textAlign={"center"}
                            onClick={() =>
                              handleClick(`/carnival/${item?.slug}`)
                            }
                            size={"md"}
                            showLinearFillShadow={true}
                          />
                        </Grid>
                      );
                    })}
                    {moreRecentsIsLoading && (
                      <Grid
                        className={CarnivalCss.mgb}
                        container
                        rowSpacing={4}
                        columnSpacing={2}
                        style={{ marginTop: 2 }}
                      >
                        {Array.from({ length: 6 }, (_, index) => {
                          return (
                            <Grid key={index} item xs={12} md={4}>
                              <ReLiveCardLoader />
                            </Grid>
                          );
                        })}
                      </Grid>
                    )}
                  </Grid>
                  {recentsLastPage &&
                  recentsPageNumber < recentsLastPage &&
                  !recentIsLoading &&
                  !moreRecentsIsLoading &&
                  recentCarnival?.length !== 0 ? (
                    <ViewMore
                      loadMore={true}
                      onClick={() => loadMoreRecentCarnivals()}
                    />
                  ) : (
                    <div className={CarnivalCss.mgb} />
                  )}
                </>
              )
            )}
          </>
        )}

        {upcomingCarnivals?.length > 0 && (
          <Label label={"Upcoming Carnivals"} size="md" />
        )}
        {upcomingIsLoading ? (
          <Grid
            className={CarnivalCss.mgb}
            container
            rowSpacing={4}
            columnSpacing={2}
          >
            {Array.from({ length: 4 }, (_, index) => (
              <Grid key={index} item xs={12} md={6}>
                <UpcomingCarnivalCardLoader />
              </Grid>
            ))}
          </Grid>
        ) : (
          upcomingCarnivals?.length > 0 && (
            <>
              <Grid
                className={CarnivalCss.mgb}
                container
                rowSpacing={4}
                columnSpacing={2}
              >
                {upcomingCarnivals?.map((item, index) => {
                  const startDate = ExtractShortMonthDate(item.start_datetime);
                  const endDate = ExtractShortMonthDate(item.end_datetime);
                  return (
                    <Grid key={index} item xs={12} md={6}>
                      <Card
                        title={item.title}
                        imageSrc={item.poster_url}
                        content={
                          <div className={CarnivalCss.subTextCard}>
                            {startDate} to {endDate}
                          </div>
                        }
                        onClick={() => handleClick(`/carnival/${item.slug}`)}
                        showLinearFillShadow={true}
                        textAlign={"center"}
                        size={"lg"}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )
        )}
        <CountryFooter
          by={"Carnivals"}
          handleClick={(item) => handleCountry(item)}
        />
      </div>
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state?.auth?.isLoggedIn,
  user: state?.auth?.user,
});
export default connect(mapStateToProps)(CarnivalDetail);

function Events(data) {
  return (
    <div className={CarnivalCss.divMain}>
      {data?.map((item, index) => {
        return (
          <div
            key={item.id}
            className={index === data?.length - 1 ? undefined : CarnivalCss.mgb}
          >
            <div className={CarnivalCss.eventTag}>{item?.timestamp}</div>
            <EventList events={item?.events} />
          </div>
        );
      })}
    </div>
  );
}

function convertData(data) {
  const aspects = [
    "Affordability",
    "Costumes",
    "Events",
    "Food",
    "Music",
    "Safety",
    "Culture",
  ];

  const convertedData = aspects.map((aspect, index) => ({
    name: aspect,
    tjjRating:
      data?.tjj_rating &&
      Object.keys(data?.tjj_rating).length > 0 &&
      data?.tjj_rating[`${aspect.toLowerCase()}_score`]
        ? data?.tjj_rating[`${aspect.toLowerCase()}_score`]
        : 0,
    userRating:
      data?.user_rating &&
      Object.keys(data?.user_rating).length > 0 &&
      data?.user_rating[`${aspect.toLowerCase()}_score`]
        ? data?.user_rating[`${aspect.toLowerCase()}_score`]
        : 0,
  }));
  return convertedData;
}
