import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import ReviewCss from "./Review.module.css";
import { Skeleton, Grid } from "@mui/material";
import FullWidthReviewsList from "../../elements/Flatlist/FullWidthReviewsList";
import ReviewFilter from "./elements/ReviewFilter";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import ReviewSlides from "./elements/ReviewSlides";
import { Context } from "../../Context";
import { useNavigate, useSearchParams } from "react-router-dom";
import ViewMore from "../../elements/view-more/ViewMore";
import CategoriesList from "../../elements/categories/CategoriesList";
import TagsList from "../../elements/Flatlist/TagsList";
import MetaTags from "../../MetaTags";
import NoData from "../../elements/no-data/NoData";
import TextField from "../../components/textfield/TextField";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import NewsLongCardLoader from "../../elements/skeletonLoaders/NewsLongCardLoader";
import PopularArticlesCardLoader from "../../elements/skeletonLoaders/PopularArticlesCardLoader";
import CategoriesLoader from "../../elements/skeletonLoaders/CategoriesLoader";
import AuthorLoader from "../../elements/skeletonLoaders/AuthorLoader";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { IoPersonCircleOutline } from "react-icons/io5";
import ReactGA from "react-ga4";
import DynamicContentInjector from "../../elements/DynamicContentInjector/DynamicContentInjector.js";

const options = [
  {
    label: "Date",
    value: "created_at",
  },
  {
    label: "Views",
    value: "view_count",
  },
  {
    label: "Title",
    value: "title",
  },
];

export default function ReviewsHome() {
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const { setPageTitle } = useContext(Context);
  const [reviews, setReviews] = useState(null);
  const [popularReviews, setPopularReviews] = useState(null);
  const [categories, setCategories] = useState(null);
  const [params, setParams] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();
  const [optionalTitle, setOptionalTitle] = useState("");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [orderBy, setOrderBy] = useState("");
  const navigate = useNavigate();
  const [ReviewsPageNumber, setReviewsPageNumber] = useState(1);
  const [ReviewsLastPage, setReviewsLastPage] = useState(null);
  const [reviewsIsLoading, setReviewsIsLoading] = useState(true);
  const [popularReviewsIsLoading, setPopularReviewsIsLoading] = useState(true);
  const [moreReviewsIsLoading, setMoreReviewsIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [tag, setTag] = useState([]);
  const [allTag, setAllTag] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [popularAuthors, setPopularAuthors] = useState([]);
  const [allAuthors, setAllAuthors] = useState([]);
  const [tagsIsLoading, setTagsIsLoading] = useState(true);
  const [allTagsIsLoading, setAllTagsIsLoading] = useState(true);
  const [allCategoriesIsLoading, setAllCategoriesIsLoading] = useState(true);
  const [allAuthorsIsLoading, setAllAuthorsIsLoading] = useState(true);
  const [categoriesIsLoading, setCategoriesIsLoading] = useState(true);
  const [bannerAd, setBannerAd] = useState(null);
  const [triggerReset, setTriggerReset] = useState(false);
  const [status, setStatus] = useState(null);
  const [bannerAd2, setBannerAd2] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/reviews`,
      title: "Reviews Home",
    });
  }, []);

  const handleGetSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "sidebar ad square",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd(res.data);
        }
      }
    } catch (err) {
      setBannerAd(null);
    }
  };

  const handleGetSecondSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "skyscraper ad",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd2(res.data);
        }
      }
    } catch (err) {
      setBannerAd2(null);
    }
  };

  useEffect(() => {
    handleGetSidebarBanner();
    handleGetSecondSidebarBanner();
  }, []);

  const handleOrder = (item) => {
    handleSearchParam("orderby", item);
    const order = item === "asc" ? "ascending" : "descending";
    ReactGA.event({
      category: "Order",
      action: `Order by ${order}`,
      label: `Order by ${order} on all reviews page`,
    });
    ReactGA.event({
      category: "Order",
      action: `Order by ${order} on all reviews page`,
      label: `Ordered the results by ${order} on all reviews page`,
    });
    if (item === "asc") {
      setOrderDirection("asc");
    } else {
      setOrderDirection("desc");
    }
  };

  function sortBy() {
    return (
      <div className={ReviewCss.sortDiv}>
        <div className={ReviewCss.sortBy}>
          <TextField
            defaultValue={orderBy}
            type={"select"}
            label={"Sort by"}
            data={options}
            onChange={(item) => handleSortby(item)}
          />
        </div>
        <FaSortAmountUp
          onClick={() => handleOrder("asc")}
          className={
            orderDirection === "asc"
              ? ReviewCss.ascDescIcon1
              : ReviewCss.ascDescIcon
          }
        />
        <>
          <FaSortAmountDownAlt
            onClick={() => handleOrder("desc")}
            className={
              orderDirection === "desc"
                ? ReviewCss.ascDescIcon1
                : ReviewCss.ascDescIcon
            }
          />
        </>
      </div>
    );
  }
  function updateReviewsPageNumber() {
    if (ReviewsLastPage != null && ReviewsPageNumber <= ReviewsLastPage) {
      setMoreReviewsIsLoading(true);
      setReviewsPageNumber(ReviewsPageNumber + 1);
    }
  }
  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  useEffect(() => {
    if (searchParams.size !== 0 && searchParams.get("sortby")) {
      handleSortby(searchParams.get("sortby"));
    } else {
      setOrderBy("created_at");
    }

    if (searchParams.size !== 0 && searchParams.get("orderby")) {
      handleOrder(searchParams.get("orderby"));
    } else {
      setOrderDirection("desc");
    }
  }, [searchParams]);

  const handleSortby = (item) => {
    handleSearchParam("sortby", item);
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results`,
      label: `Sort by ${item} on all reviews page`,
    });
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results on all reviews page`,
      label: `Sorted the results by ${item} on all reviews page`,
    });
    setOrderBy(item);
  };

  useEffect(() => {
    if (searchParams.size !== 0) {
      if (searchParams.size === 1) {
        if (searchParams?.get("tag") !== null) {
          const tag = allTag?.filter(
            (i) => i.slug === searchParams?.get("tag")
          )[0]?.title;
          if (tag) {
            setOptionalTitle(`By Tag: ${tag}`);
          }
        }
        if (searchParams?.get("author") !== null) {
          const author = allAuthors?.filter(
            (i) => i.slug === searchParams?.get("author")
          )[0]?.first_name;
          if (author) {
            setOptionalTitle(`By Author: ${author}`);
          }
        }
        if (searchParams?.get("category") !== null) {
          const category = allCategories?.filter(
            (i) => i.slug === searchParams?.get("category")
          )[0]?.title;
          if (category) {
            setOptionalTitle(`By Category: ${category}`);
          }
        } else {
          setOptionalTitle("");
        }
      } else {
        setOptionalTitle("Filtered Results");
      }
    } else {
      setOptionalTitle("");
    }
  }, [searchParams, allCategories, allAuthors, allTag]);

  useEffect(() => {
    if (ReviewsPageNumber !== 1) {
      handleGetData();
    }
  }, [ReviewsPageNumber]);

  useEffect(() => {
    setPageTitle("Reviews");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (searchParams.size !== 0) {
      let params = {};
      if (searchParams?.getAll("tag")?.length !== 0) {
        params = {
          ...params,
          tag: searchParams.getAll("tag"),
        };
      }
      if (searchParams?.getAll("author")?.length !== 0) {
        params = {
          ...params,
          author: searchParams.getAll("author"),
        };
      }
      if (searchParams?.getAll("category")?.length !== 0) {
        params = {
          ...params,
          category: searchParams.getAll("category"),
        };
      }
      if (searchParams?.get("reviewType")) {
        params = {
          ...params,
          reviewType: searchParams.get("reviewType"),
        };
      }
      if (searchParams?.get("startDate")) {
        params = {
          ...params,
          startDate: searchParams.get("startDate"),
        };
      }
      if (searchParams?.get("endDate")) {
        params = {
          ...params,
          endDate: searchParams.get("endDate"),
        };
      }

      if (
        params &&
        Object.keys(params).length > 0 &&
        Object.keys(filters).length === 0
      ) {
        handleSearch(params);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (width < 900) {
      setShowFilter(false);
    }
  }, [width]);

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  const handleGetData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_REVIEWS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: ReviewsPageNumber,
          items: PER_PAGE_COUNT.REVIEWS_ITEM_COUNT,
          orderBy: orderBy,
          orderDirection: orderDirection,
          ...(filters?.reviewType && {
            type: filters?.reviewType,
          }),
          ...(filters?.tag && {
            tags: filters?.tag,
          }),
          ...(filters?.category && {
            categories: filters?.category,
          }),
          ...(filters?.author && {
            authors: filters?.author,
          }),
          ...(filters?.startDate && {
            startDate: filters?.startDate,
          }),
          ...(filters?.endDate && {
            endDate: filters?.endDate,
          }),
        },
      });
      if (res.status === 200) {
        setStatus(res.status);
        if (res?.data && res?.data?.reviews) {
          setReviewsLastPage(res?.data?.reviews?.last_page);
          if (ReviewsPageNumber === 1) {
            setReviews(res?.data?.reviews?.data);
          } else {
            setReviews((prev) => [...prev, ...res?.data?.reviews?.data]);
          }
        }
        setReviewsIsLoading(false);
        if (moreReviewsIsLoading) {
          setMoreReviewsIsLoading(false);
        }
      }
    } catch (err) {
      setReviewsIsLoading(false);
      if (moreReviewsIsLoading) {
        setMoreReviewsIsLoading(false);
      }
    }
  };

  const handleGetPopular = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_REVIEWS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.REVIEWS_ITEM_COUNT,
          orderBy: "view_count",
          orderDirection: orderDirection,
        },
      });
      setPopularReviews(res?.data?.reviews?.data);
      setPopularReviewsIsLoading(false);
    } catch (err) {
      setPopularReviewsIsLoading(false);
    }
  };

  const getAllCategories = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CATEGORIES_REVIEWS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.review_categories?.data?.length !== 0) {
          setAllCategories(res?.data?.review_categories?.data);
        }
        setAllCategoriesIsLoading(false);
      }
    } catch (err) {
      setAllCategoriesIsLoading(false);
    }
  };

  const getCategories = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CATEGORIES_REVIEWS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
        },
      });
      if (res.status === 200) {
        if (res?.data?.review_categories?.data?.length !== 0) {
          setCategories(res?.data?.review_categories?.data);
        }
        setCategoriesIsLoading(false);
      }
    } catch (err) {
      setCategoriesIsLoading(false);
    }
  };

  useEffect(() => {
    setReviewsPageNumber(1);
    setReviewsIsLoading(true);
    setReviews([]);
    if (orderBy && orderDirection) {
      if (searchParams.size === 1 && searchParams.has("sortby")) {
        handleGetData();
      } else if (searchParams.size === 1 && searchParams.has("orderby")) {
        handleGetData();
      } else if (
        searchParams.size === 2 &&
        searchParams.has("orderby") &&
        searchParams.has("sortby")
      ) {
        handleGetData();
      } else if (searchParams.size !== 0) {
        if (Object.keys(filters)?.length !== 0) {
          handleGetData();
        }
      } else {
        handleGetData();
      }
    }
  }, [orderBy, orderDirection, filters]);

  useEffect(() => {
    handleGetPopular();
    getAllCategories();
    getAllTags();
    getTags();
    getCategories();
    getAllAuthor();
    getAuthor();
  }, []);

  const handleResetAll = () => {
    setFilters(null);
    const filter = [
      "tag",
      "author",
      "reviewType",
      "category",
      "startDate",
      "endDate",
    ];
    filter.map((item) => {
      if (searchParams.has(item)) {
        searchParams.delete(item);
        setSearchParams(searchParams);
        delete params[item];
      }
    });
  };

  const handleSearchParam = (key, value) => {
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSearch = (item) => {
    setFilters(item);
    ReactGA.event({
      category: "Filter",
      action: "Results filtered",
      label: "Results filtered on all reviews page",
    });
    ReactGA.event({
      category: "Filter",
      action: "Results filtered on all reviews page",
      label: "Results filtered on all reviews page",
    });
    Object.entries(item)?.map((i) => {
      handleSearchParam(i[0], i[1]);
    });
  };

  const handleShowDd = () => {
    setShowFilter(!showFilter);
  };

  const handleNavigate = (param) => {
    setTriggerReset(true);
    let params = {};
    if (param?.category) {
      params = {
        ...params,
        category: [param?.category],
      };
    }
    if (param?.author) {
      params = {
        ...params,
        author: [param?.author],
      };
    }
    if (param?.tag) {
      params = {
        ...params,
        tag: [param?.tag],
      };
    }
    setTimeout(() => {
      handleSearch(params);
      window.scrollTo(0, 0);
    }, 1000);
  };

  const getTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
          type: "review",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setTag(res?.data?.data);
        }
        setTagsIsLoading(false);
      }
    } catch (err) {
      setTagsIsLoading(false);
    }
  };

  const getAllTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
          type: "review",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setAllTag(res?.data?.data);
        }
        setAllTagsIsLoading(false);
      }
    } catch (err) {
      setAllTagsIsLoading(false);
    }
  };

  const getAuthor = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_AUTHORS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
          type: "review",
        },
      });
      if (res.status === 200) {
        if (res?.data?.authors?.data?.length !== 0) {
          setPopularAuthors(res?.data?.authors?.data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getAllAuthor = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_AUTHORS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
          type: "review",
        },
      });
      if (res.status === 200) {
        if (res?.data?.authors?.data?.length !== 0) {
          setAllAuthors(res?.data?.authors?.data);
        }
        setAllAuthorsIsLoading(false);
      }
    } catch (err) {
      setAllAuthorsIsLoading(false);
    }
  };

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"Reviews"}
        url={window.location.href}
        type={"website"}
        image={"../../assets/images/tjj-logo.jpg"}
      />
      <div className={ReviewCss.mainDiv}>
        <div className={ReviewCss.fullWidthContainerFd}>
          <div className={ReviewCss.firstContainer}>
            <div className={ReviewCss.flexDr}>
              <div className={ReviewCss.head}>
                <Label
                  size="lg"
                  label={
                    optionalTitle !== ""
                      ? "All Reviews " + optionalTitle
                      : "All Reviews"
                  }
                />
                {width > 899 && sortBy()}
              </div>
              <div className={ReviewCss.iconsSm}>
                <div className={ReviewCss.iconsSmDiv}>
                  <img
                    src={require("../../assets/images/filter.png")}
                    className={ReviewCss.filterSm}
                    onClick={() => handleShowDd()}
                  />
                  {showFilter ? (
                    <FaCaretUp className={ReviewCss.upDown} />
                  ) : (
                    <FaCaretDown className={ReviewCss.upDown} />
                  )}
                </div>
              </div>
            </div>
            {width < 900 && sortBy()}
            {bannerAd &&
              (bannerAd?.banner_image_url ? (
                <div className={ReviewCss.smBanner}>
                  <img
                    className={ReviewCss.BannerAds}
                    src={bannerAd?.banner_image_url}
                  />
                </div>
              ) : (
                bannerAd?.content && (
                  <div className={ReviewCss.smBanner}>
                    <DynamicContentInjector
                      className={ReviewCss.BannerAd}
                      htmlContent={bannerAd?.content}
                    />
                  </div>
                )
              ))}
            {bannerAd2 &&
              (bannerAd2?.banner_image_url ? (
                <div className={ReviewCss.smBanner}>
                  <img
                    className={ReviewCss.BannerAds}
                    src={bannerAd2?.banner_image_url}
                  />
                </div>
              ) : (
                bannerAd2?.content && (
                  <div className={ReviewCss.smBanner}>
                    <DynamicContentInjector
                      className={ReviewCss.BannerAd}
                      htmlContent={bannerAd2?.content}
                    />
                  </div>
                )
              ))}
            {showFilter &&
              (allTagsIsLoading ||
              allCategoriesIsLoading ||
              allAuthorsIsLoading ? (
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginTop: 2 }}
                  animation="wave"
                  variant="rounded"
                  width={"100%"}
                  height={440}
                />
              ) : (
                <ReviewFilter
                  authors={allAuthors}
                  categoryArray={allCategories}
                  tagArray={allTag}
                  onReset={handleResetAll}
                  filter={filters}
                  onSearch={(item) => handleSearch(item)}
                  triggerReset={triggerReset}
                  setTriggerReset={setTriggerReset}
                />
              ))}
            {reviewsIsLoading ? (
              <>
                {Array.from({ length: 6 }, (_, index) => {
                  return <NewsLongCardLoader key={index} />;
                })}
              </>
            ) : (
              <>
                {reviews && reviews?.length !== 0 ? (
                  <>
                    <FullWidthReviewsList reviews={reviews} />
                    {moreReviewsIsLoading && (
                      <>
                        {Array.from({ length: 6 }, (_, index) => {
                          return <NewsLongCardLoader key={index} />;
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <NoData errorCode={status} />
                )}
              </>
            )}
            {reviews &&
              reviews?.length !== 0 &&
              ReviewsLastPage !== null &&
              ReviewsPageNumber < ReviewsLastPage &&
              !reviewsIsLoading &&
              !moreReviewsIsLoading && (
                <ViewMore loadMore onClick={updateReviewsPageNumber} />
              )}
          </div>
          <div className={ReviewCss.secondContainer}>
            {allTagsIsLoading ||
            allCategoriesIsLoading ||
            allAuthorsIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginTop: 2 }}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={440}
              />
            ) : (
              <ReviewFilter
                authors={allAuthors}
                categoryArray={allCategories}
                tagArray={allTag}
                onReset={handleResetAll}
                filter={filters}
                onSearch={(item) => handleSearch(item)}
                triggerReset={triggerReset}
                setTriggerReset={setTriggerReset}
              />
            )}
            {bannerAd &&
              (bannerAd?.banner_image_url ? (
                <img
                  className={ReviewCss.BannerAds}
                  src={bannerAd?.banner_image_url}
                />
              ) : (
                bannerAd?.content && (
                  <DynamicContentInjector
                    className={ReviewCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                )
              ))}
            {bannerAd2 &&
              (bannerAd2?.banner_image_url ? (
                <img
                  className={ReviewCss.BannerAds}
                  src={bannerAd2?.banner_image_url}
                />
              ) : (
                bannerAd2?.content && (
                  <DynamicContentInjector
                    className={ReviewCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                )
              ))}
          </div>
        </div>
      </div>
      {popularReviewsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={width > 431 ? "30%" : "40%"}
          height={50}
        />
      ) : (
        popularReviews &&
        popularReviews?.length !== 0 && (
          <Label size="md" label={"Popular Reviews"} style={{ marginTop: 4 }} />
        )
      )}
      {popularReviewsIsLoading ? (
        <div className={ReviewCss.flexDrR}>
          <PopularArticlesCardLoader />
          <PopularArticlesCardLoader />
          <PopularArticlesCardLoader />
          <PopularArticlesCardLoader />
        </div>
      ) : (
        popularReviews &&
        popularReviews?.length !== 0 && (
          <ReviewSlides reviews={popularReviews} />
        )
      )}
      <div className={ReviewCss.mgb20} />
      {categoriesIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={width > 431 ? "30%" : "40%"}
          height={50}
        />
      ) : (
        categories &&
        categories?.length !== 0 && (
          <div className={ReviewCss.redHead}>Popular Categories</div>
        )
      )}
      <Grid className={ReviewCss.topBox} container columnGap={3}>
        {categoriesIsLoading ? (
          <CategoriesLoader />
        ) : (
          categories &&
          categories?.length !== 0 &&
          categories.map((item, index) => {
            return (
              <CategoriesList
                key={index}
                item={item}
                onClick={() => handleNavigate({ category: item?.slug })}
              />
            );
          })
        )}
      </Grid>
      {categoriesIsLoading ? (
        <div className={ReviewCss.mgb} />
      ) : (
        categories &&
        categories?.length !== 0 && (
          <ViewMore onClick={() => handleClick("/reviews/categories")} />
        )
      )}

      {tagsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={width > 431 ? "30%" : "40%"}
          height={50}
        />
      ) : (
        tag &&
        tag?.length !== 0 && (
          <div className={ReviewCss.redHead}>Popular Tags</div>
        )
      )}
      {tagsIsLoading ? (
        <Grid container columnGap={3}>
          {Array.from({ length: 50 }, (_, index) => {
            return (
              <Grid key={index} item xs={5.3} sm={3.3} md={2.2}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        tag &&
        tag?.length !== 0 && (
          <TagsList tag={tag} handleNavigate={handleNavigate} />
        )
      )}
      {tagsIsLoading ? (
        <div className={ReviewCss.mgb} />
      ) : (
        tag &&
        tag?.length !== 0 && (
          <ViewMore onClick={() => handleClick("/reviews/tags")} />
        )
      )}

      {isLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={width > 431 ? "30%" : "40%"}
          height={50}
        />
      ) : (
        popularAuthors &&
        popularAuthors?.length !== 0 && (
          <div className={ReviewCss.redHead}>Popular Authors</div>
        )
      )}
      <Grid className={ReviewCss.topBox} container columnGap={3}>
        {isLoading ? (
          <AuthorLoader />
        ) : (
          popularAuthors &&
          popularAuthors?.length !== 0 &&
          popularAuthors?.map((item, index) => {
            return (
              <Grid item key={index} xs={5.3} sm={3.3} md={2.2}>
                <div
                  onClick={() => handleNavigate({ author: item?.slug })}
                  key={index}
                  className={ReviewCss.flexDir}
                >
                  {item?.profile_photo_url === null ? (
                    <IoPersonCircleOutline className={ReviewCss.authorIcon} />
                  ) : (
                    <img
                      src={item?.profile_photo_url}
                      className={ReviewCss.authorImage}
                      alt="Author Image"
                    />
                  )}
                  <div className={ReviewCss.authorContentDiv}>
                    <div className={ReviewCss.authorTitle}>
                      {item?.first_name} {item?.last_name}
                    </div>
                    <div className={ReviewCss.authorReviews}>
                      {item?.reviews_count} reviews
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })
        )}
      </Grid>
      {isLoading ? (
        <div className={ReviewCss.mgb} />
      ) : (
        popularAuthors &&
        popularAuthors?.length !== 0 && (
          <ViewMore onClick={() => handleClick("/reviews/authors")} />
        )
      )}

      {/* <CountryFooter
        by={"Reviews"}
        handleClick={(item) => alert(`Location changed to: ${item}`)}
      /> */}
    </NavContainer>
  );
}
