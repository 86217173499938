import React from "react";
import Card from "../../components/card/Card";
import { CardSizes } from "../../config";
import FlatListCss from "./Flatlist.module.css";
import { useNavigate } from "react-router";

export default function ArtistList({ artists }) {
  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };
  return (
    <div className={FlatListCss.artistsDiv}>
      {artists.map((item, index) => {
        return (
          <div key={index} className={FlatListCss.artistsSubDiv}>
            <Card
              title={item.first_name + " " + item.last_name}
              imageSrc={
                item?.profile_photo_url
                  ? item?.profile_photo_url
                  : `https://via.placeholder.com/640x480.png/0088aa?text=${item.first_name}%20${item.last_name}`
              }
              content={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                    fontFamily: "Inter-Medium",
                    fontWeight: "500",
                    color: "#C8C8C8",
                  }}
                ></div>
              }
              imageProps={{
                borderRadius: 150,
                height: window.innerWidth < 431 ? 170 : CardSizes.xs,
              }}
              onClick={() => handleClick(`/music/artists/${item.slug}`)}
              textAlign={"center"}
              size={"xs"}
            />
          </div>
        );
      })}
    </div>
  );
}
