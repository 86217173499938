import React, { useEffect, useRef } from "react";

function DynamicContentInjector({ htmlContent, className }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!htmlContent) return;

    // Clear existing content to avoid conflicts
    if (containerRef.current) {
      containerRef.current.innerHTML = ""; // Clear previous content
    }

    // Inject new HTML content
    containerRef.current.innerHTML = htmlContent;

    // Extract and inject external scripts
    const scriptTags = containerRef.current.getElementsByTagName("script");

    Array.from(scriptTags).forEach((scriptTag) => {
      const newScript = document.createElement("script");

      if (scriptTag.src) {
        // Handle external scripts
        newScript.src = scriptTag.src;
        newScript.async = true;
        newScript.crossOrigin = "anonymous"; // Ensure cross-origin handling
        document.body.appendChild(newScript);
      } else {
        // Handle inline scripts
        newScript.textContent = scriptTag.textContent;
        document.body.appendChild(newScript);
      }
    });

    // // Initialize only one Google ad
    // if (window.adsbygoogle) {
    //   const ads = containerRef.current.querySelectorAll(".adsbygoogle");

    //   if (ads.length > 0) {
    //     // Only process the first ad element
    //     const ad = ads[0];
    //     try {
    //       // Replace the old ad element with a new one
    //       const newAd = document.createElement("ins");
    //       newAd.className = "adsbygoogle";
    //       newAd.style.display = ad.style.display;
    //       newAd.style.width = ad.style.width;
    //       newAd.style.height = ad.style.height;
    //       newAd.setAttribute(
    //         "data-ad-client",
    //         ad.getAttribute("data-ad-client")
    //       );
    //       newAd.setAttribute("data-ad-slot", ad.getAttribute("data-ad-slot"));

    //       // Replace the old ad element
    //       ad.parentNode.replaceChild(newAd, ad);

    //       // Initialize the ad
    //       window.adsbygoogle.push({});
    //     } catch (e) {
    //       console.error("Error initializing adsbygoogle:", e);
    //     }
    //   }
    // }

    // Cleanup function to prevent memory leaks
    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = ""; // Clear HTML content on unmount
      }
    };
  }, [htmlContent]);

  return <div ref={containerRef} className={className} />;
}

export default DynamicContentInjector;
