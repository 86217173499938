import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectMusic, setMusic } from "../../slices/MusicReducer";
import NavbarCss from "../NavContainer/NavContainer.module.css";
import MiniMusicPlayer from "../../components/mini-music-player/MiniMusicPlayer";
import {
  clearQueue,
  moveItemToBottom,
  moveItemToTop,
  selectQueue,
  setQueue,
} from "../../actions";
import { deleteFromQueue } from "../../actions";
import Swal from "sweetalert2";
import axios from "axios";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import "./Layout.css";
import { selectPages, setPages } from "../../slices/PagesReducer.js";

export default function LayoutWrapper({ children }) {
  const music = useSelector(selectMusic)[0];
  const pages = useSelector(selectPages);
  const queue = useSelector(selectQueue)?.queue;
  const dispatch = useDispatch();
  const [playlist, setPlaylist] = useState([]);
  const [songId, setSongId] = useState(null);
  const user = useSelector((state) => state?.auth?.user);
  const [moreFromArtist, setMoreFromArtists] = useState([]);
  useEffect(() => {
    if (music?.musicArgs?.artist_id) {
      handleGetArtistsData(music?.musicArgs?.artist_id);
    }
  }, [music]);

  const handleGetPages = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_PAGES, {
        headers: {
          Accept: "application/json",
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data?.length !== 0) {
          const d = res?.data?.data;
          const page = pages.map((item) => {
            if (item.name === "Contact") {
              return {
                ...item,
                subTabs: d?.map((i) => {
                  return {
                    tabName: i?.title,
                    link: `/pages/${i?.slug}`,
                  };
                }),
              };
            } else {
              return item;
            }
          });
          dispatch(setPages(page));
        }
      }
    } catch (err) {
      Swal.fire({
        title: "Sorry!",
        text: "Unable to fetch pages.",
        icon: "warning",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const StreamMusic = async (id) => {
    try {
      const res = await axios.get(`${SERVER_URL.STREAM_MUSIC}${id}`, {
        headers: {
          Accept: "application/json",
        },
      });
    } catch (err) {}
  };

  const handleGetArtistsData = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.GET_ARTIST_ALBUMS}${id}/albums`,
        {
          headers: {
            Accept: "application/json",
          },
          params: {
            page: 1,
            items: PER_PAGE_COUNT.MORE_ARTISTS_ALBUM,
          },
        }
      );
      if (res.status === 200) {
        if (res?.data?.data) {
          setMoreFromArtists(res?.data?.data);
        }
      }
    } catch (err) {
      setMoreFromArtists([]);
    }
  };

  useEffect(() => {
    if (queue?.length !== 0) {
      const q = JSON.stringify(queue);
      localStorage.setItem("queue", q);
    }
  }, [queue]);

  function handleClose() {
    dispatch(clearQueue());
    localStorage.removeItem("queue");
    const data = {
      isPlaying: false,
      musicArgs: {
        id: "",
        title: "",
        subTitle: "",
        hlsSource: "",
        thumbnail: "",
        footerText: "",
        duration: "",
      },
    };
    dispatch(setMusic(data));
  }

  function handleQueueUpdate(item) {
    dispatch(setQueue(item));
  }

  function handleDeleteFromQueue(item) {
    dispatch(deleteFromQueue(item));
  }

  function handleMoveToTop(item) {
    dispatch(moveItemToTop(item));
  }

  function handleMoveToBottom(item) {
    dispatch(moveItemToBottom(item));
  }

  function onMusicChange(i) {
    const data = {
      isPlaying: true,
      musicArgs: {
        id: i?.id,
        title: i?.title,
        subTitle: i?.subTitle,
        hlsSource: i?.hlsSource,
        thumbnail: i?.thumbnail,
        footerText: i?.footerText,
        duration: i?.duration,
      },
    };
    StreamMusic(i?.id);
    dispatch(setMusic(data));
  }

  const handleGetPlaylist = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_USER_PLAYLIST, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setPlaylist(res?.data?.data);
        }
      }
    } catch (err) {
      setPlaylist([]);
    }
  };

  useEffect(() => {
    handleGetPages();
  }, []);

  useEffect(() => {
    if (user && user?.token) {
      handleGetPlaylist();
    }
  }, [user]);

  const handleLike = async (data) => {
    try {
      const res = await axios.get(`${SERVER_URL.LIKE_SONG}${data?.id}/like`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (res.status === 200) {
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to like the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const onCreatePlaylist = async (item) => {
    try {
      const res = await axios.post(SERVER_URL.GET_PLAYLIST, item, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        handleGetPlaylist();
        Swal.fire({
          title: "Playlist Created Successfully",
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to create the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleOpenPlaylist = (item) => {
    setSongId(item?.id);
  };

  const handlePlaylist = async (item) => {
    const body = {
      playlist_id: item?.id,
      song: songId,
    };
    try {
      const res = await axios.post(SERVER_URL.ADD_SONGS_TO_PLAYLIST, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to add song to the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      {children}
      {music?.musicArgs?.id !== "" && (
        <div className={NavbarCss.musicPlayer}>
          <MiniMusicPlayer
            musicArgs={music?.musicArgs}
            handleClose={() => handleClose()}
            moreFromArtist={moreFromArtist}
            songQueue={queue}
            playlist={playlist}
            handleQueueUpdate={handleQueueUpdate}
            handleDeleteFromQueue={handleDeleteFromQueue}
            handleMoveToBottom={handleMoveToBottom}
            handleMoveToTop={handleMoveToTop}
            isPlaying={music?.isPlaying}
            onMusicChange={onMusicChange}
            onLike={(item) => handleLike(item)}
            onCreatePlaylist={onCreatePlaylist}
            onPlaylistClick={handlePlaylist}
            onPlaylistOpen={(item) => handleOpenPlaylist(item)}
          />
        </div>
      )}
    </div>
  );
}
