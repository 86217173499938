import { Skeleton } from '@mui/material';
import React from 'react'
import Label from '../../../../elements/label/Label';
import ViewMore from '../../../../elements/view-more/ViewMore';
import GlobalSearchCss from "../../GlobalSearch.module.css";
import GenreCardLoader from "../../../../elements/skeletonLoaders/GenreCardLoader";
import GenreList from "../../../../elements/Flatlist/GenreList";

export default function Genres({
    genres,
    isLoading,
    length,
    showViewAll = false,
    setValue,
    moreGenresIsLoading,
    genresPageNumber,
    genresLastPage,
    loadMoreGenres = undefined,
  }) {
    return (
      <div>
        <div className={GlobalSearchCss.mainDiv}>
          {isLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={"40%"}
              height={50}
            />
          ) : (
            <Label label={`Genres (${length} results)`} size="md" />
          )}
        </div>
        <div>
          {isLoading ? (
            <div className={GlobalSearchCss.albumsDiv}>
              {Array.from({ length: 8 }, (_, index) => (
                <div key={index} className={GlobalSearchCss.albumsSubDiv}>
                  <GenreCardLoader />
                </div>
              ))}
            </div>
          ) : (
            <>
              <GenreList genres={genres} />
              {moreGenresIsLoading && (
                <div className={GlobalSearchCss.flexDr} style={{ marginTop: 10 }}>
                  {Array.from({ length: 8 }, (_, index) => (
                    <GenreCardLoader key={index} />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
        {showViewAll && <ViewMore onClick={() => setValue(9)} />}
        {genresLastPage != null &&
          genresPageNumber < genresLastPage &&
          !isLoading &&
          !moreGenresIsLoading &&
          genres?.length !== 0 &&
          !showViewAll && <ViewMore loadMore={true} onClick={loadMoreGenres} />}
      </div>
    );
  }
