import React from "react";
import { Skeleton } from "@mui/material";
import LoaderCss from "./loader.module.css";
import { useState, useEffect } from "react";
export default function GenreCard() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={LoaderCss.albumsSubDiv}>
      <Skeleton
        sx={{ bgcolor: "grey.900", borderRadius: 4 }}
        animation="wave"
        variant="rectangular"
        width={width < 431 ? "100%" : 273}
        height={width < 431 ? 180 : 270}
      />
    </div>
  );
}
