import { Grid } from "@mui/material";
import React from "react";
import Card from "../../components/card/Card";
import { LocationOn } from "@mui/icons-material";
import { FaCalendarAlt } from "react-icons/fa";
import FlatListCss from "./Flatlist.module.css";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router";
import { LocationExtractor } from "../location-extractor/LocationExtractor";
import { ExtractCarnivalDate } from "../date-extractor/ExtractDate";

export default function GalleryList({ gallery, refresh = false }) {
  const navigate = useNavigate();
  const handleClick = (item) => {
    if (refresh) {
      window.location.href = item;
    } else {
      navigate(item);
      window.scrollTo(0, 0);
    }
  };
  return (
    <Grid container rowSpacing={4} columnSpacing={2}>
      {gallery?.map((item, index) => {
        return (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <Card
              title={item?.name}
              imageSrc={item?.cover_photo_url}
              onClick={() => handleClick(`/gallery/view/${item?.slug}`)}
              content={
                <div className={FlatListCss.galleryContent}>
                  <div className={FlatListCss.flexDrC}>
                    {item?.location && (
                      <div className={FlatListCss.subText}>
                        <LocationOn className={FlatListCss.locationIcon} />{" "}
                        <div className={FlatListCss.oneLineText}>
                          {LocationExtractor(item?.location)}
                        </div>
                      </div>
                    )}
                    <div className={FlatListCss.subText}>
                      <FaCalendarAlt className={FlatListCss.calendarIcon} />{" "}
                      <div className={FlatListCss.oneLineText}>
                        {ExtractCarnivalDate(item?.created_at)}
                      </div>
                    </div>
                  </div>
                  {item?.rating && (
                    <div className={FlatListCss.ratingCarnival}>
                      {item?.rating} <StarIcon className={FlatListCss.star} />
                    </div>
                  )}
                </div>
              }
              textAlign={"left"}
              showAnimation={true}
              size={"sm"}
              imageProps={{
                borderRadius: 20,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
