import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import AccountCss from "./AccountsCss.module.css";
import Label from "../../elements/label/Label";
import Button from "../../components/button/Button";
import { Delete, Edit } from "@mui/icons-material";
import IconButton from "../../components/icon-button/IconButton";
import { Modal, Skeleton } from "@mui/material";
import { Context } from "../../Context";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip";
import MetaTags from "../../MetaTags";
import VideoCard from "../../components/video-card/VideoCard";
import { ExtractDate } from "../../elements/date-extractor/ExtractDate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import { useLoggedInState } from "../../elements/logged-in-state/useLoggedInState";
import NoDataScreen from "../no-data/NoDataScreen";
import axios from "axios";
import VideoCardLoader from "../../elements/skeletonLoaders/VideoCardLoader.js";
import EditItinerary from "./elements/EditItinerary";
import { connect } from "react-redux";
import Unauthorized from "../no-data/Unauthorized.js";
import ReactGA from "react-ga4";

function PlaylistDetail({ user }) {
  const { slug } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { setPageTitle } = useContext(Context);
  const [details, setDetails] = useState(null);
  const [recommendedVideos, setRecommendedVideos] = useState([]);
  const [recommendedVideosIsLoading, setRecommendedVideosIsLoading] =
    useState(true);
  const navigate = useNavigate();
  const isLoggedIn = useLoggedInState();
  const [status, setStatus] = useState(null);
  const { pathname } = useLocation();
  const [tempId, setTempId] = useState(null);
  const [slugUrl, setSlugUrl] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    if (tempId) {
      ReactGA.send({
        hitType: "pageview",
        page: `/my-video-playlist/${tempId}/${slug}`,
        title: "My Video Playlist Detail",
      });
    }
  }, [tempId]);

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      const url = pathname?.split("/")[3];
      const id = u?.split("-")[2];
      setTempId(id);
      setSlugUrl(`${SERVER_URL.GET_USER_VIDEO_PLAYLISTS_DETAIL}${url}/details`);
    }
  }, [pathname]);

  useEffect(() => {
    if (slugUrl && isAuthorized) {
      handleGetDetails();
    }
  }, [slugUrl, isAuthorized]);

  useEffect(() => {
    if (tempId && user?.id) {
      const authorize = parseInt(tempId) === parseInt(user?.id);
      setIsAuthorized(authorize);
    }
  }, [tempId, user]);

  const getRecommendedVideos = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_VIDEOS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.FEATURED_VIDEOS_HOME,
          type: "featured",
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setRecommendedVideos(res?.data?.data);
        }
        setRecommendedVideosIsLoading(false);
      }
    } catch (err) {
      setRecommendedVideosIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthorized) {
      getRecommendedVideos();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (isAuthorized) {
      if (details) {
        setPageTitle(details?.title);
      } else {
        setPageTitle("Playlist Details");
      }
    }
  }, [details, isAuthorized]);

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const handleGetDetails = async () => {
    try {
      const res = await axios.get(slugUrl, {
        headers: {
          Accept: "application/json",
        },
      });
      setStatus(res.status);
      if (res.status === 200) {
        if (res?.data && res?.data?.playlist_details) {
          setDetails(res?.data?.playlist_details);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setStatus(err.response.status);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function VideoList({ videos, size, showDelete = true }) {
    return (
      <div className={AccountCss.flexDrR}>
        {videos?.map((item, index) => {
          const date = ExtractDate(item?.created_at);
          return (
            <div className={AccountCss.videoDiv}>
              <VideoCard
                key={index}
                title={item?.title}
                viewCount={item?.watch_count}
                likeCount={item?.like_count}
                subTitle={date}
                thumbnailLink={item?.cover_image_url}
                onClick={() => handleClick(`/videos/${item?.slug}`)}
                orientation={"portrait"}
                size={size}
              />
              {showDelete && isLoggedIn && parseInt(tempId) === user?.id && (
                <IconButton
                  backgroundColor={"#1C1C1C"}
                  icon={<Delete className={AccountCss.dltIcon} />}
                  onClick={() =>
                    handleDeleteFromVideoPlaylist(details?.id, item?.id)
                  }
                  styles={{
                    position: "absolute",
                    top: -15,
                    right: -15,
                    width: 30,
                    height: 30,
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  const handleEditVideoPlaylist = async (id, body) => {
    try {
      const res = await axios.post(
        `${SERVER_URL.EDIT_VIDEO_PLAYLISTS}${id}/update-playlist`,
        body,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Edit playlist button clicked",
          label: "A playlist has been edited on video playlist details page",
        });
        setIsLoading(true);
        handleGetDetails();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to edit the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteVideoPlaylist = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.DELETE_VIDEO_PLAYLISTS}${id}/delete-playlist`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Delete playlist button clicked",
          label: "A playlist has been delete from video playlist details page",
        });
        setIsLoading(true);
        handleClick(`/my-video-playlist/${user?.slug}`);
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteFromVideoPlaylist = async (id, videoId) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.DELETE_VIDEO_FROM_PLAYLISTS}${id}/${videoId}/delete`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Delete video from playlist button clicked",
          label:
            "A video has been delete from a playlist on video playlist details page",
        });
        setIsLoading(true);
        handleGetDetails();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete from the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Video Playlist's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `${label} playlist Shared on ${item}`,
      label: label,
    });
  }

  if (isAuthorized === false) {
    return <Unauthorized />;
  }

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status}/>;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"Playlist Details"}
        url={window.location.href}
        type={"website"}
        image="../../assets/images/tjj-logo.jpg"
      />
      <div className={AccountCss.mainDiv}>
        {isLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 3 }}
            animation="wave"
            variant="text"
            width={width > 431 ? "30%" : "40%"}
            height={50}
          />
        ) : (
          <Label label={details?.title} size="md" />
        )}
        {isLoggedIn &&
          parseInt(tempId) === user?.id &&
          (width < 500 ? (
            <div className={AccountCss.buttonDiv}>
              <SharingTooltip
                onShare={handleShare}
                transparent
                link={`https://${window.location.href?.split("/")[2]}/videos/playlist/${details?.slug}`}
              />
              <IconButton
                backgroundColor={"transparent"}
                onClick={() => setOpen(true)}
                icon={<Edit className={AccountCss.closeIcon} />}
              />
              <IconButton
                backgroundColor={"transparent"}
                icon={<Delete className={AccountCss.closeIcon} />}
                onClick={() => handleDeleteVideoPlaylist(details?.id)}
              />
            </div>
          ) : (
            <div className={AccountCss.buttonDiv}>
              <SharingTooltip
                onShare={handleShare}
                withText
                showAnimation={true}
                backgroundColor={"#1C1C1C"}
                hoverColor={"#ff0000"}
                link={`https://${window.location.href?.split("/")[2]}/videos/playlist/${details?.slug}`}
              />
              <Button
                label="Edit"
                showAnimation={true}
                backgroundColor={"#1C1C1C"}
                hoverColor={"#ff0000"}
                icon={<Edit className={AccountCss.closeIcon} />}
                iconLeft={true}
                onClick={() => setOpen(true)}
              />
              <Button
                label="Delete"
                showAnimation={true}
                backgroundColor={"#1C1C1C"}
                hoverColor={"#ff0000"}
                icon={<Delete className={AccountCss.closeIcon} />}
                iconLeft={true}
                onClick={() => handleDeleteVideoPlaylist(details?.id)}
              />
            </div>
          ))}

        {open && (
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            className={AccountCss.modalDiv}
          >
            <EditItinerary
              onClick={(i) => handleEditVideoPlaylist(details?.id, i)}
              setOpen={(bool) => setOpen(bool)}
              name={details?.title}
              itineraryDescription={details?.description}
              isPlaylist={true}
            />
          </Modal>
        )}
      </div>
      <div className={AccountCss.mainDiv}>
        <div className={AccountCss.description}>{details?.description}</div>
      </div>
      <div className={AccountCss.divMain}>
        {isLoading ? (
          <div>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="text"
              width={width < 600 ? "30%" : 406}
            />
            <div className={AccountCss.flexDrR}>
              {Array.from({ length: 4 }, (_, index) => (
                <VideoCardLoader key={index} />
              ))}
            </div>
          </div>
        ) : (
          <>
            {details?.videos?.map((item, index) => {
              return (
                <div key={index} className={AccountCss.mgb}>
                  <div className={AccountCss.eventTag}>{item?.timestamp}</div>
                  <VideoList videos={item?.videos} size={"md"} />
                </div>
              );
            })}
          </>
        )}
      </div>
      {recommendedVideosIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 3 }}
          animation="wave"
          variant="text"
          width={width > 431 ? "30%" : "40%"}
          height={50}
        />
      ) : (
        recommendedVideos &&
        recommendedVideos?.length !== 0 && (
          <Label label={"Our Top Recommendations"} size="md" />
        )
      )}
      {recommendedVideosIsLoading ? (
        <div className={AccountCss.flexDrR}>
          {Array.from({ length: 4 }, (_, index) => (
            <VideoCardLoader key={index} size={"sm"} />
          ))}
        </div>
      ) : (
        recommendedVideos &&
        recommendedVideos?.length !== 0 && (
          <>
            <VideoList
              videos={recommendedVideos}
              size={"sm"}
              showDelete={false}
            />
          </>
        )
      )}
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps)(PlaylistDetail);
