import React, { useEffect, useState } from "react";
import { calendarSxStyle } from "../../../css/DatePickerStyles";
import EventCss from "../Events.module.css";
import {
  DateCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { Badge } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function JumpToDate({
  date,
  handleSetDate,
  highlightedDaysKeys,
  resetCalendar,
  handleMonthChange,
  handleYearChange,
  isLoading,
}) {
  const [currentDate, setCurrentDate] = useState(dayjs(new Date()));
  const [highlightedDays, setHighlightedDaysKey] = useState([]);

  useEffect(() => {
    const op = highlightedDaysKeys?.map((item) => {
      return parseInt(Object.keys(item)[0]);
    });
    if (op && op?.length !== 0) {
      setHighlightedDaysKey(op);
    } else {
      setHighlightedDaysKey([]);
    }
  }, [highlightedDaysKeys]);

  useEffect(() => {
    if (date) {
      setCurrentDate(dayjs(date));
    } else {
      setCurrentDate(dayjs(new Date()));
    }
  }, [date]);

  function handleDate(day) {
    const date = new Date(day).getDate();
    const d = highlightedDays.filter((d) => d === date)[0];
    if (d) {
      const days = highlightedDaysKeys?.filter((item) => {
        if (d === date) {
          return item[d];
        }
      })[0];
      if (days) {
        const returnDate = Object.values(days)[0];
        handleSetDate(dayjs(returnDate).format("YYYY-MM-DD"));
      }
    } else {
      handleSetDate(day.format("YYYY-MM-DD"));
    }
  }

  return (
    <div className={EventCss.calendar}>
      <div className={EventCss.ratingSubDiv} style={{ marginBottom: 0 }}>
        <div className={EventCss.calendarHead}>Jump to date</div>
        <div className={EventCss.top} onClick={resetCalendar}>
          Reset
        </div>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          sx={calendarSxStyle}
          value={currentDate}
          onChange={(newValue) => handleDate(newValue)}
          loading={isLoading}
          onMonthChange={(i) => handleMonthChange(i)}
          renderLoading={() => <DayCalendarSkeleton />}
          onYearChange={handleYearChange}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: { highlightedDays },
          }}
        />
      </LocalizationProvider>
      <div className={EventCss.eventDenoter}>
        <span className={EventCss.dotSize}>⚪️</span>
        <span className={EventCss.italic}>
          This symbol serves as a marker for events occurring on the specified
          date.
        </span>
      </div>
    </div>
  );
}

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      variant="dot"
      sx={{
        "& .MuiBadge-dot": {
          backgroundColor: "white",
          height: "5px",
          minWidth: "5px",
          right: "50%",
        },
      }}
      invisible={!isSelected}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}
