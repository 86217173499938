import { createSlice } from "@reduxjs/toolkit";

const initialState = {
albumFilters:{
    country:"",
    year:[],
    artists:"",
    genre:[]
}
};

export const albumSlice = createSlice({
  name: "album",
  initialState,
  reducers: {
    setAlbumFilters: (state, action) => {
      state.albumFilters = [action.payload];
    },
  },
});

export const {
  setAlbumFilters
} = albumSlice.actions;

export const selectAlbumFilters = (state) => state.album.albumFilters;

export default albumSlice.reducer;
