import React from "react";

export function LocationExtractor(location) {
  let l = "";
  if (location?.address) {
    l = l + location?.address;
  } else {
    if (location?.city) {
      l = l + location?.city + ", ";
    }
    if (location?.state) {
      l = l + location?.state + ", ";
    }
    if (location?.country) {
      l = l + location?.country + ", ";
    }
    if (location?.region) {
      l = l + location?.region;
    }
  }
  return l;
}
