import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import NewsArticlesCss from "./NewsArticles.module.css";
import CountryFooter from "../../elements/country-footer/CountryFooter";
import { Grid, Skeleton } from "@mui/material";
import FullWidthBlogList from "../../elements/Flatlist/FullWidthBlogList";
import { popularNewsArticlesCard } from "./Common";
import { Context } from "../../Context";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import ViewMore from "../../elements/view-more/ViewMore";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import NewsArticlesFilter from "./NewsArticlesFilter";
import CategoriesList from "../../elements/categories/CategoriesList";
import TagsList from "../../elements/Flatlist/TagsList";
import MetaTags from "../../MetaTags";
import TextField from "../../components/textfield/TextField";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import NewsLongCardLoader from "../../elements/skeletonLoaders/NewsLongCardLoader";
import NoData from "../../elements/no-data/NoData";
import MiniNewsCard from "../../elements/skeletonLoaders/MiniNewsCard";
import CategoriesLoader from "../../elements/skeletonLoaders/CategoriesLoader";
import AuthorLoader from "../../elements/skeletonLoaders/AuthorLoader";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { IoPersonCircleOutline } from "react-icons/io5";
import ReactGA from "react-ga4";
import DynamicContentInjector from "../../elements/DynamicContentInjector/DynamicContentInjector.js";

const options = [
  {
    label: "Date",
    value: "created_at",
  },
  {
    label: "Views",
    value: "view_count",
  },
  {
    label: "Title",
    value: "title",
  },
];

export default function NewsArticlesHome() {
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);
  const { setPageTitle } = useContext(Context);
  const [newsArticles, setNewsArticles] = useState(null);
  const [popularNewsArticles, setPopularNewsArticles] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [categories, setCategories] = useState(null);
  const [params, setParams] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();
  const [optionalTitle, setOptionalTitle] = useState("");
  const [orderDirection, setOrderDirection] = useState(null);
  const [newsArticlesLoading, setNewsArticlesLoading] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const navigate = useNavigate();
  const [newsArticlesPageNumber, setNewsArticlesPageNumber] = useState(1);
  const [filters, setFilters] = useState({});
  const [newsArticlesLastPage, setNewsArticlesLastPage] = useState(null);
  const [tag, setTag] = useState([]);
  const [allTag, setAllTag] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [popularAuthors, setPopularAuthors] = useState([]);
  const [allAuthors, setAllAuthors] = useState([]);
  const [tagsIsLoading, setTagsIsLoading] = useState(true);
  const [allTagsIsLoading, setAllTagsIsLoading] = useState(true);
  const [allCategoriesIsLoading, setAllCategoriesIsLoading] = useState(true);
  const [allAuthorsIsLoading, setAllAuthorsIsLoading] = useState(true);
  const [categoriesIsLoading, setCategoriesIsLoading] = useState(true);
  const [popularNewsArticlesIsLoading, setPopularNewsArticlesIsLoading] =
    useState(true);
  const [moreNewsArticlesIsLoading, setMoreNewsArticlesIsLoading] =
    useState(false);
  const [bannerAd, setBannerAd] = useState(null);
  const [triggerReset, setTriggerReset] = useState(false);
  const [status, setStatus] = useState(null);
  const [bannerAd2, setBannerAd2] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/news-articles`,
      title: "NewsArticles Home",
    });
  }, []);

  const handleGetSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "sidebar ad square",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd(res.data);
        }
      }
    } catch (err) {
      setBannerAd(null);
    }
  };

  const handleGetSecondSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "skyscraper ad",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd2(res.data);
        }
      }
    } catch (err) {
      setBannerAd2(null);
    }
  };

  useEffect(() => {
    handleGetSidebarBanner();
    handleGetSecondSidebarBanner();
  }, []);

  function sortBy() {
    return (
      <div className={NewsArticlesCss.sortDiv}>
        <div className={NewsArticlesCss.sortBy}>
          <TextField
            defaultValue={orderBy}
            type={"select"}
            label={"Sort by"}
            data={options}
            onChange={(item) => handleSortby(item)}
          />
        </div>
        <FaSortAmountUp
          onClick={() => handleOrder("asc")}
          className={
            orderDirection === "asc"
              ? NewsArticlesCss.ascDescIcon1
              : NewsArticlesCss.ascDescIcon
          }
        />
        <>
          <FaSortAmountDownAlt
            onClick={() => handleOrder("desc")}
            className={
              orderDirection === "desc"
                ? NewsArticlesCss.ascDescIcon1
                : NewsArticlesCss.ascDescIcon
            }
          />
        </>
      </div>
    );
  }

  const handleGetData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_NEWSARTICLES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: newsArticlesPageNumber,
          items: PER_PAGE_COUNT.NEWSARTICLES_ITEM_COUNT,
          orderBy: orderBy,
          orderDirection: orderDirection,
          ...(filters?.tag && {
            tags: filters?.tag,
          }),
          ...(filters?.category && {
            categories: filters?.category,
          }),
          ...(filters?.author && {
            authors: filters?.author,
          }),
          ...(filters?.startDate && {
            startDate: filters?.startDate,
          }),
          ...(filters?.endDate && {
            endDate: filters?.endDate,
          }),
        },
      });
      if (res.status === 200) {
        setStatus(res.status);
        if (res?.data && res?.data?.news_articles) {
          setNewsArticlesLastPage(res?.data?.news_articles?.last_page);
          if (newsArticlesPageNumber === 1) {
            setNewsArticles(res?.data?.news_articles?.data);
          } else {
            setNewsArticles((prev) => [
              ...prev,
              ...res?.data?.news_articles?.data,
            ]);
          }
        }
        setNewsArticlesLoading(false);
        if (moreNewsArticlesIsLoading) {
          setMoreNewsArticlesIsLoading(false);
        }
      }
    } catch (err) {
      setNewsArticlesLoading(false);
      if (moreNewsArticlesIsLoading) {
        setMoreNewsArticlesIsLoading(false);
      }
    }
  };

  const handleGetPopular = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_NEWSARTICLES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.NEWSARTICLES_ITEM_COUNT,
          orderBy: "view_count",
          orderDirection: orderDirection,
        },
      });
      setPopularNewsArticles(res?.data?.news_articles?.data);
      setPopularNewsArticlesIsLoading(false);
    } catch (err) {
      setPopularNewsArticlesIsLoading(false);
    }
  };

  const getAllCategories = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CATEGORIES_NEWSARTICLES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.categories?.data?.length !== 0) {
          setAllCategories(res?.data?.categories?.data);
        }
        setAllCategoriesIsLoading(false);
      }
    } catch (err) {
      setAllCategoriesIsLoading(false);
    }
  };

  const getCategories = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CATEGORIES_NEWSARTICLES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
        },
      });
      if (res.status === 200) {
        if (res?.data?.categories?.data?.length !== 0) {
          setCategories(res?.data?.categories?.data);
        }
        setCategoriesIsLoading(false);
      }
    } catch (err) {
      setCategoriesIsLoading(false);
    }
  };

  const getTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
          type: "news_article",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setTag(res?.data?.data);
        }
        setTagsIsLoading(false);
      }
    } catch (err) {
      setTagsIsLoading(false);
    }
  };

  const getAllTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
          type: "news_article",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setAllTag(res?.data?.data);
        }
        setAllTagsIsLoading(false);
      }
    } catch (err) {
      setAllTagsIsLoading(false);
    }
  };

  const getAuthor = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_AUTHORS_NEWSARTICLES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
        },
      });
      if (res.status === 200) {
        if (res?.data?.authors?.data?.length !== 0) {
          setPopularAuthors(res?.data?.authors?.data);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getAllAuthor = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_AUTHORS_NEWSARTICLES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.authors?.data?.length !== 0) {
          setAllAuthors(res?.data?.authors?.data);
        }
        setAllAuthorsIsLoading(false);
      }
    } catch (err) {
      setAllAuthorsIsLoading(false);
    }
  };

  useEffect(() => {
    if (newsArticlesPageNumber !== 1) {
      handleGetData();
    }
  }, [newsArticlesPageNumber]);

  function updateNewsArticlesPageNumber() {
    if (
      newsArticlesLastPage != null &&
      newsArticlesPageNumber <= newsArticlesLastPage
    ) {
      setMoreNewsArticlesIsLoading(true);
      setNewsArticlesPageNumber(newsArticlesPageNumber + 1);
    }
  }

  const handleOrder = (item) => {
    handleSearchParam("orderby", item);
    const order = item === "asc" ? "ascending" : "descending";
    ReactGA.event({
      category: "Order",
      action: `Order by ${order}`,
      label: `Order by ${order} on all news articles page`,
    });
    ReactGA.event({
      category: "Order",
      action: `Order by ${order} on all news articles page`,
      label: `Ordered the results by ${order} on all news articles page`,
    });
    if (item === "asc") {
      setOrderDirection("asc");
    } else {
      setOrderDirection("desc");
    }
  };

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  useEffect(() => {
    if (searchParams.size !== 0 && searchParams.get("sortby")) {
      handleSortby(searchParams.get("sortby"));
    } else {
      setOrderBy("created_at");
    }

    if (searchParams.size !== 0 && searchParams.get("orderby")) {
      handleOrder(searchParams.get("orderby"));
    } else {
      setOrderDirection("desc");
    }
  }, [searchParams]);

  const handleSortby = (item) => {
    handleSearchParam("sortby", item);
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results`,
      label: `Sort by ${item} on all news articles page`,
    });
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results on all news articles page`,
      label: `Sorted the results by ${item} on all news articles page`,
    });
    setOrderBy(item);
  };

  useEffect(() => {
    if (searchParams.size !== 0) {
      if (searchParams.size === 1) {
        if (searchParams?.get("tag") !== null) {
          const tag = allTag?.filter(
            (i) => i.slug === searchParams?.get("tag")
          )[0]?.title;
          if (tag) {
            setOptionalTitle(`By Tag: ${tag}`);
          }
        }
        if (searchParams?.get("author") !== null) {
          const author = allAuthors?.filter(
            (i) => i.slug === searchParams?.get("author")
          )[0]?.first_name;
          if (author) {
            setOptionalTitle(`By Author: ${author}`);
          }
        }
        if (searchParams?.get("category") !== null) {
          const category = allCategories?.filter(
            (i) => i.slug === searchParams?.get("category")
          )[0]?.title;
          if (category) {
            setOptionalTitle(`By Category: ${category}`);
          }
        }
      } else {
        setOptionalTitle("Filtered Results");
      }
    } else {
      setOptionalTitle("");
    }
  }, [searchParams, allCategories, allAuthors, allTag]);

  useEffect(() => {
    setPageTitle("News Articles");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width < 431) {
      setShowFilter(false);
    }
  }, [width]);

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  const handleShowDd = () => {
    setShowFilter(!showFilter);
  };

  const handleNavigate = (param) => {
    setTriggerReset(true);
    let params = {};
    if (param?.category) {
      params = {
        ...params,
        category: [param?.category],
      };
    }
    if (param?.author) {
      params = {
        ...params,
        author: [param?.author],
      };
    }
    if (param?.tag) {
      params = {
        ...params,
        tag: [param?.tag],
      };
    }
    setTimeout(() => {
      handleSearch(params);
      window.scrollTo(0, 0);
    }, 1000);
  };

  const handleSearchParam = (key, value) => {
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    handleGetPopular();
    getAllCategories();
    getAllTags();
    getTags();
    getCategories();
    getAllAuthor();
    getAuthor();
  }, []);

  const handleResetAll = () => {
    setFilters({});
    const filter = ["tag", "author", "category", "startDate", "endDate"];
    filter.map((item) => {
      if (searchParams.has(item)) {
        searchParams.delete(item);
        setSearchParams(searchParams);
        delete params[item];
      }
    });
  };

  useEffect(() => {
    if (searchParams.size !== 0) {
      let params = {};
      if (searchParams?.getAll("tag")?.length !== 0) {
        params = {
          ...params,
          tag: searchParams.getAll("tag"),
        };
      }
      if (searchParams?.getAll("author")?.length !== 0) {
        params = {
          ...params,
          author: searchParams.getAll("author"),
        };
      }
      if (searchParams?.getAll("category")?.length !== 0) {
        params = {
          ...params,
          category: searchParams.getAll("category"),
        };
      }
      if (searchParams?.get("startDate")) {
        params = {
          ...params,
          startDate: searchParams.get("startDate"),
        };
      }
      if (searchParams?.get("endDate")) {
        params = {
          ...params,
          endDate: searchParams.get("endDate"),
        };
      }

      if (
        params &&
        Object.keys(params).length > 0 &&
        Object.keys(filters)?.length === 0
      ) {
        handleSearch(params);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    setNewsArticlesPageNumber(1);
    setNewsArticlesLoading(true);
    setNewsArticles([]);
    if (orderBy && orderDirection) {
      if (searchParams.size === 1 && searchParams.has("sortby")) {
        handleGetData();
      } else if (searchParams.size === 1 && searchParams.has("orderby")) {
        handleGetData();
      } else if (
        searchParams.size === 2 &&
        searchParams.has("orderby") &&
        searchParams.has("sortby")
      ) {
        handleGetData();
      } else if (searchParams.size !== 0) {
        if (Object.keys(filters)?.length !== 0) {
          handleGetData();
        }
      } else {
        handleGetData();
      }
    }
  }, [orderBy, orderDirection, filters]);

  const handleSearch = (item) => {
    setFilters(item);
    ReactGA.event({
      category: "Filter",
      action: "Results filtered",
      label: "Results filtered on all news articles page",
    });
    ReactGA.event({
      category: "Filter",
      action: "Results filtered on all news articles page",
      label: "Results filtered on all news articles page",
    });
    Object.entries(item)?.map((i) => {
      handleSearchParam(i[0], i[1]);
    });
  };

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"News Articles"}
        url={window.location.href}
        type={"website"}
        image="../../assets/images/tjj-logo.jpg"
      />
      <Grid container rowGap={2}>
        <Grid item className={NewsArticlesCss.gridGeneral} xs={12} md={9}>
          <div className={NewsArticlesCss.flexDr}>
            <div className={NewsArticlesCss.head}>
              <Label
                size="lg"
                label={
                  optionalTitle !== ""
                    ? "News Articles " + optionalTitle
                    : "News Articles"
                }
              />
              {width > 899 && sortBy()}
            </div>
            <div className={NewsArticlesCss.iconsSm}>
              <div className={NewsArticlesCss.iconsSmDiv}>
                <img
                  src={require("../../assets/images/filter.png")}
                  className={NewsArticlesCss.filterSm}
                  onClick={() => handleShowDd()}
                />
                {showFilter ? (
                  <FaCaretUp className={NewsArticlesCss.upDown} />
                ) : (
                  <FaCaretDown className={NewsArticlesCss.upDown} />
                )}
              </div>
            </div>
          </div>
          {width < 900 && sortBy()}

          {showFilter &&
            (allTagsIsLoading ||
            allCategoriesIsLoading ||
            allAuthorsIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginTop: 2 }}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={440}
              />
            ) : (
              <NewsArticlesFilter
                authors={allAuthors}
                categoryArray={allCategories}
                tagArray={allTag}
                onReset={handleResetAll}
                filter={filters}
                onSearch={(item) => handleSearch(item)}
                triggerReset={triggerReset}
                setTriggerReset={setTriggerReset}
              />
            ))}
          {bannerAd &&
            (bannerAd?.banner_image_url ? (
              <div className={NewsArticlesCss.smBanner}>
                <img
                  className={NewsArticlesCss.BannerAds}
                  src={bannerAd?.banner_image_url}
                />
              </div>
            ) : (
              bannerAd?.content && (
                <div className={NewsArticlesCss.smBanner}>
                  <DynamicContentInjector
                    className={NewsArticlesCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                </div>
              )
            ))}
          {bannerAd2 &&
            (bannerAd2?.banner_image_url ? (
              <div className={NewsArticlesCss.smBanner}>
                <img
                  className={NewsArticlesCss.BannerAds}
                  src={bannerAd2?.banner_image_url}
                />
              </div>
            ) : (
              bannerAd2?.content && (
                <div className={NewsArticlesCss.smBanner}>
                  <DynamicContentInjector
                    className={NewsArticlesCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                </div>
              )
            ))}
          <div className={NewsArticlesCss.firstContainer}>
            {newsArticlesLoading ? (
              <>
                {Array.from({ length: 6 }, (_, index) => {
                  return <NewsLongCardLoader key={index} />;
                })}
                <div className={NewsArticlesCss.mgb} />
              </>
            ) : (
              <>
                {newsArticles && newsArticles?.length !== 0 ? (
                  <>
                    {/* newsArticles.months.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          index === newsArticles?.months?.length - 1
                            ? undefined
                            : NewsArticlesCss.mgb
                        }
                      >
                        {orderBy === "created_at" && (
                          <Label size="sm" label={item.name} />
                        )} */}

                    <FullWidthBlogList newsarticles={newsArticles} />
                    {moreNewsArticlesIsLoading && (
                      <>
                        {Array.from({ length: 6 }, (_, index) => {
                          return <NewsLongCardLoader key={index} />;
                        })}
                        <div className={NewsArticlesCss.mgb} />
                      </>
                    )}
                    {/* </div>
                    );
                  }) */}
                  </>
                ) : (
                  <NoData errorCode={status} />
                )}
                {newsArticles &&
                  newsArticles?.length !== 0 &&
                  newsArticlesLastPage !== null &&
                  newsArticlesPageNumber < newsArticlesLastPage &&
                  !newsArticlesLoading &&
                  !moreNewsArticlesIsLoading && (
                    <ViewMore loadMore onClick={updateNewsArticlesPageNumber} />
                  )}
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={NewsArticlesCss.secondContainer}>
            {allTagsIsLoading ||
            allCategoriesIsLoading ||
            allAuthorsIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginTop: 2 }}
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={440}
              />
            ) : (
              <NewsArticlesFilter
                authors={allAuthors}
                categoryArray={allCategories}
                tagArray={allTag}
                onReset={handleResetAll}
                filter={filters}
                onSearch={(item) => handleSearch(item)}
                triggerReset={triggerReset}
                setTriggerReset={setTriggerReset}
              />
            )}
          </div>
          {popularNewsArticlesIsLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 2 }}
              animation="wave"
              variant="text"
              width={width > 431 ? "30%" : "40%"}
              height={50}
            />
          ) : (
            popularNewsArticles &&
            popularNewsArticles?.length !== 0 && (
              <Label size="md" label={"Popular News Articles"} />
            )
          )}
          <div className={NewsArticlesCss.rightDiv}>
            {popularNewsArticlesIsLoading ? (
              <>
                {Array.from({ length: 3 }, (_, index) => {
                  return <MiniNewsCard key={index} />;
                })}
              </>
            ) : (
              popularNewsArticles &&
              popularNewsArticles?.length !== 0 &&
              popularNewsArticles.map((item, index) => {
                return popularNewsArticlesCard(item, index, handleClick);
              })
            )}
          </div>
          <div className={NewsArticlesCss.secondContainer}>
            {bannerAd &&
              (bannerAd?.banner_image_url ? (
                <img
                  className={NewsArticlesCss.BannerAds}
                  src={bannerAd?.banner_image_url}
                />
              ) : (
                bannerAd?.content && (
                  <DynamicContentInjector
                    className={NewsArticlesCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                )
              ))}
            {bannerAd2 &&
              (bannerAd2?.banner_image_url ? (
                <img
                  className={NewsArticlesCss.BannerAds}
                  src={bannerAd2?.banner_image_url}
                />
              ) : (
                bannerAd2?.content && (
                  <DynamicContentInjector
                    className={NewsArticlesCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                )
              ))}
          </div>
        </Grid>
      </Grid>

      {categoriesIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={width > 431 ? "30%" : "40%"}
          height={50}
        />
      ) : (
        categories &&
        categories?.length !== 0 && (
          <div className={NewsArticlesCss.redHead}>Popular Categories</div>
        )
      )}
      <Grid className={NewsArticlesCss.topBox} container columnGap={3}>
        {categoriesIsLoading ? (
          <CategoriesLoader />
        ) : (
          categories &&
          categories?.length !== 0 &&
          categories.map((item, index) => {
            return (
              <CategoriesList
                key={index}
                item={item}
                onClick={() => handleNavigate({ category: item?.slug })}
              />
            );
          })
        )}
      </Grid>
      {categoriesIsLoading ? (
        <div className={NewsArticlesCss.mgb} />
      ) : (
        categories &&
        categories?.length !== 0 && (
          <ViewMore onClick={() => handleClick("/news-articles/categories")} />
        )
      )}

      {tagsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={width > 431 ? "30%" : "40%"}
          height={50}
        />
      ) : (
        tag &&
        tag?.length !== 0 && (
          <div className={NewsArticlesCss.redHead}>Popular Tags</div>
        )
      )}
      {tagsIsLoading ? (
        <Grid container columnGap={3}>
          {Array.from({ length: 50 }, (_, index) => {
            return (
              <Grid key={index} item xs={5.3} sm={3.3} md={2.2}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        tag &&
        tag?.length !== 0 && (
          <TagsList tag={tag} handleNavigate={handleNavigate} />
        )
      )}
      {tagsIsLoading ? (
        <div className={NewsArticlesCss.mgb} />
      ) : (
        tag &&
        tag?.length !== 0 && (
          <ViewMore onClick={() => handleClick("/news-articles/tags")} />
        )
      )}

      {isLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={width > 431 ? "30%" : "40%"}
          height={50}
        />
      ) : (
        popularAuthors &&
        popularAuthors?.length !== 0 && (
          <div className={NewsArticlesCss.redHead}>Popular Authors</div>
        )
      )}
      <Grid className={NewsArticlesCss.topBox} container columnGap={3}>
        {isLoading ? (
          <AuthorLoader />
        ) : (
          popularAuthors &&
          popularAuthors?.length !== 0 &&
          popularAuthors.map((item, index) => {
            return (
              <Grid item key={index} xs={5.3} sm={3.3} md={2.2}>
                <div
                  onClick={() => handleNavigate({ author: item?.slug })}
                  key={index}
                  className={NewsArticlesCss.flexDir}
                >
                  {item?.profile_photo_url === null ? (
                    <IoPersonCircleOutline
                      className={NewsArticlesCss.authorIcon}
                    />
                  ) : (
                    <img
                      src={item?.profile_photo_url}
                      className={NewsArticlesCss.authorImage}
                      alt="Author Image"
                    />
                  )}
                  <div className={NewsArticlesCss.authorContentDiv}>
                    <div className={NewsArticlesCss.authorTitle}>
                      {item?.first_name} {item?.last_name}
                    </div>
                    <div className={NewsArticlesCss.authorReviews}>
                      {item?.newsArticles_count} news articles
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })
        )}
      </Grid>
      {isLoading ? (
        <div className={NewsArticlesCss.mgb} />
      ) : (
        popularAuthors &&
        popularAuthors?.length !== 0 && (
          <ViewMore onClick={() => handleClick("/news-articles/authors")} />
        )
      )}

      {/* <CountryFooter
        by={"News Articles"}
        handleClick={(item) => alert(`Location changed to: ${item}`)}
      /> */}
    </NavContainer>
  );
}
