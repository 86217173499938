import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Carousel from "../../elements/Carousel/Carousel";
import Label from "../../elements/label/Label";
import Card from "../../components/card/Card";
import { Grid, Skeleton } from "@mui/material";
import GalleryList from "../../elements/Flatlist/GalleryList";
import VideoList from "../../elements/Flatlist/VideoList";
import NewsList from "../../elements/Flatlist/NewsList";
import EventList from "../../elements/Flatlist/EventList";
import AlbumsList from "../../elements/Flatlist/AlbumsList";
import CarnivalHomeCss from "./CarnivalHome.module.css";
import ViewMore from "../../elements/view-more/ViewMore";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router";
import { Context } from "../../Context";
import { LocationExtractor } from "../../elements/location-extractor/LocationExtractor";
import {
  ExtractShortMonthDate,
  ExtractYear,
} from "../../elements/date-extractor/ExtractDate";
import MetaTags from "../../MetaTags";
import GalleryCardLoader from "../../elements/skeletonLoaders/GalleryCardLoader";
import VideoCardLoader from "../../elements/skeletonLoaders/VideoCardLoader";
import NewsCardLoader from "../../elements/skeletonLoaders/NewsCardLoader";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader";
import PhotoCardLoader from "../../elements/skeletonLoaders/PhotoCardLoader";
import EventLongCardLoader from "../../elements/skeletonLoaders/EventLongCardLoader";
import UpcomingCarnivalCardLoader from "../../elements/skeletonLoaders/UpcomingCarnivalCardLoader";
import ReLiveCardLoader from "../../elements/skeletonLoaders/ReLiveCardLoader";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import ClassifiedCard from "../../components/classified-card/ClassifiedCard";
import ClassifieldsLoader from "../../elements/skeletonLoaders/ClassifiedsLoader";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import ReactGA from "react-ga4";

function CarnivalHome({ auth }) {
  const { setPageTitle } = useContext(Context);
  const [featuredCarnivals, setFeaturedCarnivals] = useState([]);
  const [upcomingCarnivals, setUpcomingCarnivals] = useState([]);
  const [carnivalByLocation, setCarnivalByLocation] = useState([]);
  const [carnivalPhotosByLocation, setCarnivalPhotosByLocation] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [videosData, setVideosData] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);
  const [recentCarnival, setRecentCarnival] = useState([]);
  const [pastCarnivals, setPastCarnivals] = useState([]);
  const [music, setMusic] = useState([]);
  const [upcomingIsLoading, setUpcomingIsLoading] = useState(true);
  const [featuredIsLoading, setFeaturedIsLoading] = useState(true);
  const [newsIsLoading, setNewsIsLoading] = useState(true);
  const [pastIsLoading, setPastIsLoading] = useState(true);
  const [carnivalsByLocationIsLoading, setCarnivalsByLocationIsLoading] =
    useState(true);
  const [classifieds, setClassifieds] = useState([]);
  const [eventsIsLoading, setEventIsLoading] = useState(true);
  const [galleriesIsLoading, setGalleriesIsLoading] = useState(true);
  const [videosIsLoading, setVideosIsLoading] = useState(true);
  const [recentIsLoading, setRecentIsLoading] = useState(true);
  const [musicIsLoading, setMusicIsLoading] = useState(true);
  const [photosByLocationIsLoading, setPhotosByLocationIsLoading] =
    useState(true);
  const [classifiedsIsLoading, setClassifiedsIsLoading] = useState(true);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/carnival",
      title: "Carnival Home",
    });
  }, []);

  // This useEffect will be called when we try to resize the window. It will be used to add conditions for different viewports.
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  const handleGetUpcoming = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_UPCOMING_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.FEATURED_VIDEOS_HOME,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setUpcomingCarnivals(res.data.data);
        }
        setUpcomingIsLoading(false);
      }
    } catch (err) {
      setUpcomingIsLoading(false);
    }
  };
  const handleGetCarnivalsByLocation = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CARNIVALS_BY_LOCATION, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.CARNIVALS_BY_LOCATION,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setCarnivalByLocation(res.data.data);
        }
        setCarnivalsByLocationIsLoading(false);
      }
    } catch (err) {
      setCarnivalsByLocationIsLoading(false);
    }
  };
  const handleGetFeaturedCarnivals = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_FEATURED_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.FEATURED_CARNIVALS,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setFeaturedCarnivals(res.data.data);
        }
        setFeaturedIsLoading(false);
      }
    } catch (err) {
      setFeaturedIsLoading(false);
    }
  };
  const handleGetPastCarnivals = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_PAST_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.PAST_CARNIVALS,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setPastCarnivals(res.data.data);
        }
        setPastIsLoading(false);
      }
    } catch (err) {
      setPastIsLoading(false);
    }
  };
  const handleCarnivalEvents = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CARNIVAL_EVENTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.CARNIVAL_EVENTS,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setEvents(res.data.data);
        }
        setEventIsLoading(false);
      }
    } catch (err) {
      setEventIsLoading(false);
    }
  };
  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };
  const handleCarnivalGalleries = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CARNIVAL_GALLERIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.CARNIVAL_GALLERIES,
          year: "current",
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setGalleries(res.data.data);
        }
        setGalleriesIsLoading(false);
      }
    } catch (err) {
      setGalleriesIsLoading(false);
    }
  };
  const handleCarnivalVideos = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CARNIVAL_VIDEOS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.CARNIVAL_VIDEOS,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setVideosData(res.data.data);
        }
        setVideosIsLoading(false);
      }
    } catch (err) {
      setVideosIsLoading(false);
    }
  };
  const handleCarnivalGalleriesByLocation = async () => {
    try {
      const res = await axios.get(
        SERVER_URL.GET_CARNIVAL_GALLERIES_BY_LOCATION,
        {
          headers: {
            Accept: "application/json",
          },
          params: {
            page: 1,
            items: PER_PAGE_COUNT.CARNIVAL_GALLERIES_BY_LOCATION,
            year: "past",
          },
        }
      );
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setCarnivalPhotosByLocation(res.data.data);
        }
        setPhotosByLocationIsLoading(false);
      }
    } catch (err) {
      setPhotosByLocationIsLoading(false);
    }
  };
  const handleGetRecentCarnivals = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_RECENT_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.RECENT_CARNIVALS,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setRecentCarnival(res.data.data);
        }
        setRecentIsLoading(false);
      }
    } catch (err) {
      setRecentIsLoading(false);
    }
  };
  const handleGetCarnivalMusic = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CARNIVAL_MUSIC, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.CARNIVAL_MUSIC,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setMusic(res.data.data);
        }
        setMusicIsLoading(false);
      }
    } catch (err) {
      setMusicIsLoading(false);
    }
  };
  const handleGetCarnivalArticles = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CARNIVAL_ARTICLES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.CARNIVAL_ARTICLES,
        },
      });
      if (res.status === 200) {
        if (
          res?.data &&
          res?.data?.news_articles &&
          res?.data?.news_articles?.data
        ) {
          setNews(res.data.news_articles.data);
        }
        setNewsIsLoading(false);
      }
    } catch (err) {
      setNewsIsLoading(false);
    }
  };
  const handleGetCarnivalClassifieds = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CARNIVAL_CLASSIFIEDS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.CARNIVAL_CLASSIFIEDS,
        },
      });
      if (res.status === 200) {
        if (
          res?.data &&
          res?.data?.classifieds &&
          res?.data?.classifieds?.data
        ) {
          setClassifieds(res?.data?.classifieds?.data);
        }
        setClassifiedsIsLoading(false);
      }
    } catch (err) {
      setClassifiedsIsLoading(false);
    }
  };

  const handleDetail = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const handleChat = (user) => {
    const userId = auth?.user?.id;
    if (userId) {
      navigate(`/carnival/classifieds/${userId}/inbox/chatbox`, {
        state: { user: user },
      });
      window.scrollTo(0, 0);
    } else {
      handleLogin();
    }
  };

  const handleNavigate = (param) => {
    const params = `tag=${param.tag}`;
    const route = "/carnival/classifieds?" + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  const handleLogin = () => {
    Swal.fire({
      title: "You are not logged in!",
      text: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  // The setPageTitle is being used to update the title in the breadcrumbs.
  useEffect(() => {
    setPageTitle("Carnival");
  }, []);

  useEffect(() => {
    handleGetUpcoming();
    handleGetFeaturedCarnivals();
    handleGetPastCarnivals();
    handleGetCarnivalsByLocation();
    handleCarnivalEvents();
    handleCarnivalGalleries();
    handleCarnivalVideos();
    handleCarnivalGalleriesByLocation();
    handleGetRecentCarnivals();
    handleGetCarnivalMusic();
    handleGetCarnivalArticles();
    handleGetCarnivalClassifieds();
  }, []);

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"Carnival"}
        url={window.location.href}
        type={"website"}
        image="../../assets/images/tjj-logo.jpg "
      />
      {featuredIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        featuredCarnivals.length > 0 && (
          <Label label={"Featured Carnivals"} size="md" />
        )
      )}
      {featuredIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 10 }}
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={600}
        />
      ) : (
        featuredCarnivals.length > 0 && <Carousel data={featuredCarnivals} />
      )}
      {upcomingIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        upcomingCarnivals.length > 0 && (
          <Label label={"Plan Your Next Carnival"} size="md" />
        )
      )}{" "}
      {upcomingIsLoading ? (
        <div className={CarnivalHomeCss.mgb}>
          <Grid container rowSpacing={4} columnSpacing={2}>
            {Array.from({ length: 4 }, (_, index) => {
              return (
                <Grid key={index} item xs={12} md={6}>
                  <div>
                    <UpcomingCarnivalCardLoader />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        upcomingCarnivals.length > 0 && (
          <>
            <Grid container rowSpacing={4} columnSpacing={2}>
              {upcomingCarnivals.map((item, index) => {
                const startDate = ExtractShortMonthDate(item.start_datetime);
                const endDate = ExtractShortMonthDate(item.end_datetime);
                return (
                  <Grid key={index} item xs={12} sm={6} md={12} lg={6}>
                    <Card
                      imageProps={{ height: width > 431 ? 318 : "auto" }}
                      title={item.title}
                      imageSrc={item.poster_url}
                      content={
                        <div className={CarnivalHomeCss.subTextCard}>
                          {startDate} to {endDate}
                        </div>
                      }
                      onClick={() => handleClick(`/carnival/${item.slug}`)}
                      showLinearFillShadow={true}
                      textAlign={"center"}
                      size={"lg"}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <ViewMore onClick={() => handleClick("/world-carnival")} />
          </>
        )
      )}
      {eventsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        events.length > 0 && <Label label={"Carnival Events"} size="md" />
      )}
      {eventsIsLoading ? (
        <div className={CarnivalHomeCss.events}>
          {Array.from({ length: 4 }, (_, index) => {
            return (
              <div key={index} style={{ minWidth: "49%" }}>
                <EventLongCardLoader />;
              </div>
            );
          })}
          <div className={CarnivalHomeCss.mgb} />
        </div>
      ) : (
        events.length > 0 && (
          <>
            <EventList events={events} />
            <ViewMore
              viewMoreOrFilter={true}
              onClick={() => {
                navigate("/events", { state: { selectedTab: "featured" } });
                window.scrollTo(0, 0);
              }}
            />
          </>
        )
      )}
      {galleriesIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        galleries.length > 0 && (
          <Label label={"Recent Carnival Photos"} size="md" />
        )
      )}
      {galleriesIsLoading ? (
        <>
          <div className={CarnivalHomeCss.flexDrR}>
            {Array.from({ length: 8 }, (_, index) => {
              return <GalleryCardLoader key={index} />;
            })}
          </div>
          <div className={CarnivalHomeCss.mgb} />
        </>
      ) : (
        galleries.length > 0 && (
          <>
            <GalleryList gallery={galleries} />
            <ViewMore onClick={() => handleClick("/gallery")} />
          </>
        )
      )}
      {videosIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        videosData.length > 0 && <Label label={"Carnival Videos"} size="md" />
      )}
      {videosIsLoading ? (
        <>
          <div className={CarnivalHomeCss.flexDrR}>
            {Array.from({ length: 6 }, (_, index) => {
              return <VideoCardLoader key={index} />;
            })}
          </div>
          <div className={CarnivalHomeCss.mgb} />
        </>
      ) : (
        videosData.length > 0 && (
          <>
            <VideoList videos={videosData} />
            <ViewMore onClick={() => handleClick("/videos")} />
          </>
        )
      )}
      {musicIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        music.length > 0 && <Label label={"Carnival Music"} size="md" />
      )}
      {musicIsLoading ? (
        <div className={CarnivalHomeCss.mgb}>
          <div className={CarnivalHomeCss.albumsDiv}>
            {Array.from({ length: 8 }, (_, index) => {
              return (
                <div key={index} className={CarnivalHomeCss.albumsSubDiv}>
                  <MusicCardLoader />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        music.length > 0 && (
          <>
            <AlbumsList albums={music} />
            <ViewMore onClick={() => handleClick("/music")} />
          </>
        )
      )}
      {news?.length > 0 && <Label label={"Carnival News"} size="lg" />}
      {newsIsLoading ? (
        <div className={CarnivalHomeCss.mgb}>
          <div className={CarnivalHomeCss.flexDrR}>
            {Array.from({ length: 4 }, (_, index) => {
              return <NewsCardLoader key={index} />;
            })}
          </div>
        </div>
      ) : (
        news?.length > 0 && (
          <>
            <NewsList news={news} share={false} />
            <ViewMore onClick={() => handleClick("/newsletters")} />
          </>
        )
      )}
      {photosByLocationIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        carnivalPhotosByLocation.length > 0 && (
          <Label label={"Past Carnival Photos"} size="md" />
        )
      )}{" "}
      {photosByLocationIsLoading ? (
        <div className={CarnivalHomeCss.mgb}>
          <Grid container rowSpacing={4} columnSpacing={2}>
            {Array.from({ length: 6 }, (_, index) => {
              return (
                <Grid key={index} item xs={12} md={4}>
                  <PhotoCardLoader />
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        carnivalPhotosByLocation.length > 0 && (
          <>
            <Grid container rowSpacing={4} columnSpacing={2}>
              {carnivalPhotosByLocation?.map((item, index) => {
                const location = LocationExtractor(item?.location);
                return (
                  <Grid key={index} item xs={12} md={4}>
                    <Card
                      imageProps={{ height: width > 431 ? 208 : "auto" }}
                      title={item?.name}
                      imageSrc={item?.cover_photo_url}
                      content={
                        <div className={CarnivalHomeCss.subTitle}>
                          {location}
                        </div>
                      }
                      onClick={() => handleClick(`/gallery/view/${item?.slug}`)}
                      textAlign={"left"}
                      textColor={"#FF0000"}
                      size={"md"}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <ViewMore onClick={() => handleClick("/gallery")} />
          </>
        )
      )}
      {recentIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        recentCarnival.length > 0 && (
          <Label label={"Re-Live Recent Carnival Experiences"} size="md" />
        )
      )}{" "}
      {recentIsLoading ? (
        <div className={CarnivalHomeCss.mgb}>
          <Grid container rowSpacing={4} columnSpacing={2}>
            {Array.from({ length: 6 }, (_, index) => {
              return (
                <Grid key={index} item xs={12} md={4}>
                  <ReLiveCardLoader />
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        recentCarnival.length > 0 && (
          <>
            <Grid container rowSpacing={4} columnSpacing={2}>
              {recentCarnival?.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                    <Card
                      imageProps={{ height: width > 431 ? 208 : "auto" }}
                      title={item.title}
                      imageSrc={item.poster_url}
                      textAlign={"center"}
                      onClick={() => handleClick(`/carnival/${item.slug}`)}
                      size={"md"}
                      showLinearFillShadow={true}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <ViewMore onClick={() => handleClick("/carnival")} />
          </>
        )
      )}
      {pastIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        pastCarnivals.length > 0 && <Label label={"Past Carnivals"} size="md" />
      )}
      {pastIsLoading ? (
        <div className={CarnivalHomeCss.mgb}>
          <Grid container rowSpacing={4} columnSpacing={2}>
            {Array.from({ length: 4 }, (_, index) => {
              return (
                <Grid key={index} item xs={12} md={6}>
                  <PhotoCardLoader
                    width={width > 600 ? 550 : 330}
                    height={360}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        pastCarnivals.length > 0 && (
          <>
            <Grid container rowSpacing={4} columnSpacing={2}>
              {pastCarnivals.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={6} md={12} lg={6}>
                    <Card
                      imageProps={{ height: width > 431 ? 318 : "auto" }}
                      title={item.title}
                      imageSrc={item.poster_url}
                      content={
                        <div className={CarnivalHomeCss.ratingCarnival}>
                          Rating : {item.tjj_rating}{" "}
                          <StarIcon className={CarnivalHomeCss.star} />| Posted
                          Year : {ExtractYear(item.end_datetime)}
                        </div>
                      }
                      textAlign={"left"}
                      onClick={() => handleClick(`/carnival/${item.slug}`)}
                      textColor={"#FF0000"}
                      size={"lg"}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <ViewMore onClick={() => handleClick("/world-carnival")} />
          </>
        )
      )}
      {classifieds.length > 0 && (
        <Label label={"Carnival Classifieds"} size="lg" />
      )}
      <Grid container rowSpacing={4} columnSpacing={2}>
        {classifiedsIsLoading
          ? Array.from({ length: 3 }, (_, index) => {
              return (
                <Grid key={index} item xs={12} md={4}>
                  <ClassifieldsLoader />
                </Grid>
              );
            })
          : classifieds?.length > 0 &&
            classifieds?.map((item, index) => {
              const user = { ...item?.user, rating: item?.rating };
              return (
                <Grid key={index} item xs={12} md={6}>
                  <ClassifiedCard
                    data={item}
                    key={index}
                    onMessageClick={() => handleChat(user)}
                    onClick={() =>
                      handleDetail(`/carnival/classifieds/${item?.slug}`)
                    }
                    shareLink={window.location.href.split("?")[0]}
                    onChipClick={(i) => handleNavigate({ tag: i })}
                  />
                </Grid>
              );
            })}
        {classifieds?.length > 0 && (
          <ViewMore
            viewMoreOrFilter={true}
            onClick={() => handleClick("/carnival/classifieds")}
          />
        )}
      </Grid>
      {carnivalsByLocationIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        carnivalByLocation.length > 0 && (
          <Label label={"All Carnivals"} size="md" />
        )
      )}
      {carnivalsByLocationIsLoading ? (
        <div className={CarnivalHomeCss.mgb}>
          <Grid container rowSpacing={4} columnSpacing={2}>
            {Array.from({ length: 6 }, (_, index) => {
              return (
                <Grid key={index} item xs={12} md={4}>
                  <div className={CarnivalHomeCss.flexDrR}>
                    <PhotoCardLoader rounded={false} />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        carnivalByLocation.length > 0 && (
          <>
            <Grid container rowSpacing={4} columnSpacing={2}>
              {carnivalByLocation?.map((item, index) => {
                const location = LocationExtractor(item?.location);
                return (
                  <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                    <Card
                      imageProps={{ height: width > 431 ? 208 : "auto" }}
                      title={item.title}
                      imageSrc={item.poster_url}
                      content={
                        <div className={CarnivalHomeCss.subTitle}>
                          {location}
                        </div>
                      }
                      onClick={() => handleClick(`/carnival/${item.slug}`)}
                      textAlign={"left"}
                      textColor={"#FF0000"}
                      size={"md"}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <ViewMore onClick={() => handleClick("/carnivals")} />
          </>
        )
      )}
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CarnivalHome);
