import { Grid } from "@mui/material";
import React from "react";
import Card from "../../components/card/Card";
import { LocationOn } from "@mui/icons-material";
import { FaCalendarAlt } from "react-icons/fa";
import FlatListCss from "./Flatlist.module.css";
import { useNavigate } from "react-router";
import { LocationExtractor } from "../location-extractor/LocationExtractor";
import { ExtractCarnivalDate } from "../date-extractor/ExtractDate";
export default function CarnivalList({ gallery }) {
  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };
  return (
    <div className={FlatListCss.songListDiv}>
      {gallery.map((item, index) => {
        const isString = typeof item?.location === "string";
        return (
          <Card
            key={index}
            title={item.title}
            imageSrc={item.imageSrc || item.poster_url}
            content={
              <div className={FlatListCss.flexDrC}>
                {item?.location && (
                  <div className={FlatListCss.subText}>
                    <LocationOn className={FlatListCss.locationIcon} />{" "}
                    {isString
                      ? item?.location
                      : LocationExtractor(item?.location)}
                  </div>
                )}
                <div className={FlatListCss.subText}>
                  <FaCalendarAlt className={FlatListCss.calendarIcon} />{" "}
                  {item.date || ExtractCarnivalDate(item.start_datetime)}
                </div>
              </div>
            }
            textAlign={"left"}
            onClick={() => handleClick(`/carnival/${item.slug}`)}
            showAnimation={true}
            textColor={"#FF0000"}
            size={"md"}
          />
        );
      })}
    </div>
  );
}
