import * as React from "react";

import { Callback, RenderFunction } from "yet-another-react-lightbox";

import { Comments } from "./Comments.tsx";

// const PLUGIN_COMMENTS = "comments";

declare module "../types.js" {
  // noinspection JSUnusedGlobalSymbols
  interface GenericSlide {
    /** slide title */
    title?: React.ReactNode;
    /** slide description */
    description?: React.ReactNode;
  }

  // noinspection JSUnusedGlobalSymbols
  interface ToolbarButtonKeys {
    ["comments"]: null;
  }

  interface LightboxProps {
    /** Comments plugin settings */
    comments?: {
      /** Comments plugin ref */
      ref?: React.ForwardedRef<CommentsRef>;

      onButtonClick?: Callback;

      onLike?: Callback;

      onReply?: Callback;

      getComments?: Callback;

      handleDelete?: Callback;

      isLoggedIn?: boolean;

      isDeleting?: boolean;

      isCommentsLoading?: boolean;

      showLoadMore?:boolean;

      moreCommentsLoading?: boolean;

      updatePhotoCommentPageNumber?: Callback;

      totalComments?:number;

      comments?: Array<Comments>;
    };
  }

  // noinspection JSUnusedGlobalSymbols
  interface SlotType {
    /** comments title customization slot */
    commentsTitle: "commentsTitle";
    /** comments title container customization slot */
    commentsTitleContainer: "commentsTitleContainer";
    /** comments description customization slot */
    commentsDescription: "commentsDescription";
    /** comments description container customization slot */
    commentsDescriptionContainer: "commentsDescriptionContainer";
  }

  // noinspection JSUnusedGlobalSymbols
  interface Render {
    /** render custom Comments Visible icon */
    iconCommentsVisible?: RenderFunction;
    /** render custom Comments Hidden icon */
    iconCommentsHidden?: RenderFunction;
    /** render custom Comments button */
    buttonComments?: RenderFunction<CommentsRef>;
  }

  interface Comments {
    user: object;
    body_text: string;
    profile_photo_url: string;
    replies: Array<Comments>;
    liked: boolean;
    like_count: number;
  }

  /** Comments plugin ref */
  interface CommentsRef {
    /** if `true`, comments are visible */
    visible: boolean;
    /** show comments */
    show: Callback;
    /** hide comments */
    hide: Callback;
  }
}

export default Comments;
