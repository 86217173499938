import React from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import NoData from "../../elements/no-data/NoData";

export default function NoDataScreen({errorCode}) {
  return (
    <NavContainer>
      <NoData errorCode={errorCode}/>
    </NavContainer>
  );
}
