import React, { useState, useEffect } from 'react'
import loaderCss from "./loader.module.css";
import { Skeleton } from "@mui/material";
import { CardSizes } from '../../config';

export default function SongLoader() {
    const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={loaderCss.flexDrR}>
    {Array.from({ length: 4 }, () => (
      <div
        style={{ width: width < 431 ? "100%" : CardSizes.lg }}
        className={loaderCss.flexDrR}
      >
        <Skeleton
          sx={{ bgcolor: "grey.900", minWidth: "80px" }}
          animation="wave"
          variant="rectangular"
          width={80}
          height={80}
        />
        <div style={{ width: width < 431 ? "70%" : "80%" }}>
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={"100%"}
          />
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={"80%"}
          />
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={"40%"}
          />
        </div>
      </div>
    ))}
  </div>
  )
}
