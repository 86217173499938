import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import AccountCss from "./AccountsCss.module.css";
import Label from "../../elements/label/Label";
import Button from "../../components/button/Button";
import { Delete, Edit } from "@mui/icons-material";
import IconButton from "../../components/icon-button/IconButton";
import { Modal, Skeleton } from "@mui/material";
import { Context } from "../../Context";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip";
import MetaTags from "../../MetaTags";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SongsList from "../../components/songs-list/SongsList";
import SongLoader from "../../elements/skeletonLoaders/SongLoader";
import { connect, useDispatch, useSelector } from "react-redux";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { selectMusic, setMusic } from "../../slices/MusicReducer.js";
import { addToQueue } from "../../actions.js";
import EditItinerary from "./elements/EditItinerary.js";
import Swal from "sweetalert2";
import { useLoggedInState } from "../../elements/logged-in-state/useLoggedInState.js";
import NoDataScreen from "../no-data/NoDataScreen.js";
import PlaylistList from "../../elements/Flatlist/PlaylistList.js";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader";
import Unauthorized from "../no-data/Unauthorized.js";
import ReactGA from "react-ga4";

function SongsPlaylistDetail({ user }) {
  const { slug } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recommendedSongs, setRecommendedSongs] = useState(null);
  const [recommendedSongsIsLoading, setRecommendedSongsIsLoading] =
    useState(null);
  const isLoggedIn = useLoggedInState();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setPageTitle } = useContext(Context);
  const [tempId, setTempId] = useState(null);
  const [details, setDetails] = useState(null);
  const dispatch = useDispatch();
  const [currentSong, setCurrentSong] = useState(null);
  const music = useSelector(selectMusic)[0];
  const [slugUrl, setSlugUrl] = useState(null);
  const [status, setStatus] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    if (tempId) {
      ReactGA.send({
        hitType: "pageview",
        page: `/my-songs-playlist/${tempId}/${slug}`,
        title: "My Songs Playlist Detail",
      });
    }
  }, [tempId]);

  useEffect(() => {
    if (!music?.isPlaying) {
      setCurrentSong(null);
    } else {
      setCurrentSong(music?.musicArgs);
    }
  }, [music]);

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      const url = pathname?.split("/")[3];
      const id = u?.split("-")[2];
      setTempId(id);
      setSlugUrl(`${SERVER_URL.GET_USER_SONG_PLAYLISTS_DETAIL}${url}`);
    }
  }, [pathname]);

  useEffect(() => {
    if (slugUrl && isAuthorized) {
      handleGetDetails();
    }
  }, [slugUrl, isAuthorized]);

  useEffect(() => {
    if (isAuthorized) {
      if (details) {
        setPageTitle(details?.title);
      } else {
        setPageTitle("Playlist Details");
      }
    }
  }, [details, isAuthorized]);

  useEffect(() => {
    if (tempId && user?.id) {
      const authorize = parseInt(tempId) === parseInt(user?.id);
      setIsAuthorized(authorize);
    }
  }, [tempId, user]);

  const handleGetDetails = async () => {
    try {
      const res = await axios.get(slugUrl, {
        headers: {
          Accept: "application/json",
        },
      });
      setStatus(res.status);
      if (res.status === 200) {
        const playlist = {
          ...res?.data,
          songs: res?.data?.songs?.map((item) => {
            const genre = item?.album?.genres?.map(
              (i, index) =>
                `${i?.title}${
                  index !== item?.album?.genres?.length - 1 ? ", " : ""
                }`
            );
            return {
              ...item,
              subTitle: item?.album?.title,
              thumbnail: item?.album?.cover_image_url,
              footerText: genre,
              hlsSource: item?.media_url,
            };
          }),
        };
        if (playlist) {
          setDetails(playlist);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setStatus(err.response.status);
      setIsLoading(false);
    }
  };

  const handleEditSongPlaylist = async (id, body) => {
    try {
      const res = await axios.put(
        `${SERVER_URL.EDIT_SONG_PLAYLISTS}${id}/playlists`,
        body,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Edit playlist button clicked",
          label: "A playlist has been edited on songs playlist details page",
        });
        setIsLoading(true);
        handleGetDetails();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to edit the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteSongPlaylist = async (id) => {
    try {
      const res = await axios.delete(
        `${SERVER_URL.DELETE_SONG_PLAYLISTS}${id}/playlists`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Delete playlist button clicked",
          label: "A playlist has been delete from songs playlist details page",
        });
        setIsLoading(true);
        handleClick(`/my-songs-playlist/${user?.slug}`);
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteFromSongPlaylist = async (id, songId) => {
    const body = {
      playlist_id: id,
      song: songId,
    };
    try {
      const res = await axios.post(
        `${SERVER_URL.DELETE_SONG_FROM_PLAYLISTS}`,
        body,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Playlist",
          action: "Delete song from playlist button clicked",
          label:
            "A song has been delete from a playlist on songs playlist details page",
        });
        setIsLoading(true);
        handleGetDetails();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete from the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Song Playlist's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `${label} playlist Shared on ${item}`,
      label: label,
    });
  }

  function handleAddToQueue(data) {
    ReactGA.event({
      category: "Queue",
      action: `Add to queue button clicked`,
      label: `Songs added to the queue`,
    });
    if (Array.isArray(data)) {
      data?.map((item) => {
        const genre = item?.album?.genres?.map(
          (i, index) =>
            `${i?.title}${
              index !== item?.album?.genres?.length - 1 ? ", " : ""
            }`
        );
        const d = {
          ...item,
          subTitle: item?.album?.title,
          thumbnail: item?.album?.cover_image_url,
          footerText: genre[0],
          hlsSource: item?.media_url,
        };
        dispatch(addToQueue(d));
      });
    } else {
      const genre = data?.album?.genres?.map(
        (i, index) =>
          `${i?.title}${index !== data?.album?.genres?.length - 1 ? ", " : ""}`
      );
      const d = {
        ...data,
        subTitle: data?.album?.title,
        thumbnail: data?.album?.cover_image_url,
        footerText: genre[0],
        hlsSource: data?.media_url,
      };
      dispatch(addToQueue(d));
    }
  }

  const StreamMusic = async (id) => {
    try {
      const res = await axios.get(`${SERVER_URL.STREAM_MUSIC}${id}`, {
        headers: {
          Accept: "application/json",
        },
      });
    } catch (err) {}
  };

  const handlePlay = (data) => {
    ReactGA.event({
      category: "Play",
      action: `Play Song Clicked`,
      label: `${data?.title} is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play Song: ${data?.title}`,
      label: `${data?.title} is being played`,
    });
    setCurrentSong(data);
    const genre = data?.album?.genres?.map(
      (i, index) =>
        `${i?.title}${index !== data?.album?.genres?.length - 1 ? ", " : ""}`
    );
    const d = {
      isPlaying: true,
      musicArgs: {
        id: data?.id,
        title: data?.title,
        subTitle: data?.album?.title,
        hlsSource: data?.media_url,
        thumbnail: data?.album?.cover_image_url,
        footerText: genre[0],
        duration: data?.duration,
        artist_id: data?.album?.artists[0]?.id,
        isLiked: data?.is_liked,
      },
    };
    StreamMusic(data?.id);
    dispatch(setMusic(d));
  };

  const handleFeaturedPlaylists = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_FEATURED_PLAYLIST, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.FEATURED_VIDEOS_HOME,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setRecommendedSongs(res?.data?.data);
        }
        setRecommendedSongsIsLoading(false);
      }
    } catch (err) {
      setRecommendedSongsIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthorized) {
      handleFeaturedPlaylists();
    }
  }, [isAuthorized]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDownload = async (song) => {
    if (!song?.media_url) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const response = await fetch(song.media_url);
      if (!response.ok) {
        Swal.fire({
          title: "Unable to download the song",
          icon: "error",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const fileExtension = song?.media_url?.split(".").pop(); // Assuming the extension is present in the URL
      const fileName =
        song?.title?.replace(/\s+/g, "-").toLowerCase() + "." + fileExtension;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      ReactGA.event({
        category: "Download",
        action: "Download button clicked",
        label: fileName,
      });
      ReactGA.event({
        category: "Download",
        action: `${fileName} is downloaded`,
        label: fileName,
      });
    } catch (error) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  if (isAuthorized === false) {
    return <Unauthorized />;
  }

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={details?.title}
        url={window.location.href}
        type={"website"}
        image="../../assets/images/tjj-logo.jpg"
      />
      <div className={AccountCss.mainDiv}>
        <Label label={details?.title} size="lg" />
        {isLoggedIn &&
          parseInt(tempId) === user?.id &&
          (width < 500 ? (
            <div className={AccountCss.buttonDiv}>
              <SharingTooltip
                onShare={handleShare}
                transparent
                link={`https://${window.location.href?.split("/")[2]}/music/playlist/${details?.slug}`}
              />
              <IconButton
                backgroundColor={"transparent"}
                onClick={() => {
                  setOpen(true);
                }}
                icon={<Edit className={AccountCss.closeIcon} />}
              />
              <IconButton
                backgroundColor={"transparent"}
                icon={<Delete className={AccountCss.closeIcon} />}
                onClick={() => handleDeleteSongPlaylist(details?.id)}
              />
            </div>
          ) : (
            <div className={AccountCss.buttonDiv}>
              <SharingTooltip
                onShare={handleShare}
                withText
                showAnimation={true}
                backgroundColor={"#1C1C1C"}
                hoverColor={"#ff0000"}
                link={`https://${window.location.href?.split("/")[2]}/music/playlist/${details?.slug}`}
              />
              <Button
                label="Edit"
                showAnimation={true}
                backgroundColor={"#1C1C1C"}
                hoverColor={"#ff0000"}
                icon={<Edit className={AccountCss.closeIcon} />}
                iconLeft={true}
                onClick={() => {
                  setOpen(true);
                }}
              />
              <Button
                label="Delete"
                showAnimation={true}
                backgroundColor={"#1C1C1C"}
                hoverColor={"#ff0000"}
                icon={<Delete className={AccountCss.closeIcon} />}
                iconLeft={true}
                onClick={() => handleDeleteSongPlaylist(details?.id)}
              />
            </div>
          ))}
        {open && (
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            className={AccountCss.modalDiv}
          >
            <EditItinerary
              onClick={(i) => handleEditSongPlaylist(details?.id, i)}
              setOpen={(bool) => setOpen(bool)}
              name={details?.title}
              itineraryDescription={details?.description}
              isPlaylist={true}
            />
          </Modal>
        )}
      </div>
      <div className={AccountCss.mainDiv}>
        <div className={AccountCss.description}>{details?.description}</div>
      </div>
      <div className={AccountCss.divMain}>
        {isLoading ? (
          Array.from({ length: 4 }, () => (
            <div className={AccountCss.mgb}>
              <SongLoader />
            </div>
          ))
        ) : (
          <div className={AccountCss.mgb}>
            {details && details?.songs?.length !== 0 && (
              <>
                <SongsList
                  showGrid={true}
                  onClick={handlePlay}
                  handleToAdd={handleAddToQueue}
                  songArray={details?.songs}
                  currentSongItem={currentSong}
                  showDelete={isLoggedIn && parseInt(tempId) === user?.id}
                  handleDelete={(i) =>
                    handleDeleteFromSongPlaylist(details?.id, i)
                  }
                  handleDownload={handleDownload}
                />
              </>
            )}
          </div>
        )}
      </div>
      {recommendedSongsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 3 }}
          animation="wave"
          variant="text"
          width={width > 431 ? "30%" : "40%"}
          height={50}
        />
      ) : (
        recommendedSongs &&
        recommendedSongs?.length !== 0 && (
          <Label label={"Our Top Recommendations"} size="md" />
        )
      )}
      {recommendedSongsIsLoading ? (
        <>
          <div className={AccountCss.albumsDiv}>
            {Array.from({ length: 4 }, () => (
              <div className={AccountCss.albumsSubDiv}>
                <MusicCardLoader music={true} />
              </div>
            ))}
          </div>
          <div className={AccountCss.mgb} />
        </>
      ) : (
        recommendedSongs && (
          <>
            <div className={AccountCss.albumsDiv}>
              <PlaylistList playlist={recommendedSongs} />
            </div>
          </>
        )
      )}
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps)(SongsPlaylistDetail);
