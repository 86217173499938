import React, { useEffect, useState } from "react";
import {
  InputAdornment,
  TextField as MuiTextField,
  IconButton,
  MenuItem,
} from "@mui/material";
import "./TextField.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FontSizes } from "../../config";

export default function TextField({
  onChange = undefined,
  label = null,
  size = "small",
  defaultValue = "",
  type = "text",
  data = null,
  error = false,
  placeholder = undefined,
  multiline = false,
  rows = undefined,
  disabled = false,
  onKeyDown = undefined,
}) {
  const [value, setValue] = useState("");
  const [count, setCount] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const maxValue = 11;

  const color = error ? "#ff0000" : "#fff";

  const textfieldCss = {
    "& .MuiInputBase-root": {
      minHeight: 36.13,
      color: "#fff !important",
      fontSize: 14,
    },
    "& .MuiInputBase-root input": {
      color: "#fff !important",
      paddingRight: "50px",
      fontSize: FontSizes.body,
      fontFamily: "Inter-Regular",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#fff",
      paddingRight: "50px",
      fontSize: FontSizes.body,
      fontFamily: "Inter-Regular",
      WebkitTextFillColor: "#fff",
    },
    // "& .MuiInputBase-root input:-webkit-autofill": {
    //   backgroundColor: 'transparent !important',
    //   WebkitBoxShadow: "0 0 0 1000px #000 inset",
    //   color:"#fff !important",
    //   WebkitTextFillColor: '#fff'
    // },
    "& .MuiFormLabel-root": {
      color: color,
      fontSize: 14,
      fontFamily: "Inter-Regular",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: color,
      fontSize: 14,
      fontFamily: "Inter-Regular",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: color,
      fontSize: 14,
      fontFamily: "Inter-Regular",
    },
    "& .MuiButtonBase-root": {
      color: color,
      fontSize: FontSizes.smallText,
      fontFamily: "Inter-Regular",
      fontWeight: "400",
    },
    "& .MuiButtonBase-root svg": {
      color: color,
      fontSize: FontSizes.heading,
    },

    "& .MuiSelect-icon": {
      color: error ? "#ff0000 !important" : "#fff !important",
    },

    "& .MuiOutlinedInput-root fieldset": {
      borderColor: error ? "#ff0000 !important" : "#fff !important",
    },

    "& .MuiOutlinedInput-root fieldset legend span": {
      fontSize: "11px",
    },
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #000 inset",
      WebkitTextFillColor: "#fff",
    },
  };

  const handleOnChange = (item) => {
    if (type !== "number") {
      setValue(item);
      if (onChange) {
        onChange(item);
      }
    } else {
      if (count < maxValue) {
        setCount((prev) => prev + 1);
        setValue(item);
        if (onChange) {
          onChange(item);
        }
      }
    }
  };

  return type === "password" ? (
    <MuiTextField
      sx={textfieldCss}
      fullWidth
      id="outlined-helperText"
      label={label}
      onChange={(event) => handleOnChange(event.target.value)}
      value={value}
      size={size}
      disabled={disabled}
      type={showPassword ? "text" : "password"}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  ) : type === "select" ? (
    <MuiTextField
      sx={textfieldCss}
      fullWidth
      id="outlined-helperText"
      label={label}
      disabled={disabled}
      onChange={(event) => handleOnChange(event.target.value)}
      value={value}
      size={size}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      select
    >
      {data?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MuiTextField>
  ) : (
    <MuiTextField
      sx={textfieldCss}
      fullWidth
      id="outlined-helperText"
      label={label}
      onChange={(event) => handleOnChange(event.target.value)}
      value={value}
      size={size}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      multiline={multiline}
      onKeyDown={onKeyDown}
      rows={rows}
    />
  );
}
