import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Skeleton, Typography } from "@mui/material";
import TableSortLabel, {
  tableSortLabelClasses,
} from "@mui/material/TableSortLabel";
import { FontSizes } from "../../config";
import Button from "../../components/button/Button";
import Checkbox from "@mui/material/Checkbox";
import AccountsCss from "./AccountsCss.module.css";
import Chip from "../../components/chip/Chip";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import { visuallyHidden } from "@mui/utils";
import { SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { Context } from "../../Context.js";
import Unauthorized from "../no-data/Unauthorized.js";
import ReactGA from "react-ga4";

const tagsArray = ["promoters", "events", "carnivals", "artists"];

const orders = {
  promoters: "first_name",
  events: "title",
  carnivals: "title",
  artists: "first_name",
};

function Subscriptions({ user }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#515151",
      color: "#fff",
      fontSize: FontSizes.heading,
      fontWeight: "700",
      fontFamily: "Inter-Bold",
      border: "1px solid #636363",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: FontSizes.body,
      fontWeight: "500",
      fontFamily: "Inter-Medium",
      color: "#fff",
      border: "1px solid #636363",
    },
  }));
  const StyledTableLabel = styled(TableSortLabel)(({ theme }) => ({
    [`&.${tableSortLabelClasses.active}`]: {
      color: "#fff !important",
      fontSize: FontSizes.heading,
      fontWeight: "700",
      fontFamily: "Inter-Bold",
    },
    [`&.${tableSortLabelClasses.active} .${tableSortLabelClasses.icon}`]: {
      color: "#fff !important",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#202020",
      border: "1px solid #636363",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#363636",
      border: "1px solid #636363",
      borderRight: 0,
    },
    // hide last border
  }));

  const [selectedChip, setSelectedChip] = useState("promoters");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("first_name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [url, setUrl] = useState(null);
  const [unsubscribeUrl, setUnsubscribeUrl] = useState(null);
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(null);
  const { setPageTitle } = useContext(Context);
  const [tempId, setTempId] = useState(null);

  useEffect(() => {
    if (tempId) {
      ReactGA.send({
        hitType: "pageview",
        page: `/subscriptions/${tempId}`,
        title: "Subscriptions",
      });
    }
  }, [tempId]);

  const getSubscriptionLists = async () => {
    try {
      const res = await axios.get(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        setIsLoading(false);
        if (res?.data && res?.data?.data) {
          setRows(res?.data?.data);
        } else {
          setRows([]);
        }
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (url && isAuthorized) {
      getSubscriptionLists();
    }
  }, [url, isAuthorized]);

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      const id = u?.split("-")[2];
      setTempId(id);
    }
  }, [pathname]);

  useEffect(() => {
    if (selectedChip) {
      setIsLoading(true);
      setSelected([]);
      setOrderBy(orders[selectedChip]);
      if (selectedChip === "promoters") {
        setUrl(SERVER_URL.PROMOTERS_SUBSCRIPTIONS);
        setUnsubscribeUrl(SERVER_URL.PROMOTERS_UNSUBSCRIBE);
      }
      if (selectedChip === "events") {
        setUrl(SERVER_URL.EVENTS_SUBSCRIPTIONS);
        setUnsubscribeUrl(SERVER_URL.EVENTS_UNSUBSCRIBE);
      }
      if (selectedChip === "carnivals") {
        setUrl(SERVER_URL.CARNIVALS_SUBSCRIPTIONS);
        setUnsubscribeUrl(SERVER_URL.CARNIVALS_UNSUBSCRIBE);
      }
      if (selectedChip === "artists") {
        setUrl(SERVER_URL.ARTISTS_SUBSCRIPTIONS);
        setUnsubscribeUrl(SERVER_URL.ARTISTS_UNSUBSCRIBE);
      }
    }
  }, [selectedChip]);

  useEffect(() => {
    if (isAuthorized) {
      setPageTitle("Subscriptions");
    }
  }, [isAuthorized]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleUnsubscribe = async (id) => {
    let body = {};
    if (selectedChip === "artists") {
      body = {
        artist_ids: id ? [id] : selected,
      };
    }
    if (selectedChip === "events") {
      body = {
        event_ids: id ? [id] : selected,
      };
    }
    if (selectedChip === "carnivals") {
      body = {
        carnival_ids: id ? [id] : selected,
      };
    }
    if (selectedChip === "promoters") {
      body = {
        promoter_ids: id ? [id] : selected,
      };
    }

    try {
      const res = await axios.post(unsubscribeUrl, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        ReactGA.event({
          category: "Subscribe",
          action: `Unsubscribe button clicked`,
          label: `${selectedChip} is unsubscribed fron subscriptions page`,
        });
        setIsLoading(true);
        getSubscriptionLists();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to unsubscribe",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChange = (item) => {
    setSelectedChip(item);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            backgroundColor: "#2B2B2B",
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="white"
            variant="subtitle1"
            component="div"
            fontSize={FontSizes.body}
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
            color="white"
            fontSize={FontSizes.body}
          >
            List
          </Typography>
        )}

        {numSelected > 1 ? (
          <Tooltip title="Unsubscribe">
            <Button
              label="Unsubscribe"
              backgroundColor={"transparent"}
              color={"black"}
              styles={{ backgroundColor: "red", borderRadius: "5px" }}
              onClick={() => handleUnsubscribe()}
            />
          </Tooltip>
        ) : null}
      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <StyledTableRow>
          <StyledTableCell
            align="center"
            style={{ paddingLeft: 10, width: 50 }}
          >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all inputs",
              }}
              style={{
                color: "white",
              }}
            />
          </StyledTableCell>
          <StyledTableCell
            align="center"
            colSpan={1}
            sortDirection={orderBy === orders[selectedChip] ? order : false}
          >
            <StyledTableLabel
              active={orderBy === orders[selectedChip]}
              direction={orderBy === orders[selectedChip] ? order : "asc"}
              onClick={createSortHandler(orders[selectedChip])}
            >
              {selectedChip &&
                selectedChip[0].toUpperCase() + selectedChip.slice(1)}
              &nbsp;Name
              {orderBy === orders[selectedChip] ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableLabel>
          </StyledTableCell>
          <StyledTableCell
            align="center"
            style={{ paddingLeft: 10, width: 250 }}
          >
            Actions
          </StyledTableCell>
        </StyledTableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  useEffect(() => {
    if (tempId && user?.id) {
      const authorize = parseInt(tempId) === parseInt(user?.id);
      setIsAuthorized(authorize);
    }
  }, [tempId, user]);

  if (isAuthorized === false) {
    return <Unauthorized />;
  }

  return (
    <NavContainer>
      <div>
        <Label
          size="lg"
          label={`Subscription - List of ${
            selectedChip &&
            selectedChip[0].toUpperCase() + selectedChip.slice(1)
          }`}
        />
        <div className={AccountsCss.chipsArray}>
          {tagsArray.map((item, index) => {
            return (
              <Chip
                label={item}
                size={"md"}
                backgroundColor={selectedChip === item ? "#ff0000" : ""}
                handleClick={() => handleChange(item)}
                key={index}
              />
            );
          })}
        </div>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2, backgroundColor: "#2B2B2B" }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {isLoading ? (
                    Array.from({ length: 4 }, (_, index) => {
                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          // key={row.id}
                          key={index}
                          sx={{ cursor: "pointer" }}
                        >
                          <StyledTableCell align="center">
                            <Checkbox
                              style={{
                                color: "white",
                              }}
                              checked={false}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="left" scope="row">
                            <Skeleton
                              sx={{ bgcolor: "grey.700" }}
                              animation="wave"
                              variant="text"
                              width={"80%"}
                              height={30}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {" "}
                            <Skeleton
                              sx={{ bgcolor: "grey.700" }}
                              animation="wave"
                              variant="text"
                              width={"80%"}
                              height={30}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : visibleRows?.length === 0 ? (
                    <StyledTableCell align="center" colSpan={3} scope="row">
                      No {selectedChip} subscribed yet
                    </StyledTableCell>
                  ) : (
                    visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          // key={row.id}
                          key={row.name}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <StyledTableCell
                            align="center"
                            onClick={(event) => handleClick(event, row.id)}
                          >
                            <Checkbox
                              style={{
                                color: "white",
                              }}
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            // component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            {selectedChip === "artists" ||
                            selectedChip === "promoters"
                              ? `${row?.first_name} ${row?.last_name}`
                              : row?.title}
                          </StyledTableCell>
                          {/* <StyledTableCell align="left" /> */}
                          <StyledTableCell
                            align="center"
                            style={{ color: "red" }}
                          >
                            <Button
                              label="Unsubscribe"
                              backgroundColor={"transparent"}
                              color={"red"}
                              onClick={() => handleUnsubscribe(row?.id)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{
                color: "white",
                fontSize: FontSizes.body,
                "& .MuiTablePagination-selectLabel": {
                  fontSize: FontSizes.body,
                },
                "& .MuiTablePagination-displayedRows": {
                  fontSize: FontSizes.body,
                },
                "& .MuiSvgIcon-root": {
                  fontSize: FontSizes.body,
                  color: "white",
                },
              }}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Subscriptions);
