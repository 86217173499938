import React from "react";
import NotificationsCardCss from "./NotificationsCard.module.css";

export default function NotificationsCard({ data = {}, onClick = undefined }) {
  return (
    <div onClick={onClick} className={NotificationsCardCss.inboxCardMainDiv}>
      <div className={NotificationsCardCss.flex}>
        <img
          className={NotificationsCardCss.inboxCardAvatar}
          src={data?.notification_icon}
          alt="img"
        />
        <div className={NotificationsCardCss.inboxCardDiv2}>
          <div className={NotificationsCardCss.inboxCardUsername}>
            {data?.title}
          </div>
          {data?.message && (
            <div className={NotificationsCardCss.inboxCardText}>
              {data?.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
