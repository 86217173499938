import React from "react";
import loaderCss from "./loader.module.css";
import { Skeleton } from "@mui/material";
export default function ChipsLoader({ numberOfChips }) {
  return (
    <div>
      <div className={loaderCss.chips}>
        {Array.from({ length: numberOfChips }, (_, index) => (
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="text"
            width={50}
            key={index}
            height={40}
          />
        ))}
      </div>
    </div>
  );
}
