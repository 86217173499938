import React, { useEffect, useState } from "react";
import FlatListCss from "./Flatlist.module.css";
import { CardSizes } from "../../config";
import { navigate, useNavigate } from "react-router-dom";
export default function GenreList({ genres, onClick }) {
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={FlatListCss.songListDiv}>
      {genres?.map((item, index) => {
        return (
          <div
            onClick={() => {
              navigate("/music/albums/all?genre=" + item.slug);
              window.scrollTo(0, 0);
            }}
            key={index}
            style={
              item?.poster_image_url && item?.poster_image_url !== null
                ? {
                    backgroundImage: `url(${item?.poster_image_url})`,
                    backgroundPosition: "center",
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    width: width < 431 ? "46%" : CardSizes.sm,
                  }
                : {
                    background: `linear-gradient(180deg, #75E2BB, #ACB177)`,
                    width: width < 431 ? "46%" : CardSizes.sm,
                  }
            }
            className={FlatListCss.genreCard}
          >
            {item?.title}
          </div>
        );
      })}
    </div>
  );
}
