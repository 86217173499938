import * as React from "react";

import {
  createIcon,
  IconButton,
  useLightboxProps,
  useLightboxState,
} from "yet-another-react-lightbox";
// import { useLikes } from "./LikesContext.tsx";

const unlikeIcon = () => (
  <>
    <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3m-4.4 15.55-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05"></path>
  </>
);
const likeIcon = () => (
  <>
    <path d="m12 21.35-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54z"></path>
  </>
);

const like = createIcon("LikesVisible", likeIcon());

const unlike = createIcon("LikesVisible", unlikeIcon());

export function LikesButton({ onClickHandler, isLoggedIn, likedState, setLikedState }) {
  // const { visible, show, hide } = useLikes();
  // const { render } = useLightboxProps();
  // const [likeState, setLikeState] = React.useState(false);
  // const [likeCount, setLikeCount] = React.useState(0);
  const { currentSlide, currentIndex } = useLightboxState();

  React.useEffect(() => {
    // if (currentSlide?.liked) {
    //   setLikedState(currentSlide?.liked)
    // }
    // if (currentSlide?.likeCount) {
    //   setLikeCount(currentSlide?.likeCount)
    // }
    if (currentSlide) {
      if (!likedState[currentSlide?.id]) {
        setLikedState((prevState) => ({
          ...prevState,
          [currentSlide?.id]: { likeCount: currentSlide?.likeCount, liked: currentSlide?.liked },
        }));
      }
    }
  }, [currentSlide])

  function updateLikeState(item) {
    if (isLoggedIn) {
      // setLikeState(item);
      // if (item) {
      //   setLikeCount(likeCount + 1)
      // }
      // else {
      //   setLikeCount(likeCount - 1)
      // }
      setLikedState((prevState) => {
        const updatedSlide = { ...prevState[item] };
        if (updatedSlide.liked) {
          updatedSlide.likeCount -= 1;
        } else {
          updatedSlide.likeCount += 1;
        }
        updatedSlide.liked = !updatedSlide.liked;

        return { ...prevState, [item]: updatedSlide };
      });
    }
  }

  return (
    <>
      <IconButton
        label={"Likes Count"}
        icon={likedState[currentSlide?.id]?.liked ? like : unlike}
        onClick={() => {
          onClickHandler(currentSlide?.id);
          updateLikeState(currentSlide?.id);
        }}
      />
      <div
        style={{
          color: "#fff",
          fontSize: 14,
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {likedState[currentSlide?.id]?.likeCount}
      </div>
    </>
  );
}
