import * as React from "react";

import { addToolbarButton, createModule } from "yet-another-react-lightbox";
import { CommentsButton } from "./CommentsButton.tsx";
import { CommentsContextProvider } from "./CommentsContext.tsx";
import { resolveCommentsProps } from "./props.ts";
import CommentsList from "./CommentsList.js";

const PLUGIN_COMMENTS = "comments";

/** Comments plugin */
export function Comments({ augment, addModule }) {
  const [visible, setVisible] = React.useState(false);

  augment(
    ({ comments: commentsProps, render: { slideFooter: renderFooter, ...restRender }, toolbar, ...restProps }) => {
      const comments = resolveCommentsProps(commentsProps);
      return {
        render: {
          slideFooter: ({ slide }) => (
            <>
              {renderFooter?.({ slide })}
              {visible ? <CommentsList
                isLoggedIn={comments?.isLoggedIn}
                onButtonClick={(i) => comments?.onButtonClick(i, slide?.id)}
                onLike={comments?.onLike}
                onReply={comments?.onReply}
                data={comments?.comments}
                isCommentsLoading={comments?.isCommentsLoading}
                moreCommentsLoading={comments?.moreCommentsLoading}
                updatePhotoCommentPageNumber={comments?.updatePhotoCommentPageNumber}
                showLoadMore={comments?.showLoadMore}
                handleDelete={(i) => comments?.handleDelete(i, slide?.id)}
                isDeleting={comments?.isDeleting} />
                : null}
            </>
          ),
          ...restRender,
        },
        toolbar: addToolbarButton(toolbar, PLUGIN_COMMENTS, comments.showToggle === true ? <CommentsButton commentsVisible={(i) => setVisible(i)} commentCount={comments?.totalComments} getComments={comments?.getComments} /> : null),
        comments,
        ...restProps,
      };
    },
  );

  addModule(createModule(PLUGIN_COMMENTS, CommentsContextProvider));
}
