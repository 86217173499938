import {
  SET_QUEUE,
  GET_QUEUE,
  ADD_TO_QUEUE,
  DELETE_FROM_QUEUE,
  ADD_ITEM_AT_ZERO_INDEX,
  MOVE_ITEM_TO_BOTTOM,
  MOVE_ITEM_TO_TOP,
  CLEAR_QUEUE,
} from "../ActionTypes";

const initialState = {
  queue: [],
};

const queueReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUEUE:
      return {
        ...state,
        queue: action.payload,
      };
    case ADD_TO_QUEUE:
      if (!state.queue.find((item) => item.id === action.payload.id)) {
        return {
          ...state,
          queue: [...state.queue, action.payload],
        };
      }
    case DELETE_FROM_QUEUE:
      return {
        ...state,
        queue: state.queue.filter((item) => item?.title !== action.payload),
      };
    case CLEAR_QUEUE:
      return {
        ...state,
        queue: [],
      };
    case ADD_ITEM_AT_ZERO_INDEX:
      if (!state.queue.find((item) => item.id === action.payload.id)) {
        return {
          ...state,
          queue: [action.payload, ...state.queue],
        };
      }
    case MOVE_ITEM_TO_TOP:
      const { payload: itemId } = action;
      const items = state.queue;
      const index = items.findIndex((item) => item.id === itemId);
      if (index === -1 || index === 0) {
        return state; // Item not found or already at the top
      }
      const itemToMove = items[index];
      const updatedItems = [
        itemToMove,
        ...items.slice(0, index),
        ...items.slice(index + 1),
      ];
      return {
        ...state,
        items: updatedItems,
      };
    case MOVE_ITEM_TO_BOTTOM:
      const { payload: bottomItemId } = action;
      const bottomItems = state.queue;
      const indexBottom = bottomItems.findIndex(
        (item) => item.id === bottomItemId
      );
      if (indexBottom === -1 || indexBottom === bottomItems.length - 1) {
        return state; // Item not found or already at the bottom
      }
      const itemToMoveBottom = bottomItems[indexBottom];
      const updatedItemsBottom = [
        ...bottomItems.slice(0, indexBottom),
        ...bottomItems.slice(indexBottom + 1),
        itemToMoveBottom,
      ];
      return {
        ...state,
        items: updatedItemsBottom,
      };
    case GET_QUEUE:
      return state;
    default:
      return state;
  }
};

export default queueReducer;
