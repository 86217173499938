import React from "react";
import CommentList from "../../components/comment/CommentList";
import "./comments.css";
import CommentLoader from "../../elements/skeletonLoaders/CommentLoader";
import ViewMore from "../../elements/view-more/ViewMore";

export default function CommentsList({
  isLoggedIn,
  onButtonClick,
  onLike,
  onReply,
  data,
  isCommentsLoading,
  moreCommentsLoading,
  updatePhotoCommentPageNumber,
  showLoadMore,
  handleDelete,
  isDeleting,
}) {
  return (
    <div className="comments">
      {isCommentsLoading ? (
        <div style={{ width: "100%" }}>
          <CommentLoader showAll={false} />
        </div>
      ) : (
        <>
          <CommentList
            isLoggedIn={isLoggedIn}
            onButtonClick={onButtonClick}
            onLike={onLike}
            onReply={onReply}
            data={data}
            handleDelete={handleDelete}
            isDeleting={isDeleting}
          />
          {moreCommentsLoading && (
            <div style={{ width: "100%" }}>
              <CommentLoader showAll={false} />
            </div>
          )}
          {showLoadMore && (
            <ViewMore loadMore onClick={updatePhotoCommentPageNumber} />
          )}
        </>
      )}
    </div>
  );
}
