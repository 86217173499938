import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchMusicFilters: {
    location: "",
    albumName: "",
    genre: "",
    artists: [],
    year: [],
  },
  searchArtistsFilters: {
    location: "",
    genre: "",
    artists: [],
    search: "",
  },
  searchNewslettersFilters: {
    authorName: "",
    year: [],
    startDate: "",
    endDate: "",
    search: "",
  },
  searchPhotosFilters: {
    location: "",
    carnivalName: "",
    eventName: "",
    year: [],
    startDate: "",
    endDate: "",
    search: "",
  },
  searchEventsFilters: {
    location: "",
    category: [],
    time: [],
    promoter: [],
    startDate: "",
    endDate: "",
    search: "",
  },
  searchCarnivalFilters: {
    location: "",
    year: [],
    startDate: "",
    endDate: "",
    search: "",
  },
  searchGenresFilters: {
    albumName: "",
    songName: "",
    artists: [],
    search: "",
  },
  searchAlbumsFilters: {
    songName: "",
    artists: [],
    year: [],
    search: "",
  },
  searchVideosFilters: {
    location: "",
    channelName: "",
    year: [],
    search: "",
  },
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchMusicFilters: (state, action) => {
      state.searchMusicFilters = [action.payload];
    },
    setSearchArtistsFilters: (state, action) => {
      state.searchArtistsFilters = [action.payload];
    },
    setSearchNewslettersFilters: (state, action) => {
      state.searchNewslettersFilters = [action.payload];
    },
    setSearchPhotosFilters: (state, action) => {
      state.searchPhotosFilters = [action.payload];
    },
    setSearchEventsFilters: (state, action) => {
      state.searchEventsFilters = [action.payload];
    },
    setSearchCarnivalFilters: (state, action) => {
      state.searchCarnivalFilters = [action.payload];
    },
    setSearchGenresFilters: (state, action) => {
      state.searchGenresFilters = [action.payload];
    },
    setSearchAlbumsFilters: (state, action) => {
      state.searchAlbumsFilters = [action.payload];
    },
    setSearchVideosFilters: (state, action) => {
      state.searchVideosFilters = [action.payload];
    },
  },
});

export const {
  setSearchAlbumsFilters,
  setSearchArtistsFilters,
  setSearchCarnivalFilters,
  setSearchEventsFilters,
  setSearchGenresFilters,
  setSearchMusicFilters,
  setSearchNewslettersFilters,
  setSearchPhotosFilters,
  setSearchVideosFilters,
} = searchSlice.actions;

export const selectSearchAlbumsFilters = (state) =>
  state.search.searchAlbumsFilters;
export const selectSearchMusicFilters = (state) =>
  state.search.searchMusicFilters;
export const selectSearchGenresFilters = (state) =>
  state.search.searchGenresFilters;
export const selectSearchVideosFilters = (state) =>
  state.search.searchVideosFilters;
export const selectSearchEventsFilters = (state) =>
  state.search.searchEventsFilters;
export const selectSearchCarnivalFilters = (state) =>
  state.search.searchCarnivalFilters;
export const selectSearchNewslettersFilters = (state) =>
  state.search.searchNewslettersFilters;
export const selectSearchPhotosFilters = (state) =>
  state.search.searchPhotosFilters;
export const selectSearchArtistsFilters = (state) =>
  state.search.searchArtistsFilters;

export default searchSlice.reducer;
