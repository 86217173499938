import React from "react";
import ReactPlayer from "react-player";

export default function VideoPlayer({
  videoProps = {
    height: 500,
    width: 500,
    autoPlay: false,
    loop: false,
    controls: false,
    url: "https://www.youtube.com/watch?v=VlVJjtid6r8",
  },
}) {
  return <ReactPlayer {...videoProps} />;
}
