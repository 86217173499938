import { createSlice } from "@reduxjs/toolkit";

const initialState = {
reviewLocation:"",
reviewTab:"",
reviewFilters:{
    location:"",
    category:[],
    type:[],
    year:[],
}
};

export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    setReviewLocation: (state, action) => {
      state.reviewLocation = [action.payload];
    },
    setReviewTab: (state, action) => {
      state.reviewTab = [action.payload];
    },
    setReviewFilters: (state, action) => {
      state.reviewFilters = [action.payload];
    },
  },
});

export const {
  setReviewLocation,
  setReviewTab,
  setReviewFilters
} = reviewSlice.actions;

export const selectReviewLocation = (state) => state.review.reviewLocation;
export const selectReviewTab = (state) => state.review.reviewTab;
export const selectReviewFilters = (state) => state.review.reviewFilters;

export default reviewSlice.reducer;
