import React, { useEffect, useRef, useState } from "react";
import { FontSizes } from "../../config";
import SongCardCss from "./SongCard.module.css";
import { Box, MenuItem, MenuList, Popover } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function SongCard({
  title = "",
  subTitle = "",
  footerText = "",
  titleColor = null,
  subTitleColor = null,
  footerTextColor = null,
  thumbnail = null,
  duration = "",
  queue = false,
  index = 0,
  length = 1,
  durationColor = null,
  onClick = undefined,
  grid = false,
  showPlayIcon = false,
  imageProps = {},
  titleFontSize = null,
  subTitleFontSize = null,
  footerTextFontSize = null,
  durationFontSize = null,
  handleRemove = undefined,
  handleToTop = undefined,
  handleToBottom = undefined,
  handleToAdd = undefined,
  showDelete = false,
  handleDelete = undefined,
  handleDownload = undefined,
  isDownloadable = false,
}) {
  const [width, setWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef();

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
    } else if (event.key === "Escape") {
      setAnchorEl(null);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = {
    imageMdDiv: {
      ...imageProps,
      width: imageProps?.width ?? 70,
      height: imageProps?.height ?? 70,
    },
  };

  return (
    <Box
      className={SongCardCss.songBox}
      sx={{
        width: "100%",
        borderBottom:
          index === length - 1 || (grid && index === length - 2)
            ? "1px solid #D9D9D9"
            : 0,
        ":hover": {
          cursor: !queue && onClick ? "pointer" : "auto",
        },
      }}
    >
      {queue && <div className={SongCardCss.queue} />}
      <Box
        className={SongCardCss.songSubBox}
        sx={{
          ":hover": {
            cursor: queue && onClick ? "pointer" : "auto",
          },
        }}
        onClick={onClick}
      >
        <div style={styles.imageMdDiv} className={SongCardCss.imageDiv}>
          <img
            src={thumbnail}
            alt="thumbnail"
            className={SongCardCss.thumbnail}
          />
          {showPlayIcon && <PlayArrow className={SongCardCss.playArrow} />}
        </div>
        <div className={SongCardCss.contentDiv}>
          <div
            style={{
              fontSize:
                width < 431
                  ? FontSizes.body
                  : titleFontSize ?? FontSizes.heading,
              fontWeight: "500",
              fontFamily: "Inter-Medium",
              color: titleColor ?? "#fff",
            }}
            className={SongCardCss.box}
          >
            {title}
          </div>
          <div
            style={{
              fontSize:
                width < 431
                  ? FontSizes.text
                  : subTitleFontSize ?? FontSizes.body,
              fontWeight: "400",
              fontFamily: "Inter-Regular",
              color: subTitleColor ?? "#909090",
            }}
            className="box"
          >
            {subTitle}
          </div>
          <div
            style={{
              fontSize:
                width < 431
                  ? FontSizes.smallText
                  : footerTextFontSize ?? FontSizes.text,
              fontWeight: "400",
              fontFamily: "Inter-Regular",
              color: footerTextColor ?? "#909090",
            }}
            className="box"
          >
            {footerText}
          </div>
        </div>
      </Box>
      <div className={SongCardCss.fdr}>
        {duration && duration !== "" && (
          <div
            style={{
              fontSize:
                width < 431
                  ? FontSizes.smallText
                  : durationFontSize ?? FontSizes.text,
              fontWeight: "400",
              fontFamily: "Inter-Regular",
              color: durationColor ?? "#fff",
            }}
          >
            {duration}
          </div>
        )}
        <BsThreeDotsVertical
          className={SongCardCss.threeDots}
          ref={ref}
          onClick={(e) => handleClick(e)}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          style={{ zIndex: 99999999999 }}
        >
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}
            style={{
              backgroundColor: "#363635",
              color: "#fff",
              fontSize: FontSizes.smallText,
              fontFamily: "Inter-Regular",
            }}
          >
            {queue ? (
              <>
                <MenuItem
                  onClick={() => {
                    handleRemove();
                    handleClose();
                  }}
                >
                  Remove from queue
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleToTop();
                    handleClose();
                  }}
                >
                  Move to top
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleToBottom();
                    handleClose();
                  }}
                >
                  Move to bottom
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem
                  onClick={() => {
                    if (handleToAdd) {
                      handleToAdd();
                    }
                    handleClose();
                  }}
                >
                  Add to queue
                </MenuItem>

                {showDelete && (
                  <MenuItem
                    onClick={() => {
                      if (handleDelete) {
                        handleDelete();
                      }
                      handleClose();
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
              </>
            )}
            {isDownloadable && (
              <MenuItem onClick={handleDownload}>Download</MenuItem>
            )}
          </MenuList>
        </Popover>
      </div>
    </Box>
  );
}
