import React from "react";
import loaderCss from "./loader.module.css";
import { Skeleton } from "@mui/material";
export default function NotificationsLoader() {
  return Array.from({ length: 12 }, (_, index) => (
    <div key={index} className={loaderCss.col3}>
      <Skeleton
        sx={{ bgcolor: "grey.900", paddingRight: 5 }}
        animation="wave"
        width={40}
        height={60}
        variant="rectangular"
      />
      <div className={loaderCss.row2}>
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
          animation="wave"
          variant="text"
          width={"30%"}
          height={25}
        />
        {Array.from({ length: 3 }, (_, index) => (
          <Skeleton
            sx={{ bgcolor: "grey.900", marginBottom: 0.2 }}
            animation="wave"
            variant="text"
            width={"100%"}
            height={14}
            key={index}
          />
        ))}
      </div>
    </div>
  ));
}
