import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import VideoCss from "./Video.module.css";
import { Grid, Skeleton } from "@mui/material";
import VideoCard from "../../components/video-card/VideoCard";
import { useNavigate } from "react-router";
import { Context } from "../../Context";
import VideoPlayer from "../../components/video-player/VideoPlayer";
import { ExtractDate } from "../../elements/date-extractor/ExtractDate";
import MetaTags from "../../MetaTags";
import ViewMore from "../../elements/view-more/ViewMore";
import CategoriesList from "../../elements/categories/CategoriesList";
import SlugConverter from "../../elements/slug-converter/SlugConverter";
import TagsList from "../../elements/Flatlist/TagsList";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import ReactGA from "react-ga4";

export default function VideoHome() {
  const [width, setWidth] = useState(window.innerWidth);
  const { setPageTitle } = useContext(Context);
  const [videosData, setVideosData] = useState(null);
  const [topTrending, setTopTrending] = useState(null);
  const [popular, setPopular] = useState(null);
  const [categories, setCategories] = useState(null);
  const [tag, setTag] = useState(null);
  const [featuredVideos, setFeaturedVideos] = useState(null);
  const [featuredIsLoading, setFeaturedIsLoading] = useState(true);
  const [newIsLoading, setNewIsLoading] = useState(true);
  const [popularIsLoading, setPopularIsLoading] = useState(true);
  const [trendingIsLoading, setTrendingIsLoading] = useState(true);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [tagsIsLoading, setTagsIsLoading] = useState(true);
  const [categoriesIsLoading, setCategoriesIsLoading] = useState(true);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/videos`,
      title: "Videos Home",
    });
  }, []);

  useEffect(() => {
    setPageTitle("Videos");
  }, []);

  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };
  const handleNavigate = (tag) => {
    const params = `${tag}`;
    const route = "" + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  const handleSlugNavigate = (param) => {
    const params = param?.tag
      ? `tag=${SlugConverter(param.tag)}`
      : `category=${SlugConverter(param.category)}`;
    const route = "all?" + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleGetFeatured = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_VIDEOS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.FEATURED_VIDEOS_HOME,
          type: "featured",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length > 0) {
          const randomNumber = Math.floor(
            Math.random() * res?.data?.data?.length
          );
          const u = res?.data?.data[randomNumber]?.media_url;
          const t = res?.data?.data[randomNumber]?.title;
          setUrl(u);
          setTitle(t);
          setFeaturedVideos(res?.data?.data?.slice(0, 3));
        }
        setFeaturedIsLoading(false);
      }
    } catch (err) {
      setFeaturedIsLoading(false);
    }
  };

  const handleGetNew = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_VIDEOS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.NEW_VIDEOS_HOME,
          orderBy: "created_at",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setVideosData(res?.data?.data);
        }
        setNewIsLoading(false);
      }
    } catch (err) {
      setNewIsLoading(false);
    }
  };

  const handleGetPopular = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_VIDEOS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.POPULAR_VIDEOS_HOME,
          type: "popular",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setPopular(res?.data?.data);
        }
        setPopularIsLoading(false);
      }
    } catch (err) {
      setPopularIsLoading(false);
    }
  };

  const handleGetTrending = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_VIDEOS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.TOP_TRENDING_VIDEOS_HOME,
          type: "trending",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setTopTrending(res?.data?.data);
        }
        setTrendingIsLoading(false);
      }
    } catch (err) {
      setTrendingIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetFeatured();
    handleGetNew();
    handleGetPopular();
    handleGetTrending();
    getCategories();
    getTags();
  }, []);

  const videoParams = {
    videoProps: {
      height: width < 431 ? 240 : width < 835 ? 450 : width < 1233 ? 570 : 570,
      width: "100%",
      autoPlay: true,
      playing: true,
      loop: false,
      muted: true,
      controls: true,
      url: url,
    },
  };

  const getCategories = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_VIDEO_CATEGORIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
        },
      });
      if (res.status === 200) {
        if (res?.data?.categories?.data?.length !== 0) {
          setCategories(res?.data?.categories?.data);
        }
        setCategoriesIsLoading(false);
      }
    } catch (err) {
      setCategoriesIsLoading(false);
    }
  };

  const getTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
          type: "video",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setTag(res?.data?.data);
        }
        setTagsIsLoading(false);
      }
    } catch (err) {
      setTagsIsLoading(false);
    }
  };

  function getRandomPercentage() {
    const randomNumber = Math.floor(Math.random() * (100 - 60 + 1)) + 60;
    return randomNumber.toString() + "%";
  }

  function VideoList({ videos, size }) {
    return (
      <div className={VideoCss.flexDrR}>
        {videos.map((item, index) => {
          const date = ExtractDate(item.created_at);
          return (
            <VideoCard
              key={index}
              title={item.title}
              viewCount={item.watch_count}
              likeCount={item.like_count}
              subTitle={date}
              thumbnailLink={item.cover_image_url}
              onClick={() => handleClick(`/videos/${item.slug}`)}
              orientation={"portrait"}
              size={size}
            />
          );
        })}
      </div>
    );
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"Videos"}
        url={window.location.href}
        type={"website"}
        image="../../assets/images/tjj-logo.jpg "
      />
      <div className={VideoCss.mainDiv}>
        <div className={VideoCss.fullWidthContainerFd}>
          <Label size="lg" label={"TJJ TV | Trini Jungle Juice TV"} />
          {featuredIsLoading ? (
            <>
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={"30%"}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={450}
              />
              <div className={VideoCss.mgb} />
            </>
          ) : (
            url && (
              <>
                <div className={VideoCss.heading}>{title}</div>
                <div className={VideoCss.mgb}>
                  <VideoPlayer {...videoParams} />
                </div>
              </>
            )
          )}
          {featuredVideos && <Label size="md" label={"Featured"} />}
          {featuredIsLoading ? (
            <>
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={"30%"}
                height={50}
              />
              <div className={VideoCss.flexDrR}>
                {Array.from({ length: 3 }, (_, index) => {
                  return (
                    <div key={index} className={VideoCss.skeleton}>
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="rectangular"
                        width={370}
                        height={240}
                      />
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="text"
                        width={"70%"}
                      />
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="text"
                        width={"50%"}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            featuredVideos && <VideoList size={"md"} videos={featuredVideos} />
          )}
          {featuredVideos &&
            (featuredVideos?.length > 2 ? (
              <ViewMore
                onClick={() => handleNavigate("featured")}
                viewMoreOrFilter
              />
            ) : (
              <div className={VideoCss.mgb} />
            ))}

          {videosData && <Label size="md" label={"New"} />}
          {newIsLoading ? (
            <>
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={"30%"}
                height={50}
              />
              <div className={VideoCss.flexDrR}>
                {Array.from({ length: 8 }, (_, index) => {
                  return (
                    <div key={index} className={VideoCss.skeleton}>
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="rectangular"
                        width={273}
                        height={181}
                      />
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="text"
                        width={"70%"}
                      />
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="text"
                        width={"50%"}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            videosData && <VideoList size={"sm"} videos={videosData} />
          )}

          {videosData && (
            <ViewMore
              onClick={() => handleClick("/videos/all")}
              viewMoreOrFilter
            />
          )}

          {popular && <Label size="md" label={"Popular"} />}
          {popularIsLoading ? (
            <>
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={"30%"}
                height={50}
              />
              <div className={VideoCss.flexDrR}>
                {Array.from({ length: 8 }, (_, index) => {
                  return (
                    <div key={index} className={VideoCss.skeleton}>
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="rectangular"
                        width={273}
                        height={181}
                      />
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="text"
                        width={"70%"}
                      />
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="text"
                        width={"50%"}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            popular && <VideoList size={"sm"} videos={popular} />
          )}
          {popular &&
            (popular?.length === PER_PAGE_COUNT.POPULAR_VIDEOS_HOME ? (
              <ViewMore
                onClick={() => handleNavigate("popular")}
                viewMoreOrFilter
              />
            ) : (
              <div className={VideoCss.mgb} />
            ))}

          {topTrending && <Label size="md" label={"Top Trending"} />}
          {trendingIsLoading ? (
            <>
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="text"
                width={"30%"}
                height={50}
              />
              <div className={VideoCss.flexDrR}>
                {Array.from({ length: 10 }, (_, index) => {
                  return (
                    <div key={index} className={VideoCss.skeleton}>
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="rectangular"
                        width={214.4}
                        height={181}
                      />
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="text"
                        width={"70%"}
                      />
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        animation="wave"
                        variant="text"
                        width={"50%"}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            topTrending && <VideoList size={"xs"} videos={topTrending} />
          )}
          {topTrending &&
            (topTrending?.length === PER_PAGE_COUNT.TOP_TRENDING_VIDEOS_HOME ? (
              <ViewMore
                onClick={() => handleNavigate("top-trending")}
                viewMoreOrFilter
              />
            ) : (
              <div className={VideoCss.mgb} />
            ))}

          {(categoriesIsLoading || categories) && (
            <div className={VideoCss.redHead}>Categories</div>
          )}
          <Grid className={VideoCss.topBox} container columnGap={3}>
            {categoriesIsLoading
              ? Array.from({ length: 50 }, (_, index) => {
                  return (
                    <Grid key={index} item xs={5.3} sm={3.3} md={2.2}>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <Skeleton
                          sx={{ bgcolor: "grey.900" }}
                          animation="wave"
                          variant="circular"
                          width={"10%"}
                        />
                        <Skeleton
                          sx={{ bgcolor: "grey.900" }}
                          animation="wave"
                          variant="text"
                          width={getRandomPercentage()}
                        />
                      </div>
                    </Grid>
                  );
                })
              : categories &&
                categories?.length !== 0 &&
                categories?.map((item, index) => {
                  return (
                    <CategoriesList
                      key={index}
                      item={item}
                      onClick={() =>
                        handleSlugNavigate({ category: item.slug })
                      }
                    />
                  );
                })}
          </Grid>
          {categoriesIsLoading ? (
            <div className={VideoCss.mgb20} />
          ) : (
            categories &&
            categories?.length !== 0 && (
              <ViewMore onClick={() => handleClick("/videos/categories")} />
            )
          )}
          {(tagsIsLoading || tag) && (
            <div className={VideoCss.redHead}>Popular Tags</div>
          )}
          {tagsIsLoading ? (
            <Grid container columnGap={3}>
              {Array.from({ length: 50 }, (_, index) => {
                return (
                  <Grid key={index} item xs={5.3} sm={3.3} md={2.2}>
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={"100%"}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            tag && (
              <>
                <TagsList tag={tag} handleNavigate={handleSlugNavigate} />
                <ViewMore onClick={() => handleClick("/videos/tags")} />
              </>
            )
          )}
        </div>
      </div>
    </NavContainer>
  );
}
