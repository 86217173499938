import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import ArtistsList from "../../elements/sorted-list/SortedArtistsList";
import MusicCss from "./MusicHome.module.css";
import { Context } from "../../Context";
import MetaTags from "../../MetaTags";
import ViewMore from "../../elements/view-more/ViewMore";
import { useSearchParams } from "react-router-dom";
import TextField from "../../components/textfield/TextField";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import ArtistAndDjCardLoader from "../../elements/skeletonLoaders/ArtistAndDjCardLoader";
import ArtistList from "../../elements/Flatlist/ArtistList";
import axios from "axios";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import NoData from "../../elements/no-data/NoData.js";
import { IconButton } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import ReactGA from "react-ga4";

const options = [
  {
    label: "Name",
    value: "first_name",
  },
  {
    label: "Followers",
    value: "followers_count",
  },
];

export default function ArtistHomePage() {
  const { setPageTitle } = useContext(Context);
  const [artistNameLists, setArtistNameLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [moreArtistIsLoading, setMoreArtistIsLoading] = useState(true);
  const [orderDirection, setOrderDirection] = useState(null);
  const [orderBy, setOrderBy] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  let [searchParams, setSearchParams] = useSearchParams();
  const [artistsPageNumber, setArtistsPageNumber] = useState(1);
  const [params, setParams] = useState({});
  const [artistsLastPage, setArtistsLastPage] = useState(null);
  const [search, setSearch] = useState("");
  const [finalSearch, setFinalSearch] = useState("");
  const [status, setStatus] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/music/artists`,
      title: "Artists Home",
    });
  }, []);

  function sortBy() {
    return (
      <div className={MusicCss.sortDiv}>
        <div className={MusicCss.sortBy}>
          <TextField
            defaultValue={orderBy}
            type={"select"}
            label={"Sort by"}
            data={options}
            onChange={(item) => handleSortby(item)}
          />
        </div>
        <FaSortAmountUp
          onClick={() => handleOrder("asc")}
          className={
            orderDirection === "asc"
              ? MusicCss.ascDescIcon1
              : MusicCss.ascDescIcon
          }
        />
        <>
          <FaSortAmountDownAlt
            onClick={() => handleOrder("desc")}
            className={
              orderDirection === "desc"
                ? MusicCss.ascDescIcon1
                : MusicCss.ascDescIcon
            }
          />
        </>
      </div>
    );
  }

  const handleSortby = (item) => {
    handleSearchParam("sortby", item);
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results`,
      label: `Sort by ${item} on all artists page`,
    });
    ReactGA.event({
      category: "Sort",
      action: `Sorted the results on all artists page`,
      label: `Sorted the results by ${item} on all artists page`,
    });
    setOrderBy(item);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  const handleGetData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ARTISTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: artistsPageNumber,
          items: PER_PAGE_COUNT.MUSIC_ARTISTS_MORE,
          orderBy: orderBy,
          orderDirection: orderDirection,
          ...(finalSearch && {
            search: finalSearch,
          }),
        },
      });
      if (res.status === 200) {
        setStatus(res.status)
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setArtistsLastPage(res?.data?.last_page);
          if (artistNameLists?.length === 0) {
            setArtistNameLists(res?.data?.data);
          } else {
            setArtistNameLists((prev) => [...prev, ...res?.data?.data]);
          }
        }
        if (moreArtistIsLoading) {
          setMoreArtistIsLoading(false);
        }
        setIsLoading(false);
      }
    } catch (err) {
      if (moreArtistIsLoading) {
        setMoreArtistIsLoading(false);
      }
      setIsLoading(false);
      setArtistNameLists([]);
    }
  };

  useEffect(() => {
    setArtistsPageNumber(1);
    setIsLoading(true);
    setArtistNameLists([]);
    if (searchParams.size === 1 && searchParams.has("sortby")) {
      if (orderBy && orderDirection) {
        handleGetData();
      }
    } else if (searchParams.size === 1 && searchParams.has("orderby")) {
      if (orderBy && orderDirection) {
        handleGetData();
      }
    } else if (
      searchParams.size === 2 &&
      searchParams.has("orderby") &&
      searchParams.has("sortby")
    ) {
      if (orderBy && orderDirection) {
        handleGetData();
      }
    } else if (searchParams.size !== 0) {
      if (finalSearch && orderBy && orderDirection) {
        handleGetData();
      }
    } else {
      if (orderBy && orderDirection) {
        handleGetData();
      }
    }
  }, [orderBy, orderDirection, finalSearch]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearchParam = (key, value) => {
    setParams((prevParams) => ({
      ...prevParams,
      [key]: value,
    }));
  };

  const handleOrder = (item) => {
    handleSearchParam("orderby", item);
    const order = item === "asc" ? "ascending" : "descending";
    ReactGA.event({
      category: "Order",
      action: `Order by ${order}`,
      label: `Order by ${order} on all artists page`,
    });
    ReactGA.event({
      category: "Order",
      action: `Order by ${order} on all artists page`,
      label: `Ordered the results by ${order} on all artists page`,
    });
    if (item === "asc") {
      setOrderDirection("asc");
    } else {
      setOrderDirection("desc");
    }
  };

  useEffect(() => {
    if (artistsPageNumber !== 1) {
      handleGetData();
    }
  }, [artistsPageNumber]);

  const handlePagination = () => {
    if (artistsLastPage != null && artistsPageNumber <= artistsLastPage) {
      setMoreArtistIsLoading(true);
      setArtistsPageNumber(artistsPageNumber + 1);
    }
  };

  useEffect(() => {
    setPageTitle("Artists");
  }, []);

  useEffect(() => {
    if (searchParams.size !== 0 && searchParams.get("sortby")) {
      handleSortby(searchParams.get("sortby"));
    } else {
      setOrderBy("first_name");
    }
    if (searchParams.size !== 0 && searchParams.get("orderby")) {
      handleOrder(searchParams.get("orderby"));
    } else {
      setOrderDirection("asc");
    }
    if (searchParams.size !== 0 && searchParams?.get("search")) {
      setSearch(searchParams.get("search"));
      handleSearch(searchParams.get("search"));
    }
  }, [searchParams]);

  const handleSearch = (item) => {
    if (item && item !== "") {
      setFinalSearch(item);
      ReactGA.event({
        category: "Search",
        action: `Searched ${item}`,
        label: `Searched ${item} on all artists page`,
      });
      ReactGA.event({
        category: "Search",
        action: `Searched ${item} on all artists page`,
        label: `Searched ${item} on all artists page`,
      });
      handleSearchParam("search", item);
    }
  };

  const handleClear = () => {
    if (searchParams.has("search")) {
      searchParams.delete("search");
      setSearchParams(searchParams);
      delete params["search"];
    }
    setSearch("");
    setFinalSearch("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(search);
    }
  };

  return (
    <>
      <NavContainer>
        <MetaTags
          author={"Trini Jungle Juice"}
          title={"Artists"}
          url={window.location.href}
          type={"website"}
          image="../../assets/images/tjj-logo.jpg "
        />
        <div className={MusicCss.allMusicMainDiv}>
          <div className={MusicCss.flexDr1}>
            <Label size="lg" label={"Artists"} />
            {sortBy()}
          </div>
          <div className={MusicCss.allMusicSubDiv}>
            <TextField
              label="Search"
              onChange={(value) => setSearch(value)}
              defaultValue={search}
              size="small"
              onKeyDown={handleKeyPress}
            />
            {finalSearch ? (
              <IconButton
                onClick={handleClear}
                className={MusicCss.allMusicIconButton}
              >
                <Close className={MusicCss.allMusicSearchIcon} />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => handleSearch(search)}
                className={MusicCss.allMusicIconButton}
              >
                <Search className={MusicCss.allMusicSearchIcon} />
              </IconButton>
            )}
          </div>
        </div>
        {width < 431 && <div className={MusicCss.mgb10} />}
        <div>
          {isLoading ? (
            <div className={MusicCss.mgb}>
              <div className={MusicCss.albumsDiv}>
                {Array.from({ length: 10 }, (_, index) => {
                  return (
                    <div key={index} className={MusicCss.albumsSubDiv}>
                      <ArtistAndDjCardLoader />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : artistNameLists?.length !== 0 ? (
            <>
              {orderBy === "first_name" ? (
                <ArtistsList data={artistNameLists} order={orderDirection} />
              ) : (
                <ArtistList artists={artistNameLists} />
              )}
              {moreArtistIsLoading && (
                <div className={MusicCss.flexDr} style={{ marginTop: 10 }}>
                  {Array.from({ length: 8 }, (_, index) => (
                    <div key={index} className={MusicCss.albumsSubDiv}>
                      <ArtistAndDjCardLoader />
                    </div>
                  ))}
                </div>
              )}
              {artistsLastPage != null &&
                artistsPageNumber < artistsLastPage &&
                !moreArtistIsLoading &&
                artistNameLists?.length !== 0 && (
                  <ViewMore loadMore={true} onClick={handlePagination} />
                )}
            </>
          ) : (
            <NoData errorCode={status} />
          )}
        </div>
      </NavContainer>
    </>
  );
}
