import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { CardSizes, FontSizes } from "../../../config";
import { Box, MenuItem, MenuList, Popover } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ItineraryCss from "./ItineraryCard.module.css";

export default function ItineraryCard({ title, subTitle, onClick }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
    } else if (event.key === "Escape") {
      setAnchorEl(null);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const id = open ? "simple-popover" : undefined;

  const maxLength = width < 431 ? 26 : 50;

  return (
    <Box
      className={ItineraryCss.itineraryBox}
      style={{
        width: width < 431 ? "100%" : CardSizes.md,
      }}
      onClick={onClick === null ? null : onClick}
    >
      <Box className={ItineraryCss.itinerarySubBox}>
        <div className={ItineraryCss.contentDiv}>
          <div className={ItineraryCss.title}>
            {title !== "" && title?.length > maxLength
              ? `${title?.substring(0, maxLength)}...`
              : title}
          </div>
          <div className={ItineraryCss.subTitle}>
            {subTitle !== "" && subTitle?.length > maxLength
              ? `${subTitle?.substring(0, maxLength)}...`
              : subTitle}
          </div>
        </div>
      </Box>
    </Box>
  );
}

ItineraryCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  chips: PropTypes.array,
  onClick: PropTypes.func,
  thumbnail: PropTypes.string,
};

ItineraryCard.defaultProps = {
  title: "",
  subTitle: null,
  chips: [],
  onClick: null,
  thumbnail: "",
};
