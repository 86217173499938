import React, { useEffect, useState } from "react";
import { CardSizes } from "../../config";
import { Box } from "@mui/material";
import PlaylistCss from "./PlaylistCard.module.css";

export default function PlaylistCard({
  title = "",
  subTitle = null,
  thumbnail = "",
  onClick = undefined,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxLength = width < 431 ? 26 : 50;

  return (
    <Box
      className={PlaylistCss.playlistBox}
      style={{
        width: width < 431 ? "100%" : CardSizes.md,
      }}
      onClick={onClick === null ? null : onClick}
    >
      <Box className={PlaylistCss.playlistSubBox}>
        <div className={PlaylistCss.imageDiv}>
          <img
            src={thumbnail}
            alt="thumbnail"
            className={PlaylistCss.thumbnail}
          />
        </div>
        <div className={PlaylistCss.contentDiv}>
          <div className={PlaylistCss.title}>
            {title !== "" && title?.length > maxLength
              ? `${title?.substring(0, maxLength)}...`
              : title}
          </div>
          <div className={PlaylistCss.subTitle}>
            {subTitle !== "" && subTitle?.length > maxLength
              ? `${subTitle.substring(0, maxLength)}...`
              : subTitle}
          </div>
        </div>
      </Box>
    </Box>
  );
}
