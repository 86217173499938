import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import { Skeleton } from "@mui/material";
import Card from "../../components/card/Card";
import Slider from "react-slick";
import { LocationOn } from "@mui/icons-material";
import { FaCalendarAlt } from "react-icons/fa";
import GalleryCss from "./GalleryHome.module.css";
import { useNavigate, useParams } from "react-router";
import ViewMore from "../../elements/view-more/ViewMore";
import { Context } from "../../Context";
import LightBoxGallery from "../../components/lightBox-gallery/LightBoxGallery";
import CountryFooter from "../../elements/country-footer/CountryFooter";
import { LocationExtractor } from "../../elements/location-extractor/LocationExtractor";
import { ExtractShortDate } from "../../elements/date-extractor/ExtractDate";
import MetaTags from "../../MetaTags";
import GalleryCardLoader from "../../elements/skeletonLoaders/GalleryCardLoader";
import GalleryImagesLoader from "../../elements/skeletonLoaders/GalleryImagesLoader";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { useLoggedInState } from "../../elements/logged-in-state/useLoggedInState.js";
import ReactGA from "react-ga4";

function GalleryHome({ isLoggedIn, user }) {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const { setPageTitle } = useContext(Context);
  const { image_id } = useParams();
  const [selectedPhotoId, setSelectedPhotoId] = useState(image_id);
  const [mostRecent, setMostRecent] = useState(null);
  const [mostLiked, setMostLiked] = useState(null);
  const [mostLikedPhotos, setMostLikedPhotos] = useState([]);
  const [thisTimeLastYear, setThisTimeLastYear] = useState(null);
  const [highlyRated, setHighlyRated] = useState(null);
  const [topViewed, setTopViewed] = useState(null);
  const [mostRecentIsLoading, setMostRecentIsLoading] = useState(true);
  const [mostLikedIsLoading, setMostLikedIsLoading] = useState(true);
  const [mostLikedPhotosIsLoading, setMostLikedPhotosIsLoading] =
    useState(true);
  const [thisTimeLastYearIsLoading, setThisTimeLastYearIsLoading] =
    useState(true);
  const [highlyRatedIsLoading, setHighlyRatedIsLoading] = useState(true);
  const [topViewedIsLoading, setTopViewedIsLoading] = useState(true);
  const [photoId, setPhotoId] = useState(null);
  const [photoCommentsId, setPhotoCommentsId] = useState(null); //This photoCommentsId will be used to load more comments of the same photo.
  const [totalPhotoComments, setTotalPhotoComments] = useState(0);
  const [photoCommentsData, setPhotoCommentsData] = useState([]);
  const [isPhotosCommentsLoading, setIsPhotosCommentsLoading] = useState(true);
  const [isMorePhotosCommentsLoading, setIsMorePhotosCommentsLoading] =
    useState(false);
  const [photoCommentsPageNumber, setPhotoCommentsPageNumber] = useState(1);
  const [photoCommentsLastPage, setPhotoCommentsLastPage] = useState(null);
  const [isDeletingPhotoComment, setIsDeletingPhotoComment] = useState(false);
  const isLoggedInUser = useLoggedInState();

  // This useEffect is being used for google analytics.
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/gallery`,
      title: "Gallery Home",
    });
  }, []);

  // This function updates the current url with the currently opened galleries photo index which will be helpful to open the same gallery photo on other's desktop.
  function updatePageURL(index) {
    if (index > -1)
      window.history.replaceState(null, "Gallery", `/gallery/${index}`);
    else window.history.replaceState(null, "Gallery", `/gallery/`);
  }

  useEffect(() => {
    GetMostRecent();
    GetThisTimeLastYear();
    GetMostLiked();
    GetTopViewed();
    GetHighlyRated();
  }, []);

  // Collect all the recent galleries.
  const GetMostRecent = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_GALLERIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.GALLERIES_HOME,
          type: "most_recent",
        },
      });
      if (res.status === 200) {
        if (res?.data?.galleries?.data?.length !== 0) {
          setMostRecent(res?.data?.galleries?.data);
        }
        setMostRecentIsLoading(false);
      }
    } catch (err) {
      setMostRecentIsLoading(false);
    }
  };

  // Collect all the highly rated galleries.
  const GetHighlyRated = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_GALLERIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.GALLERIES_HOME,
          type: "highly_rated",
        },
      });
      if (res.status === 200) {
        if (res?.data?.galleries?.data?.length !== 0) {
          setHighlyRated(res?.data?.galleries?.data);
        }
        setHighlyRatedIsLoading(false);
      }
    } catch (err) {
      setHighlyRatedIsLoading(false);
    }
  };

  // Collect all the top viewed galleries.
  const GetTopViewed = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_GALLERIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.GALLERIES_HOME,
          type: "top_viewed",
        },
      });
      if (res.status === 200) {
        if (res?.data?.galleries?.data?.length !== 0) {
          setTopViewed(res?.data?.galleries?.data);
        }
        setTopViewedIsLoading(false);
      }
    } catch (err) {
      setTopViewedIsLoading(false);
    }
  };

  // Collect all the most liked photos.
  const GetMostLikedPhotos = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MOST_LIKED_PHOTOS, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.MOST_LIKED_PHOTOS,
        },
      });
      if (res.status === 200) {
        if (res?.data?.most_liked_photos?.data?.length !== 0) {
          setMostLikedPhotos(res?.data?.most_liked_photos?.data);
        }
        setMostLikedPhotosIsLoading(false);
      }
    } catch (err) {
      setMostLikedPhotosIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedInUser) {
      if (user?.token) {
        GetMostLikedPhotos();
      }
    } else {
      GetMostLikedPhotos();
    }
  }, [isLoggedInUser, user]);

  // Collect all the most liked galleries.
  const GetMostLiked = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_GALLERIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.GALLERIES_HOME,
          type: "most_liked",
        },
      });
      if (res.status === 200) {
        if (res?.data?.galleries?.data?.length !== 0) {
          setMostLiked(res?.data?.galleries?.data);
        }
        setMostLikedIsLoading(false);
      }
    } catch (err) {
      setMostLikedIsLoading(false);
    }
  };

  // Collect all the galleries of the current time for the last year.
  const GetThisTimeLastYear = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_GALLERIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.GALLERIES_HOME,
          type: "this_time_last_year",
        },
      });
      if (res.status === 200) {
        if (res?.data?.galleries?.data?.length !== 0) {
          setThisTimeLastYear(res?.data?.galleries?.data);
        }
        setThisTimeLastYearIsLoading(false);
      }
    } catch (err) {
      setThisTimeLastYearIsLoading(false);
    }
  };

  const getPhotoCommentsData = async (id) => {
    const galleryPhotoId = id ? id : photoCommentsId;
    if (photoCommentsPageNumber === 1) {
      setPhotoCommentsId(id);
    }
    try {
      const res = await axios.get(
        `${SERVER_URL.COMMENT_GALLERY_PHOTO}${galleryPhotoId}/comments`,
        {
          headers: {
            Accept: "application/json",
            ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
          },
          params: {
            page: photoCommentsPageNumber,
            items: PER_PAGE_COUNT.COMMENTS_ITEMS_COUNT,
          },
        }
      );
      if (res.status === 200) {
        const data = res.data.gallery_photo_comments;
        const commentsDataArray = data.data;

        if (photoCommentsPageNumber === 1)
          setPhotoCommentsData(commentsDataArray);
        else {
          setPhotoCommentsData((prevData) => {
            const newData = [...prevData, ...commentsDataArray];
            if (newData.length > PER_PAGE_COUNT.MAX_COMMENTS_DISPLAY_COUNT) {
              return newData.slice(PER_PAGE_COUNT.COMMENTS_ITEMS_COUNT);
            }
            return newData;
          });
        }
        setTotalPhotoComments(data?.total);
        setPhotoCommentsLastPage(data?.last_page);
        setIsPhotosCommentsLoading(false);
        if (isMorePhotosCommentsLoading) {
          setIsMorePhotosCommentsLoading(false);
        }
        if (isDeletingPhotoComment) {
          setIsDeletingPhotoComment(false);
        }
      }
    } catch (err) {
      setIsPhotosCommentsLoading(false);
      if (isMorePhotosCommentsLoading) {
        setIsMorePhotosCommentsLoading(false);
      }
    }
  };

  // This function will be used to post a comment on a photo.
  const handlePostPhotoComment = (value, id) => {
    const body = {
      comment: value,
      ...(photoId && { parent_comment_id: photoId }),
    };
    try {
      axios
        .post(SERVER_URL.COMMENT_GALLERY_PHOTO + `${id}/comment`, body, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((res) => {
          if (res?.status === 200) {
            if (photoId) {
              ReactGA.event({
                category: "Comments",
                action: `Replied on a comment`,
                label:
                  "Replied a comment in most like photos section on gallery home page",
              });
              ReactGA.event({
                category: "Comments",
                action: `Replied a comment on gallery home page`,
                label:
                  "Replied a comment in most like photos section on gallery home page",
              });
            } else {
              ReactGA.event({
                category: "Comments",
                action: `New comment posted`,
                label:
                  "Posted a comment in most like photos section on gallery home page",
              });
              ReactGA.event({
                category: "Comments",
                action: `New comment posted`,
                label:
                  "Posted a comment in most like photos section on gallery home page",
              });
            }
            if (photoCommentsId) {
              getPhotoCommentsData();
            }
            if (photoId) {
              setPhotoId(null);
              getPhotoCommentsData();
            }
          }
        });
    } catch (err) {
      Swal.fire({
        title: "Unable to post comment",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
    }
  };

  // This function will be used to set the photo id for the comment you are replying on.
  const onReplyPhoto = (id) => {
    setPhotoId(id);
  };

  // This function will be used to delete a comment of a photo
  const handleDeletePhotoComment = (value, id) => {
    setIsDeletingPhotoComment(true);
    try {
      axios
        .get(`${SERVER_URL.DELETE_COMMENT}${value}/delete`, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((res) => {
          ReactGA.event({
            category: "Comments",
            action: `Deleted a comment`,
            label:
              "Deleted a comment in most like photos section on gallery home page",
          });
          ReactGA.event({
            category: "Comments",
            action: `Deleted a comment on gallery home page`,
            label:
              "Deleted a comment in most like photos section on gallery home page",
          });
          if (photoCommentsPageNumber === 1) {
            getPhotoCommentsData(id);
          } else {
            setPhotoCommentsPageNumber(1);
          }
        });
    } catch (err) {
      setIsDeletingPhotoComment(false);
    }
  };

  function updatePhotoCommentPageNumber() {
    if (
      photoCommentsLastPage != null &&
      photoCommentsPageNumber <= photoCommentsLastPage
    ) {
      setIsMorePhotosCommentsLoading(true);
      setPhotoCommentsPageNumber(photoCommentsPageNumber + 1);
    }
  }

  // This function will be used to like a photo
  const onLikePhoto = async (id) => {
    if (isLoggedIn) {
      try {
        const res = await axios.get(
          SERVER_URL.LIKE_GALLERY_PHOTO + `${id}/like`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        if (res.status === 200) {
          ReactGA.event({
            category: "Photo",
            action: `Liked a photo`,
            label:
              "Liked a photo in most like photos section on gallery home page",
          });
          ReactGA.event({
            category: "Photo",
            action: `Liked a photo on gallery home page`,
            label:
              "Liked a photo in most like photos section on gallery home page",
          });
          Swal.fire({
            title: res?.data?.message,
            icon: "success",
            width: 600,
            padding: "3em",
            color: "#fff",
            background: "#000",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      } catch (err) {
        Swal.fire({
          title: "Unable to like the photo",
          icon: "error",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } else {
      handleLogin();
    }
  };

  // This function will be used to like a comment of a photo
  const handleLikeComment = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.LIKE_GALLERY_COMMENT}${id}/like`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Comments",
          action: `Liked a comment`,
          label:
            "Liked a comment in most like photos section on gallery home page",
        });
        ReactGA.event({
          category: "Comments",
          action: `Liked a comment on gallery home page`,
          label:
            "Liked a comment in most like photos section on gallery home page",
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to like comment",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  useEffect(() => {
    setPageTitle("Gallery");
  }, []);

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getSlides(data) {
    return data?.map((item, index) => {
      const location = LocationExtractor(item?.location);
      const date = ExtractShortDate(item.created_at);
      return (
        <div key={index}>
          <div className={GalleryCss.slidesDiv}>
            <Card
              title={item.name}
              imageSrc={item?.cover_photo_url}
              content={
                <div className={GalleryCss.flexDrC}>
                  {item?.location && (
                    <div className={GalleryCss.subText}>
                      <LocationOn className={GalleryCss.locationOnIcon} />{" "}
                      <span className={GalleryCss.locationText}>
                        {location}
                      </span>
                    </div>
                  )}
                  <div className={GalleryCss.subText}>
                    <FaCalendarAlt className={GalleryCss.calendarIcon} /> {date}
                  </div>
                </div>
              }
              textAlign={"left"}
              showAnimation={true}
              size={"sm"}
              onClick={() => handleClick(`/gallery/view/${item?.slug}`)}
              imageProps={{
                borderRadius: 20,
              }}
            />
          </div>
        </div>
      );
    });
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    swipeToSlide: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 2.7,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Lighbox Gallery Share Button Clicked",
      label: `${label} Shared on ${item} from most liked photos section on gallery home page`,
    });
    ReactGA.event({
      category: "Share",
      action: `${label} Shared on ${item} from gallery home page`,
      label: `${label} Shared on ${item} from most liked photos section on gallery home page`,
    });
  }

  function handleDownload() {
    ReactGA.event({
      category: "Download",
      action: "Lighbox Gallery download Button Clicked",
      label: `A image is downloaded from most liked photos section on gallery home page`,
    });
  }

  const gallerySlides = (data) => {
    return (
      <div className="slider-container">
        <Slider {...settings}>{getSlides(data)}</Slider>
      </div>
    );
  };

  const galleries = (data) => {
    return <div className={GalleryCss.galleries}>{getSlides(data)}</div>;
  };

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"Gallery"}
        url={window.location.href}
        type={"website"}
        image={"../../assets/images/tjj-logo.jpg "}
      />
      {mostRecentIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={"30%"}
          height={40}
        />
      ) : (
        mostRecent && (
          <div className={GalleryCss.mainDiv}>
            <Label label={"Most Recent Galleries"} size="lg" />
          </div>
        )
      )}
      {mostRecentIsLoading ? (
        <div className={GalleryCss.mgb40}>
          <div className={GalleryCss.flexDrR}>
            {Array.from({ length: 8 }, (_, index) => {
              return <GalleryCardLoader key={index} />;
            })}
          </div>
        </div>
      ) : (
        mostRecent && (
          <>{width < 431 ? gallerySlides(mostRecent) : galleries(mostRecent)}</>
        )
      )}
      {!mostRecentIsLoading &&
      mostRecent &&
      mostRecent?.length === PER_PAGE_COUNT.GALLERIES_HOME ? (
        <ViewMore
          onClick={() => handleClick("/gallery/all")}
          viewMoreOrFilter={true}
        />
      ) : (
        mostRecent && <div className={GalleryCss.mgb40} />
      )}

      {thisTimeLastYearIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={"30%"}
          height={40}
        />
      ) : (
        thisTimeLastYear && (
          <div className={GalleryCss.mainDiv}>
            <Label label={"This Time Last Year"} size="lg" />
          </div>
        )
      )}
      {thisTimeLastYearIsLoading ? (
        <div className={GalleryCss.mgb40}>
          <div className={GalleryCss.flexDrR}>
            {Array.from({ length: 8 }, (_, index) => {
              return <GalleryCardLoader key={index} />;
            })}
          </div>
        </div>
      ) : (
        thisTimeLastYear && (
          <>
            {width < 431
              ? gallerySlides(thisTimeLastYear)
              : galleries(thisTimeLastYear)}
          </>
        )
      )}
      {!thisTimeLastYearIsLoading &&
      thisTimeLastYear &&
      thisTimeLastYear?.length === PER_PAGE_COUNT.GALLERIES_HOME ? (
        <ViewMore
          onClick={() => handleClick("/gallery/this-time-last-year")}
          viewMoreOrFilter={true}
        />
      ) : (
        thisTimeLastYear && <div className={GalleryCss.mgb40} />
      )}

      {mostLikedPhotosIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={"30%"}
          height={40}
        />
      ) : (
        mostLikedPhotos?.length !== 0 && (
          <div className={GalleryCss.mainDiv}>
            <Label label={"Most Liked Photos"} size="lg" />
          </div>
        )
      )}
      {mostLikedPhotosIsLoading ? (
        <>
          <GalleryImagesLoader />
          <div className={GalleryCss.mgb40} />
        </>
      ) : (
        mostLikedPhotos?.length !== 0 && (
          <LightBoxGallery
            imageArray={mostLikedPhotos}
            imageIndex={selectedPhotoId}
            onChangeImageIndex={(val) => {
              updatePageURL(val.index);
            }}
            onDownload={handleDownload}
            onClose={() => {
              setSelectedPhotoId(undefined);
              updatePageURL(-1);
            }}
            onLikePhoto={onLikePhoto}
            isLoggedIn={isLoggedIn}
            onLike={handleLikeComment}
            onButtonClick={handlePostPhotoComment}
            onReply={onReplyPhoto}
            onShare={handleShare}
            getComments={(id) => {
              getPhotoCommentsData(id);
              setIsPhotosCommentsLoading(true);
            }}
            moreCommentsLoading={isMorePhotosCommentsLoading}
            isCommentsLoading={isPhotosCommentsLoading}
            updatePhotoCommentPageNumber={updatePhotoCommentPageNumber}
            showLoadMore={
              photoCommentsPageNumber < photoCommentsLastPage &&
              photoCommentsLastPage &&
              photoCommentsData?.length !== 0 &&
              !isMorePhotosCommentsLoading &&
              !isPhotosCommentsLoading
            }
            comments={photoCommentsData}
            totalComments={totalPhotoComments}
            handleDelete={handleDeletePhotoComment}
            isDeleting={isDeletingPhotoComment}
          />
        )
      )}
      {(mostLikedPhotosIsLoading || mostLikedPhotos?.length !== 0) && (
        <div className={GalleryCss.mgb40} />
      )}

      {mostLikedIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={"30%"}
          height={40}
        />
      ) : (
        mostLiked && (
          <div className={GalleryCss.mainDiv}>
            <Label label={"Most Liked Galleries"} size="lg" />
          </div>
        )
      )}
      {mostLikedIsLoading ? (
        <div className={GalleryCss.mgb40}>
          <div className={GalleryCss.flexDrR}>
            {Array.from({ length: 8 }, (_, index) => {
              return <GalleryCardLoader key={index} />;
            })}
          </div>
        </div>
      ) : (
        mostLiked && (
          <>{width < 431 ? gallerySlides(mostLiked) : galleries(mostLiked)}</>
        )
      )}
      {!mostLikedIsLoading &&
      mostLiked &&
      mostLiked?.length === PER_PAGE_COUNT.GALLERIES_HOME ? (
        <ViewMore
          onClick={() => handleClick("/gallery/most-liked")}
          viewMoreOrFilter={true}
        />
      ) : (
        mostLiked && <div className={GalleryCss.mgb40} />
      )}

      {topViewedIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={"30%"}
          height={40}
        />
      ) : (
        topViewed && (
          <div className={GalleryCss.mainDiv}>
            <Label label={"Top Viewed Galleries"} size="lg" />
          </div>
        )
      )}
      {topViewedIsLoading ? (
        <div className={GalleryCss.mgb40}>
          <div className={GalleryCss.flexDrR}>
            {Array.from({ length: 8 }, (_, index) => {
              return <GalleryCardLoader key={index} />;
            })}
          </div>
        </div>
      ) : (
        topViewed && (
          <>{width < 431 ? gallerySlides(topViewed) : galleries(topViewed)}</>
        )
      )}
      {!topViewedIsLoading &&
      topViewed &&
      topViewed?.length === PER_PAGE_COUNT.GALLERIES_HOME ? (
        <ViewMore
          onClick={() => handleClick("/gallery/top-viewed")}
          viewMoreOrFilter={true}
        />
      ) : (
        topViewed && <div className={GalleryCss.mgb40} />
      )}

      {highlyRatedIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 2 }}
          animation="wave"
          variant="text"
          width={"30%"}
          height={40}
        />
      ) : (
        highlyRated && (
          <div className={GalleryCss.mainDiv}>
            <Label label={"Highly Rated Galleries"} size="lg" />
          </div>
        )
      )}
      {highlyRatedIsLoading ? (
        <div className={GalleryCss.mgb40}>
          <div className={GalleryCss.flexDrR}>
            {Array.from({ length: 8 }, (_, index) => {
              return <GalleryCardLoader key={index} />;
            })}
          </div>
        </div>
      ) : (
        highlyRated && (
          <>
            {width < 431 ? gallerySlides(highlyRated) : galleries(highlyRated)}
          </>
        )
      )}
      {!highlyRatedIsLoading &&
      highlyRated &&
      highlyRated?.length === PER_PAGE_COUNT.GALLERIES_HOME ? (
        <ViewMore
          onClick={() => handleClick("/gallery/highly-rated")}
          viewMoreOrFilter={true}
        />
      ) : (
        highlyRated && <div className={GalleryCss.mgb40} />
      )}

      <CountryFooter
        by={"Galleries"}
        handleClick={(item) => {
          navigate(`/gallery/all?region=${item}`);
          window.scrollTo(0, 0);
        }}
      />
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
});

export default connect(mapStateToProps)(GalleryHome);
