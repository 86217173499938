import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer.js";
import Label from "../../elements/label/Label.js";
import { connect } from "react-redux";
import { Context } from "../../Context.js";
import Unauthorized from "../no-data/Unauthorized.js";
import ViewMore from "../../elements/view-more/ViewMore.js";
import NotificationsCss from "./AccountsCss.module.css";
import axios from "axios";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import NotificationsCard from "../../components/notifications-card/NotificationsCard.js";
import NotificationsLoader from "../../elements/skeletonLoaders/NotificationsLoader.js";
import { styled } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";
import { FontSizes } from "../../config.js";
import Swal from "sweetalert2";
import ReactGA from "react-ga4";

function Notifications({ user }) {
  const [notifications, setNotifications] = useState([]);
  const [moreDataIsLoading, setMoreDataIsLoading] = useState(true);
  const [notificationsPageNumber, setNotificationsPageNumber] = useState(1);
  const [notificationsLastPage, setNotificationsLastPage] = useState(null);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [readNotifications, setReadNotifications] = useState([]);
  const [moreReadDataIsLoading, setMoreReadDataIsLoading] = useState(true);
  const [readNotificationsPageNumber, setReadNotificationsPageNumber] =
    useState(1);
  const [readNotificationsLastPage, setReadNotificationsLastPage] =
    useState(null);
  const [readTotal, setReadTotal] = useState(0);
  const [readIsLoading, setReadIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(null);
  const { setPageTitle } = useContext(Context);
  const [width, setWidth] = useState(window.innerWidth);
  const [value, setValue] = useState(0);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/notifications`,
      title: "Notifications",
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className={NotificationsCss.tabPanel}>{children}</div>
        )}
      </div>
    );
  }

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: "48%",
      width: "100%",
      backgroundColor: "#FF0000",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
      fontSize: 30,
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: "700",
      fontSize: FontSizes.subTitle,
      fontFamily: "Inter-Bold",
      marginRight: theme.spacing(1),
      color: "#fff",
      minWidth: "49%",
      "&.Mui-selected": {
        color: "#fff",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(255, 255, 255, 0.32)",
      },
    })
  );

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (isAuthorized) {
      setPageTitle("Notifications");
    }
  }, [isAuthorized]);

  const handleGetNotifications = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_NOTIFICATIONS, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
        params: {
          page: notificationsPageNumber,
          items: PER_PAGE_COUNT.GET_NOTIFICATIONS,
          orderDirection: "asc",
        },
      });
      if (res.status === 200) {
        setTotal(res?.data?.total);
        setNotificationsLastPage(res?.data?.last_page);
        if (res?.data && res?.data?.data) {
          if (notificationsPageNumber === 1) {
            setNotifications(res?.data?.data);
          } else {
            setNotifications((prev) => [...prev, ...res?.data?.data]);
          }
        }
        setIsLoading(false);
        if (moreDataIsLoading) {
          setMoreDataIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
      if (moreDataIsLoading) {
        setMoreDataIsLoading(false);
      }
      const status = err.response.status;

      if (status === 401) {
        setIsAuthorized(false);
      }
    }
  };

  const handleGetReadNotifications = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_READ_NOTIFICATIONS, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
        params: {
          page: readNotificationsPageNumber,
          items: PER_PAGE_COUNT.GET_NOTIFICATIONS,
        },
      });
      if (res.status === 200) {
        setReadTotal(res?.data?.total);
        setReadNotificationsLastPage(res?.data?.last_page);
        if (res?.data && res?.data?.data) {
          if (readNotificationsPageNumber === 1) {
            setReadNotifications(res?.data?.data);
          } else {
            setReadNotifications((prev) => [...prev, ...res?.data?.data]);
          }
        }
        setReadIsLoading(false);
        if (moreReadDataIsLoading) {
          setMoreReadDataIsLoading(false);
        }
      }
    } catch (err) {
      setReadIsLoading(false);
      if (moreReadDataIsLoading) {
        setMoreReadDataIsLoading(false);
      }
      const status = err.response.status;

      if (status === 401) {
        setIsAuthorized(false);
      }
    }
  };

  useEffect(() => {
    if (user?.token) {
      handleGetNotifications();
    }
  }, [user, notificationsPageNumber]);

  useEffect(() => {
    if (user?.token) {
      handleGetReadNotifications();
    }
  }, [user, readNotificationsPageNumber]);

  function updateNotificationsPageNumber() {
    if (
      notificationsLastPage != null &&
      notificationsPageNumber <= notificationsLastPage
    ) {
      setMoreDataIsLoading(true);
      setNotificationsPageNumber(notificationsPageNumber + 1);
    }
  }

  const handleMarkRead = async (link, id) => {
    Swal.fire({
      title: "Loading...",
      didOpen: () => {
        Swal.showLoading();
      },
      background: "#000",
      color: "#fff",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    try {
      const res = await axios.get(
        `${SERVER_URL.READ_NOTIFICATIONS}${id}/read`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      Swal.close();
      if (res.status === 200) {
        setIsLoading(true);
        setReadIsLoading(true);
        if (notificationsPageNumber !== 1) {
          setNotificationsPageNumber(1);
        } else {
          handleGetNotifications();
        }
        if (readNotificationsPageNumber !== 1) {
          setReadNotificationsPageNumber(1);
        } else {
          handleGetReadNotifications();
        }
        const aTag = document.createElement("a");
        aTag.setAttribute("href", link);
        aTag.setAttribute("target", "_blank");
        aTag.click();
      }
    } catch (err) {
      Swal.close();
      Swal.fire({
        title: "Sorry...",
        text: "Something unforseen has happpened!",
        icon: "warning",
        color: "#fff",
        background: "#000",
        timer: 3000,
        showConfirmButton: false,
      });
    }
  };

  function handleClick(link, id) {
    if (value === 0) {
      handleMarkRead(link, id);
    } else {
      const aTag = document.createElement("a");
      aTag.setAttribute("href", link);
      aTag.setAttribute("target", "_blank");
      aTag.click();
    }
  }

  function updateReadNotificationsPageNumber() {
    if (
      readNotificationsLastPage != null &&
      readNotificationsPageNumber <= readNotificationsLastPage
    ) {
      setMoreReadDataIsLoading(true);
      setReadNotificationsPageNumber(readNotificationsPageNumber + 1);
    }
  }

  if (isAuthorized === false) {
    return <Unauthorized />;
  }

  return (
    <NavContainer>
      <Label label={`Notifications (${total})`} size="lg" center />
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={width < 431 ? false : true}
        allowScrollButtonsMobile={width < 431 ? false : true}
        aria-label={
          width < 431
            ? "scrollable prevent tabs example"
            : "scrollable force tabs example"
        }
      >
        <StyledTab label="Unread" {...a11yProps(0)} />
        <StyledTab label="Read" {...a11yProps(1)} />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <RenderNotificationsList
          notifications={notifications}
          isLoading={isLoading}
          moreDataIsLoading={moreDataIsLoading}
          notificationsLastPage={notificationsLastPage}
          notificationsPageNumber={notificationsPageNumber}
          updateNotificationsPageNumber={updateNotificationsPageNumber}
          onClick={handleClick}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RenderNotificationsList
          notifications={readNotifications}
          isLoading={readIsLoading}
          moreDataIsLoading={moreReadDataIsLoading}
          notificationsLastPage={readNotificationsLastPage}
          notificationsPageNumber={readNotificationsPageNumber}
          updateNotificationsPageNumber={updateReadNotificationsPageNumber}
          onClick={handleClick}
        />
      </TabPanel>
    </NavContainer>
  );
}

function RenderNotificationsList({
  notifications,
  isLoading,
  moreDataIsLoading,
  notificationsLastPage,
  notificationsPageNumber,
  updateNotificationsPageNumber,
  onClick,
}) {
  return (
    <div className={NotificationsCss.notificationsDiv}>
      <div className={NotificationsCss.notificationsSubDiv}>
        {isLoading ? (
          <NotificationsLoader />
        ) : (
          <>
            {notifications?.map((item, index) => {
              return (
                <NotificationsCard
                  data={item}
                  key={index}
                  onClick={() => onClick(item?.notification_link, item?.id)}
                />
              );
            })}
            {moreDataIsLoading && <NotificationsLoader />}
            {notifications &&
            notifications?.length !== 0 &&
            notificationsLastPage !== null &&
            notificationsPageNumber < notificationsLastPage &&
            !isLoading &&
            !moreDataIsLoading ? (
              <ViewMore loadMore onClick={updateNotificationsPageNumber} />
            ) : (
              <div className={NotificationsCss.mgb} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Notifications);
