import React, { useEffect, useState } from "react";
import MusicControls from "../music-controls/MusicControls";
import { Sheet } from "react-modal-sheet";
import MusicPlayer from "../music-player/MusicPlayer";
import { MusicPlayerRoot } from "../MusicPlayerRoot";
import miniMusicCss from "./MiniMusicPlayer.module.css";
import IconButton from "../icon-button/IconButton";
import { Close } from "@mui/icons-material";
import { MdKeyboardArrowUp } from "react-icons/md";

export default function MiniMusicPlayer({
  musicArgs = {
    title: "",
    subTitle: "",
    hlsSource: "",
    thumbnail: "",
    footerText: "",
    duration: "",
  },
  handleClose = undefined,
  playlist = [],
  songQueue = [],
  handleQueueUpdate = undefined,
  onPlaylistClick = undefined,
  onCreatePlaylist = undefined,
  moreFromArtist = [],
  handleDeleteFromQueue = undefined,
  handleMoveToTop = undefined,
  handleMoveToBottom = undefined,
  isPlaying = false,
  onMusicChange = undefined,
  onLike = undefined,
  onPlaylistOpen = undefined,
}) {
  const [width, setWidth] = useState(window.innerWidth);
  const [isOpen, setOpen] = useState(false);
  const [currentSongIndex, setCurrentSongIndex] = useState(-1);

  const [musicPlayerArgs, setMusicPlayerArgs] = useState({
    id: "",
    title: "",
    subTitle: "",
    hlsSource: "",
    thumbnail: "",
    duration: "",
    isLiked: false,
  });

  useEffect(() => {
    setMusicPlayerArgs(musicArgs);
  }, [musicArgs]);

  const playerRef = MusicPlayerRoot({ hlsSource: musicPlayerArgs.hlsSource });
  const audioRef = playerRef.getAudioRef();

  const handleMusicPlayer = (i) => {
    setMusicPlayerArgs(i);
    if (onMusicChange) {
      onMusicChange(i);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const maxLength = width < 431 ? 26 : 54;

  function bottomSheet() {
    return (
      <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
        <Sheet.Container className={miniMusicCss.reactModalSheetContainer}>
          <Sheet.Content>
            <MusicPlayer
              onClose={() => setOpen(false)}
              onCross={() => onClose()}
              playerRef={playerRef}
              onSongChange={(i) => handleMusicPlayer(i)}
              songArgs={musicPlayerArgs}
              playlist={playlist}
              songQueue={songQueue}
              handleQueueUpdate={handleQueueUpdate}
              onPlaylistClick={onPlaylistClick}
              onCreatePlaylist={onCreatePlaylist}
              moreFromArtist={moreFromArtist}
              handleDeleteFromQueue={handleDeleteFromQueue}
              handleMoveToBottom={handleMoveToBottom}
              handleMoveToTop={handleMoveToTop}
              currentSongIndex={currentSongIndex}
              setCurrentSongIndex={setCurrentSongIndex}
              onLike={onLike}
              onPlaylistOpen={onPlaylistOpen}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    );
  }

  const styles = {
    playIcon: {
      width: width < 431 ? 22 : 25,
      height: width < 431 ? 22 : 25,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: 10,
      position: "absolute",
      left: width < 431 ? -28 : -35,
    },
    caret: {
      width: width < 431 ? 30 : 35,
      height: width < 431 ? 30 : 35,
    },
  };

  function onClose() {
    playerRef.pauseAudio();
    if (handleClose) {
      handleClose();
    }
  }

  useEffect(() => {
    if (!isPlaying) {
      onClose();
    }
  }, [isPlaying]);

  // This useEffect will update the song state if the song has been ended.
  useEffect(() => {
    if (audioRef?.current?.ended) {
      if (songQueue?.length !== 0) {
        if (currentSongIndex === songQueue?.length - 1) {
          playerRef?.pauseAudio();
        } else {
          setCurrentSongIndex((prev) => prev + 1);
        }
      } else {
        playerRef?.setSongState({ ...playerRef?.songState, isPlaying: false });
        playerRef?.pauseAudio();
      }
    }
  }, [audioRef?.current?.ended]);

  return (
    <>
      <div className={miniMusicCss.miniMusicDiv}>
        <div className={miniMusicCss.caretDiv}>
          <IconButton
            backgroundColor={"#FF2B2B"}
            onClick={() => setOpen(!isOpen)}
            styles={styles.caret}
            size={"small"}
            icon={<MdKeyboardArrowUp className={miniMusicCss.caret} />}
          />
        </div>
        <div className={miniMusicCss.miniMusicSubdiv}>
          <IconButton
            backgroundColor={"#FF2B2B"}
            onClick={() => onClose()}
            styles={styles.playIcon}
            size={"small"}
            icon={
              <Close
                style={{
                  color: "#D9D9D9",
                  fontSize: width < 431 ? 12 : 15,
                }}
              />
            }
          />
          <div className={miniMusicCss.fg} onClick={() => setOpen(!isOpen)}>
            <div
              style={{
                width: `${
                  (playerRef.songState.currentTime /
                    playerRef.songState.totalDuration) *
                  100
                }%`,
              }}
              className={miniMusicCss.miniMusicSeekBar}
            />
            <div className={miniMusicCss.fdr}>
              <img
                src={musicPlayerArgs.thumbnail}
                alt="thumbnail"
                className={miniMusicCss.miniMusicImage}
              />
              <div className={miniMusicCss.fdc}>
                <div className={miniMusicCss.miniMusicTitle}>
                  {musicPlayerArgs.title !== "" &&
                  musicPlayerArgs.title.length > maxLength
                    ? `${musicPlayerArgs.title?.substring(0, maxLength)}...`
                    : musicPlayerArgs.title}
                </div>
                <div className={miniMusicCss.miniMusicSubTitle}>
                  {musicPlayerArgs.subTitle !== "" &&
                  musicPlayerArgs.subTitle.length > maxLength
                    ? `${musicPlayerArgs.subTitle.substring(0, maxLength)}...`
                    : musicPlayerArgs.subTitle}
                </div>
                <div className={miniMusicCss.miniMusicDuration}>
                  {playerRef.songState.elapsed} / {playerRef.songState.duration}
                </div>
              </div>
            </div>
          </div>
          <MusicControls
            audioRef={playerRef.getAudioRef()}
            disabled={false}
            showBackward={width < 431 ? false : true}
            showForward={width < 431 ? false : true}
            size={width < 431 ? "md" : "lg"}
            setSongState={playerRef.setSongState}
            songState={playerRef.songState}
            currentSongIndex={currentSongIndex}
            setCurrentSongIndex={setCurrentSongIndex}
            songQueue={songQueue}
            onSongChange={(i) => handleMusicPlayer(i)}
          />
        </div>
      </div>
      {bottomSheet()}
    </>
  );
}
