import React, { useEffect, useRef, useState } from "react";
import Comment from "./Comment";
import CommentCss from "./CommentList.module.css";
import SendIcon from "@mui/icons-material/Send";
import { IconButton } from "@mui/material";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { FontSizes } from "../../config";

function CommentList({
  isLoggedIn = false,
  user,
  commentTextField = "",
  showButton = true,
  onButtonClick = undefined,
  data = [],
  onLike = undefined,
  onReply = undefined,
  size = "sm",
  handleDelete = undefined,
  isDeleting = false,
}) {
  const [likes, setLikes] = useState([]);
  const textfieldCss = {
    "& .MuiInputBase-root": {
      minHeight: 36.13,
      color: "#fff",
      fontSize: 14,
    },
    "& .MuiInputBase-root input": {
      color: "#fff",
      paddingRight: "50px",
      fontSize: FontSizes.body,
      fontFamily: "Inter-Regular",
    },
    "& .MuiFormLabel-root": {
      color: "#fff",
      fontSize: 14,
      fontFamily: "Inter-Regular",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#fff",
      fontSize: 14,
      fontFamily: "Inter-Regular",
    },
    "& .MuiButtonBase-root": {
      color: "#fff",
      fontSize: FontSizes.smallText,
      fontFamily: "Inter-Regular",
      fontWeight: "400",
    },
    "& .MuiButtonBase-root svg": {
      color: "#fff",
      fontSize: FontSizes.heading,
    },

    "& .MuiSelect-icon": {
      color: "#fff !important",
    },

    "& .MuiOutlinedInput-root fieldset": {
      borderColor: "#fff !important",
    },

    "& .MuiOutlinedInput-root fieldset legend span": {
      fontSize: "11px",
    },
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #000 inset",
      WebkitTextFillColor: "#fff",
    },
  };
  const textFieldRef = useRef(null);
  const [commentsText, setCommentsText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setCommentsText(commentTextField);
  }, [commentTextField]);

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  const handleClick = () => {
    if (isLoggedIn && commentsText !== "" && commentsText) {
      onButtonClick(commentsText);
      setCommentsText("");
    } else {
      if (!isLoggedIn) {
        handleLogin();
      }
    }
  };

  const handleReply = (id) => {
    if (isLoggedIn) {
      onReply(id);
      textFieldRef?.current?.focus();
      textFieldRef?.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      handleLogin();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  return (
    <div className={CommentCss.commentsMainDiv}>
      <div className={CommentCss.commentsSubDiv}>
        <TextField
          inputRef={textFieldRef}
          sx={textfieldCss}
          fullWidth
          id="outlined-helperText"
          label={"Comment"}
          onChange={(event) => setCommentsText(event.target.value)}
          value={commentsText}
          size={"medium"}
          onKeyDown={handleKeyDown}
        />
        {showButton && (
          <IconButton
            sx={{
              right: 6,
              display: "flex",
              alignSelf: "center",
              backgroundColor: "transparent",
              position: "absolute",
            }}
            onClick={() => handleClick()}
          >
            <SendIcon style={{ color: "#fff", fontSize: 26 }} />
          </IconButton>
        )}
      </div>
      {data?.map((item, index) => {
        if (item.parent_comment_id !== null) {
          return null;
        }
        return (
          <Comment
            onReply={handleReply}
            onLike={onLike}
            size={size}
            {...item}
            key={index}
            likes={likes}
            setLikes={setLikes}
            authUser={user}
            handleDelete={handleDelete}
            isDeleting={isDeleting}
          />
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn, // Assuming `isLoggedIn` is stored in your Redux state
  user: state.auth.user,
});

export default connect(mapStateToProps)(CommentList);
