import React, { useContext, useEffect, useRef, useState } from "react";
import VideoStyles from "./VideoScreen.module.css";
import VideoPlayer from "../../components/video-player/VideoPlayer";
import { Grid, Modal, Skeleton } from "@mui/material";
import CommentList from "../../components/comment/CommentList";
import Chip from "../../components/chip/Chip";
import CloseIcon from "@mui/icons-material/Close";
import NavContainer from "../../elements/NavContainer/NavContainer";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip";
import { Context } from "../../Context";
import Label from "../../elements/label/Label";
import VideoCard from "../../components/video-card/VideoCard";
import { ExtractDate } from "../../elements/date-extractor/ExtractDate";
import { useLocation, useNavigate } from "react-router-dom";
import { PlaylistAdd } from "@mui/icons-material";
import { BiSolidAlbum, BiLike, BiSolidLike } from "react-icons/bi";
import Playlist from "../../elements/Playlist/Playlist";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import { convertNumUnit } from "../../elements/convert-num-unit/ConvertNumUnit.js";
import ViewMore from "../../elements/view-more/ViewMore.js";
import CommentLoader from "../../elements/skeletonLoaders/CommentLoader.js";
import NoDataScreen from "../no-data/NoDataScreen.js";
import ReactGA from "react-ga4";
import MetaTags from "../../MetaTags.js";

function VideoScreen({ isLoggedIn, user }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [showComments, setShowComments] = useState(true);
  const [showPlayer, setShowPlayer] = useState(true);
  const [categories, setCategories] = useState([]);
  const [artistData, setArtistData] = useState(null);
  const { setPageTitle } = useContext(Context);
  const [videos, setVideos] = useState(null);
  const [videoIsLoading, setVideoIsLoading] = useState(true);
  const [videosData, setVideosData] = useState(null);
  const [videoDataIsLoading, setVideoDataIsLoading] = useState(true);
  const [commentsData, setCommentsData] = useState([]);
  const [openPlaylist, setOpenPlaylist] = useState(false);
  const [like, setLike] = useState(false);
  const [slugUrl, setSlugUrl] = useState(null);
  const navigate = useNavigate();
  const [commentsPage, setCommentsPage] = useState(1);
  const [commentsIsLoading, setCommentsIsLoading] = useState(true);
  const [commentsLastPage, setCommentsLastPage] = useState(null);
  const [moreCommentsIsLoading, setMoreCommentsIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [url, setUrl] = useState(null);
  const [playlist, setPlaylist] = useState([]);
  const [playlistIsLoading, setPlaylistIsLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [isLiking, setIsLiking] = useState(false);
  const [isDeletingComment, setIsDeletingComment] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      setSlugUrl(`${SERVER_URL.GET_VIDEO_DETAILS}${u}`);
      ReactGA.send({
        hitType: "pageview",
        page: `/videos/${u}`,
        title: "Video Detail",
      });
    }
  }, [pathname]);

  useEffect(() => {
    if (slugUrl) {
      GetVideoDetail();
    }
  }, [slugUrl]);

  useEffect(() => {
    if (videosData) {
      setPageTitle(videosData?.title);
    } else {
      setPageTitle("Video Details Page");
    }
  }, [videosData]);

  function getAllCommentsLength() {
    var lengthOfChildren = 0;
    for (var i = 0; i < commentsData?.length; i++) {
      if (
        commentsData[i]?.children?.length !== 0 &&
        commentsData[i]?.parent_comment_id !== null
      ) {
        var replies = commentsData[i].children;
        lengthOfChildren += replies.length;
      }
    }
    return lengthOfChildren + commentsData?.length;
  }

  const GetVideoDetail = async () => {
    try {
      const res = await axios.get(slugUrl, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
      });
      setStatus(res?.status);
      if (res.status === 200) {
        if (res?.data && res?.data?.video_details) {
          setVideosData(res?.data?.video_details);
          if (res?.data?.video_details?.artists?.length !== 0) {
            setArtistData(res?.data?.video_details?.artists);
          }
          if (res?.data?.video_details?.categories?.length !== 0) {
            setCategories(res?.data?.video_details?.categories);
          }
        }
        setVideoDataIsLoading(false);
      }
    } catch (err) {
      setVideoDataIsLoading(false);
      setStatus(err?.response?.status);
    }
  };

  useEffect(() => {
    if (videosData) {
      setUrl(`${SERVER_URL.GET_ALL_VIDEOS}/${videosData?.id}`);
      setLike(videosData?.is_liked);
      StreamVideo();
    }
  }, [videosData]);

  useEffect(() => {
    if (url) {
      GetRelatedVideos();
    }
  }, [url]);

  const grid1 = useRef(null);
  const commentsRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width < 431) {
      setShowComments(false);
    } else {
      setShowComments(true);
      setShowPlayer(true);
    }
  }, [width]);

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isLoggedIn && user?.token) {
      handleGetPlaylist();
    }
  }, [isLoggedIn]);

  const onCreatePlaylist = async (item) => {
    const body = {
      playlist_name: item?.title,
      ...(item?.description && { description: item?.description }),
    };
    try {
      const res = await axios.post(SERVER_URL.CREATE_VIDEO_PLAYLIST, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        setPlaylistIsLoading(true);
        handleGetPlaylist();
        ReactGA.event({
          category: "Playlist",
          action: "New playlist created",
        });
        Swal.fire({
          title: "Playlist Created Successfully",
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        }).then(() => {
          setOpenPlaylist(true);
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to create the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const StreamVideo = async () => {
    try {
      const res = await axios.get(
        `${SERVER_URL.STREAM_VIDEO}${videosData?.id}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
    } catch (err) {}
  };

  const handlePlaylist = async (item) => {
    setOpenPlaylist(false);
    const body = {
      playlist_id: item?.id,
      video_id: videosData?.id,
    };
    try {
      const res = await axios.post(SERVER_URL.ADD_VIDEO_TO_PLAYLIST, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to add video to the playlist",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleGetPlaylist = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_VIDEO_PLAYLIST, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data && res?.data?.data?.length !== 0) {
          setPlaylist(res?.data?.data);
        }
        setPlaylistIsLoading(false);
      }
    } catch (err) {
      setPlaylist([]);
      setPlaylistIsLoading(false);
    }
  };

  const onReply = (id) => {
    setId(id);
  };

  const videoParams = {
    videoProps: {
      height: width < 431 ? 240 : width < 835 ? 450 : width < 1233 ? 500 : 570,
      width: "100%",
      autoPlay: false,
      loop: false,
      controls: true,
      url: videosData ? videosData?.media_url : "",
    },
  };

  const handleCommentsClick = () => {
    setShowComments(true);
    setTimeout(() => {
      setShowPlayer(false);
      commentsRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };

  const handleShowPlayer = () => {
    setTimeout(() => {
      if (showComments) {
        setShowComments(false);
      }
      grid1.current?.scrollIntoView({ behavior: "smooth" });
      setShowPlayer(true);
    }, 1000);
  };

  const handleOpenPlaylist = () => {
    if (isLoggedIn) {
      ReactGA.event({
        category: "Playlist",
        action: "Add to playlist button clicked",
        label: videosData?.title,
      });
      ReactGA.event({
        category: "Playlist",
        action: `${videosData?.title} added to playlist`,
        label: videosData?.title,
      });
      setOpenPlaylist(true);
    } else {
      handleLogin();
    }
  };

  const handleNavigate = (t) => {
    const params = t?.tag ? `?tag=${t.tag}` : `?category=${t.categories}`;
    const route = "/videos/all" + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  const handleGetComments = async () => {
    try {
      const res = await axios.get(url + "/comments", {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
        },
        params: {
          page: commentsPage,
          items: PER_PAGE_COUNT.COMMENTS_PER_PAGE,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.comments) {
          if (res?.data?.comments?.data) {
            if (commentsPage === 1) {
              setCommentsData(res?.data?.comments?.data);
            } else {
              setCommentsData([...commentsData, ...res?.data?.comments?.data]);
            }
          }
          setCommentsLastPage(res?.data?.comments?.last_page);
        }
        if (moreCommentsIsLoading) {
          setMoreCommentsIsLoading(false);
        }
        if (isDeletingComment) {
          setIsDeletingComment(false);
        }
        setCommentsIsLoading(false);
      }
    } catch (err) {
      setCommentsIsLoading(false);
      if (moreCommentsIsLoading) {
        setMoreCommentsIsLoading(false);
      }
    }
  };

  const handleLikeComment = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.LIKE_VIDEO_COMMENT}${id}/like`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Comments",
          action: `Liked/Unliked a comment`,
          label: `Liked/Unliked a comment on ${videosData?.title}`,
        });
        ReactGA.event({
          category: "Comments",
          action: `Liked/Unliked a comment on ${videosData?.title}`,
          label: `Liked/Unliked a comment on ${videosData?.title}`,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to like comment",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
    }
  };

  function updateCommentPageNumber() {
    if (commentsLastPage != null && commentsPage <= commentsLastPage) {
      setMoreCommentsIsLoading(true);
      setCommentsPage(commentsPage + 1);
    }
  }

  useEffect(() => {
    if (url) {
      handleGetComments();
    }
  }, [commentsPage, url]);

  const handlePostComment = (value) => {
    const body = {
      comment: value,
      ...(id && { parent_comment_id: id }),
    };
    axios
      .post(url + `/comment`, body, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (id) {
          ReactGA.event({
            category: "Comments",
            action: `Replied on a comment`,
            label: `Replied on a comment on ${videosData?.title}`,
          });
          ReactGA.event({
            category: "Comments",
            action: `Replied on a comment on ${videosData?.title}`,
            label: `Replied on a comment on ${videosData?.title}`,
          });
        } else {
          ReactGA.event({
            category: "Comments",
            action: `New comment posted`,
            label: `Posted a comment on ${videosData?.title}`,
          });
          ReactGA.event({
            category: "Comments",
            action: `New comment posted on ${videosData?.title}`,
            label: `Posted a comment on ${videosData?.title}`,
          });
        }
        setId(null);
        if (commentsPage === 1) {
          handleGetComments();
        } else {
          setCommentsPage(1);
        }
      });
  };

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  const GetRelatedVideos = async () => {
    let param = {
      page: 1,
      items: PER_PAGE_COUNT.NEW_VIDEOS_HOME,
    };
    if (categories) {
      param = {
        ...param,
        categories: [categories[0]?.slug],
      };
    } else if (videosData?.tags) {
      param = {
        ...param,
        tags: [videosData?.tags[0]?.slug],
      };
    } else if (artistData) {
      param = {
        ...param,
        artists: [artistData?.slug],
      };
    } else {
      param = {
        ...param,
        type: "featured",
      };
    }
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_VIDEOS, {
        headers: {
          Accept: "application/json",
        },
        params: param,
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.length !== 0) {
          setVideos(res?.data?.data);
          setVideoIsLoading(false);
        } else {
          setVideoIsLoading(false);
        }
      }
    } catch (err) {
      setVideoIsLoading(false);
    }
  };

  const handleDeleteComment = (value) => {
    setIsDeletingComment(true);
    axios
      .get(`${SERVER_URL.DELETE_COMMENT}${value}/delete`, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then(() => {
        ReactGA.event({
          category: "Comments",
          action: `Deleted a comment`,
          label: `Deleted a comment of ${videosData?.title}`,
        });
        ReactGA.event({
          category: "Comments",
          action: `Deleted a comment of ${videosData?.title}`,
          label: `Deleted a comment of ${videosData?.title}`,
        });
        if (commentsPage === 1) {
          handleGetComments();
        } else {
          setCommentsPage(1);
        }
      });
  };

  const handleLike = async () => {
    if (isLoggedIn) {
      setIsLiking(true);
      try {
        const res = await axios.get(url + "/like", {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        });
        if (res.status === 200) {
          Swal.fire({
            title: res?.data?.message,
            icon: "success",
            width: 600,
            padding: "3em",
            color: "#fff",
            background: "#000",
            timer: 2000,
            showConfirmButton: false,
          });
        }
        setIsLiking(false);
        setLike(!like);
        ReactGA.event({
          category: "Video",
          action: `Liked/Unliked a video`,
          label: `Liked/Unliked a video: ${videosData?.title}`,
        });
        ReactGA.event({
          category: "Video",
          action: `Liked/Unliked a video: ${videosData?.title}`,
          label: `Liked/Unliked a video: ${videosData?.title}`,
        });
      } catch (err) {
        setIsLiking(false);
        Swal.fire({
          title: "Unable to like the song",
          icon: "error",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } else {
      handleLogin();
    }
  };

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Share Button Clicked",
      label: `${label} Shared on ${item} from video details page`,
    });
    ReactGA.event({
      category: "Share",
      action: `${videosData?.title} Shared on ${item}`,
      label: `${label} Shared on ${item} from video details page`,
    });
  }

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={videosData?.title}
        url={window.location.href}
        image={videosData?.cover_image_url}
      />
      {showPlayer &&
        (videoDataIsLoading ? (
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={
              width < 431 ? 240 : width < 835 ? 450 : width < 1233 ? 500 : 570
            }
          />
        ) : (
          <VideoPlayer ref={grid1} {...videoParams} />
        ))}
      <Grid container>
        <Grid
          className={VideoStyles.rightMg}
          item
          xs={12}
          md={videoIsLoading || videos ? 8 : 12}
          lg={videoIsLoading || videos ? 9 : 12}
          xl={videoIsLoading || videos ? 9 : 12}
        >
          {showPlayer && (
            <>
              <div className={VideoStyles.videoFlexDr}>
                <div className={VideoStyles.videoTitleText}>
                  {videoDataIsLoading ? (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={width < 431 ? "100%" : 300}
                      height={30}
                    />
                  ) : (
                    videosData?.title
                  )}
                </div>
                <div className={VideoStyles.videoIconsDiv}>
                  <div className={VideoStyles.videoIconsSubDiv}>
                    <div className={VideoStyles.videoIcons}>
                      {like && videosData?.is_liked ? (
                        <BiSolidLike
                          style={{
                            color: "#fff",
                            fontSize: 22,
                            cursor: "pointer",
                          }}
                          onClick={() => !isLiking && handleLike()}
                        />
                      ) : like ? (
                        <BiSolidLike
                          style={{
                            color: "#fff",
                            fontSize: 22,
                            cursor: "pointer",
                          }}
                          onClick={() => !isLiking && handleLike()}
                        />
                      ) : (
                        <BiLike
                          style={{
                            color: "#fff",
                            fontSize: 22,
                            cursor: "pointer",
                          }}
                          onClick={() => !isLiking && handleLike()}
                        />
                      )}
                      <div className={VideoStyles.vtIconText}>
                        {convertNumUnit(videosData?.like_count)}
                      </div>
                    </div>
                    {width > 600 && (
                      <PlaylistAdd
                        onClick={() => handleOpenPlaylist()}
                        className={VideoStyles.queueIcon}
                      />
                    )}
                    <SharingTooltip
                      title={
                        "Checkout this amazing video: " + videosData?.title
                      }
                      link={window.location.href}
                      transparent
                      onShare={handleShare}
                    />
                  </div>
                  {width < 431 && (
                    <div className={VideoStyles.bgColor}>
                      <PlaylistAdd
                        onClick={() => handleOpenPlaylist()}
                        className={VideoStyles.queueIcon}
                      />
                      <img
                        src={require("../../assets/images/Comments.png")}
                        className={VideoStyles.commentsIcon}
                        onClick={() => handleCommentsClick()}
                        alt="comments"
                      />
                    </div>
                  )}
                </div>
              </div>

              {playlistIsLoading && openPlaylist ? (
                <Modal
                  open={openPlaylist}
                  onClose={() => setOpenPlaylist(false)}
                >
                  <Skeleton
                    sx={{ bgcolor: "grey.900", borderRadius: 10 }}
                    animation="wave"
                    variant="rounded"
                    width={"40%"}
                    height={300}
                  />
                </Modal>
              ) : (
                openPlaylist &&
                !playlistIsLoading && (
                  <Modal
                    open={openPlaylist}
                    onClose={() => setOpenPlaylist(false)}
                    style={{ zIndex: 99999999999 }}
                  >
                    <Playlist
                      playlist={playlist}
                      onClick={onCreatePlaylist}
                      onPlaylistClick={handlePlaylist}
                    />
                  </Modal>
                )
              )}
              {((categories && categories?.length !== 0) ||
                (artistData && artistData?.length !== 0)) && (
                <div className={VideoStyles.vtDiv}>
                  {categories && categories?.length !== 0 && (
                    <div className={VideoStyles.vtSubDiv}>
                      <BiSolidAlbum className={VideoStyles.disc} />
                      <div className={VideoStyles.vtArtistDetails}>
                        <div className={VideoStyles.vtArtistText}>
                          Categories
                        </div>
                        <div className={VideoStyles.vtCategoriesSubText}>
                          {categories?.length !== 0 &&
                            categories.map((item, index) => {
                              if (index < categories.length - 1)
                                return (
                                  <span
                                    key={index}
                                    onClick={() =>
                                      handleNavigate({
                                        categories: item?.slug,
                                      })
                                    }
                                    className={VideoStyles.categories}
                                  >
                                    {item.title},{" "}
                                  </span>
                                );
                              else
                                return (
                                  <span
                                    key={index}
                                    onClick={() =>
                                      handleNavigate({
                                        categories: item?.slug,
                                      })
                                    }
                                    className={VideoStyles.categories}
                                  >
                                    {item.title}
                                  </span>
                                );
                            })}
                        </div>
                      </div>
                    </div>
                  )}
                  {artistData && artistData?.length !== 0 && (
                    <div className={VideoStyles.vtSubDiv}>
                      <div className={VideoStyles.vtArtistDiv}>
                        <img
                          style={{ width: 50, height: 50 }}
                          src={artistData[0]?.profile_photo_url}
                          alt="Views"
                        />
                        <div className={VideoStyles.vtArtistDetails}>
                          <div className={VideoStyles.vtArtistText}>
                            Artists
                          </div>
                          <div className={VideoStyles.vtCategoriesSubText}>
                            {artistData?.length !== 0 &&
                              artistData.map((item, index) => {
                                if (index < artistData.length - 1)
                                  return (
                                    <span
                                      key={index}
                                      onClick={() =>
                                        handleClick(
                                          `/music/artists/${item?.slug}`
                                        )
                                      }
                                      className={VideoStyles.categories}
                                    >
                                      {item?.first_name && item?.first_name}{" "}
                                      {item?.last_name && item?.last_name}
                                      {", "}
                                    </span>
                                  );
                                else
                                  return (
                                    <span
                                      key={index}
                                      onClick={() =>
                                        handleClick(
                                          `/music/artists/${item?.slug}`
                                        )
                                      }
                                      className={VideoStyles.categories}
                                    >
                                      {item?.first_name && item?.first_name}{" "}
                                      {item?.last_name && item?.last_name}
                                    </span>
                                  );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className={VideoStyles.vtViews}>
                <div> {convertNumUnit(videosData?.watch_count)} views</div>
                <div>{convertTime(videosData?.created_at)} ago</div>
              </div>
              <div
                className={`${VideoStyles.vtVideoDescription} ck-content`}
                dangerouslySetInnerHTML={{ __html: videosData?.description }}
              />
              {videosData?.tags && videosData?.tags?.length !== 0 && (
                <div className={VideoStyles.chips}>
                  {videosData?.tags?.map((item) => {
                    return (
                      <Chip
                        label={item.slug}
                        key={item.name}
                        handleClick={() => handleNavigate({ tag: item?.slug })}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
          <div ref={commentsRef}>
            {showComments && (
              <>
                <div className={VideoStyles.queueTitleDiv}>
                  {commentsIsLoading ? (
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={width < 600 ? "100%" : 150}
                      height={40}
                    />
                  ) : (
                    <Label
                      size="md"
                      label={`Comments (${getAllCommentsLength()})`}
                      style={{ marginBottom: 3 }}
                    />
                  )}
                  {width < 431 && (
                    <CloseIcon
                      style={{ fontSize: 18, color: "#fff" }}
                      onClick={() => handleShowPlayer()}
                    />
                  )}
                </div>
                {commentsIsLoading ? (
                  <CommentLoader />
                ) : (
                  <>
                    <CommentList
                      onLike={handleLikeComment}
                      onReply={onReply}
                      onButtonClick={(value) => handlePostComment(value)}
                      data={commentsData}
                      handleDelete={handleDeleteComment}
                      isDeleting={isDeletingComment}
                    />
                    {moreCommentsIsLoading && <CommentLoader />}
                    {commentsPage < commentsLastPage &&
                      !moreCommentsIsLoading && (
                        <ViewMore
                          loadMore={true}
                          onClick={() => {
                            updateCommentPageNumber();
                          }}
                        />
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={videoIsLoading || videos ? 4 : 0}
          lg={videoIsLoading || videos ? 3 : 0}
          xl={videoIsLoading || videos ? 3 : 0}
        >
          {showPlayer && (
            <div className={VideoStyles.queueDiv}>
              {videos && (
                <Label
                  size="md"
                  label={"Related Videos"}
                  style={{ marginBottom: 4 }}
                />
              )}
              <div className={VideoStyles.videoListDiv}>
                {videoIsLoading ? (
                  <>
                    <Skeleton
                      sx={{ bgcolor: "grey.900" }}
                      animation="wave"
                      variant="text"
                      width={width < 600 ? "100%" : 150}
                      height={40}
                    />
                    {Array.from({ length: 8 }, (_, index) => {
                      return (
                        <div key={index}>
                          <Skeleton
                            sx={{ bgcolor: "grey.900" }}
                            animation="wave"
                            variant="rectangular"
                            width={width < 600 ? "100%" : 273}
                            height={181}
                          />
                          <Skeleton
                            sx={{ bgcolor: "grey.900" }}
                            animation="wave"
                            variant="text"
                            width={"70%"}
                          />
                          <Skeleton
                            sx={{ bgcolor: "grey.900" }}
                            animation="wave"
                            variant="text"
                            width={"50%"}
                          />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  videos && (
                    <VideoList
                      size={"sm"}
                      videos={videos}
                      handleClick={handleClick}
                    />
                  )
                )}
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state?.auth?.user,
});

export default connect(mapStateToProps)(VideoScreen);

function VideoList({ videos, size, handleClick }) {
  return videos?.map((item, index) => {
    const date = ExtractDate(item.created_at);
    return (
      <VideoCard
        key={index}
        title={item.title}
        viewCount={item.watch_count}
        likeCount={item.like_count}
        subTitle={date}
        thumbnailLink={item.cover_image_url}
        onClick={() => handleClick(`/videos/${item.slug}`)}
        orientation={"portrait"}
        size={size}
      />
    );
  });
}

const convertTime = (createdAt) => {
  const createdAtDate = new Date(createdAt);
  const now = new Date();

  const diffMs = now - createdAtDate;
  const diffSeconds = Math.floor(diffMs / 1000);
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  if (diffSeconds < 60) {
    const sec = diffSeconds + (diffSeconds === 1 ? " second" : " seconds");
    return `${sec}`;
  } else if (diffMinutes < 60) {
    const min = diffMinutes + (diffMinutes === 1 ? " minute" : " minutes");
    return `${min}`;
  } else if (diffHours < 24) {
    const hour = diffHours + (diffHours === 1 ? " hour" : " hours");
    return `${hour}`;
  } else if (diffDays < 30) {
    const day = diffDays + (diffDays === 1 ? " day" : " days");
    return `${day}`;
  } else if (diffMonths < 12) {
    const month = diffMonths + (diffMonths === 1 ? " month" : " months");
    return `${month}`;
  } else {
    const year = diffYears + (diffYears === 1 ? " year" : " years");
    return `${year}`;
  }
};
