import React from "react";
import FlatlistCss from "./Flatlist.module.css";
import { Grid } from "@mui/material";
import BlogCard from "../../components/blog-card/BlogCard";
import { useNavigate } from "react-router";
import { ExtractShortDate } from "../date-extractor/ExtractDate";
import SlugConverter from "../slug-converter/SlugConverter";
import ReactGA from "react-ga4";

export default function FullWidthBlogsList({ reviews }) {
  const navigate = useNavigate();

  const handleClick = (item) => {
    window.scrollTo(0, 0);
    navigate(SlugConverter(item));
  };

  const handleNavigate = (param) => {
    const params = param?.tag
      ? `tag=${SlugConverter(param.tag)}`
      : `author=${SlugConverter(param.author)}`;
    const route = "/reviews?" + params;
    navigate(route);
    window.scrollTo(0, 0);
  };

  function handleShare(label, item) {
      ReactGA.event({
        category: "Share",
        action: "Review's Share Button Clicked",
        label: label,
      });
      ReactGA.event({
        category: "Share",
        action: `Review's Share Button Clicked(Shared on ${item}`,
        label: label,
      });
  }

  return (
    <Grid container>
      {reviews?.map((item, index) => {
        const date = ExtractShortDate(item.created_at);
        return (
          <Grid
            item
            className={
              index < reviews?.length - 1 ? FlatlistCss.mgb20 : undefined
            }
            xs={12}
            md={11}
            key={index}
          >
            <BlogCard
              title={item.title}
              imageSrc={
                item?.poster_url ??
                require("../../assets/images/no-image-available.png")
              }
              onShare={handleShare}
              footerText={
                <span>
                  Published by{" "}
                  <span
                    className={FlatlistCss.name}
                    onClick={() =>
                      handleNavigate({
                        author: `${item?.author?.first_name} ${item?.author?.last_name}`,
                      })
                    }
                  >
                    {item?.author?.first_name} {item?.author?.last_name}
                  </span>{" "}
                  on {date}
                </span>
              }
              excerpt={item?.excerpt || item?.content}
              tagArray={item?.tags}
              size={"lg"}
              share={true}
              handleChipCallback={(item) => handleNavigate({ tag: item })}
              link={window.location.href.split("?")[0] + `/${item?.slug}`}
              onClick={() => handleClick(`/reviews/${item?.slug}`)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
