// Helper function for query parameters
export function objectToGetParams(object, specialEncoding = false) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => {
      let encodedValue = encodeURIComponent(String(value));
      if (specialEncoding) {
        encodedValue = encodedValue.replace(/%23/g, "#"); // Keep hashtags intact for Facebook
      }
      return `${encodeURIComponent(key)}=${encodedValue}`;
    });

  return params.length > 0 ? `?${params.join("&")}` : "";
}

// Facebook sharing link
export function facebookLink(url) {
  return (
    "https://www.facebook.com/sharer.php" +
    objectToGetParams({ u: url }, true) // Handle Facebook-specific encoding
  );
}

// Reddit sharing link
export function redditLink(url, title) {
  return (
    "https://www.reddit.com/submit" +
    objectToGetParams({
      url,
      title,
    })
  );
}

// Twitter sharing link
export function twitterLink(url, title) {
  return (
    "https://twitter.com/intent/tweet" +
    objectToGetParams({
      url,
      text: title,
    })
  );
}

// WhatsApp sharing link
export function whatsappLink(url, title, separator = " ") {
  return (
    "https://" +
    (isMobileOrTablet() ? "api" : "web") +
    ".whatsapp.com/send" +
    objectToGetParams({
      text: title ? title + separator + url : url,
    })
  );
}

// Email sharing link
export function emailLink(url, subject, body, separator = "\n") {
  return (
    "mailto:" +
    objectToGetParams({
      subject,
      body: body ? body + separator + url : url,
    })
  );
}

// Clipboard copy function with error handling
export const copy = async (link) => {
  try {
    await navigator.clipboard.writeText(link);
    alert("Link copied");
  } catch (err) {
    console.error("Failed to copy the link:", err);
    alert("Failed to copy the link. Please try again.");
  }
};

// Utility function to detect mobile or tablet devices
export function isMobileOrTablet() {
  return /Mobi|Android|iPad|iPhone/i.test(navigator.userAgent);
}
