import React, { useEffect, useState } from "react";
import SortedCss from "./SortedList.module.css";
import { Grid } from "@mui/material";

export default function SortedCategoryList({ data, handleClick }) {
  const [groupedData, setGroupedData] = useState();
  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  useEffect(() => {
    let d = data.reduce((result, element) => {
      let group = element.name[0].toUpperCase();
      if (!result[group]) result[group] = { group, children: [element] };
      else result[group].children.push(element);

      return result;
    }, {});

    setGroupedData(d);
  }, [data]);

  const keysArray = groupedData && Object.keys(groupedData);
  const lastKey = keysArray && keysArray.slice(-1)[0];

  return (
    <div>
      {groupedData &&
        lastKey &&
        alphabets.map((item) => {
          if (groupedData[item]?.group === item) {
            return (
              <div
                key={item}
                className={lastKey === item ? undefined : SortedCss.mgb}
              >
                <div className={SortedCss.heading}>
                  {groupedData[item].group}
                </div>
                <CategoryList
                  handleClick={handleClick}
                  categories={groupedData[item].children}
                />
              </div>
            );
          }
        })}
    </div>
  );
}

function CategoryList({ categories, handleClick }) {
  return (
    <Grid className={SortedCss.topBox} container columnGap={3}>
      {categories.map((item, index) => {
        return (
          <Grid item key={index} xs={5.3} sm={5.3} md={2.8}>
            <div
              onClick={() => handleClick(item.slug)}
              className={SortedCss.top}
            >
              {item.slug}
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}
