import React, { useEffect, useState } from "react";
import { newsletters } from "../data/data";
import ReviewCss from "../Review.module.css";
import Card from "../../../components/card/Card";
import Slider from "react-slick";
import { useNavigate } from "react-router";

export default function ReviewSlides({ reviews }) {
  const [width, setWidth] = useState(window.innerWidth);
  let sliderRef = React.useRef(null);
  const navigate = useNavigate();

  const handleClick = (item) => {
    window.scrollTo(0, 0);
    navigate(item);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      if (window.innerWidth > 431) {
        sliderRef.current.slickGoTo(0);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  var settings = {
    dots: false,
    infinite: reviews?.length < 4 ? false : true,
    speed: 500,
    arrows: false,
    swipeToSlide: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1154,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 712,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function getSlides(data) {
    return data.map((item, index) => {
      return (
        <div key={index}>
          <div className={ReviewCss.eventSlidesDiv}>
            <Card
              onClick={() => handleClick(`/reviews/${item.slug}`)}
              title={item.title}
              imageSrc={item.poster_url ?? require("../../../assets/images/no-image-available.png")}
              textAlign={"center"}
              size={"sm"}
              imageProps={
                width < 431
                  ? { height: 300 }
                  : {
                      height: 350,
                    }
              }
            />
          </div>
        </div>
      );
    });
  }
  return (
    <div className={ReviewCss.mgb}>
      <Slider ref={sliderRef} {...settings}>
        {getSlides(reviews)}
      </Slider>
    </div>
  );
}
