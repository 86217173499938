import React from "react";
import { Skeleton } from "@mui/material";
export default function SingleCategoryLoader() {
  function getRandomPercentage() {
    const randomNumber = Math.floor(Math.random() * (100 - 60 + 1)) + 60;
    return randomNumber.toString() + "%";
  }
  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="circular"
        width={20}
      />
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="text"
        width={getRandomPercentage()}
      />
    </div>
  );
}
