import React, { useEffect, useRef, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { IconButton as MUIIconButton, Popover } from "@mui/material";
import "add-to-calendar-button";
import "./Calendar.css";

export default function CalendarTooltip({
  title = "Sample Event",
  description = null,
  startDate = "2024-05-12",
  endDate = "2024-05-16",
  startTime = "10:15",
  endTime = "17:45",
  location = "Kolkata",
  transparent = false,
  showTooltip = true,
  timezone = "America/Port_of_Spain",
  onCalendarClick = undefined,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [x, setX] = useState();

  const id = open ? "simple-popover" : undefined;

  const getPosition = () => {
    const x = ref?.current?.offsetLeft;
    setX(x);
  };

  useEffect(() => {
    const calendarButton = document.querySelector("add-to-calendar-button");

    if (calendarButton) {
      const handleCalendarClick = () => {
        if (onCalendarClick) {
          onCalendarClick(title);
        }
      };

      calendarButton.addEventListener("click", handleCalendarClick);

      return () => {
        if (calendarButton) {
          calendarButton.removeEventListener("click", handleCalendarClick);
        }
      };
    }
  }, []);

  useEffect(() => {
    getPosition();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getPosition);
  }, []);

  const PopoverArrowStyles = {
    position: "absolute",
    top: "-7px",
    left: x + 10,
    margin: "auto",
    display: "block",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderColor: "#413f3f",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderTop: "0px",
    borderBottom: "16px solid #413f3f",
    zIndex: 9999,
    content: '""',
  };

  return showTooltip ? (
    <div style={{ position: "relative" }}>
      <MUIIconButton
        ref={ref}
        variant="contained"
        aria-describedby={id}
        onClick={handleClick}
        style={{
          backgroundColor: transparent ? "transparent" : "red",
          color: "#fff",
        }}
      >
        <CalendarMonthIcon style={{ color: "#fff", fontSize: 24 }} />
      </MUIIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#413f3f",
            borderRadius: "43px",
            padding: "9px",
            marginTop: "8px",
            overflow: "visible",
          },
          "& .MuiPaper-root:before": open ? PopoverArrowStyles : {},
        }}
      >
        <add-to-calendar-button
          id="css-part-example"
          name={title}
          description={description}
          startDate={startDate}
          startTime={startTime}
          endTime={endTime}
          endDate={endDate}
          iCalFileName={title + "-" + new Date().getTime()}
          timeZone={timezone ?? "America/Port_of_Spain"}
          location={location}
          options="'Apple','Google','Outlook.com','Yahoo','iCal'"
          listStyle="modal"
          buttonStyle="round"
          trigger="click"
          hideTextLabelButton
          buttonsList
          size="4"
          lightMode="light"
        />
      </Popover>
    </div>
  ) : (
    <add-to-calendar-button
      id="css-part-example"
      name={title}
      description={description}
      startDate={startDate}
      startTime={startTime}
      endTime={endTime}
      endDate={endDate}
      iCalFileName={title + "-" + new Date().getTime()}
      timeZone={timezone ?? "America/Port_of_Spain"}
      location={location}
      options="'Apple','Google','Outlook.com','Yahoo','iCal'"
      listStyle="modal"
      buttonStyle="round"
      trigger="click"
      hideTextLabelButton
      buttonsList
      size="4"
      lightMode="light"
    />
  );
}
