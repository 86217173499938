import React, { useEffect, useState } from "react";
import SongCard from "../song-card/SongCard";
import { Grid } from "@mui/material";
import { Reorder } from "framer-motion";

export default function SongsList({
  songArray = [],
  titleColor = null,
  subTitleColor = null,
  footerTextColor = null,
  durationColor = null,
  currentQueue = undefined,
  showQueue = false,
  showGrid = false,
  onClick = undefined,
  imageProps = {},
  titleFontSize = null,
  subTitleFontSize = null,
  footerTextFontSize = null,
  durationFontSize = null,
  handleToAdd = undefined,
  currentSongItem = null,
  handleDeleteFromQueue = undefined,
  handleMoveToTop = undefined,
  handleMoveToBottom = undefined,
  showDelete = false,
  handleDelete = undefined,
  handleDownload = undefined
}) {
  const [updatedList, setUpdatedList] = useState(songArray);
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);
  const [isDragStart, setIsDragStart] = useState(true);

  useEffect(() => {
    if (!isDragStart) {
      if (currentQueue) {
        currentQueue(updatedList);
      }
      setIsDragStart(true);
    }
  }, [updatedList, isDragStart]);

  function handleClick(item, index) {
    onClick(item, index);
    setTitle(item.title);
  }

  useEffect(() => {
    if (currentSongItem?.title) {
      setTitle(currentSongItem?.title);
    } else {
      setTitle("");
    }
  }, [currentSongItem]);

  function handleRemove(item) {
    handleDeleteFromQueue(item);
    setTimeout(() => {
      for (let i = 0; i < updatedList.length; i++) {
        if (updatedList[i].title == item) {
          updatedList.splice(i, 1);
          setShow(!show);
        }
      }
    }, 1000);
  }

  function moveToTop(fromIndex, id) {
    handleMoveToTop(id);
    setTimeout(() => {
      const cutOut = updatedList.splice(fromIndex, 1)[0];
      updatedList.unshift(cutOut);
      setUpdatedList([...updatedList]);
      setShow(!show);
    }, 1000);
  }

  function moveToBottom(fromIndex, id) {
    handleMoveToBottom(id);
    setTimeout(() => {
      const cutOut = updatedList.splice(fromIndex, 1)[0];
      updatedList.push(cutOut);
      setUpdatedList([...updatedList]);
      setShow(!show);
    }, 1000);
  }

  return (
    <>
      {showGrid ? (
        <Grid container columnSpacing={2}>
          {songArray.map((item, index) => {
            return (
              <Grid item xs={12} sm={12} md={6}>
                <SongCard
                  key={index}
                  title={item?.title}
                  thumbnail={item?.thumbnail}
                  subTitle={item?.subTitle}
                  footerText={item?.footerText}
                  length={songArray.length}
                  index={index}
                  duration={convertDurationToTime(item?.duration)}
                  titleColor={titleColor}
                  subTitleColor={subTitleColor}
                  durationColor={durationColor}
                  footerTextColor={footerTextColor}
                  queue={false}
                  grid={true}
                  onClick={() => handleClick(item, index)}
                  showPlayIcon={item.title === title}
                  imageProps={imageProps}
                  titleFontSize={titleFontSize}
                  subTitleFontSize={subTitleFontSize}
                  footerTextFontSize={footerTextFontSize}
                  durationFontSize={durationFontSize}
                  handleToAdd={() => handleToAdd(item)}
                  showDelete={showDelete}
                  handleDelete={() => handleDelete(item?.id)}
                  handleDownload={()=>handleDownload(item)}
                  isDownloadable={item?.is_downloadable}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : showQueue ? (
        <Reorder.Group
          axis="y"
          values={updatedList}
          onReorder={setUpdatedList}
          style={{ listStyle: "none", padding: 0, margin: 0 }}
        >
          {updatedList?.map((item, index) => {
            return (
              <Reorder.Item
                dragElastic={1}
                onDragStart={() => setIsDragStart(true)}
                onDragEnd={() => setIsDragStart(false)}
                key={item.title}
                value={item}
                style={{ listStyle: "none", padding: 0, margin: 0 }}
              >
                <SongCard
                  title={item?.title}
                  thumbnail={item?.thumbnail}
                  subTitle={item?.subTitle}
                  footerText={item?.footerText}
                  length={songArray?.length}
                  index={index}
                  duration={convertDurationToTime(item?.duration)}
                  titleColor={titleColor}
                  subTitleColor={subTitleColor}
                  durationColor={durationColor}
                  footerTextColor={footerTextColor}
                  queue={true}
                  grid={false}
                  onClick={() => handleClick(item, index)}
                  showPlayIcon={item?.title === title}
                  imageProps={imageProps}
                  titleFontSize={titleFontSize}
                  subTitleFontSize={subTitleFontSize}
                  footerTextFontSize={footerTextFontSize}
                  durationFontSize={durationFontSize}
                  handleRemove={() => handleRemove(item?.title)}
                  handleToTop={() => moveToTop(index, item?.id)}
                  handleToBottom={() => moveToBottom(index, item?.id)}
                  showDelete={showDelete}
                  handleDelete={() => handleDelete(item?.id)}
                  handleDownload={()=>handleDownload(item)}
                  isDownloadable={item?.is_downloadable}
                />
              </Reorder.Item>
            );
          })}
        </Reorder.Group>
      ) : (
        <div>
          {songArray.map((item, index) => {
            return (
              <SongCard
                key={index}
                title={item?.title}
                thumbnail={item?.thumbnail}
                subTitle={item?.subTitle}
                footerText={item?.footerText}
                length={songArray.length}
                index={index}
                duration={convertDurationToTime(item?.duration)}
                titleColor={titleColor}
                subTitleColor={subTitleColor}
                durationColor={durationColor}
                footerTextColor={footerTextColor}
                queue={false}
                grid={false}
                onClick={() => handleClick(item, index)}
                showPlayIcon={item.title === title}
                imageProps={imageProps}
                titleFontSize={titleFontSize}
                subTitleFontSize={subTitleFontSize}
                footerTextFontSize={footerTextFontSize}
                durationFontSize={durationFontSize}
                handleToAdd={() => handleToAdd(item)}
                showDelete={showDelete}
                handleDelete={() => handleDelete(item?.id)}
                handleDownload={()=>handleDownload(item)}
                isDownloadable={item?.is_downloadable}
              />
            );
          })}
        </div>
      )}
    </>
  );
}

const convertDurationToTime = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  // Construct the time string based on the presence of hours
  let formattedTime = "";
  if (hours > 0) {
    formattedTime += `${formatNumber(hours)}:${formatNumber(
      minutes
    )}:${formatNumber(seconds)}`;
  } else {
    formattedTime += `${formatNumber(minutes)}:${formatNumber(seconds)}`;
  }
  return formattedTime;
};

const formatNumber = (number) => {
  return number < 10 ? `0${number}` : number;
};
