import React from "react";
import { Skeleton } from "@mui/material";
import LoaderCss from "./loader.module.css";
import { useState, useEffect } from "react";
export default function ArtistAndDjCardLoader() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={LoaderCss.centreContent}>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        animation="wave"
        variant="circular"
        width={width < 431 ? 170 : 214}
        height={width < 431 ? 170 : 214}
      />
      <Skeleton
        sx={{ bgcolor: "grey.900", borderRadius: "5px", marginTop: "5px" }}
        animation="wave"
        variant="text"
        width={width < 431 ? 70 : 100}
        height={25}
      />
    </div>
  );
}
