import { Grid } from "@mui/material";
import React from "react";
import RatingCss from "./RatingBox.module.css";

export default function RatingBox({
  tjjRatingPercentage = 0,
  userRatingPercentage = 0,
  multiline = false,
}) {
  return (
    <div className={RatingCss.overallRatingDiv}>
      <div className={RatingCss.overallHeading}>Score Card</div>
      <Grid container>
        <Grid
          item
          xs={12}
          md={multiline ? 12 : 6}
          className={!multiline ? RatingCss.gridBorderRight : null}
        >
          <div className={RatingCss.overallRatingSubDiv}>
            <div className={RatingCss.overallRatingHead}>TJJ Rating</div>
            <div className={RatingCss.overallRating}>
              {tjjRatingPercentage}%
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={multiline ? 12 : 6}>
          <div className={RatingCss.overallRatingSubDiv}>
            <div className={RatingCss.overallRatingHead}>My Rating</div>
            <div className={RatingCss.overallRating}>
              {userRatingPercentage}%
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
