import React, { useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FontSizes } from "../../../config";
import GalleryCss from "../GalleryHome.module.css";
import TextField from "../../../components/textfield/TextField";
import AutoComplete from "../../../components/autocomplete/AutoComplete";
import Button from "../../../components/button/Button";
import { datePickerStyle, pickerStyle } from "../../../css/DatePickerStyles";
import SlugConverter from "../../../elements/slug-converter/SlugConverter";
import dayjs from "dayjs";
import ExtractStateCountry from "../../../elements/country-footer/ExtractStateCountry";

export default function GalleryFilter({
  locationArray,
  photographerArray,
  onSearch,
  onReset,
  filter,
  tagsOptions,
}) {
  const [region, setRegion] = useState([]);
  const [locations, setLocations] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = React.useState();
  const [search, setSearch] = useState("");
  const [photographer, setPhotographer] = useState([]);
  const [photographers, setPhotographers] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);

  useEffect(() => {
    const op = locationArray?.map((item, index) => ({
      id: index,
      name: ExtractStateCountry(item),
      slug: ExtractStateCountry(item),
    }));
    if (op) {
      setLocations(op);
    }
  }, [locationArray]);

  useEffect(() => {
    const op = tagsOptions?.map((item) => ({
      id: item?.id,
      name: item?.slug,
      slug: item?.slug,
    }));
    if (op) {
      setTagsArray(op);
    }
  }, [tagsOptions]);

  useEffect(() => {
    const op = photographerArray?.map((item) => ({
      id: item?.id,
      name: `${item?.first_name} ${item?.last_name}`,
      slug: item?.slug,
    }));
    if (op) {
      setPhotographers(op);
    }
  }, [photographerArray]);

  function handleFilter() {
    onReset();
    let params = {};
    if (region!=="") {
      params = {
        ...params,
        region: region,
      };
    }
    if (search !== "") {
      params = {
        ...params,
        search: search,
      };
    }
    if (photographer.length !== 0) {
      params = {
        ...params,
        photographer: photographer,
      };
    }
    if (tags?.length !== 0) {
      params = {
        ...params,
        tag: tags,
      };
    }
    if (startDate) {
      params = {
        ...params,
        startDate: startDate,
      };
    }
    if (endDate) {
      params = {
        ...params,
        endDate: endDate,
      };
    }
    onSearch(params);
  }

  function handleReset() {
    setRegion([]);
    setStartDate(null);
    setEndDate(null);
    setPhotographer([]);
    setSearch("");
    setTags([]);
    onReset();
  }

  useEffect(() => {
    if (filter) {
      if (filter?.region) {
        if (filter?.region !== region) {
          setRegion(filter?.region);
        }
      }
      if (filter?.photographer?.length !== 0) {
        const filtered = filter?.photographer?.filter(
          (item) => !photographer.includes(item)
        );
        if (filtered && filtered?.length !== 0) {
          setPhotographer(filtered);
        }
      }
      if (filter?.tag?.length !== 0) {
        const filtered = filter?.tag?.filter((item) => !tags.includes(item));
        if (filtered && filtered?.length !== 0) {
          setTags(filtered);
        }
      }
      if (filter?.search) {
        if (filter?.search !== search) {
          setSearch(filter?.search);
        }
      }
      if (filter?.startDate) {
        if (filter?.startDate !== startDate) {
          setStartDate(filter.startDate);
        }
      }
      if (filter?.endDate) {
        if (filter?.endDate !== endDate) {
          setEndDate(filter.endDate);
        }
      }
    }
  }, [filter]);

  const handleStartDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setStartDate(d);
  };

  const handleEndDate = (val) => {
    const d = val.format("YYYY-MM-DD");
    setEndDate(d);
  };

  return (
    <div className={GalleryCss.filter}>
      <div className={GalleryCss.calendarHead}>Filters</div>
      {/* <div className={GalleryCss.date}>Advanced search</div> */}
      <TextField
        label="Search"
        onChange={(value) => setSearch(value)}
        defaultValue={search}
        size="small"
      />
      {locations?.length !== 0 && (
        <AutoComplete
            defaultValue={region}
            handleOnChange={(val) => setRegion(val)}
            options={locations}
            label={"Locations"}
            singleSelect={true}
            isSlug={true}
        />
      )}
      <AutoComplete
        defaultValue={photographer}
        handleOnChange={(val) => setPhotographer(val)}
        options={photographers}
        label="Photographers"
        isSlug={true}
      />
        {tagsArray?.length !== 0 && (
        <AutoComplete
          defaultValue={tags}
          handleOnChange={(val) => setTags(val)}
          options={tagsArray}
          label="Tags"
          isSlug={true}
        />
      )}
      <div className={GalleryCss.date}>Date Range</div>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            sx={datePickerStyle}
            slotProps={{
              textField: { size: "small" },
              layout: {
                sx: pickerStyle,
              },
            }}
            label="Start date"
            value={startDate ? dayjs(startDate) : null}
            onChange={(newValue) => handleStartDate(newValue)}
          />
        </LocalizationProvider>
        <div className={GalleryCss.to}>to</div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            sx={datePickerStyle}
            slotProps={{
              textField: { size: "small" },
              layout: {
                sx: pickerStyle,
              },
            }}
            label="End date"
            value={endDate ? dayjs(endDate) : null}
            onChange={(newValue) => handleEndDate(newValue)}
          />
        </LocalizationProvider>
      </div>
      <div className={GalleryCss.buttonsDiv}>
        <Button
          size={"small"}
          label="Reset"
          backgroundColor={"transparent"}
          color={"#ff0000"}
          styles={{ fontWeight: "600", fontSize: FontSizes.text }}
          onClick={() => handleReset()}
        />
        <Button
          size={"small"}
          onClick={() => {
            handleFilter();
          }}
          label="Search"
        />
      </div>
    </div>
  );
}
