import React, { useEffect, useState } from "react";
import TextField from "../../../components/textfield/TextField";
import AccountsCss from "../AccountsCss.module.css";
import { Box } from "@mui/material";
import Button from "../../../components/button/Button";

export default function EditItinerary({
  onClick,
  setOpen,
  name,
  itineraryDescription,
  isPlaylist = false,
}) {
  const [itineraryName, setItineraryName] = useState("");
  const [itineraryNameError, setItineraryNameError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);

  useEffect(() => {
    if (name) {
      setItineraryName(name);
    }
    if (itineraryDescription) {
      setDescription(itineraryDescription);
    }
  }, [name, itineraryDescription]);

  const handleClick = () => {
    let valid = true;
    if (itineraryName === "") {
      setItineraryNameError(true);
      valid = false;
    }
    if (description === "") {
      setDescriptionError(true);
      valid = false;
    }
    if (valid) {
      const body = {
        title: itineraryName,
        description: description,
      };
      onClick(body);
      setOpen(false);
    }
  };

  return (
    <Box className={AccountsCss.subBox}>
      <div className={AccountsCss.head}>
        Edit {isPlaylist ? "Playlist" : "Itinerary"}
      </div>
      <div className={AccountsCss.mgTop}>
        <TextField
          label={isPlaylist ? "Playlist Name" : "Itinerary Name"}
          onChange={(value) => setItineraryName(value)}
          defaultValue={itineraryName}
          size={"small"}
          error={itineraryNameError}
        />
      </div>
      <div className={AccountsCss.mgTop}>
        <TextField
          label="Description"
          onChange={(event) => setDescription(event)}
          defaultValue={description}
          size={"small"}
          rows={3}
          multiline
          error={descriptionError}
        />
      </div>
      <div className={AccountsCss.buttonsDiv}>
        <Button
          onClick={() => setOpen(false)}
          size={"medium"}
          label="Cancel"
          backgroundColor={"transparent"}
        />
        <Button
          onClick={() => handleClick()}
          disabled={itineraryName === ""}
          size={"medium"}
          label="Edit"
        />
      </div>
    </Box>
  );
}
