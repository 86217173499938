import React from "react";

export function TimeExtractor(timeS) {
  // const time = new Date(timeS);
  // const hours = time.getUTCHours();
  // const mins = time.getUTCMinutes();
  // const ampm = hours >= 12 ? "pm" : "am";
  // const formattedHours =
  //   hours > 12 ? (hours % 12 >= 10 ? hours % 12 : "0" + (hours % 12)) : hours;
  // const formattedMins = mins >= 10 ? mins : "0" + mins;

  const timePart = timeS.split("T")[1]; // Extract the time part
  const time = timePart.split(".")[0]; // Remove milliseconds and timezone

  // Split the time into hours, minutes, and seconds
  const [hours, minutes, seconds] = time.split(":").map(Number);

  // Format hours, minutes, and determine AM/PM
  const formattedHours = hours % 12 || 12;
  const formattedMins = minutes < 10 ? `0${minutes}` : minutes;
  const ampm = hours >= 12 ? "pm" : "am";

  return `${formattedHours}:${formattedMins} ${ampm}`;
}

export function EventTimeExtractor(timeS) {
  const timePart = timeS.split("T")[1]; // Split the date and time
  const time = timePart.split(".")[0]; // Remove milliseconds and timezone
  return time;
}
