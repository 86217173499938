import React from "react";
export default function NoData({ image, width, height, error, errorCode }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={
          error
            ? require("../../assets/images/404.png")
            : image
              ? image
              : errorCode === 500
              ? require("../../assets/images/Server_error.png")
              : require("../../assets/images/404.png")
        }
        alt="No Data"
        width={width ? width : "70%"}
        height={height ? height : "auto"}
      />
    </div>
  );
}
