import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import CarnivalList from "../../elements/Flatlist/CarnivalList";
import CarnivalCss from "./CarnivalHome.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { FontSizes } from "../../config";
import { LocationOn } from "@mui/icons-material";
import { FaCalendarAlt } from "react-icons/fa";
import { Context } from "../../Context";
import CountryFooter from "../../elements/country-footer/CountryFooter";
import { Grid } from "@mui/material";
import Card from "../../components/card/Card";
import PhotoCardLoader from "../../elements/skeletonLoaders/PhotoCardLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import MetaTags from "../../MetaTags";
import { Skeleton } from "@mui/material";
import ReLiveCardLoader from "../../elements/skeletonLoaders/ReLiveCardLoader";
import ReactGA from "react-ga4";

export default function WorldCarnival() {
  const [layout, setLayout] = useState("list");
  const [width, setWidth] = useState(window.innerWidth);
  const { setPageTitle } = useContext(Context);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [worldCarnivals, setWorldCarnivals] = useState([]);
  const [worldIsLoading, setWorldIsLoading] = useState(true);
  const [recentIsLoading, setRecentIsLoading] = useState(true);
  const [recentCarnival, setRecentCarnival] = useState([]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/world-carnival",
      title: "World Carnival",
    });
  }, []);

  useEffect(() => {
    setPageTitle("World Carnivals");
    handleWorldCarnivals();
    handleGetRecentCarnivals();
  }, []);

  const handleWorldCarnivals = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_WORLD_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
      });
      if (res.status === 200) {
        setWorldCarnivals(res.data.carnivals);
        setWorldIsLoading(false);
      }
    } catch (err) {
      setWorldIsLoading(false);
    }
  };

  const handleGetRecentCarnivals = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_RECENT_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.RECENT_CARNIVALS,
        },
      });
      if (res.status === 200) {
        setRecentCarnival(res.data.data);
        setRecentIsLoading(false);
      }
    } catch (err) {
      setRecentIsLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const handleCountry = (item) => {
    const route = `/carnivals?region=${item}`;
    navigate(route);
    window.scrollTo(0, 0);
  };

  // This useEffect will detect whether the layout is list or grid.
  useEffect(() => {
    if (searchParams.size !== 0) {
      if (searchParams?.get("layout")) {
        setLayout(searchParams.get("layout"));
      }
    }
  }, [searchParams]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#515151",
      color: "#fff",
      fontSize: FontSizes.heading,
      fontWeight: "700",
      fontFamily: "Inter-Bold",
      border: "1px solid #636363",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: FontSizes.body,
      fontWeight: "500",
      fontFamily: "Inter-Medium",
      color: "#fff",
      border: "1px solid #636363",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#202020",
      border: "1px solid #636363",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#363636",
      border: "1px solid #636363",
    },
    // hide last border
  }));

  //Update the layout from list to grid or vice-versa
  const handleChange = (item) => {
    setLayout(item);
    setSearchParams({ layout: item });
  };

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"World Carnival"}
        url={window.location.href}
        type={"website"}
        image="../../assets/images/tjj-logo.jpg "
      />
      <div className={CarnivalCss.worldCarnivalMainDiv}>
        <Label size="lg" label="World Carnivals" />

        <div className={CarnivalCss.capsuleMainDiv}>
          <div
            className={CarnivalCss.capsuleL}
            onClick={() => handleChange("grid")}
            style={{
              backgroundColor: layout === "grid" ? "#ff0000" : "#515151",
            }}
          >
            Grid
          </div>
          <div
            className={CarnivalCss.capsuleR}
            onClick={() => handleChange("list")}
            style={{
              backgroundColor: layout === "list" ? "#ff0000" : "#515151",
            }}
          >
            List
          </div>
        </div>
      </div>
      {worldIsLoading ? (
        layout === "grid" ? (
          <>
            <Skeleton
              sx={{ bgcolor: "grey.900", margin: "20px" }}
              variant="text"
              width={80}
            />
            <div className={CarnivalCss.flexDrR}>
              {Array.from({ length: 3 }, (_, index) => {
                return <PhotoCardLoader key={index} width={320} height={230} />;
              })}
            </div>
            <Skeleton
              sx={{ bgcolor: "grey.900", margin: "20px" }}
              variant="text"
              width={80}
            />{" "}
            <div className={CarnivalCss.flexDrR}>
              {Array.from({ length: 3 }, (_, index) => {
                return <PhotoCardLoader key={index} width={320} height={230} />;
              })}
            </div>
            <Skeleton
              sx={{ bgcolor: "grey.900", margin: "20px" }}
              variant="text"
              width={80}
            />
            <div className={CarnivalCss.flexDrR}>
              {Array.from({ length: 3 }, (_, index) => {
                return <PhotoCardLoader key={index} width={320} height={230} />;
              })}
            </div>
          </>
        ) : (
          <>
            {width < 431 ? (
              <TableContainer>
                <Table
                  sx={{ maxWidth: width - 32 }}
                  aria-label="spanning table"
                >
                  <TableBody>
                    {Array.from(new Array(5)).map((_, index) => {
                      return index === 0 ? (
                        <>
                          <Skeleton
                            sx={{ bgcolor: "grey.700" }}
                            animation="wave"
                            variant="text"
                            width={100}
                          />
                          <StyledTableRow
                            sx={{
                              "& .MuiTableCell-body": {
                                backgroundColor: "transparent",
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell>
                              <div>
                                <div className={CarnivalCss.title}>
                                  <Skeleton
                                    sx={{ bgcolor: "grey.700" }}
                                    animation="wave"
                                    variant="text"
                                    width={100}
                                  />
                                </div>
                                <div className={CarnivalCss.flexDrC}>
                                  <div className={CarnivalCss.subText}>
                                    <LocationOn
                                      className={CarnivalCss.locationIcon}
                                    />
                                    <Skeleton
                                      sx={{ bgcolor: "grey.700" }}
                                      animation="wave"
                                      variant="text"
                                      width={100}
                                    />
                                  </div>
                                  <div className={CarnivalCss.subText}>
                                    <FaCalendarAlt
                                      className={CarnivalCss.calendarIcon}
                                    />
                                    <Skeleton
                                      sx={{ bgcolor: "grey.700" }}
                                      animation="wave"
                                      variant="text"
                                      width={100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      ) : (
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            <div>
                              <div className={CarnivalCss.title}>
                                <Skeleton
                                  sx={{ bgcolor: "grey.700" }}
                                  animation="wave"
                                  variant="text"
                                  width={100}
                                />
                              </div>
                              <div className={CarnivalCss.flexDrC}>
                                <div className={CarnivalCss.subText}>
                                  <LocationOn
                                    className={CarnivalCss.locationIcon}
                                  />
                                  <Skeleton
                                    sx={{ bgcolor: "grey.700" }}
                                    animation="wave"
                                    variant="text"
                                    width={100}
                                  />
                                </div>
                                <div className={CarnivalCss.subText}>
                                  <FaCalendarAlt
                                    className={CarnivalCss.calendarIcon}
                                  />
                                  <Skeleton
                                    sx={{ bgcolor: "grey.700" }}
                                    animation="wave"
                                    variant="text"
                                    width={100}
                                  />
                                </div>
                              </div>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell colSpan={2} align="center">
                        Carnival Name
                      </StyledTableCell>
                      <StyledTableCell align="center">Location</StyledTableCell>
                      <StyledTableCell align="center">Date</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from(new Array(5)).map((_, index) => {
                      return Array.from(new Array(3)).map((_, index) => {
                        return index === 0 ? (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              rowSpan={3}
                              sx={{
                                transform: "rotate(270deg)",
                                transformOrigin: "center",
                                maxWidth: 30,
                              }}
                            >
                              <Skeleton
                                sx={{ bgcolor: "grey.700" }}
                                animation="wave"
                                variant="text"
                                width={60}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {" "}
                              <div className={CarnivalCss.title}>
                                <Skeleton
                                  sx={{ bgcolor: "grey.700" }}
                                  animation="wave"
                                  variant="text"
                                  width={60}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <div className={CarnivalCss.subText}>
                                <Skeleton
                                  sx={{ bgcolor: "grey.700" }}
                                  animation="wave"
                                  variant="text"
                                  width={60}
                                />
                              </div>{" "}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Skeleton
                                sx={{ bgcolor: "grey.700" }}
                                animation="wave"
                                variant="text"
                                width={60}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="left">
                              <div className={CarnivalCss.title}>
                                <Skeleton
                                  sx={{ bgcolor: "grey.700" }}
                                  animation="wave"
                                  variant="text"
                                  width={60}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <div className={CarnivalCss.subText}>
                                <Skeleton
                                  sx={{ bgcolor: "grey.700" }}
                                  animation="wave"
                                  variant="text"
                                  width={60}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Skeleton
                                sx={{ bgcolor: "grey.700" }}
                                animation="wave"
                                variant="text"
                                width={100}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      });
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )
      ) : layout === "grid" ? (
        worldCarnivals.map((item, index) => (
          <div key={index} className={CarnivalCss.mgb}>
            <Label size="md" label={item.month} />
            <CarnivalList gallery={item.carnivals} />
          </div>
        ))
      ) : (
        <>
          {width < 431 ? (
            <TableContainer>
              <Table sx={{ maxWidth: width - 32 }} aria-label="spanning table">
                <TableBody>
                  {worldCarnivals.map((item, ind) => {
                    return (
                      <>
                        {item.carnivals.map((d, index) => {
                          return (
                            <>
                              {index === 0 ? (
                                <>
                                  <Label
                                    size="md"
                                    label={item.month}
                                    style={{ marginTop: ind !== 0 ? 4 : 0 }}
                                  />
                                  <StyledTableRow
                                    sx={{
                                      "& .MuiTableCell-body": {
                                        backgroundColor: "transparent",
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <StyledTableCell>
                                      <div>
                                        <div
                                          onClick={() => {
                                            handleClick(`/carnival/${d.slug}`);
                                          }}
                                          className={CarnivalCss.title}
                                        >
                                          {d.title}
                                        </div>
                                        <div className={CarnivalCss.flexDrC}>
                                          <div
                                            className={CarnivalCss.subText}
                                            onClick={() => {
                                              handleCountry(d.location);
                                            }}
                                          >
                                            <LocationOn
                                              className={
                                                CarnivalCss.locationIcon
                                              }
                                            />{" "}
                                            {d.location}
                                          </div>
                                          <div className={CarnivalCss.subText}>
                                            <FaCalendarAlt
                                              className={
                                                CarnivalCss.calendarIcon
                                              }
                                            />{" "}
                                            {d.date}
                                          </div>
                                        </div>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </>
                              ) : (
                                <StyledTableRow>
                                  <StyledTableCell align="left">
                                    <div>
                                      <div
                                        className={CarnivalCss.title}
                                        onClick={() => {
                                          handleClick(`/carnival/${d.title}`);
                                        }}
                                      >
                                        {d.title}
                                      </div>
                                      <div className={CarnivalCss.flexDrC}>
                                        <div
                                          className={CarnivalCss.subText}
                                          onClick={() => {
                                            handleCountry(d.location);
                                          }}
                                        >
                                          <LocationOn
                                            className={CarnivalCss.locationIcon}
                                          />{" "}
                                          {d.location}
                                        </div>
                                        <div className={CarnivalCss.subText}>
                                          <FaCalendarAlt
                                            className={CarnivalCss.calendarIcon}
                                          />{" "}
                                          {d.date}
                                        </div>
                                      </div>
                                    </div>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell colSpan={2} align="center">
                      Carnival Name
                    </StyledTableCell>
                    <StyledTableCell align="center">Location</StyledTableCell>
                    <StyledTableCell align="center">Date</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {worldCarnivals.map((item) => {
                    return item.carnivals.map((d, index) => {
                      return index === 0 ? (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            rowSpan={item.carnivals.length}
                            sx={{
                              maxWidth: 30,
                              position: "relative",
                            }}
                          >
                            <div className={CarnivalCss.textTransform}>
                              {item.month}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {" "}
                            <div
                              className={CarnivalCss.title}
                              onClick={() => {
                                handleClick(`/carnival/${d.slug}`);
                              }}
                            >
                              {" "}
                              {d.title}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <div
                              onClick={() => {
                                handleCountry(d.location);
                              }}
                              className={CarnivalCss.subText}
                            >
                              {d.location}
                            </div>{" "}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {d.date}
                          </StyledTableCell>
                        </StyledTableRow>
                      ) : (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="left">
                            <div
                              className={CarnivalCss.title}
                              onClick={() => {}}
                            >
                              {" "}
                              <div
                                className={CarnivalCss.title}
                                onClick={() => {
                                  handleClick(`/carnival/${d.slug}`);
                                }}
                              >
                                {d.title}
                              </div>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <div
                              onClick={() => {
                                handleCountry(d.location);
                              }}
                              className={CarnivalCss.subText}
                            >
                              {d.location}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {d.date}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    });
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
      <div className={CarnivalCss.mgb} />
      {recentIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        recentCarnival.length > 0 && (
          <Label label={"Re-Live Recent Carnival Experiences"} size="md" />
        )
      )}
      <Grid
        className={CarnivalCss.mgb}
        container
        rowSpacing={4}
        columnSpacing={2}
      >
        {recentIsLoading
          ? Array.from({ length: 6 }, (_, index) => {
              return (
                <Grid key={index} item xs={12} md={4}>
                  <ReLiveCardLoader />
                </Grid>
              );
            })
          : recentCarnival.map((item, index) => {
              return (
                <Grid key={index} item xs={12} md={4}>
                  <Card
                    title={item.title}
                    imageSrc={item.poster_url}
                    textAlign={"center"}
                    onClick={() => handleClick(`/carnival/${item.slug}`)}
                    size={"md"}
                    showLinearFillShadow={true}
                  />
                </Grid>
              );
            })}
      </Grid>
      <CountryFooter by={"Carnivals"} handleClick={handleCountry} />
    </NavContainer>
  );
}
