import React, { useContext, useEffect, useState, useCallback } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import { Context } from "../../Context";
import CarnivalClassifiedsCss from "./CarnivalClassifieds.module.css";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ClassifiedChatCard from "../../components/classified-chat-card/ClassifiedChatCard";
import SendIcon from "@mui/icons-material/Send";
import { IconButton, Skeleton } from "@mui/material";
import TextField from "../../components/textfield/TextField";
import Rating from "../../components/rating/Rating";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import ChatLoader from "../../elements/skeletonLoaders/ChatLoader";
import axios from "axios";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import RatingModal from "./elements/RatingModal.js";
import ReactGA from "react-ga4";

const ClassifiedsChatbox = ({ user }) => {
  const { id } = useParams();
  const { setPageTitle } = useContext(Context);
  const [width, setWidth] = useState(window.innerWidth);
  const { state } = useLocation();
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [toUser, setToUser] = useState({});
  const [data, setData] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [chatboxPageNumber, setChatboxPageNumber] = useState(1);
  const [chatboxLastPage, setChatboxLastPage] = useState(null);
  const [message, setMessage] = useState("");
  const [olderData, setOlderData] = useState([]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/carnival/classifieds/${id}/inbox/chatbox`,
      title: "Carnival Chatbox",
    });
  }, []);

  useEffect(() => {
    if (state?.user) {
      setToUser(state?.user);
    }
  }, [state]);

  const fetchMessages = async () => {
    try {
      const res = await axios.get(
        `${SERVER_URL.GET_INBOX_DATA}/${toUser?.id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
          params: {
            page: 1,
            items: PER_PAGE_COUNT.GET_INBOX,
            orderDirection: "desc",
          },
        }
      );
      if (res.status === 200 && res?.data?.messages?.data) {
        const newMessages = res?.data?.messages?.data?.reverse();
        setChatboxLastPage(res?.data?.messages?.last_page);
        if (data?.length === 0) {
          setData(newMessages);
        } else {
          setData((prev) => [...prev, ...newMessages]);
        }
        setDataIsLoading(false);
      }
    } catch (err) {
      setDataIsLoading(false);
    }
  };

  const fetchOlderMessages = async () => {
    try {
      const res = await axios.get(
        `${SERVER_URL.GET_INBOX_DATA}/${toUser?.id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
          params: {
            page: chatboxPageNumber,
            items: PER_PAGE_COUNT.GET_INBOX,
            orderDirection: "desc",
          },
        }
      );
      if (res.status === 200 && res?.data?.messages?.data) {
        const newMessages = res?.data?.messages?.data?.reverse();
        setOlderData((prev) => [...newMessages, ...prev]);
      }
    } catch (err) {}
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setPageTitle("Chatbox");
  }, []);

  useEffect(() => {
    if (toUser?.id && user?.id && chatboxPageNumber !== 1) {
      fetchOlderMessages();
    }
  }, [toUser, chatboxPageNumber, user]);

  useEffect(() => {
    if (toUser?.id && user?.id) {
      fetchMessages();
    }
  }, [toUser, user]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0 && chatboxPageNumber < chatboxLastPage) {
        const newPageNumber = chatboxPageNumber + 1;
        setChatboxPageNumber(newPageNumber);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [chatboxPageNumber, chatboxLastPage]);

  const sendMessage = async () => {
    const body = {
      user_id: toUser?.id,
      message: message,
    };
    try {
      const res = await axios.post(SERVER_URL.SEND_MESSAGE, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (res.status === 200) {
        setMessage("");
        fetchMessages();
      }
    } catch (err) {}
  };

  const submitReview = async (body) => {
    try {
      const res = await axios.post(
        `${SERVER_URL.GET_INBOX_DATA}/${toUser?.id}/review`,
        body,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if(res.status===200)
      {
        ReactGA.event({
          category: "Ratings",
          action: "Ratings Submitted",
          label:`Ratings submitted for ${toUser?.first_name} ${toUser?.last_name}`
        });
      }
    } catch (err) {}
  };

  // Fetch newer messages every 10 seconds
  useEffect(() => {
    if (user?.id && toUser?.id) {
      const interval = setInterval(() => {
        fetchMessages();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [toUser?.id, user]);

  return (
    <NavContainer stickyHead={true} useFooter={false} useScrollToTop={false}>
      {dataIsLoading ? (
        <div className={CarnivalClassifiedsCss.inboxDiv}>
          <div className={CarnivalClassifiedsCss.classifiedChatboxSubDiv}>
            <div className={CarnivalClassifiedsCss.container}>
              <div className={CarnivalClassifiedsCss.label1}>
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginBottom: 0.2 }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                  height={25}
                />
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                  height={25}
                />
              </div>
              <div className={CarnivalClassifiedsCss.label1}>
                <div className={CarnivalClassifiedsCss.flex}>
                  <Skeleton
                    sx={{
                      bgcolor: "grey.900",
                      minWidth: "40px",
                      minHeight: "40px",
                    }}
                    animation="wave"
                    width={40}
                    height={40}
                    variant="circular"
                  />
                  <Skeleton
                    sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                    animation="wave"
                    variant="rounded"
                    width={width < 431 ? "100%" : 350}
                    height={20}
                  />
                </div>
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                  height={25}
                />
              </div>
              <div className={CarnivalClassifiedsCss.label1}>
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginBottom: 0.2 }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                  height={25}
                />
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                  height={25}
                />
              </div>
            </div>
            <ChatLoader />
          </div>
        </div>
      ) : (
        <div className={CarnivalClassifiedsCss.inboxDiv}>
          <div className={CarnivalClassifiedsCss.classifiedChatboxSubDiv}>
            <div className={CarnivalClassifiedsCss.container}>
              {width < 834 ? (
                <>
                  <div className={CarnivalClassifiedsCss.label2}>
                    <div className={CarnivalClassifiedsCss.flex}>
                      {toUser?.profile_photo_url ? (
                        <img
                          className={
                            CarnivalClassifiedsCss.classifiedCardAvatar
                          }
                          src={toUser?.profile_photo_url}
                          alt="user"
                        />
                      ) : (
                        <div
                          className={
                            CarnivalClassifiedsCss.classifiedChatCardAvatar
                          }
                        >
                          <div
                            className={
                              CarnivalClassifiedsCss.classifiedChatCardUsername
                            }
                          >
                            {toUser &&
                              toUser?.first_name &&
                              toUser?.first_name[0]}
                          </div>
                        </div>
                      )}
                      <div
                        className={
                          CarnivalClassifiedsCss.classifiedCardUsername
                        }
                      >
                        {toUser?.first_name} {toUser?.last_name}
                      </div>
                      {isOpened ? (
                        <MdKeyboardArrowUp
                          onClick={() => setIsOpened(false)}
                          className={CarnivalClassifiedsCss.tabsArrow}
                        />
                      ) : (
                        <MdKeyboardArrowDown
                          onClick={() => setIsOpened(true)}
                          className={CarnivalClassifiedsCss.tabsArrow}
                        />
                      )}
                    </div>
                    <div className={CarnivalClassifiedsCss.subTitle}>
                      Account Created: {convertTime(toUser?.created_at)} ago
                    </div>
                  </div>
                  {isOpened && (
                    <>
                      <div className={CarnivalClassifiedsCss.label1}>
                        {toUser?.location_address && (
                          <>
                            Location
                            <div className={CarnivalClassifiedsCss.subTitle}>
                              {toUser?.location_address}
                            </div>
                          </>
                        )}
                      </div>
                      <div className={CarnivalClassifiedsCss.label3}>
                        Reviews:
                        <Rating
                          value={toUser?.rating}
                          readOnly
                          size={"large"}
                        />
                        <RatingModal onClick={submitReview} />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className={CarnivalClassifiedsCss.label1}>
                    {toUser?.location_address && (
                      <>
                        Location
                        <div className={CarnivalClassifiedsCss.subTitle}>
                          {toUser?.location_address}
                        </div>
                      </>
                    )}
                  </div>
                  <div className={CarnivalClassifiedsCss.label2}>
                    <div className={CarnivalClassifiedsCss.flex}>
                      {toUser?.profile_photo_url ? (
                        <img
                          className={
                            CarnivalClassifiedsCss.classifiedCardAvatar
                          }
                          src={toUser?.profile_photo_url}
                          alt="user"
                        />
                      ) : (
                        <div
                          className={
                            CarnivalClassifiedsCss.classifiedChatCardAvatar
                          }
                        >
                          <div
                            className={
                              CarnivalClassifiedsCss.classifiedChatCardUsername
                            }
                          >
                            {toUser &&
                              toUser?.first_name &&
                              toUser?.first_name[0]}
                          </div>
                        </div>
                      )}
                      <div
                        className={
                          CarnivalClassifiedsCss.classifiedCardUsername
                        }
                      >
                        {toUser?.first_name} {toUser?.last_name}
                      </div>
                    </div>
                    <div className={CarnivalClassifiedsCss.subTitle}>
                      Account Created: {convertTime(toUser?.created_at)} ago
                    </div>
                  </div>
                  <div className={CarnivalClassifiedsCss.label3Div}>
                    <div className={CarnivalClassifiedsCss.label3}>
                      Reviews
                      <Rating value={toUser?.rating} readOnly size={"large"} />
                      <RatingModal onClick={submitReview} />
                    </div>
                  </div>
                </>
              )}
            </div>
            {olderData?.length > 0 &&
              olderData?.map((item, index) => {
                const currentUser = item?.from_user?.id === user?.id;
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: currentUser ? "flex-end" : "flex-start",
                    }}
                  >
                    <ClassifiedChatCard
                      messageText={item?.message_text}
                      user={item?.from_user}
                      role={currentUser ? "sender" : "receiver"}
                    />
                  </div>
                );
              })}
            {data?.map((item, index) => {
              const currentUser = item?.from_user?.id === user?.id;
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: currentUser ? "flex-end" : "flex-start",
                  }}
                >
                  <ClassifiedChatCard
                    messageText={item?.message_text}
                    user={item?.from_user}
                    role={currentUser ? "sender" : "receiver"}
                  />
                </div>
              );
            })}
            <div className={CarnivalClassifiedsCss.textField}>
              <div className={CarnivalClassifiedsCss.commentsSubDiv}>
                <TextField
                  placeholder={"Type anything...."}
                  defaultValue={message}
                  onChange={(val) => setMessage(val)}
                  size={"medium"}
                  onKeyDown={handleKeyPress}
                />
                <IconButton
                  sx={{
                    right: 6,
                    display: "flex",
                    alignSelf: "center",
                    backgroundColor: "transparent",
                    position: "absolute",
                  }}
                  disabled={message === ""}
                  onClick={() => sendMessage()}
                >
                  <SendIcon style={{ color: "#fff", fontSize: 26 }} />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </NavContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps)(ClassifiedsChatbox);

const convertTime = (createdAt) => {
  const createdAtDate = new Date(createdAt);
  const now = new Date();

  const diffMs = now - createdAtDate;
  const diffSeconds = Math.floor(diffMs / 1000);
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  if (diffSeconds < 60) {
    const sec = diffSeconds + (diffSeconds === 1 ? " second" : " seconds");
    return `${sec}`;
  } else if (diffMinutes < 60) {
    const min = diffMinutes + (diffMinutes === 1 ? " minute" : " minutes");
    return `${min}`;
  } else if (diffHours < 24) {
    const hour = diffHours + (diffHours === 1 ? " hour" : " hours");
    return `${hour}`;
  } else if (diffDays < 30) {
    const day = diffDays + (diffDays === 1 ? " day" : " days");
    return `${day}`;
  } else if (diffMonths < 12) {
    const month = diffMonths + (diffMonths === 1 ? " month" : " months");
    return `${month}`;
  } else {
    const year = diffYears + (diffYears === 1 ? " year" : " years");
    return `${year}`;
  }
};
