import React, { useState, useEffect } from "react";
import scrollToTopCss from "./scrollToTop.module.css";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function scroll() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      {isVisible && (
        <div onClick={() => scroll()} className={scrollToTopCss.absolute}>
          <KeyboardArrowUpRoundedIcon
            sx={{ fontSize: "50px" }}
            className={scrollToTopCss.icon}
          />
        </div>
      )}
    </>
  );
}
