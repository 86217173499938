export const CardSizes = {
  xs: 214.4,
  sm: 273,
  md: 370.6,
  lg: 566,
  xl: 742,
  xxl: "100%",
};

export const FontSizes={
    h1:32,
    title:20,
    subTitle:18,
    body:14,
    heading:16,
    text:12,
    smallText:10,
}
